.e-toolbar {
  background-image: linear-gradient(
    to right,
    var(--header-gradient-left) 30%,
    var(--header-gradient-right)
  ) !important;
  color: white;
  border-bottom: inset 3px var(--header-border);

  .e-hor-nav {
    background-color: transparent;

    .e-icons {
      color: white !important;
    }
  }

  .e-toolbar-items {
    background: transparent;
    color: var(--header-item-text) !important;

    .e-toolbar-item {
      background-color: transparent !important;
      border-radius: 0;
      padding: 0 !important;

      button {
        background-color: transparent !important;
        color: var(--header-item-text) !important;
      }

      span {
        color: var(--header-item-text) !important;
      }

      &:hover {
        span {
          color: var(--primary-color) !important;
        }
      }
    }

    .e-menu-wrapper {
      background-color: transparent !important;

      &.e-menu-popup {
        background-color: var(--header-item-background) !important;
      }
    }

    .e-menu-item {
      color: var(--header-item-text) !important;
      background-color: transparent !important;

      span {
        color: var(--header-item-text) !important;
      }

      &:hover {
        color: var(--header-item-hover-text) !important;
      }
    }
  }

  box-shadow: 0 2px 4px var(--border-color);
}

.e-menu-popup {
  border-top: outset 3px var(--header-border) !important;
  background-color: var(--header-item-background) !important;

  .e-menu-item {
    color: var(--header-dropdown-item-text) !important;

    &:hover {
      background-color: var(--header-item-hover-background) !important;
    }
  }
}
