// uploader layout styles
@include export-module('uploader-layout') {
  // For bigger class UI
  

  // For Desktop view

  .e-upload {
    width: 100%;

    #{if(&, '&', '*')}.e-control-wrapper {
      font-family: $upload-root-font-family;
    }

    .e-hidden-file-input {
      border: 0;
      height: 0;
      margin: 0;
      outline: none;
      padding: 0;
      text-indent: 0;
      visibility: hidden;
      width: 0;
    }

    .e-file-select-wrap {
      padding: $header-padding;

      .e-file-select,
      .e-file-select .e-uploader {
        display: inline-block;
        width: $upload-element-width;
      }

      .e-file-select .e-uploader {
        opacity: $upload-element-opacity;
      }

      #{if(&, '&', '*')} .e-file-drop {
        font-family: $upload-font-family;
        font-size: $drop-area-font-size-smaller;
        margin-left: $drop-area-padding-left-smaller;
      }
    }

    #{if(&, '&', '*')}.e-form-upload .e-upload-files {
      #{if(&, '&', '*')} .e-upload-file-list {
        min-height: $form-upload-file-list-height;
        padding-bottom: $form-upload-file-list-padding-bottom;
        padding-top: $form-upload-file-list-padding-top;

        #{if(&, '&', '*')} .e-file-container {
          top: 0;

          #{if(&, '&', '*')} .e-hidden-input {
            display: none;
          }

          #{if(&, '&', '*')} .e-file-name,
          #{if(&, '&', '*')} .e-file-type {
            line-height: $form-upload-file-name-line-height;
            padding-top: $form-upload-file-name-padding;
          }

          #{if(&, '&', '*')} .e-file-name.e-error,
          #{if(&, '&', '*')} .e-file-type.e-error,
          #{if(&, '&', '*')} .e-file-status.e-error {
            color: $upload-failure-color;
          }

          #{if(&, '&', '*')} .e-file-status,
          #{if(&, '&', '*')} .e-file-information {
            color: $file-size-color;
            display: block;
            font-size: $form-upload-file-status-font-size;
            line-height: $form-upload-file-name-line-height;
            padding-bottom: 0;
            padding-top: $form-upload-file-name-padding;
            position: relative;
          }
        }

        #{if(&, '&', '*')}.e-file-invalid {
          color: $upload-failure-color;

          #{if(&, '&', '*')} .e-file-name,
          #{if(&, '&', '*')} .e-file-type,
          #{if(&, '&', '*')} .e-file-status {
            color: $upload-failure-color;
          }
        }
      }

      #{if(&, '&', '*')} .e-file-remove-btn.e-icons,
      #{if(&, '&', '*')} .e-file-delete-btn.e-icons,
      #{if(&, '&', '*')} .e-file-abort-btn.e-icons,
      #{if(&, '&', '*')} .e-file-reload-btn.e-icons,
      #{if(&, '&', '*')} .e-file-play-btn.e-icons,
      #{if(&, '&', '*')} .e-file-pause-btn.e-icons {
        box-sizing: border-box;
        top: $form-upload-remove-icon-top;
      }

      #{if(&, '&', '*')} .e-file-remove-btn.e-icons.e-disabled,
      #{if(&, '&', '*')} .e-file-delete-btn.e-icons.e-disabled {
        color: $disabled-state;
        cursor: not-allowed;
        opacity: .4;
        pointer-events: none;
      }
    }

    #{if(&, '&', '*')} .e-upload-files {
      list-style-type: none;
      margin: $select-file-margin;
      padding: $select-file-padding;

      .e-icons:focus {
        @if ($skin-name == 'material' or $skin-name == 'Material3' or $skin-name == 'fluent2') {
          outline: none;
        }
      }

      .e-upload-file-list {
        font-family: $upload-font-family;
        font-size: $list-items-font-size-smaller;
        height: 100%;
        line-height: $list-line-height;
        @if $skin-name == 'tailwind' {
          line-height: $list-line-height-smaller;
        }
        min-height: $li-min-height;
        position: relative;

        .e-file-container {
          display: block;
          height: 100%;
          margin-left: $list-item-margin-left-smaller;
          margin-right: $list-item-margin-right;
          min-height: $list-item-height;
          position: relative;
          top: $file-container-top;

          .e-file-name {
            float: left;
            font-family: $file-name-font-family;
            font-size: $file-name-font-size-smaller;
            max-width: $list-item-name-width;
            overflow: hidden;
            padding-top: $file-name-padding-top-smaller;
            position: relative;
            text-overflow: ellipsis;
            top: $file-name-top;
            white-space: nowrap;
          }

          .e-file-name::before {
            content: attr(data-tail);
            float: right;
          }

          #{if(&, '&', '*')} .e-file-type {
            display: block;
            font-family: $file-name-font-family;
            font-size: $file-name-font-size-smaller;
            padding-top: $file-name-padding-top-smaller;
          }

          #{if(&, '&', '*')} .e-file-type.e-hidden {
            visibility: hidden;
          }

          #{if(&, '&', '*')} .e-file-size {
            display: block;
            font-size: $list-item-status-font-size-smaller;
            padding: $file-size-padding-smaller;
            @if $skin-name == 'tailwind' {
              font-size: $list-item-file-size-font-size-smaller;
              line-height: 18px;
            }
          }

          #{if(&, '&', '*')} .e-file-status,
          #{if(&, '&', '*')} .e-file-information {
            display: block;
            font-family: $upload-font-family;
            font-size: $list-item-status-font-size-smaller;
            padding-bottom: $file-name-padding-top-smaller;

            #{if(&, '&', '*')}.e-upload-progress {
              display: none;
            }
          }

          #{if(&, '&', '*')} .e-upload-progress-wrap {
            display: block;
            height: $progress-bar-wrapper-height-smaller;
            padding-bottom: $progress-bar-bottom-paddding;
            padding-top: $progress-bar-top-paddding;
            position: absolute;
            width: $progress-bar-wrapper-width;

            #{if(&, '&', '*')} .e-progress-inner-wrap {
              border-radius: $inner-wrap-radius;
              display: block;
              height: $progress-inner-wrap-height-smaller;
              width: 100%;

              #{if(&, '&', '*')} .e-upload-progress-bar {
                border-radius: $progress-bar-radius;
                display: inherit;
                height: $progress-bar-height-smaller;
              }
            }

            #{if(&, '&', '*')} .e-progress-bar-text {
              float: right;
              font-family: $upload-font-family;
              font-size: $list-item-status-font-size-smaller;
              position: relative;
              right: $progress-bar-text-right-smaller;
              top: $progress-bar-text-top-smaller;
            }
          }
        }
      }

      #{if(&, '&', '*')} .e-file-remove-btn.e-icons,
      #{if(&, '&', '*')} .e-file-delete-btn.e-icons,
      #{if(&, '&', '*')} .e-file-abort-btn.e-icons,
      #{if(&, '&', '*')} .e-file-pause-btn.e-icons,
      #{if(&, '&', '*')} .e-file-play-btn.e-icons,
      #{if(&, '&', '*')} .e-file-reload-btn.e-icons {
        align-items: center;
        box-sizing: border-box;
        cursor: pointer;
        display: inline-flex;
        font-size: $remove-icon-font-size-smaller;
        height: $remove-icon-height;
        justify-content: center;
        margin: $remove-icon-margin-smaller;
        margin-top: $remove-icon-margin-top-smaller;
        padding: $remove-icon-padding-smaller;
        position: absolute;
        right: $remove-icon-right;
        top: $remove-icon-top-smaller;
        vertical-align: middle;
        width: $remove-icon-width;

        #{if(&, '&', '*')}.e-upload-progress {
          cursor: default;
        }
      }

      #{if(&, '&', '*')} .e-file-remove-btn.e-icons.e-disabled,
      #{if(&, '&', '*')} .e-file-delete-btn.e-icons.e-disabled {
        color: $disabled-state;
        cursor: not-allowed;
        opacity: .4;
        pointer-events: none;
      }

      #{if(&, '&', '*')} .e-file-remove-btn.e-msie.e-icons,
      #{if(&, '&', '*')} .e-file-delete-btn.e-msie.e-icons,
      #{if(&, '&', '*')} .e-file-abort-btn.e-msie.e-icons,
      #{if(&, '&', '*')} .e-file-pause-btn.e-msie.e-icons,
      #{if(&, '&', '*')} .e-file-play-btn.e-msie.e-icons,
      #{if(&, '&', '*')} .e-file-reload-btn.e-msie.e-icons {
        padding: $icons-small-ie-padding;
      }

      #{if(&, '&', '*')} .e-file-pause-btn.e-icons,
      #{if(&, '&', '*')} .e-file-play-btn.e-icons,
      #{if(&, '&', '*')} .e-file-reload-btn.e-icons {
        right: $pause-play-button-right-value;
      }

      #{if(&, '&', '*')} .e-file-remove-btn.e-icons:not(.e-upload-progress):hover,
      #{if(&, '&', '*')} .e-file-delete-btn.e-icons:not(.e-upload-progress):hover,
      #{if(&, '&', '*')} .e-file-pause-btn.e-icons:not(.e-upload-progress):hover,
      #{if(&, '&', '*')} .e-file-play-btn.e-icons:not(.e-upload-progress):hover,
      #{if(&, '&', '*')} .e-file-reload-btn.e-icons:hover,
      #{if(&, '&', '*')} .e-file-abort-btn.e-icons:not(.e-disabled):hover,
      #{if(&, '&', '*')} .e-clear-icon-focus {
        @if $skin-name == 'Material3' {
          background: $clear-icon-focus-color;
        }
        @if $skin-name == 'fluent2' {
          color: $primary;
        }
        @if $skin-name != 'Material3' and $skin-name != 'fluent2' {
          background-color: $clear-icon-focus-color;
        }
        border-color: transparent;
        border-radius: $uploader-icons-hover-radius;
        box-shadow: 0 0 0 transparent;
      }

      #{if(&, '&', '*')} .e-file-remove-btn.e-icons:not(.e-upload-progress):focus,
      #{if(&, '&', '*')} .e-file-delete-btn.e-icons:not(.e-upload-progress):focus,
      #{if(&, '&', '*')} .e-file-pause-btn.e-icons:not(.e-upload-progress):focus,
      #{if(&, '&', '*')} .e-file-play-btn.e-icons:not(.e-upload-progress):focus,
      #{if(&, '&', '*')} .e-file-reload-btn.e-icons:focus,
      #{if(&, '&', '*')} .e-file-abort-btn.e-icons:not(.e-disabled):focus,
      #{if(&, '&', '*')} .e-clear-icon-focus {
        border-radius: $uploader-icons-hover-radius;
      }

      #{if(&, '&', '*')} .e-file-delete-btn.e-icons {
        font-size: $delete-icon-font-size-smaller;
        opacity: $delete-icon-opacity;
      }

      #{if(&, '&', '*')} .e-file-abort-btn.e-icons {
        font-size: $abort-icon-font-size-smaller;
        opacity: $delete-icon-opacity;
      }
    }

    #{if(&, '&', '*')} .e-file-select-wrap .e-btn,
    #{if(&, '&', '*')} .e-upload-actions .e-btn {
      font-family: $upload-font-family;
    }

    #{if(&, '&', '*')} .e-upload-actions {
      position: relative;
      text-align: right;

      .e-file-upload-btn {
        @if ($skin-name == 'bootstrap4' or $skin-name == 'FluentUI') {
          margin: $action-upload-buttons-margin-smaller;
        }
        @else if ($skin-name != 'bootstrap4' and $skin-name != 'FluentUI') {
          margin: $action-buttons-margin-smaller;
        }
      }

      .e-file-clear-btn {
        @if ($skin-name == 'bootstrap4' or $skin-name == 'FluentUI') {
          margin: $action-clear-buttons-margin-smaller;
        }
        @else if ($skin-name != 'bootstrap4' and $skin-name != 'FluentUI') {
          margin: $action-buttons-margin-smaller;
        }
      }
    }

    #{if(&, '&', '*')}.e-rtl {
      #{if(&, '&', '*')} .e-file-select-wrap {
        padding: $header-padding-rtl-smaller;

        #{if(&, '&', '*')} .e-control {
          #{if(&, '&', '*')}.e-btn {
            margin-right: $margin-rtl-header-smaller;
          }
        }

        #{if(&, '&', '*')} .e-file-drop {
          margin-left: $file-container-left;
          margin-right: $drop-area-padding-left-smaller;
          position: relative;
        }
      }

      #{if(&, '&', '*')} .e-upload-actions {
        text-align: $float-left;

        .e-file-upload-btn {
          @if $skin-name == 'FluentUI' {
            margin: $action-upload-buttons-margin-smaller-rtl;
          }
        }

        .e-file-clear-btn {
          @if $skin-name == 'FluentUI' {
            margin: $action-clear-buttons-margin-smaller-rtl;
          }
        }
      }

      #{if(&, '&', '*')} .e-upload-files {
        #{if(&, '&', '*')} .e-upload-file-list {
          #{if(&, '&', '*')} .e-file-container {
            height: $file-container-height;
            margin-left: $file-container-left;
            margin-right: $margin-rtl-smaller;
            position: relative;

            #{if(&, '&', '*')} .e-rtl-container {
              direction: ltr;
              float: $float-right;
              width: $file-container-height;
            }

            #{if(&, '&', '*')} .e-file-type {
              float: $float-right;
            }

            #{if(&, '&', '*')} .e-file-name {
              float: $float-right;
            }

            #{if(&, '&', '*')} .e-file-size {
              direction: ltr;
              float: $float-right;
              position: relative;
              text-align: right;
              width: $file-container-height;
            }

            #{if(&, '&', '*')} .e-upload-progress-wrap {
              float: $float-right;
              position: initial;
              top: $progress-bar-wrap-top-smaller;
              width: $progress-bar-width-rtl;

              #{if(&, '&', '*')} .e-progress-bar-text {
                float: $float-left;
                right: $progress-text-rtl-smaller;
                top: $rtl-progress-top;
              }
            }
          }

          #{if(&, '&', '*')} .e-file-remove-btn,
          #{if(&, '&', '*')} .e-file-delete-btn,
          #{if(&, '&', '*')} .e-file-abort-btn,
          #{if(&, '&', '*')} .e-file-reload-btn,
          #{if(&, '&', '*')} .e-file-pause-btn,
          #{if(&, '&', '*')} .e-file-play-btn {
            #{if(&, '&', '*')}.e-icons {
              left: $left;
              margin-left: $margin-rtl-smaller;
              margin-right: $margin-rtl-smaller;
              right: auto;
              top: $remove-icon-rtl-top-smaller;
              @if $skin-name == 'FluentUI' {
                margin-left: $margin-icon-rtl-smaller;
                margin-right: $margin-icon-rtl-smaller;
              }
            }
          }

          #{if(&, '&', '*')} .e-file-reload-btn {
            #{if(&, '&', '*')}.e-icons {
              left: $reload-btn-right;
              right: auto;
            }
          }

          #{if(&, '&', '*')} .e-file-play-btn,
          #{if(&, '&', '*')} .e-file-reload-btn,
          #{if(&, '&', '*')} .e-file-pause-btn {
            #{if(&, '&', '*')}.e-icons {
              left: $pause-play-button-right-value;
            }
          }
        }
      }
    }

    #{if(&, '&', '*')}.e-disabled {
      #{if(&, '&', '*')} .e-file-drop {
        color: $disabled-state;
      }

      #{if(&, '&', '*')} .e-upload-files {
        .e-upload-file-list {
          #{if(&, '&', '*')} .e-file-container .e-file-name,
          #{if(&, '&', '*')} .e-file-container .e-file-type,
          #{if(&, '&', '*')} .e-file-container .e-file-size,
          #{if(&, '&', '*')} .e-file-container .e-file-status,
          #{if(&, '&', '*')} .e-file-container .e-file-information {
            color: $disabled-state;
          }
        }
      }
    }

    .e-content-placeholder.e-upload.e-placeholder-upload {
      background-size: 400px 65px;
      min-height: 65px;
    }
  }

  .e-small {
    .e-upload {
      .e-file-select-wrap {
        padding: $header-padding-small;

        #{if(&, '&', '*')} .e-file-drop {
          font-size: $drop-area-small-font-size;
          margin-left: $drop-small-area-padding-left;
        }
      }

      #{if(&, '&', '*')} .e-upload-files {
        .e-upload-file-list {
          min-height: $li-min-height-small;

          .e-file-container .e-file-name {
            font-size: $file-small-name-font-size;
            padding-top: $file-small-name-padding-top;
          }

          .e-file-container .e-file-size {
            font-size: $list-small-item-status-font-size;
            padding: $file-small-size-padding;
          }

          .e-file-container .e-file-type {
            font-size: $file-small-name-font-size;
            padding-top: $file-small-name-padding-top;
          }

          .e-file-container .e-file-status,
          .e-file-container .e-file-information {
            padding-bottom: $file-small-name-padding-top;
          }

          .e-file-container .e-upload-progress-wrap {
            padding-bottom: $progress-bar-bottom-paddding-small;
            padding-top: $progress-bar-bottom-paddding-small;
          }

          .e-file-container {
            @if $skin-name == 'FluentUI' {
              margin-left: $list-item-margin-left-small;
            }
          }
        }

        #{if(&, '&', '*')} .e-file-remove-btn.e-icons,
        #{if(&, '&', '*')} .e-file-delete-btn.e-icons,
        #{if(&, '&', '*')} .e-file-abort-btn.e-icons,
        #{if(&, '&', '*')} .e-file-reload-btn.e-icons,
        #{if(&, '&', '*')} .e-file-play-btn.e-icons,
        #{if(&, '&', '*')} .e-file-pause-btn.e-icons {
          font-size: $remove-small-icon-font-size;
          height: $remove-small-icon-height;
          padding: $delete-small-icon-padding;
          width: $remove-small-icon-width;
          @if $skin-name == 'FluentUI' {
            margin: $remove-icon-margin-small;
          }
        }

        #{if(&, '&', '*')} .e-file-remove-btn.e-icons.e-disabled,
        #{if(&, '&', '*')} .e-file-delete-btn.e-icons.e-disabled {
          color: $disabled-state;
          cursor: not-allowed;
          opacity: .4;
          pointer-events: none;
        }
      }

      #{if(&, '&', '*')} .e-upload-actions {
        .e-file-upload-btn {
          @if $skin-name == 'FluentUI' {
            margin: $footer-upload-buttons-margin-small;
          }
        }

        .e-file-clear-btn {
          @if $skin-name == 'FluentUI' {
            margin: $footer-clear-buttons-margin-samll;
          }
        }
      }

      #{if(&, '&', '*')}.e-rtl {
        #{if(&, '&', '*')} .e-file-select-wrap {
          padding: $header-padding-rtl-small;
        }

        #{if(&, '&', '*')} .e-upload-actions {
          .e-file-upload-btn {
            @if $skin-name == 'FluentUI' {
              margin: $footer-upload-buttons-margin-rtl-small;
            }
          }

          .e-file-clear-btn {
            @if $skin-name == 'FluentUI' {
              margin: $footer-clear-buttons-margin-rtl-small;
            }
          }
        }

        #{if(&, '&', '*')} .e-upload-files {
          #{if(&, '&', '*')} .e-upload-file-list {
            #{if(&, '&', '*')} .e-file-remove-btn.e-icons,
            #{if(&, '&', '*')} .e-file-delete-btn.e-icons,
            #{if(&, '&', '*')} .e-file-abort-btn.e-icons,
            #{if(&, '&', '*')} .e-file-reload-btn.e-icons,
            #{if(&, '&', '*')} .e-file-play-btn.e-icons,
            #{if(&, '&', '*')} .e-file-pause-btn.e-icons {
              @if $skin-name == 'FluentUI' {
                margin-left: $margin-icon-rtl-small;
                margin-right: $margin-icon-rtl-small;
              }
            }
          }
        }
      }

      #{if(&, '&', '*')}.e-form-upload .e-upload-files {
        #{if(&, '&', '*')} .e-upload-file-list {
          min-height: $form-upload-small-file-list-height;
          padding-bottom: $form-upload-small-file-list-padding-bottom;
          padding-top: $form-upload-small-file-list-padding-top;

          #{if(&, '&', '*')} .e-file-container {
            top: 0;

            #{if(&, '&', '*')} .e-hidden-input {
              display: none;
            }

            #{if(&, '&', '*')} .e-file-name,
            #{if(&, '&', '*')} .e-file-type {
              line-height: $form-upload-small-file-name-line-height;
              padding-top: $form-upload-small-file-name-padding;
            }

            #{if(&, '&', '*')} .e-file-name.e-error,
            #{if(&, '&', '*')} .e-file-type.e-error,
            #{if(&, '&', '*')} .e-file-status.e-error {
              color: $upload-failure-color;
            }

            #{if(&, '&', '*')} .e-file-status,
            #{if(&, '&', '*')} .e-file-information {
              color: $file-size-color;
              display: block;
              font-size: $form-upload-small-file-status-font-size;
              line-height: $form-upload-small-file-name-line-height;
              padding-bottom: 0;
              padding-top: $form-upload-small-file-name-padding;
              position: relative;
            }
          }

          #{if(&, '&', '*')}.e-file-invalid {
            color: $upload-failure-color;

            #{if(&, '&', '*')} .e-file-name,
            #{if(&, '&', '*')} .e-file-type,
            #{if(&, '&', '*')} .e-file-status {
              color: $upload-failure-color;
            }
          }
        }

        #{if(&, '&', '*')} .e-file-remove-btn.e-icons,
        #{if(&, '&', '*')} .e-file-delete-btn.e-icons,
        #{if(&, '&', '*')} .e-file-abort-btn.e-icons,
        #{if(&, '&', '*')} .e-file-reload-btn.e-icons,
        #{if(&, '&', '*')} .e-file-play-btn.e-icons,
        #{if(&, '&', '*')} .e-file-pause-btn.e-icons {
          box-sizing: border-box;
          top: $form-upload-small-remove-icon-top;
        }

        #{if(&, '&', '*')} .e-file-remove-btn.e-icons.e-disabled,
        #{if(&, '&', '*')} .e-file-delete-btn.e-icons.e-disabled {
          color: $disabled-state;
          cursor: not-allowed;
          opacity: .4;
          pointer-events: none;
        }
      }
    }
  }
}
