﻿@include export-module('sortable-layout') {
  #{&}.e-sortable {
    outline: none;
    position: relative;
    user-select: none;

    .e-disabled {
      background-image: none;
      cursor: default;
      opacity: .35;
    }

    * {
      box-sizing: border-box;

      &:focus {
        outline: none;
      }
    }
  }

  /* stylelint-disable */
  .e-sortableclone {
    position: fixed !important;
  }
  /* stylelint-enable */
}
