$ddl-chip-mobile-close-font: 16px !default;
$ddl-multiselect-skin-name: 'material' !default;
$ddl-delim-font-size: 14px !default;
$ddl-rtl-wrapper: 0 2px 0 32px !default;
$ddl-chip-mobile-bg: $primary !default;
$ddl-chip-mobile-font: $primary-font !default;
$ddl-list-box-shadow-color: rgba(0, 0, 0, .21) !default;
$ddl-chip-bg-color:  $grey-200 !default;
$ddl-chip-hover-bg-color: $grey-200 !default;
$ddl-chip-hover-font-color: rgba($grey-light-font, .87) !default;
$ddl-sel-hover-chip-bg-color:  $grey-600 !default;
$ddl-sel-chip-hover-font-color: $grey-dark-font !default;
$ddl-chip-font-color:  rgba($grey-light-font, .87) !default;
$ddl-chip-close:  rgba($grey-light-font, .54) !default;
$ddl-sel-chip-font-color: $grey-dark-font !default;
$ddl-sel-chip-close: $grey-dark-font !default;
$ddl-sel-chip-color: $grey-dark-font !default;
$ddl-sel-chip-bg-color: $grey-600 !default;
$ddl-search-wrapper-width: calc(100% - 32px) !default;
$ddl-bigger-search-wrapper-width: calc(100% - 38px) !default;
$ddl-bigger-small-search-wrapper-width: calc(100% - 32px) !default;
$ddl-small-search-wrapper-width: calc(100% - 30px) !default;
$ddl-chip-selected-shadow-value: 0 2px 3px 1px $ddl-list-box-shadow-color !default;
$ddl-popup-shadow-value: 0 5px 5px -3px rgba($grey-light-font, .2), 0 8px 10px 1px rgba($grey-light-font, .14), 0 3px 14px 2px rgba($grey-light-font, .12) !default;
$ddl-remains-font-color: rgba($grey-light-font, .54) !default;
$ddl-remains-padding-left: 16px !default;
$ddl-chip-margin: 0 8px 6px 0 !default;
$ddl-chip-margin-bigger: 0 8px 6px 0 !default;
$ddl-chip-radius: 16px !default;
$ddl-chip-content-padding: 0 4px 0 0 !default;
$ddl-chip-mobile-content-padding: 8px 4px 8px 0 !default;
$ddl-rtl-chip-mobile-content-padding: 8px 0 8px 4px !default;
$ddl-chip-padding: 0 4px 0 8px !default;
$ddl-chip-close-top: 0 !default;
$ddl-chip-close-left: 0 !default;
$ddl-chip-close-margin: 0 0 0 !default;
$ddl-chip-close-rtl-margin: 0 0 0 !default;
$ddl-chip-close-square: 16px !default;
$ddl-chip-close-width: 16px !default;
$ddl-chip-hooker-square: 24px !default;
$ddl-chip-hooker-width: 24px !default;
$ddl-chip-close-font: 16px !default;
$ddl-chip-height: 24px !default;
$ddl-chip-mobile-height: 28px !default;
$ddl-chip-collection-top: 0 !default;
$ddl-input-height: 28px !default;
$ddl-input-bigger-height: 34px !default;
$ddl-input-mob-height: 30px !default;
$ddl-control-height: 30px !default;
$ddl-control-bigger-height: 37px !default;
$ddl-delimviewheight: 28px !default;
$ddl-delimviewheight-bigger: 34px !default;
$ddl-closer-hooker-font: 10px !default;
$ddl-chip-font-family: inherit !default;
$ddl-closer-margin-top: -2.5em !default;
$ddl-closer-margin-top-bigger: -3em !default;
$ddl-icon-margin-top: -2.1em !default;
$ddl-clear-margin-top: -2.5em !default;
$ddl-icon-margin-top-bigger: -2.5em !default;
$ddl-small-ddl-icon-top: -1.8em !default;
$ddl-bigger-small-ddl-icon-top: -2em !default;
$ddl-chip-mobile-radius: 2px !default;
$ddl-chip-sel-mobile-height: 40px !default;
$ddl-chip-selected-padding: 12px 4px 11px 0 !default;
$ddl-chip-mobile-close-margin: 3px 0 0 !default;
$ddl-chip-close-mob-margin: 8px 0 0 !default;
$ddl-closer-hooker-top: 100% !default;
$ddl-chip-hooker-right: 5px !default;
$ddl-chip-mob-left: 0 !default;
$ddl-chip-mob-padding: 0 0 0 16px !default;
$ddl-chip-font-size: 13px !default;
$ddl-rtl-chip-close-left: 0 !default;
$ddl-rtl-chip-content-padding: 5px 4px !default;
$ddl-rtl-mob-chip-content-padding: 8px 4px !default;
$ddl-rtl-mob-chip-content-margin: 0 0 8px 8px !default;
$ddl-rtl-mob-sel-chip-content-padding: 12px 4px !default;
$ddl-rtl-chip-padding: 0 8px 0 4px !default;
$ddl-rtl-mob-sel-chip-padding: 0 8px 0 4px !default;
$ddl-rtl-mob-chip-padding: 0 4px 0 8px !default;
$ddl-rtl-hooker-left: -4px !default;
$ddl-chip-close-mob-top: 0 !default;
$ddl-chip-close-mobile-top: 0 !default;
$ddl-control-placholder-padding: 0 32px 0 0 !default;
$ddl-overall-close-top: 6px !default;
$ddl-overall-close-left: 6px !default;
$ddl-sel-chip-close-mobile-top: 0 !default;
$ddl-sel-chip-close-mobile-left: 12px !default;
$ddl-chip-sel-mobile-close-height: 40px !default;
$ddl-chip-sel-mobile-close-margin: 0 0 0 0 !default;
$ddl-input-text-indent: 0 !default;
$ddl-input-placeholder-padding: 4px 0 4px 0 !default;
$ddl-rtl-chip-sel-close-left: -12px !default;
$ddl-popup-active-focus-bg-color: $grey-300 !default;
$ddl-popup-active-focus-font-color: $accent !default;
$ddl-popup-active-focus-border-color: transparent !default;
$ddl-popup-active-focus-border-width: 1px !default;
$ddl-popup-active-focus-shadow-item: none !default;
$ddl-input-placeholder: rgba($grey-light-font, .42) !default;
$ddl-popup-active-border-color: $grey-white !default;
$ddl-popup-active-border-width: 1px !default;
$ddl-delim-text-indent: 0 !default;
$ddl-delimview-bigger-padding-left: $ddl-delim-text-indent !default;
$ddl-popup-normal-border-width: 1px !default;
$ddl-check-right: 17px !default;
$ddl-check-left: 12px !default;
$ddl-bigger-select-all-height: 48px !default;
$ddl-select-all-height: 36px !default;
$ddl-back-icon-margin: 2.5px 10px 0 -52px !default;
$ddl-back-icon-padding: 0 8px !default;
$ddl-filterbar-padding: 8px !default;
$ddl-bigger-filterbar-padding: 4px 0 !default;
$ddl-delimviewheight-check-bigger: 34px !default;
$ddl-popup-reorder-border: rgba($grey-light-font, .12) !default;
$ddl-check-right-margin: 10px !default;
$ddl-bigger-check-right-margin: 12px !default;
$ddl-selectall-font-size: 14px !default;
$ddl-close-icon-left: 22px !default;
$ddl-check-icon-top: 6px !default;
$ddl-bigger-check-right: 17px !default;
$ddl-multi-list-default-font-color: rgba($grey-light-font, .87) !default;
$ddl-multi-list-hover-bg-color: $grey-200 !default;
$ddl-delim-text-padding-right: 6px !default;
$ddl-list-rtl-padding-right: 15px !default;
$ddl-close-rtl-icon-left: 19px !default;
$ddl-chip-close-rtl-left: -7px !default;
$ddl-delim-font-color: $grey-light-font !default;
$ddl-close-icon-color: $grey-light-font !default;
$ddl-multiselect-group-list-item-rtl-padding-right: 25px !default;
$ddl-bigger-multiselect-group-list-item-rtl-padding-right: 25px !default;
$ddl-multiselect-group-list-group-item-padding-left: 16px !default;
$ddl-multiselect-group-checkbox-wrapper-padding-left: 14px !default;
$ddl-bigger-multiselect-group-checkbox-wrapper-padding-left: $ddl-multiselect-group-checkbox-wrapper-padding-left !default;
$ddl-multiselect-group-list-group-item-rtl-padding-right: 16px !default;
$ddl-multiselect-label-position: 15px !default;
$ddl-multiselect-filled-floatlabel-fontsize: 14px !default;
$ddl-multiselect-filled-float-input-min-height: 28px !default;
$ddl-multiselect-filled-input-min-height: 39px !default;
$ddl-multiselect-filled-float-input-chip-clear-icon-size: 12px !default;
$ddl-multiselect-filled-chip-clear-icon-size: 14px !default;
$multiselect-disable-font-color: rgba($grey-light-font, .42) !default;

// Small size
$ddl-select-all-height-small: 26px !default;
$ddl-small-chip-height: 20px !default;
$ddl-chip-small-font-size: 12px !default;
$ddl-small-chip-close-square: 14px !default;
$ddl-small-chip-close-width: 14px !default;
$ddl-small-chip-close-font: 14px !default;
$ddl-control-small-height: 24px !default;
$ddl-small-input-height: 26px !default;
$ddl-delim-small-font-size: 12px !default;
$ddl-small-closer-margin-top: -2.5em !default;
$ddl-chip-close-hooker-small-left: 6px !default;
$ddl-chip-close-hooker-small-bigger-left: 6px !default;
$ddl-delimviewheight-small: 26px !default;
$ddl-multiselect-filled-input-min-height-small: 32px !default;
$ddl-multiselect-filled-float-input-min-height-small: 22px !default;
$ddl-multiselect-filled-floatlabel-fontsize-small: 10px !default;
$ddl-multiselect-filled-chip-clear-icon-size-small: 10px !default;

// Touch
$ddl-multiselect-filled-input-min-height-bigger: 52px !default;
$ddl-multiselect-filled-float-input-min-height-bigger: 36px !default;
$ddl-multiselect-filled-floatlabel-fontsize-bigger: 12px !default;

// Touch Small size
$ddl-select-all-height-bigger-small: 40px !default;
$ddl-bigger-small-chip-height: 22px !default;
$ddl-chip-bigger-small-font-size: 13px !default;
$ddl-bigger-small-chip-close-square: 16px !default;
$ddl-bigger-small-chip-close-width: 16px !default;
$ddl-bigger-small-chip-close-font: 16px !default;
$ddl-control-bigger-small-height: 28px !default;
$ddl-bigger-small-input-height: 28px !default;
$ddl-delim-bigger-small-font-size: 13px !default;
$ddl-delimviewheight-bigger-small: 28px !default;
$ddl-closer-margin-top-bigger-small: -2.5em !default;

$ddl-outline-multiselect-height: 34px !default;
$outline-multiselect-bigger-height: 40px !default;
$outline-multiselect-small-height: 30px !default;

$outline-multiselect-inner-input-height: $ddl-outline-multiselect-height !default;
$outline-multiselect-bigger-inner-input-height: $outline-multiselect-bigger-height !default;
$outline-multiselect-small-inner-input-height: $outline-multiselect-small-height !default;

$outline-multiselect-delimeter-padding: 0 0 0 12px !default;
$outline-multiselect-bigger-delimeter-padding: 0 0 0 16px !default;
$outline-multiselect-small-delimeter-padding: 0 0 0 8px !default;
$outline-rtl-multiselect-delimeter-padding: 0 12px 0 0 !default;
$outline-rtl-multiselect-bigger-delimeter-padding: 0 16px 0 0 !default;
$outline-rtl-multiselect-small-delimeter-padding: 0 8px 0 0 !default;

$outline-multiselect-chip-height: 26px !default;
$outline-multiselect-bigger-chip-height: 32px !default;
$outline-multiselect-small-chip-height: 24px !default;

$outline-multiselect-chip-padding: 0 8px !default;
$outline-multiselect-bigger-chip-padding: 0 12px !default;

$outline-multiselect-overall-padding: 3px 38px 1px 0 !default;
$outline-multiselect-bigger-overall-padding: 7px 42px 7px 0 !default;
$outline-rtl-multiselect-overall-padding: 3px 0 1px 38px !default;
$outline-rtl-multiselect-bigger-overall-padding: 7px 0 7px 42px !default;

$outline-multiselect-chip-margin: 4px 0 4px 8px !default;
$outline-rtl-multiselect-chip-margin: 4px 8px 4px 0 !default;

$outline-multiselect-chip-font-size: 13px !default;
$outline-multiselect-small-chip-font-size: 12px !default;
$outline-multiselect-bigger-chip-font-size: 14px !default;

$outline-multiselect-chip-bg-color: $grey-300 !default;
$outline-multiselect-chip-hover-bg-color: darken($grey-300, 4%) !default;
$outline-multiselect-sel-chip-bg-color: darken($grey-300, 10%) !default;
$outline-multiselect-sel-hover-chip-bg-color: darken($grey-300, 10%) !default;
$outline-multiselect-close-icon-bg-color: rgba($grey-light-font, .54) !default;
$outline-multiselect-close-icon-hover-bg-color: rgba($grey-light-font, .87) !default;
$outline-multiselect-remains-font-color: rgba($grey-light-font, .6) !default;
$outline-multiselect-disabled-font-color: rgba($grey-light-font, .38) !default;
$outline-multiselect-disabled-chip-bg-color: $grey-100 !default;
$filled-multiselect-chip-bg-color: darken($grey-300, 7%) !default;
$filled-multiselect-chip-hover-bg-color: darken($grey-300, 7%) !default;

@include export-module('multiselect-material') {
  .e-multiselect.e-input-group.e-checkbox .e-multi-select-wrapper input[type = 'text'] {
    padding: 1px 0;
  }

  .e-small .e-multiselect.e-input-group.e-checkbox .e-multi-select-wrapper input[type = 'text'],
  .e-small.e-multiselect.e-input-group.e-checkbox .e-multi-select-wrapper input[type = 'text'] {
    padding: 0;
  }

  .e-multiselect.e-input-group .e-multi-select-wrapper .e-multiselect-box input[type = 'text'],
  .e-multiselect.e-input-group.e-input-focus .e-multi-select-wrapper .e-searcher:not(.e-zero-size) input[type = 'text'] {
    padding: 0;
  }
}
