﻿@include export-module('dialog-layout') {
  /* stylelint-disable property-no-vendor-prefix */
  .e-dialog {
    -webkit-flex-direction: column;
    @if $skin-name == 'fluent2' {
      border: $dialog-outer-border;
    }
    @else {
      border: $dialog-border;
    }
    border-radius: $dialog-border-radius;
    flex-direction: column;
    width: 100%;

    &.e-popup {
      width: 100%;
    }

    &.e-dlg-resizable {
      padding-bottom: $dialog-enable-resize-padding-bottom;
      touch-action: none;
    }

    .e-dlg-header-content {
      border-radius: $header-content-border-radius;
      @if $skin-name != 'bootstrap5' {
        line-height: $dialog-header-line-height;
      }
    }

    .e-dlg-header-content + .e-dlg-content {
      padding-top: $dialog-content-padding-top;
    }

    .e-btn .e-btn-icon.e-icon-dlg-close {
      font-size: $dialog-close-icon-font-size;
      width: $dialog-close-icon-width;
      @if $skin-name == 'bootstrap5.3' or $skin-name == 'bootstrap5' {
        top: $dialog-header-close-icon-top;
        left: $dialog-header-close-icon-left;
        position: inherit;
      }
    }

    .e-dlg-header {
      display: block;
      font-size: $dialog-header-font-size;
      font-weight: $dialog-header-font-weight;
      overflow: hidden;
      text-overflow: ellipsis;
      user-select: none;
      vertical-align: top;
      white-space: nowrap;
      width: 80%;
      @if $skin-name == 'Material3' {
        line-height: 30px;
      }
      @if $skin-name == 'fluent2' {
        line-height: 32px;
      }

      &.e-hide {
        display: none;
      }
    }

    .e-dlg-modal {
      position: fixed;
    }

    /* stylelint-disable declaration-no-important */
    .e-scroll-disabled {
      overflow: hidden !important;
    }
    /* stylelint-enable declaration-no-important */

    .e-dlg-content {
      display: block;
      flex: 1 1 auto;
      font-size: $dialog-content-font-size;
      font-weight: $dialog-content-font-weight;
      line-height: $dialog-content-line-height;
      overflow: auto;
      overflow-x: hidden;

      &.e-hide {
        display: none;
      }
    }

    .e-footer-content {
      border-bottom-left-radius: $footer-content-border-radius;
      border-bottom-right-radius: $footer-content-border-radius;
      border-top: $dialog-footer-content-border-top;
      bottom: 0;
      display: block;
      right: 0;
      width: 100%;

      &.e-hide {
        display: none;
      }
    }

    .e-footer-content {
      text-align: right;
    }

    .e-resize-handle {
      height: $dialog-resize-handler-height;
      position: absolute;
      width: $dialog-resize-handler-width;

      &.e-south-east {
        bottom: $dialog-resize-handler-position;
        cursor: nwse-resize;
        right: $dialog-resize-handler-position;
      }

      &.e-south-west {
        bottom: $dialog-resize-handler-position;
        cursor: nesw-resize;
        left: $dialog-resize-handler-position;
        transform: rotate(90deg);
      }

      &.e-north-east {
        cursor: nesw-resize;
        right: $dialog-resize-handler-position;
        top: $dialog-resize-handler-position;
        transform: rotate(-90deg);
      }

      &.e-north-west {
        cursor: nwse-resize;
        left: $dialog-resize-handler-position;
        top: $dialog-resize-handler-position;
        transform: rotate(180deg);
      }
    }

    .e-south,
    .e-north,
    .e-east,
    .e-west {
      background-color: transparent;
      background-repeat: repeat;
      overflow: visible;
      position: absolute;
    }

    .e-east,
    .e-west {
      cursor: ew-resize;
    }

    .e-south,
    .e-north {
      cursor: ns-resize;
    }

    &.e-blazor-hidden {
      left: 0;
      position: absolute;
    }
  }

  .e-dialog {
    & .e-dlg-header-content {
      border-bottom: $dialog-header-border;
      padding: $dialog-header-content-padding;
    }

    & .e-dlg-content {
      padding: $dialog-content-padding;
      @if $skin-name == 'Material3' or $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' {
        border-radius: $dialog-border-radius;
      }
    }

    & .e-footer-content {
      padding: $dialog-footer-content-padding;
    }

    @if $skin-name == 'FluentUI' {
      &.e-dlg-resizable {
        & .e-footer-content {
          padding-bottom: $dialog-footer-content-padding-bottom;
        }
      }
    }

    & .e-footer-content .e-btn {
      margin-left: $dialog-footer-button-margin-left;
    }
  }

  .e-alert-dialog .e-footer-content{
    border-top: $dialog-alert-footer-content-border-top;
  }
  
  .e-rtl .e-footer-content .e-btn {
    margin-right: $dialog-footer-button-margin-right;
  }

  .e-dialog.e-draggable > .e-dlg-header-content {
    cursor: move;
  }

  .e-dialog.e-device.e-draggable.e-popup-open {
    touch-action: none;
  }

  .e-dialog {
    max-height: 98%;
    max-width: 100%;
    min-width: 240px;
    position: absolute;
  }

  .e-rtl .e-footer-content .e-btn {
    margin-left: 0;
  }

  .e-rtl .e-footer-content {
    text-align: left;
  }

  .e-rtl .e-footer-content {
    text-align: left;
  }

  .e-dialog.e-rtl .e-dlg-header-content .e-btn.e-dlg-closeicon-btn {
    float: left;
    left: 0;
    right: $dialog-close-button-left;
  }

  .e-dialog .e-dlg-header-content .e-btn.e-dlg-closeicon-btn {
    background-color: $dialog-btn-bg-color;
    border-color: $dialog-btn-border-color;
    border-radius: $dialog-header-icon-hover-border-radius;
    bottom: $dialog-headericon-bottom;
    float: right;
    height: $dialog-close-button-height;
    left: $dialog-close-button-left;
    position: relative;
    width: $dialog-close-button-width;
    @if $skin-name == 'FluentUI' or $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' or $skin-name == 'fluent2' {
      padding: $dialog-header-btn-padding;
    }
    @if $skin-name == 'Material3' {
      padding: $zero-value;
    }
    @if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' {
      line-height: 1;
    }
  }

  .e-rtl {
    &.e-dialog {
      .e-resize-handle {
        &.e-south-west {
          bottom: $dialog-resize-handler-position;
          cursor: sw-resize;
          direction: ltr;
          left: $dialog-resize-handler-position;
          text-align: initial;
          transform: rotate(90deg);
        }
      }
    }
  }

  /* stylelint-disable declaration-no-important */
  .e-dlg-target.e-scroll-disabled {
    overflow: hidden !important;
  }
  /* stylelint-enable declaration-no-important */
  .e-dlg-overlay {
    height: 100%;
    left: 0;
    opacity: $dialog-overlay-opacity;
    position: fixed;
    top: 0;
    transition: opacity .15s linear;
    width: 100%;

    &.e-fade {
      opacity: 0;
    }
  }

  .e-dlg-overflow-hidden {
    overflow: auto;
  }

  /* stylelint-disable declaration-no-important */
  .e-dlg-fullscreen {
    height: 100% !important;
    left: 0 !important;
    width: 100% !important;
  }
  /* stylelint-enable declaration-no-important */

  .e-popup.e-popup-open.e-dialog {
    display: inline-flex;
  }

  .e-dlg-container {
    align-items: flex-start;
    display: none;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
  }

  .e-dlg-center-center {
    -webkit-align-items: center;
    -webkit-justify-content: center;
    align-items: center;
    justify-content: center;
  }

  .e-dlg-left-center {
    -webkit-align-items: center;
    -webkit-justify-content: flex-start;
    align-items: center;
    justify-content: flex-start;
  }

  .e-dlg-right-center {
    -webkit-align-items: center;
    -webkit-justify-content: flex-end;
    align-items: center;
    justify-content: flex-end;
  }

  .e-dlg-left-top {
    -webkit-align-items: flex-start;
    -webkit-justify-content: flex-start;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .e-dlg-right-top {
    -webkit-align-items: flex-start;
    -webkit-justify-content: flex-end;
    align-items: flex-start;
    justify-content: flex-end;
  }

  .e-dlg-center-top {
    -webkit-align-items: center;
    -webkit-flex-direction: column;
    align-items: center;
    flex-direction: column;
  }

  .e-dlg-left-bottom {
    -webkit-align-items: flex-end;
    -webkit-justify-content: flex-start;
    align-items: flex-end;
    justify-content: flex-start;
  }

  .e-dlg-right-bottom {
    -webkit-align-items: flex-end;
    -webkit-justify-content: flex-end;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .e-dlg-center-bottom {
    -webkit-align-items: center;
    -webkit-flex-direction: column;
    -webkit-justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
  }

  .e-dialog .e-btn.e-dlg-closeicon-btn:hover,
  .e-dialog .e-btn.e-dlg-closeicon-btn:focus,
  .e-dialog .e-btn.e-dlg-closeicon-btn:active {
    @if $skin-name != 'fluent2' {
      background-color: $dialog-hover-focus-btn-bg-color;
      border-color: $dialog-active-btn-border-color;
      box-shadow: 0 0 0 transparent;
    }
    @if $skin-name == 'bootstrap5.3' {
      background-color: $content-bg-color;
      border-color: $dialog-active-btn-border-color;
    }
  }

  .e-dialog .e-btn.e-dlg-closeicon-btn:active {
    @if $skin-name != 'fluent2' {
      background-color: $dialog-active-btn-bg-color;
    }
  }

  .e-dialog .e-btn.e-dlg-closeicon-btn:focus,
  .e-dialog .e-btn.e-dlg-closeicon-btn:active {
    @if $skin-name == 'bootstrap5.3' {
      background-color: $content-bg-color;
      border-color: $dialog-active-btn-border-color;
      box-shadow: $primary-shadow-focus;
    }
  }
}

.e-content-placeholder.e-dialog.e-placeholder-dialog {
  background-size: 400px 210px;
  min-height: 210px;
}

@media (min-width: 768px) {
  .e-alert-dialog.e-dialog.e-popup,
  .e-confirm-dialog.e-dialog.e-popup {
    margin: 30px;
    width: 600px;
  }
}

@media (max-width: 768px) {
  .e-alert-dialog.e-dialog.e-popup,
  .e-confirm-dialog.e-dialog.e-popup {
    margin: 30px;
    width: auto;
  }
}

@media (max-width: 768px) {
  .e-prompt-dialog.e-dialog.e-popup,
  .e-prompt-dialog.e-dialog.e-popup {
    margin: 30px;
    width: auto;
  }
}

@media (min-width: 768px) {
  .e-prompt-dialog.e-dialog.e-popup,
  .e-prompt-dialog.e-dialog.e-popup {
    margin: 30px;
    width: 600px;
  }
}

.e-dlg-ref-element {
  display: none;
}

.e-dialog .e-footer-content {
  box-sizing: border-box;
}

@if $skin-name == 'bootstrap' or $skin-name == 'bootstrap4' or $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' or $skin-name == 'tailwind' {
  .e-dialog .e-dlg-content .e-richtexteditor .e-rte-container .e-rte-tb-float {
    top: -$dialog-content-padding-top !important; /* stylelint-disable-line declaration-no-important */
  }
}
