.e-dialog {
  position: absolute;
  min-width: 350px;
  min-height: 600px;
  background-color: var(--dialog-background) !important;
  color: var(--text-color);

  form {
    height: 0 !important;
  }

  .e-dlg-header-content,
  .e-dlg-header,
  .e-dlg-content,
  .e-footer-content {
    color: var(--text-color) !important;
    background-color: var(--card-background) !important;
    text-align: unset !important;

    .e-btn {
      margin: 0 !important;
    }
  }

  .e-dlg-header-content {
    .e-btn {
      background: transparent !important;

      span {
        font-size: 1.2em !important;
        color: var(--text-color) !important;

        &:hover {
          color: var(--primary-color) !important;
        }
      }
    }
  }

  .e-dlg-content {
    height: 100% !important;
    overflow: auto;
  }
}
