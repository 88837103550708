@include export-module('badge-theme') {
  //sass-lint:disable no-color-literals
  .e-badge {
    &.e-badge-primary:not(.e-badge-ghost):not([href]),
    &.e-badge-primary[href]:not(.e-badge-ghost) {
      background-color: $badge-primary-bg-color;
      color: $badge-primary-font-color;
    }

    &.e-badge-secondary:not(.e-badge-ghost):not([href]),
    &.e-badge-secondary[href]:not(.e-badge-ghost) {
      @if ($badge-theme == 'bootstrap5.3') {
        background-color: $badge-secondary-bg-color;
        color: $badge-secondary-font-color;
      }
      
      @if $badge-theme == 'fluent2' {
        border: $badge-secondary-border-color;
      }
    }

    &.e-badge-success:not(.e-badge-ghost):not([href]),
    &.e-badge-success[href]:not(.e-badge-ghost) {
      background-color: $badge-success-bg-color;
      @if ($badge-theme == 'tailwind' or $badge-theme == 'tailwind-dark' or $badge-theme == 'bootstrap5' or $badge-theme == 'bootstrap5.3' or $badge-theme == 'Material3' or $badge-theme == 'fluent2') {
        color: $badge-success-font-color;
      }
      @else {
        color: $badge-dark-font-color;
      }
    }

    &.e-badge-danger:not(.e-badge-ghost):not([href]),
    &.e-badge-danger[href]:not(.e-badge-ghost) {
      background-color: $badge-danger-bg-color;
      @if ($badge-theme == 'tailwind' or $badge-theme == 'tailwind-dark' or $badge-theme == 'bootstrap5' or $badge-theme == 'bootstrap5.3' or $badge-theme == 'Material3' or $badge-theme == 'fluent2') {
        color: $badge-danger-font-color;
      }
      @else {
        color: $badge-dark-font-color;
      }
    }

    &.e-badge-warning:not(.e-badge-ghost):not([href]),
    &.e-badge-warning[href]:not(.e-badge-ghost) {
      background-color: $badge-warning-bg-color;
      color: $badge-warning-font-color;
    }

    &.e-badge-info:not(.e-badge-ghost):not([href]),
    &.e-badge-info[href]:not(.e-badge-ghost) {
      background-color: $badge-info-bg-color;
      @if ($badge-theme == 'tailwind' or $badge-theme == 'tailwind-dark' or $badge-theme == 'bootstrap5' or $badge-theme == 'bootstrap5.3' or $badge-theme == 'Material3' or $badge-theme == 'fluent2') {
        color: $badge-info-font-color;
      }
      @else {
        color: $badge-dark-font-color;
      }
    }

    &.e-badge-light:not(.e-badge-ghost):not([href]),
    &.e-badge-light[href]:not(.e-badge-ghost) {
      @if $badge-theme == 'Material3' or $badge-theme == 'Material3-dark' {
        background: $badge-light-bg-color;
      }
      @else {
        background-color: $badge-light-bg-color;
      }
      color: $badge-light-font-color;
    }

    &.e-badge-dark:not(.e-badge-ghost):not([href]),
    &.e-badge-dark[href]:not(.e-badge-ghost) {
      background-color: $badge-dark-bg-color;
      color: $badge-dark-font-color;
    }

    $badge-types: (
      'primary': $badge-primary-bg-color,
      'secondary': $badge-secondary-bg-color,
      'success': $badge-success-bg-color,
      'danger': $badge-danger-bg-color,
      'warning': $badge-warning-bg-color,
      'info': $badge-info-bg-color,
      'light': $badge-light-bg-color,
      'dark': $badge-dark-bg-color
    );

    @each $type, $color in $badge-types {
      &.e-badge-#{$type}[href]:not(.e-badge-ghost) {
        &:hover {
          @if $badge-theme == 'Material3' or $badge-theme == 'fluent2' {
            text-decoration: underline;
          }
          @else if $badge-theme == 'bootstrap5' or $badge-theme == 'bootstrap5.3' {
            background-color: $badge-primary-bg-color-darken;
          }
          @else {
            background-color: darken($color: $color, $amount: 10%);
          }
        }
      }
    }

    @each $type, $color in $badge-types {
      &.e-badge-#{$type}[href].e-badge-ghost {
        &:hover {
          @if $badge-theme != 'Material3' and $badge-theme != 'fluent2' and $badge-theme != 'bootstrap5' and $badge-theme != 'bootstrap5.3' and $badge-theme != 'bds' {
            border-color: darken($color: $color, $amount: 15%);
            color: darken($color: $color, $amount: 15%);
          }
        }
      }
    }

    &.e-badge-ghost {
      &.e-badge-primary {
        background-color: transparent;
        border: 1px solid $badge-primary-bg-color;
        color: $badge-primary-bg-color;
      }

      &.e-badge-secondary {
        background-color: transparent;
        @if ($badge-theme == 'tailwind' or $badge-theme == 'FluentUI' or $badge-theme == 'tailwind-dark' or $badge-theme == 'bootstrap5' or $badge-theme == 'Material3' or $badge-theme == 'Material3-dark' or $badge-theme == 'fluent2') {
          border: 1px solid $badge-secondary-font-color;
          color: $badge-secondary-font-color;
        }
        @else {
          border: 1px solid $badge-secondary-bg-color;
          color: $badge-secondary-bg-color;
        }
      }

      &.e-badge-success {
        background-color: transparent;
        @if ($badge-theme == 'tailwind' or $badge-theme == 'tailwind-dark' or $badge-theme == 'bootstrap5') {
          border: 1px solid $badge-success-font-color;
          color: $badge-success-font-color;
        }
        @else {
          border: 1px solid $badge-success-bg-color;
          color: $badge-success-bg-color;
        }
      }

      &.e-badge-danger {
        background-color: transparent;
        @if ($badge-theme == 'tailwind' or $badge-theme == 'tailwind-dark' or $badge-theme == 'bootstrap5') {
          border: 1px solid $badge-danger-font-color;
          color: $badge-danger-font-color;
        }
        @else {
          border: 1px solid $badge-danger-bg-color;
          color: $badge-danger-bg-color;
        }
      }

      &.e-badge-warning {
        background-color: transparent;
        @if ($badge-theme == 'tailwind' or $badge-theme == 'tailwind-dark' or $badge-theme == 'bootstrap5') {
          border: 1px solid $badge-warning-font-color;
          color: $badge-warning-font-color;
        }
        @else if $badge-theme == 'fluent2' {
          border: 1px solid $badge-warning-bg-color;
          color: $badge-warning-ghost-color;
        }
        @else {
          border: 1px solid $badge-warning-bg-color;
          color: $badge-warning-bg-color;
        }
      }

      &.e-badge-info {
        background-color: transparent;
        @if ($badge-theme == 'tailwind' or $badge-theme == 'tailwind-dark' or $badge-theme == 'bootstrap5') {
          border: 1px solid $badge-info-font-color;
          color: $badge-info-font-color;
        }
        @else if $badge-theme == 'fluent2' {
          border: 1px solid $badge-info-ghost-border;
          color: $badge-info-ghost-color;
        }
        @else {
          border: 1px solid $badge-info-bg-color;
          color: $badge-info-bg-color;
        }
      }

      &.e-badge-light {
        background-color: transparent;
        @if ($badge-theme == 'tailwind' or $badge-theme == 'FluentUI' or$badge-theme == 'tailwind-dark' or$badge-theme == 'bootstrap5' or $badge-theme == 'Material3' or $badge-theme == 'Material3-dark') {
          border: 1px solid $badge-light-font-color;
          color: $badge-light-font-color;
        }
        @else if $badge-theme == 'fluent2' {
          border: 1px solid $badge-light-bg-color;
          color: $badge-light-font-color;
        }
        @else {
          border: 1px solid $badge-light-bg-color;
          color: $badge-light-bg-color;
        }
      }

      &.e-badge-dark {
        background-color: transparent;
        border: 1px solid $badge-dark-bg-color;
        color: $badge-dark-bg-color;
      }
    }
  }
}
