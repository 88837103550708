@include export-module('pdfviewer-layout') {

  /*! component layout */
  /* stylelint-disable property-no-vendor-prefix */
  .e-pdfviewer {
    .e-pv-viewer-container {
      border-style: $pv-viewer-border-style;
      border-width: $pv-viewer-border-width;
    }

    .e-pv-text-layer {
      position: absolute;
      top: 0;
      z-index: 2;
    }

    .e-pv-annotation-canvas {
      left: 0;
      position: absolute;
      top: 0;
    }

    .e-pv-text {
      background: transparent;
      color: transparent;
      line-height: normal;
      opacity: $pv-text-selection-opacity;
      position: absolute;
    }

    .e-pv-search-text-highlight,
    .e-pv-search-text-highlightother {
      line-height: normal;
      opacity: $pv-text-selection-opacity;
      position: absolute;
    }

    .e-pv-hyperlink {
      z-index: 2;
    }

    .e-pv-hyperlink.e-pv-onselection {
      z-index: 1;
    }

    .e-enable-text-selection {
      -moz-user-select: text;
      -ms-user-select: text;
      -webkit-user-select: text;
      user-select: text;
    }

    .e-disable-text-selection {
      -moz-user-select: none;
      -ms-user-select: none;
      -webkit-user-select: none;
      user-select: none;
    }

    .e-enable-text-selection .e-pv-text {
      display: inline-block;
    }

    .e-disable-text-selection .e-pv-text {
      display: none;
    }

    .e-pv-cursor {
      cursor: text;
    }

    .e-pv-crosshair-cursor {
      cursor: crosshair;
    }

    .e-pv-touch-select-drop {
      display: inline-block;
      height: 30px;
      position: absolute;
      width: 30px;
      z-index: 1000;
    }

    .e-pv-touch-ellipse {
      background-color: $pv-selection-touch-element-background-color;
      border-bottom-left-radius: 50%;
      border-bottom-right-radius: 50%;
      border-color: $pv-selection-touch-element-border-color;
      border-style: solid;
      border-top-left-radius: 50%;
      border-width: 2px;
      height: 20px;
      margin: 0 0 0 9px;
      position: absolute;
      width: 20px;
    }

    .e-pv-annotation-note {
      border-radius: 2px;
      cursor: auto;
      font-size: $pv-sidebar-title-font-size;
      height: auto;
      max-width: 200px;
      min-height: 10px;
      min-width: 30px;
      overflow-wrap: break-word;
      padding: 5px;
      position: absolute;
      width: auto;
      z-index: 10000;
    }

    .e-pv-annotation-note-author {
      border-bottom: 1px solid $pv-annotation-note-border-bottom-color;
      font-weight: bold;
    }

    .e-pv-annotation-popup-menu {
      cursor: auto;
      min-width: 396px;
      padding-bottom: 6px;
      position: absolute;
      width: auto;
      z-index: 10000;
    }

    .e-pv-annotation-popup-header {
      height: auto;
      min-height: 29px;
      padding-top: 14px;
    }

    .e-pv-annotation-popup-author {
      float: left;
      font-size: 16px;
      font-weight: bold;
      margin-left: 14px;
    }

    .e-pv-annotation-popup-close {
      float: right;
      height: 20px;
      margin-right: 14px;
      width: 20px;
    }

    .e-pv-annotation-modified-time {
      height: 14px;
      margin-left: 14px;
      padding-top: 8px;
    }

    .e-pv-annotation-popup-note-container {
      height: auto;
      padding: 14px;
      width: auto;
    }

    .e-pv-annotation-popup-content {
      background-color: $pv-annotation-note-popup-bg-color;
      border: 1px solid;
      font-size: 16px;
      min-height: 132.08px;
      overflow: hidden auto;
      width: 368px;
    }

    .e-pv-properties-fill-color-icon,
    .e-pv-properties-stroke-color-icon {
      border-bottom: 2px solid;
    }

    .e-pv-drop {
      color: $pv-selection-touch-element-background-color;
      display: inline-block;
      height: 20px;
      position: absolute;
      width: 20px;
      z-index: 1000;
    }

    .e-pv-droplet {
      background-color: $pv-selection-touch-element-background-color;
      border-bottom-left-radius: 50%;
      border-bottom-right-radius: 50%;
      border-color: $pv-selection-touch-element-border-color;
      border-style: solid;
      border-top-left-radius: 50%;
      border-width: 2px;
      height: 20px;
      margin: 0 0 0 9px;
      position: absolute;
      width: 20px;
    }
  }
}
