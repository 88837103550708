@include export-module('grid-material-icons') {

  .e-icon-check::before {
    content: '\e7ff';
  }

  #{&}.e-grid,
  .e-grid-menu {

    &.e-rtl .e-icon-rowdragicon::before,
    .e-icon-rowdragicon::before,
    .e-group-animate .e-drag.e-icon-drag::before {
      content: '\e330';
    }

    .e-group-animate .e-nextgroup.e-icon-next::before {
      content: '\ec07';
    }

    .e-icon-ascending::before {
      content: '\e840';
    }

    .e-icon-descending::before {
      content: '\e83f';
    }

    .e-icon-hide::before {
      content: '\e825';
    }

    .e-ungroupbutton.e-icon-hide::before {
      content: '\e917';
    }

    .e-icon-rowselect::before {
      content: '\e930';
    }

    .e-icon-sortdirect::before {
      content: '\e890';
    }

    .e-icon-gdownarrow::before {
      content: '\e83d';
    }

    .e-icon-grightarrow::before {
      content: '\e859';
    }

    .e-icon-filter::before,
    .e-icon-filter.e-filtered::before {
      content: '\e7ee';
    }

    .e-excl-filter-icon::before,
    .e-resfilter-icon::before {
      content: '\e251';
    }

    .e-ressort-icon::before {
      content: '\e612';
    }

    .e-excl-filter-icon.e-filtered::before {
      content: '\e248';
    }

    &.e-rtl {
      .e-icon-grightarrow::before {
        content: '\e854';
      }
    }

    .e-icon-group::before,
    .e-icon-ungroup::before {
      content: '\e926';
    }

    .e-icon-reorderuparrow::before {
      content: '\e918';
    }

    .e-icon-reorderdownarrow::before {
      content: '\e919';
    }

    .e-print::before {
      content: '\e813';
    }

    .e-add::before {
      content: '\e7f9';
    }

    .e-resback-icon::before {
      content: '\e977';
    }

    .e-wordexport::before {
      content: '\e7b0';
    }

    .e-pdfexport::before {
      content: '\e240';
    }

    .e-csvexport::before {
      content: '\e241';
    }

    .e-excelexport::before {
      content: '\e242';
    }

    .e-edit::before {
      content: '\e81e';
    }

    .e-responsivetoolbaritems-btn::before,
    .e-columnmenu::before,
    .e-columnmenu.e-filtered::before {
      content: '\e984';
    }

    .e-delete::before {
      content: '\e84e';
    }

    .e-copy::before {
      content: '\e70a';
    }

    .e-save::before {
      content: '\e98e';
    }

    .e-update::before {
      content: '\e735';
    }

    .e-columnchooserdiv::before {
      content: '\e714';
    }

    .e-columnchooser::before {
      content: '\e21e';
    }

    .e-columnchooser-btn::before {
      content: '\e969';
    }

    .e-cc-icon::before {
      content: '\e82e';
    }
  }
}

#{&}.e-grid,
.e-grid-menu,
.e-grid-popup {
  .e-ccsearch-icon::before,
  .e-search-icon::before {
    content: '\e993';
  }

  .e-ccsearch-icon.e-cc-cancel::before,
  .e-chkcancel-icon::before {
    content: '\e7a7';
  }

  .e-cancel-icon::before,
  .e-cancel::before {
    content: '\e825';
  }
}

.e-responsive-dialog {
  & .e-resfilterback::before {
    content: '\e977';
  }

  & .e-filterset::before {
    content: '\e7ee';
  }

  & .e-search-icon::before,
  & .e-ccdlg span.e-ccsearch-icon::before {
    content: '\e993';
  }

  & .e-chkcancel-icon::before,
  & .e-ccdlg span.e-cc-cancel::before {
    content: '\e7a7';
  }

  & .e-icon-filter-clear::before {
    content: '\e248';
  }

  & .e-resfilter-icon.e-icons::before {
    content: '\e251';
  }

  & .e-resascending-icon.e-icons::before {
    content: '\e840';
  }

  & .e-resdescending-icon.e-icons::before {
    content: '\e83f';
  }

  & .e-resgroup-icon.e-icons::before,
  & .e-resungroup-icon.e-icons::before {
    content: '\e926';
  }
}

.e-grid-toolbarmenu .e-responsivetoolbar-menu {
  .e-menu-icon.e-print::before {
    content: '\e813';
  }
  .e-menu-icon.e-pdfexport::before {
    content: '\e240';
  }
  .e-menu-icon.e-excelexport::before {
    content: '\e242';
  }
  .e-menu-icon.e-csvexport::before {
    content: '\e241';
  }
}

.e-row-responsive-filter {
  & .e-dlg-closeicon-btn {
    & .e-icon-dlg-close::before {
      content: '\e977';
    }
  }
}
