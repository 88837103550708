// uploader theme file
// uploader layout styles
@include export-module('uploader-theme') {

  .e-upload {
    border: $upload-border;
    @if $skin-name == 'tailwind' or $skin-name == 'FluentUI' or $skin-name == 'Material3' or $skin-name == 'fluent2' {
      border-radius: $upload-border-radius;
    }

    #{if(&, '&', '*')} .e-file-drop {
      color: $file-drop-text-color;
      vertical-align: middle;
    }

    #{if(&, '&', '*')} .e-upload-files {
      border-top: $list-bottom-border;

      .e-upload-file-list {
        border-bottom: $list-bottom-border;

        .e-file-container {

          .e-file-name {
            color: $drop-text-color;
          }

          .e-file-type {
            color: $drop-text-color;
          }

          .e-file-size {
            color: $file-size-color;
          }

          .e-file-status,
          .e-file-information {
            color: $drop-text-color;
            @if $skin-name == 'FluentUI' or $skin-name == 'tailwind' {
              color: $file-status-color;
            }
          }

          .e-file-status.e-upload-success {
            color: $upload-success-color;
          }

          .e-file-status.e-upload-fails {
            color: $upload-failure-color;
          }

          .e-file-status.e-validation-fails {
            color: $upload-failure-color;
          }

          #{if(&, '&', '*')} .e-progress-inner-wrap {
            @if $skin-name != 'Material3' and $skin-name != 'fluent2' {
              background-color: $progress-background-color;
            }
            @if $skin-name == 'Material3' or $skin-name == 'fluent2' {
              background: $progress-background-color;
            }

            #{if(&, '&', '*')} .e-upload-progress-bar {

              #{if(&, '&', '*')}.e-upload-progress {
                background: $upload-in-progress-color;
              }

              #{if(&, '&', '*')}.e-upload-success {
                background: $upload-success-color;
              }

              #{if(&, '&', '*')}.e-upload-failed {
                background: $upload-failure-color;
              }
            }
          }

          #{if(&, '&', '*')} .e-progress-bar-text {
            color: $drop-text-color;
            @if $skin-name == 'tailwind' or $skin-name == 'FluentUI' {
              color: $progress-text-color;
            }
          }
        }
      }

      #{if(&, '&', '*')} .e-file-remove-btn.e-icons,
      #{if(&, '&', '*')} .e-file-delete-btn.e-icons,
      #{if(&, '&', '*')} .e-file-reload-btn.e-icons,
      #{if(&, '&', '*')} .e-file-abort-btn.e-icons,
      #{if(&, '&', '*')} .e-file-pause-btn.e-icons,
      #{if(&, '&', '*')} .e-file-play-btn.e-icons {
        color: $remove-icon-color;

        #{if(&, '&', '*')}:hover {
          color: $upload-hover-icon-color;
        }
      }

      #{if(&, '&', '*')} .e-file-remove-btn.e-icons:not(.e-upload-progress):focus,
      #{if(&, '&', '*')} .e-file-delete-btn.e-icons:not(.e-upload-progress):focus,
      #{if(&, '&', '*')} .e-file-pause-btn.e-icons:not(.e-upload-progress):focus,
      #{if(&, '&', '*')} .e-file-play-btn.e-icons:not(.e-upload-progress):focus,
      #{if(&, '&', '*')} .e-file-reload-btn.e-icons:focus,
      #{if(&, '&', '*')} .e-file-abort-btn.e-icons:not(.e-disabled):focus,
      #{if(&, '&', '*')} .e-clear-icon-focus {
        @if $skin-name != 'Material3' and $skin-name != 'fluent2' {
          background-color: $clear-icon-focus-color;
        }
        @if $skin-name == 'Material3' {
          background: $clear-icon-focus-color;
        }
        @if $skin-name == 'fluent2' {
          color: $primary;
        }
        border-color: transparent;
        box-shadow: 0 0 0 transparent;
      }
    }
  }

  .e-upload-drag-hover {
    @if $skin-name == 'FluentUI' or $skin-name == 'tailwind' {
      border-color: transparent;
    }
  }

  .e-upload .e-upload-actions .e-file-clear-btn.e-flat:not(:hover) {
    @if $skin-name == 'tailwind' {
      /* stylelint-disable property-no-vendor-prefix */
      -webkit-tap-highlight-color: $upload-btn-tap-highlight;
      background-color: $upload-flat-btn-bgcolor;
      border-color: $upload-flat-btn-border-color;
      box-shadow: $upload-flat-btn-box-shadow;
      color: $upload-flat-btn-color;
    }
  }

  .e-upload .e-upload-actions .e-file-upload-btn.e-flat.e-primary:not(:hover) {
    @if $skin-name == 'tailwind' {
      -webkit-tap-highlight-color: $upload-btn-tap-highlight;
      background-color: $upload-btn-primary-bgcolor;
      border-color: $upload-btn-primary-border-color;
      color: $upload-btn-primary-color;
    }
  }
}
