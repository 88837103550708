@include export-module('spinner-theme') {
  .e-spinner-pane {
    background-color: transparent;

    &.e-spin-overlay {
      background-color: $spin-overlay-background;

      .e-spinner-inner {

        .e-spin-label {
          color: $spin-overlay-font-color;
        }

        .e-spin-bootstrap {
          fill: $spin-overlay-stroke-color;
          stroke: $spin-overlay-stroke-color;
        }
      }
    }

    .e-spinner-inner {
      .e-spin-label {
        color: $spin-label-color;
      }

      .e-spin-fluent,
      .e-spin-fluent2,
      .e-spin-fabric {

        .e-path-circle {
          stroke: $spin-fabric-stroke-color;
        }

        .e-path-arc {
          stroke: $spin-fabric-arc-color;
        }
      }

      .e-spin-tailwind {
        .e-path-circle {
          stroke: $spin-tailwind-stroke-color;
        }

        .e-path-arc {
          stroke: $spin-tailwind-arc-color;
        }
      }

      .e-spin-bootstrap5,
      .e-spin-bootstrap5v3 {
        stroke: $spin-boot5-stroke-color;
      }

      .e-spin-high-contrast {
        .e-path-circle {
          stroke: $spin-fabric-stroke-color;
        }

        .e-path-arc {
          stroke: $spin-fabric-arc-color;
        }
      }

      .e-spin-material,
      .e-spin-material3 {
        stroke: $spin-material-stroke-color;
      }

      .e-spin-bootstrap4 {
        stroke: $spin-boot4-stroke-color;
      }

      .e-spin-bootstrap {
        fill: $spin-bootstrap-stroke-color;
        stroke: $spin-bootstrap-stroke-color;
      }
    }
  }
}
