.e-content-placeholder.e-textbox.e-placeholder-textbox {
  background-size: 300px 33px;
  min-height: 33px;
}

.e-input-group.e-input-focus.e-multi-line-input textarea.e-textbox {
  padding-right: 30px;
}

.e-input-group.e-input-focus.e-rtl.e-multi-line-input textarea.e-textbox {
  padding-left: 30px;
}
