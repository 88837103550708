//Mouse Mode
$toast-icon-nrml-height: 24px !default;
$toast-icon-nrml-width: 24px !default;
$toast-icon-nrml-size: 18px !default;
$toast-icon-nrml-right-margin: 14px !default;
$toast-close-icon-nrml-height: 30px !default;
$toast-close-icon-nrml-width: 30px !default;
$toast-close-icon-nrml-size: 12px !default;
$toast-position-nrml-distance: 10px !default;
$multitoast-nrml-gap-distance: 10px !default;
$toast-nrml-min-height: 48px !default;
$toast-nrml-padding: 14px !default;
$toast-action-btn-nrml-padding: 10px 0 0 0 !default;
$toast-action-btn-nrml-margin: 10px !default;
$toast-progressbar-nrml-height: 4px !default;
$toast-content-nrml-padding: 14px 0 10px 0 !default;
$toast-nrml-border-radious: 2px !default;
$toast-font-nrml-size: 14px !default;
$toast-title-font-nrml-size: 15px !default;

//Touch Mode
$toast-icon-bgr-height: 24px !default;
$toast-icon-bgr-width: 24px !default;
$toast-icon-bgr-size: 18px !default;
$toast-icon-bgr-right-margin: 14px !default;
$toast-close-icon-bgr-height: 30px !default;
$toast-close-icon-bgr-width: 30px !default;
$toast-close-icon-bgr-size: 14px !default;
$toast-position-bgr-distance: 10px !default;
$multitoast-bgr-gap-distance: 10px !default;
$toast-bgr-min-height: 48px !default;
$toast-bgr-padding: 14px !default;
$toast-action-btn-bgr-padding: 10px 0 0 0 !default;
$toast-action-btn-bgr-margin: 10px !default;
$toast-progressbar-bgr-height: 4px !default;
$toast-content-bgr-padding: 14px 0 10px 0 !default;
$toast-bgr-border-radious: 2px !default;
$toast-font-bgr-size: 14px !default;

$toast-title-font-weight: bold !default;

//theme variables
$toast-border: none !default;
$toast-box-shadow: 0 2px 5px 0 rgba($grey-black, .26) !default;
$toast-hover-box-shadow: 0 3px 6px 0 rgba($grey-black, .26) !default;
$toast-progress-color: $accent !default;
$toast-bg-color: $grey-dark !default;
$toast-hover-bg-color: $grey-900 !default;
$toast-active-bg-color: $grey-dark !default;
$toast-font-color: $grey-dark-font !default;
$toast-icon-color: $grey-dark-font !default;
$toast-close-icon-color: $grey-dark-font !default;
$toast-title-color: $grey-dark-font !default;
$toast-content-color: $grey-dark-font !default;

// Flat Button Customization

$toast-btn-flat-color: lighten($accent, 15%) !default;
$toast-btn-flat-bgcolor: transparent !default;
$toast-btn-flat-border-color: transparent !default;
$toast-btn-flat-box-shadow: none !default;
$toast-btn-flat-hover-color: $toast-btn-flat-color !default;
$toast-btn-flat-hover-bgcolor: transparent !default;
$toast-btn-flat-focus-color: $toast-btn-flat-color !default;
$toast-btn-flat-focus-bgcolor: transparent !default;
$toast-btn-flat-active-color: $toast-btn-flat-color !default;
$toast-btn-flat-active-bgcolor: transparent !default;

// Toast Types theme definitions

$toast-success-bg-color: #4d831e !default;
$toast-success-color: $toast-font-color !default;
$toast-success-title-color: $toast-success-color !default;
$toast-success-icon-color: $toast-success-color !default;
$toast-success-content-color: $toast-success-color !default;
$toast-success-hover-bg-color: $toast-success-bg-color !default;
$toast-success-hover-color: $toast-success-color !default;
$toast-success-hover-box-shadow: 0 3px 6px 0 rgba($grey-black, .26) !default;

$toast-info-bg-color: #0677d5 !default;
$toast-info-color: $toast-font-color !default;
$toast-info-title-color: $toast-info-color !default;
$toast-info-icon-color: $toast-info-color !default;
$toast-info-content-color: $toast-info-color !default;
$toast-info-hover-bg-color: $toast-info-bg-color !default;
$toast-info-hover-color: $toast-info-color !default;
$toast-info-hover-box-shadow: 0 3px 6px 0 rgba($grey-black, .26) !default;

$toast-warning-bg-color: #c15601 !default;
$toast-warning-color: $toast-font-color !default;
$toast-warning-title-color: $toast-warning-color !default;
$toast-warning-icon-color: $toast-warning-color !default;
$toast-warning-content-color: $toast-warning-color !default;
$toast-warning-hover-bg-color: $toast-warning-bg-color !default;
$toast-warning-hover-color: $toast-warning-color !default;
$toast-warning-hover-box-shadow: 0 3px 6px 0 rgba($grey-black, .26) !default;

$toast-danger-bg-color: #d74113 !default;
$toast-danger-color: $toast-font-color !default;
$toast-danger-title-color: $toast-danger-color !default;
$toast-danger-icon-color: $toast-danger-color !default;
$toast-danger-content-color: $toast-danger-color !default;
$toast-danger-hover-bg-color: $toast-danger-bg-color !default;
$toast-danger-hover-color: $toast-danger-color !default;
$toast-danger-hover-box-shadow: 0 3px 6px 0 rgba($grey-black, .26) !default;

$toast-title-font-weight: bold !default;

$toast-close-icon-hover-bg-color: $grey-300 !default;
$toast-close-icon-hover-color: $grey-light-font !default;
$toast-icon-hover-border-radius: 50% !default;
