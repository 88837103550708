@include export-module('otp-input-layout') {
  .e-otpinput {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: $otp-input-gap;
    flex-direction: row;
    &:has(.e-otp-separator):not(:empty) {
      gap: $otp-separator-input-gap;
    }
    .e-otp-input-field {
      min-width: $otp-input-width;
      min-height: $otp-input-height;
      text-align: center;
      padding: $otp-input-padding;
      font-size: $otp-input-font-size;
      font-weight: $otp-input-font-weight;
      line-height: $otp-input-line-height;
      border: none;
      &input[type = 'password'],
      &[type = 'password'] {
        font-size: $otp-input-password-font-size;
      }
      &.e-input.e-otp-input-focus:focus {
        padding-bottom: 0;
      }
    }
    &.e-outline {
      .e-otp-input-field {
        border-radius: $otp-input-border-radius-outlined;
      }
    }
    &.e-underlined {
      .e-otp-input-field {
        border-radius: $otp-input-border-radius-underlined;
      }
    }
    &.e-filled {
      .e-otp-input-field {
        border-radius: $otp-input-border-radius-filled;
        padding: 0;
      }
    }
    .e-otp-separator {
      font-size: $otp-separator-font-size;
      font-weight: $otp-separator-font-weight;
      line-height: $otp-separator-line-height;
    }
    input[type = 'number']::-webkit-inner-spin-button,
    [type = 'number']::-webkit-outer-spin-button {
      /* stylelint-disable property-no-vendor-prefix */
      -webkit-appearance: none;
      /* stylelint-enable property-no-vendor-prefix */
      margin: 0;
    }
    // Remove spin buttons in Firefox
    input[type = 'number'] {
      /* stylelint-disable property-no-vendor-prefix */
      -moz-appearance: textfield;
      /* stylelint-enable property-no-vendor-prefix */
    }
  }

  .e-small.e-otpinput,
  .e-small .e-otpinput {
    gap: $otp-small-input-gap;
    &:has(.e-otp-separator):not(:empty) {
      gap: $otp-small-separator-input-gap;
    }
    .e-otp-input-field {
      min-width: $otp-small-input-width;
      min-height: $otp-small-input-height;
      font-size: $otp-small-input-font-size;
      line-height: $otp-small-input-line-height;
      padding: $otp-input-padding;
      &input[type = 'password'],
      &[type = 'password'] {
        font-size: $otp-small-input-password-font-size;
      }
      &.e-input.e-otp-input-focus:focus {
        padding-bottom: 0;
      }
    }
    .e-otp-separator {
      font-size: $otp-small-separator-font-size;
      line-height: $otp-small-separator-line-height;
    }
    &.e-filled {
      .e-otp-input-field {
        padding: 0;
      }
    }
  }
}
