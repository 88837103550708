@use '@syncfusion/ej2-base/styles/material.scss' as base;
@use '@syncfusion/ej2-buttons/styles/material.scss' as buttons;
@use '@syncfusion/ej2-inputs/styles/material.scss' as inputs;
@use '@syncfusion/ej2-popups/styles/material.scss' as popups;
@use '@syncfusion/ej2-lists/styles/material.scss' as lists;
@use '@syncfusion/ej2-navigations/styles/material.scss' as navigations;
@use '@syncfusion/ej2-splitbuttons/styles/material.scss' as splitbuttons;
@use '@syncfusion/ej2-dropdowns/styles/material.scss' as dropdowns;
@use '@syncfusion/ej2-pdfviewer/styles/material.scss' as pdfviewer;
@use '@syncfusion/ej2-calendars/styles/material.scss' as calendars;
@use '@syncfusion/ej2-angular-calendars/styles/material.scss' as calendars-angular;
@use '@syncfusion/ej2-notifications/styles/material.scss' as notifications;
@use '@syncfusion/ej2-angular-grids/styles/material.scss' as grids;

@use '../src/app/styles/themes/themes.scss';
@use '../src/app/styles/syncfusion/syncfusion-overide.scss';

* {
  padding: 0;
  margin: 0;
  /* Ermöglicht Scrollen */
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Scrollbar-Track (Führungsschiene) */
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
    margin: 4px;
  }

  /* Scrollbar-Thumb (der bewegliche Teil) */
  &::-webkit-scrollbar-thumb {
    background: #ff4200;
    border-radius: 50px;
    border: 2px solid transparent;
    background-clip: padding-box;
  }

  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: #ff4200 transparent;

  /* IE */
  -ms-overflow-style: none;

  ::selection {
    background-color: #ff4500; /* Ihre gewünschte Farbe */
    color: white; /* Textfarbe der Markierung */
  }

  ::-moz-selection {
    background-color: #ff4500; /* Für Firefox */
    color: white;
  }

  /* Spezifisch für Syncfusion-Komponenten */
  .e-control ::selection {
    background-color: #ff4500 !important;
    color: white !important;
  }

  .e-control ::-moz-selection {
    background-color: #ff4500 !important;
    color: white !important;
  }
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: Arial, Helvetica, sans-serif;
  height: 100vh;
}

:root {
  $primary: rgba(254, 103, 49, 1);
  $primary-font: #fff;
  $accent: rgba(254, 103, 49, 1);
  $accent-font: #000000;

  $accent: #00b0ff !important;
  $accent-font: #000 !important;
  $primary: #3f51b5 !important;
  $primary-50: #e8eaf6 !important;
  $primary-100: #c5cae9 !important;
  $primary-200: #9fa8da !important;
  $primary-300: #7986cb !important;
  $primary-font: #fff !important;
  $primary-50-font: #000 !important;
  $primary-100-font: #000 !important;
  $primary-200-font: #000 !important;
  $primary-300-font: #fff !important;
  $grey-white: #fff !important;
  $grey-black: #000 !important;
  $grey-50: #fafafa !important;
  $grey-100: #f5f5f5 !important;
  $grey-200: #eee !important;
  $grey-300: #e0e0e0 !important;
  $grey-400: #bdbdbd !important;
  $grey-500: #9e9e9e !important;
  $grey-600: #757575 !important;
  $grey-700: #616161 !important;
  $grey-800: #424242 !important;
  $grey-900: #212121 !important;
  $grey-dark: #303030 !important;
  $grey-light-font: #000 !important;
  $grey-dark-font: #fff !important;
  $base-font: #000 !important;
  $success-bg: #4caf50 !important;
  $error-bg: #ff6652 !important;
  $warning-bg: #ff9800 !important;
  $info-bg: #03a9f4 !important;
  $message-font: #fff !important;
  $success-font: #4caf50 !important;
  $error-font: #ff6652 !important;
  $warning-font: #ff9800 !important;
  $info-font: #03a9f4 !important;
  $shadow: #000 !important;
  $skin-name: 'material-dark' !important;
  $theme-name: 'material-dark' !important;
}

.app-loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Light Mode (Standard) */
.app-loading {
  background: #ffffff;
  color: #1a1a1a;
}

.loader {
  border: 3px solid #ffffff;
  border-top: #ff4500;
}

/* Dark Mode (über System-Einstellungen) */
@media (prefers-color-scheme: dark) {
  .app-loading {
    background: #1a1a1a;
    color: #f5f5f5;
  }

  .loader {
    border: 3px solid #1a1a1a;
    border-top: 3px solid #ff4500;
  }
}

.loader {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader-text {
  margin-top: 1rem;
  font-family: sans-serif;
}
