@include export-module('context-menu-material-icons') {
  .e-contextmenu-wrapper,
  .e-contextmenu-container {
    @at-root {
      & ul {
        & .e-menu-item  {
          & .e-previous {
            &::before {
              content: '\e977';
            }
          }

          & .e-caret {
            &::before {
              content: '\e956';
            }
          }
        }
      }
    }
  }

  .e-rtl.e-contextmenu-wrapper .e-menu-item,
  .e-rtl.e-contextmenu-container .e-menu-item {
    & .e-caret {
      &::before {
        content: '\e937';
      }
    }
  }
}
