$otp-input-width: 32px !default;
$otp-input-height: 32px !default;
$otp-input-gap: 14px !default;
$otp-separator-input-gap: 6px !default;
$otp-input-padding: 0 !default;
$otp-input-font-size: 14px !default;
$otp-input-font-weight: 400 !default;
$otp-input-password-font-size: 18px !default;
$otp-input-line-height: 22px !default;
$otp-input-border-radius-outlined: 4px !default;
$otp-input-border-radius-underlined: 0 !default;
$otp-input-border-radius-filled: 4px 4px 0 0 !default;
$otp-separator-font-size: 14px !default;
$otp-separator-font-weight: 400 !default;
$otp-separator-line-height: 22px !default;

$otp-small-input-width: 26px !default;
$otp-small-input-height: 26px !default;
$otp-small-input-gap: 8px !default;
$otp-small-separator-input-gap: 4px !default;
$otp-small-input-font-size: 12px !default;
$otp-small-input-password-font-size: 18px !default;
$otp-small-input-line-height: 18px !default;
$otp-small-separator-font-size: 12px !default;
$otp-small-separator-line-height: 18px !default;

$otp-bigger-small-input-width: 36px !default;
$otp-bigger-small-input-height: 36px !default;
$otp-bigger-small-input-gap: 16px !default;
$otp-bigger-small-separator-input-gap: 6px !default;
$otp-bigger-small-input-font-size: 16px !default;
$otp-bigger-small-input-password-font-size: 20px !default;
$otp-bigger-small-input-line-height: 24px !default;
$otp-bigger-small-separator-font-size: 16px !default;
$otp-bigger-small-separator-line-height: 24px !default;

$otp-bigger-input-width: 40px !default;
$otp-bigger-input-height: 40px !default;
$otp-bigger-input-gap: 16px !default;
$otp-bigger-separator-input-gap: 8px !default;
$otp-bigger-input-font-size: 16px !default;
$otp-bigger-input-password-font-size: 20px !default;
$otp-bigger-input-line-height: 24px !default;
$otp-bigger-separator-font-size: 16px !default;
$otp-bigger-separator-line-height: 24px !default;

$otp-input-text-color: rgba($grey-light-font, .87) !default;
$otp-background-color: transparent !default;
$otp-input-border-color: $grey-400 !default;
$otp-input-border-bottom-color: $grey-400 !default;
$otp-input-filled-bg-color: $grey-100 !default;
$otp-input-error-color: $error-font !default;
$otp-input-success-color: $msg-success-color-alt1 !default;
$otp-input-warning-color: $msg-warning-color-alt1 !default;
$otp-input-separator-color: $grey-400 !default;
$otp-input-underlined-bg-color: transparent !default;
$otp-input-disabled-color: rgba($grey-light-font, .38) !default;
$otp-input-disabled-border-color: $grey-300 !default;
$otp-input-disabled-bg-color: $otp-background-color !default;
