@include export-module('daterangepicker-material-icons') {

  /*! component icons */
  .e-input-group-icon.e-range-icon,
  #{if(&, '&', '*')}.e-control-wrapper .e-input-group-icon.e-range-icon {
    font-size: $range-icon-font-size;
    margin: $range-icon-margin;
    min-height: $range-icon-container-min-height;
    min-width: $range-icon-container-min-width;
    outline: none;

    #{if(&, '&', '*')}::before {
      content: '\e245';
      font-family: 'e-icons';
    }

    #{if(&, '&', '*')}:focus {
      background: $range-icon-hover-color;
      border-radius: $range-icon-border-radius;
    }
  }

  .e-daterangepicker {
    #{if(&, '&', '*')} .e-calendar .e-header .e-date-icon-next::before {
      content: '\e913';
    }
    #{if(&, '&', '*')} .e-calendar .e-header .e-date-icon-prev::before {
      content: '\e921';
    }
    #{if(&, '&', '*')} .e-change-icon::before {
      content: '\e85f';
    }
    #{if(&, '&', '*')} .e-calendar.e-rtl .e-header .e-date-icon-next::before {
      content: '\e921';
    }
    #{if(&, '&', '*')} .e-calendar.e-rtl .e-header .e-date-icon-prev::before {
      content: '\e913';
    }
    #{if(&, '&', '*')}.e-rtl .e-start-end .e-change-icon::before {
      content: '\e85b';
    }
  }

  // mouse small icon
  .e-small .e-input-group-icon.e-range-icon,
  #{if(&, '&', '*')}.e-control-wrapper.e-small .e-input-group-icon.e-range-icon,
  #{if(&, '&', '*')}.e-small .e-control-wrapper .e-input-group-icon.e-range-icon {
    font-size: $range-small-icon-font-size;
  }

  // Outline textbox configuration

  .e-date-range-wrapper.e-outline .e-input-group-icon.e-range-icon,
  .e-date-range-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-range-icon {
    min-height: $zero-value;
    min-width: 20px;
  }

  .e-small .e-date-range-wrapper.e-outline .e-input-group-icon.e-range-icon,
  .e-date-range-wrapper.e-control-wrapper.e-small.e-outline .e-input-group-icon.e-range-icon,
  .e-small .e-date-range-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-range-icon {
    min-height: $zero-value;
    min-width: 18px;
  }

  .e-daterangepick-mob-popup-wrap {
    
    #{if(&, '&', '*')} .e-daterangepicker.e-popup-expand {
  
      #{if(&, '&', '*')} .e-range-header,
      #{if(&, '&', '*')} .e-model-header {
  
        #{if(&, '&', '*')} .e-popup-close::before {
          content: '\e932';
          font-family: 'e-icons';
        }

        #{if(&, '&', '*')} .e-apply::before {
          content: '\e7ff';
          font-family: 'e-icons';
        }
      }
    }
  }
}
