@import '../common/mixin.scss';

$accent: #e3165b !default;
$accent-font: #fff !default;

$primary: #3f51b5 !default;
$primary-50: #e8eaf6 !default;
$primary-100: #c5cae9 !default;
$primary-200: #9fa8da !default;
$primary-300: #7986cb !default;
$primary-font: #fff !default;
$primary-50-font: #000 !default;
$primary-100-font: #000 !default;
$primary-200-font: #000 !default;
$primary-300-font: #fff !default;
$grey-white: #fff !default;
$grey-black: #000 !default;
$grey-50: #fafafa !default;
$grey-100: #f5f5f5 !default;
$grey-200: #eee !default;
$grey-300: #e0e0e0 !default;
$grey-400: #bdbdbd !default;
$grey-500: #9e9e9e !default;
$grey-600: #757575 !default;
$grey-700: #616161 !default;
$grey-800: #424242 !default;
$grey-900: #212121 !default;
$grey-dark: #303030 !default;
$grey-light-font: #000 !default;
$grey-dark-font: #fff !default;
$base-font: #000 !default;
$error-font: #f44336 !default;
$shadow: #000 !default;
$skin-name: 'material' !default;
$theme-name: 'material' !default;

$msg-color: $grey-900 !default;
$msg-bg-color: $grey-200 !default;
$msg-border-color: $grey-200 !default;
$msg-color-alt1: $grey-900 !default;
$msg-bg-color-alt1: transparent !default;
$msg-border-color-alt1: $grey-400 !default;
$msg-color-alt2: $grey-white !default;
$msg-bg-color-alt2: $grey-900 !default;
$msg-border-color-alt2: $grey-900 !default;

$msg-icon-color: $grey-900 !default;
$msg-icon-color-alt1: $grey-900 !default;
$msg-icon-color-alt2: $grey-white !default;

$msg-close-icon-color: $grey-800 !default;
$msg-close-icon-color-alt1: $grey-800 !default;
$msg-close-icon-color-alt2: $grey-white !default;

$msg-danger-color-alt1:#d74113 !default;
$msg-danger-bg-color-alt1: transparent !default;
$msg-danger-border-color-alt1: $msg-danger-color-alt1 !default;
$msg-danger-color: $grey-900 !default;
$msg-danger-bg-color: lighten($msg-danger-color-alt1, 50%) !default;
$msg-danger-border-color: lighten($msg-danger-color-alt1, 50%) !default;
$msg-danger-color-alt2: $grey-white !default;
$msg-danger-bg-color-alt2: darken($msg-danger-color-alt1, 20%) !default;
$msg-danger-border-color-alt2: darken($msg-danger-color-alt1, 20%) !default;

$msg-danger-icon-color: #d74113 !default;
$msg-danger-icon-color-alt1: #d74113 !default;
$msg-danger-icon-color-alt2: $grey-white !default;

$msg-danger-close-icon-color: $grey-800 !default;
$msg-danger-close-icon-color-alt1: $grey-800 !default;
$msg-danger-close-icon-color-alt2: $grey-white !default;

$msg-success-color-alt1: #4d831e !default;
$msg-success-bg-color-alt1: transparent !default;
$msg-success-border-color-alt1: $msg-success-color-alt1 !default;
$msg-success-color: $grey-900 !default;
$msg-success-bg-color: lighten($msg-success-color-alt1, 50%) !default;
$msg-success-border-color: lighten($msg-success-color-alt1, 50%) !default;
$msg-success-color-alt2: $grey-white !default;
$msg-success-bg-color-alt2: darken($msg-success-color-alt1, 10%) !default;
$msg-success-border-color-alt2: darken($msg-success-color-alt1, 10%) !default;

$msg-success-icon-color: #4d831e !default;
$msg-success-icon-color-alt1: #4d831e !default;
$msg-success-icon-color-alt2: $grey-white !default;

$msg-success-close-icon-color: $grey-800 !default;
$msg-success-close-icon-color-alt1: $grey-800 !default;
$msg-success-close-icon-color-alt2: $grey-white !default;

$msg-warning-color-alt1: #c15601 !default;
$msg-warning-bg-color-alt1: transparent !default;
$msg-warning-border-color-alt1: $msg-warning-color-alt1 !default;
$msg-warning-color: $grey-900 !default;
$msg-warning-bg-color: lighten($msg-warning-color-alt1, 50%) !default;
$msg-warning-border-color: lighten($msg-warning-color-alt1, 50%) !default;
$msg-warning-color-alt2: $grey-white !default;
$msg-warning-bg-color-alt2: darken($msg-warning-color-alt1, 10%) !default;
$msg-warning-border-color-alt2: darken($msg-warning-color-alt1, 10%) !default;

$msg-warning-icon-color: #c15601 !default;
$msg-warning-icon-color-alt1: #c15601 !default;
$msg-warning-icon-color-alt2: $grey-white !default;

$msg-warning-close-icon-color: $grey-800 !default;
$msg-warning-close-icon-color-alt1: $grey-800 !default;
$msg-warning-close-icon-color-alt2: $grey-white !default;

$msg-info-color-alt1: #0677d5 !default;
$msg-info-bg-color-alt1: transparent !default;
$msg-info-border-color-alt1: $msg-info-color-alt1 !default;
$msg-info-color: $grey-900 !default;
$msg-info-bg-color: lighten($msg-info-color-alt1, 50%) !default;
$msg-info-border-color: lighten($msg-info-color-alt1, 50%) !default;
$msg-info-color-alt2: $grey-white !default;
$msg-info-bg-color-alt2: darken($msg-info-color-alt1, 15%) !default;
$msg-info-border-color-alt2: darken($msg-info-color-alt1, 15%) !default;

$msg-info-icon-color: #0677d5 !default;
$msg-info-icon-color-alt1: #0677d5 !default;
$msg-info-icon-color-alt2: $grey-white !default;

$msg-info-close-icon-color: $grey-800 !default;
$msg-info-close-icon-color-alt1: $grey-800 !default;
$msg-info-close-icon-color-alt2: $grey-white !default;

$appbar-bg-color-alt1: $grey-white !default;
$appbar-color-alt1: $grey-black !default;
$appbar-border-color-alt1: $grey-white !default;
$appbar-hover-bg-color-alt1: rgba(transparent, .056) !default;

$appbar-bg-color-alt2: $grey-900 !default;
$appbar-color-alt2: $grey-white !default;
$appbar-border-color-alt2: $grey-900 !default;
$appbar-hover-bg-color-alt2: rgba(255, 255, 255, .08) !default;

$appbar-bottom-shadow: 0 2px 4px rgba(0, 0, 0, .1), 0 2px 6px rgba(0, 0, 0, .16) !default;
$appbar-top-shadow: 0 -2px 3px rgba(0, 0, 0, .1), 0 -2px 6px rgba(0, 0, 0, .2) !default;

$font-family: 'Roboto', 'Segoe UI', 'GeezaPro', 'DejaVu Serif', 'sans-serif', '-apple-system', 'BlinkMacSystemFont' !default;

$font-size: 12px !default;
$font-weight: normal !default;
$font-path: 'common-images' !default;
$header-bg-color: $grey-white !default;
$header-gradient-color: none !default;
$header-border-color: $grey-black !default;
$header-font-color: $grey-light-font !default;
$header-icon-color: $grey-light-font !default;
$content-bg-color: $grey-white !default;
$content-border-color: $grey-black !default;
$content-font-color: $grey-light-font !default;
$default-bg-color: $grey-white !default;
$default-gradient-color: none !default;
$default-border-color: $grey-black !default;
$default-font-color: $grey-light-font !default;
$default-icon-color: $grey-light-font !default;
$hover-bg-color: $grey-200 !default;
$hover-gradient-color: none !default;
$hover-border-color: $grey-black !default;
$hover-font-color: $grey-light-font !default;
$hover-icon-color: $grey-light-font !default;
$active-bg-color: $grey-100 !default;
$active-gradient-color: none !default;
$active-border-color: $grey-black !default;
$active-font-color: $grey-light-font !default;
$active-icon-color: $grey-light-font !default;
$error-font-color: $error-font !default;
$warning-font-color: #ffca1c !default;
$success-font-color: #22b24b !default;
$information-font-color: #489bd5 !default;
$overlay-bg-color: #383838 !default;
$shadow-color: rgba(0, 0, 0, .4) !default;
$border-size: 1px !default;
$border-type: solid !default;
$border-radius: 0 !default;
$border-left-radius: 0 !default;
$border-right-radius: 0 !default;
$border-top-radius: 0 !default;
$border-bottom-radius: 0 !default;
