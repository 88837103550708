@mixin shimmer-animation($animation-name) {
  animation: $animation-name $shimmer-effect-duration infinite;
  animation-timing-function: $shimmer-effect-timing-function;
}

@include export-module('skeleton-layout') {
  .e-skeleton {
    display: inline-block;
    position: relative;
    overflow: hidden;
    border: $skeleton-boder;

    @at-root {
      &.e-skeleton-text,
      &.e-skeleton-square,
      &.e-skeleton-rectangle {
        border-radius: $skeleton-border-radius;
      }

      @if ($skin-name == 'highcontrast' or $skin-name == 'highcontrast-light') {
        &.e-skeleton-text {
          border-width: $skeleton-text-boder-width;
        }
      }

      &.e-skeleton-circle {
        border-radius: 50%;
      }

      &.e-visible-none {
        display: none;
      }

      &.e-shimmer-wave::after {
        @include shimmer-animation(e-shimmer-wave);
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: calc(200% + 200px);
      }

      &.e-shimmer-fade {
        @include shimmer-animation(e-shimmer-fade);
      }

      &.e-shimmer-pulse {
        @include shimmer-animation(e-shimmer-pulse);
      }
    }
  }

  .e-rtl .e-shimmer-wave::after,
  .e-rtl.e-shimmer-wave::after {
    animation: e-shimmer-wave-rtl $shimmer-effect-duration infinite;
  }

  @keyframes e-shimmer-wave {
    to {
      transform: translateX(calc(50% + 100px));
    }
  }

  @keyframes e-shimmer-wave-rtl {
    from {
      transform: translateX(calc(50% + 100px));
    }
  }

  @keyframes e-shimmer-fade {
    0% {
      opacity: 1;
    }
    50% {
      opacity: .4;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes e-shimmer-pulse {
    0% {
      transform: scale(1);
    }
    40% {
      transform: scale(1);
    }
    50% {
      transform: scale(.975);
    }
    100% {
      transform: scale(1);
    }
  }
}
