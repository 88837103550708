// Button

.e-btn {
  background-color: var(--button-background) !important;
  color: var(--button-text) !important;
  width: auto;

  &:hover {
    background-color: var(--button-hover) !important;
  }

  &:disabled {
    color: var(--button-disabled-text) !important;
    background-color: var(--button-disabled-background) !important;
  }

  &.alternate {
    color: var(--button-alt-text) !important;
    background-color: var(--button-alt-background) !important;

    span {
      background-color: var(--button-alt-background) !important;
    }

    &:disabled {
      color: var(--button-disabled-alt-text) !important;
      background-color: var(--button-disabled-alt-background) !important;
    }
  }

  &.is-icon {
    background: transparent !important;
    width: 40px !important;
    height: 40px !important;
    box-shadow: none !important;
    padding: 0;

    &.default {
      span {
        color: var(--primary-color) !important;
        font-size: 1.4em !important;
      }
    }

    &.alternate {
      span {
        color: var(--button-alt-text) !important;
        font-size: 1.4em !important;
      }
    }

    &:hover {
      text-shadow: 1px 1px 1px var(--border-color);
    }

    &:disabled {
      &:hover {
        text-shadow: none;
      }

      span {
        color: var(--button-disabled-alt-text) !important;
      }
    }
  }
}
