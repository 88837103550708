@include export-module('maskedtextbox-layout') {

  /*! maskedtextbox layout */
  .e-control-wrapper.e-mask #{&}.e-maskedtextbox {
    @if $skin-name != 'bootstrap' {
      font-family: $mask-font-family;
      font-size: $mask-font-size;
    }
  }

  .e-content-placeholder.e-mask.e-placeholder-mask {
    background-size: 300px 33px;
    min-height: 33px;
  }
}
