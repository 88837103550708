$dialog-btn-bg-bgcolor: transparent !default;
$dialog-btn-box-shadow: none !default;
$dialog-bg-color: $grey-white !default;
$dialog-icon-color: $grey-light-font !default;
$dialog-active-icon-color: $grey-light-font !default;
$dialog-close-button-left: 0 !default;
$dialog-close-button-right: 8px !default;
$dialog-header-border: none !default;
$dialog-border: none !default;
$dialog-primary-button-color:  #e3165b !default;
$dialog-secondary-button-color: #333333 !default;
$dialog-footer-content-border-top: none !default;
$dialog-alert-footer-content-border-top: none !default;
$dialog-close-icon-normal-opacity: initial !default;
$dialog-close-icon-active-opacity: initial !default;
$dialog-close-icon-font-size: 12px !default;
$header-content-border-radius: 1px 1px 0 0 !default;
$dialog-header-line-height: 30px !default;
$dialog-content-padding-top: 0 !default;
$dialog-bigger-content-padding-top: 0 !default;
$footer-content-border-radius: 0 !default;
$dialog-close-icon-button-padding: 0 12px !default;
$dialog-bigger-close-icon-button-padding: 0 12px !default;
$dialog-bigger-close-icon-font-size: 12px !default;
$dialog-hover-icon-color: $grey-light-font !default;
$dialog-icon-bg-normal: $grey-white !default;
$dialog-header-bg-color: $grey-white !default;
$dialog-content-bg-color: $grey-white !default;
$dialog-footer-bg-color: $grey-white !default;
$dialog-shadow-color: 0 11px 15px -7px rgba($grey-light-font, .2), 0 24px 38px 3px rgba($grey-light-font, .14), 0 9px 46px 8px rgba($grey-light-font, .12) !default;
$dialog-header-font-size: 18px !default;
$dialog-header-font-weight: normal !default;
$dialog-overlay-bg-color: #383838 !default;
$dialog-overlay-opacity: .5 !default;
$dialog-header-font-color: rgba($grey-light-font, .87) !default;
$dialog-content-font-size: 13px !default;
$dialog-content-line-height: normal !default;
$dialog-content-font-weight: normal !default;
$dialog-device-content-font-size: 14px !default;
$dialog-content-font-color: rgba($grey-light-font, .87) !default;
$dialog-border-radius: 2px !default;
$dialog-header-icon-hover-border-radius: 50% !default;
$dialog-closeicon-btn-hover-color: transparent !default;
$dialog-closeicon-btn-active-color: transparent !default;
$dialog-close-button-height: 30px !default;
$dialog-close-button-width: 30px !default;
$dialog-header-content-padding: 18px !default;
$dialog-content-padding: 18px !default;
$dialog-footer-content-padding: 8px !default;
$dialog-bigger-header-content-padding: 24px 24px 20px !default;
$dialog-bigger-content-padding: 24px !default;
$dialog-bigger-footer-content-padding: 8px !default;
$dialog-footer-button-margin-left: 6px !default;
$dialog-bigger-footer-button-margin-left: 8px !default;
$dialog-footer-button-margin-right: 6px !default;
$dialog-bigger-footer-button-margin-right: 8px !default;
$dialog-close-icon-margin-left: -4px !default;
$dialog-close-icon-height: 24px !default;
$dialog-close-icon-width: auto !default;
$dialog-bigger-close-icon-height: 34px !default;
$dialog-bigger-close-icon-width: auto !default;
$dialog-close-icon-top: 0 !default;
$dialog-bigger-header-content-font-size: 18px !default;
$dialog-bigger-content-font-size: 13px !default;
$dialog-bigger-close-icon-top: -1px !default;
$dialog-bigger-close-icon-margin-left: -5px !default;
$dialog-bigger-close-icon-margin-right: -5px !default;
$dialog-bigger-close-button-bottom: 3px !default;
$dialog-bigger-close-button-height: 36px !default;
$dialog-bigger-close-button-left: 3px !default;
$dialog-bigger-close-button-width: 36px !default;
$dialog-btn-border-color: transparent !default;
$dialog-btn-bg-color: transparent !default;
$dialog-active-btn-border-color: transparent !default;
$dialog-hover-focus-btn-bg-color: $grey-300 !default;
$dialog-active-btn-bg-color: $grey-300 !default;
$dialog-headericon-bottom: 0 !default;
$dialog-resize-handler-width: 15px;
$dialog-resize-handler-height: 15px;
$dialog-resize-handler-position: 0;
$resize-hanlder-bottom-rtl: 2px;
$dialog-enable-resize-padding-bottom: 15px !default;
$dialog-footer-primary-hover-btn-bg-color: rgba($accent, .1) !default;
$dialog-footer-flat-hover-btn-bg-color: $grey-300 !default;
$dialog-footer-primary-focus-btn-bg-color: rgba($accent, .2) !default;
$dialog-footer-primary-active-btn-bg-color: rgba($accent, .3) !default;
$dialog-footer-flat-active-btn-bg-color: $grey-400 !default;
