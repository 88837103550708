// Dimension variables
$msg-font-family: $font-family !default;
$msg-font-weight: 400 !default;
$msg-font-style: normal !default;
$msg-padding: 9px 7px !default;
$msg-border-radius: 4px !default;
$msg-icon-size: 16px !default;
$msg-icon-margin: 3px 4px !default;
$msg-close-icon-margin: 0 0 0 auto !default;
$msg-close-icon-size: 14px !default;
$msg-close-icon-padding: 3px 4px !default;
$msg-close-icon-height: 22px !default;
$msg-content-font-size: 14px !default;
$msg-content-line-height: 22px !default;
$msg-content-padding: 0 4px !default;

$msg-rtl-close-icon-margin: 0 auto 0 0 !default;

$msg-bigger-padding: 11px 9px !default;
$msg-bigger-icon-size: 18px !default;
$msg-bigger-icon-margin: 3px 6px !default;
$msg-bigger-close-icon-margin: 0 2px 0 auto !default;
$msg-bigger-close-icon-padding: 3px 4px !default;
$msg-bigger-close-icon-height: 24px !default;
$msg-bigger-close-icon-size: 16px !default;
$msg-bigger-content-font-size: 16px !default;
$msg-bigger-content-line-height: 24px !default;
$msg-bigger-content-padding: 0 6px !default;

$msg-bigger-rtl-close-icon-margin: 0 auto 0 2px !default;

// Color variables
// Text
$msg-color: $msg-color !default;
$msg-bg-color: $msg-bg-color !default;
$msg-border-color: $msg-border-color !default;
$msg-success-color: $msg-success-color !default;
$msg-success-bg-color: $msg-success-bg-color !default;
$msg-success-border-color: $msg-success-border-color !default;
$msg-warning-color: $msg-warning-color !default;
$msg-warning-bg-color: $msg-warning-bg-color !default;
$msg-warning-border-color: $msg-success-border-color !default;
$msg-info-color: $msg-info-color !default;
$msg-info-bg-color: $msg-info-bg-color !default;
$msg-info-border-color: $msg-info-border-color !default;
$msg-error-color: $msg-danger-color !default;
$msg-error-bg-color: $msg-danger-bg-color !default;
$msg-error-border-color: $msg-danger-border-color !default;

$msg-icon-color: $msg-icon-color !default;
$msg-success-icon-color: $msg-success-icon-color !default;
$msg-warning-icon-color: $msg-warning-icon-color !default;
$msg-info-icon-color: $msg-info-icon-color !default;
$msg-error-icon-color: $msg-danger-icon-color !default;

$msg-close-icon-color: $msg-close-icon-color !default;
$msg-close-icon-hover-bg-color: rgba($msg-close-icon-color, .12) !default;
$msg-success-close-icon-color: $msg-success-close-icon-color !default;
$msg-success-close-icon-hover-bg-color: rgba($msg-success-close-icon-color, .12) !default;
$msg-warning-close-icon-color: $msg-warning-close-icon-color !default;
$msg-warning-close-icon-hover-bg-color: rgba($msg-warning-close-icon-color, .12) !default;
$msg-info-close-icon-color: $msg-info-close-icon-color !default;
$msg-info-close-icon-hover-bg-color: rgba($msg-info-close-icon-color, .12) !default;
$msg-error-close-icon-color: $msg-danger-close-icon-color !default;
$msg-error-close-icon-hover-bg-color: rgba($msg-error-close-icon-color, .12) !default;

// Outlined
$msg-outline-color: $msg-color-alt1 !default;
$msg-outline-bg-color: $msg-bg-color-alt1 !default;
$msg-outline-border-color: $msg-border-color-alt1 !default;
$msg-outline-success-color: $msg-success-color-alt1 !default;
$msg-outline-success-border-color: $msg-success-border-color-alt1 !default;
$msg-outline-warning-color: $msg-warning-color-alt1 !default;
$msg-outline-warning-border-color: $msg-warning-border-color-alt1 !default;
$msg-outline-info-color: $msg-info-color-alt1 !default;
$msg-outline-info-border-color: $msg-info-border-color-alt1 !default;
$msg-outline-error-color: $msg-danger-color-alt1 !default;
$msg-outline-error-border-color: $msg-danger-border-color-alt1 !default;

$msg-outline-icon-color: $msg-icon-color-alt1 !default;
$msg-outline-success-icon-color: $msg-success-icon-color-alt1 !default;
$msg-outline-warning-icon-color: $msg-warning-icon-color-alt1 !default;
$msg-outline-info-icon-color: $msg-info-icon-color-alt1 !default;
$msg-outline-error-icon-color: $msg-danger-icon-color-alt1 !default;

$msg-outline-close-icon-color: $msg-close-icon-color-alt1 !default;
$msg-outline-close-icon-hover-bg-color: rgba($msg-outline-close-icon-color, .12) !default;
$msg-outline-success-close-icon-color: $msg-success-close-icon-color-alt1 !default;
$msg-outline-success-close-icon-hover-bg-color: rgba($msg-outline-success-close-icon-color, .12) !default;
$msg-outline-warning-close-icon-color: $msg-warning-close-icon-color-alt1 !default;
$msg-outline-warning-close-icon-hover-bg-color: rgba($msg-outline-warning-close-icon-color, .12) !default;
$msg-outline-info-close-icon-color: $msg-info-close-icon-color-alt1 !default;
$msg-outline-info-close-icon-hover-bg-color: rgba($msg-outline-info-close-icon-color, .12) !default;
$msg-outline-error-close-icon-color: $msg-danger-close-icon-color-alt1 !default;
$msg-outline-error-close-icon-hover-bg-color: rgba($msg-outline-error-close-icon-color, .12) !default;

// Filled
$msg-filled-color: $msg-color-alt2 !default;
$msg-filled-bg-color: $msg-bg-color-alt2 !default;
$msg-filled-border-color: $msg-border-color-alt2 !default;
$msg-filled-success-color: $msg-success-color-alt2 !default;
$msg-filled-success-bg-color: $msg-success-bg-color-alt2 !default;
$msg-filled-success-border-color: $msg-success-border-color-alt2 !default;
$msg-filled-warning-color: $msg-warning-color-alt2 !default;
$msg-filled-warning-bg-color:$msg-warning-bg-color-alt2 !default;
$msg-filled-warning-border-color: $msg-warning-border-color-alt2 !default;
$msg-filled-info-color: $msg-info-color-alt2 !default;
$msg-filled-info-bg-color: $msg-info-bg-color-alt2 !default;
$msg-filled-info-border-color: $msg-info-border-color-alt2 !default;
$msg-filled-error-color: $msg-danger-color-alt2 !default;
$msg-filled-error-bg-color: $msg-danger-bg-color-alt2 !default;
$msg-filled-error-border-color: $msg-danger-border-color-alt2 !default;

$msg-filled-icon-color: $msg-icon-color-alt2 !default;
$msg-filled-success-icon-color: $msg-success-icon-color-alt2 !default;
$msg-filled-warning-icon-color: $msg-warning-icon-color-alt2 !default;
$msg-filled-info-icon-color: $msg-info-icon-color-alt2 !default;
$msg-filled-error-icon-color: $msg-danger-icon-color-alt2 !default;

$msg-filled-close-icon-color: $msg-close-icon-color-alt2 !default;
$msg-filled-close-icon-hover-bg-color: rgba($msg-filled-close-icon-color, .12) !default;
$msg-filled-success-close-icon-color: $msg-success-close-icon-color-alt2 !default;
$msg-filled-success-close-icon-hover-bg-color: rgba($msg-filled-success-close-icon-color, .12) !default;
$msg-filled-warning-close-icon-color: $msg-warning-close-icon-color-alt2 !default;
$msg-filled-warning-close-icon-hover-bg-color: rgba($msg-filled-warning-close-icon-color, .12) !default;
$msg-filled-info-close-icon-color: $msg-info-close-icon-color-alt2 !default;
$msg-filled-info-close-icon-hover-bg-color: rgba($msg-filled-info-close-icon-color, .12) !default;
$msg-filled-error-close-icon-color: $msg-danger-close-icon-color-alt2 !default;
$msg-filled-error-close-icon-hover-bg-color: rgba($msg-filled-error-close-icon-color, .12) !default;
