// Variables für die Platzhalter-Opacity
$placeholder-opacity: 0.5;

// Mixin für Placeholder-Styles
@mixin placeholder-styles {
  color: var(--text-color);
  opacity: $placeholder-opacity;
}

// Dropdown Base Styles
.e-dropdownbase {
  background-color: var(--input-background) !important;
  color: var(--text-color) !important;

  .e-ul {
    background-color: var(--input-background);
    color: var(--text-color) !important;
  }

  .e-list-item {
    color: var(--text-color) !important;
    background-color: var(--header-item-background) !important;

    &:hover {
      background-color: var(--header-item-hover-background) !important;
    }

    &.e-active {
      color: var(--primary-color) !important;
    }
  }
}

// Gemeinsame Styles für Dropdown, ComboBox und MultiSelect
%shared-input-styles {
  background-color: var(--input-background);
  border-color: var(--input-border);

  .e-input {
    color: var(--text-color) !important;

    &::placeholder {
      @include placeholder-styles;
    }

    &::-webkit-input-placeholder {
      @include placeholder-styles;
    }

    &::-moz-placeholder {
      @include placeholder-styles;
    }

    &:-ms-input-placeholder {
      @include placeholder-styles;
    }

    &:-moz-placeholder {
      @include placeholder-styles;
    }
  }
}

// Anwendung der gemeinsamen Styles
.e-input-group.e-ddl,
.e-multiselect.e-input-group {
  @extend %shared-input-styles;
}
