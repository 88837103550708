@include export-module('dropdownlist-theme') {
  #{&}.e-popup {
    border-color: $ddl-default-border-color;
  }

  .e-float-input.e-input-group.e-ddl.e-control.e-icon-anim > .e-float-text,
  .e-float-input.e-input-focus.e-input-group.e-ddl.e-control.e-keyboard > .e-float-text {
    color: $ddl-active-font-color;
  }

  .e-ddl.e-popup .e-filter-parent .e-input-group input.e-input-filter {
    border-color: transparent;
    @if $skin-name == 'bootstrap4' or $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' or $skin-name == 'highcontrast' {
      background: none;
    }
  }
}
