// Variables
$placeholder-opacity: 0.5;

// Mixins
@mixin placeholder-styles {
  color: var(--text-color);
  opacity: $placeholder-opacity;
}

@mixin float-line-styles {
  background: var(--primary-color) !important;
}

@mixin input-base-styles {
  color: var(--text-color);
  background-color: var(--input-background);
}

// Placeholder Selektoren für Browser-Kompatibilität
%placeholder-selectors {
  &::placeholder {
    @include placeholder-styles;
  }

  &::-webkit-input-placeholder {
    @include placeholder-styles;
  }

  &::-moz-placeholder {
    @include placeholder-styles;
  }

  &:-ms-input-placeholder {
    @include placeholder-styles;
  }

  &:-moz-placeholder {
    @include placeholder-styles;
  }
}

// Haupt Input-Styles
.e-input-group {
  width: 100% !important;
  border-bottom: 1px solid var(--input-border) !important;

  &:not(.e-float-input) {
    &::after {
      border-color: var(--primary-color) !important;
      background-color: var(--primary-color) !important;
    }

    &::before {
      border-color: var(--primary-color) !important;
      background-color: var(--primary-color) !important;
    }
  }

  &,
  &.e-control-wrapper {
    @include input-base-styles;
    background-color: var(--input-background) !important;

    // Float Line Styles
    .e-float-line {
      @include float-line-styles;

      &::before,
      &::after {
        @include float-line-styles;
      }
    }

    // Input und Textarea Styles
    input,
    textarea {
      @include input-base-styles;
      @extend %placeholder-selectors;
      padding-left: 5px;
    }

    // Hover und Focus States
    &:hover:not(.e-disabled),
    &.e-input-focus:not(.e-disabled) {
      border-color: var(--input-focus) !important;
    }

    // Icon Styles
    .e-input-group-icon {
      color: var(--text-color) !important;
    }
  }
}

// Animated Icon Styles
.e-input-group.e-icon-anim {
  .e-input-group-icon {
    color: var(--primary-color) !important;
  }
}

// Float Input Spezifische Styles
.e-float-input {
  &.e-control-wrapper.e-input-group:not(.e-float-icon-left) {
    .e-float-line {
      &::before,
      &::after {
        border-color: var(--primary-color) !important;
        background-color: var(--primary-color) !important;
      }
    }
  }

  &:not(.e-error).e-input-focus {
    input ~ label.e-float-text {
      color: var(--primary-color) !important;
    }
  }
}

// Label Styles
input ~ label {
  color: var(--input-label-color) !important;
  padding-left: 5px;
}

label.e-float-text,
.e-float-input:not(.e-error):not(.e-input-focus)
  input:not(:focus):valid
  ~ label.e-float-text.e-label-bottom,
.e-float-input.e-control-wrapper:not(.e-error):not(.e-input-focus)
  input:not(:focus):valid
  ~ label.e-float-text.e-label-bottom {
  padding-left: 5px !important;
}

.e-control input.e-input,
.e-control .e-input-group input,
.e-control .e-input-group input.e-input,
.e-control .e-input-group.e-control-wrapper input,
.e-control .e-input-group.e-control-wrapper input.e-input {
  padding-left: 5px !important;
}

.e-input-group,
.e-input-group.e-control-wrapper,
.e-float-input,
.e-float-input.e-input-group,
.e-float-input.e-control-wrapper,
.e-float-input.e-input-group.e-control-wrapper {
  color: var(--text-color);
}

.ng-pristine,
.ng-valid,
.e-control,
.e-textbox,
.e-lib,
.ng-touched {
  color: var(--text-color) !important;
}
