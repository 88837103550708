.e-menu {
  .e-menu-item {
    color: var(--text-color);
    background-color: var(--dropdown-item-background);

    &:hover {
      background-color: var(--dropdown-item-hover-background);
    }
  }
}
