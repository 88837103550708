﻿@mixin chip-style($bg-color, $close-color, $font-color) {

  @if $ddt-skin-name == 'Material3' {
    background: $bg-color;
  }
  @else {
    background-color: $bg-color;
  }
  .e-chips-close::before {
    color: $close-color;
  }

  > .e-chipcontent {
    color: $font-color;
  }
}

@include export-module('dropdowntree-theme') {

  .e-ddt {

    .e-chips {
      @include chip-style($ddt-chip-bg-color, $ddt-chip-close, $ddt-chip-font-color);
      @if $ddt-skin-name == 'Material3' or $theme-name == 'fluent2-highcontrast' {
        border-color: $ddt-chip-border-color;
        border: 1px solid;
      }
      &:hover {
        @if $ddt-skin-name == 'highcontrast' {
          @include chip-style($ddt-chip-hover-bg-color, $ddt-chip-hover-font-color, $ddt-chip-hover-font-color);
        }
        @if $ddt-skin-name == 'tailwind' {
          @include chip-style($ddt-chip-hover-bg-color, $icon-color-hover, $ddt-chip-hover-font-color);
        }
      }
    }

    @if $ddt-skin-name == 'material' or $ddt-skin-name == 'material-dark' {
      &.e-filled {
        .e-chips {
          background-color: $ddt-filled-chip-bg-color;
        }
      }
    }

    @if $ddt-skin-name == 'tailwind' {
      &.e-disabled {
        @include chip-style($content-bg-color-alt3, $content-text-color-disabled, $content-text-color-disabled);
      }
    }

    .e-overflow {

      @if $skin-name == 'bootstrap4' {
        color: $ddt-remains-font-color;
      }

      .e-remain {
        color: $ddt-remains-font-color;
      }
    }

    &.e-input-group.e-control-wrapper,
    &.e-float-input.e-control-wrapper {
      .e-clear-icon {
        @if $ddt-skin-name != 'tailwind' and $ddt-skin-name != 'bootstrap5' and $ddt-skin-name != 'bootstrap5.3' and $ddt-skin-name != 'material' and  $ddt-skin-name != 'material-dark' and $ddt-skin-name != 'FluentUI' and $ddt-skin-name != 'Material3' and $ddt-skin-name != 'fluent2' {
          background-color: $ddt-icon-bg-color;
        }
      }
    }

    @if ($ddt-skin-name == 'fluent2') {
      &.e-input-group.e-control-wrapper:focus,
      &.e-float-input.e-control-wrapper:focus {
        border-color: $ddt-input-border-color;
      }
  
      &.e-input-group.e-control-wrapper:active,
      &.e-float-input.e-control-wrapper:active {
        border-color: $ddt-input-border-color;
      }
    }

    &.e-input-group.e-control-wrapper .e-input[readonly],
    &.e-float-input.e-control-wrapper input[readonly] {
      @if $ddt-skin-name == 'bootstrap4' or $ddt-skin-name == 'bootstrap5' or $ddt-skin-name == 'bootstrap5.3' or $ddt-skin-name == 'FluentUI' {
        @if $ddt-skin-name == 'FluentUI' {
          background: transparent;
        }
        @else {
          background: $ddt-readonly-input-bg-color;
        }

        @if $ddt-skin-name == 'bootstrap5' or $ddt-skin-name == 'bootstrap5.3' {
          ~ span.e-input-group-icon.e-ddt-icon {
            background: transparent;
            color: $icon-color;
          }
        }
      }

      &.e-disabled {
        @if $ddt-skin-name == 'tailwind' {
          background-color: $content-bg-color-alt1;
          color: $content-text-color-disabled;
        }
      }
    }

    &.e-popup {
      background: $ddt-popup-background-color;
      border-color: $ddt-popup-border-color;

      .e-popup-content.e-no-data {
        color: $ddt-nodata-font-color;
      }

      .e-selectall-parent {
        border-bottom-color: $ddt-popup-reorder-border;
        
        &:focus {
          background: $ddt-item-hover-bg;
        }
        .e-all-text {
          color: $ddt-select-all-font-color;
        }
      }
    }
  }
}
