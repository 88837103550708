@include export-module('input-theme') {

  /*! input theme */

  input.e-input#{$css},
  .e-input-group input.e-input,
  .e-input-group.e-control-wrapper input.e-input,
  .e-input-group textarea.e-input,
  .e-input-group.e-control-wrapper textarea.e-input,
  textarea.e-input#{$css},
  .e-float-input input,
  .e-float-input.e-control-wrapper input,
  .e-float-input textarea,
  .e-float-input.e-control-wrapper textarea {
    background: $input-bg-color;
    border-color: $input-box-border-color;
    color: $input-font-color;
  }

  .e-input-group,
  .e-input-group.e-control-wrapper,
  .e-float-input,
  .e-float-input.e-input-group,
  .e-float-input.e-control-wrapper,
  .e-float-input.e-input-group.e-control-wrapper {
    background: $input-bg-color;
    color: $input-font-color;
    @if $input-skin-name == 'fluent2' {
      border-bottom-color: $input-border-bottom-color;
    }
  }

  .e-input-group .e-input-group-icon,
  .e-input-group.e-control-wrapper .e-input-group-icon {
    background: transparent;
    border-color: $input-box-border-color;
    color: $input-icon-font-color;
    @if ($input-skin-name == 'FluentUI') {
      border-color: $input-group-icon-border-color;
    }
    @if ($input-skin-name == 'Material3' or $input-skin-name == 'fluent2') {
      margin: 0;
    }
  }

  .e-input-group.e-disabled .e-input-group-icon,
  .e-input-group.e-control-wrapper.e-disabled .e-input-group-icon,
  .e-float-input.e-disabled .e-clear-icon,
  .e-float-input.e-control-wrapper.e-disabled .e-clear-icon,
  .e-input-group.e-disabled .e-clear-icon,
  .e-input-group.e-control-wrapper.e-disabled .e-clear-icon {
    color: $input-group-disabled-color;
  }

  /* stylelint-disable property-no-vendor-prefix */
  /* stylelint-disable selector-no-vendor-prefix */
  .e-input#{$css}[disabled],
  .e-input-group .e-input[disabled],
  .e-input-group.e-control-wrapper .e-input[disabled],
  .e-input-group.e-disabled,
  .e-input-group.e-control-wrapper.e-disabled,
  .e-float-input input[disabled],
  .e-float-input.e-control-wrapper input[disabled],
  .e-float-input textarea[disabled],
  .e-float-input.e-control-wrapper textarea[disabled],
  .e-float-input.e-disabled,
  .e-float-input.e-control-wrapper.e-disabled  {
    @if ($input-skin-name != 'bootstrap5' and $input-skin-name != 'bootstrap5.3') {
      -webkit-text-fill-color: $input-disable-font-color;
    }
    background: $input-disable-bg-color;
    color: $input-disable-font-color;
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      background-image: linear-gradient(90deg, $input-disable-border-color 0, $input-disable-border-color 33%, transparent 0);
      background-position: bottom -1px left 0;
      background-repeat: repeat-x;
      background-size: 4px 1px;
      border-bottom-color: transparent;
    }

    @else {
      border-color: $input-disable-border-color;
    }
  }

  .e-input-group input.e-input,
  .e-input-group.e-control-wrapper input.e-input,
  .e-float-input input,
  .e-float-input.e-control-wrapper input,
  .e-input-group textarea.e-input,
  .e-input-group.e-control-wrapper textarea.e-input,
  .e-float-input textarea,
  .e-float-input.e-control-wrapper textarea,
  .e-input-group .e-input[disabled],
  .e-input-group.e-control-wrapper .e-input[disabled],
  .e-input-group.e-disabled input.e-input,
  .e-input-group.e-control-wrapper.e-disabled input.e-input,
  .e-input-group.e-disabled textarea.e-input,
  .e-input-group.e-control-wrapper.e-disabled textarea.e-input {
    background: none;
    color: inherit;
  }

  .e-input-group .e-input[readonly],
  .e-input-group.e-control-wrapper .e-input[readonly],
  .e-float-input input[readonly],
  .e-float-input.e-control-wrapper input[readonly],
  .e-float-input textarea[readonly],
  .e-float-input.e-control-wrapper textarea[readonly] {
    background: $input-readonly-bg-color;
    @if $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3'  {
      color: $input-readonly-color;
    }
    @if $input-skin-name != 'bootstrap4' and $input-skin-name != 'bootstrap5' and $input-skin-name != 'bootstrap5.3' {
      color: inherit;
    }
  }

  .e-input-group .e-input[readonly] ~ span.e-input-group-icon,
  .e-input-group.e-control-wrapper .e-input[readonly] ~ span.e-input-group-icon,
  .e-float-input input[readonly] ~ span.e-input-group-icon,
  .e-float-input.e-control-wrapper input[readonly] ~ span.e-input-group-icon,
  .e-float-input.e-control-wrapper input[readonly] ~ span.e-clear-icon {
    @if $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' {
      background: $input-readonly-bg-color;
      color: $input-icon-font-color;
    }
  }

  .e-float-input.e-disabled input,
  .e-float-input.e-control-wrapper.e-disabled input,
  .e-float-input.e-disabled textarea,
  .e-float-input.e-control-wrapper.e-disabled textarea,
  .e-float-input input[disabled],
  .e-float-input.e-control-wrapper input[disabled],
  .e-float-input textarea[disabled],
  .e-float-input.e-control-wrapper textarea[disabled] {
    color: inherit;
  }

  /*! Added color to textbox for disbaled state */
  .e-float-input:not(.e-disabled) input[disabled],
  .e-float-input.e-control-wrapper:not(.e-disabled) input[disabled],
  .e-float-input:not(.e-disabled) textarea[disabled],
  .e-float-input.e-control-wrapper:not(.e-disabled) textarea[disabled] {
    -webkit-text-fill-color: $input-disable-font-color;
    color: $input-disable-font-color;
  }

  .e-input-group.e-disabled .e-input-group-icon,
  .e-input-group.e-control-wrapper.e-disabled .e-input-group-icon {
    background: $input-disabled-group-bg-color;
    border-color: $input-disable-border-color;
  }

  .e-input-group:not(.e-disabled) .e-input-group-icon:hover,
  .e-input-group.e-control-wrapper:not(.e-disabled) .e-input-group-icon:hover {
    @if $input-skin-name == 'fabric' or $input-skin-name == 'FluentUI' or $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'highcontrast' {
      background: $input-hover-bg-color;
    }
    @if $input-skin-name == 'bootstrap4' {
      border-color: $input-group-border-color-hover;
    }
    @if $input-skin-name == 'highcontrast' {
      border-color: $input-group-border-color-hover;
    }
    @if $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      border: $input-icon-hover-border;
      background: $input-icon-hover-bg-color;
    }
    color: $input-group-hovered-color;
  }

  .e-input-group.e-disabled .e-input-group-icon:hover,
  .e-input-group.e-control-wrapper.e-disabled .e-input-group-icon:hover {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      background: transparent;
    }
  }

  .e-input#{$css}.e-success,
  .e-input#{$css}.e-success:focus,
  .e-input-group.e-success input.e-input,
  .e-input-group.e-control-wrapper.e-success input.e-input,
  .e-input-group.e-success .e-input-group-icon,
  .e-input-group.e-control-wrapper.e-success .e-input-group-icon,
  .e-input-group.e-success textarea.e-input,
  .e-input-group.e-control-wrapper.e-success textarea.e-input {
    border-color: $input-success-color;
  }

  .e-input#{$css}.e-warning,
  .e-input#{$css}.e-warning:focus,
  .e-input-group.e-warning input.e-input,
  .e-input-group.e-control-wrapper.e-warning input.e-input,
  .e-input-group.e-warning .e-input-group-icon,
  .e-input-group.e-control-wrapper.e-warning .e-input-group-icon,
  .e-input-group.e-warning textarea.e-input,
  .e-input-group.e-control-wrapper.e-warning textarea.e-input {
    border-color: $input-warning-color;
  }

  .e-input + .e-input-group-icon:not(.e-input-group):not(.e-float-icon-left):last-child:hover,
  .e-input-group.e-success .e-input + .e-input-group-icon:last-child:hover,
  .e-input-group.e-success.e-control-wrapper .e-input + .e-input-group-icon:last-child:hover {
    @if $input-skin-name == 'highcontrast' {
      border-color: $input-success-color;
    }
  }

  .e-input#{$css}.e-error,
  .e-input#{$css}.e-error:focus,
  .e-input-group.e-error input.e-input,
  .e-input-group.e-control-wrapper.e-error input.e-input,
  .e-input-group.e-control-wrapper.e-error .e-input-group-icon,
  .e-input-group.e-error .e-input-group-icon,
  .e-input-group.e-error textarea.e-input,
  .e-input-group.e-control-wrapper.e-error textarea.e-input {
    border-color: $input-error-color;
  }

  label.e-float-text,
  .e-float-input:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-small:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-small:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input.e-control-wrapper:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    color: $input-header-font-color;
    @if $input-skin-name == 'highcontrast' {
      color: $input-placeholder;
    }
  }

  .e-float-input.e-error label.e-float-text,
  .e-float-input.e-control-wrapper.e-error label.e-float-text,
  .e-float-input.e-error input:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-error input:focus ~ label.e-float-text,
  .e-float-input.e-error.e-input-focus input ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-error.e-input-focus input ~ label.e-float-text,
  .e-float-input.e-error textarea:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-error textarea:focus ~ label.e-float-text {
    color: $input-error-color;
    @if $input-skin-name == 'highcontrast' {
      color: $content-font;
    }
    @if ($input-skin-name == 'FluentUI') {
      color: $float-label-font-color;
    }
  }

  .e-float-input input,
  .e-float-input textarea,
  .e-float-input.e-control-wrapper input,
  .e-float-input.e-control-wrapper textarea,
  .e-input-group,
  .e-input-group.e-control-wrapper {
    border-bottom-color: $input-box-border-color;
  }

  .e-float-input.e-success input,
  .e-float-input.e-success textarea,
  .e-float-input.e-control-wrapper.e-success input,
  .e-float-input.e-control-wrapper.e-success textarea {
    @include input-state-color ($input-success-color);
  }

  .e-float-input.e-warning input,
  .e-float-input.e-control-wrapper.e-warning input,
  .e-float-input.e-warning textarea,
  .e-float-input.e-control-wrapper.e-warning textarea {
    @include input-state-color ($input-warning-color);
  }

  .e-float-input.e-error input,
  .e-float-input.e-control-wrapper.e-error input,
  .e-float-input.e-error textarea,
  .e-float-input.e-control-wrapper.e-error textarea {
    @include input-state-color ($input-error-color);
  }

  label.e-float-text,
  .e-float-input label.e-float-text,
  .e-float-input.e-control-wrapper label.e-float-text,
  .e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  label.e-float-text,
  .e-float-input label.e-float-text,
  .e-float-input.e-control-wrapper label.e-float-text,
  .e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  
  .e-float-input.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  
  .e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  
  .e-float-input.e-control-wrapper.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  
  .e-float-input textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  
  .e-float-input.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  
  .e-float-input.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  
  .e-float-input.e-control-wrapper.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'highcontrast' {
      color: $content-font-alt;
    }
  }

  .e-float-input:not(.e-input-group) .e-float-line::before,
  .e-float-input:not(.e-input-group) .e-float-line::after,
  .e-float-input:not(.e-input-group) .e-float-line::before,
  .e-float-input:not(.e-input-group) .e-float-line::after,
  .e-float-input.e-control-wrapper:not(.e-input-group) .e-float-line::before,
  .e-float-input.e-control-wrapper:not(.e-input-group) .e-float-line::after,
  .e-float-input.e-control-wrapper:not(.e-input-group) .e-float-line::before,
  .e-float-input.e-control-wrapper:not(.e-input-group) .e-float-line::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      background: $input-active-accent-color;
    }
  }

  .e-float-input.e-success:not(.e-input-group) .e-float-line::before,
  .e-float-input.e-success:not(.e-input-group) .e-float-line::after,
  .e-float-input.e-success:not(.e-input-group) .e-float-line::before,
  .e-float-input.e-success:not(.e-input-group) .e-float-line::after,
  .e-float-input.e-control-wrapper.e-success:not(.e-input-group) .e-float-line::before,
  .e-float-input.e-control-wrapper.e-success:not(.e-input-group) .e-float-line::after,
  .e-float-input.e-control-wrapper.e-success:not(.e-input-group) .e-float-line::before,
  .e-float-input.e-control-wrapper.e-success:not(.e-input-group) .e-float-line::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      background: $input-success-color;
    }
  }

  .e-float-input.e-warning:not(.e-input-group) .e-float-line::before,
  .e-float-input.e-warning:not(.e-input-group) .e-float-line::after,
  .e-float-input.e-warning:not(.e-input-group) .e-float-line::before,
  .e-float-input.e-warning:not(.e-input-group) .e-float-line::after,
  .e-float-input.e-control-wrapper.e-warning:not(.e-input-group) .e-float-line::before,
  .e-float-input.e-control-wrapper.e-warning:not(.e-input-group) .e-float-line::after,
  .e-float-input.e-control-wrapper.e-warning:not(.e-input-group) .e-float-line::before,
  .e-float-input.e-control-wrapper.e-warning:not(.e-input-group) .e-float-line::after  {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      background: $input-warning-color;
    }
  }

  input.e-input#{$css}:-moz-placeholder,
  input.e-input#{$css}:-moz-placeholder,
  textarea.e-input#{$css}:-moz-placeholder,
  input.e-input#{$css}::-moz-placeholder,
  textarea.e-input#{$css}::-moz-placeholder,
  .e-input-group input.e-input:-moz-placeholder,
  .e-input-group input.e-input:-moz-placeholder,
  .e-input-group.e-control-wrapper input.e-input:-moz-placeholder,
  .e-input-group input.e-input:-moz-placeholder,
  .e-input-group.e-control-wrapper input.e-input:-moz-placeholder,
  .e-input-group textarea.e-input:-moz-placeholder,
  .e-input-group.e-control-wrapper textarea.e-input:-moz-placeholder,
  .e-input-group input.e-input::-moz-placeholder,
  .e-input-group.e-control-wrapper input.e-input::-moz-placeholder,
  .e-input-group textarea.e-input::-moz-placeholder,
  .e-input-group.e-control-wrapper textarea.e-input::-moz-placeholder {
    color: $input-placeholder;
  }

  input.e-input#{$css}::-webkit-input-placeholder,
  textarea.e-input#{$css}::-webkit-input-placeholder,
  .e-input-group input.e-input::-webkit-input-placeholder,
  .e-input-group textarea.e-input::-webkit-input-placeholder,
  .e-input-group.e-control-wrapper input.e-input::-webkit-input-placeholder,
  .e-input-group.e-control-wrapper textarea.e-input::-webkit-input-placeholder {
    color: $input-placeholder;
  }

  input.e-input#{$css}:-ms-input-placeholder,
  textarea.e-input#{$css}:-ms-input-placeholder,
  .e-input-group input.e-input:-ms-input-placeholder,
  .e-input-group.e-control-wrapper input.e-input:-ms-input-placeholder,
  .e-input-group.e-control-wrapper textarea.e-input:-ms-input-placeholder,
  .e-input-group textarea.e-input:-ms-input-placeholder {
    color: $input-placeholder;
  }

  .e-float-input:not(.e-error):not(.e-input-focus):not(.e-disabled) input:not(:focus):not(:valid) ~ label.e-float-text:not(.e-label-top),
  .e-float-input:not(.e-error):not(.e-input-focus) textarea:not(:focus):not(:valid) ~ label.e-float-text:not(.e-label-top),
  .e-float-input.e-control-wrapper:not(.e-error):not(.e-input-focus):not(.e-disabled) input:not(:focus):not(:valid) ~ label.e-float-text:not(.e-label-top),
  .e-float-input:not(.e-input-focus):not(.e-disabled) textarea:not(:focus):not(:valid) ~ label.e-float-text:not(.e-label-top),
  .e-float-input:not(.e-input-focus) textarea:not(:focus):not(:valid) ~ label.e-float-text:not(.e-label-top),
  .e-float-input.e-control-wrapper:not(.e-input-focus):not(.e-disabled) textarea:not(:focus):not(:valid) ~ label.e-float-text:not(.e-label-top) {
    color: $input-placeholder;
  }

  .e-float-input.e-error:not(.e-input-group) .e-float-line::before,
  .e-float-input.e-error:not(.e-input-group) .e-float-line::after,
  .e-float-input.e-error:not(.e-input-group) .e-float-line::before,
  .e-float-input.e-error:not(.e-input-group) .e-float-line::after,
  .e-float-input.e-control-wrapper.e-error:not(.e-input-group) .e-float-line::before,
  .e-float-input.e-control-wrapper.e-error:not(.e-input-group) .e-float-line::after,
  .e-float-input.e-control-wrapper.e-error:not(.e-input-group) .e-float-line::before,
  .e-float-input.e-control-wrapper.e-error:not(.e-input-group) .e-float-line::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      background: $input-error-color;
    }
  }

  .e-input-group:not(.e-disabled) .e-input-group-icon:active,
  .e-input-group.e-control-wrapper:not(.e-disabled) .e-input-group-icon:active {
    @if $skin-name != 'bootstrap5.3'{
      background: $input-group-pressed-bg;
    }
    color: $input-group-pressed-color;
    @if $input-skin-name == 'bootstrap' {
      -webkit-box-shadow: $input-icon-shadow;
      box-shadow: $input-icon-shadow;
    }
    @if $input-skin-name == 'bootstrap4' {
      -webkit-box-shadow: $input-icon-shadow;
      border-color: $pressed-border-color;
      box-shadow: $input-icon-shadow;
    }
    @if $input-skin-name == 'highcontrast' {
      border-left-color: transparent;
      border-right-color: transparent;
    }
  }

  .e-float-input.e-input-group.e-small.e-float-icon-left .e-input-group-icon:last-child:hover,
  .e-float-input.e-input-group.e-float-icon-left .e-input-group-icon:last-child:hover,
  .e-float-input.e-control-wrapper.e-input-group.e-small.e-float-icon-left .e-input-group-icon:last-child:hover,
  .e-float-input.e-control-wrapper.e-input-group.e-float-icon-left .e-input-group-icon:last-child:hover,
  .e-float-input.e-input-group.e-small:not(.e-float-icon-left):not(.e-input-group):not(.e-disabled) .e-input-group-icon:last-child:hover,
  .e-float-input.e-control-wrapper.e-input-group.e-small:not(.e-float-icon-left):not(.e-input-group):not(.e-disabled) .e-input-group-icon:last-child:hover,
  .e-input-group.e-small:not(.e-disabled):not(.e-float-input):not(.e-rtl) .e-input-group-icon:last-child:hover,
  .e-input-group.e-float-icon-left .e-input-group-icon:last-child:hover,
  .e-input-group.e-control-wrapper.e-small:not(.e-disabled):not(.e-float-input):not(.e-rtl) .e-input-group-icon:last-child:hover,
  .e-input-group.e-control-wrapper.e-float-icon-left .e-input-group-icon:last-child:hover,
  .e-input-group:not(.e-rtl):not(.e-disabled) .e-input + .e-input-group-icon:last-child:hover,
  .e-input-group.e-control-wrapper:not(.e-rtl):not(.e-disabled) .e-input + .e-input-group-icon:last-child:hover,
  .e-input-group:not(.e-disabled) .e-input-group-icon:not(:last-child):hover + .e-input-group-icon:not(:last-child):hover,
  .e-input-group.e-control-wrapper:not(.e-disabled) .e-input-group-icon:not(:last-child):hover + .e-input-group-icon:not(:last-child):hover,
  .e-input-group:not(.e-rtl):not(.e-disabled) .e-input-group-icon:last-child:hover,
  .e-input-group.e-control-wrapper:not(.e-rtl):not(.e-disabled) .e-input-group-icon:last-child:hover {
    @if $input-skin-name == 'highcontrast' {
      border-right-color: transparent;
      background-color: $input-hover-bg-color;
    }
  }

  .e-float-input.e-input-group.e-small.e-float-icon-left .e-input-group-icon:not(:last-child):hover,
  .e-float-input.e-input-group.e-float-icon-left .e-input-group-icon:not(:last-child):hover,
  .e-float-input.e-control-wrapper.e-input-group.e-small.e-float-icon-left .e-input-group-icon:not(:last-child):hover,
  .e-float-input.e-control-wrapper.e-input-group.e-float-icon-left .e-input-group-icon:not(:last-child):hover,
  .e-input-group.e-small.e-float-icon-left:not(.e-disabled) .e-input-group-icon:first-child:hover,
  .e-input-group.e-control-wrapper.e-small.e-float-icon-left:not(.e-disabled) .e-input-group-icon:first-child:hover,
  .e-input-group.e-small:not(.e-disabled) .e-input-group-icon:first-child:hover,
  .e-input-group.e-float-icon-left .e-input-group-icon:first-child:hover,
  .e-input-group.e-control-wrapper.e-small:not(.e-disabled) .e-input-group-icon:first-child:hover,
  .e-input-group.e-control-wrapper.e-float-icon-left .e-input-group-icon:first-child:hover,
  .e-input-group.e-rtl:not(.e-disabled) .e-input-group-icon:last-child:hover,
  .e-input-group.e-control-wrapper.e-rtl:not(.e-disabled) .e-input-group-icon:last-child:hover,
  .e-input-group:not(.e-disabled) .e-input-group-icon:first-child:hover,
  .e-input-group.e-control-wrapper:not(.e-disabled) .e-input-group-icon:first-child:hover {
    @if $input-skin-name == 'highcontrast' {
      border-left-color: transparent;
    }
  }

  input.e-input#{$css}::selection,
  textarea.e-input#{$css}::selection,
  .e-input-group input.e-input::selection,
  .e-input-group.e-control-wrapper input.e-input::selection,
  .e-float-input input::selection,
  .e-float-input.e-control-wrapper input::selection,
  .e-input-group textarea.e-input::selection,
  .e-input-group.e-control-wrapper textarea.e-input::selection,
  .e-float-input textarea::selection,
  .e-float-input.e-control-wrapper textarea::selection,
  .e-float-input.e-small textarea::selection,
  .e-float-input textarea::selection {
    @include input-selection;
  }

  .e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
  .e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
  .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
  .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
  .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
  .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after,
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
  .e-float-input.e-input-group:not(.e-float-icon-left) .e-float-line::before,
  .e-float-input.e-input-group:not(.e-float-icon-left) .e-float-line::after,
  .e-float-input.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::before,
  .e-float-input.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::after,
  .e-float-input.e-control-wrapper.e-input-group:not(.e-float-icon-left) .e-float-line::before,
  .e-float-input.e-control-wrapper.e-input-group:not(.e-float-icon-left) .e-float-line::after,
  .e-float-input.e-control-wrapper.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::before,
  .e-float-input.e-control-wrapper.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::after,
  .e-filled.e-input-group.e-float-icon-left:not(.e-float-input)::before,
  .e-filled.e-input-group.e-float-icon-left:not(.e-float-input)::after,
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)::before,
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)::after,
  .e-filled.e-float-input.e-input-group.e-float-icon-left .e-float-line::before,
  .e-filled.e-float-input.e-input-group.e-float-icon-left .e-float-line::after,
  .e-filled.e-float-input.e-control-wrapper.e-input-group.e-float-icon-left .e-float-line::before,
  .e-filled.e-float-input.e-control-wrapper.e-input-group.e-float-icon-left .e-float-line::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      @include input-group-animation-bg;
    }
  }

  .e-float-input.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group) input:focus,
  .e-float-input.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group) textarea:focus,
  .e-float-input:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group).e-input-focus input,
  .e-float-input.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group).e-input-focus input {
    @if $input-skin-name == 'fluent2' {
      border-top: $input-active-border-color;
    }
  }

  .e-input-group::before,
  .e-input-group::after,
  .e-input-group.e-control-wrapper::before,
  .e-input-group.e-control-wrapper::after {
    @if $input-skin-name != 'material' and $input-skin-name != 'Material3' {
      @include input-group-animation-bg;
    }
  }

  .e-input-group:not(.e-float-icon-left):not(.e-float-input).e-success::before,
  .e-input-group:not(.e-float-icon-left):not(.e-float-input).e-success::after,
  .e-input-group.e-float-icon-left.e-success:not(.e-float-input) .e-input-in-wrap::before,
  .e-input-group.e-float-icon-left.e-success:not(.e-float-input) .e-input-in-wrap::after,
  .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input).e-success::before,
  .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input).e-success::after,
  .e-input-group.e-control-wrapper.e-float-icon-left.e-success:not(.e-float-input) .e-input-in-wrap::before,
  .e-input-group.e-control-wrapper.e-float-icon-left.e-success:not(.e-float-input) .e-input-in-wrap::after,
  .e-float-input.e-input-group:not(.e-float-icon-left).e-success .e-float-line::before,
  .e-float-input.e-input-group:not(.e-float-icon-left).e-success .e-float-line::after,
  .e-float-input.e-input-group.e-float-icon-left.e-success .e-input-in-wrap .e-float-line::before,
  .e-float-input.e-input-group.e-float-icon-left.e-success .e-input-in-wrap .e-float-line::after,
  .e-float-input.e-control-wrapper.e-input-group:not(.e-float-icon-left).e-success .e-float-line::before,
  .e-float-input.e-control-wrapper.e-input-group:not(.e-float-icon-left).e-success .e-float-line::after,
  .e-float-input.e-control-wrapper.e-input-group.e-float-icon-left.e-success .e-input-in-wrap .e-float-line::before,
  .e-float-input.e-control-wrapper.e-input-group.e-float-icon-left.e-success .e-input-in-wrap .e-float-line::after,
  .e-float-input.e-input-group.e-control-wrapper:not(.e-float-icon-left).e-success .e-float-line::before,
  .e-float-input.e-input-group.e-control-wrapper:not(.e-float-icon-left).e-success .e-float-line::after,
  .e-float-input.e-input-group.e-control-wrapper.e-float-icon-left.e-success .e-input-in-wrap .e-float-line::before,
  .e-float-input.e-input-group.e-control-wrapper.e-float-icon-left.e-success .e-input-in-wrap .e-float-line::after,
  .e-filled.e-input-group.e-float-icon-left.e-success:not(.e-float-input)::before,
  .e-filled.e-input-group.e-float-icon-left.e-success:not(.e-float-input)::after,
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left.e-success:not(.e-float-input)::before,
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left.e-success:not(.e-float-input)::after,
  .e-filled.e-float-input.e-input-group.e-float-icon-left.e-success .e-float-line::before,
  .e-filled.e-float-input.e-input-group.e-float-icon-left.e-success .e-float-line::after,
  .e-filled.e-float-input.e-control-wrapper.e-input-group.e-float-icon-left.e-success .e-float-line::before,
  .e-filled.e-float-input.e-control-wrapper.e-input-group.e-float-icon-left.e-success .e-float-line::after,
  .e-filled.e-float-input.e-input-group.e-control-wrapper.e-float-icon-left.e-success .e-float-line::before,
  .e-filled.e-float-input.e-input-group.e-control-wrapper.e-float-icon-left.e-success .e-float-line::after  {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      @include input-group-success-animation-bg;
    }
  }

  .e-input-group.e-success::before,
  .e-input-group.e-success::after,
  .e-input-group.e-control-wrapper.e-success::before,
  .e-input-group.e-control-wrapper.e-success::after {
    @if $input-skin-name != 'material' and $input-skin-name != 'Material3' {
      @include input-group-success-animation-bg;
    }
  }

  .e-input-group:not(.e-float-icon-left).e-warning:not(.e-float-input)::before,
  .e-input-group:not(.e-float-icon-left).e-warning:not(.e-float-input)::after,
  .e-input-group.e-float-icon-left.e-warning:not(.e-float-input) .e-input-in-wrap::before,
  .e-input-group.e-float-icon-left.e-warning:not(.e-float-input) .e-input-in-wrap::after,
  .e-input-group.e-control-wrapper:not(.e-float-icon-left).e-warning:not(.e-float-input)::before,
  .e-input-group.e-control-wrapper:not(.e-float-icon-left).e-warning:not(.e-float-input)::after,
  .e-input-group.e-control-wrapper.e-float-icon-left.e-warning:not(.e-float-input) .e-input-in-wrap::before,
  .e-input-group.e-control-wrapper.e-float-icon-left.e-warning:not(.e-float-input) .e-input-in-wrap::after,
  .e-float-input.e-input-group:not(.e-float-icon-left).e-warning .e-float-line::before,
  .e-float-input.e-input-group:not(.e-float-icon-left).e-warning .e-float-line::after,
  .e-float-input.e-control-wrapper.e-input-group.e-float-icon-left.e-warning .e-input-in-wrap .e-float-line::before,
  .e-float-input.e-control-wrapper.e-input-group.e-float-icon-left.e-warning .e-input-in-wrap .e-float-line::after,
  .e-filled.e-input-group.e-float-icon-left.e-warning:not(.e-float-input)::before,
  .e-filled.e-input-group.e-float-icon-left.e-warning:not(.e-float-input)::after,
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left.e-warning:not(.e-float-input)::before,
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left.e-warning:not(.e-float-input)::after,
  .e-filled.e-float-input.e-control-wrapper.e-input-group.e-float-icon-left.e-warning .e-float-line::before,
  .e-filled.e-float-input.e-control-wrapper.e-input-group.e-float-icon-left.e-warning .e-float-line::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      @include input-group-warning-animation-bg;
    }
  }

  .e-input-group.e-warning::before,
  .e-input-group.e-warning::after,
  .e-input-group.e-control-wrapper.e-warning::before,
  .e-input-group.e-control-wrapper.e-warning::after {
    @if $input-skin-name != 'material' and $input-skin-name != 'Material3' {
      @include input-group-warning-animation-bg;
    }
  }

  .e-input-group:not(.e-float-icon-left).e-error:not(.e-float-input)::before,
  .e-input-group:not(.e-float-icon-left).e-error:not(.e-float-input)::after,
  .e-input-group.e-float-icon-left.e-error:not(.e-float-input) .e-input-in-wrap::before,
  .e-input-group.e-float-icon-left.e-error:not(.e-float-input) .e-input-in-wrap::after,
  .e-input-group.e-control-wrapper:not(.e-float-icon-left).e-error:not(.e-float-input)::before,
  .e-input-group.e-control-wrapper:not(.e-float-icon-left).e-error:not(.e-float-input)::after,
  .e-input-group.e-control-wrapper.e-float-icon-left.e-error:not(.e-float-input) .e-input-in-wrap::before,
  .e-input-group.e-control-wrapper.e-float-icon-left.e-error:not(.e-float-input) .e-input-in-wrap::after,
  .e-float-input.e-input-group:not(.e-float-icon-left).e-error .e-float-line::before,
  .e-float-input.e-input-group:not(.e-float-icon-left).e-error .e-float-line::after,
  .e-float-input.e-input-group.e-float-icon-left.e-error .e-input-in-wrap .e-float-line::before,
  .e-float-input.e-input-group.e-float-icon-left.e-error .e-input-in-wrap .e-float-line::after,
  .e-float-input.e-input-group.e-control-wrapper:not(.e-float-icon-left).e-error .e-float-line::before,
  .e-float-input.e-input-group.e-control-wrapper:not(.e-float-icon-left).e-error .e-float-line::after,
  .e-float-input.e-input-group.e-control-wrapper.e-float-icon-left.e-error .e-input-in-wrap .e-float-line::before,
  .e-float-input.e-input-group.e-control-wrapper.e-float-icon-left.e-error .e-input-in-wrap .e-float-line::after,
  .e-filled.e-input-group.e-float-icon-left.e-error:not(.e-float-input)::before,
  .e-filled.e-input-group.e-float-icon-left.e-error:not(.e-float-input)::after,
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left.e-error:not(.e-float-input)::before,
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left.e-error:not(.e-float-input)::after,
  .e-filled.e-float-input.e-input-group.e-float-icon-left.e-error .e-float-line::before,
  .e-filled.e-float-input.e-input-group.e-float-icon-left.e-error .e-float-line::after,
  .e-filled.e-float-input.e-input-group.e-control-wrapper.e-float-icon-left.e-error .e-float-line::before,
  .e-filled.e-float-input.e-input-group.e-control-wrapper.e-float-icon-left.e-error .e-float-line::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      @include input-group-error-animation-bg;
    }
  }

  .e-input-group.e-error::before,
  .e-input-group.e-error::after,
  .e-input-group.e-control-wrapper.e-error::before,
  .e-input-group.e-control-wrapper.e-error::after {
    @if $input-skin-name != 'material' and $input-skin-name != 'Material3' {
      @include input-group-error-animation-bg;
    }
  }

  .e-input-group.e-success .e-input-group-icon,
  .e-input-group.e-control-wrapper.e-success .e-input-group-icon,
  .e-input-group.e-warning .e-input-group-icon,
  .e-input-group.e-control-wrapper.e-warning .e-input-group-icon,
  .e-input-group.e-error .e-input-group-icon,
  .e-input-group.e-control-wrapper.e-error .e-input-group-icon {
    color: $input-icon-font-color;
  }

  .e-input-group.e-success:not(.e-disabled):not(:active) .e-input-group-icon:hover,
  .e-input-group.e-control-wrapper.e-success:not(.e-disabled):not(:active) .e-input-group-icon:hover,
  .e-input-group.e-warning:not(.e-disabled):not(:active) .e-input-group-icon:hover,
  .e-input-group.e-control-wrapper.e-warning:not(.e-disabled):not(:active) .e-input-group-icon:hover,
  .e-input-group.e-error:not(.e-disabled):not(:active) .e-input-group-icon:hover,
  .e-input-group.e-control-wrapper.e-error:not(.e-disabled):not(:active) .e-input-group-icon:hover {
    color: $input-icon-font-color;
    @if $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'FluentUI' {
      color: $input-group-hovered-color;
    }
  }

  .e-input-group.e-success:not(.e-disabled) .e-input-group-icon:active,
  .e-input-group.e-control-wrapper.e-success:not(.e-disabled) .e-input-group-icon:active,
  .e-input-group.e-warning:not(.e-disabled) .e-input-group-icon:active,
  .e-input-group.e-control-wrapper.e-warning:not(.e-disabled) .e-input-group-icon:active,
  .e-input-group.e-error:not(.e-disabled) .e-input-group-icon:active,
  .e-input-group.e-control-wrapper.e-error:not(.e-disabled) .e-input-group-icon:active {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      color: $input-icon-font-color;
    }
  }

  .e-input-group input.e-input,
  .e-input-group.e-control-wrapper input.e-input,
  .e-input-group textarea.e-input,
  .e-input-group.e-control-wrapper textarea.e-input,
  .e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input:focus,
  .e-input-group.e-control-wrapper.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input:focus,
  .e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input,
  .e-input-group.e-control-wrapper.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input {
    border-color: $input-box-border-color;
  }
  
  .e-input#{$css}:focus:not(.e-success):not(.e-warning):not(.e-error),
  .e-float-input:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group) input:focus,
  .e-float-input:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group) textarea:focus,
  .e-float-input.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group) input:focus,
  .e-float-input.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group) textarea:focus,
  .e-float-input:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group).e-input-focus input,
  .e-float-input.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group).e-input-focus input {
    @if $input-skin-name != 'fluent2' {
      border-color: $input-active-border-color;
    }
    @if $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4'  {
      -webkit-box-shadow: $input-border-shadow;
      box-shadow: $input-border-shadow;
    }
    @if $input-skin-name == 'tailwind' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' or $input-skin-name == 'FluentUI'  {
      border-radius: $input-groupo-focus-border-radius;
      box-shadow: $input-group-focus-box-shadow;
    }
    @if $input-skin-name == 'bootstrap4'  {
      transition: $input-transition-shadow;
    }
    @if $input-skin-name == 'fluent2' {
      border-bottom-color: $input-active-border-color;
      padding-bottom: 2px;
      border-bottom-width: 2px;
    }
  }

  textarea.e-input#{$css}:focus:not(.e-success):not(.e-warning):not(.e-error) {
    @if $input-skin-name == 'fluent2' {
      border-bottom-color: $input-active-border-color;
      padding-bottom: 4px;
      border-bottom-width: 0;
    }
  }

  .e-input-group .e-input#{$css}:focus:not(.e-success):not(.e-warning):not(.e-error),
  .e-input-group .e-float-input:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group) input:focus,
  .e-input-group .e-float-input:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group) textarea:focus,
  .e-input-group .e-float-input.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group) input:focus,
  .e-input-group .e-float-input.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group) textarea:focus,
  .e-input-group .e-float-input:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group).e-input-focus input,
  .e-input-group .e-float-input.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group).e-input-focus input {
    @if $input-skin-name == 'tailwind' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' or $input-skin-name == 'FluentUI' {
      box-shadow: none;
    }
  }

  
  .e-input#{$css}:focus:not(.e-success):not(.e-warning).e-error,
  .e-float-input:not(.e-success):not(.e-warning).e-error:not(.e-input-group) input:focus,
  .e-float-input:not(.e-success):not(.e-warning).e-error:not(.e-input-group) textarea:focus,
  .e-float-input.e-control-wrapper:not(.e-success):not(.e-warning).e-error:not(.e-input-group) input:focus,
  .e-float-input.e-control-wrapper:not(.e-success):not(.e-warning).e-error:not(.e-input-group) textarea:focus,
  .e-float-input:not(.e-success):not(.e-warning).e-error:not(.e-input-group).e-input-focus input,
  .e-float-input.e-control-wrapper:not(.e-success):not(.e-warning).e-error:not(.e-input-group).e-input-focus input,
  .e-input-group.e-input-focus:not(.e-success):not(.e-warning).e-error,
  .e-input-group.e-control-wrapper.e-input-focus:not(.e-success):not(.e-warning).e-error,
  .e-input-group:not(.e-disabled):active:not(.e-success):not(.e-warning).e-error,
  .e-input-group.e-control-wrapper:not(.e-disabled):active:not(.e-success):not(.e-warning).e-error {
    @if $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' or $input-skin-name == 'FluentUI' {
      -webkit-box-shadow: $input-error-border-shadow;
      box-shadow: $input-error-border-shadow;
    }
  }

  
  .e-input#{$css}:focus:not(.e-error):not(.e-warning).e-success,
  .e-float-input:not(.e-error):not(.e-warning).e-success:not(.e-input-group) input:focus,
  .e-float-input:not(.e-error):not(.e-warning).e-success:not(.e-input-group) textarea:focus,
  .e-float-input.e-control-wrapper:not(.e-error):not(.e-warning).e-success:not(.e-input-group) input:focus,
  .e-float-input.e-control-wrapper:not(.e-error):not(.e-warning).e-success:not(.e-input-group) textarea:focus,
  .e-float-input:not(.e-error):not(.e-warning).e-success:not(.e-input-group).e-input-focus input,
  .e-float-input.e-control-wrapper:not(.e-error):not(.e-warning).e-success:not(.e-input-group).e-input-focus input {
    @if $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' or $input-skin-name == 'FluentUI'  {
      -webkit-box-shadow: $input-success-border-shadow;
      box-shadow: $input-success-border-shadow;
    }
  }

  
  .e-input#{$css}:focus:not(.e-success):not(.e-error).e-warning,
  .e-float-input:not(.e-success):not(.e-error).e-warning:not(.e-input-group) input:focus,
  .e-float-input:not(.e-success):not(.e-error).e-warning:not(.e-input-group) textarea:focus,
  .e-float-input.e-control-wrapper:not(.e-success):not(.e-error).e-warning:not(.e-input-group) input:focus,
  .e-float-input.e-control-wrapper:not(.e-success):not(.e-error).e-warning:not(.e-input-group) textarea:focus,
  .e-float-input:not(.e-success):not(.e-error).e-warning:not(.e-input-group).e-input-focus input,
  .e-float-input.e-control-wrapper:not(.e-success):not(.e-error).e-warning:not(.e-input-group).e-input-focus input,
  .e-input-group.e-input-focus:not(.e-success):not(.e-error).e-warning,
  .e-input-group.e-control-wrapper.e-input-focus:not(.e-success):not(.e-error).e-warning,
  .e-input-group:not(.e-disabled):active:not(.e-success):not(.e-error).e-warning,
  .e-input-group.e-control-wrapper:not(.e-disabled):active:not(.e-success):not(.e-error).e-warning {
    @if $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' or $input-skin-name == 'FluentUI' {
      -webkit-box-shadow: $input-warning-border-shadow;
      box-shadow: $input-warning-border-shadow;
    }
  }

  .e-input-group:not(.e-float-input):not(.e-float-icon-left) .e-input:focus:not(.e-success):not(.e-warning):not(.e-error),
  .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap .e-input:focus:not(.e-success):not(.e-warning):not(.e-error),
  .e-input-group.e-control-wrapper:not(.e-float-input):not(.e-float-icon-left) .e-input:focus:not(.e-success):not(.e-warning):not(.e-error),
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap .e-input:focus:not(.e-success):not(.e-warning):not(.e-error),
  .e-input-group:not(.e-float-input):not(.e-float-icon-left).e-input-focus .e-input:not(.e-success):not(.e-warning):not(.e-error),
  .e-input-group.e-float-icon-left:not(.e-float-input).e-input-focus .e-input-in-wrap .e-input:not(.e-success):not(.e-warning):not(.e-error),
  .e-input-group.e-control-wrapper:not(.e-float-input):not(.e-float-icon-left).e-input-focus .e-input:not(.e-success):not(.e-warning):not(.e-error),
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input).e-input-focus .e-input-in-wrap .e-input:not(.e-success):not(.e-warning):not(.e-error) {
    @if $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4'  {
      box-shadow: none;
    }
  }

  .e-input-group:not(.e-success):not(.e-warning):not(.e-error) input.e-input:focus,
  .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error) input.e-input:focus,
  .e-input-group:not(.e-success):not(.e-warning):not(.e-error).e-input-focus input.e-input,
  .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error).e-input-focus input.e-input,
  .e-input-group:not(.e-success):not(.e-warning):not(.e-error) textarea.e-input:focus,
  .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error) textarea.e-input:focus {
    border-color: $input-group-active-border-color;
  }

  .e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
  .e-input-group.e-control-wrapper.e-input-focus:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
  .e-input-group.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-input-group.e-control-wrapper.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-float-input.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-float-input.e-control-wrapper.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-input-group.e-input-focus.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) .e-input-in-wrap:hover,
  .e-input-group.e-control-wrapper.e-input-focus.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) .e-input-in-wrap:hover,
  .e-float-input.e-input-focus.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) .e-input-in-wrap:hover,
  .e-float-input.e-input-focus:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) input:not([disabled]),
  .e-float-input.e-input-focus:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) textarea:not([disabled]),
  .e-float-input.e-control-wrapper.e-input-focus.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) .e-input-in-wrap:hover,
  .e-float-input.e-control-wrapper.e-input-focus:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) input:not([disabled]),
  .e-float-input.e-control-wrapper.e-input-focus:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) textarea:not([disabled]) {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-color: $input-group-border-color-focus;
    }
  }

  .e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error),
  .e-input-group.e-control-wrapper.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) {
    @if $input-skin-name != 'material' and $input-skin-name != 'Material3' and $input-skin-name != 'fluent2' {
      border-color: $input-group-border-color-focus;
    }
    @if $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4'  {
      -webkit-box-shadow: $input-border-shadow;
      box-shadow: $input-border-shadow;
    }
    @if $input-skin-name == 'bootstrap4'  {
      transition: $input-transition-shadow;
    }
    @if $input-skin-name == 'tailwind' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' or $input-skin-name == 'FluentUI' {
      border-color: $input-group-border-color-focus;
      border-radius: $input-groupo-focus-border-radius;
      box-shadow: $input-group-focus-box-shadow;
    }
  }

  .e-input-group.e-input-focus:not(.e-error):not(.e-warning).e-success,
  .e-input-group.e-control-wrapper.e-input-focus:not(.e-error):not(.e-warning).e-success,
  .e-input-group:not(.e-disabled):active:not(.e-error):not(.e-warning).e-success,
  .e-input-group.e-control-wrapper:not(.e-disabled):active:not(.e-error):not(.e-warning).e-success {
    @if $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' or $input-skin-name == 'FluentUI' {
      -webkit-box-shadow: $input-success-border-shadow;
      box-shadow: $input-success-border-shadow;
    }
  }

  .e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input:first-child:focus,
  .e-input-group.e-input-focus.e-rtl:not(.e-success):not(.e-warning):not(.e-error) span:last-child.e-input-group-icon,
  .e-input-group.e-control-wrapper.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input:first-child:focus,
  .e-input-group.e-control-wrapper.e-input-focus.e-rtl:not(.e-success):not(.e-warning):not(.e-error) span:last-child.e-input-group-icon {
    @if ($input-skin-name != 'bootstrap' and $input-skin-name != 'bootstrap4' and $input-skin-name != 'FluentUI' and $input-skin-name != 'bootstrap5' and $input-skin-name != 'bootstrap5.3') {
      border-color: $input-group-border-right-focus;
    }
  }

  .e-input-group.e-input-focus.e-rtl:not(.e-success):not(.e-warning):not(.e-error) span.e-input-group-icon,
  .e-input-group.e-input-focus.e-rtl:not(.e-success):not(.e-warning):not(.e-error) span:first-child.e-input-group-icon,
  .e-input-focus.e-rtl:not(.e-success):not(.e-warning):not(.e-error) .e-input:first-child:focus,
  .e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input:last-child:focus,
  .e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) span.e-input-group-icon,
  .e-input-group.e-control-wrapper.e-input-focus.e-rtl:not(.e-success):not(.e-warning):not(.e-error) span.e-input-group-icon,
  .e-input-group.e-control-wrapper.e-input-focus.e-rtl:not(.e-success):not(.e-warning):not(.e-error) span:first-child.e-input-group-icon,
  .e-input-focus.e-control-wrapper.e-rtl:not(.e-success):not(.e-warning):not(.e-error) .e-input:first-child:focus,
  .e-input-focus.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error) .e-input:last-child:focus,
  .e-input-group.e-control-wrapper.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) span.e-input-group-icon {
    @if ($input-skin-name != 'bootstrap' and $input-skin-name != 'bootstrap4' and $input-skin-name != 'bootstrap5' and $input-skin-name != 'FluentUI' and $input-skin-name != 'bootstrap5.3') {
      border-color: $input-group-border-left-focus;
    }
  }

  .e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) span:first-child.e-input-group-icon,
  .e-input-group.e-input-focus.e-rtl:not(.e-success):not(.e-warning):not(.e-error) .e-input:last-child:focus,
  .e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) span:first-child.e-input-group-icon,
  .e-input-group.e-control-wrapper.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) span:first-child.e-input-group-icon,
  .e-input-group.e-control-wrapper.e-input-focus.e-rtl:not(.e-success):not(.e-warning):not(.e-error) .e-input:last-child:focus,
  .e-input-group.e-control-wrapper.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) span:first-child.e-input-group-icon {
    @if ($input-skin-name != 'bootstrap' and $input-skin-name != 'bootstrap4' and $input-skin-name != 'bootstrap5' and $input-skin-name != 'bootstrap5.3') {
      border-color: transparent;
    }
  }

  .e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left).e-input-focus,
  .e-input-group.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap.e-input-focus,
  .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left).e-input-focus,
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap.e-input-focus,
  .e-float-input.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap.e-input-focus,
  .e-float-input.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap.e-input-focus {
    @if $input-skin-name == 'fluent2' {
      border-bottom-color: $input-group-focus-border-bottom-color;
    }
  }

  .e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
  .e-input-group.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap,
  .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap,
  .e-float-input.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap,
  .e-float-input.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-color: $input-group-full-border-color;
    }
    @else if $input-skin-name == 'fluent2' {
      border-bottom-color: $input-group-full-border-bottom-color;
    }
  }

  .e-input-group:not(.e-success):not(.e-warning):not(.e-error),
  .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error) {
    @if $input-skin-name != 'material' and $input-skin-name != 'Material3' and $input-skin-name != 'fluent2' {
      border-color: $input-group-full-border-color;
    }
    @if $input-skin-name == 'tailwind' {
      box-shadow: $input-group-border-shadow;
    }
    @if $input-skin-name == 'FluentUI' {
      box-shadow: none;
    }
  }

   
  .e-input-group:not(.e-disabled):active:not(.e-success):not(.e-warning):not(.e-error),
  .e-input-group.e-control-wrapper:not(.e-disabled):active:not(.e-success):not(.e-warning):not(.e-error) {
    @if $input-skin-name == 'fabric' or $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' or $input-skin-name == 'highcontrast' {
      border-color: $input-active-border-color;
    }
    @if $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4'  {
      -webkit-box-shadow: $input-border-shadow;
      box-shadow: $input-border-shadow;
    }
    @if $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' {
      box-shadow: $input-group-focus-box-shadow;
    }
  }

  .e-input-group.e-disabled:not(.e-success):not(.e-warning):not(.e-error),
  .e-input-group.e-control-wrapper.e-disabled:not(.e-success):not(.e-warning):not(.e-error) {
    @if $input-skin-name == 'fabric' or $input-skin-name == 'FluentUI' or $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'highcontrast' {
      border-color: $input-disable-border-color;
    }
  }

  .e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left) .e-input:focus,
  .e-input-group.e-float-icon-left.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input:focus,
  .e-input-group.e-control-wrapper.e-input-focus:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left) .e-input:focus,
  .e-input-group.e-control-wrapper.e-float-icon-left.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input:focus,
  .e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left) .e-input,
  .e-input-group.e-float-icon-left.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input,
  .e-input-group.e-control-wrapper.e-input-focus:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left) .e-input,
  .e-input-group.e-control-wrapper.e-float-icon-left.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      border-bottom-color: transparent;
      border-top-color: transparent;
    }
  }

  .e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input:focus,
  .e-input-group.e-control-wrapper.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input:focus,
  .e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input,
  .e-input-group.e-control-wrapper.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input {
    @if $input-skin-name != 'material' and $input-skin-name != 'Material3' {
      border-bottom-color: transparent;
      border-top-color: transparent;
    }
  }

  .e-input-group.e-success,
  .e-input-group.e-control-wrapper.e-success,
  .e-float-icon-left.e-input-group.e-success,
  .e-float-icon-left.e-control-wrapper.e-input-group.e-success,
  .e-input-group.e-warning,
  .e-input-group.e-control-wrapper.e-warning,
  .e-float-icon-left.e-input-group.e-warning,
  .e-float-icon-left.e-input-group.e-control-wrapper.e-warning,
  .e-input-group.e-error,
  .e-input-group.e-control-wrapper.e-error,
  .e-float-icon-left.e-input-group.e-error,
  .e-float-icon-left.e-input-group.e-control-wrapper.e-error,
  .e-input-group.e-float-icon-left,
  .e-input-group.e-control-wrapper.e-float-icon-left {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-color: transparent;
    }
  }

  .e-input-group.e-success,
  .e-input-group.e-control-wrapper.e-success,
  .e-input-group.e-success:not(.e-float-icon-left),
  .e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left) {
    @include input-state-color ($input-group-success-color);
  }

  .e-input-group.e-float-icon-left.e-success .e-input-in-wrap,
  .e-input-group.e-control-wrapper.e-float-icon-left.e-success .e-input-in-wrap {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      @include input-state-color ($input-group-success-color);
    }
  }

  .e-input-group.e-warning,
  .e-input-group.e-control-wrapper.e-warning,
  .e-input-group.e-warning:not(.e-float-icon-left),
  .e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left) {
    @include input-state-color ($input-group-warning-color);
  }

  .e-input-group.e-float-icon-left.e-warning .e-input-in-wrap,
  .e-input-group.e-control-wrapper.e-float-icon-left.e-warning .e-input-in-wrap {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      @include input-state-color ($input-group-warning-color);
    }
  }

  .e-input-group.e-error,
  .e-input-group.e-control-wrapper.e-error,
  .e-input-group.e-error:not(.e-float-icon-left),
  .e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left) {
    @include input-state-color ($input-group-error-color);
  }

  .e-input-group.e-float-icon-left.e-error .e-input-in-wrap,
  .e-input-group.e-control-wrapper.e-float-icon-left.e-error .e-input-in-wrap {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      @include input-state-color ($input-group-error-color);
    }
  }

  .e-float-input .e-clear-icon,
  .e-float-input.e-control-wrapper .e-clear-icon,
  .e-input-group .e-clear-icon,
  .e-input-group.e-control-wrapper .e-clear-icon {
    color: $input-clear-icon-color;
  }

  .e-float-input .e-clear-icon:active,
  .e-float-input.e-control-wrapper .e-clear-icon:active,
  .e-input-group .e-clear-icon:active,
  .e-input-group.e-control-wrapper .e-clear-icon:active {
    @if ($input-skin-name == 'FluentUI') {
      color: $input-group-pressed-color;
    }
  }

  .e-float-input .e-clear-icon:hover,
  .e-float-input.e-control-wrapper .e-clear-icon:hover,
  .e-input-group .e-clear-icon:hover,
  .e-input-group.e-control-wrapper .e-clear-icon:hover {
    color: $input-clear-icon-hover-color;
    @if ($input-skin-name == 'FluentUI') {
      background: $input-clear-icon-hover-bg-color;
    }
    @if $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      border: $input-clear-icon-hover-border;
      background: $input-clear-icon-hover-bg-color;
    }
  }

  .e-float-input:not(.e-disabled) .e-clear-icon:hover,
  .e-float-input.e-control-wrapper:not(.e-disabled) .e-clear-icon:hover,
  .e-input-group:not(.e-disabled) .e-clear-icon:hover,
  .e-input-group.e-control-wrapper:not(.e-disabled) .e-clear-icon:hover {
    @if $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4'  {
      color: $input-clear-icon-hover-color;
    }
    @if ($input-skin-name == 'FluentUI') {
      background: $input-clear-icon-hover-bg-color;
    }
  }

  .e-float-input.e-input-focus .e-input:focus,
  .e-float-input.e-control-wrapper.e-input-focus .e-input:focus {
    border-bottom-color: transparent;
    border-top-color: transparent;
  }

  .e-float-input:not(.e-error) input:focus ~ label.e-float-text,
  .e-float-input:not(.e-error) input:valid ~ label.e-float-text,
  .e-float-input:not(.e-error) input ~ label.e-label-top.e-float-text,
  .e-float-input:not(.e-error) input[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input:not(.e-error) input label.e-float-text.e-label-top,
  .e-float-input:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-float-input.e-small:not(.e-error) input:focus ~ label.e-float-text,
  .e-float-input.e-small:not(.e-error) input:valid ~ label.e-float-text,
  .e-float-input.e-small:not(.e-error) input ~ label.e-label-top.e-float-text,
  .e-float-input.e-small:not(.e-error) input[readonly] ~ label.e-float-text,
  .e-float-input.e-small:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-small .e-float-input:not(.e-error) input:focus ~ label.e-float-text,
  .e-small .e-float-input:not(.e-error) input:valid ~ label.e-float-text,
  .e-small .e-float-input:not(.e-error) input ~ label.e-label-top.e-float-text,
  .e-small .e-float-input:not(.e-error) input[readonly] ~ label.e-float-text.e-label-top,
  .e-small .e-float-input:not(.e-error) input[disabled] ~ label.e-float-text.e-label-top,
  .e-float-input.e-small:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-small .e-float-input:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small:not(.e-error) input:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small:not(.e-error) input:valid ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small:not(.e-error) input ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-small:not(.e-error) input[readonly] ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-small .e-float-input.e-control-wrapper:not(.e-error) input:focus ~ label.e-float-text,
  .e-small .e-float-input.e-control-wrapper:not(.e-error) input:valid ~ label.e-float-text,
  .e-small .e-float-input.e-control-wrapper:not(.e-error) input ~ label.e-label-top.e-float-text,
  .e-small .e-float-input.e-control-wrapper:not(.e-error) input[readonly] ~ label.e-float-text.e-label-top,
  .e-small .e-float-input.e-control-wrapper:not(.e-error) input[disabled] ~ label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper.e-small:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-small .e-float-input.e-control-wrapper:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-float-input:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-float-input:not(.e-error) textarea:valid ~ label.e-float-text,
  .e-float-input:not(.e-error) textarea ~ label.e-label-top.e-float-text,
  .e-float-input:not(.e-error) textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input:not(.e-error) textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input:not(.e-error) textarea label.e-float-text.e-label-top,
  .e-float-input.e-small:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-float-input.e-small:not(.e-error) textarea:valid ~ label.e-float-text,
  .e-float-input.e-small:not(.e-error) textarea ~ label.e-label-top.e-float-text,
  .e-float-input.e-small:not(.e-error) textarea[readonly] ~ label.e-float-text,
  .e-float-input.e-small:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-small .e-float-input:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-small .e-float-input:not(.e-error) textarea:valid ~ label.e-float-text,
  .e-small .e-float-input:not(.e-error) textarea ~ label.e-label-top.e-float-text,
  .e-small .e-float-input:not(.e-error) textarea[readonly] ~ label.e-float-text.e-label-top,
  .e-small .e-float-input:not(.e-error) textarea[disabled] ~ label.e-float-text.e-label-top,
  .e-float-input:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small .e-float-input:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small.e-float-input:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-autofill:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small .e-float-input.e-control-wrapper.e-autofill:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small.e-float-input.e-control-wrapper.e-autofill:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-float-input:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small .e-float-input:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small.e-float-input:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-autofill:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small .e-float-input.e-control-wrapper.e-autofill:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small.e-float-input.e-control-wrapper.e-autofill:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text {
    color: $float-label-font-color;
  }

  .e-float-input.e-control-wrapper:not(.e-error) input:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper:not(.e-error) input:valid ~ label.e-float-text,
  .e-float-input.e-control-wrapper:not(.e-error) input ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper:not(.e-error) input[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input .e-control-wrapper:not(.e-error) input label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-float-input.e-control-wrapper:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper:not(.e-error) textarea:valid ~ label.e-float-text,
  .e-float-input.e-control-wrapper:not(.e-error) textarea ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper:not(.e-error) textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper:not(.e-error) textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper:not(.e-error) textarea label.e-float-text.e-label-top {
    @if $input-skin-name != 'fluent2' {
      color: $float-label-font-color;
    }
    @else {
      color: $float-label-top-font-color;
    }
  }

  .e-float-input:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  
  .e-float-input.e-small:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-small:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input.e-control-wrapper:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    color: $input-header-font-color;
    @if $input-skin-name == 'highcontrast' {
      color: $input-placeholder;
    }
  }

  .e-float-input.e-control-wrapper.e-small:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small:not(.e-error) textarea:valid ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small:not(.e-error) textarea ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-small:not(.e-error) textarea[readonly] ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-small .e-float-input.e-control-wrapper:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-small .e-float-input.e-control-wrapper:not(.e-error) textarea:valid ~ label.e-float-text,
  .e-small .e-float-input.e-control-wrapper:not(.e-error) textarea ~ label.e-label-top.e-float-text,
  .e-small .e-float-input.e-control-wrapper:not(.e-error) textarea[readonly] ~ label.e-float-text.e-label-top,
  .e-small .e-float-input.e-control-wrapper:not(.e-error) textarea[disabled] ~ label.e-float-text.e-label-top {
    color: $float-label-font-color;
  }

  .e-float-input.e-input-group.e-disabled .e-float-text,
  .e-float-input.e-input-group.e-disabled .e-float-text.e-label-top,
  .e-float-input input[disabled] ~ label.e-float-text,
  .e-float-input input[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-disabled label.e-float-text,
  .e-float-input.e-disabled label.e-float-text.e-label-top,
  .e-float-input:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-float-input:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-small:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-float-input.e-small:not(.e-error) input[disabled] ~ label.e-float-text.e-label-top,
  .e-float-input textarea[disabled] ~ label.e-float-text,
  .e-float-input textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-float-input:not(.e-error) textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-small:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-float-input.e-small:not(.e-error) textarea[disabled] ~ label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper.e-input-group.e-disabled .e-float-text,
  .e-float-input.e-control-wrapper.e-input-group.e-disabled .e-float-text.e-label-top,
  .e-float-input.e-control-wrapper.e-disabled input[disabled] ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-disabled input[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper input[disabled] ~ label.e-float-text,
  .e-float-input.e-control-wrapper input[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-disabled label.e-float-text,
  .e-float-input.e-control-wrapper.e-disabled label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-float-input.e-control-wrapper:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-small:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small:not(.e-error) input[disabled] ~ label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper.e-disabled:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-disabled:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-disabled.e-small:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-disabled.e-small:not(.e-error) input[disabled] ~ label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper.e-disabled textarea[disabled] ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-disabled textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper textarea[disabled] ~ label.e-float-text,
  .e-float-input.e-control-wrapper textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-float-input.e-control-wrapper:not(.e-error) textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-small:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small:not(.e-error) textarea[disabled] ~ label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper.e-disabled:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-disabled:not(.e-error) textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-disabled.e-small:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-disabled.e-small:not(.e-error) textarea[disabled] ~ label.e-float-text.e-label-top {
    color: $float-label-disbale-font-color;
  }

  .e-float-input:not(.e-error) input:focus ~ label.e-float-text,
  .e-float-input.e-small:not(.e-error) input:focus ~ label.e-float-text,
  .e-small .e-float-input:not(.e-error) input:focus ~ label.e-float-text,
  .e-float-input:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-float-input.e-small:not(.e-error) input[readonly]:focus ~ label.e-float-text,
  .e-small .e-float-input:not(.e-error) input[readonly]:focus ~ label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper.e-small:not(.e-error) input[readonly]:focus ~ label.e-float-text,
  .e-small .e-float-input.e-control-wrapper:not(.e-error) input[readonly]:focus ~ label.e-float-text.e-label-top,
  .e-float-input:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-small:not(.e-error).e-input-focus input[readonly] ~ label.e-float-text,
  .e-small .e-float-input:not(.e-error).e-input-focus input[readonly] ~ label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper.e-small:not(.e-error).e-input-focus input[readonly] ~ label.e-float-text,
  .e-small .e-float-input.e-control-wrapper:not(.e-error).e-input-focus input[readonly] ~ label.e-float-text.e-label-top,
  .e-float-input:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-float-input.e-small:not(.e-error) textarea[readonly]:focus ~ label.e-float-text,
  .e-small .e-float-input:not(.e-error) textarea[readonly]:focus ~ label.e-float-text.e-label-top,
  .e-small .e-float-input.e-control-wrapper:not(.e-error) textarea[readonly]:focus ~ label.e-float-text.e-label-top,
  .e-float-input:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-small:not(.e-error).e-input-focus textarea[readonly] ~ label.e-float-text,
  .e-small .e-float-input:not(.e-error).e-input-focus textarea[readonly] ~ label.e-float-text.e-label-top,
  .e-small .e-float-input.e-control-wrapper:not(.e-error).e-input-focus textarea[readonly] ~ label.e-float-text.e-label-top,
  .e-float-input:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-float-input.e-small:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-small .e-float-input:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper:not(.e-error) input:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small:not(.e-error) input:focus ~ label.e-float-text,
  .e-small .e-float-input.e-control-wrapper:not(.e-error) input:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-small .e-float-input.e-control-wrapper:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-float-input:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-float-input.e-small:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-small .e-float-input:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-float-input.e-control-wrapper:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-small .e-float-input.e-control-wrapper:not(.e-error).e-input-focus input ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      color: $input-active-accent-color;
    }
  }

  input.e-input#{$css}:hover:not(.e-success):not(.e-warning):not(.e-error):not([disabled]):not(:focus),
  textarea.e-input#{$css}:hover:not(.e-success):not(.e-warning):not(.e-error):not([disabled]) {
    @if $input-skin-name == 'fabric' or $input-skin-name == 'FluentUI' or $input-skin-name == 'bootstrap' {
      border-color: $input-group-border-color-hover;
    }
  }

  .e-input-group:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-input-group.e-control-wrapper:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-float-input.e-control-wrapper:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-float-input:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-input-group.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) .e-input-in-wrap:hover,
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) .e-input-in-wrap:hover,
  .e-float-input.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) .e-input-in-wrap:hover,
  .e-float-input.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) .e-input-in-wrap:hover,
  .e-float-input:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) input:not([disabled]),
  .e-float-input:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) textarea:not([disabled]),
  .e-float-input.e-control-wrapper:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) input:not([disabled]),
  .e-float-input.e-control-wrapper:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) textarea:not([disabled]),
  .e-float-input.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) .e-input-in-wrap:hover {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-color: $input-group-border-color-hover;
    }
    @else if $input-skin-name == 'fluent2' {
      border-bottom-color: $input-group-full-border-bottom-color;
    }
  }

  .e-underline.e-input-group:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-underline.e-input-group.e-control-wrapper:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-underline.e-float-input.e-control-wrapper:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-underline.e-float-input:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-underline.e-input-group.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) .e-input-in-wrap:hover,
  .e-underline.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) .e-input-in-wrap:hover,
  .e-underline.e-float-input.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) .e-input-in-wrap:hover,
  .e-underline.e-float-input.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) .e-input-in-wrap:hover,
  .e-underline.e-float-input:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) input:not([disabled]),
  .e-underline.e-float-input:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) textarea:not([disabled]),
  .e-underline.e-float-input.e-control-wrapper:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) input:not([disabled]),
  .e-underline.e-float-input.e-control-wrapper:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) textarea:not([disabled]),
  .e-underline.e-float-input.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) .e-input-in-wrap:hover {
    @if $input-skin-name == 'fluent' {
      border-color: $input-group-border-color-hover;
    }
  }

  .e-input-group:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-input-group.e-control-wrapper:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-float-input:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-float-input:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) input:not([disabled]),
  .e-float-input:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) textarea:not([disabled]),
  .e-float-input.e-control-wrapper:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-float-input.e-control-wrapper:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) input:not([disabled]),
  .e-float-input.e-control-wrapper:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) textarea:not([disabled]) {
    @if $input-skin-name != 'material' and $input-skin-name != 'Material3' and $input-skin-name != 'bootstrap' and $input-skin-name != 'bootstrap4' and $input-skin-name != 'bootstrap5' and $input-skin-name != 'bootstrap5.3' and $input-skin-name != 'highcontrast' and $input-skin-name != 'tailwind' and $input-skin-name != 'FluentUI' and $input-skin-name != 'fluent2' {
      border-color: $input-group-border-color-hover;
    }
  }

  .e-input-group:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-input-group.e-control-wrapper:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-float-input.e-input-group:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-float-input.e-input-group.e-control-wrapper:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-float-input:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) input:not([disabled]):not(:focus),
  .e-float-input:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) textarea:not([disabled]):not(:focus),
  .e-float-input.e-control-wrapper:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) input:not([disabled]):not(:focus),
  .e-float-input.e-control-wrapper:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) textarea:not([disabled]):not(:focus) {
    @if $input-skin-name == 'highcontrast' {
      border-color: $input-group-border-color-hover;
    }
  }

  .e-input-group.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-input-group.e-control-wrapper.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-float-input.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-float-input.e-control-wrapper.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-float-input.e-input-focus:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) input:not([disabled]),
  .e-float-input.e-control-wrapper.e-input-focus:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) input:not([disabled]),
  .e-float-input.e-input-focus:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) textarea:not([disabled]),
  .e-float-input.e-control-wrapper.e-input-focus:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) textarea:not([disabled]) {
    @if $input-skin-name != 'material' and $input-skin-name != 'Material3' and $input-skin-name != 'fluent2' {
      border-color: $input-group-border-color-focus;
    }
  }

  .e-input-group:active:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-input-group.e-control-wrapper:active:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-float-input:active:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-float-input.e-control-wrapper:active:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-float-input:active:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) input:not([disabled]),
  .e-float-input.e-control-wrapper:active:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) input:not([disabled]),
  .e-float-input:active:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) textarea:not([disabled]),
  .e-float-input.e-control-wrapper:active:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) textarea:not([disabled]) {
    @if $input-skin-name == 'fabric' or $input-skin-name == 'FluentUI' or $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'highcontrast' or $input-skin-name == 'FluentUI' {
      border-color: $input-active-border-color;
    }
  }

  .e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
  .e-input-group.e-control-wrapper.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
  .e-float-input.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
  .e-float-input.e-control-wrapper.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
  .e-input-group.e-float-icon-left.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap,
  .e-input-group.e-control-wrapper.e-float-icon-left.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap,
  .e-input-group.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-input-group.e-control-wrapper.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-float-input.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-float-input.e-control-wrapper.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-input-group.e-input-focus.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) .e-input-in-wrap:hover,
  .e-input-group.e-control-wrapper.e-input-focus.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) .e-input-in-wrap:hover,
  .e-float-input.e-input-focus.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) .e-input-in-wrap:hover,
  .e-float-input.e-input-focus:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) input:not([disabled]),
  .e-float-input.e-input-focus:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) textarea:not([disabled]),
  .e-float-input.e-control-wrapper.e-input-focus.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) .e-input-in-wrap:hover,
  .e-float-input.e-control-wrapper.e-input-focus:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) input:not([disabled]),
  .e-float-input.e-control-wrapper.e-input-focus:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) textarea:not([disabled]) {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-color: $input-group-border-color-hover;
    }
  }

  .e-outline.e-input-group,
  .e-outline.e-input-group.e-control-wrapper,
  .e-outline.e-float-input,
  .e-outline.e-float-input.e-input-group,
  .e-outline.e-float-input.e-control-wrapper,
  .e-outline.e-float-input.e-input-group.e-control-wrapper {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      color: $outline-input-font-color;
    }
  }

  .e-outline.e-input-group.e-disabled,
  .e-outline.e-input-group.e-control-wrapper.e-disabled,
  .e-outline.e-float-input.e-disabled,
  .e-outline.e-float-input.e-input-group.e-disabled,
  .e-outline.e-float-input.e-control-wrapper.e-disabled,
  .e-outline.e-float-input.e-input-group.e-control-wrapper.e-disabled {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      color: $outline-disabled-input-font-color;
    }
  }

  .e-outline.e-input-group:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-outline.e-input-group.e-control-wrapper:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-outline.e-float-input.e-control-wrapper:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-outline.e-input-group.e-float-icon-left:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-outline.e-input-group.e-control-wrapper.e-float-icon-left:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-outline.e-float-input.e-float-icon-left:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-outline.e-float-input.e-control-wrapper.e-float-icon-left:not(.e-input-focus):hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-outline.e-float-input:hover:not(.e-input-focus):not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-color: $outline-hover-border-color;
      color: $outline-hover-font-color;
    }
  }

  .e-underline.e-input-group:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-underline.e-input-group.e-control-wrapper:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-underline.e-float-input.e-control-wrapper:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-underline.e-input-group.e-float-icon-left:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-underline.e-input-group.e-control-wrapper.e-float-icon-left:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-underline.e-float-input.e-float-icon-left:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-underline.e-float-input.e-control-wrapper.e-float-icon-left:not(.e-input-focus):hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-underline.e-float-input:hover:not(.e-input-focus):not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) {
    @if $input-skin-name == 'fluent2' {
      border-color: $outline-hover-border-color;
      color: $outline-hover-font-color;
    }
  }

  .e-underline.e-input-group:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-underline.e-input-group.e-control-wrapper:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-underline.e-float-input.e-control-wrapper:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-underline.e-input-group.e-float-icon-left:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-underline.e-input-group.e-control-wrapper.e-float-icon-left:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-underline.e-float-input.e-float-icon-left:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-underline.e-float-input.e-control-wrapper.e-float-icon-left:not(.e-input-focus):hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-underline.e-float-input:hover:not(.e-input-focus):not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) {
    @if $input-skin-name == 'fluent2' {
      border-bottom-color: $underline-hover-border-color;
      color: $outline-hover-font-color;
    }
  }

  .e-outline.e-input-group.e-valid-input:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) label.e-float-text::before,
  .e-outline.e-input-group.e-valid-input:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) label.e-float-text::after,
  .e-outline.e-input-group.e-control-wrapper.e-valid-input:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) label.e-float-text::before,
  .e-outline.e-input-group.e-control-wrapper.e-valid-input:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) label.e-float-text::after,
  .e-outline.e-float-input.e-control-wrapper.e-valid-input:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) label.e-float-text::before,
  .e-outline.e-float-input.e-control-wrapper.e-valid-input:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) label.e-float-text::after,
  .e-outline.e-input-group.e-float-icon-left.e-valid-input:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) label.e-float-text::before,
  .e-outline.e-input-group.e-float-icon-left.e-valid-input:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) label.e-float-text::after,
  .e-outline.e-input-group.e-control-wrapper.e-float-icon-left.e-valid-input:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) label.e-float-text::before,
  .e-outline.e-input-group.e-control-wrapper.e-float-icon-left.e-valid-input:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) label.e-float-text::after,
  .e-outline.e-float-input.e-float-icon-left:hover.e-valid-input:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) label.e-float-text::before,
  .e-outline.e-float-input.e-float-icon-left:hover.e-valid-input:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) label.e-float-text::after,
  .e-outline.e-float-input.e-control-wrapper.e-float-icon-left.e-valid-input:not(.e-input-focus):hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) label.e-float-text::before,
  .e-outline.e-float-input.e-control-wrapper.e-float-icon-left.e-valid-input:not(.e-input-focus):hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) label.e-float-text::after,
  .e-outline.e-float-input:hover.e-valid-input:not(.e-input-focus):not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) label.e-float-text::before,
  .e-outline.e-float-input:hover.e-valid-input:not(.e-input-focus):not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) label.e-float-text::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-color: $outline-hover-border-color;
    }
  }

  .e-outline.e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error),
  .e-outline.e-input-group.e-control-wrapper.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-color: $input-active-border-color;
      box-shadow: inset 1px 1px $input-active-border-color, inset -1px 0 $input-active-border-color, inset 0 -1px $input-active-border-color;
    }
  }

  .e-outline.e-input-group:not(.e-input-focus),
  .e-outline.e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left):not(.e-input-focus),
  .e-outline.e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left):not(.e-input-focus),
  .e-outline.e-input-group.e-float-icon-left:not(.e-input-focus),
  .e-outline.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-input-focus) {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-color: $outline-border-color;
    }
  }

  .e-outline.e-input-group.e-error,
  .e-outline.e-input-group.e-control-wrapper.e-error,
  .e-outline.e-input-group.e-error:not(.e-float-icon-left),
  .e-outline.e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left),
  .e-outline.e-float-input.e-error,
  .e-outline.e-float-input.e-input-group.e-error,
  .e-outline.e-float-input.e-control-wrapper.e-error,
  .e-outline.e-float-input.e-input-group.e-control-wrapper.e-error {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-color: $input-error-color;
    }
  }

  .e-outline.e-float-input.e-success.e-input-focus,
  .e-outline.e-float-input.e-control-wrapper.e-success.e-input-focus,
  .e-outline.e-float-input.e-input-group.e-success.e-input-focus,
  .e-outline.e-float-input.e-input-group.e-control-wrapper.e-success.e-input-focus,
  .e-outline.e-float-input.e-input-group.e-success.e-input-focus:not(.e-float-icon-left),
  .e-outline.e-float-input.e-input-group.e-control-wrapper.e-success.e-input-focus:not(.e-float-icon-left),
  .e-outline.e-float-input.e-warning.e-input-focus,
  .e-outline.e-float-input.e-control-wrapper.e-warning.e-input-focus,
  .e-outline.e-float-input.e-input-group.e-warning.e-input-focus,
  .e-outline.e-float-input.e-input-group.e-control-wrapper.e-warning.e-input-focus,
  .e-outline.e-float-input.e-input-group.e-warning.e-input-focus:not(.e-float-icon-left),
  .e-outline.e-float-input.e-input-group.e-control-wrapper.e-warning.e-input-focus:not(.e-float-icon-left),
  .e-outline.e-float-input.e-error.e-input-focus,
  .e-outline.e-float-input.e-control-wrapper.e-error.e-input-focus,
  .e-outline.e-float-input.e-input-group.e-error.e-input-focus,
  .e-outline.e-float-input.e-input-group.e-control-wrapper.e-error.e-input-focus,
  .e-outline.e-float-input.e-input-group.e-error.e-input-focus:not(.e-float-icon-left),
  .e-outline.e-float-input.e-input-group.e-control-wrapper.e-error.e-input-focus:not(.e-float-icon-left),
  .e-outline.e-float-input.e-input-group.e-disabled.e-valid-input:not(.e-input-focus),
  .e-outline.e-float-input.e-input-group.e-disabled.e-valid-input:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left):not(.e-input-focus),
  .e-outline.e-float-input.e-input-group.e-control-wrapper.e-disabled.e-valid-input:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left):not(.e-input-focus) {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-top-color: transparent;
    }
  }

  .e-outline.e-input-group.e-success,
  .e-outline.e-input-group.e-control-wrapper.e-success,
  .e-outline.e-input-group.e-success:not(.e-float-icon-left),
  .e-outline.e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left),
  .e-outline.e-float-input.e-success,
  .e-outline.e-float-input.e-input-group.e-success,
  .e-outline.e-float-input.e-control-wrapper.e-success,
  .e-outline.e-float-input.e-input-group.e-control-wrapper.e-success {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-color: $input-success-color;
    }
  }

  .e-outline.e-input-group.e-warning,
  .e-outline.e-input-group.e-control-wrapper.e-warning,
  .e-outline.e-input-group.e-warning:not(.e-float-icon-left),
  .e-outline.e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left),
  .e-outline.e-float-input.e-warning,
  .e-outline.e-float-input.e-input-group.e-warning,
  .e-outline.e-float-input.e-control-wrapper.e-warning,
  .e-outline.e-float-input.e-input-group.e-control-wrapper.e-warning {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-color: $input-warning-color;
    }
  }

  .e-outline.e-input-group.e-input-focus.e-error:not(.e-success):not(.e-warning),
  .e-outline.e-input-group.e-control-wrapper.e-input-focus.e-error:not(.e-success):not(.e-warning) {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      box-shadow: inset 1px 1px $input-error-color, inset -1px $zero-value $input-error-color, inset $zero-value -1px $input-error-color;
    }
  }

  .e-outline.e-input-group.e-input-focus.e-success:not(.e-error):not(.e-warning),
  .e-outline.e-input-group.e-control-wrapper.e-input-focus.e-success:not(.e-error):not(.e-warning) {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      box-shadow: inset 1px 1px $input-success-color, inset -1px $zero-value $input-success-color, inset $zero-value -1px $input-success-color;
    }
  }

  .e-outline.e-input-group.e-input-focus.e-warning:not(.e-error):not(.e-success),
  .e-outline.e-input-group.e-control-wrapper.e-input-focus.e-warning:not(.e-error):not(.e-success) {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      box-shadow: inset 1px 1px $input-warning-color, inset -1px $zero-value $input-warning-color, inset $zero-value -1px $input-warning-color;
    }
  }

  .e-outline.e-float-input.e-input-focus.e-error:not(.e-success):not(.e-warning),
  .e-outline.e-float-input.e-control-wrapper.e-input-focus.e-error:not(.e-success):not(.e-warning),
  .e-outline.e-float-input.e-input-group.e-input-focus.e-error:not(.e-success):not(.e-warning),
  .e-outline.e-float-input.e-input-group.e-control-wrapper.e-input-focus.e-error:not(.e-success):not(.e-warning) {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      box-shadow: inset 1px $zero-value $input-error-color, inset -1px $zero-value $input-error-color, inset $zero-value -1px $input-error-color;
    }
  }

  .e-outline.e-float-input.e-input-focus.e-success:not(.e-error):not(.e-warning),
  .e-outline.e-float-input.e-control-wrapper.e-input-focus.e-success:not(.e-error):not(.e-warning),
  .e-outline.e-float-input.e-input-group.e-input-focus.e-success:not(.e-error):not(.e-warning),
  .e-outline.e-float-input.e-input-group.e-control-wrapper.e-input-focus.e-success:not(.e-error):not(.e-warning) {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      box-shadow: inset 1px $zero-value $input-success-color, inset -1px $zero-value $input-success-color, inset $zero-value -1px $input-success-color;
    }
  }

  .e-outline.e-float-input.e-input-focus.e-warning:not(.e-error):not(.e-success),
  .e-outline.e-float-input.e-control-wrapper.e-input-focus.e-warning:not(.e-error):not(.e-success),
  .e-outline.e-float-input.e-input-group.e-input-focus.e-warning:not(.e-error):not(.e-success),
  .e-outline.e-float-input.e-input-group.e-control-wrapper.e-input-focus.e-warning:not(.e-error):not(.e-success) {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      box-shadow: inset 1px $zero-value $input-warning-color, inset -1px $zero-value $input-warning-color, inset $zero-value -1px $input-warning-color;
    }
  }

  .e-outline.e-input-group.e-disabled:not(.e-input-focus),
  .e-outline.e-input-group.e-disabled:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left):not(.e-input-focus),
  .e-outline.e-input-group.e-control-wrapper.e-disabled:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left):not(.e-input-focus),
  .e-outline.e-input-group.e-disabled.e-float-icon-left:not(.e-input-focus),
  .e-outline.e-input-group.e-disabled.e-control-wrapper.e-float-icon-left:not(.e-input-focus),
  .e-outline.e-float-input.e-input-group.e-disabled.e-valid-input:not(.e-input-focus) label.e-float-text::before,
  .e-outline.e-float-input.e-input-group.e-disabled.e-valid-input:not(.e-input-focus) label.e-float-text::after,
  .e-outline.e-float-input.e-input-group.e-disabled.e-valid-input:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left):not(.e-input-focus) label.e-float-text::before,
  .e-outline.e-float-input.e-input-group.e-disabled.e-valid-input:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left):not(.e-input-focus) label.e-float-text::after,
  .e-outline.e-float-input.e-input-group.e-control-wrapper.e-disabled.e-valid-input:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left):not(.e-input-focus) label.e-float-text::before,
  .e-outline.e-float-input.e-input-group.e-control-wrapper.e-disabled.e-valid-input:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left):not(.e-input-focus) label.e-float-text::after,
  .e-outline.e-disabled.e-valid-input:not(.e-input-focus) label.e-float-text::before,
  .e-outline.e-disabled.e-valid-input:not(.e-input-focus) label.e-float-text::after,
  .e-outline.e-disabled.e-float-input.e-valid-input:not(.e-input-focus) label.e-float-text::before,
  .e-outline.e-disabled.e-float-input.e-valid-input:not(.e-input-focus) label.e-float-text::after,
  .e-outline.e-disabled.e-float-input.e-control-wrapper.e-valid-input:not(.e-input-focus) label.e-float-text::before,
  .e-outline.e-disabled.e-float-input.e-control-wrapper.e-valid-input:not(.e-input-focus) label.e-float-text::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-color: $outline-disabled-border-color;
    }
  }

  .e-outline.e-float-input.e-success.e-input-focus input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-input-group.e-success.e-valid-input label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-small.e-success.e-input-focus input:focus ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-success input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-success textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-small.e-success textarea:focus ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-success textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-success input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-success input:focus ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-success input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-success textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-success textarea:focus ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-success textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-success label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-input-group.e-success label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-control-wrapper.e-success label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-valid-input.e-success:not(.e-input-focus) input:valid ~ label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-control-wrapper.e-valid-input.e-success:not(.e-input-focus) input:focus ~ label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-valid-input.e-success:not(.e-input-focus) textarea:valid ~ label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-control-wrapper.e-valid-input.e-success:not(.e-input-focus) textarea:focus ~ label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-success.e-input-focus input ~ label.e-float-text,
  .e-outline.e-float-input.e-small.e-success.e-input-focus input ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-success.e-input-focus input ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-success.e-input-focus input ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-success.e-input-focus input ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-success.e-input-focus input ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      color: $input-success-color;
    }
  }

  .e-outline.e-float-input.e-error.e-input-focus input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-input-group.e-error.e-valid-input label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-small.e-error.e-input-focus input:focus ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-error input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-error textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-small.e-error textarea:focus ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-error textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-error input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-error input:focus ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-error input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-error textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-error textarea:focus ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-error textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-error label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-input-group.e-error label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-control-wrapper.e-error label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-valid-input.e-error:not(.e-input-focus) input:valid ~ label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-control-wrapper.e-valid-input.e-error:not(.e-input-focus) input:focus ~ label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-valid-input.e-error:not(.e-input-focus) textarea:valid ~ label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-control-wrapper.e-valid-input.e-error:not(.e-input-focus) textarea:focus ~ label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-error.e-input-focus input ~ label.e-float-text,
  .e-outline.e-float-input.e-small.e-error.e-input-focus input ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-error.e-input-focus input ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-error.e-input-focus input ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-error.e-input-focus input ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-error.e-input-focus input ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      color: $input-error-color;
    }
  }

  .e-outline.e-float-input.e-warning.e-input-focus input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-input-group.e-warning.e-valid-input label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-small.e-warning.e-input-focus input:focus ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-warning input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-warning textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-small.e-warning textarea:focus ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-warning textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-warning input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-warning input:focus ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-warning input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-warning textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-warning textarea:focus ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-warning textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-warning label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-input-group.e-warning label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-control-wrapper.e-warning label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-valid-input.e-warning:not(.e-input-focus) input:valid ~ label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-control-wrapper.e-valid-input.e-warning:not(.e-input-focus) input:focus ~ label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-valid-input.e-warning:not(.e-input-focus) textarea:valid ~ label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-control-wrapper.e-valid-input.e-warning:not(.e-input-focus) textarea:focus ~ label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-warning.e-input-focus input ~ label.e-float-text,
  .e-outline.e-float-input.e-small.e-warning.e-input-focus input ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-warning.e-input-focus input ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-warning.e-input-focus input ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-warning.e-input-focus input ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-warning.e-input-focus input ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      color: $input-warning-color;
    }
  }

  .e-outline.e-float-input.e-input-group.e-valid-input.e-input-focus label.e-label-top,
  .e-outline.e-float-input.e-input-group.e-input-focus label.e-label-top,
  .e-outline.e-float-input.e-control-wrapper.e-input-group.e-valid-input.e-input-focus label.e-label-top,
  .e-outline.e-float-input.e-control-wrapper.e-input-group.e-input-focus label.e-label-top {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      color: $input-active-border-color;
    }
  }

  .e-outline.e-input[disabled],
  .e-outline.e-input-group .e-input[disabled],
  .e-outline.e-input-group.e-control-wrapper .e-input[disabled],
  .e-outline.e-input-group.e-disabled,
  .e-outline.e-input-group.e-control-wrapper.e-disabled,
  .e-outline.e-float-input input[disabled],
  .e-outline.e-float-input.e-control-wrapper input[disabled],
  .e-outline.e-float-input textarea[disabled],
  .e-outline.e-float-input.e-control-wrapper textarea[disabled],
  .e-outline.e-float-input.e-disabled,
  .e-outline.e-float-input.e-control-wrapper.e-disabled,
  .e-outline.e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left).e-disabled,
  .e-outline.e-input-group.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error).e-disabled .e-input-in-wrap,
  .e-outline.e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left).e-disabled,
  .e-outline.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error).e-disabled .e-input-in-wrap {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      background-image: none;
      border-bottom-color: $outline-disabled-border-color;
      border-color: $outline-disabled-border-color;
    }
  }

  .e-outline.e-float-input.e-input-group.e-disabled .e-float-text,
  .e-outline.e-float-input.e-input-group.e-disabled .e-float-text.e-label-top,
  .e-outline.e-float-input input[disabled] ~ label.e-float-text,
  .e-outline.e-float-input input[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-disabled label.e-float-text,
  .e-outline.e-float-input.e-disabled label.e-float-text.e-label-top,
  .e-outline.e-float-input:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-outline.e-float-input:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-small:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-outline.e-float-input.e-small:not(.e-error) input[disabled] ~ label.e-float-text.e-label-top,
  .e-outline.e-float-input textarea[disabled] ~ label.e-float-text,
  .e-outline.e-float-input textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-outline.e-float-input:not(.e-error) textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-small:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-outline.e-float-input.e-small:not(.e-error) textarea[disabled] ~ label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-control-wrapper.e-input-group.e-disabled .e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-input-group.e-disabled .e-float-text.e-label-top,
  .e-outline.e-float-input.e-control-wrapper.e-disabled input[disabled] ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-disabled input[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper input[disabled] ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper input[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-disabled label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-disabled label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-control-wrapper:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small:not(.e-error) input[disabled] ~ label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-control-wrapper.e-disabled:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-disabled:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-disabled.e-small:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-disabled.e-small:not(.e-error) input[disabled] ~ label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-control-wrapper.e-disabled textarea[disabled] ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-disabled textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper textarea[disabled] ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper:not(.e-error) textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small:not(.e-error) textarea[disabled] ~ label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-control-wrapper.e-disabled:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-disabled:not(.e-error) textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-disabled.e-small:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-disabled.e-small:not(.e-error) textarea[disabled] ~ label.e-float-text.e-label-top {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      color: $outline-float-label-disbale-font-color;
    }
  }

  .e-outline.e-input-group.e-disabled .e-input-group-icon,
  .e-outline.e-input-group.e-control-wrapper.e-disabled .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      color: $outline-input-group-disabled-color;
    }
  }

  .e-outline.e-float-input:not(.e-disabled) .e-clear-icon:hover,
  .e-outline.e-float-input.e-control-wrapper:not(.e-disabled) .e-clear-icon:hover,
  .e-outline.e-input-group:not(.e-disabled) .e-clear-icon:hover,
  .e-outline.e-input-group.e-control-wrapper:not(.e-disabled) .e-clear-icon:hover {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      color: $outline-input-clear-icon-hover-color;
    }
  }

  .e-outline.e-float-input:not(.e-disabled) .e-clear-icon:active,
  .e-outline.e-float-input.e-control-wrapper:not(.e-disabled) .e-clear-icon:active,
  .e-outline.e-input-group:not(.e-disabled) .e-clear-icon:active,
  .e-outline.e-input-group.e-control-wrapper:not(.e-disabled) .e-clear-icon:active {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      color: $outline-input-clear-icon-active-color;
    }
  }

  .e-filled.e-input-group,
  .e-filled.e-input-group.e-control-wrapper,
  .e-filled.e-float-input,
  .e-filled.e-float-input.e-input-group,
  .e-filled.e-float-input.e-control-wrapper,
  .e-filled.e-float-input.e-input-group.e-control-wrapper {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      background: $filled-input-bg-color;
      transition: $background-color-transition;
    }
  }

  .e-filled.e-input-group:hover,
  .e-filled.e-input-group.e-control-wrapper:hover,
  .e-filled.e-float-input:hover,
  .e-filled.e-float-input.e-input-group:hover,
  .e-filled.e-float-input.e-control-wrapper:hover,
  .e-filled.e-float-input.e-input-group.e-control-wrapper:hover {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      background: $filled-input-overlay-focused-bg-color;
      transition: $background-color-transition;
    }
  }

  .e-filled.e-input-group.e-input-focus,
  .e-filled.e-input-group.e-control-wrapper.e-input-focus,
  .e-filled.e-float-input.e-input-focus,
  .e-filled.e-float-input.e-input-group.e-input-focus,
  .e-filled.e-float-input.e-control-wrapper.e-input-focus,
  .e-filled.e-float-input.e-input-group.e-control-wrapper.e-input-focus,
  .e-filled.e-input-group:hover.e-input-focus,
  .e-filled.e-input-group:hover.e-control-wrapper.e-input-focus,
  .e-filled.e-float-input:hover.e-input-focus,
  .e-filled.e-float-input:hover.e-input-group.e-input-focus,
  .e-filled.e-float-input:hover.e-control-wrapper.e-input-focus,
  .e-filled.e-float-input:hover.e-input-group.e-control-wrapper.e-input-focus {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      background: $filled-input-overlay-activated-bg-color;
      transition: $background-color-transition;
    }
  }

  .e-filled.e-input-group:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-filled.e-input-group.e-control-wrapper:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-filled.e-float-input.e-control-wrapper:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-filled.e-float-input:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-filled.e-float-input:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-filled.e-float-input.e-control-wrapper:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-filled.e-input-group.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-filled.e-input-group.e-control-wrapper.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-filled.e-float-input.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-filled.e-float-input.e-control-wrapper.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-filled.e-float-input.e-input-focus:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-filled.e-float-input.e-control-wrapper.e-input-focus:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-filled.e-input-group.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-filled.e-input-group.e-control-wrapper.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-filled.e-float-input.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-filled.e-float-input.e-control-wrapper.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-filled.e-float-input.e-input-focus:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-filled.e-float-input.e-control-wrapper.e-input-focus:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-filled.e-input-group:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled).e-float-icon-left,
  .e-filled.e-input-group.e-control-wrapper:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled).e-float-icon-left,
  .e-filled.e-float-input.e-control-wrapper:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled).e-float-icon-left,
  .e-filled.e-float-input:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled).e-float-icon-left,
  .e-filled.e-input-group.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled).e-float-icon-left,
  .e-filled.e-input-group.e-control-wrapper.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled).e-float-icon-left,
  .e-filled.e-float-input.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled).e-float-icon-left,
  .e-filled.e-float-input.e-control-wrapper.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled).e-float-icon-left,
  .e-filled.e-input-group.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled).e-float-icon-left,
  .e-filled.e-input-group.e-control-wrapper.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled).e-float-icon-left,
  .e-filled.e-float-input.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled).e-float-icon-left,
  .e-filled.e-float-input.e-control-wrapper.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled).e-float-icon-left {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      border-color: $filled-input-hover-border-color;
    }
  }

  .e-filled.e-input-group.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error),
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error),
  .e-filled.e-float-input:not(.e-success):not(.e-warning):not(.e-error),
  .e-filled.e-float-input.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error) {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      border-color: $input-group-full-border-color;
    }
  }

  .e-filled.e-float-input.e-success,
  .e-filled.e-float-input.e-control-wrapper.e-success,
  .e-filled.e-input-group.e-float-icon-left.e-success,
  .e-filled.e-input-group.e-float-icon-left.e-control-wrapper.e-success {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      @include input-state-color ($input-success-color);
    }
  }

  .e-filled.e-float-input.e-warning,
  .e-filled.e-float-input.e-control-wrapper.e-warning,
  .e-filled.e-input-group.e-float-icon-left.e-warning,
  .e-filled.e-input-group.e-float-icon-left.e-control-wrapper.e-warning {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      @include input-state-color ($input-warning-color);
    }
  }

  .e-filled.e-float-input.e-error,
  .e-filled.e-float-input.e-control-wrapper.e-error,
  .e-filled.e-input-group.e-float-icon-left.e-error,
  .e-filled.e-input-group.e-float-icon-left.e-control-wrapper.e-error {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      @include input-state-color ($input-error-color);
    }
  }

  .e-filled label.e-float-text,
  .e-filled.e-float-input:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-small:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input.e-control-wrapper:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input:not(.e-error) input:focus ~ label.e-float-text,
  .e-filled.e-float-input:not(.e-error) input:valid ~ label.e-float-text,
  .e-filled.e-float-input:not(.e-error) input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input:not(.e-error) input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input:not(.e-error) input label.e-float-text.e-label-top,
  .e-filled.e-float-input:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-input-group.e-filled textarea.e-input:-ms-input-placeholder,
  input.e-filled.e-input#{$css}::-webkit-input-placeholder,
  .e-input-group.e-filled.e-control-wrapper input.e-input::-webkit-input-placeholder,
  .e-input-group.e-filled input.e-input::-webkit-input-placeholder,
  textarea.e-filled.e-input#{$css}::-webkit-input-placeholder,
  .e-input-group.e-filled textarea.e-input::-webkit-input-placeholder,
  .e-input-group.e-filled.e-control-wrapper textarea.e-input::-webkit-input-placeholder,
  .e-filled.e-float-input:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small .e-filled.e-float-input:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small.e-filled.e-float-input:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-autofill:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-autofill:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small.e-filled.e-float-input.e-control-wrapper.e-autofill:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-filled.e-float-input:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small .e-filled.e-float-input:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small.e-filled.e-float-input:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-autofill:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-autofill:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small.e-filled.e-float-input.e-control-wrapper.e-autofill:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  input.e-filled.e-input#{$css}:-moz-placeholder,
  .e-input-group.e-filled input.e-input:-moz-placeholder,
  .e-input-group.e-filled input.e-input:-moz-placeholder,
  .e-input-group.e-filled.e-control-wrapper input.e-input:-moz-placeholder,
  input.e-filled.e-input#{$css}:-moz-placeholder,
  .e-input-group.e-filled input.e-input:-moz-placeholder,
  .e-input-group.e-filled.e-control-wrapper input.e-input:-moz-placeholder,
  textarea.e-filled.e-input#{$css}:-moz-placeholder,
  .e-input-group.e-filled textarea.e-input:-moz-placeholder,
  .e-input-group.e-filled.e-control-wrapper textarea.e-input:-moz-placeholder,
  input.e-filled.e-input#{$css}::-moz-placeholder,
  .e-input-group.e-filled input.e-input::-moz-placeholder,
  .e-input-group.e-filled.e-control-wrapper input.e-input::-moz-placeholder,
  textarea.e-filled.e-input#{$css}::-moz-placeholder,
  .e-input-group.e-filled textarea.e-input::-moz-placeholder,
  .e-input-group.e-filled.e-control-wrapper textarea.e-input::-moz-placeholder,
  input.e-filled.e-input#{$css}:-ms-input-placeholder,
  .e-input-group.e-filled input.e-input:-ms-input-placeholder,
  .e-input-group.e-filled.e-control-wrapper input.e-input:-ms-input-placeholder,
  textarea.e-filled.e-input#{$css}:-ms-input-placeholder,
  .e-input-group.e-filled.e-control-wrapper textarea.e-input:-ms-input-placeholder {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      color: $filled-input-float-label-color;
    }
  }

  .e-filled.e-float-input.e-error label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-error label.e-float-text,
  .e-filled.e-float-input.e-error input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-error input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-error textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-error textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-error.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-error.e-input-focus input ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      color: $input-error-color;
    }
  }

  .e-filled.e-float-input.e-success label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-success label.e-float-text,
  .e-filled.e-float-input.e-success input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-success input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-success textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-success textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-success.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-success.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-success input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-success input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-success input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-success input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-success input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-success input label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-success.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-success input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-success input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-success input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-success input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-success input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-success input label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-success.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-success input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-success input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-success input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-success input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-success input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input .e-control-wrapper.e-success input label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper.e-success.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-success input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-success input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-success input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small.e-success input[readonly] ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-success input[disabled] ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-success input:focus ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-success input:valid ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-success input ~ label.e-label-top.e-float-text,
  .e-small .e-filled.e-float-input.e-success input[readonly] ~ label.e-float-text.e-label-top,
  .e-small .e-filled.e-float-input.e-success input[disabled] ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-small.e-success.e-input-focus input ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-success.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-success input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-success input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-success input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-success input[readonly] ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-success input[disabled] ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-success input:focus ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-success input:valid ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-success input ~ label.e-label-top.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-success input[readonly] ~ label.e-float-text.e-label-top,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-success input[disabled] ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-success.e-input-focus input ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-success.e-input-focus input ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      color: $input-success-color;
    }
  }

  .e-filled.e-float-input.e-warning label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-warning label.e-float-text,
  .e-filled.e-float-input.e-warning input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-warning input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-warning textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-warning textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-warning.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-warning.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-warning input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-warning input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-warning input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-warning input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-warning input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-warning input label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-warning.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-warning input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-warning input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-warning input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-warning input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-warning input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-warning input label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-warning.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-warning input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-warning input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-warning input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-warning input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-warning input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input .e-control-wrapper.e-warning input label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper.e-warning.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-warning input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-warning input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-warning input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small.e-warning input[readonly] ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-warning input[disabled] ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-warning input:focus ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-warning input:valid ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-warning input ~ label.e-label-top.e-float-text,
  .e-small .e-filled.e-float-input.e-warning input[readonly] ~ label.e-float-text.e-label-top,
  .e-small .e-filled.e-float-input.e-warning input[disabled] ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-small.e-warning.e-input-focus input ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-warning.e-input-focus input ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      color: $input-warning-color;
    }
  }

  .e-filled.e-float-input:not(.e-error) input:focus ~ label.e-float-text,
  .e-filled.e-float-input:not(.e-error) input:valid ~ label.e-float-text,
  .e-filled.e-float-input:not(.e-error) input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input:not(.e-error) input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input:not(.e-error) input label.e-float-text.e-label-top,
  .e-filled.e-float-input:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper:not(.e-error) input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper:not(.e-error) input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper:not(.e-error) input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper:not(.e-error) input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input .e-control-wrapper:not(.e-error) input label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-small:not(.e-error) input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-small:not(.e-error) input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-small:not(.e-error) input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small:not(.e-error) input[readonly] ~ label.e-float-text,
  .e-filled.e-float-input.e-small:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-small .e-filled.e-float-input:not(.e-error) input:focus ~ label.e-float-text,
  .e-small .e-filled.e-float-input:not(.e-error) input:valid ~ label.e-float-text,
  .e-small .e-filled.e-float-input:not(.e-error) input ~ label.e-label-top.e-float-text,
  .e-small .e-filled.e-float-input:not(.e-error) input[readonly] ~ label.e-float-text.e-label-top,
  .e-small .e-filled.e-float-input:not(.e-error) input[disabled] ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-small:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-small .e-filled.e-float-input:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) input[readonly] ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper:not(.e-error) input:focus ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper:not(.e-error) input:valid ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper:not(.e-error) input ~ label.e-label-top.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper:not(.e-error) input[readonly] ~ label.e-float-text.e-label-top,
  .e-small .e-filled.e-float-input.e-control-wrapper:not(.e-error) input[disabled] ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-small:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input.e-control-wrapper:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input:not(.e-input-focus):not(.e-disabled) textarea:not(:focus):not(:valid) ~ label.e-float-text:not(.e-label-top),
  .e-filled.e-float-input:not(.e-input-focus) textarea:not(:focus):not(:valid) ~ label.e-float-text:not(.e-label-top),
  .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus):not(.e-disabled) textarea:not(:focus):not(:valid) ~ label.e-float-text:not(.e-label-top),
  .e-filled.e-float-input:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input:not(.e-error) textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input:not(.e-error) textarea ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input:not(.e-error) textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input:not(.e-error) textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input:not(.e-error) textarea label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper:not(.e-error) textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper:not(.e-error) textarea ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper:not(.e-error) textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper:not(.e-error) textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper:not(.e-error) textarea label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-small:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-small:not(.e-error) textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-small:not(.e-error) textarea ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small:not(.e-error) textarea[readonly] ~ label.e-float-text,
  .e-filled.e-float-input.e-small:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-small .e-filled.e-float-input:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-small .e-filled.e-float-input:not(.e-error) textarea:valid ~ label.e-float-text,
  .e-small .e-filled.e-float-input:not(.e-error) textarea ~ label.e-label-top.e-float-text,
  .e-small .e-filled.e-float-input:not(.e-error) textarea[readonly] ~ label.e-float-text.e-label-top,
  .e-small .e-filled.e-float-input:not(.e-error) textarea[disabled] ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) textarea ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) textarea[readonly] ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper:not(.e-error) textarea:valid ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper:not(.e-error) textarea ~ label.e-label-top.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper:not(.e-error) textarea[readonly] ~ label.e-float-text.e-label-top,
  .e-small .e-filled.e-float-input.e-control-wrapper:not(.e-error) textarea[disabled] ~ label.e-float-text.e-label-top {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      color: $filled-input-float-label-color;
    }
  }

  .e-filled.e-float-input:not(.e-error) input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-small:not(.e-error) input:focus ~ label.e-float-text,
  .e-small .e-filled.e-float-input:not(.e-error) input:focus ~ label.e-float-text,
  .e-filled.e-float-input:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small:not(.e-error) input[readonly]:focus ~ label.e-float-text,
  .e-small .e-filled.e-float-input:not(.e-error) input[readonly]:focus ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) input[readonly]:focus ~ label.e-float-text,
  .e-small .e-float-input.e-control-wrapper:not(.e-error) input[readonly]:focus ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small:not(.e-error).e-input-focus input[readonly] ~ label.e-float-text,
  .e-small .e-filled.e-float-input:not(.e-error).e-input-focus input[readonly] ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error).e-input-focus input[readonly] ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper:not(.e-error).e-input-focus input[readonly] ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small:not(.e-error) textarea[readonly]:focus ~ label.e-float-text,
  .e-small .e-filled.e-float-input:not(.e-error) textarea[readonly]:focus ~ label.e-float-text.e-label-top,
  .e-small .e-filled.e-float-input.e-control-wrapper:not(.e-error) textarea[readonly]:focus ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small:not(.e-error).e-input-focus textarea[readonly] ~ label.e-float-text,
  .e-small .e-filled.e-float-input:not(.e-error).e-input-focus textarea[readonly] ~ label.e-float-text.e-label-top,
  .e-small .e-filled.e-float-input.e-control-wrapper:not(.e-error).e-input-focus textarea[readonly] ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-small:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-small .e-filled.e-float-input:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper:not(.e-error) input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) input:focus ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper:not(.e-error) input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-small:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-small .e-filled.e-float-input:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper:not(.e-error).e-input-focus input ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      color: $input-active-accent-color;
    }
  }
  
  .e-input-group textarea.e-input:-ms-input-placeholder,
  .e-input-group textarea.e-input:-moz-placeholder,
  .e-input-group .e-input:-ms-input-placeholder,
  input.e-input#{$css}::-webkit-input-placeholder {
    @if $input-skin-name == 'tailwind' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' {
      color: $input-placeholder-color;
    }
  }

  .e-filled.e-float-input:not(.e-disabled) .e-clear-icon:hover,
  .e-filled.e-float-input.e-control-wrapper:not(.e-disabled) .e-clear-icon:hover,
  .e-filled.e-input-group:not(.e-disabled) .e-clear-icon:hover,
  .e-filled.e-input-group.e-control-wrapper:not(.e-disabled) .e-clear-icon:hover {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      color: $filled-input-clear-icon-hover-color;
    }
  }

  .e-filled.e-float-input:not(.e-disabled) .e-clear-icon:active,
  .e-filled.e-float-input.e-control-wrapper:not(.e-disabled) .e-clear-icon:active,
  .e-filled.e-input-group:not(.e-disabled) .e-clear-icon:active,
  .e-filled.e-input-group.e-control-wrapper:not(.e-disabled) .e-clear-icon:active {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      color: $filled-input-clear-icon-active-color;
    }
  }

  .e-filled.e-input#{$css}[disabled],
  .e-input-group.e-filled .e-input[disabled],
  .e-input-group.e-filled.e-control-wrapper .e-input[disabled],
  .e-input-group.e-filled.e-disabled,
  .e-input-group.e-filled.e-control-wrapper.e-disabled,
  .e-filled.e-float-input input[disabled],
  .e-filled.e-float-input.e-control-wrapper input[disabled],
  .e-filled.e-float-input textarea[disabled],
  .e-filled.e-float-input.e-control-wrapper textarea[disabled],
  .e-filled.e-float-input.e-disabled,
  .e-filled.e-float-input.e-control-wrapper.e-disabled {
    @if $input-skin-name == 'material' {
      background: $filled-input-disabled-bg-color;
      background-image: none;
      background-position: initial;
      background-repeat: no-repeat;
      background-size: $zero-value;
      border-color: $filled-input-disabled-border-color;
      color: $filled-input-disabled-font-color;
    }
    @if $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      //background: $filled-input-disabled-bg-color;
      background-image: none;
      background-position: initial;
      background-repeat: no-repeat;
      background-size: $zero-value;
      border-color: $filled-input-disabled-border-color;
      color: $filled-input-disabled-font-color;
    }
  }

  .e-filled.e-float-input.e-disabled:not(.e-success):not(.e-warning):not(.e-error),
  .e-filled.e-float-input.e-control-wrapper.e-disabled:not(.e-success):not(.e-warning):not(.e-error) {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      border-color: $filled-input-disabled-border-color;
    }
  }

  .e-filled.e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left).e-disabled,
  .e-filled.e-input-group.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error).e-disabled .e-input-in-wrap,
  .e-filled.e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left).e-disabled,
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error).e-disabled .e-input-in-wrap {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      background: $filled-input-disabled-bg-color;
      background-image: none;
      background-position: initial;
      background-repeat: no-repeat;
      background-size: $zero-value;
      border-color: $filled-input-disabled-border-color;
      color: $filled-input-disabled-font-color;
    }
  }

  .e-filled.e-float-input.e-input-group.e-disabled .e-float-text,
  .e-filled.e-float-input.e-input-group.e-disabled .e-float-text.e-label-top,
  .e-filled.e-float-input input[disabled] ~ label.e-float-text,
  .e-filled.e-float-input input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-disabled label.e-float-text,
  .e-filled.e-float-input.e-disabled label.e-float-text.e-label-top,
  .e-filled.e-float-input:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-filled.e-float-input:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-filled.e-float-input.e-small:not(.e-error) input[disabled] ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input textarea[disabled] ~ label.e-float-text,
  .e-filled.e-float-input textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-filled.e-float-input:not(.e-error) textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-filled.e-float-input.e-small:not(.e-error) textarea[disabled] ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper.e-input-group.e-disabled .e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-input-group.e-disabled .e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper.e-disabled input[disabled] ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-disabled input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper input[disabled] ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-disabled label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-disabled label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) input[disabled] ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper.e-disabled:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-disabled:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-disabled.e-small:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-disabled.e-small:not(.e-error) input[disabled] ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper.e-disabled textarea[disabled] ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-disabled textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper textarea[disabled] ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper:not(.e-error) textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) textarea[disabled] ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper.e-disabled:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-disabled:not(.e-error) textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-disabled.e-small:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-disabled.e-small:not(.e-error) textarea[disabled] ~ label.e-float-text.e-label-top,
  input.e-filled.e-disabled.e-input#{$css}:-moz-placeholder,
  .e-input-group.e-filled.e-disabled input.e-input:-moz-placeholder,
  .e-input-group.e-filled.e-disabled input.e-input:-moz-placeholder,
  .e-input-group.e-filled.e-disabled.e-control-wrapper input.e-input:-moz-placeholder,
  input.e-filled.e-disabled.e-input#{$css}:-moz-placeholder,
  .e-input-group.e-filled.e-disabled input.e-input:-moz-placeholder,
  .e-input-group.e-filled.e-disabled.e-control-wrapper input.e-input:-moz-placeholder,
  textarea.e-filled.e-disabled.e-input#{$css}:-moz-placeholder,
  .e-input-group.e-filled.e-disabled textarea.e-input:-moz-placeholder,
  .e-input-group.e-filled.e-disabled.e-control-wrapper textarea.e-input:-moz-placeholder,
  input.e-filled.e-disabled.e-input#{$css}::-moz-placeholder,
  .e-input-group.e-filled.e-disabled input.e-input::-moz-placeholder,
  .e-input-group.e-filled.e-disabled.e-control-wrapper input.e-input::-moz-placeholder,
  textarea.e-filled.e-disabled.e-input#{$css}::-moz-placeholder,
  .e-input-group.e-filled.e-disabled textarea.e-input::-moz-placeholder,
  .e-input-group.e-filled.e-disabled.e-control-wrapper textarea.e-input::-moz-placeholder,
  input.e-filled.e-disabled.e-input#{$css}:-ms-input-placeholder,
  .e-input-group.e-filled.e-disabled input.e-input:-ms-input-placeholder,
  .e-input-group.e-filled.e-disabled.e-control-wrapper input.e-input:-ms-input-placeholder,
  textarea.e-filled.e-disabled.e-input#{$css}:-ms-input-placeholder,
  .e-input-group.e-filled.e-disabled.e-control-wrapper textarea.e-input:-ms-input-placeholder,
  .e-input-group.e-filled.e-disabled textarea.e-input:-ms-input-placeholder,
  input.e-filled.e-disabled.e-input#{$css}::-webkit-input-placeholder,
  .e-input-group.e-filled.e-disabled.e-control-wrapper input.e-input::-webkit-input-placeholder,
  .e-input-group.e-filled.e-disabled input.e-input::-webkit-input-placeholder,
  textarea.e-filled.e-disabled.e-input#{$css}::-webkit-input-placeholder,
  .e-input-group.e-filled.e-disabled textarea.e-input::-webkit-input-placeholder,
  .e-input-group.e-filled.e-disabled.e-control-wrapper textarea.e-input::-webkit-input-placeholder,
  .e-filled.e-float-input.e-disabled .e-clear-icon,
  .e-filled.e-float-input.e-control-wrapper.e-disabled .e-clear-icon,
  .e-input-group.e-filled.e-disabled .e-clear-icon,
  .e-input-group.e-filled.e-control-wrapper.e-disabled .e-clear-icon,
  .e-input-group.e-filled.e-disabled .e-input-group-icon,
  .e-input-group.e-filled.e-control-wrapper.e-disabled .e-input-group-icon,
  .e-filled.e-float-input:not(.e-disabled) input[disabled],
  .e-filled.e-float-input.e-control-wrapper:not(.e-disabled) input[disabled],
  .e-filled.e-float-input:not(.e-disabled) textarea[disabled],
  .e-filled.e-float-input.e-control-wrapper:not(.e-disabled) textarea[disabled] {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      color: $filled-input-disabled-font-color;
    }
  }

  .e-filled.e-float-input.e-success textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-success textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-small.e-success textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-success textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input.e-success textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-success textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-success textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-success textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-success textarea ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-success textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-success textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-success textarea label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper.e-success textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-success textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-success textarea ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-success textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-success textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-success textarea label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-small.e-success textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-success textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-success textarea ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small.e-success textarea[readonly] ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-success textarea[disabled] ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-success textarea:focus ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-success textarea:valid ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-success textarea ~ label.e-label-top.e-float-text,
  .e-small .e-filled.e-float-input.e-success textarea[readonly] ~ label.e-float-text.e-label-top,
  .e-small .e-filled.e-float-input.e-success textarea[disabled] ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-success textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-success textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-success textarea ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-success textarea[readonly] ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-success textarea[disabled] ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-success textarea:focus ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-success textarea:valid ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-success textarea ~ label.e-label-top.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-success textarea[readonly] ~ label.e-float-text.e-label-top,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-success textarea[disabled] ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-success input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-success input:focus ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-success input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-success textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-success textarea:focus ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-success textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-success input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-success input:focus ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-success input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-success textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-success textarea:focus ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-success textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-success.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-success.e-input-focus input ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-success.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-success.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-success.e-input-focus input ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-success.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-success:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-success:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small.e-filled.e-float-input.e-success:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-autofill.e-success:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-autofill.e-success:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small.e-filled.e-float-input.e-control-wrapper.e-autofill.e-success:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-filled.e-float-input.e-success textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-success textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small.e-filled.e-float-input.e-success textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-autofill.e-success textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-autofill.e-success textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small.e-filled.e-float-input.e-control-wrapper.e-autofill.e-success textarea:not(:focus):-webkit-autofill ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      color: $input-success-color;
    }
  }

  .e-filled.e-float-input.e-control-wrapper.e-small.e-warning input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-warning input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-warning input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-warning input[readonly] ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-warning input[disabled] ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-warning input:focus ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-warning input:valid ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-warning input ~ label.e-label-top.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-warning input[readonly] ~ label.e-float-text.e-label-top,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-warning input[disabled] ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-warning.e-input-focus input ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-warning.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-warning textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-warning textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-small.e-warning textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-warning textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input.e-warning textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-warning textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-warning textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-warning textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-warning textarea ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-warning textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-warning textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-warning textarea label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper.e-warning textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-warning textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-warning textarea ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-warning textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-warning textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-warning textarea label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-small.e-warning textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-warning textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-warning textarea ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small.e-warning textarea[readonly] ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-warning textarea[disabled] ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-warning textarea:focus ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-warning textarea:valid ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-warning textarea ~ label.e-label-top.e-float-text,
  .e-small .e-filled.e-float-input.e-warning textarea[readonly] ~ label.e-float-text.e-label-top,
  .e-small .e-filled.e-float-input.e-warning textarea[disabled] ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-warning textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-warning textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-warning textarea ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-warning textarea[readonly] ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-warning textarea[disabled] ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-warning textarea:focus ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-warning textarea:valid ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-warning textarea ~ label.e-label-top.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-warning textarea[readonly] ~ label.e-float-text.e-label-top,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-warning textarea[disabled] ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-warning input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-warning input:focus ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-warning input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-warning textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-warning textarea:focus ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-warning textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-warning input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-warning input:focus ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-warning input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-warning textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-warning textarea:focus ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-warning textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-warning.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-warning.e-input-focus input ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-warning.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-warning.e-input-focus inputs ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-warning.e-input-focus input ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-warning.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-warning:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-warning:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small.e-filled.e-float-input.e-warning:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-autofill.e-warning:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-autofill.e-warning:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small.e-filled.e-float-input.e-control-wrapper.e-autofill.e-warning:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-filled.e-float-input.e-warning textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-warning textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small.e-filled.e-float-input.e-warning textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-autofill.e-warning textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-autofill.e-warning textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small.e-filled.e-float-input.e-control-wrapper.e-autofill.e-warning textarea:not(:focus):-webkit-autofill ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      color: $input-warning-color;
    }
  }
}
