@include export-module('toast-material-icons') {
  .e-toast {
    .e-toast-close-icon::before {
      content: '\e7fc';
      font-family: 'e-icons';
    }

    .e-toast-success-icon::before {
      content: '\ea84';
      font-family: 'e-icons';
    }

    .e-toast-error-icon::before {
      content: '\e208';
      font-family: 'e-icons';
    }

    .e-toast-info-icon::before {
      content: '\e607';
      font-family: 'e-icons';
    }

    .e-toast-warning-icon::before {
      content: '\e92f';
      font-family: 'e-icons';
    }
  }
}
