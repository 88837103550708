.e-multi-select-wrapper {
  box-sizing: border-box;
  cursor: text;
  line-height: normal;
  min-height: $ddl-control-height;
  padding: $ddl-control-placholder-padding;
  position: relative;
  user-select: none;
  width: 100%;

  #{if(&, '&', '*')}.e-delimiter {
    #{if(&, '&', '*')} .e-searcher,
    #{if(&, '&', '*')} .e-multi-searcher {
      height: 27px;
      vertical-align: middle;
      #{if(&, '&', '*')} .e-dropdownbase {
        height: 100%;
        min-height: 100%;
      }
    }
  }

  .e-delim-view {
    white-space: nowrap;

    #{if(&, '&', '*')}.e-delim-values.e-delim-overflow,
    #{if(&, '&', '*')}.e-delim-values.e-delim-total {
      box-sizing: border-box;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      @if $ddl-multiselect-skin-name == 'tailwind' {
        padding: 0;
      }
    }
  }

  .e-chips.e-mob-chip.e-chip-selected .e-chips-close::before {
    color: $ddl-chip-mobile-font;
    font-size: $ddl-chip-close-font;
    left: $ddl-sel-chip-close-mobile-left;
    top: $ddl-sel-chip-close-mobile-top;
  }

  .e-chips.e-mob-chip.e-chip-selected .e-chipcontent {
    @if $ddl-multiselect-skin-name == 'Material3' {
      background: $ddl-chip-mobile-bg;
    }
    @if $ddl-multiselect-skin-name != 'Material3' {
      background-color: $ddl-chip-mobile-bg;
    }
    color: $ddl-chip-mobile-font;
    padding: $ddl-chip-selected-padding;
  }

  .e-searcher.e-zero-size:not(.e-multiselect-box),
  .e-multi-searcher.e-zero-size:not(.e-multiselect-box) {
    width: 0;

    input[type = 'text'] {
      height: 1px;
      min-height: 1px;
    }
  }

  .e-chips.e-mob-chip > .e-chipcontent {
    max-width: 100%;
  }
}

.e-multiselect.e-control-container,
.e-multiselect.e-control-container.e-input-group {
  .e-multi-select-wrapper .e-multi-searcher {
    display: flex;
    float: left;
    width: auto;
  }

  .e-multi-select-wrapper.e-delimiter .e-multi-searcher {
    display: inline-flex;
    float: none;
  }

  .e-multi-select-wrapper .e-multi-searcher.e-zero-size:not(.e-multiselect-box) {
    width: 0;
  }

  .e-multi-select-wrapper .e-delim-values.e-delim-hide,
  .e-multi-select-wrapper .e-delim-view.e-delim-values.e-delim-hide,
  .e-multi-select-wrapper .e-chips-collection.e-delim-hide {
    display: none;
  }

  .e-multi-select-wrapper.e-down-icon {
    @if $ddl-multiselect-skin-name != 'fluent2' {
      padding: 0 48px 0 0;
    }
  }

  .e-multi-select-wrapper .e-delim-values {
    align-items: center;
    display: inline;
    max-width: 100%;
    word-break: break-word;
  }

  .e-multi-select-wrapper .e-clear-icon,
  .e-multi-select-wrapper .e-ddl-icon {
    align-items: center;
    cursor: pointer;
    display: none;
    flex-direction: row;
    margin-top: $ddl-clear-margin-top;
    @if $ddl-multiselect-skin-name == 'tailwind' {
      margin-top: -1.55em;
    }
    outline: 0;
    padding: 0;
    position: absolute;
    right: $ddl-chip-hooker-right;
    top: $ddl-closer-hooker-top;
    @if $ddl-multiselect-skin-name == 'FluentUI' {
      right: $ddl-clear-icon-right;
      top: $ddl-clear-icon-top;
    }
  }

  :not(.e-disabled).e-multi-select-wrapper:hover .e-clear-icon,
  .e-multi-select-wrapper .e-ddl-icon {
    display: flex;
  }

  .e-multi-select-wrapper .e-ddl-icon {
    margin-right: 0;
    margin-top: $ddl-icon-margin-top;
    right: 0;
    @if $ddl-multiselect-skin-name == 'fluent2' {
      padding: 9px;
    }
    @if $ddl-multiselect-skin-name == 'tailwind' or $ddl-multiselect-skin-name == 'tailwind-dark'{
      min-width: 30px;
    }
  }

  .e-multi-select-wrapper.e-delimiter {
    @if $ddl-multiselect-skin-name != 'FluentUI' {
      top: -1px;
    }
  }

  .e-multi-select-wrapper .e-clear-icon {
    font-size: $ddl-closer-hooker-font;
    @if $ddl-multiselect-skin-name != 'bootstrap' {
      height: $ddl-chip-hooker-square;
      width: $ddl-chip-hooker-square;
    }
  }
}

.e-multiselect.e-control-container.e-rtl,
.e-multiselect.e-control-container.e-input-group.e-rtl {
  .e-multi-select-wrapper .e-multi-searcher {
    float: right;
  }

  .e-multi-select-wrapper .e-clear-icon,
  .e-multi-select-wrapper .e-ddl-icon {
    left: 0;
    right: auto;
  }

  .e-multi-select-wrapper.e-down-icon {
    padding: 0 0 0 48px;
  }
}

.e-popup.e-multi-select-list-wrapper .e-list-item.e-disabled,
.e-popup.e-multi-select-list-wrapper.e-multiselect-group.e-checkbox .e-list-group-item.e-disabled {
  opacity: .7;
  pointer-events: none;
}

.e-multiselect.e-input-group,
.e-multiselect.e-float-input {
  word-wrap: initial;
}

.e-multiselect.e-input-group .e-searcher .e-label-top,
.e-multiselect.e-input-group .e-multi-searcher .e-label-top {
  top: $ddl-multiselect-label-position;
}

.e-multi-select-wrapper.e-close-icon-hide {
  padding-right: 0;
}

.e-multi-select-wrapper .e-chips-collection {
  cursor: default;
  display: block;
}

.e-multi-select-wrapper .e-multi-hidden {
  border: 0;
  height: 0;
  position: absolute;
  visibility: hidden;
  width: 0;
}

.e-multi-select-wrapper .e-chips {
  align-items: center;
  display: inline-flex;
  float: left;
  margin: $ddl-chip-margin;
  max-width: 100%;
  overflow: hidden;
  padding: $ddl-chip-padding;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.e-multi-select-wrapper .e-chips > .e-chipcontent {
  max-width: 100%;
  overflow: hidden;
  padding: $ddl-chip-content-padding;
  text-indent: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  @if $ddl-multiselect-skin-name == 'bootstrap5' or $ddl-multiselect-skin-name == 'bootstrap5.3' {
    line-height: 1.5;
  }
}

.e-small .e-multi-select-wrapper .e-chips {
  @if $ddl-multiselect-skin-name == 'highcontrast' {
    margin: $ddl-chip-small-margin;
    #{if(&, '&', '*')} > .e-chipcontent {
      padding: $ddl-chip-small-content-padding;
    }
  }
}

.e-multi-select-wrapper.e-delimiter {
  .e-searcher {
    display: inline-block;
    float: none;
  }
}

.e-multi-select-wrapper .e-mob-chip.e-chips > .e-chipcontent {
  padding: $ddl-chip-mobile-content-padding;
}

#{&}.e-multiselect.e-rtl .e-multi-select-wrapper .e-mob-chip.e-chips>.e-chipcontent {
  padding: $ddl-rtl-chip-mobile-content-padding;
}

.e-multi-select-wrapper .e-chips-close {
  align-self: center;
  display: flex;
  float: right;
  font-family: 'e-icons';
  height: $ddl-chip-close-square;
  margin: $ddl-chip-close-margin;
  width: $ddl-chip-close-width;
}

.e-rtl .e-multi-select-wrapper .e-chips-close {
  margin: $ddl-chip-close-rtl-margin;
}

.e-multi-select-wrapper .e-mob-chip.e-chips .e-chips-close {
  margin: $ddl-chip-mobile-close-margin;
}

.e-multi-select-wrapper .e-chips-close.e-close-hooker {
  cursor: default;
  font-size: $ddl-closer-hooker-font;
  height: $ddl-chip-hooker-square;
  margin-top: $ddl-closer-margin-top;
  position: absolute;
  right: $ddl-chip-hooker-right;
  width: $ddl-chip-hooker-width;
  @if $ddl-multiselect-skin-name != 'Material3' {
    top: $ddl-closer-hooker-top;
  }
  @else {
    top: $ddl-closer-hooker-top-margin;
    border-radius: $ddl-closer-hooker-font;
  }
}

.e-multiselect .e-chips-close.e-close-hooker:hover {
  @if $ddl-multiselect-skin-name == 'Material3' {
    background: $ddl-bigger-closer-hooker-bg-color;
    border: 1px;
  }
}

.e-multiselect .e-down-icon .e-chips-close.e-close-hooker,
.e-small.e-multiselect .e-down-icon .e-chips-close.e-close-hooker,
.e-multiselect.e-input-group.e-control-container .e-multi-select-wrapper.e-down-icon .e-clear-icon {
  right: $ddl-close-icon-left;
}

.e-small.e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker,
.e-small.e-multiselect .e-down-icon .e-chips-close.e-close-hooker,
.e-small.e-multiselect.e-input-group.e-control-container .e-multi-select-wrapper.e-down-icon .e-clear-icon {
  @if $ddl-multiselect-skin-name == 'FluentUI' {
    font-size: $ddl-closer-hooker-font-small;
  }
  @if $ddl-multiselect-skin-name == 'Material3' {
    right: 30px;
  }
}

.e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker,
.e-multiselect .e-down-icon .e-chips-close.e-close-hooker {
  @if $ddl-multiselect-skin-name == 'FluentUI' {
    font-size: $ddl-chip-close-font;
  }
}

.e-multiselect.e-input-group.e-control-container.e-rtl .e-multi-select-wrapper.e-down-icon .e-clear-icon {
  left: $ddl-close-icon-left;
  right: auto;
}

.e-small .e-multiselect.e-rtl .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-small.e-multiselect.e-rtl .e-multi-select-wrapper .e-chips-close.e-close-hooker {
  @if $ddl-multiselect-skin-name == 'bootstrap5' or $ddl-multiselect-skin-name == 'bootstrap5.3' {
    left: 12px;
    right: auto;
  }
}

.e-multi-select-wrapper input[type = 'text'] {
  background: none;
  border: 0;
  font-family: $ddl-chip-font-family;
  font-size: $ddl-chip-font-size;
  font-weight: normal;
  height: $ddl-input-height;
  min-height: $ddl-input-height;
  outline: none;
  @if $ddl-multiselect-skin-name == 'Material3' {
    padding: 0;
  }
  @else {
    padding: $ddl-input-placeholder-padding;
  }
  text-indent: 0;
}

.e-multiselect.e-filled .e-multi-select-wrapper input[type = 'text'] {
  background: none;
  border: 0;
  color: inherit;
  font-family: $ddl-chip-font-family;
  font-size: $ddl-delim-font-size;
  font-weight: normal;
  height: $ddl-input-height;
  min-height: $ddl-input-height;
  outline: none;
  padding: 0;
  text-indent: $ddl-input-text-indent;
}

.e-rtl.e-multiselect.e-filled .e-multi-select-wrapper input[type = 'text'] {
  padding: 5px;
}

/* stylelint-disable property-no-vendor-prefix */
.e-multi-select-wrapper input[type = 'text']::-ms-clear {
  display: none;
}

.e-multi-select-wrapper .e-searcher {
  display: block;
  float: left;
  width: auto;
}

.e-multiselect .e-multi-select-wrapper.e-down-icon .e-searcher.e-search-custom-width {
  width: $ddl-search-wrapper-width;
}

.e-small .e-multiselect .e-multi-select-wrapper.e-down-icon .e-searcher.e-search-custom-width,
.e-small.e-multiselect .e-multi-select-wrapper.e-down-icon .e-searcher.e-search-custom-width {
  width: $ddl-small-search-wrapper-width;
}

.e-multiselect.e-outline .e-multi-select-wrapper.e-down-icon .e-searcher.e-search-custom-width {
  @if $ddl-multiselect-skin-name == 'material' or $ddl-multiselect-skin-name == 'Material3' {
    width: $ddl-search-wrapper-width;
  }
}

.e-small.e-multiselect.e-outline .e-multi-select-wrapper.e-down-icon .e-searcher.e-search-custom-width,
.e-small .e-multiselect.e-outline .e-multi-select-wrapper.e-down-icon .e-searcher.e-search-custom-width {
  @if $ddl-multiselect-skin-name == 'material' or $ddl-multiselect-skin-name == 'Material3' {
    width: $ddl-small-search-wrapper-width;
  }
}

.e-multi-select-wrapper .e-delim-values {
  font-family: $ddl-chip-font-family;
  font-size: $ddl-delim-font-size;
  line-height: $ddl-delimviewheight;
  max-width: 100%;
  padding-left: $ddl-delim-text-indent;
  padding-right: $ddl-delim-text-padding-right;
  @if $ddl-multiselect-skin-name != 'bootstrap5' and $ddl-multiselect-skin-name != 'bootstrap5.3' and $ddl-multiselect-skin-name != 'FluentUI' {
    vertical-align: middle;
  }
}

.e-small .e-multi-select-wrapper .e-delim-values .e-remain {
  @if $ddl-multiselect-skin-name == 'tailwind' {
    font-size: $ddl-chip-small-font-size;
  }
}

.e-multi-select-list-wrapper .e-hide-listitem,
.e-multi-select-list-wrapper .e-hide-group-header {
  display: none;
}

.e-multi-select-wrapper .e-delim-values .e-remain {
  color: $ddl-remains-font-color;
  cursor: pointer;
  display: inline-block;
  font-size: $ddl-chip-font-size;
  padding-left: $ddl-remains-padding-left;
}

#{&}.e-multiselect.e-disabled .e-multi-select-wrapper,
#{&}.e-multiselect.e-disabled .e-multi-select-wrapper .e-chips .e-chips-close::before {
  cursor: not-allowed;
}

.e-multi-select-wrapper .e-chips.e-mob-chip.e-chip-selected .e-chips-close {
  height: $ddl-chip-sel-mobile-height;
  left: $ddl-chip-mob-left;
  margin: $ddl-chip-sel-mobile-close-margin;
  margin-left: auto;
  position: relative;
  top: $ddl-chip-close-mob-top;
  width: $ddl-chip-sel-mobile-height;
}

.e-multi-select-wrapper .e-chips.e-mob-chip.e-chip-selected {
  align-self: center;
  box-shadow: $ddl-chip-selected-shadow-value;
  display: inline-flex;
  padding: $ddl-chip-mob-padding;
  width: 92%;
}

.e-multi-select-wrapper .e-ddl-disable-icon::before {
  content: '';
}

#{&}.e-multiselect.e-rtl .e-multi-select-wrapper.e-delimiter {
  .e-searcher {
    float: none;
  }
}

#{&}.e-multiselect.e-rtl {
  .e-multi-select-wrapper.e-close-icon-hide {
    padding-left: 0;
  }

  .e-multi-select-wrapper {
    padding: $ddl-rtl-wrapper;

    .e-chips.e-mob-chip.e-chip-selected .e-chips-close::before {
      left: $ddl-rtl-chip-sel-close-left;
    }

    .e-chips.e-mob-chip.e-chip-selected .e-chips-close {
      margin: 0;
      margin-right: auto;
    }

    .e-searcher {
      float: right;
    }

    .e-chips {
      float: right;
      margin: $ddl-rtl-mob-chip-content-margin;
      padding: $ddl-rtl-chip-padding;

      .e-chipcontent {
        padding: $ddl-rtl-chip-content-padding;
      }

      .e-chips-close {
        float: left;
      }

      .e-chips-close::before {
        left: $ddl-rtl-chip-close-left;
      }
    }

    .e-chips.e-mob-chip {
      padding: $ddl-rtl-mob-chip-padding;

      .e-chipcontent {
        padding: $ddl-rtl-mob-chip-content-padding;
      }
    }

    .e-chips.e-mob-chip.e-chip-selected {
      padding: $ddl-rtl-mob-sel-chip-padding;

      .e-chipcontent {
        padding: $ddl-rtl-mob-sel-chip-content-padding;
      }
    }

    .e-chips-close.e-close-hooker {
      left: $ddl-close-icon-left;
      position: absolute;
      right: auto;
      @if $ddl-multiselect-skin-name == 'bootstrap5' or $ddl-multiselect-skin-name == 'bootstrap5.3' or $ddl-multiselect-skin-name == 'tailwind' {
        left: 0;
      }
    }

    .e-close-hooker::before {
      left: $ddl-rtl-hooker-left;
    }

    .e-delim-values .e-remain {
      padding-right: $ddl-remains-padding-left;
    }
  }
}

.e-multiselect.e-rtl .e-down-icon .e-chips-close.e-close-hooker {
  left: $ddl-close-rtl-icon-left;
}

.e-multiselect.e-rtl .e-multi-select-wrapper.e-down-icon .e-close-hooker::before {
  left: $ddl-chip-close-rtl-left;
}

.e-small.e-multiselect.e-rtl .e-multi-select-wrapper.e-down-icon .e-close-hooker::before,
.e-small .e-multiselect.e-rtl .e-multi-select-wrapper.e-down-icon .e-close-hooker::before {
  @if ($ddl-multiselect-skin-name == 'bootstrap5' or $ddl-multiselect-skin-name == 'bootstrap5.3') {
    left: $ddl-chip-close-rtl-small-left;
  }
}

.e-popup.e-multi-select-list-wrapper .e-list-item .e-checkbox-wrapper {
  bottom: 1px;
  margin-right: $ddl-check-right-margin;
  position: relative;
  text-indent: 0;
  vertical-align: middle;
}

.e-popup.e-multi-select-list-wrapper.e-multiselect-group:not(.e-rtl) .e-list-item .e-checkbox-wrapper {
  @if ($ddl-multiselect-skin-name != 'tailwind' and $ddl-multiselect-skin-name != 'FluentUI') {
    padding-left: $ddl-multiselect-group-checkbox-wrapper-padding-left;
  }
}

.e-popup.e-multi-select-list-wrapper.e-multiselect-group:not(.e-rtl) .e-list-item .e-checkbox-wrapper .e-ripple-container {
  @if ($ddl-multiselect-skin-name == 'Material3') {
    left: 4px;
  }
}

.e-popup.e-multi-select-list-wrapper.e-multiselect-group.e-rtl .e-list-item .e-checkbox-wrapper {
  @if ($ddl-multiselect-skin-name != 'tailwind' and $ddl-multiselect-skin-name != 'FluentUI') {
    padding-right: $ddl-multiselect-group-list-item-rtl-padding-right;
  }
}

.e-popup.e-multi-select-list-wrapper.e-multiselect-group .e-list-group-item .e-checkbox-wrapper {
  bottom: 1px;
  @if $ddl-multiselect-skin-name == 'bootstrap5' or $ddl-multiselect-skin-name == 'bootstrap5.3' {
    bottom: 2px;
  }
  margin-right: $ddl-check-right-margin;
  position: relative;
  text-indent: 0;
  vertical-align: middle;
}

.e-popup.e-multi-select-list-wrapper.e-rtl .e-list-item .e-checkbox-wrapper {
  margin-left: 12px;
  margin-right: 0;
}

.e-popup.e-multi-select-list-wrapper.e-multiselect-group.e-rtl .e-list-group-item .e-checkbox-wrapper {
  margin-left: 12px;
  margin-right: 0;
}

.e-popup.e-multi-select-list-wrapper.e-rtl .e-list-item {
  @if ($ddl-multiselect-skin-name != 'tailwind') {
    padding-right: 0;
  }
}

.e-popup.e-multi-select-list-wrapper.e-rtl .e-dropdownbase.e-rtl.e-dd-group .e-list-item {
  @if ($ddl-multiselect-skin-name != 'tailwind') {
    padding-right: $ddl-list-rtl-padding-right;
  }
}

.e-popup.e-multi-select-list-wrapper.e-multiselect-group.e-rtl .e-dropdownbase.e-rtl.e-dd-group .e-list-group-item {
  @if $ddl-multiselect-skin-name == 'highcontrast' or $ddl-multiselect-skin-name == 'fabric' {
    padding-right: $ddl-multiselect-group-list-group-item-rtl-padding-right;
  }
}

.e-multi-select-list-wrapper .e-selectall-parent {
  cursor: pointer;
  display: block;
  line-height: $ddl-select-all-height;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: $ddl-list-padding-right;
  position: relative;
  text-indent: $ddl-check-right;
  white-space: nowrap;
  width: 100%;
}

.e-multi-select-list-wrapper.e-multiselect-group.e-checkbox .e-selectall-parent {
  @if ($ddl-multiselect-skin-name == 'FluentUI') {
    text-indent: $ddl-check-group-right;
  }
}

.e-rtl .e-multi-select-list-wrapper .e-selectall-parent,
.e-multi-select-list-wrapper.e-rtl .e-selectall-parent {
  padding-left: $ddl-list-rtl-padding-left;
  padding-right: $ddl-list-rtl-padding-right;
}

.e-multi-select-list-wrapper .e-selectall-parent .e-all-text {
  color: $ddl-multi-list-default-font-color;
  font-family: $ddl-chip-font-family;
  font-size: $ddl-selectall-font-size;
}

.e-small.e-multi-select-list-wrapper .e-selectall-parent .e-all-text,
.e-small .e-multi-select-list-wrapper .e-selectall-parent .e-all-text {
  @if $ddl-multiselect-skin-name == 'tailwind' {
    font-size: $ddl-small-selectall-font-size;
  }
}

.e-multi-select-list-wrapper .e-selectall-parent .e-checkbox-wrapper {
  bottom: 1px;
  margin-right: $ddl-check-right-margin;
  position: relative;
  text-indent: 0;
  vertical-align: middle;
}

.e-multi-select-list-wrapper.e-rtl .e-selectall-parent .e-checkbox-wrapper {
  margin-left: $ddl-check-left;
  margin-right: 0;
}

.e-multiselect .e-input-group-icon.e-ddl-icon {
  float: right;
  margin-top: $ddl-check-icon-top;
  @if $ddl-multiselect-skin-name == 'Material3' {
    top: 1px;
  }
}

.e-small .e-multiselect:not(.e-outline):not(.e-filled) .e-input-group-icon.e-ddl-icon {
  @if $ddl-multiselect-skin-name == 'Material3' {
    top: 6px;
  }
}

.e-multiselect.e-rtl .e-input-group-icon.e-ddl-icon {
  float: left;
}

.e-multiselect .e-ddl-icon::before {
  @if $ddl-multiselect-skin-name == 'material' or $ddl-multiselect-skin-name == 'Material3' {
    transform: rotate(0deg);
    transition: transform 300ms ease;
  }
}

.e-multiselect.e-icon-anim .e-ddl-icon::before {
  @if $ddl-multiselect-skin-name == 'material' or $ddl-multiselect-skin-name == 'Material3' {
    transform: rotate(180deg);
    transition: transform 300ms ease;
  }
}

.e-multiselect.e-checkbox .e-multi-select-wrapper,
.e-multiselect .e-multi-select-wrapper.e-down-icon {
  @if $ddl-multiselect-skin-name != 'fluent2' {
    padding: 0;
  }
}

.e-multiselect .e-multi-select-wrapper.e-down-icon {
  @if $ddl-multiselect-skin-name =='fluent2' {
    padding: 1px 0;
  }
}

.e-ddl.e-popup.e-multi-select-list-wrapper .e-filter-parent .e-input-filter,
.e-ddl.e-popup.e-multi-select-list-wrapper .e-filter-parent .e-input-group.e-input-focus .e-input-filter {
  @if $ddl-multiselect-skin-name != 'bootstrap5' and $ddl-multiselect-skin-name != 'bootstrap5.3' and $ddl-multiselect-skin-name != 'tailwind' and $ddl-multiselect-skin-name != 'fluent2' {
    padding: $ddl-filterbar-padding;
  }
}

.e-ddl.e-popup.e-multi-select-list-wrapper .e-filter-parent .e-input-group:not(.e-control-container) .e-clear-icon {
  @if $ddl-multiselect-skin-name != 'Material3' {
    padding-left: 8px;
  }
  @if $ddl-multiselect-skin-name == 'material' {
    padding-right: 8px;
  }
}

.e-ddl.e-popup.e-multi-select-list-wrapper .e-filter-parent .e-back-icon {
  padding: $ddl-back-icon-padding;
}

.e-checkbox .e-multi-select-wrapper .e-delim-values .e-remain {
  line-height: 20px;
  padding-left: 10px;
  @if $ddl-multiselect-skin-name == 'bootstrap5' or $ddl-multiselect-skin-name == 'bootstrap5.3' {
    padding-left: 0;
  }
}

.e-popup.e-multi-select-list-wrapper .e-list-item.e-disable .e-checkbox-wrapper .e-frame,
.e-popup.e-multi-select-list-wrapper .e-list-group-item.e-disable .e-checkbox-wrapper .e-frame {
  opacity: .3;
}

.e-popup.e-multi-select-list-wrapper .e-list-item.e-disable,
.e-popup.e-multi-select-list-wrapper.e-multiselect-group.e-checkbox .e-list-group-item.e-disable {
  opacity: .7;
}

.e-multi-select-wrapper input[readonly = 'true'] {
  pointer-events: none;
}

.e-multiselect.e-checkbox .e-multi-select-wrapper .e-searcher {
  pointer-events: none;
}

ejs-multiselect {
  display: block;
}

.e-small.e-multi-select-list-wrapper .e-selectall-parent,
.e-small .e-multi-select-list-wrapper .e-selectall-parent {
  line-height: $ddl-select-all-height-small;
  @if $ddl-multiselect-skin-name == 'tailwind' {
    font-size: $ddl-chip-small-font-size;
    text-indent: $ddl-small-check-right;
  }
}

.e-small .e-multi-select-wrapper .e-chips-close {
  height: $ddl-small-chip-close-square;
  width: $ddl-small-chip-close-width;
}

.e-small .e-multiselect .e-down-icon .e-chips-close.e-close-hooker {
  @if $ddl-multiselect-skin-name == 'Material3' {
    right: 24px;
  }
}

.e-small .e-multi-select-wrapper .e-chips-close.e-close-hooker {
  @if $ddl-multiselect-skin-name == 'Material3' {
    margin-top: $ddl-small-close-hooker-margin-top;
    height: $ddl-small-chip-close-hooker-height;
    width: $ddl-small-chip-close-hooker-width;
    line-height: normal;
  }
}

.e-small .e-multi-select-wrapper {
  min-height: $ddl-control-small-height;
}

.e-small .e-multi-select-wrapper.e-delimiter .e-searcher,
.e-small .e-multi-select-wrapper.e-delimiter .e-multi-searcher {
  @if $ddl-multiselect-skin-name == 'tailwind' or $ddl-multiselect-skin-name == 'FluentUI' {
    height: $ddl-small-input-height;
  }
}

.e-small .e-multi-select-wrapper input[type = 'text'] {
  @if $ddl-multiselect-skin-name == 'tailwind' {
    font-size: $ddl-chip-small-font-size;
  }
  height: $ddl-small-input-height;
  min-height: $ddl-small-input-height;
}

.e-small .e-multi-select-wrapper .e-delim-values {
  font-size: $ddl-delim-small-font-size;
  line-height: $ddl-delimviewheight-small;
}

.e-small .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-small .e-multiselect.e-control-container .e-multi-select-wrapper .e-clear-icon {
  @if $ddl-multiselect-skin-name != 'fluent2' {
    margin-top: $ddl-small-closer-margin-top;
  }
  @if $ddl-multiselect-skin-name == 'tailwind' or $ddl-multiselect-skin-name == 'FluentUI' or $ddl-multiselect-skin-name == 'Material3' {
    font-size: $ddl-closer-hooker-font-small;
    height: $ddl-small-clear-icon-height;
    width: $ddl-small-clear-icon-width;
  }
}

.e-small .e-multiselect.e-control-container .e-multi-select-wrapper .e-ddl-icon,
.e-small .e-multiselect.e-control-container.e-input-group .e-multi-select-wrapper .e-ddl-icon {
  @if $ddl-multiselect-skin-name == 'Material3' {
    margin-top: $ddl-small-ddl-icon-top;
  }
}

.e-small.e-multiselect.e-control-container .e-multi-select-wrapper .e-ddl-icon,
.e-small.e-multiselect.e-control-container.e-input-group .e-multi-select-wrapper .e-ddl-icon {
  margin-top: $ddl-small-ddl-icon-top;
}

.e-small .e-multi-select-wrapper .e-chips-close.e-close-hooker {
  @if ddl-multiselect-skin-name == 'Material3' {
    height: $ddl-small-clear-icon-height;
    width: $ddl-small-clear-icon-width;
  }
}

.e-small .e-multi-select-wrapper .e-chips > .e-chipcontent {
  @if $ddl-multiselect-skin-name == 'bootstrap4' {
    padding: $ddl-chip-small-content-padding;
  }
}

.e-small .e-multiselect.e-input-group.e-control-container .e-multi-select-wrapper.e-down-icon .e-clear-icon {
  @if $ddl-multiselect-skin-name == 'Material3' {
    right: $ddl-close-icon-small-right;
  }
}

.e-content-placeholder.e-multiselect.e-placeholder-multiselect {
  background-size: 300px 33px;
  min-height: 33px;
}

.e-multiselect.e-outline .e-multi-select-wrapper input[type = 'text'] {
  color: inherit;
  @if $ddl-multiselect-skin-name == 'material' or $ddl-multiselect-skin-name == 'Material3' {
    font-size: inherit;
    min-height: $outline-multiselect-inner-input-height;
    padding: 8px 12px 9px;
  }
}

.e-multiselect.e-outline.e-input-focus .e-multi-select-wrapper input[type = 'text'] {
  @if $ddl-multiselect-skin-name == 'material' or $ddl-multiselect-skin-name == 'Material3' {
    padding: 8px 12px 9px;
  }
}

.e-small.e-multiselect.e-outline .e-multi-select-wrapper input[type = 'text'],
.e-small .e-multiselect.e-outline .e-multi-select-wrapper input[type = 'text'] {
  @if $ddl-multiselect-skin-name == 'material' or $ddl-multiselect-skin-name == 'Material3' {
    font-size: inherit;
    min-height: $outline-multiselect-small-inner-input-height;
    padding: 7px 10px;
  }
}

.e-small.e-multiselect.e-outline.e-input-focus .e-multi-select-wrapper input[type = 'text'],
.e-small .e-multiselect.e-outline.e-input-focus .e-multi-select-wrapper input[type = 'text'] {
  @if $ddl-multiselect-skin-name == 'material' or $ddl-multiselect-skin-name == 'Material3' {
    padding: 7px 10px;
  }
}

.e-small {
  .e-multiselect.e-outline .e-multi-select-wrapper,
  #{if(&, '&', '*')}.e-multiselect.e-outline .e-multi-select-wrapper {
    @if $ddl-multiselect-skin-name == 'material' or $ddl-multiselect-skin-name == 'Material3' {
      min-height: $outline-multiselect-small-height;
      padding: $outline-multiselect-overall-padding;
    }
  }
}

.e-multiselect.e-outline .e-multi-select-wrapper {
  @if $ddl-multiselect-skin-name == 'material' or $ddl-multiselect-skin-name == 'Material3' {
    min-height: $ddl-outline-multiselect-height;
    padding: $outline-multiselect-overall-padding;
  }
}

.e-outline.e-multiselect.e-checkbox .e-multi-select-wrapper,
.e-multiselect.e-outline .e-multi-select-wrapper.e-down-icon {
  @if $ddl-multiselect-skin-name == 'material' or $ddl-multiselect-skin-name == 'Material3' {
    padding-right: 0;
  }
}

.e-rtl.e-outline.e-multiselect.e-checkbox .e-multi-select-wrapper,
.e-rtl.e-multiselect.e-outline .e-multi-select-wrapper.e-down-icon,
.e-rtl .e-outline.e-multiselect.e-checkbox .e-multi-select-wrapper,
.e-rtl .e-multiselect.e-outline .e-multi-select-wrapper.e-down-icon,
.e-rtl.e-small.e-outline.e-multiselect.e-checkbox .e-multi-select-wrapper,
.e-rtl.e-small.e-multiselect.e-outline .e-multi-select-wrapper.e-down-icon,
.e-small .e-rtl.e-outline.e-multiselect.e-checkbox .e-multi-select-wrapper,
.e-small .e-rtl.e-multiselect.e-outline .e-multi-select-wrapper.e-down-icon,
.e-rtl .e-small.e-outline.e-multiselect.e-checkbox .e-multi-select-wrapper,
.e-rtl .e-small.e-multiselect.e-outline .e-multi-select-wrapper.e-down-icon {
  @if $ddl-multiselect-skin-name == 'material' or $ddl-multiselect-skin-name == 'Material3' {
    padding-left: 0;
  }
}

.e-rtl .e-multiselect.e-outline .e-multi-select-wrapper,
.e-rtl.e-multiselect.e-outline .e-multi-select-wrapper,
.e-rtl .e-small.e-multiselect.e-outline .e-multi-select-wrapper,
.e-rtl.e-small .e-multiselect.e-outline .e-multi-select-wrapper,
.e-rtl.e-small.e-multiselect.e-outline .e-multi-select-wrapper {
  @if $ddl-multiselect-skin-name == 'material' or $ddl-multiselect-skin-name == 'Material3' {
    padding: $outline-rtl-multiselect-overall-padding;
  }
}

.e-rtl .e-multiselect.e-outline .e-multi-select-wrapper.e-close-icon-hide,
.e-rtl.e-multiselect.e-outline .e-multi-select-wrapper.e-close-icon-hide,
.e-rtl .e-small.e-multiselect.e-outline .e-multi-select-wrapper.e-close-icon-hide,
.e-rtl.e-small .e-multiselect.e-outline .e-multi-select-wrapper.e-close-icon-hide,
.e-rtl.e-small.e-multiselect.e-outline .e-multi-select-wrapper.e-close-icon-hide {
  @if $ddl-multiselect-skin-name == 'material' or $ddl-multiselect-skin-name == 'Material3' {
    padding-left: 0;
  }
}

.e-multiselect.e-outline .e-multi-select-wrapper.e-close-icon-hide,
.e-small.e-multiselect.e-outline .e-multi-select-wrapper.e-close-icon-hide,
.e-small .e-multiselect.e-outline .e-multi-select-wrapper.e-close-icon-hide {
  @if $ddl-multiselect-skin-name == 'material' or $ddl-multiselect-skin-name == 'Material3' {
    padding-right: 0;
  }
}

.e-multiselect.e-outline .e-multi-select-wrapper.e-delimiter .e-searcher {
  @if $ddl-multiselect-skin-name == 'material' or $ddl-multiselect-skin-name == 'Material3' {
    height: $ddl-outline-multiselect-height;
  }
}

.e-small.e-multiselect.e-outline .e-multi-select-wrapper.e-delimiter .e-searcher,
.e-small .e-multiselect.e-outline .e-multi-select-wrapper.e-delimiter .e-searcher {
  @if $ddl-multiselect-skin-name == 'material' or $ddl-multiselect-skin-name == 'Material3' {
    height: $outline-multiselect-small-height;
  }
}

.e-multiselect.e-outline .e-multi-select-wrapper .e-delim-values {
  @if $ddl-multiselect-skin-name == 'material' or $ddl-multiselect-skin-name == 'Material3' {
    font-size: 14px;
    line-height: $ddl-outline-multiselect-height;
    padding: $outline-multiselect-delimeter-padding;
  }
}

.e-small.e-multiselect.e-outline .e-multi-select-wrapper .e-delim-values,
.e-small .e-multiselect.e-outline .e-multi-select-wrapper .e-delim-values {
  @if $ddl-multiselect-skin-name == 'material' or $ddl-multiselect-skin-name == 'Material3' {
    font-size: 13px;
    line-height: $outline-multiselect-small-height;
    padding: $outline-multiselect-small-delimeter-padding;
  }
}

.e-rtl.e-multiselect.e-outline .e-multi-select-wrapper .e-delim-values {
  @if $ddl-multiselect-skin-name == 'material' or $ddl-multiselect-skin-name == 'Material3' {
    padding: $outline-rtl-multiselect-delimeter-padding;
  }
}

.e-rtl.e-small.e-multiselect.e-outline .e-multi-select-wrapper .e-delim-values,
.e-rtl.e-small .e-multiselect.e-outline .e-multi-select-wrapper .e-delim-values,
.e-rtl .e-small.e-multiselect.e-outline .e-multi-select-wrapper .e-delim-values,
.e-small .e-rtl.e-multiselect.e-outline .e-multi-select-wrapper .e-delim-values,
.e-small .e-rtl .e-multiselect.e-outline .e-multi-select-wrapper .e-delim-values,
.e-rtl .e-small .e-multiselect.e-outline .e-multi-select-wrapper .e-delim-values {
  @if $ddl-multiselect-skin-name == 'material' or $ddl-multiselect-skin-name == 'Material3' {
    padding: $outline-rtl-multiselect-small-delimeter-padding;
  }
}

.e-multiselect.e-outline .e-multi-select-wrapper .e-chips {
  @if $ddl-multiselect-skin-name == 'material' or $ddl-multiselect-skin-name == 'Material3' {
    height: $outline-multiselect-chip-height;
    margin: $outline-multiselect-chip-margin;
    padding: $outline-multiselect-chip-padding;
  }
}

.e-rtl .e-multiselect.e-outline .e-multi-select-wrapper .e-chips,
.e-rtl.e-multiselect.e-outline .e-multi-select-wrapper .e-chips {
  @if $ddl-multiselect-skin-name == 'material' or $ddl-multiselect-skin-name == 'Material3' {
    margin: $outline-rtl-multiselect-chip-margin;
  }
}

.e-small.e-multiselect.e-outline .e-multi-select-wrapper .e-chips,
.e-small .e-multiselect.e-outline .e-multi-select-wrapper .e-chips {
  @if $ddl-multiselect-skin-name == 'material' or $ddl-multiselect-skin-name == 'Material3' {
    height: $outline-multiselect-small-chip-height;
    padding: $outline-multiselect-chip-padding;
  }
}

.e-multiselect.e-outline .e-multi-select-wrapper .e-chips > .e-chipcontent {
  @if $ddl-multiselect-skin-name == 'material' or $ddl-multiselect-skin-name == 'Material3' {
    padding: 0 8px 0 0;
  }
}

.e-rtl.e-multiselect.e-outline .e-multi-select-wrapper .e-chips > .e-chipcontent,
.e-rtl .e-multiselect.e-outline .e-multi-select-wrapper .e-chips > .e-chipcontent {
  @if $ddl-multiselect-skin-name == 'material' or $ddl-multiselect-skin-name == 'Material3' {
    padding: 0 0 0 8px;
  }
}

.e-multiselect.e-outline .e-multi-select-wrapper .e-chips > .e-chipcontent {
  @if $ddl-multiselect-skin-name == 'material' or $ddl-multiselect-skin-name == 'Material3' {
    font-size: $outline-multiselect-chip-font-size;
  }
}

.e-small.e-multiselect.e-outline .e-multi-select-wrapper .e-chips > .e-chipcontent,
.e-small .e-multiselect.e-outline .e-multi-select-wrapper .e-chips > .e-chipcontent {
  @if $ddl-multiselect-skin-name == 'material' or $ddl-multiselect-skin-name == 'Material3' {
    font-size: $outline-multiselect-small-chip-font-size;
  }
}

.e-multiselect.e-outline .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-multiselect.e-control-container .e-multi-select-wrapper .e-clear-icon {
  align-items: normal;
}

.e-multiselect.e-filled .e-multi-select-wrapper .e-delim-values .e-remain,
.e-multiselect.e-outline .e-multi-select-wrapper .e-delim-values .e-remain {
  @if $ddl-multiselect-skin-name == 'material' or $ddl-multiselect-skin-name == 'Material3' {
    font-size: 14px;
  }
}

.e-small.e-multiselect.e-filled .e-multi-select-wrapper .e-delim-values .e-remain,
.e-small .e-multiselect.e-filled .e-multi-select-wrapper .e-delim-values .e-remain,
.e-small.e-multiselect.e-outline .e-multi-select-wrapper .e-delim-values .e-remain,
.e-small .e-multiselect.e-outline .e-multi-select-wrapper .e-delim-values .e-remain {
  @if $ddl-multiselect-skin-name == 'material' or $ddl-multiselect-skin-name == 'Material3' {
    font-size: 13px;
  }
}

.e-filled.e-multiselect:not(.e-float-input) .e-multi-select-wrapper {
  padding: 0 12px 0 8px;
}

.e-multiselect.e-filled.e-input-group.e-control-wrapper {
  padding: 0;
}

.e-filled.e-float-input .e-multi-select-wrapper {
  padding: 11px 12px 0;
}

.e-small.e-filled.e-float-input .e-multi-select-wrapper,
.e-small .e-filled.e-float-input .e-multi-select-wrapper {
  padding: 12px 12px 0 8px;
}

.e-rtl.e-multiselect.e-filled.e-input-group.e-control-wrapper,
.e-rtl.e-multiselect.e-filled.e-float-input.e-control-wrapper {
  padding: 0;
}

.e-multiselect.e-filled:not(.e-float-input) .e-multi-select-wrapper input[type = 'text'] {
  @if $ddl-multiselect-skin-name == 'material' or $ddl-multiselect-skin-name == 'Material3' {
    min-height: $ddl-multiselect-filled-input-min-height;
    padding-right: 10px;
  }
}

.e-small.e-multiselect.e-filled:not(.e-float-input) .e-multi-select-wrapper input[type = 'text'],
.e-small .e-multiselect.e-filled:not(.e-float-input) .e-multi-select-wrapper input[type = 'text'] {
  @if $ddl-multiselect-skin-name == 'material' or $ddl-multiselect-skin-name == 'Material3' {
    min-height: $ddl-multiselect-filled-input-min-height-small;
  }
}

.e-small.e-multiselect.e-filled.e-float-input .e-multi-select-wrapper input[type = 'text'],
.e-small .e-multiselect.e-filled.e-float-input .e-multi-select-wrapper input[type = 'text'] {
  @if $ddl-multiselect-skin-name == 'material' or $ddl-multiselect-skin-name == 'Material3' {
    height: $ddl-multiselect-filled-float-input-min-height-small;
    min-height: $ddl-multiselect-filled-float-input-min-height-small;
    padding: 2px;
  }
}

.e-filled.e-multiselect:not(.e-float-input) .e-multi-select-wrapper.e-delimiter .e-searcher {
  @if $ddl-multiselect-skin-name == 'material' or $ddl-multiselect-skin-name == 'Material3' {
    height: $ddl-multiselect-filled-input-min-height;
  }
}

.e-small.e-filled.e-multiselect:not(.e-float-input) .e-multi-select-wrapper.e-delimiter .e-searcher,
.e-small .e-filled.e-multiselect:not(.e-float-input) .e-multi-select-wrapper.e-delimiter .e-searcher {
  @if $ddl-multiselect-skin-name == 'material' or $ddl-multiselect-skin-name == 'Material3' {
    height: $ddl-multiselect-filled-input-min-height-small;
  }
}

.e-small.e-filled.e-multiselect.e-float-input .e-multi-select-wrapper.e-delimiter .e-searcher,
.e-small .e-filled.e-multiselect.e-float-input .e-multi-select-wrapper.e-delimiter .e-searcher {
  @if $ddl-multiselect-skin-name == 'material' or $ddl-multiselect-skin-name == 'Material3' {
    height: $ddl-multiselect-filled-float-input-min-height-small;
  }
}

.e-multiselect.e-filled.e-float-input .e-searcher .e-dropdownbase {
  @if $ddl-multiselect-skin-name == 'material' or $ddl-multiselect-skin-name == 'Material3' {
    min-height: $ddl-multiselect-filled-float-input-min-height;
  }
}

.e-multiselect.e-filled:not(.e-float-input) .e-multi-select-wrapper .e-delim-values {
  @if $ddl-multiselect-skin-name == 'material' or $ddl-multiselect-skin-name == 'Material3' {
    font-size: 13px;
    line-height: $ddl-multiselect-filled-input-min-height;
  }
}

.e-multiselect.e-filled.e-float-input .e-multi-select-wrapper .e-delim-values {
  @if $ddl-multiselect-skin-name == 'material' or $ddl-multiselect-skin-name == 'Material3' {
    font-size: 14px;
    line-height: $ddl-multiselect-filled-float-input-min-height;
  }
}

.e-small.e-multiselect.e-filled:not(.e-float-input) .e-multi-select-wrapper .e-delim-values,
.e-small .e-multiselect.e-filled:not(.e-float-input) .e-multi-select-wrapper .e-delim-values,
.e-small.e-multiselect.e-filled.e-float-input .e-multi-select-wrapper .e-delim-values,
.e-small .e-multiselect.e-filled.e-float-input .e-multi-select-wrapper .e-delim-values {
  @if $ddl-multiselect-skin-name == 'material' or $ddl-multiselect-skin-name == 'Material3' {
    font-size: $ddl-delim-bigger-small-font-size;
    line-height: $ddl-multiselect-filled-input-min-height-small;
  }
}

.e-small.e-multiselect.e-filled.e-float-input .e-multi-select-wrapper .e-delim-values,
.e-small .e-multiselect.e-filled.e-float-input .e-multi-select-wrapper .e-delim-values {
  @if $ddl-multiselect-skin-name == 'material' or $ddl-multiselect-skin-name == 'Material3' {
    line-height: $ddl-multiselect-filled-float-input-min-height-small;
  }
}

.e-filled:not(.e-float-input) .e-multi-select-wrapper .e-chips {
  height: 28px;
}

.e-small.e-filled:not(.e-float-input) .e-multi-select-wrapper .e-chips,
.e-small .e-filled:not(.e-float-input) .e-multi-select-wrapper .e-chips,
.e-multiselect.e-filled.e-float-input .e-multi-select-wrapper.e-mob-wrapper .e-chips.e-mob-chip,
.e-small .e-filled:not(.e-float-input) .e-multi-select-wrapper .e-chips,
.e-small.e-filled:not(.e-float-input) .e-multi-select-wrapper .e-chips {
  height: 24px;
}

.e-filled.e-float-input .e-multi-select-wrapper .e-chips {
  height: 18px;
}

.e-filled.e-float-input .e-multi-select-wrapper .e-chips,
.e-filled:not(.e-float-input) .e-multi-select-wrapper .e-chips {
  margin: 8px 8px 0 0;
  padding: 0 8px;
}

.e-rtl.e-filled.e-float-input .e-multi-select-wrapper .e-chips,
.e-rtl.e-filled:not(.e-float-input) .e-multi-select-wrapper .e-chips {
  margin: 8px 0 0 8px;
  padding: 0 8px;
}

.e-small .e-filled .e-multi-select-wrapper .e-chips,
.e-small.e-filled .e-multi-select-wrapper .e-chips {
  height: 16px;
  margin: 4px 4px 0 0;
  padding-right: 8px;
}

.e.rtl.e-small .e-filled.e-float-input .e-multi-select-wrapper .e-chips,
.e-rtl.e-small.e-filled.e-float-input .e-multi-select-wrapper .e-chips {
  margin: 4px 0 0 4px;
}

.e-filled.e-multiselect.e-float-input .e-multi-select-wrapper .e-chips:not(.e-mob-chip) .e-chips-close {
  @if $ddl-multiselect-skin-name == 'material' or $ddl-multiselect-skin-name == 'Material3' {
    height: $ddl-multiselect-filled-float-input-chip-clear-icon-size;
    width: $ddl-multiselect-filled-float-input-chip-clear-icon-size;
  }
}

.e-filled.e-multiselect:not(.e-float-input) .e-multi-select-wrapper .e-chips > .e-chipcontent {
  font-size: 13px;
  padding: 0 8px 0 0;
}

.e-small.e-filled.e-multiselect .e-multi-select-wrapper .e-chips > .e-chipcontent,
.e-small .e-filled.e-multiselect .e-multi-select-wrapper .e-chips > .e-chipcontent {
  font-size: 12px;
}

.e-filled.e-float-input.e-multiselect .e-multi-select-wrapper .e-chips > .e-chipcontent {
  font-size: 12px;
  padding: 0 4px 0 0;
}

.e-rtl.e-filled.e-multiselect .e-multi-select-wrapper .e-chips > .e-chipcontent {
  padding: 0 0 0 4px;
}

.e-small.e-filled.e-float-input.e-multiselect .e-multi-select-wrapper .e-chips > .e-chipcontent,
.e-small .e-filled.e-float-input.e-multiselect .e-multi-select-wrapper .e-chips > .e-chipcontent {
  font-size: 10px;
}

.e-filled.e-multiselect.e-float-input .e-multi-select-wrapper.e-mob-wrapper .e-chips > .e-chipcontent {
  font-size: 13px;
  padding-right: 4px;
}

// Clear icon styles

.e-filled.e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-multiselect.e-control-container .e-filled.e-multi-select-wrapper .e-clear-icon {
  height: 38px;
  margin-top: -38px;
  right: 12px;
  top: 100%;
  width: 16px;
}

.e-multiselect.e-control-wrapper.e-input-group.e-readonly .e-clear-icon,
.e-outline.e-multiselect.e-control-wrapper.e-input-group.e-readonly .e-clear-icon,
.e-filled.e-multiselect.e-control-wrapper.e-input-group.e-readonly .e-clear-icon,
.e-multiselect.e-readonly.e-control-wrapper.e-input-group.e-control-container :not(.e-disabled).e-multi-select-wrapper:hover .e-clear-icon,
.e-multiselect.e-readonly.e-control-wrapper.e-input-group.e-control-container.e-input-group :not(.e-disabled).e-multi-select-wrapper:hover .e-clear-icon {
  display: none;
}

.e-multiselect.e-input-group.e-control-wrapper .e-input-group-icon {
  @if $ddl-multiselect-skin-name != 'Material3' {
    margin-right: 0;
  }
  @else {
    margin: 0;
  }
}

.e-multiselect.e-control-container.e-input-group.e-disabled .e-input-group-icon.e-ddl-icon.e-icons,
.e-multiselect.e-control-container.e-input-group.e-disabled .e-clear-icon {
  cursor: not-allowed;
}

.e-multiselect.e-control-wrapper.e-control-container.e-input-group.e-checkbox .e-multi-select-wrapper.e-down-icon,
.e-multiselect.e-control-wrapper.e-control-container.e-input-group.e-checkbox .e-multi-select-wrapper.e-down-icon .e-multi-searcher .e-dropdownbase.e-control.e-multiselect.e-lib {
  cursor: pointer;
}
