@include export-module('treeview-material-icons') {

  /*! TreeView icons */
  .e-treeview {

    .e-list-item {

      div.e-icons:not(.e-icons-spinner).e-icon-expandable::before,
      div.e-icons:not(.e-icons-spinner).e-icon-collapsible::before {
        content: '\e22f';
      }
    }

    .e-sibling::before {
      content: '';
    }

    .e-popup {

      .e-icons::before {
        content: '\e930';
      }
    }

    &.e-drag-item {

      .e-icons.e-drop-in::before {
        content: '\e22c';
      }

      .e-icons.e-drop-out::before {
        content: '\e22b';
      }

      .e-icons.e-drop-next::before {
        content: '\e22d';
      }

      .e-icons.e-no-drop::before {
        content: '\e22a';
      }
    }
  }
}
