@include export-module('maskedit-theme') {

  /*! maskedtextbox theme */
  .e-control-wrapper.e-mask.e-error #{&}.e-maskedtextbox,
  .e-utility-mask.e-error {
    @if $skin-name != 'bootstrap' and $skin-name != 'FluentUI' {
      color: $mask-error-color;
    }
  }
}
