﻿@include export-module('appbar-layout') {
  .e-appbar {
    display: flex;
    width: 100%;
    height: 48px;
    flex-shrink: 0;
    flex-direction: row;
    position: relative;
    align-items: center;
    padding: 8px;
    overflow: hidden;
    font-size: $appbar-font-size;
    @if ($skin-name == 'fluent2') {
      border-radius: 4px;
    }

    > div:first-child {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
    }

    &.e-sticky {
      position: sticky;
      top: 0;
      z-index: 1000;
    }

    &.e-prominent {
      height: 94px;
      align-items: flex-start;

      > div:first-child {
        align-items: flex-start;
      }
    }

    &.e-dense {
      height: 40px;
    }

    &.e-horizontal-bottom {
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;

      &.e-sticky {
        position: fixed;
        top: auto;
        max-width: inherit;
        left: auto;
        right: auto;
      }
    }

    .e-appbar-separator {
      height: 24px;
    }

    .e-appbar-spacer {
      flex-grow: 1;
    }

    .e-btn,
    .e-css.e-btn {
      border-image: unset;
    }
  }

  .e-inherit.e-menu-wrapper.e-scrollable .e-menu,
  .e-inherit.e-menu-container.e-scrollable .e-menu {
    overflow: hidden;
  }
}
