$skeleton-background-color: $grey-200 !default;
$shimmer-wave-color: $grey-white !default;
$shimmer-background-color: transparent !default;
$shimmer-wave-color-opacity: 50% !default;
$shimmer-wave-length: 100px !default;
$shimmer-wave-pos: 50% !default;
$shimmer-wave-angle: 90deg !default;
$skeleton-border-radius: 2px !default;
$shimmer-wave-angle-rtl: 45deg !default;
$shimmer-effect-duration: 2s !default;
$shimmer-effect-timing-function: ease-in-out !default;
$skeleton-boder: none;
