.e-accordion {
  background: transparent !important;
  border-color: transparent !important;

  .e-acrdn-item {
    background: transparent !important;
  }

  .e-acrdn-header {
    color: var(--text-color) !important;
    background: var(--note-background) !important;

    .e-acrdn-header-content {
      color: var(--text-color) !important;
    }

    .e-toggle-icon span {
      color: var(--text-color) !important;
    }
  }
}
