// Base Badge
$badge-theme : $skin-name !default;
$badge-base-border-radius: .25em !default;
$badge-base-font-size: .75em !default;
$badge-base-line-height: 1 !default;
$badge-base-font-weight: 500 !default;
$badge-base-text-indent: 0 !default;
$badge-base-text-color: rgba(0, 0, 0, .87) !default;
$badge-base-background-color: #fafafa !default;

// Pill Badge
$badge-pill-border-radius: 5em !default;

// Notification Badge
$badge-notification-top: -10px !default;
$badge-notification-left: 100% !default;
$badge-notification-font-size: 12px !default;
$badge-notification-border-radius: 1em !default;
$badge-notification-min-width: 24px !default;
$badge-notification-height: 18px !default;
$badge-notification-padding: 0 8px 0 8px !default;

// Circle Badge
$badge-circle-width: 1.834em !default;
$badge-circle-height: 1.834em !default;
$badge-circle-line-height: 1.834em !default;
$badge-circle-border-radius: 50% !default;
$badge-circle-padding: 0 !default;
$badge-circle-min-width: 0 !default;
$badge-circle-ghost-line-height: 1.8em !default;

// Overlap Badge
$badge-overlap-top: -10px !default;
$badge-overlap-transform-left: translateX(-50%) !default;

// Button Badge
$badge-button-line-height: .9 !default;
$badge-button-circle-width: 2em !default;
$badge-button-circle-height: 2em !default;
$badge-button-circle-line-height: 2em !default;
$badge-ghost-circle-line-height: 1.9em !default;
$badge-button-top: -2px !default;

// Dot Badge
$badge-dot-top: -3px !default;
$badge-dot-width: 6px !default;
$badge-dot-height: 6px !default;
$badge-dot-line-height: 1 !default;
$badge-dot-border-radius: 100% !default;
$badge-dot-padding: 0 !default;
$badge-dot-min-width: 0 !default;
$badge-dot-left: 100% !default;
$badge-dot-box-shadow: 0 0 0 1px #fff !default;

$badge-padding: .25em .4em .25em .4em !default;
$badge-notification-line-height: 18px !default;
$badge-notification-ghost-line-height: 16px !default;

$badge-primary-bg-color: $primary !default;
$badge-secondary-bg-color: $accent !default;
$badge-success-bg-color: #4d841d !default;
$badge-danger-bg-color: #d64113 !default;
$badge-warning-bg-color: #c15700 !default;
$badge-info-bg-color: #0378d5 !default;
$badge-light-bg-color: $grey-white !default;
$badge-dark-bg-color: $grey-dark !default;

$badge-primary-font-color: $primary-font !default;
$badge-secondary-font-color: $accent-font !default;
$badge-light-font-color: $grey-light-font !default;
$badge-dark-font-color: $grey-dark-font !default;
$badge-warning-font-color: $grey-dark-font !default;
