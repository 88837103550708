@use '../base/base.theme' as theme;

:root .dark-theme {
  /* Hauptfarben */
  --background-color: #{theme.$sr-black};
  --text-color: #{theme.$sr-soft-grey};
  --primary-color: #{theme.$sr-power-orange};
  --faded-text: #{theme.$sr-grey-petrol};

  /* Funktionale Farben */
  --success-color: #28a745;
  --warning-color: #e4af00;
  --error-color: #dc3545;
  --info-color: #17a2b8;

  /* Interaktionselemente */
  --button-background: #{theme.$sr-power-orange};
  --button-text: #{theme.$sr-clean-white};
  --button-hover: #{theme.$sr-lighter-power-orange};
  --button-alt-background: #{theme.$sr-grey-petrol};
  --button-alt-hover-background: #{theme.$sr-darker-grey};
  --button-alt-text: #{theme.$sr-deeper-black};
  --button-disabled-text: #{theme.$sr-soft-grey};
  --button-disabled-background: #{theme.$sr-lighter-power-orange};
  --button-disabled-alt-text: #{theme.$sr-grey};
  --button-disabled-alt-background: #{theme.$sr-grey-petrol};
  --button-disabled-icon: #{theme.$sr-lighter-grey};
  --button-hover-icon: #{theme.$sr-lighter-power-orange};

  /* Formularelemente */
  --input-background: #{theme.$sr-lighter-black};
  --input-border: #{theme.$sr-darker-grey};
  --input-focus: #{theme.$sr-power-orange};
  --input-label-color: #{theme.$sr-light-grey};

  /* Strukturelemente */
  --header-background: #{theme.$sr-dark-grey};
  --header-item-background: #{theme.$sr-dark-grey};
  --header-item-text: #{theme.$sr-clean-white};
  --header-item-hover-text: #{theme.$sr-power-orange};
  --header-item-hover-background: #{theme.$sr-black};
  --header-border: #{theme.$sr-power-orange};
  --header-gradient-right: #{theme.$sr-dark-grey};
  --header-gradient-left: #{theme.$sr-dark-grey};
  --header-title: #{theme.$sr-clean-white};
  --header-dropdown-item-text: #{theme.$sr-clean-white};

  /* Sidebar*/
  --sidebar-background: #{theme.$sr-dark-grey};
  --sidebar-item-background: #{theme.$sr-dark-grey};
  --sidebar-item-text: #{theme.$sr-light-grey};
  --sidebar-item-hover-background: #{theme.$sr-power-orange};
  --sidebar-item-hover-text: #{theme.$sr-soft-grey};

  /* Trennlinien */
  --border-color: #{theme.$sr-deep-black};

  /* Statuszustände */
  --disabled-background: #{theme.$sr-lighter-grey};
  --disabled-text: #{theme.$sr-dark-grey};
  --active-item-background: #fff0e6;

  --toolbar-background: #{theme.$sr-lighter-black};

  /* Tabellen */
  --table-bg-color: #{theme.$sr-darker-grey};
  --table-header-color: #{theme.$sr-darker-grey};
  --table-row-color: #{theme.$sr-deeper-black};
  --table-row-alternate-color: #{theme.$sr-black};
  --table-row-highlight-color: #{theme.$sr-dark-grey};
  --table-row-highlight-border-color: #{theme.$sr-power-orange};
  --table-row-selected-color: #{theme.$sr-power-orange};
  --table-row-selected-text-color: #{theme.$sr-clean-white};
  --table-border-color: #{theme.$sr-deeper-black};

  /*Dropdown*/
  --dropdown-item-background: #{theme.$sr-dark-grey};
  --dropdown-item-text: #{theme.$sr-clean-white};
  --dropdown-item-hover-background: #{theme.$sr-power-orange};

  /* PDF-Viewer */
  --pdf-toolbar-color: #{theme.$sr-dark-grey};
  --pdf-page-background: #{theme.$sr-deeper-black};

  /* Note */
  --note-background: #{theme.$sr-darker-grey};
  --note-light-text: #{theme.$sr-lighter-grey};

  /* Dialog */
  --dialog-background: #{theme.$sr-black};

  /* Cards */
  --card-background: #{theme.$sr-dark-grey};

  --card-alt-background: #{theme.$sr-darker-grey};
  --card-hover-background: #{theme.$sr-darker-grey};
  --card-active-background: #{theme.$sr-grey};
}
