@include export-module('message-material-icons') {
  .e-message {
    .e-msg-icon::before,
    .e-msg-close-icon::before {
      font-family: 'e-icons';
    }

    .e-msg-icon::before,
    &.e-info .e-msg-icon::before {
      content: '\e607';
    }

    .e-msg-close-icon::before {
      content: '\e7fc';
    }

    &.e-success .e-msg-icon::before {
      content: '\ea84';
    }

    &.e-error .e-msg-icon::before {
      content: '\e208';
    }

    &.e-warning .e-msg-icon::before {
      content: '\e92f';
    }
  }
}
