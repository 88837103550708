﻿@include export-module('smart-textarea-layout') {
  .smart-textarea-suggestion-overlay {
    display: none;
    position: absolute;
    padding: .25rem .75rem;
    border-radius: .3rem;
    cursor: pointer;
    user-select: none;
    margin-right: .5rem;
  }

  .smart-textarea-suggestion-overlay.smart-textarea-suggestion-overlay-visible {
    display: block;
  }

  .smart-textarea-caret {
    position: absolute;
    width: .8px;
    display: none;
    animation: caret-blink 1.025s step-end infinite;
  }

  @keyframes caret-blink {

    from,
    to {
      opacity: 1;
    }

    50% {
      opacity: 0;
    }
  }
}
