@import 'theme-mixin.scss';
@include export-module('contextmenu-theme') {
  .e-contextmenu-wrapper,
  .e-contextmenu-container {
    @at-root {
      & ul {
        @if $skin-name == 'Material3' {
          background: $cmenu-ul-bgcolor;
        }
        @else {
          background-color: $cmenu-ul-bgcolor;
        }
        color: $cmenu-color;

        & .e-menu-item  {
          @include icon-color;
          @if $skin-name == 'fluent2' {
            & .e-menu-icon,
            & .e-caret {
              color: $icon-color;
            }
          }

          & .e-menu-url {
            color: $cmenu-color;
          }

          &.e-focused {
            @include focused-color;
            @include focused-icon-color;
          }

          &.e-selected {
            @include selected-color;
            @include selected-icon-color;
          }

          &:active {
            @if $skin-name == 'bootstrap5.3' {
              background-color: $cmenu-li-selection-bgcolor;
              color: $cmenu-selected-color;

              & .e-menu-icon {
                color: $cmenu-selected-color;
              }
            }
          }
        }

        @include disabled-color;

        & .e-separator {
          border-bottom-color: $cmenu-li-border-color;
        }
      }
    }

    &:not(.e-menu-container) {
      & .e-menu-vscroll.e-vscroll {
        background-color: inherit;
        border: $cmenu-ul-border;

        & .e-scroll-nav {
          border-color: $cmenu-li-border-color;
        }
      }
    }
  }
}
