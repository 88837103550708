$acrdn-skin: 'material' !default;
$acrdn-slct-header-font-weight: normal;
$acrdn-nrml-header-minheight: 36px !default;
$acrdn-bgr-header-minheight: 48px !default;
$acrdn-nrml-header-lineheight: 35px !default;
$acrdn-bgr-header-lineheight: 47px !default;
$acrdn-nrml-header-paddingpix: 16px !default;
$acrdn-bgr-header-paddingpix: 16px !default;
$acrdn-icon-font-size: $font-size !default;
$acrdn-bgr-arrow-icon-font-size: 12px !default;
$acrdn-bgr-item-header-content-font-size: 14px !default;
$acrdn-content-line-height: 1.5 !default;
$acrdn-tab-highlight-color:  rgba(0, 0, 0, 0) !default;
$acrdn-item-hdr-con-lineheight: 35px !default;
$acrdn-item-selected-first-child-border-top: 0 !default;
$acrdn-item-selected-last-child-border-bottom: 0 !default;
$acrdn-item-border-radius: 0 !default;
$acrdn-item-margin-top: 0 !default;
$acrdn-item-nested-panel-item-select-margin: 0 !default;
$acrdn-item-overflow: visible !default;
$acrdn-item-hover-focus-hdr-con-text-decoration: none !default;
$acrdn-item-hdr-border-radius: 0 !default;
$acrdn-item-tgl-icon-display: table !default;
$acrdn-item-arrow-icon-font-size: 12px !default;
$acrdn-opacity: .5 !default;
$acrdn-nest-nrml-header-padding: 0 40px 0 32px !default;
$acrdn-nest-bgr-header-padding: 0 46px 0 32px !default;
$acrdn-nest-second-nrml-header-padding: 0 40px 0 48px !default;
$acrdn-nest-second-bgr-header-padding: 0 46px 0 48px !default;
$acrdn-nrml-header-padding: 0 40px 0 16px !default;
$acrdn-bgr-header-padding: 0 46px 0 16px !default;
$acrdn-rtl-nrml-header-padding: 0 16px 0 40px !default;
$acrdn-rtl-bgr-header-padding: 0 16px 0 46px !default;
$acrdn-rtl-nest-nrml-header-padding: 0 32px 0 40px !default;
$acrdn-rtl-nest-bgr-header-padding: 0 32px 0 46px !default;
$acrdn-rtl-nest-second-nrml-header-padding: 0 48px 0 40px !default;
$acrdn-rtl-nest-second-bgr-header-padding: 0 48px 0 46px !default;
$acrdn-nested-item-header-focus-box-shadow: none !default;
$acrdn-nrml-content-padding: 16px !default;
$acrdn-bgr-content-padding: 16px !default;
$acrdn-nest-nrml-content-padding: 16px 16px 16px 32px !default;
$acrdn-nest-bgr-content-padding: 16px 16px 16px 32px !default;
$acrdn-nest-second-nrml-content-padding: 16px 16px 16px 48px !default;
$acrdn-nest-second-bgr-content-padding: 16px 16px 16px 48px !default;
$acrdn-rtl-nest-nrml-content-padding: 16px 32px 16px 16px !default;
$acrdn-rtl-nest-bgr-content-padding: 16px 32px 16px 16px !default;
$acrdn-rtl-nest-second-nrml-content-padding: 16px 48px 16px 16px !default;
$acrdn-rtl-nest-second-bgr-content-padding: 16px 48px 16px 16px !default;
$acrdn-nrml-header-icon-padding: 8px !default;
$acrdn-bgr-header-icon-padding: 14px !default;
$acrdn-nrml-icn-minwidth: 24px !default;
$acrdn-bgr-icn-minwidth: 30px !default;
$acrdn-active-bg: $default-bg-color !default;
$acrdn-bg-color: $default-bg-color !default;
$acrdn-default-bg-color: $acrdn-bg-color !default;
$acrdn-active-bg-color: $acrdn-active-bg !default;
$acrdn-selected-header-font-color: $accent !default;
$acrdn-selected-header-color: $header-bg-color !default;
$acrdn-icon-color: rgba($grey-light-font, .54) !default;
$acrdn-selected-icon-color: rgba($grey-light-font, .54) !default;
$acrdn-content-color: rgba($grey-light-font, .87) !default;
$acrdn-header-font:  rgba($grey-light-font, .87) !default;
$acrdn-nested-header-font: rgba($grey-light-font, .87) !default;
$acrdn-header-font-weight: normal !default;
$acrdn-header-font-size: 15px !default;
$acrdn-nest-header-font-size: 14px !default;
$acrdn-nest-header-content-font-size: $acrdn-nest-header-font-size !default;
$acrdn-nest-header-content-font-weight: normal !default;
$acrdn-content-font-size: 13px !default;
$acrdn-header-font-bgr-size: 16px !default;
$acrdn-content-font-bgr-size: 14px !default;
$acrdn-selected-border-size: $border-size !default;
$acrdn-item-padding-size: 0 !default;
$acrdn-selected-border-type: $border-type !default;
$acrdn-selected-border-color: rgba($grey-light-font, .12) !default;
$acrdn-default-border: $acrdn-selected-border-color !default;
$acrdn-header-focus-bg:  $hover-bg-color !default;
$acrdn-header-hover-bg: transparent !default;
$acrdn-header-active-bg: transparent !default;
$acrdn-border-type: solid !default;
$acrdn-border-size: $border-size !default;
$acrdn-border-radius: 0 !default;
$acrdn-item-border-color: $acrdn-selected-border-color !default;
$acrdn-item-border-nav-type: solid !default;
$acrdn-item-border-size: 0 !default;
$acrdn-item-select-last-child-border-bottom: 1px solid transparent !default;
$acrdn-item-select-last-child-border-radius: 0 !default;
$acrdn-item-first-child-hdr-focus-border-radius: 0 !default;
$acrdn-item-last-child-border-bottom: 0 !default;
$acrdn-item-last-child-not-exp-hdr-focus-border-radius: 0 !default;
$acrdn-item-focus-border-color: $acrdn-selected-border-color !default;
$acrdn-item-focus-border-size: 0 !default;
$acrdn-item-focus-selected-exp-border-top: 1px solid $acrdn-selected-header-color !default;
$acrdn-item-focus-selected-exp-hdr-focus-border-color: $acrdn-selected-border-color !default;
$acrdn-item-focus-selected-exp-hdr-focus-border-type: solid !default;
$acrdn-item-focus-selected-exp-hdr-focus-border-size: 0 !default;
$acrdn-item-focus-exp-hdr-hover-focus-bg-color: $grey-200 !default;
$acrdn-item-focus-exp-hdr-hover-focus-color: $acrdn-selected-header-font-color !default;
$acrdn-item-focus-selected-hdr-icons-font: $acrdn-selected-icon-color !default;
$acrdn-item-focus-exp-hdricon-hover-font: $acrdn-selected-header-font-color !default;
$acrdn-item-focus-selected-exp-border-color: $acrdn-header-focus-bg !default;
$acrdn-item-exp-select-border-color: $acrdn-selected-header-color !default;
$acrdn-item-exp-select-border-size: 1px 0 1px 0 !default;
$acrdn-item-header-border: 0 !default;
$acrdn-item-select-exp-hdr-con-disable-font: $accent !default;
$acrdn-item-header-content-disable-color: $acrdn-header-font !default;
$acrdn-item-hdr-con-text-disabled-font: $accent !default;
$acrdn-item-icon-disable-color:  $accent !default;
$acrdn-item-selected-exp-hdr-focus-border-color: $acrdn-selected-border-color !default;
$acrdn-item-selected-exp-hdr-focus-border-type: solid !default;
$acrdn-item-selected-exp-hdr-focus-border-size: 0 !default;
$acrdn-item-selected-exp-hdr-focus-bg: $hover-bg-color !default;
$acrdn-item-selected-exp-hdr-hover-bg: $hover-bg-color !default;
$acrdn-item-selected-exp-hdr-active-bg: $header-bg-color !default;
$acrdn-item-nes-pan-con-last-child-border-bottom: 1px none $acrdn-selected-border-color !default;
$acrdn-item-select-bg: none !default;
$acrdn-item-select-border-color: transparent !default;
$acrdn-item-select-border-size: 1px 0 1px 0 !default;
$acrdn-item-panel-content-select-bg: none !default;
$acrdn-item-panel-content-select-border-top: 0 !default;
$acrdn-item-panel-content-select-border-bottom: 0 !default;
$acrdn-item-header-hover-border: 0 !default;
$acrdn-item-header-hover-bg: transparent !default;
$acrdn-item-header-hover-border-color: initial !default;
$acrdn-item-header-active-border: 0 !default;
$acrdn-item-header-active-border-color: initial !default;
$acrdn-item-header-focus-border: 0 !default;
$acrdn-item-header-focus-box-shadow: none !default;
$acrdn-item-header-focus-bg: $hover-bg-color !default;
$acrdn-item-header-focus-active-color: $acrdn-header-font !default;
$acrdn-item-select-exp-hdr-hover-color: $acrdn-header-font !default;
$acrdn-item-selected-exp-hdr-border-color: initial !default;
$acrdn-item-selected-exp-hdr-border-size: 0 !default;
$acrdn-item-selected-exp-hdr-bg: $header-bg-color !default;
$acrdn-item-selected-exp-hdr-hover-color: $acrdn-selected-icon-color !default;
$acrdn-item-selected-exp-hdr-hover-focus-color: $accent !default;
$acrdn-item-selected-exp-hdr-icon-color: $accent !default;
$acrdn-item-selected-exp-hdr-hover-border-color: initial !default;
$acrdn-item-selected-tgl-icon-color: $acrdn-selected-icon-color !default;
$acrdn-item-selected-hdr-font: $accent !default;
$acrdn-item-select-hdr-focus-border-color: $hover-bg-color !default;
$acrdn-item-selected-border-color: $acrdn-selected-border-color !default;
$acrdn-item-selected-border-size: 0 0 1px 0 !default;
$acrdn-item-selected-select-last-border-bottom: 0 !default;
$acrdn-item-selected-selected-border-color: transparent transparent $acrdn-selected-border-color !default;
$acrdn-item-selected-selected-border-size: 1px 0 1px 0 !default;
$acrdn-item-selected-header-background: transparent !default;
$acrdn-item-selected-hdr-border-color: initial !default;
$acrdn-item-selected-hdr-border-size: 0 !default;
$acrdn-item-selected-header-border-radius: 0 !default;
$acrdn-item-selected-hdr-con-font: $acrdn-header-font !default;
$acrdn-item-selected-select-border-color: $acrdn-selected-border-color !default;
$acrdn-item-selected-select-border-size: 1px 0 1px 0 !default;
$acrdn-item-selected-hdr-icon-font: $acrdn-selected-icon-color !default;
$acrdn-item-selected-exp-hdr-con-hover-color: $accent !default;
$acrdn-item-selected-exp-hdr-icon-hover-color: $accent !default;
$acrdn-item-selected-select-active-bg: $header-bg-color !default;
$acrdn-item-nes-pan-exp-hdr-color: $accent !default;
$acrdn-item-selected-hdr-hover-border-color: initial !default;
$acrdn-item-selected-hdr-tgl-icon-hover-font: $acrdn-selected-icon-color !default;
$acrdn-item-nes-selected-exp-hover-hdr-icons-font: $acrdn-selected-icon-color !default;
$acrdn-item-nes-selected-exp-hover-hdr-con-font: $accent !default;
$acrdn-item-exp-not-selected-hdr-focus-bg: $hover-bg-color !default;
$acrdn-item-exp-not-selected-hdr-focus-border: 0 !default;
$acrdn-item-exp-not-selected-hdr-focus-border-color: initial !default;
