.e-uploader {
  border-color: var(--input-border);

  .e-upload {
    border-color: var(--input-border);

    button {
      width: 160px;
    }
  }

  .e-upload-files {
    background-color: var(--card-background);
    border-color: var(--input-border);

    .e-file-name,
    .e-file-type,
    .e-file-size,
    .e-file-remove-btn {
      color: var(--text-color) !important;
    }
  }
}
