@include export-module('calendar-bigger') {

  .e-bigger#{&}.e-calendar,
  #{if(&, '&', '*')}.e-bigger #{&}.e-calendar {
    max-width: $calendar-bigger-max-width;
    min-width: $calendar-bigger-min-width;
    padding: $calendar-popup-bigger-padding;

    #{if(&, '&', '*')}.e-calendar-day-header-lg {
      max-width: $calendar-lg-day-header-format-max-width;
      min-width: $calendar-lg-day-header-format-min-width;
    }
    #{if(&, '&', '*')}.e-week {
      max-width: $calendar-week-bigger-max-width;
      min-width: $calendar-week-bigger-min-width;
    }
    #{if(&, '&', '*')}.e-week-number {
      min-width: $calendar-weeknumber-bigger-width;
    }
    #{if(&, '&', '*')} .e-header .e-title {
      font-size: $calendar-bigger-header-font-size;
      @if $skin-name == 'bootstrap5.3' {
        line-height: $calendar-bigger-header-line-height;
        padding: $calendar-bigger-header-title-padding;
      }
      @else {
        line-height: $calendar-bigger-header-height;
      }
      @if $skin-name == 'fluent2' {
        width: auto;
      }
      @else {
        width: 60%;
      }
    }
    #{if(&, '&', '*')}.e-rtl .e-header .e-title {
      line-height: $calendar-bigger-header-height;
      text-indent: 6px;
    }
    #{if(&, '&', '*')} .e-header {
      height: $calendar-bigger-header-height;
      padding: $calendar-bigger-header-padding;

      #{if(&, '&', '*')} button {
        margin-right: $calendar-bigger-icon-margin;

        #{if(&, '&', '*')} span {
          font-size: $calendar-bigger-icon-font-size;
          padding: $calendar-bigger-icon-padding;
        }
      }

      #{if(&, '&', '*')}.e-year,
      #{if(&, '&', '*')}.e-decade {
        padding: $calendar-bigger-yeardecade-header-padding;
      }
    }
    #{if(&, '&', '*')} th {
      font-size: $calendar-bigger-week-header-font-size;
      height: $calendar-bigger-day-header-height;
      text-transform: $calendar-normal-day-header-text;
    }
    #{if(&, '&', '*')} .e-content {
      #{if(&, '&', '*')}.e-year span.e-day {
        font-size: $calendar-bigger-yeardecade-font-size;
        font-weight: $calendar-bigger-yeardecade-font-weight;
      }

      #{if(&, '&', '*')}.e-month table {
        padding: $calendar-bigger-table-month-padding;
      }

      #{if(&, '&', '*')}.e-year table,
      #{if(&, '&', '*')}.e-decade table	   {
        padding: $calendar-bigger-table-yeardecade-padding;
      }

      #{if(&, '&', '*')} .e-selected ,
      #{if(&, '&', '*')} .e-state-hover {
        border-radius: 0;
      }
      #{if(&, '&', '*')} span.e-day {
        font-size: $calendar-bigger-month-font-size;
        height: $calendar-bigger-month-view-height;
        line-height: $calendar-bigger-month-view-height;
        width: $calendar-bigger-month-view-width;
      }
    }
    #{if(&, '&', '*')} .e-content.e-month td.e-today span.e-day {
      line-height: $calendar-bigger-month-view-line-height;
    }
    #{if(&, '&', '*')} .e-content td.e-today span.e-day {
      @if $skin-name == 'FluentUI' {
        height: 29px;
        width: 29px;
        line-height: 29px;
      }
    }
    #{if(&, '&', '*')} .e-content.e-month td {
      height: $calendar-bigger-month-view-padding;
      padding: $calendar-bigger-month-view-padding;
    }
    #{if(&, '&', '*')} .e-content.e-year td,
    #{if(&, '&', '*')} .e-content.e-decade td {
      @if ($skin-name != 'Material3' and $skin-name != 'fluent2') {
        height: $calendar-bigger-year-decade-height;
        padding: $calendar-bigger-year-decade-padding;
      }
      @else {
        height: $calendar-bigger-year-decade-height;
        padding: $calendar-bigger-year-decade-padding;
        width: $calendar-bigger-year-decade-width;
      }
    }
    #{if(&, '&', '*')} .e-content.e-year td > span.e-day,
    #{if(&, '&', '*')} .e-content.e-decade td > span.e-day {
      @if ($skin-name != 'Material3' and $skin-name != 'fluent2') {
        height: $calendar-bigger-year-decade-height;
        line-height: $calendar-bigger-year-decade-height;
        width: $calendar-bigger-year-decade-width;
      }
      @else {
        height: $calendar-bigger-year-decade-height-inside;
        line-height: $calendar-bigger-year-decade-height-inside;
        width: $calendar-bigger-year-decade-width;
      }
    }
    #{if(&, '&', '*')} .e-header .e-icon-container {
      #{if(&, '&', '*')} .e-prev,
      #{if(&, '&', '*')} .e-next  {
        height: $calendar-bigger-icon-size;
        width: $calendar-bigger-icon-size;
      }

      #{if(&, '&', '*')} .e-prev {
        @if $skin-name == 'tailwind' {
          margin: $calendar-bigger-down-icon-margin;
        }
      }
    }
    #{if(&, '&', '*')} .e-footer-container {
      border-top: $calendar-bigger-footer-border;
      padding: $calendar-bigger-footer-container-padding;
    }
    #{if(&, '&', '*')} .e-header .e-title {
      @if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' {
        margin-left: $calendar-title-margin-bigger-small-left-style;
      }
    }

    #{if(&, '&', '*')}.e-rtl .e-header .e-title {
      @if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' {
        text-indent: $calendar-rtl-bigger-text-indent;
      }
    }
  }

  .e-bigger .e-content-placeholder.e-calendar.e-placeholder-calendar,
  .e-bigger.e-content-placeholder.e-calendar.e-placeholder-calendar {
    background-size: 300px 392px;
    min-height: 392px;
  }

  .e-bigger.e-small #{&}.e-calendar {
    @if $skin-name == 'Material3' or $skin-name == 'fluent2' {
      background: $calendar-bg-color;
      border-radius: 8px;
    }
    @if ($skin-name != 'Material3' and $skin-name != 'fluent2') {
      background-color: $calendar-bg-color;
    }
    border: $calendar-border-style;
    box-shadow: $calendar-box-shadow;
    #{if(&, '&', '*')} .e-date-icon-prev,
    #{if(&, '&', '*')} .e-date-icon-next {
      color: $calendar-header-icon-color;
    }
    #{if(&, '&', '*')} th {
      border-bottom: 0;
      color: $calendar-week-header-font-color;
    }
    @at-root {
      #{if(&, '&', '*')} .e-header {
        border-bottom: 0;
        #{if(&, '&', '*')} a {
          #{if(&, '&', '*')} span {
            border: $calendar-default-border-color;
            color: $calendar-icon-font-color;
          }
        }
        #{if(&, '&', '*')} .e-title {
          color: $calendar-title-font-color;
        }
        #{if(&, '&', '*')} .e-title:hover {
          background: $calendar-title-hover-bg-color;
          cursor: pointer;
          text-decoration: $calendar-title-decoration-style;
          @if $skin-name == 'fluent2' {
            color: $calendar-title-hover-color;
          }
        }
        #{if(&, '&', '*')} .e-title:focus {
          box-shadow: $calendar-title-focus-box-shadow;
          @if $skin-name != 'fluent2' {
            background: $calendar-title-focus-background;
          }
          @if $skin-name == 'highcontrast' {
            text-decoration: none;
          }
          @else {
            text-decoration: $calendar-title-decoration-style;
          }
        }
        #{if(&, '&', '*')} .e-title:active {
          @if $skin-name == 'fluent2' {
            background: $calendar-title-active-background;
            color: $calendar-title-active-color;
          }
          @else {
            box-shadow: $calendar-box-shadow;
            background: $calendar-title-active-background;
            text-decoration: $calendar-title-decoration-style;
          }
        }
        #{if(&, '&', '*')} .e-prev:hover > span,
        #{if(&, '&', '*')} .e-next:hover > span {
          border: $calendar-icon-hover-border-color;
          color: $calendar-icon-hover-color;
          cursor: pointer;
        }

        #{if(&, '&', '*')} .e-prev:hover,
        #{if(&, '&', '*')} .e-next:hover {
          background: $calendar-icon-hover-bg-color;
          @if $skin-name == 'fluent2' {
            color: $calendar-active-font-color;
          }
        }
        #{if(&, '&', '*')} .e-prev:focus,
        #{if(&, '&', '*')} .e-next:focus {
          box-shadow: $calendar-icon-focus-box-shadow;
        }
        #{if(&, '&', '*')} .e-prev:active,
        #{if(&, '&', '*')} .e-next:active {
          background: $calendar-active-state-icon-bg-color;
          color: $calendar-active-font-color;
        }
        #{if(&, '&', '*')} button.e-prev:active span,
        #{if(&, '&', '*')} button.e-next:active span {
          border: $calendar-selected-border-color;
          color: $calendar-active-icon-color;
        }
        #{if(&, '&', '*')}.e-decade .e-title {
          @if $skin-name != 'fluent2' {
            color: $calendar-light-font;
            cursor: default;
          }
        }
        #{if(&, '&', '*')} .e-next.e-disabled span,
        #{if(&, '&', '*')} .e-prev.e-disabled span {
          color: $calendar-disable-font-color;
          font-weight: $calendar-disable-font-weight-style;
        }
        #{if(&, '&', '*')} .e-next.e-disabled,
        #{if(&, '&', '*')} .e-prev.e-disabled {
          opacity: $calendar-disable-opacity;
        }
      }
      #{if(&, '&', '*')} .e-content.e-year,
      #{if(&, '&', '*')} .e-content.e-decade {
        #{if(&, '&', '*')} td.e-selected span.e-day {
          @if $skin-name == 'fluent2' {
            font-weight: 600;
          }
        }
      }
      #{if(&, '&', '*')} .e-content {
        #{if(&, '&', '*')}.e-decade tr:first-child .e-cell:first-child span.e-day,
        #{if(&, '&', '*')}.e-decade tr:last-child .e-cell:last-child span.e-day {
          color: $calendar-other-decade-cell-color;
        }
        #{if(&, '&', '*')}.e-decade tr:first-child .e-cell:first-child.e-selected span.e-day,
        #{if(&, '&', '*')}.e-decade tr:last-child .e-cell:last-child.e-selected span.e-day {
          color: $calendar-active-font-color;
        }
        #{if(&, '&', '*')}.e-decade tr:first-child .e-cell.e-disabled:first-child span.e-day,
        #{if(&, '&', '*')}.e-decade tr:last-child .e-cell.e-disabled:last-child span.e-day {
          color: $calendar-disable-font-color;
        }
        #{if(&, '&', '*')}.e-year td:hover span.e-day,
        #{if(&, '&', '*')}.e-decade td:hover span.e-day {
          @if ($skin-name != 'Material3' and $skin-name == 'fluent2') {
            background-color: $calendar-hover-color;
          }
          @if $skin-name == 'Material3' or $skin-name == 'fluent2' {
            background: $calendar-hover-color;
          }
        }
        #{if(&, '&', '*')}.e-year td.e-selected:hover span.e-day,
        #{if(&, '&', '*')}.e-decade td.e-selected:hover span.e-day {
          @if ($skin-name != 'Material3' and $skin-name != 'fluent2') {
            background-color: $calendar-yeardecade-selected-hover-bg;
          }
          @if $skin-name == 'Material3' or $skin-name == 'fluent2' {
            background: $calendar-yeardecade-selected-hover-bg;
          }
        }

        #{if(&, '&', '*')}.e-year td > span.e-day,
        #{if(&, '&', '*')}.e-decade td > span.e-day {
          background: $calendar-yeardecade-bg-color;
        }

        #{if(&, '&', '*')} .e-week-number span {
          color: $calendar-week-number-color-style;
        }

        #{if(&, '&', '*')} td.e-focused-date span.e-day,
        #{if(&, '&', '*')} td.e-focused-date:hover span.e-day,
        #{if(&, '&', '*')} td.e-focused-date:focus span.e-day {
          background: $calendar-focused-date-bg-style;
          border: $calendar-focus-border-color;
          border-radius: $calendar-border-radius;
          box-shadow: $calendar-focus-box-shadow;
          @if ($skin-name == 'FluentUI') {
            background: none;
            border-radius: $calendar-focused-state-border-radius;
            box-shadow: $calendar-focused-state-box-shadow;
          }
          @if $skin-name == 'fluent2' {
            font-weight: 600;
          }
        }
        #{if(&, '&', '*')} td.e-focused-date:hover span.e-day {
          @if ($skin-name != 'Material3' and $skin-name != 'fluent2') {
            background-color: $calendar-hover-color;
          }
          @if $skin-name == 'Material3' or $skin-name == 'fluent2' {
            background: $calendar-hover-color;
          }
          border: $calendar-hover-border-color;
          border-radius: $calendar-border-radius;
          color: $calendar-text-color;
        }
        #{if(&, '&', '*')} td.e-today span.e-day,
        #{if(&, '&', '*')} td.e-focused-date.e-today span.e-day {
          background: $calendar-today-bg-style;
          border: $calendar-today-border-color;
          @if $skin-name != 'fluent2' {
            border-radius: $calendar-border-radius;
          }
          @else {
            border-radius: $calendar-today-border-radius;
          }
          box-shadow: $calendar-today-box-shadow;
          color: $calendar-today-color;
          @if ($skin-name == 'FluentUI') {
            border-radius: $calendar-border-selected-radius;
          }
        }
        #{if(&, '&', '*')} td.e-focused-date.e-today span.e-day {
          background: $calendar-focused-today-bg-style;
          border: $calendar-focused-today-border-style;
          box-shadow: $calendar-focused-today-box-shadow;
          color: $calendar-today-focused-font-color;
        }
        #{if(&, '&', '*')} td.e-today:focus span.e-day,
        #{if(&, '&', '*')} td.e-focused-date.e-today:focus span.e-day {
          @if ($skin-name != 'Material3' and $skin-name != 'fluent2') {
            background-color: $calendar-focus-bg-color;
          }
          @if $skin-name == 'Material3' {
            background: $calendar-focus-bg-color;
          }
          @if $skin-name == 'fluent2' {
            background: $calendar-today-focus-bg-color;
          }
          border: $calendar-focus-border-color;
          @if $skin-name == 'fluent2' {
            border-radius: $calendar-today-border-radius;
          }
          @else {
            border-radius: $calendar-border-radius;
          }
          color: $calendar-today-focus-color;
          @if ($skin-name == 'FluentUI') {
            background-color: $calendar-focused-today-bg-style;
            border-radius: $calendar-border-selected-radius;
          }
        }
        #{if(&, '&', '*')} td.e-today:hover span.e-day,
        #{if(&, '&', '*')} td.e-focused-date.e-today:hover span.e-day,
        #{if(&, '&', '*')} td.e-focused-date.e-today:focus span.e-day {
          @if ($skin-name != 'Material3' and $skin-name != 'fluent2') {
            background-color: $calendar-hover-color;
          }
          @if $skin-name == 'Material3' {
            background: $calendar-hover-color;
          }
          @if $skin-name == 'fluent2' {
            background: $calendar-today-hover-color;
            border-radius: $calendar-border-selected-radius;
          }
          border: $calendar-today-border-color;
          color: $calendar-today-focus-color;
          @if ($skin-name == 'FluentUI') {
            background-color: $calendar-today-bg-style;
            border-radius: $calendar-border-selected-radius;
          }
        }
        #{if(&, '&', '*')} td.e-today.e-selected span.e-day {
          @if ($skin-name != 'Material3' and $skin-name != 'fluent2') {
            background-color: $calendar-active-bg-color;
          }
          @if $skin-name == 'Material3' {
            background: $calendar-active-bg-color;
          }
          @if $skin-name == 'fluent2' {
            background: $calendar-focused-today-bg-style;
            border-radius: $calendar-today-border-radius;
          }
          border: $calendar-active-bg-border-color;
          @if ($skin-name == 'material' or $skin-name == 'material-dark' or $skin-name == 'Material3' or $skin-name == 'fluent2') {
            box-shadow: $calendar-selected-box-shadow;
          }
          @else {
            box-shadow: $calendar-active-bg-box-shadow;
          }
          color: $calendar-active-today-font-color;
        }
        #{if(&, '&', '*')} td.e-today.e-selected:hover span.e-day {
          @if $skin-name == 'fluent2' {
            border-radius: $calendar-today-border-radius;
          }
        }

        #{if(&, '&', '*')} td.e-today.e-selected:hover span.e-day,
        #{if(&, '&', '*')} td.e-selected:hover span.e-day,
        #{if(&, '&', '*')} td.e-selected.e-focused-date span.e-day {
          @if ($skin-name != 'Material3' and $skin-name != 'fluent2') {
            background-color: $calendar-active-hover-bg-color;
          }
          @if $skin-name == 'Material3' or $skin-name == 'fluent2' {
            background: $calendar-active-hover-bg-color;
          }
          color: $calendar-active-today-hover-font-color;
          @if ($skin-name == 'FluentUI') {
            background-color: $calendar-focused-today-bg-style;
            border-radius: $calendar-border-selected-radius;
            color: $calendar-today-color;
          }
        }
        #{if(&, '&', '*')} span {
          color: $calendar-text-color;
        }
        #{if(&, '&', '*')} .e-disabled span.e-day:hover {
          background: $calendar-none-style;
          border: 0;
          color: $calendar-disable-font-color;
        }
        #{if(&, '&', '*')} .e-other-month:hover span.e-day {
          @if ($skin-name != 'fabric-dark') {
            color: $calendar-other-month-date-hover-bg;
          }
        }
        #{if(&, '&', '*')} .e-other-month span.e-day,
        #{if(&, '&', '*')} .e-other-month.e-today span.e-day {
          color: $calendar-other-month-date;
        }
        #{if(&, '&', '*')} .e-other-month.e-today:hover span.e-day {
          @if ($skin-name != 'Material3' and $skin-name != 'fluent2') {
            background-color: $calendar-hover-color;
          }
          @if $skin-name == 'Material3' or $skin-name == 'fluent2' {
            background: $calendar-hover-color;
          }
          color: $calendar-other-month-date;
          @if ($skin-name == 'FluentUI') {
            background: $calendar-today-bg-style;
            border: $calendar-today-border-color;
            border-radius: $calendar-border-selected-radius;
            box-shadow: $calendar-today-box-shadow;
            color: $calendar-today-color;
          }
        }
        #{if(&, '&', '*')} thead {
          background: $calendar-week-header-bg-style;
          border-bottom: 0;
        }
        #{if(&, '&', '*')} td:hover span.e-day,
        #{if(&, '&', '*')} td:focus span.e-day {
          @if ($skin-name != 'Material3' and $skin-name != 'fluent2') {
            background-color: $calendar-hover-color;
          }
          @if $skin-name == 'Material3' or $skin-name == 'fluent2' {
            background: $calendar-hover-color;
          }
          border: $calendar-hover-border-color;
          border-radius: $calendar-border-radius;
          color: $calendar-hover-text;
        }
        #{if(&, '&', '*')} td:focus span.e-day {
          @if ($skin-name != 'Material3' and $skin-name != 'fluent2') {
            background-color: $calendar-focus-bg-color;
            color: $calendar-text-color;
          }
          @if $skin-name == 'Material3' or $skin-name == 'fluent2' {
            background: $calendar-focus-bg-color;
            color: $calendar-text-color-focus;
          }
          border: $calendar-focus-border-color;
          border-radius: $calendar-border-radius;
          @if ($skin-name == 'FluentUI') {
            border-radius: $calendar-border-selected-radius;
          }
        }
        #{if(&, '&', '*')} td.e-disabled span.e-day,
        #{if(&, '&', '*')} td.e-disabled:hover span.e-day,
        #{if(&, '&', '*')} td.e-disabled:focus span.e-day {
          background: $calendar-none-style;
          border: $calendar-none-style;
          color: $calendar-disable-font-color;
        }
        #{if(&, '&', '*')} td.e-today.e-disabled.e-overlay span.e-day,
        #{if(&, '&', '*')} td.e-today.e-disabled.e-overlay:hover span.e-day,
        #{if(&, '&', '*')} td.e-today.e-disabled.e-overlay:focus span.e-day {
          @if ($skin-name == 'FluentUI') {
            background-color: $calendar-focused-today-bg-style;
            border-radius: $calendar-border-selected-radius;
            color: $calendar-today-color;
          }
        }
        #{if(&, '&', '*')} td.e-selected span.e-day {
          @if ($skin-name != 'Material3' and $skin-name != 'fluent2') {
            background-color: $calendar-active-bg-color;
          }
          @if $skin-name == 'Material3' {
            background: $calendar-active-bg-color;
          }
          @if $skin-name == 'fluent2' {
            background: $calendar-active-bg-color;
            color: $calendar-active-font-colour;
          }
          @else {
            color: $calendar-active-font-color;
          }
          border: $calendar-selected-border-color;
          border-radius: $calendar-border-radius;
        }
        #{if(&, '&', '*')} td.e-selected:not(.e-focused-date) span.e-day {
          @if ($skin-name == 'FluentUI' or $skin-name == 'fluent2') {
            box-shadow: $calendar-selected-box-shadow;
          }
        }
        #{if(&, '&', '*')} .e-footer {
          color: $calendar-active-bg-color;
        }
        #{if(&, '&', '*')} td.e-today.e-selected.e-focused-date span.e-day,
        #{if(&, '&', '*')} td.e-today.e-selected:hover span.e-day,
        #{if(&, '&', '*')} td.e-today.e-selected span.e-day {
          @if ($skin-name == 'FluentUI' or $skin-name == 'fluent2') {
            background-color: $calendar-focused-today-bg-style;
            border-radius: $calendar-border-selected-radius;
            color: $calendar-today-color;
          }
        }
        #{if(&, '&', '*')} td.e-today.e-selected.e-focused-date {
          @if ($skin-name == 'FluentUI' or $skin-name == 'fluent2') {
            background-color: $calendar-active-bg-color;
            border-radius: $calendar-focused-state-border-radius;
            box-shadow: $calendar-focused-state-box-shadow;
          }
        }
        #{if(&, '&', '*')} td.e-today.e-selected {
          @if ($skin-name == 'FluentUI' or $skin-name == 'fluent2') {
            background-color: $calendar-active-bg-color;
            border-radius: $calendar-wrapper-border-radius;
            box-shadow: $calendar-selected-box-shadow;
          }
        }
        #{if(&, '&', '*')} td.e-selected:hover span.e-day {
          @if ($skin-name == 'FluentUI' or $skin-name == 'fluent2') {
            background-color: $calendar-active-bg-color;
            border: $calendar-selected-border-color;
            border-radius: $calendar-border-radius;
            color: $calendar-active-font-color;
          }
        }
        #{if(&, '&', '*')} td.e-selected.e-focused-date span.e-day {
          @if ($skin-name == 'FluentUI' or $skin-name == 'fluent2') {
            background-color: $calendar-active-bg-color;
            border: $calendar-selected-border-color;
            border-radius: $calendar-focused-state-border-radius;
            color: $calendar-active-font-color;
          }
        }
        #{if(&, '&', '*')}.e-month td.e-today span.e-day {
          @if ($skin-name == 'FluentUI' or $skin-name == 'fluent2') {
            color: $calendar-today-focused-font-color;
          }
        }
        #{if(&, '&', '*')}.e-month td.e-disabled.e-today span.e-day {
          @if ($skin-name == 'FluentUI' or $skin-name == 'fluent2') {
            color: $calendar-today-disabled-color;
          }
        }
        #{if(&, '&', '*')}.e-year td.e-selected > span.e-day,
        #{if(&, '&', '*')}.e-decade td.e-selected > span.e-day,
        #{if(&, '&', '*')}.e-year td.e-selected:hover > span.e-day,
        #{if(&, '&', '*')}.e-decade td.e-selected:hover > span.e-day {
          @if ($skin-name == 'FluentUI' or $skin-name == 'fluent2') {
            background-color: $calendar-selected-month-bg-color;
            color: $calendar-week-header-font-color;
          }
        }
        #{if(&, '&', '*')}.e-year td.e-focused-date > span.e-day,
        #{if(&, '&', '*')}.e-decade td.e-focused-date > span.e-day {
          @if ($skin-name == 'FluentUI' or $skin-name == 'fluent2') {
            background: $calendar-none-style;
            border-radius: 0;
            box-shadow: $calendar-focused-state-month-box-shadow;
          }
        }
        #{if(&, '&', '*')} td.e-focused-date.e-today {
          @if ($skin-name == 'FluentUI' or $skin-name == 'fluent2') {
            border-radius: $calendar-focused-state-border-radius;
            box-shadow: $calendar-focused-state-box-shadow;
          }
        }
        #{if(&, '&', '*')} td.e-focused-date:hover span.e-day {
          @if ($skin-name == 'FluentUI' or $skin-name == 'fluent2') {
            border-radius: $calendar-focused-state-border-radius;
          }
        }
        #{if(&, '&', '*')}.e-year td.e-focused-date:hover > span.e-day,
        #{if(&, '&', '*')}.e-decade td.e-focused-date:hover > span.e-day {
          @if ($skin-name == 'FluentUI' or $skin-name == 'fluent2') {
            background: $calendar-hover-color;
            border-radius: 0;
            box-shadow: $calendar-focused-state-month-box-shadow;
          }
        }
      }
    }
    #{if(&, '&', '*')}.e-device {
      #{if(&, '&', '*')} .e-prev:hover,
      #{if(&, '&', '*')} .e-next:hover,
      #{if(&, '&', '*')} .e-prev:active,
      #{if(&, '&', '*')} .e-next:active,
      #{if(&, '&', '*')} .e-prev:focus,
      #{if(&, '&', '*')} .e-next:focus {
        background: $calendar-device-icon-hover-bg-color;
      }
      #{if(&, '&', '*')} button.e-prev:active span,
      #{if(&, '&', '*')} button.e-next:active span {
        color: $calendar-header-icon-color;
      }
    }

    #{if(&, '&', '*')} .e-footer-container {
      #{if(&, '&', '*')} .e-btn.e-today.e-flat.e-primary:not(:hover) {
        @if $skin-name == 'tailwind' {
          -webkit-tap-highlight-color: transparent;
          background-color: $calendar-today-btn-primary-bgcolor;
          border-color: $calendar-today-btn-primary-border-color;
          color: $calendar-today-btn-primary-color;
        }
      }

      #{if(&, '&', '*')} .e-btn.e-today.e-flat.e-primary.e-disabled {
        @if $skin-name == 'tailwind' {
          -webkit-tap-highlight-color: transparent;
          background-color: $calendar-today-btn-primary-disabled-bgcolor;
          border-color: $calendar-today-btn-primary-disabled-border-color;
          color: $calendar-today-btn-primary-disabled-color;
        }
      }
    }
  }

  .e-bigger.e-small #{&}.e-calendar {
    /* stylelint-disable property-no-vendor-prefix */
    -webkit-tap-highlight-color: transparent;
    border-radius: $calendar-wrapper-border-radius;
    display: $calendar-block-style;
    overflow: auto;
    user-select: $calendar-none-style;

    #{if(&, '&', '*')}.e-rtl .e-header .e-title {
      float: $calendar-float-right-style;
      text-align: $calendar-float-right-style;
    }
    #{if(&, '&', '*')}.e-rtl .e-header .e-icon-container {
      float: $calendar-float-left-style;
    }
    #{if(&, '&', '*')} .e-header {
      background: $calendar-none-style;
      display: table;
      font-weight: $calendar-spanicon-font-weight-style;
      position: relative;
      text-align: center;
      width: $calendar-full-width;

      #{if(&, '&', '*')} button {
        background: transparent;
        border: 0;
        margin-right: $calendar-icon-button-margin;
        padding: 0;
        text-decoration: $calendar-none-style;
      }
      #{if(&, '&', '*')} span {
        cursor: $calendar-cursor-pointer-style;
        display: inline-block;
        font-size: $calendar-icon-font-size-style;
        font-weight: $calendar-spanicon-font-weight-style;
        line-height: $calendar-icon-line-height;
        padding: $calendar-icon-padding;
        vertical-align: middle;

        #{if(&, '&', '*')}.e-disabled {
          cursor: $calendar-cursor-default-style;
        }
      }
    }
    #{if(&, '&', '*')} .e-week-header {
      padding: $calendar-thead-padding;
    }
    #{if(&, '&', '*')} th {
      cursor: $calendar-cursor-default-style;
      font-size: $calendar-header-font-size;
      font-weight: normal;
      text-align: center;
    }
    #{if(&, '&', '*')} .e-content {
      #{if(&, '&', '*')} .e-selected ,
      #{if(&, '&', '*')} .e-state-hover {
        border-radius: 0;
      }
      #{if(&, '&', '*')} span.e-day {
        border-radius: 0;
        cursor: $calendar-cursor-pointer-style;
        display: $calendar-inline-block-style;
        font-size: $calendar-date-font-size;
        overflow: hidden;
        padding: 0;
        text-align: center;
        text-decoration: $calendar-none-style;
        vertical-align: middle;
      }
      #{if(&, '&', '*')} th,
      #{if(&, '&', '*')} td {
        box-sizing: border-box;
      }
      #{if(&, '&', '*')} td.e-disabled {
        opacity: $calendar-disable-opacity;
        pointer-events: $calendar-none-style;
        touch-action: $calendar-none-style;
      }
      #{if(&,'&','*')} td.e-disabled.e-today {
        @if $skin-name == 'fabric' or $skin-name == 'fabric-dark' or $skin-name == 'material' or $skin-name == 'material-dark' or $skin-name == 'highcontrast' or $skin-name == 'Material3' or $skin-name == 'fluent2' {
          opacity: 1;
          #{if(&,'&','*')} span.e-day {
            box-shadow: $calendar-disabled-today-box-shadow;
            color: $calendar-disabled-today-font-color;
          }
        }
      }
      #{if(&, '&', '*')} td {
        cursor: pointer;
        padding: $calendar-yeardeacde-span-padding;
        text-align: center;
        #{if(&, '&', '*')}.e-week-number {
          color: $calendar-week-number-font-color;
          font-size: $calendar-week-number-font-size-style;
          font-style: $calendar-week-number-font-style;
          font-weight: $calendar-week-number-font-weight;
          @if $skin-name == 'FluentUI' {
            background-color: $calendar-week-number-bg-color;
            border-right: $calendar-week-number-border;
          }
          @if $skin-name == 'fluent2' {
            border-right: $calendar-week-number-border;
          }
        }
        #{if(&, '&', '*')}.e-overlay {
          background: $calendar-none-style;
          width: initial;
        }
      }
      #{if(&, '&', '*')} td:hover {
        @if skin-name == 'fluent2' {
          border-radius: 2px;
          background: $calendar-hover-border-color;
        }
      }

      table {
        border-collapse: separate;
        border-spacing: 0;
        border-width: 0;
        float: $calendar-float-left-style;
        margin: 0;
        outline: 0;
        padding: $calendar-table-padding;
        table-layout: fixed;
        width: $calendar-full-width;
      }
      #{if(&, '&', '*')} td.e-other-month > span.e-day,
      #{if(&, '&', '*')} td.e-other-year > span.e-day {
        display: $calendar-other-month-display-style;
        font-weight: $calendar-link-font-weight-style;
      }
      #{if(&, '&', '*')} tr.e-month-hide {
        display: $calendar-other-month-row-display-style;
        font-weight: $calendar-link-font-weight-style;
      }
      #{if(&, '&', '*')} tr.e-month-hide,
      #{if(&, '&', '*')} td.e-other-month,
      #{if(&, '&', '*')} td.e-other-year {
        pointer-events: $calendar-pointer-events;
        touch-action: $calendar-pointer-events;
      }
      #{if(&, '&', '*')} tr.e-month-hide,
      #{if(&, '&', '*')} td.e-other-month.e-disabled,
      #{if(&, '&', '*')} td.e-other-year.e-disabled {
        pointer-events: $calendar-none-style;
        touch-action: $calendar-none-style;
      }
      #{if(&, '&', '*')} td.e-week-number:hover span.e-day,
      #{if(&, '&', '*')} td.e-week-number:hover {
        @if ($skin-name != 'Material3' and $skin-name != 'fluent2') {
          background-color: $calendar-bg-color;
        }
        @if $skin-name == 'Material3' or $skin-name == 'fluent2' {
          background: $calendar-bg-color;
        }
        cursor: $calendar-cursor-default-style;
        @if $skin-name == 'FluentUI' {
          background-color: $calendar-week-number-bg-color;
        }
      }
    }
    #{if(&, '&', '*')} .e-header {
      #{if(&, '&', '*')} .e-prev,
      #{if(&, '&', '*')} .e-next {
        border-radius: $calendar-border-radius;
        display: inline-block;
        font-size: $calendar-next-prev-icon-size;
        vertical-align: middle;
      }
      #{if(&, '&', '*')} .e-title {
        cursor: $calendar-cursor-pointer-style;
        display: $calendar-inline-block-style;
        float: $calendar-float-left-style;
        font-size: $calendar-title-font-size;
        font-weight: $calendar-title-font-weight-style;
        text-align: $calendar-float-left-style;
        padding: $calendar-header-title-padding;
        border: $calendar-none-style;
        border-radius: $calendar-title-border-radius;
      }
      #{if(&, '&', '*')} .e-title {
        margin-left: $calendar-title-margin-left-style;
      }
      #{if(&, '&', '*')} .e-prev:hover,
      #{if(&, '&', '*')} .e-next:hover {
        cursor: $calendar-cursor-pointer-style;
      }
      #{if(&, '&', '*')} .e-prev.e-overlay,
      #{if(&, '&', '*')} .e-next.e-overlay {
        background: $calendar-none-style;
      }
    }
    #{if(&, '&', '*')} .e-header.e-decade .e-title,
    #{if(&, '&', '*')} .e-header.e-year .e-title {
      margin-left: $calendar-decade-title-left-margin-style;
    }
    #{if(&, '&', '*')} .e-header.e-decade .e-title {
      cursor: $calendar-cursor-default-style;
    }
    #{if(&, '&', '*')} .e-header .e-icon-container {
      display: $calendar-inline-block-style;
      float: $calendar-float-right-style;
      padding-top: $calendar-icon-padding-top;
    }
    #{if(&, '&', '*')} .e-footer-container {
      text-transform: uppercase;
    }
  }

  .e-bigger.e-small #{&}.e-calendar {
    @if ($skin-name != 'Material3' and $skin-name != 'fluent2') {
      border-spacing: 0;
      max-width: $calendar-normal-max-width;
      min-width: $calendar-normal-min-width;
      padding: $calendar-popup-padding;
    }
    @else {
      border-spacing: 0;
      max-width: $calendar-normal-max-width;
      min-width: $calendar-normal-min-width;
      padding: $calendar-popup-padding;
      min-height: $calendar-normal-min-height;
    }

    #{if(&, '&', '*')}.e-calendar-day-header-lg {
      max-width: $calendar-lg-day-header-format-max-width;
      min-width: $calendar-lg-day-header-format-min-width;
    }
    #{if(&, '&', '*')}.e-week-number {
      min-width: $calendar-weeknumber-min-width;
    }

    #{if(&, '&', '*')}.e-week {
      max-width: $calendar-week-normal-max-width;
      min-width: $calendar-week-normal-min-width;
    }
    #{if(&, '&', '*')} .e-header .e-title {
      line-height: $calendar-normal-header-height;
    }
    #{if(&, '&', '*')}.e-rtl .e-header .e-title {
      text-align: $calendar-float-right-style;
      text-indent: $calendar-rtl-text-indent;
    }
    #{if(&, '&', '*')} .e-header {
      height: $calendar-normal-header-height;

      #{if(&, '&', '*')}.e-month {
        padding: $calendar-normal-header-padding;
      }

      #{if(&, '&', '*')}.e-year,
      #{if(&, '&', '*')}.e-decade {
        padding: $calendar-yeardecade-header-padding;
      }
    }
    #{if(&, '&', '*')} th {
      font-weight: $calendar-normal-day-header-font-weight;
      height: $calendar-normal-day-header-height;
      text-transform: $calendar-normal-day-header-text;
    }
    #{if(&, '&', '*')} .e-content {
      #{if(&, '&', '*')} .e-selected ,
      #{if(&, '&', '*')} .e-state-hover {
        border-radius: 0;
      }
      #{if(&, '&', '*')} span.e-day {
        border: $calendar-default-border-color;
        font-size: $calendar-date-font-size;
        font-weight: $calendar-link-font-weight-style;
        height: $calendar-normal-month-view-height;
        line-height: $calendar-normal-month-view-height;
        width: $calendar-normal-month-view-width;
      }
    }
    #{if(&, '&', '*')} .e-content.e-month td.e-today span.e-day {
      line-height: $calendar-normal-month-view-line-height;
    }
    #{if(&, '&', '*')} .e-content td.e-today span.e-day {
      @if $skin-name == 'FluentUI' {
        height: 25px;
        width: 25px;
        line-height: 25px;
      }
    }
    #{if(&, '&', '*')} .e-content.e-year table,
    #{if(&, '&', '*')} .e-content.e-decade table {
      border-spacing: 0;
      padding: $calendar-yeardecade-padding;
    }
    #{if(&, '&', '*')} .e-content.e-month td {
      height: $calendar-normal-month-view-height;
      padding: $calendar-normal-month-cell-padding;
    }
    #{if(&, '&', '*')} .e-content .tfooter > tr > td {
      height: $calendar-normal-today-button-height;
      line-height: $calendar-normal-today-button-height;
    }
    #{if(&, '&', '*')} .e-content.e-year td,
    #{if(&, '&', '*')} .e-content.e-decade td {
      @if ($skin-name != 'Material3' and $skin-name != 'fluent2') {
        height: $calendar-normal-year-decade-height;
        padding: $calendar-normal-year-decade-padding;
      }
      @else {
        height: $calendar-normal-year-decade-height;
        padding: $calendar-normal-year-decade-padding;
        width: $calendar-normal-year-decade-width;
      }
    }
    #{if(&, '&', '*')} .e-content.e-year td > span.e-day,
    #{if(&, '&', '*')} .e-content.e-decade td > span.e-day {
      @if ($skin-name != 'Material3' and $skin-name != 'fluent2') {
        font-weight: $calendar-yeardecade-font-weight;
        height: $calendar-normal-year-decade-height;
        line-height: $calendar-normal-year-decade-height;
        width: $calendar-normal-year-decade-width;
      }
      @else {
        font-weight: $calendar-yeardecade-font-weight;
        height: $calendar-normal-year-decade-height-inside;
        line-height: $calendar-normal-year-decade-height-inside;
        width: $calendar-normal-year-decade-width;
      }
    }
    #{if(&, '&', '*')} .e-content.e-year td.e-selected:not(.e-focused-date) > span.e-day,
    #{if(&, '&', '*')} .e-content.e-decade td.e-selected:not(.e-focused-date) > span.e-day,
    #{if(&, '&', '*')} .e-content.e-year td.e-selected:not(.e-focused-date):hover > span.e-day,
    #{if(&, '&', '*')} .e-content.e-decade td.e-selected:not(.e-focused-date):hover > span.e-day {
      @if ($skin-name == 'FluentUI') {
        box-shadow: $calendar-none-style;
      }
    }
    #{if(&, '&', '*')} .e-header .e-icon-container {
      #{if(&, '&', '*')} .e-prev,
      #{if(&, '&', '*')} .e-next {
        height: $calendar-normal-icon-size;
        width: $calendar-normal-icon-size;
      }

      #{if(&, '&', '*')} .e-prev {
        @if $skin-name == 'tailwind' {
          margin: $calendar-normal-down-icon-margin;
        }
      }
    }
    #{if(&, '&', '*')} .e-footer-container {
      @if ($skin-name != 'Material3' and $skin-name != 'fluent2') {
        background-color: $calendar-footer-background;
      }
      @if $skin-name == 'Material3' or $skin-name == 'fluent2' {
        background: $calendar-footer-background;
      }
      border-top: $calendar-footer-border;
      cursor: $calendar-cursor-default-style;
      display: $calendar-display-style;
      flex-direction: row;
      justify-content: flex-end;
      padding: $calendar-footer-container-padding;
      text-align: center;
      width: $calendar-full-width;
    }
    #{if(&, '&', '*')}.e-rtl .e-header .e-title {
      @if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' {
        text-indent: $calendar-rtl-bigger-small-text-indent;
      }
    }
  }

  .e-small.e-bigger#{&}.e-calendar,
  .e-small.e-bigger #{&}.e-calendar {
    @if $skin-name == 'tailwind' {
      max-width: $calendar-bigger-small-max-width;
      min-width: $calendar-bigger-small-min-width;
    }

    #{if(&, '&', '*')} .e-footer-container {
      @if $skin-name == 'tailwind' {
        padding: $calendar-bigger-small-footer-container-padding;
      }
    }

    #{if(&, '&', '*')} .e-content.e-year {
      #{if(&, '&', '*')} span.e-day {
        font-size: $calendar-bigger-small-yeardecade-font-size;
      }
    }

    #{if(&, '&', '*')} .e-header {
      #{if(&, '&', '*')}.e-month,
      #{if(&, '&', '*')}.e-year,
      #{if(&, '&', '*')}.e-decade {
        @if $skin-name == 'tailwind' {
          padding: $calendar-bigger-small-header-padding;
        }
      }

      #{if(&, '&', '*')} .e-icon-container .e-prev {
        @if $skin-name == 'tailwind' {
          margin: $calendar-bigger-small-down-icon-margin;
        }
      }
    }

    #{if(&, '&', '*')} .e-content.e-month table {
      padding: $calendar-bigger-small-table-padding;
    }

    #{if(&, '&', '*')} .e-content.e-year table,
    #{if(&, '&', '*')} .e-content.e-decade table {
      @if $skin-name == 'tailwind' {
        padding: $calendar-bigger-small-table-padding;
      }
    }

    #{if(&, '&', '*')} th {
      @if $skin-name == 'tailwind' {
        height: $calendar-bigger-small-day-header-height;
      }
    }

    #{if(&, '&', '*')} .e-content {
      #{if(&, '&', '*')} span.e-day {
        @if $skin-name == 'tailwind' {
          height: $calendar-bigger-small-month-view-height;
          line-height: $calendar-bigger-small-month-view-height;
          width: $calendar-bigger-small-month-view-width;
        }
      }

      #{if(&, '&', '*')}.e-month td.e-today span.e-day {
        @if $skin-name == 'tailwind' {
          line-height: $calendar-bigger-small-month-view-line-height;
        }
      }
      #{if(&, '&', '*')}.e-month td {
        @if $skin-name == 'tailwind' {
          height: $calendar-bigger-small-month-view-height;
        }
      }
    }

    #{if(&, '&', '*')} .e-content span.e-day {
      @if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' {
        height: $calendar-day-bigger-small-cell-size;
        width: $calendar-day-bigger-small-cell-size;
      }
    }
    #{if(&, '&', '*')} .e-header .e-title {
      @if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' {
        margin-left: $calendar-title-margin-bigger-small-left-style;
      }
    }
  }

  .e-bigger.e-small .e-calendar .e-content td.e-today:hover {
    @if $skin-name == 'fluent2' {
      background: $calendar-hover-border-color;
      border-radius: 2px;
    }
  }
}
