/* Complete Syncfusion Theme Overrides */
@use 'es-input';
@use 'es-button';
@use 'es-tab.scss';
@use 'es-ddl.scss';
@use 'es-tabs.scss';
@use 'es-uploader.scss';
@use 'es-toolbar.scss';
@use 'es-pdfviewer.scss';
@use 'es-dialog.scss';
@use 'es-grid.scss';
@use 'es-list.scss';
@use 'es-accordion.scss';
@use 'es-checkbox.scss';
@use 'es-multiselect.scss';
