@include export-module('input-bigger') {

  .e-input-group.e-bigger .e-input,
  .e-input-group.e-bigger.e-control-wrapper .e-input,
  .e-bigger .e-input-group .e-input,
  .e-bigger .e-input-group.e-control-wrapper .e-input {
    font: inherit;
  }

  .e-input.e-bigger#{$css}:focus,
  .e-bigger .e-input#{$css}:focus {
    padding-bottom: $input-bigger-padding-bottom;
  }

  .e-input.e-bigger#{$css}:focus,
  .e-bigger .e-input#{$css}:focus {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      padding-bottom: $input-focus-bigger-padding-bottom;
    }
  }

  input.e-input.e-bigger#{$css},
  textarea.e-input.e-bigger#{$css},
  .e-bigger input.e-input#{$css},
  .e-bigger textarea.e-input#{$css},
  .e-input-group.e-bigger,
  .e-bigger .e-input-group,
  .e-input-group.e-control-wrapper.e-bigger,
  .e-bigger .e-input-group.e-control-wrapper {
    @if ($input-skin-name == 'tailwind' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3') {
      border-radius: $input-bigger-border-radius;
    }
    font-size: $input-bigger-font-size;
  }

  .e-input-group.e-bigger .e-input,
  .e-input-group.e-bigger input,
  .e-input-group.e-control-wrapper.e-bigger .e-input,
  .e-input-group.e-control-wrapper.e-bigger input,
  .e-float-input.e-bigger input,
  .e-float-input.e-input-group.e-bigger input,
  .e-float-input.e-control-wrapper.e-bigger input,
  .e-float-input.e-control-wrapper.e-input-group.e-bigger input,
  .e-float-input.e-bigger,
  .e-float-input.e-control-wrapper.e-bigger,
  .e-bigger .e-input-group .e-input,
  .e-bigger .e-input-group input,
  .e-bigger .e-input-group.e-control-wrapper .e-input,
  .e-bigger .e-input-group.e-control-wrapper input,
  .e-bigger .e-float-input input,
  .e-bigger .e-float-input.e-input-group input,
  .e-bigger .e-float-input.e-control-wrapper input,
  .e-bigger .e-float-input.e-control-wrapper.e-input-group input,
  .e-bigger .e-float-input,
  .e-bigger .e-float-input.e-control-wrapper {
    @if ($input-skin-name == 'tailwind' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3') {
      border-radius: $input-bigger-border-radius;
    }
  }

  
  input.e-input.e-bigger#{$css},
  textarea.e-input.e-bigger#{$css},
  .e-input-group.e-bigger .e-input,
  .e-input-group.e-bigger.e-control-wrapper .e-input,
  .e-bigger input.e-input#{$css},
  .e-bigger textarea.e-input#{$css},
  .e-bigger .e-input-group .e-input,
  .e-bigger .e-input-group.e-control-wrapper .e-input {
    line-height: inherit;
    margin-bottom: $input-bigger-margin-bottom;
    padding: $input-bigger-padding;
  }

  .e-input-group.e-bigger .e-input:focus,
  .e-bigger .e-input-group .e-input:focus,
  .e-input-group.e-control-wrapper.e-bigger .e-input:focus,
  .e-bigger .e-input-group.e-control-wrapper .e-input:focus,
  .e-input-group.e-bigger.e-input-focus .e-input,
  .e-bigger .e-input-group.e-input-focus .e-input,
  .e-input-group.e-control-wrapper.e-bigger.e-input-focus .e-input,
  .e-bigger .e-input-group.e-control-wrapper.e-input-focus .e-input {
    padding: $input-bigger-padding;
  }
  /* stylelint-disable property-no-vendor-prefix */
  .e-input-group.e-bigger.e-float-icon-left:not(.e-disabled) .e-input-group-icon:first-child:hover,
  .e-input-group.e-control-wrapper.e-bigger.e-float-icon-left:not(.e-disabled) .e-input-group-icon:first-child:hover {
    @if $input-skin-name == 'highcontrast' {
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      border-width: $zero-value + px 1px $zero-value + px 1px;
      box-sizing: border-box;
    }
  }

  .e-input-group.e-bigger .e-input-group-icon,
  .e-input-group .e-input-group-icon.e-bigger,
  .e-bigger .e-input-group .e-input-group-icon,
  .e-input-group.e-control-wrapper.e-bigger .e-input-group-icon,
  .e-input-group.e-control-wrapper .e-input-group-icon.e-bigger,
  .e-bigger .e-input-group.e-control-wrapper .e-input-group-icon {
    @if $skin-name != 'fluent2' {
      min-height: $input-bigger-child-min-height;
      min-width: $input-bigger-child-min-width;
    }
    @if $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      border-radius: $input-bigger-icon-border-radius;
    }
  }

  .e-input-group.e-bigger .e-input-group-icon,
  .e-input-group .e-input-group-icon.e-bigger,
  .e-bigger .e-input-group .e-input-group-icon,
  .e-input-group.e-control-wrapper.e-bigger .e-input-group-icon,
  .e-input-group.e-control-wrapper .e-input-group-icon.e-bigger,
  .e-bigger .e-input-group.e-control-wrapper .e-input-group-icon {
    font-size: $input-bigger-icon-font-size;
    padding: $input-bigger-child-padding;
  }

  .e-input-group.e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-input-group.e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-input-group.e-float-icon-left > .e-input-group-icon,
  .e-float-input.e-input-group.e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-float-input.e-input-group.e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-float-input.e-input-group.e-float-icon-left > .e-input-group-icon,
  .e-input-group.e-control-wrapper.e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-input-group.e-control-wrapper.e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-input-group.e-control-wrapper.e-float-icon-left > .e-input-group-icon,
  .e-float-input.e-input-group.e-control-wrapper.e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-float-input.e-input-group.e-control-wrapper.e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-float-input.e-input-group.e-control-wrapper.e-float-icon-left > .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      font-size: $input-bigger-left-icon-font-size;
      margin: $zero-value;
      min-height: $input-bigger-left-child-min-height;
      min-width: $input-bigger-left-child-min-width;
      padding: $zero-value;
    }
  }

  .e-input.e-small.e-bigger#{$css}:focus,
  .e-bigger .e-input.e-small#{$css}:focus {
    padding-bottom: $input-bigger-small-padding-bottom;
  }

  .e-input.e-small.e-bigger#{$css}:focus,
  .e-bigger .e-input.e-small#{$css}:focus {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      padding-bottom: $input-focus-bigger-small-padding-bottom;
    }
  }

  input.e-input.e-bigger.e-small#{$css},
  textarea.e-input.e-bigger.e-small#{$css},
  .e-bigger.e-small input.e-input#{$css},
  .e-bigger.e-small textarea.e-input#{$css},
  .e-input-group.e-bigger.e-small,
  .e-bigger.e-small .e-input-group,
  .e-bigger.e-small .e-input-group.e-control-wrapper {
    @if ($input-skin-name == 'tailwind' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3') {
      border-radius: $input-bigger-small-border-radius;
    }
  }

  .e-input-group.e-bigger.e-small .e-input,
  .e-input-group.e-bigger.e-small input,
  .e-input-group.e-control-wrapper.e-bigger.e-small .e-input,
  .e-input-group.e-control-wrapper.e-bigger.e-small input,
  .e-float-input.e-bigger.e-small input,
  .e-float-input.e-input-group.e-bigger.e-small input,
  .e-float-input.e-control-wrapper.e-bigger.e-small input,
  .e-float-input.e-control-wrapper.e-input-group.e-bigger.e-small input,
  .e-float-input.e-bigger.e-small,
  .e-float-input.e-control-wrapper.e-bigger.e-small,
  .e-bigger.e-small .e-input-group .e-input,
  .e-bigger.e-small .e-input-group input,
  .e-bigger.e-small .e-input-group.e-control-wrapper .e-input,
  .e-bigger.e-small .e-input-group.e-control-wrapper input,
  .e-bigger.e-small .e-float-input input,
  .e-bigger.e-small .e-float-input.e-input-group input,
  .e-bigger.e-small .e-float-input.e-control-wrapper input,
  .e-bigger.e-small .e-float-input.e-control-wrapper.e-input-group input,
  .e-bigger.e-small .e-float-input,
  .e-bigger.e-small .e-float-input.e-control-wrapper {
    @if ($input-skin-name == 'tailwind' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3') {
      border-radius: $input-bigger-small-border-radius;
    }
  }

  .e-input-group.e-small.e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-input-group.e-small.e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-input-group.e-small.e-float-icon-left > .e-input-group-icon,
  .e-float-input.e-input-group.e-small.e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-float-input.e-input-group.e-small.e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-input-group.e-float-input.e-small.e-float-icon-left > .e-input-group-icon,
  .e-input-group.e-control-wrapper.e-small.e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-input-group.e-control-wrapper.e-small.e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-input-group.e-control-wrapper.e-small.e-float-icon-left > .e-input-group-icon,
  .e-float-input.e-control-wrapper.e-input-group.e-small.e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-float-input.e-control-wrapper.e-input-group.e-small.e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-input-group.e-control-wrapper.e-float-input.e-small.e-float-icon-left > .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      font-size: $input-bigger-small-left-icon-font-size;
      margin: $zero-value;
      min-height: $input-bigger-small-left-child-min-height;
      min-width: $input-bigger-small-left-child-min-width;
      padding: $zero-value;
    }
  }

  .e-input.e-small.e-bigger#{$css},
  .e-input-group.e-small.e-bigger,
  .e-small.e-bigger .e-input-group,
  .e-input-group.e-control-wrapper.e-small.e-bigger,
  .e-small.e-bigger .e-input-group.e-control-wrapper,
  .e-bigger .e-input#{$css}.e-small,
  .e-small .e-input#{$css}.e-bigger,
  .e-bigger .e-input-group.e-small,
  .e-bigger .e-input-group.e-control-wrapper.e-small,
  .e-small .e-input-group.e-bigger,
  .e-small .e-input-group.e-control-wrapper.e-bigger,
  .e-input-group.e-small.e-bigger.e-disabled,
  .e-small.e-bigger .e-input-group.e-disabled,
  .e-input-group.e-control-wrapper.e-small.e-bigger.e-disabled,
  .e-small.e-bigger .e-input-group.e-control-wrapper.e-disabled,
  .e-bigger .e-input#{$css}.e-small.e-disabled,
  .e-small .e-input#{$css}.e-bigger.e-disabled,
  .e-bigger .e-input-group.e-small.e-disabled,
  .e-bigger .e-input-group.e-control-wrapper.e-small.e-disabled,
  .e-small .e-input-group.e-bigger.e-disabled,
  .e-small .e-input-group.e-control-wrapper.e-bigger.e-disabled {
    font-size: $input-bigger-small-font-size;
  }

  .e-input-group.e-small.e-bigger .e-input,
  .e-input-group.e-small .e-input.e-bigger,
  .e-input-group.e-control-wrapper.e-small.e-bigger .e-input,
  .e-input-group.e-control-wrapper.e-small .e-input.e-bigger,
  .e-bigger .e-input-group.e-small .e-input,
  .e-bigger .e-input-group.e-control-wrapper.e-small .e-input {
    font: inherit;
    padding: $input-bigger-small-padding;
  }

  .e-input-group.e-small.e-bigger .e-input:focus,
  .e-input-group.e-small .e-input.e-bigger:focus,
  .e-bigger .e-input-group.e-small .e-input:focus,
  .e-input-group.e-control-wrapper.e-small.e-bigger .e-input:focus,
  .e-input-group.e-control-wrapper.e-small .e-input.e-bigger:focus,
  .e-bigger .e-input-group.e-control-wrapper.e-small .e-input:focus,
  .e-input-group.e-small.e-bigger.e-input-focus .e-input,
  .e-input-group.e-small.e-input-focus .e-input.e-bigger,
  .e-bigger .e-input-group.e-small.e-input-focus .e-input,
  .e-input-group.e-control-wrapper.e-small.e-bigger.e-input-focus .e-input,
  .e-input-group.e-control-wrapper.e-small.e-input-focus .e-input.e-bigger,
  .e-bigger .e-input-group.e-control-wrapper.e-small.e-input-focus .e-input,
  .e-input.e-small.e-bigger#{$css},
  .e-bigger .e-input#{$css}.e-small,
  .e-small .e-input#{$css}.e-bigger {
    padding: $input-bigger-small-padding;
  }

  .e-input-group.e-small.e-bigger .e-input-group-icon,
  .e-input-group.e-small .e-input-group-icon.e-bigger,
  .e-input-group.e-control-wrapper.e-small.e-bigger .e-input-group-icon,
  .e-input-group.e-control-wrapper.e-small .e-input-group-icon.e-bigger,
  .e-small.e-bigger .e-input-group .e-input-group-icon,
  .e-small.e-bigger .e-input-group.e-control-wrapper .e-input-group-icon,
  .e-bigger .e-input-group.e-small .e-input-group-icon,
  .e-bigger .e-input-group.e-control-wrapper.e-small .e-input-group-icon {
    font-size: $input-bigger-small-icon-font-size;
    min-height: $input-bigger-small-child-min-height;
    min-width: $input-bigger-small-child-min-width;
    padding: $input-bigger-small-child-padding;
    @if $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      border-radius: $input-bigger-small-child-border-radius;
      margin: $input-bigger-small-child-margin;
    }
  }

  .e-float-input.e-bigger label.e-float-text,
  .e-float-input label.e-float-text.e-bigger,
  .e-float-input input.e-bigger ~ label.e-float-text,
  .e-bigger .e-float-input label.e-float-text,
  .e-float-input.e-bigger:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-bigger label.e-float-text,
  .e-float-input.e-control-wrapper label.e-float-text.e-bigger,
  .e-float-input.e-control-wrapper input.e-bigger ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    font-size: $float-placeholder-bigger-font-size;
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      top: -8px;
    }
  }

  .e-float-input.e-bigger:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input.e-bigger:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-bigger.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-bigger.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input.e-control-wrapper.e-bigger:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-bigger.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-bigger.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom  {
    @include float-label-alignment;
    font-style: $input-font-style;
  }

  .e-float-input.e-small.e-bigger label.e-float-text,
  .e-bigger .e-float-input.e-small label.e-float-text,
  .e-bigger .e-float-input.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input.e-bigger:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-bigger.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-bigger.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-small.e-bigger label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input.e-control-wrapper.e-bigger:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-bigger.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-bigger.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    font-size: $float-placeholder-bigger-small-font-size;
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      top: -9px;
    }
    font-style: $input-font-style;
  }

  .e-float-input.e-bigger .e-input-in-wrap label.e-float-text,
  .e-float-input .e-input-in-wrap label.e-float-text.e-bigger,
  .e-float-input .e-input-in-wrap input.e-bigger ~ label.e-float-text,
  .e-bigger .e-float-input .e-input-in-wrap label.e-float-text,
  .e-float-input.e-bigger:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-bigger .e-input-in-wrap label.e-float-text,
  .e-float-input.e-control-wrapper .e-input-in-wrap label.e-float-text.e-bigger,
  .e-float-input.e-control-wrapper .e-input-in-wrap input.e-bigger ~ label.e-float-text,
  .e-bigger.e-float-input.e-control-wrapper .e-input-in-wrap label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-float-input.e-control-wrapper:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom  {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      top: -8px;
    }
  }

  .e-float-input.e-bigger.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-float-input.e-input-focus input ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-input-focus input ~ label.e-float-text {
    font-size: $float-label-font-size;
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      top: -9px;
      transform: translate3d(0, -6px, 0) scale(.92);
    }
    @else if $input-skin-name == 'fabric' or $input-skin-name == 'highcontrast' {
      font-style: $input-font-normal;
      padding-right: 0;
      transform: translate3d(-10px, -43px, 0) scale(1);
    }
    @else if ($input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4') {
      padding-right: 0;
      transform: translate3d(-10px, -39px, 0) scale(1);
    }
    @else if ($input-skin-name == 'tailwind') {
      font-weight: 500;
      padding-right: 0;
      transform: translate3d(-10px, -34px, 0) scale(1);
    }
    @else if ($input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3') {
      font-weight: 500;
      padding-right: 0;
      transform: translate3d(-10px, -35px, 0) scale(1);
    }
    @else if ($input-skin-name == 'FluentUI') {
      font-style: $input-font-normal;
      font-weight: 600;
      padding-right: 0;
      transform: translate3d(-10px, -38px, 0) scale(1);
    }
  }

  .e-float-input.e-bigger input:focus ~ label.e-float-text,
  .e-float-input.e-bigger input:valid ~ label.e-float-text,
  .e-float-input.e-bigger input ~ label.e-label-top.e-float-text,
  .e-float-input.e-bigger input[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-bigger input[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-bigger input label.e-float-text.e-label-top,
  .e-bigger .e-float-input input:focus ~ label.e-float-text,
  .e-bigger .e-float-input input:valid ~ label.e-float-text,
  .e-bigger .e-float-input input ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input input label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper.e-bigger input:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger input:valid ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger input ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger input[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger input[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger input label.e-float-text.e-label-top,
  .e-bigger .e-float-input.e-control-wrapper input:focus ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper input:valid ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper input ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper input label.e-float-text.e-label-top,
  .e-float-input.e-bigger.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-float-input.e-input-focus input ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-input-focus input ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      transform: translate3d(0, -6px, 0) scale(.86);
    }
    @else if $input-skin-name == 'fabric' or $input-skin-name == 'highcontrast' {
      font-style: $input-font-normal;
      padding-right: 0;
      transform: translate3d(-10px, -43px, 0) scale(1);
    }
    @else if $input-skin-name == 'bootstrap' {
      padding-right: 0;
      transform: translate3d(-10px, -39px, 0) scale(1);
    }
    @else if ($input-skin-name == 'bootstrap4') {
      padding-right: 0;
      transform: translate3d(-10px, -42px, 0) scale(1);
    }
    @else if ($input-skin-name == 'tailwind') {
      font-weight: 500;
      padding-right: 0;
      transform: translate3d(-10px, -37px, 0) scale(1);
    }
    @else if ($input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3') {
      font-weight: 500;
      padding-right: 0;
      transform: translate3d(-10px, -39px, 0) scale(1);
    }
    @else if ($input-skin-name == 'FluentUI') {
      font-style: $input-font-normal;
      font-weight: 600;
      padding-right: 0;
      transform: translate3d(-10px, -45px, 0) scale(1);
    }
  }

  .e-float-input.e-bigger.e-small input:focus ~ label.e-float-text,
  .e-float-input.e-bigger.e-small input:valid ~ label.e-float-text,
  .e-float-input.e-bigger.e-small input ~ label.e-label-top.e-float-text,
  .e-float-input.e-bigger.e-small input[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-bigger.e-small input[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-bigger.e-small input label.e-float-text.e-label-top,
  .e-bigger .e-float-input.e-small input:focus ~ label.e-float-text,
  .e-bigger .e-float-input.e-small input:valid ~ label.e-float-text,
  .e-bigger .e-float-input.e-small input ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-small input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-small input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-small input label.e-float-text.e-label-top,
  .e-small .e-float-input.e-bigger input:focus ~ label.e-float-text,
  .e-small .e-float-input.e-bigger input:valid ~ label.e-float-text,
  .e-small .e-float-input.e-bigger input ~ label.e-label-top.e-float-text,
  .e-small .e-float-input.e-bigger input[readonly] ~ label.e-label-top.e-float-text,
  .e-small .e-float-input.e-bigger input[disabled] ~ label.e-label-top.e-float-text,
  .e-small .e-float-input.e-bigger input label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper.e-bigger.e-small input:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger.e-small input:valid ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger.e-small input ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger.e-small input[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger.e-small input[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger.e-small input label.e-float-text.e-label-top,
  .e-bigger .e-float-input.e-control-wrapper.e-small input:focus ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small input:valid ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small input ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small input label.e-float-text.e-label-top,
  .e-small .e-float-input.e-control-wrapper.e-bigger input:focus ~ label.e-float-text,
  .e-small .e-float-input.e-control-wrapper.e-bigger input:valid ~ label.e-float-text,
  .e-small .e-float-input.e-control-wrapper.e-bigger input ~ label.e-label-top.e-float-text,
  .e-small .e-float-input.e-control-wrapper.e-bigger input[readonly] ~ label.e-label-top.e-float-text,
  .e-small .e-float-input.e-control-wrapper.e-bigger input[disabled] ~ label.e-label-top.e-float-text,
  .e-small .e-float-input.e-control-wrapper.e-bigger input label.e-float-text.e-label-top,
  .e-float-input.e-bigger.e-small.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-float-input.e-small.e-input-focus input-group-animation ~ label.e-float-text,
  .e-small .e-float-input.e-bigger.e-input-focus input ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger.e-small.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small.e-input-focus input ~ label.e-float-text,
  .e-small .e-float-input.e-control-wrapper.e-bigger.e-input-focus input ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      transform: translate3d(0, -6px, 0) scale(.92);
    }
    @else if $input-skin-name == 'fabric' or $input-skin-name == 'highcontrast' {
      font-style: $input-font-normal;
      padding-right: 0;
      transform: translate3d(-10px, -43px, 0) scale(1);
    }
    @else if $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' {
      padding-right: 0;
      transform: translate3d(-10px, -39px, 0) scale(1);
    }
    @else if ($input-skin-name == 'tailwind') {
      font-weight: 500;
      padding-right: 0;
      transform: translate3d(-10px, -34px, 0) scale(1);
    }
    @else if ($input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3') {
      font-weight: 500;
      padding-right: 0;
      transform: translate3d(-10px, -36px, 0) scale(1);
    }
    @else if ($input-skin-name == 'FluentUI') {
      font-style: $input-font-normal;
      font-weight: 600;
      padding-right: 0;
      transform: translate3d(-10px, -41px, 0) scale(1);
    }
  }

  .e-float-input.e-bigger input:focus ~ label.e-float-text,
  .e-float-input.e-bigger input:valid ~ label.e-float-text,
  .e-float-input.e-bigger input ~ label.e-label-top.e-float-text,
  .e-float-input.e-bigger input[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-bigger input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input input:focus ~ label.e-float-text,
  .e-bigger .e-float-input input:valid ~ label.e-float-text,
  .e-bigger .e-float-input input ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input input[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger input:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger input:valid ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger input ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger input[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper input:focus ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper input:valid ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper input ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper input[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-bigger.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-float-input.e-input-focus input ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-input-focus input ~ label.e-float-text {
    font-size: $float-label-bigger-font-size;
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      top: -9px;
    }
    @if $input-skin-name == 'fluent2' {
      top: -14px;
    }
    @else if $input-skin-name == 'fabric' or $input-skin-name == 'FluentUI' or $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' or $input-skin-name == 'highcontrast' or $input-skin-name == 'tailwind' {
      padding-right: 0;
      top: 14px;
    }
  }

  .e-float-input.e-bigger .e-input-in-wrap input:focus ~ label.e-float-text,
  .e-float-input.e-bigger .e-input-in-wrap input:valid ~ label.e-float-text,
  .e-float-input.e-bigger .e-input-in-wrap input ~ label.e-label-top.e-float-text,
  .e-float-input.e-bigger .e-input-in-wrap input[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-bigger .e-input-in-wrap input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input .e-input-in-wrap input:focus ~ label.e-float-text,
  .e-bigger .e-float-input .e-input-in-wrap input:valid ~ label.e-float-text,
  .e-bigger .e-float-input .e-input-in-wrap input ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input .e-input-in-wrap input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input .e-input-in-wrap input[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger .e-input-in-wrap input:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger .e-input-in-wrap input:valid ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger .e-input-in-wrap input ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger .e-input-in-wrap input[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger .e-input-in-wrap input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper .e-input-in-wrap input:focus ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper .e-input-in-wrap input:valid ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper .e-input-in-wrap input ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper .e-input-in-wrap input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper .e-input-in-wrap input[disabled] ~ label.e-label-top.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      top: -7px;
    }
    @if $input-skin-name == 'fluent2' {
      top: -14px;
    }
  }

  .e-float-input.e-small.e-bigger input:focus ~ label.e-float-text,
  .e-float-input.e-small.e-bigger input:valid ~ label.e-float-text,
  .e-float-input.e-small.e-bigger input ~ label.e-label-top.e-float-text,
  .e-float-input.e-small.e-bigger input[readonly] ~ label.e-float-text,
  .e-float-input.e-small.e-bigger input[disabled] ~ label.e-float-text,
  .e-bigger .e-float-input.e-small input:focus ~ label.e-float-text,
  .e-bigger .e-float-input.e-small input:valid ~ label.e-float-text,
  .e-bigger .e-float-input.e-small input ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-small input[readonly] ~ label.e-float-text,
  .e-bigger .e-float-input.e-small input[disabled] ~ label.e-float-text,
  .e-bigger.e-small .e-float-input.e-control-wrapper input:focus ~ label.e-float-text,
  .e-bigger.e-small .e-float-input.e-control-wrapper input:valid ~ label.e-float-text,
  .e-bigger.e-small .e-float-input.e-control-wrapper input ~ label.e-label-top.e-float-text,
  .e-bigger.e-small .e-float-input.e-control-wrapper input[readonly] ~ label.e-float-text,
  .e-bigger.e-small .e-float-input.e-control-wrapper input[disabled] ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger input:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger input:valid ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger input ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger input[readonly] ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger input[disabled] ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small input:focus ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small input:valid ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small input ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small input[readonly] ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small input[disabled] ~ label.e-float-text,
  .e-float-input.e-small.e-bigger.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-float-input.e-small.e-input-focus input ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small.e-input-focus input ~ label.e-float-text {
    font-size: $float-label-bigger-small-font-size;
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      top: -7px;
    }
    @else if $input-skin-name == 'fluent2' {
      top: -10px;
    }
    @else if $input-skin-name == 'fabric' or $input-skin-name == 'FluentUI' or $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' or  $input-skin-name == 'highcontrast' or $input-skin-name == 'tailwind' {
      padding-right: 0;
      top: 16px;
    }
  }

  .e-float-input.e-small.e-bigger .e-input-in-wrap input:focus ~ label.e-float-text,
  .e-float-input.e-small.e-bigger .e-input-in-wrap input:valid ~ label.e-float-text,
  .e-float-input.e-small.e-bigger .e-input-in-wrap input ~ label.e-label-top.e-float-text,
  .e-float-input.e-small.e-bigger .e-input-in-wrap input[readonly] ~ label.e-float-text,
  .e-float-input.e-small.e-bigger .e-input-in-wrap input[disabled] ~ label.e-float-text,
  .e-bigger .e-float-input.e-small .e-input-in-wrap input:focus ~ label.e-float-text,
  .e-bigger .e-float-input.e-small .e-input-in-wrap input:valid ~ label.e-float-text,
  .e-bigger .e-float-input.e-small .e-input-in-wrap input ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-small .e-input-in-wrap input[readonly] ~ label.e-float-text,
  .e-bigger .e-float-input.e-small .e-input-in-wrap input[disabled] ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger .e-input-in-wrap input:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger .e-input-in-wrap input:valid ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger .e-input-in-wrap input ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger .e-input-in-wrap input[readonly] ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger .e-input-in-wrap input[disabled] ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small .e-input-in-wrap input:focus ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small .e-input-in-wrap input:valid ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small .e-input-in-wrap input ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small .e-input-in-wrap input[readonly] ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small .e-input-in-wrap input[disabled] ~ label.e-float-text,
  .e-float-input.e-small .e-input-in-wrap label.e-float-text,
  .e-small .e-float-input .e-input-in-wrap label.e-float-text,
  .e-float-input.e-small:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-small .e-input-in-wrap label.e-float-text,
  .e-small .e-float-input.e-control-wrapper .e-input-in-wrap label.e-float-text,
  .e-float-input.e-control-wrapper.e-small:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input.e-control-wrapper:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-small.e-bigger .e-input-in-wrap label.e-float-text,
  .e-bigger .e-float-input.e-small .e-input-in-wrap label.e-float-text,
  .e-bigger .e-float-input.e-small:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input.e-bigger:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-bigger.e-small:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-float-input:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-small.e-bigger .e-input-in-wrap label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small .e-input-in-wrap label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input.e-control-wrapper.e-bigger:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-bigger.e-small:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-float-input.e-control-wrapper:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      top: -10px;
    }
  }

  .e-float-input.e-bigger,
  .e-bigger .e-float-input,
  .e-float-input.e-control-wrapper.e-bigger,
  .e-bigger .e-float-input.e-control-wrapper {
    line-height: 1.5;
    margin-bottom: $input-bigger-margin-bottom;
    margin-top: $input-bigger-margin-top;
    padding-top: $float-input-bigger-wrap-padding-top;
    position: relative;
    width: 100%;
  }

  .e-float-input.e-small.e-bigger,
  .e-bigger.e-small .e-float-input.e-small,
  .e-bigger .e-float-input.e-small,
  .e-small .e-float-input.e-bigger,
  .e-float-input.e-control-wrapper.e-small.e-bigger,
  .e-bigger.e-small .e-float-input.e-control-wrapper.e-small,
  .e-bigger .e-float-input.e-control-wrapper.e-small,
  .e-small .e-float-input.e-control-wrapper.e-bigger {
    line-height: 1.4;
    margin-bottom: $input-small-bigger-margin-bottom;
    margin-top: $input-small-bigger-margin-top;
    padding-top: $float-input-bigger-small-wrap-padding-top;
  }

  .e-input-group.e-bigger,
  .e-bigger .e-input-group,
  .e-input-group.e-control-wrapper.e-bigger,
  .e-bigger .e-input-group.e-control-wrapper {
    line-height: 1.5;
  }

  .e-input-group.e-small.e-bigger,
  .e-bigger.e-small .e-input-group.e-small,
  .e-bigger .e-input-group.e-small,
  .e-small .e-input-group.e-bigger,
  .e-input-group.e-control-wrapper.e-small.e-bigger,
  .e-bigger.e-small .e-input-group.e-control-wrapper.e-small,
  .e-bigger .e-input-group.e-control-wrapper.e-small,
  .e-small .e-input-group.e-control-wrapper.e-bigger {
    line-height: normal;
  }

  .e-float-input.e-bigger.e-no-float-label,
  .e-bigger .e-float-input.e-no-float-label,
  .e-float-input.e-small.e-bigger.e-no-float-label,
  .e-bigger .e-float-input.e-small.e-no-float-label,
  .e-small .e-float-input.e-bigger.e-no-float-label,
  .e-float-input.e-control-wrapper.e-bigger.e-no-float-label,
  .e-bigger .e-float-input.e-control-wrapper.e-no-float-label,
  .e-float-input.e-control-wrapper.e-small.e-bigger.e-no-float-label,
  .e-bigger .e-float-input.e-control-wrapper.e-small.e-no-float-label,
  .e-small .e-float-input.e-control-wrapper.e-bigger.e-no-float-label {
    margin-top: $zero-value;
  }

  .e-float-input.e-bigger.e-disabled,
  .e-bigger .e-float-input.e-disabled,
  .e-float-input.e-control-wrapper.e-bigger.e-disabled,
  .e-bigger .e-float-input.e-control-wrapper.e-disabled,
  .e-float-input.e-input-group.e-bigger.e-disabled,
  .e-bigger .e-float-input.e-input-group.e-disabled,
  .e-float-input.e-input-group.e-control-wrapper.e-bigger.e-disabled,
  .e-bigger .e-float-input.e-input-group.e-control-wrapper.e-disabled,
  .e-float-input.e-bigger,
  .e-bigger .e-float-input,
  .e-float-input.e-control-wrapper.e-bigger,
  .e-bigger .e-float-input.e-control-wrapper {
    font-size: $input-bigger-font-size;
  }

  .e-float-input.e-bigger input,
  .e-float-input input.e-bigger,
  .e-bigger .e-float-input input,
  .e-float-input.e-control-wrapper.e-bigger input,
  .e-float-input.e-control-wrapper input.e-bigger,
  .e-bigger .e-float-input.e-control-wrapper input {
    font: inherit;
    line-height: inherit;
    padding: $float-input-bigger-padding;
  }

  .e-float-input.e-small.e-bigger.e-disabled,
  .e-small.e-bigger .e-float-input.e-disabled,
  .e-bigger .e-float-input.e-small.e-disabled,
  .e-small .e-float-input.e-bigger.e-disabled,
  .e-float-input.e-control-wrapper.e-small.e-bigger.e-disabled,
  .e-small.e-bigger .e-float-input.e-control-wrapper.e-disabled,
  .e-bigger .e-float-input.e-control-wrapper.e-small.e-disabled,
  .e-small .e-float-input.e-control-wrapper.e-bigger.e-disabled,
  .e-float-input.e-input-group.e-small.e-bigger.e-disabled,
  .e-small.e-bigger .e-float-input.e-input-group.e-disabled,
  .e-bigger .e-float-input.e-input-group.e-small.e-disabled,
  .e-small .e-float-input.e-input-group.e-bigger.e-disabled,
  .e-float-input.e-input-group.e-control-wrapper.e-small.e-bigger.e-disabled,
  .e-small.e-bigger .e-float-input.e-input-group.e-control-wrapper.e-disabled,
  .e-bigger .e-float-input.e-input-group.e-control-wrapper.e-small.e-disabled,
  .e-small .e-float-input.e-input-group.e-control-wrapper.e-bigger.e-disabled,
  .e-float-input.e-small.e-bigger,
  .e-small.e-bigger .e-float-input,
  .e-bigger .e-float-input.e-small,
  .e-small .e-float-input.e-bigger,
  .e-float-input.e-control-wrapper.e-small.e-bigger,
  .e-small.e-bigger .e-float-input.e-control-wrapper,
  .e-bigger .e-float-input.e-control-wrapper.e-small,
  .e-small .e-float-input.e-control-wrapper.e-bigger {
    font-size: $input-bigger-small-font-size;
  }

  .e-float-input.e-small.e-bigger input,
  .e-float-input.e-small input.e-bigger,
  .e-bigger .e-float-input.e-small input,
  .e-float-input.e-control-wrapper.e-small.e-bigger input,
  .e-float-input.e-control-wrapper.e-small input.e-bigger,
  .e-bigger .e-float-input.e-control-wrapper.e-small input {
    font: inherit;
    line-height: inherit;
    padding: $float-input-bigger-small-padding;
  }

  .e-bigger .e-float-input.e-outline.e-float-icon-left:not(.e-rtl) .e-input-in-wrap input ~ label.e-float-text.e-label-top {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      left: -40px;
      width: auto;
    }
  }

  .e-bigger .e-float-input.e-outline.e-float-icon-left.e-rtl .e-input-in-wrap input ~ label.e-float-text.e-label-top {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      right: -40px;
      width: auto;
    }
  }

  .e-float-input.e-bigger:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-bigger.e-control-wrapper:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-small.e-bigger:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input.e-small:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input.e-control-wrapper:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-bigger:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-bigger.e-control-wrapper:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-small.e-bigger:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input.e-small:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input.e-control-wrapper:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    font-weight: normal;
  }

  .e-float-input.e-rtl.e-bigger:not(.e-input-focus) label.e-float-text.e-label-bottom,
  .e-float-input.e-rtl.e-control-wrapper.e-bigger:not(.e-input-focus) label.e-float-text.e-label-bottom,
  .e-rtl .e-float-input.e-bigger:not(.e-input-focus) label.e-float-text.e-label-bottom,
  .e-rtl .e-float-input.e-control-wrapper.e-bigger:not(.e-input-focus) label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input.e-rtl:not(.e-input-focus) label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input.e-rtl.e-control-wrapper:not(.e-input-focus) label.e-float-text.e-label-bottom,
  .e-bigger.e-rtl .e-float-input:not(.e-input-focus) label.e-float-text.e-label-bottom,
  .e-bigger.e-rtl .e-float-input.e-control-wrapper:not(.e-input-focus) label.e-float-text.e-label-bottom,
  .e-float-input.e-bigger.e-rtl input:not(:focus):not(:valid) label.e-float-text,
  .e-float-input.e-rtl.e-bigger input:not(:focus):not(:valid) label.e-float-text.e-label-bottom,
  .e-rtl .e-float-input.e-bigger input:not(:focus):not(:valid) label.e-float-text,
  .e-rtl .e-float-input.e-bigger input:not(:focus):not(:valid) label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input.e-rtl input:not(:focus):not(:valid) label.e-float-text,
  .e-bigger .e-float-input.e-rtl input:not(:focus):not(:valid) label.e-float-text.e-label-bottom,
  .e-bigger.e-rtl .e-float-input input:not(:focus):not(:valid) label.e-float-text,
  .e-bigger.e-rtl .e-float-input input:not(:focus):not(:valid) label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-bigger.e-rtl input:not(:focus):not(:valid) label.e-float-text,
  .e-float-input.e-control-wrapper.e-rtl.e-bigger input:not(:focus):not(:valid) label.e-float-text.e-label-bottom,
  .e-rtl .e-float-input.e-control-wrapper.e-bigger input:not(:focus):not(:valid) label.e-float-text,
  .e-rtl .e-float-input.e-control-wrapper.e-bigger input:not(:focus):not(:valid) label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input.e-control-wrapper.e-rtl input:not(:focus):not(:valid) label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-rtl input:not(:focus):not(:valid) label.e-float-text.e-label-bottom,
  .e-bigger.e-rtl .e-float-input.e-control-wrapper input:not(:focus):not(:valid) label.e-float-text,
  .e-bigger.e-rtl .e-float-input.e-control-wrapper input:not(:focus):not(:valid) label.e-float-text.e-label-bottom,
  .e-float-input.e-rtl.e-bigger.e-small:not(.e-input-focus) label.e-float-text.e-label-bottom,
  .e-float-input.e-rtl.e-control-wrapper.e-bigger.e-small:not(.e-input-focus) label.e-float-text.e-label-bottom,
  .e-rtl .e-float-input.e-bigger.e-small:not(.e-input-focus) label.e-float-text.e-label-bottom,
  .e-rtl .e-float-input.e-control-wrapper.e-bigger.e-small:not(.e-input-focus) label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-float-input.e-rtl:not(.e-input-focus) label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-float-input.e-rtl.e-control-wrapper:not(.e-input-focus) label.e-float-text.e-label-bottom,
  .e-bigger.e-small.e-rtl .e-float-input:not(.e-input-focus) label.e-float-text.e-label-bottom,
  .e-bigger.e-small.e-rtl .e-float-input.e-control-wrapper:not(.e-input-focus) label.e-float-text.e-label-bottom,
  .e-float-input.e-bigger.e-small.e-rtl input:not(:focus):not(:valid) label.e-float-text,
  .e-float-input.e-rtl.e-bigger.e-small input:not(:focus):not(:valid) label.e-float-text.e-label-bottom,
  .e-rtl .e-float-input.e-bigger.e-small input:not(:focus):not(:valid) label.e-float-text,
  .e-rtl .e-float-input.e-bigger.e-small input:not(:focus):not(:valid) label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-float-input.e-rtl input:not(:focus):not(:valid) label.e-float-text,
  .e-bigger.e-small .e-float-input.e-rtl input:not(:focus):not(:valid) label.e-float-text.e-label-bottom,
  .e-bigger.e-small.e-rtl .e-float-input input:not(:focus):not(:valid) label.e-float-text,
  .e-bigger.e-small.e-rtl .e-float-input input:not(:focus):not(:valid) label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-bigger.e-small.e-rtl input:not(:focus):not(:valid) label.e-float-text,
  .e-float-input.e-control-wrapper.e-rtl.e-bigger.e-small input:not(:focus):not(:valid) label.e-float-text.e-label-bottom,
  .e-rtl .e-float-input.e-control-wrapper.e-bigger.e-small input:not(:focus):not(:valid) label.e-float-text,
  .e-rtl .e-float-input.e-control-wrapper.e-bigger.e-small input:not(:focus):not(:valid) label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-float-input.e-control-wrapper.e-rtl input:not(:focus):not(:valid) label.e-float-text,
  .e-bigger.e-small .e-float-input.e-control-wrapper.e-rtl input:not(:focus):not(:valid) label.e-float-text.e-label-bottom,
  .e-bigger.e-small.e-rtl .e-float-input.e-control-wrapper input:not(:focus):not(:valid) label.e-float-text,
  .e-bigger.e-small.e-rtl .e-float-input.e-control-wrapper input:not(:focus):not(:valid) label.e-float-text.e-label-bottom {
    padding-right: $float-label-padding;
  }

  .e-input-group.e-bigger .e-input,
  .e-input-group.e-control-wrapper.e-bigger .e-input,
  .e-bigger .e-input-group .e-input,
  .e-bigger .e-input-group.e-control-wrapper .e-input,
  .e-float-input.e-bigger .e-input,
  .e-bigger .e-float-input .e-input,
  .e-float-input.e-control-wrapper.e-bigger .e-input,
  .e-bigger .e-float-input.e-control-wrapper .e-input {
    margin-bottom: $zero-value;
  }

  .e-input#{$css}.e-small.e-bigger,
  .e-input-group.e-small.e-bigger,
  .e-input-group.e-control-wrapper.e-small.e-bigger,
  .e-bigger .e-input#{$css}.e-small,
  .e-bigger .e-input-group.e-small,
  .e-bigger .e-input-group.e-control-wrapper.e-small {
    line-height: inherit;
    margin-bottom: $input-small-bigger-margin-bottom;
  }

  .e-input-group.e-bigger .e-input-group-icon,
  .e-input-group .e-input-group-icon.e-bigger,
  .e-input-group.e-control-wrapper.e-bigger .e-input-group-icon,
  .e-input-group.e-control-wrapper .e-input-group-icon.e-bigger,
  .e-bigger .e-input-group .e-input-group-icon,
  .e-bigger .e-input-group.e-control-wrapper .e-input-group-icon {
    @if ($input-skin-name != 'Material3' and $input-skin-name != 'fluent2') {
      margin-bottom: $input-child-bigger-margin-bottom;
      margin-right: $input-child-bigger-margin-right;
      margin-top: $input-child-bigger-margin-top;
    }
    @else {
      margin: $input-child-bigger-margin;
    }
  }

  .e-float-input.e-input-group.e-bigger .e-input-group-icon,
  .e-float-input.e-input-group .e-input-group-icon.e-bigger,
  .e-bigger .e-float-input.e-input-group .e-input-group-icon,
  .e-float-input.e-control-wrapper.e-input-group.e-bigger .e-input-group-icon,
  .e-float-input.e-control-wrapper.e-input-group .e-input-group-icon.e-bigger,
  .e-bigger .e-float-input.e-control-wrapper.e-input-group .e-input-group-icon {
    @if $input-skin-name != 'Material3' and $input-skin-name != 'fluent2' {
      margin-top: $float-input-child-bigger-margin-top;
    }
  }

  .e-input-group.e-small.e-bigger .e-input-group-icon,
  .e-input-group.e-small .e-input-group-icon.e-bigger,
  .e-input-group.e-control-wrapper.e-small.e-bigger .e-input-group-icon,
  .e-input-group.e-control-wrapper.e-small .e-input-group-icon.e-bigger,
  .e-small .e-bigger.e-input-group .e-input-group-icon,
  .e-small .e-bigger.e-input-group.e-control-wrapper .e-input-group-icon,
  .e-bigger .e-input-group.e-small .e-input-group-icon,
  .e-bigger .e-input-group.e-control-wrapper.e-small .e-input-group-icon {
    @if $input-skin-name != 'fluent2' {
      margin-bottom: $input-child-small-bigger-margin-bottom;
      margin-right: $input-child-small-bigger-margin-right;
      margin-top: $input-child-small-bigger-margin-top;
    }
  }

  .e-float-input.e-input-group.e-small.e-bigger .e-input-group-icon,
  .e-float-input.e-input-group.e-small .e-input-group-icon.e-bigger,
  .e-bigger .e-float-input.e-input-group.e-small .e-input-group-icon,
  .e-float-input.e-control-wrapper.e-input-group.e-small.e-bigger .e-input-group-icon,
  .e-float-input.e-control-wrapper.e-input-group.e-small .e-input-group-icon.e-bigger,
  .e-bigger .e-float-input.e-control-wrapper.e-input-group.e-small .e-input-group-icon {
    @if $input-skin-name != 'Material3' and $input-skin-name != 'fluent2' {
      margin-top: $float-input-child-small-bigger-margin-top;
    }
  }

  .e-input-group.e-bigger .e-input-group-icon:last-child,
  .e-input-group .e-input-group-icon.e-bigger:last-child,
  .e-bigger .e-input-group .e-input-group-icon:last-child,
  .e-input-group.e-small.e-bigger .e-input-group-icon:last-child,
  .e-input-group.e-small .e-input-group-icon.e-bigger:last-child,
  .e-input-group.e-control-wrapper.e-bigger .e-input-group-icon:last-child,
  .e-input-group.e-control-wrapper .e-input-group-icon.e-bigger:last-child,
  .e-input-group.e-control-wrapper.e-small.e-bigger .e-input-group-icon:last-child,
  .e-input-group.e-control-wrapper.e-small .e-input-group-icon.e-bigger:last-child,
  .e-bigger .e-input-group.e-control-wrapper.e-small .e-input-group-icon:last-child,
  .e-bigger .e-input-group.e-small .e-input-group-icon:last-child {
    @if $input-skin-name != 'fluent' {
      margin-right: $zero-value;
    }
    @if $input-skin-name == 'fluent2' {
      margin: $zero-value;
    }
  }

  .e-bigger input.e-input#{$css}::-webkit-input-placeholder,
  input.e-bigger.e-input#{$css}::-webkit-input-placeholder,
  .e-bigger input.e-input#{$css}:-moz-placeholder,
  input.e-bigger.e-input#{$css}:-moz-placeholder,
  .e-bigger input.e-input#{$css}:-ms-input-placeholder,
  input.e-bigger.e-input#{$css}:-ms-input-placeholder,
  .e-bigger input.e-input#{$css}::-moz-placeholder,
  input.e-bigger.e-input#{$css}::-moz-placeholder,
  .e-bigger textarea.e-input#{$css}::-webkit-input-placeholder,
  textarea.e-bigger.e-input#{$css}::-webkit-input-placeholder,
  .e-bigger textarea.e-input#{$css}:-moz-placeholder,
  textarea.e-bigger.e-input#{$css}:-moz-placeholder,
  .e-bigger textarea.e-input#{$css}:-ms-input-placeholder,
  textarea.e-bigger.e-input#{$css}:-ms-input-placeholder,
  .e-bigger textarea.e-input#{$css}::-moz-placeholder,
  textarea.e-bigger.e-input#{$css}::-moz-placeholder,
  .e-bigger textarea.e-input#{$css}::-webkit-textarea-placeholder,
  textarea.e-bigger.e-input#{$css}::-webkit-textarea-placeholder,
  .e-bigger textarea.e-input#{$css}:-moz-placeholder,
  textarea.e-bigger.e-input#{$css}:-moz-placeholder,
  .e-bigger textarea.e-input#{$css}:-ms-textarea-placeholder,
  textarea.e-bigger.e-input#{$css}:-ms-textarea-placeholder,
  .e-bigger textarea.e-input#{$css}::-moz-placeholder,
  textarea.e-bigger.e-input#{$css}::-moz-placeholder {
    font-size: $input-bigger-font-size;
    font-style: $input-font-style;
  }

  .e-bigger input.e-small.e-input#{$css}::-webkit-input-placeholder,
  .e-small input.e-bigger.e-input#{$css}::-webkit-input-placeholder,
  .e-bigger input.e-small.e-input#{$css}:-moz-placeholder,
  .e-small input.e-bigger.e-input#{$css}:-moz-placeholder,
  .e-bigger input.e-small.e-input#{$css}:-ms-input-placeholder,
  .e-small input.e-bigger.e-input#{$css}:-ms-input-placeholder,
  .e-bigger input.e-small.e-input#{$css}::-moz-placeholder,
  .e-small input.e-bigger.e-input#{$css}::-moz-placeholder,
  .e-bigger textarea.e-small.e-input#{$css}::-webkit-input-placeholder,
  .e-small textarea.e-bigger.e-input#{$css}::-webkit-input-placeholder,
  .e-bigger textarea.e-small.e-input#{$css}:-moz-placeholder,
  .e-small textarea.e-bigger.e-input#{$css}:-moz-placeholder,
  .e-bigger intextareaput.e-small.e-input#{$css}:-ms-input-placeholder,
  .e-small textarea.e-bigger.e-input#{$css}:-ms-input-placeholder,
  .e-bigger textarea.e-small.e-input#{$css}::-moz-placeholder,
  .e-small textarea.e-bigger.e-input#{$css}::-moz-placeholder,
  .e-bigger textarea.e-small.e-input#{$css}::-webkit-textarea-placeholder,
  .e-small textarea.e-bigger.e-input#{$css}::-webkit-textarea-placeholder,
  .e-bigger textarea.e-small.e-input#{$css}:-moz-placeholder,
  .e-small textarea.e-bigger.e-input#{$css}:-moz-placeholder,
  .e-bigger textarea.e-small.e-input#{$css}:-ms-input-placeholder,
  .e-small textarea.e-bigger.e-input#{$css}:-ms-input-placeholder,
  .e-bigger textarea.e-small.e-input#{$css}::-moz-placeholder,
  .e-small textarea.e-bigger.e-input#{$css}::-moz-placeholder {
    font-size: $input-bigger-small-font-size;
    font-style: $input-font-style;
  }

  input.e-input.e-bigger#{$css},
  .e-bigger input.e-input#{$css},
  .e-input-group.e-bigger input,
  .e-input-group.e-bigger input.e-input,
  .e-input-group.e-control-wrapper.e-bigger input,
  .e-input-group.e-control-wrapper.e-bigger input.e-input,
  .e-bigger .e-input-group input,
  .e-bigger .e-input-group input.e-input,
  .e-bigger .e-input-group.e-control-wrapper input,
  .e-bigger .e-input-group.e-control-wrapper input.e-input,
  .e-float-input.e-bigger input,
  .e-float-input.e-bigger input.e-input,
  .e-bigger .e-float-input input,
  .e-bigger .e-float-input input.e-input,
  .e-float-input.e-control-wrapper.e-bigger input,
  .e-float-input.e-control-wrapper.e-bigger input.e-input,
  .e-bigger .e-float-input.e-control-wrapper input,
  .e-bigger .e-float-input.e-control-wrapper input.e-input {
    @include input-sizing;
    @include input-height ($input-bigger-height);
  }

  .e-float-input.e-bigger:not(.e-input-group) input,
  .e-float-input.e-bigger:not(.e-input-group) input.e-input,
  .e-bigger .e-float-input:not(.e-input-group) input,
  .e-bigger .e-float-input:not(.e-input-group) input.e-input,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-input-group) input,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-input-group) input.e-input,
  .e-bigger .e-float-input.e-control-wrapper:not(.e-input-group) input,
  .e-bigger .e-float-input.e-control-wrapper:not(.e-input-group) input.e-input {
    @include input-sizing;
    @include input-height ($float-input-bigger-height);
  }

  input.e-input.e-small.e-bigger#{$css},
  .e-input-group.e-bigger.e-small input,
  .e-input-group.e-bigger.e-small input.e-input,
  .e-bigger.e-small .e-input-group input,
  .e-bigger.e-small .e-input-group input.e-input,
  .e-input-group.e-control-wrapper.e-bigger.e-small input,
  .e-input-group.e-control-wrapper.e-bigger.e-small input.e-input,
  .e-bigger.e-small .e-input-group.e-control-wrapper input,
  .e-bigger.e-small .e-input-group.e-control-wrapper input.e-input,
  .e-float-input.e-bigger.e-small input,
  .e-float-input.e-bigger.e-small input.e-input,
  .e-bigger.e-small .e-float-input input,
  .e-bigger.e-small .e-float-input input.e-input,
  .e-float-input.e-control-wrapper.e-bigger.e-small input,
  .e-float-input.e-control-wrapper.e-bigger.e-small input.e-input,
  .e-bigger.e-small .e-float-input.e-control-wrapper input,
  .e-bigger.e-small .e-float-input.e-control-wrapper input.e-input {
    @include input-sizing;
    @include input-height ($input-bigger-small-height);
  }

  .e-float-input.e-bigger.e-small:not(.e-input-group) input,
  .e-float-input.e-bigger.e-small:not(.e-input-group) input.e-input,
  .e-bigger.e-small .e-float-input:not(.e-input-group) input,
  .e-bigger.e-small .e-float-input:not(.e-input-group) input.e-input,
  .e-float-input.e-control-wrapper.e-bigger.e-small:not(.e-input-group) input,
  .e-float-input.e-control-wrapper.e-bigger.e-small:not(.e-input-group) input.e-input,
  .e-bigger.e-small .e-float-input.e-control-wrapper:not(.e-input-group) input,
  .e-bigger.e-small .e-float-input.e-control-wrapper:not(.e-input-group) input.e-input {
    @include input-sizing;
    @include input-height ($float-input-bigger-small-height);
  }

  .e-input-group textarea.e-input.e-bigger,
  .e-input-group.e-control-wrapper textarea.e-input.e-bigger,
  textarea.e-input.e-bigger#{$css},
  .e-input-group.e-bigger textarea,
  .e-input-group.e-bigger textarea.e-input,
  .e-bigger .e-input-group textarea,
  .e-bigger .e-input-group textarea.e-input,
  .e-input-group.e-control-wrapper.e-bigger textarea,
  .e-input-group.e-control-wrapper.e-bigger textarea.e-input,
  .e-bigger .e-input-group.e-control-wrapper textarea,
  .e-bigger .e-input-group.e-control-wrapper textarea.e-input,
  .e-float-input.e-bigger textarea,
  .e-float-input.e-bigger textarea.e-input,
  .e-bigger .e-float-input textarea,
  .e-bigger .e-float-input textarea.e-input,
  .e-float-input.e-control-wrapper.e-bigger textarea,
  .e-float-input.e-control-wrapper.e-bigger textarea.e-input,
  .e-bigger .e-float-input.e-control-wrapper textarea,
  .e-bigger .e-float-input.e-control-wrapper textarea.e-input  {
    @include input-sizing;
    @include input-height ($textarea-bigger-height);
  }

  textarea.e-input.e-small.e-bigger#{$css},
  .e-input-group.e-bigger.e-small textarea,
  .e-input-group.e-bigger.e-small textarea.e-input,
  .e-bigger.e-small .e-input-group textarea,
  .e-bigger.e-small .e-input-group textarea.e-input,
  .e-input-group.e-control-wrapper.e-bigger.e-small textarea,
  .e-input-group.e-control-wrapper.e-bigger.e-small textarea.e-input,
  .e-bigger.e-small .e-input-group.e-control-wrapper textarea,
  .e-bigger.e-small .e-input-group.e-control-wrapper textarea.e-input,
  .e-float-input.e-bigger.e-small textarea,
  .e-float-input.e-bigger.e-small textarea.e-input,
  .e-bigger.e-small .e-float-input textarea,
  .e-bigger.e-small .e-float-input textarea.e-input,
  .e-float-input.e-control-wrapper.e-bigger.e-small textarea,
  .e-float-input.e-control-wrapper.e-bigger.e-small textarea.e-input,
  .e-bigger.e-small .e-float-input.e-control-wrapper textarea,
  .e-bigger.e-small .e-float-input.e-control-wrapper textarea.e-input {
    @include input-sizing;
    @include input-height ($textarea-bigger-small-height);
  }

  input.e-input.e-bigger#{$css},
  .e-input-group input.e-input.e-bigger,
  .e-input-group input.e-input.e-bigger,
  .e-input-group.e-control-wrapper input.e-input.e-bigger,
  .e-input-group.e-control-wrapper input.e-input.e-bigger,
  .e-input-group.e-bigger .e-input,
  .e-input-group.e-control-wrapper.e-bigger .e-input,
  .e-bigger input.e-input#{$css},
  .e-bigger .e-input-group .e-input,
  .e-bigger .e-input-group.e-control-wrapper .e-input,
  .e-float-input.e-bigger input,
  .e-float-input input.e-bigger,
  .e-bigger .e-float-input input,
  .e-float-input.e-control-wrapper.e-bigger input,
  .e-float-input.e-control-wrapper input.e-bigger,
  .e-bigger .e-float-input.e-control-wrapper input {
    text-indent: $input-bigger-text-indent;
  }

  input.e-input.e-bigger#{$css},
  .e-bigger input.e-input#{$css},
  .e-input-group.e-bigger input.e-input,
  .e-input-group.e-control-wrapper.e-bigger input.e-input,
  .e-float-input.e-control-wrapper.e-bigger input,
  .e-float-input.e-control-wrapper input.e-bigger,
  .e-float-input.e-bigger input,
  .e-float-input input.e-bigger,
  .e-input-group input.e-input.e-bigger,
  .e-input-group.e-control-wrapper input.e-input.e-bigger,
  .e-bigger .e-float-input.e-control-wrapper input,
  .e-bigger .e-float-input input,
  .e-bigger .e-input-group.e-control-wrapper input.e-input,
  .e-bigger .e-input-group input.e-input,
  .e-input-group.e-bigger input.e-input:focus,
  .e-input-group.e-control-wrapper.e-bigger input.e-input:focus,
  .e-float-input.e-control-wrapper.e-bigger input:focus,
  .e-float-input.e-bigger input:focus,
  .e-bigger .e-input-group input.e-input:focus,
  .e-bigger .e-input-group.e-control-wrapper input.e-input:focus,
  .e-bigger .e-float-input.e-control-wrapper input:focus,
  .e-bigger .e-float-input input:focus,
  .e-input-group.e-bigger.e-input-focus input.e-input,
  .e-input-group.e-control-wrapper.e-bigger.e-input-focus input.e-input,
  .e-bigger .e-input-group.e-input-focus input.e-input,
  .e-bigger .e-input-group.e-control-wrapper.e-input-focus input.e-input,
  .e-float-input.e-control-wrapper.e-bigger.e-input-focus input,
  .e-float-input.e-bigger.e-input-focus input,
  .e-bigger .e-float-input.e-control-wrapper.e-input-focus input,
  .e-bigger .e-float-input.e-input-focus input {
    @if $input-skin-name == 'fluent2' {
      padding: 2px 4px;
    }
    @else {
      padding-left: $input-bigger-text-indent;
    }
    text-indent: 0;
  }

  .e-rtl input.e-input.e-bigger#{$css},
  input.e-input.e-bigger.e-rtl#{$css},
  .e-bigger.e-rtl input.e-input#{$css},
  .e-bigger input.e-input.e-rtl#{$css},
  .e-float-input.e-control-wrapper.e-bigger.e-rtl input,
  .e-float-input.e-bigger.e-rtl input,
  .e-input-group.e-bigger.e-rtl input.e-input,
  .e-input-group.e-control-wrapper.e-bigger.e-rtl input.e-input,
  .e-rtl .e-float-input.e-control-wrapper.e-bigger input,
  .e-rtl .e-float-input.e-bigger input,
  .e-rtl .e-input-group.e-bigger input.e-input,
  .e-rtl .e-input-group.e-control-wrapper.e-bigger input.e-input,
  .e-float-input.e-control-wrapper.e-rtl input.e-bigger,
  .e-float-input.e-rtl input.e-bigger,
  .e-input-group.e-rtl input.e-input.e-bigger,
  .e-input-group.e-control-wrapper.e-rtl input.e-input.e-bigger,
  .e-rtl .e-float-input.e-control-wrapper input.e-bigger,
  .e-rtl .e-float-input input.e-bigger,
  .e-rtl .e-input-group input.e-input.e-bigger,
  .e-rtl .e-input-group.e-control-wrapper input.e-input.e-bigger,
  .e-bigger .e-float-input.e-control-wrapper.e-rtl input,
  .e-bigger .e-float-input.e-rtl input,
  .e-bigger .e-input-group.e-rtl input.e-input,
  .e-bigger .e-input-group.e-control-wrapper.e-rtl input.e-input,
  .e-bigger.e-rtl .e-float-input.e-control-wrapper input,
  .e-bigger.e-rtl .e-float-input input,
  .e-bigger.e-rtl .e-input-group input.e-input,
  .e-bigger.e-rtl .e-input-group.e-control-wrapper input.e-input,
  .e-bigger.e-rtl .e-input-group input.e-input:focus,
  .e-bigger.e-rtl .e-input-group.e-control-wrapper input.e-input:focus,
  .e-bigger.e-rtl .e-float-input.e-control-wrapper input:focus,
  .e-bigger.e-rtl .e-float-input input:focus,
  .e-bigger .e-input-group.e-rtl input.e-input:focus,
  .e-bigger .e-input-group.e-control-wrapper.e-rtl input.e-input:focus,
  .e-bigger .e-float-input.e-rtl input:focus,
  .e-bigger .e-float-input.e-control-wrapper.e-rtl input:focus,
  .e-bigger.e-rtl .e-input-group.e-input-focus input.e-input,
  .e-bigger.e-rtl .e-input-group.e-control-wrapper.e-input-focus input.e-input,
  .e-bigger .e-input-group.e-rtl.e-input-focus input.e-input,
  .e-bigger .e-input-group.e-control-wrapper.e-rtl.e-input-focus input.e-input,
  .e-bigger.e-rtl .e-float-input.e-control-wrapper.e-input-focus input,
  .e-bigger.e-rtl .e-float-input.e-input-focus input,
  .e-bigger .e-float-input.e-rtl.e-input-focus input,
  .e-bigger .e-float-input.e-control-wrapper.e-rtl.e-input-focus input {
    padding-left: 0;
    padding-right: $input-bigger-text-indent;
    text-indent: 0;
  }

  textarea.e-input.e-bigger#{$css},
  .e-bigger textarea.e-input#{$css},
  .e-input-group.e-bigger textarea.e-input,
  .e-input-group.e-control-wrapper.e-bigger textarea.e-input,
  .e-float-input.e-control-wrapper.e-bigger textarea,
  .e-float-input.e-control-wrapper textarea.e-bigger,
  .e-float-input.e-bigger textarea,
  .e-float-input textarea.e-bigger,
  .e-input-group textarea.e-input.e-bigger,
  .e-input-group.e-control-wrapper textarea.e-input.e-bigger,
  .e-bigger .e-float-input.e-control-wrapper textarea,
  .e-bigger .e-float-input textarea,
  .e-bigger .e-input-group textarea.e-input,
  .e-bigger .e-input-group.e-control-wrapper textarea.e-input,
  .e-input-group.e-bigger textarea.e-input:focus,
  .e-input-group.e-control-wrapper.e-bigger textarea.e-input:focus,
  .e-float-input.e-control-wrapper.e-bigger textarea:focus,
  .e-float-input.e-bigger textarea:focus,
  .e-bigger .e-input-group textarea.e-input:focus,
  .e-bigger .e-input-group.e-control-wrapper textarea.e-input:focus,
  .e-bigger .e-float-input.e-control-wrapper textarea:focus,
  .e-bigger .e-float-input textarea:focus {
    @if $input-skin-name == 'fluent2' {
      padding: $textarea-bigger-padding;
    }
    @else {
      padding-left: $input-bigger-text-indent;
    }
    text-indent: 0;
  }

  .e-rtl textarea.e-input.e-bigger#{$css},
  textarea.e-input.e-bigger.e-rtl#{$css},
  .e-bigger.e-rtl textarea.e-input#{$css},
  .e-bigger textarea.e-input.e-rtl#{$css},
  .e-float-input.e-control-wrapper:not(.e-outline).e-bigger.e-rtl textarea,
  .e-float-input:not(.e-outline).e-bigger.e-rtl textarea,
  .e-input-group:not(.e-outline).e-bigger.e-rtl textarea.e-input,
  .e-input-group:not(.e-outline).e-control-wrapper.e-bigger.e-rtl textarea.e-input,
  .e-rtl .e-float-input:not(.e-outline).e-control-wrapper.e-bigger textarea,
  .e-rtl .e-float-input:not(.e-outline).e-bigger textarea,
  .e-rtl .e-input-group:not(.e-outline).e-bigger textarea.e-input,
  .e-rtl .e-input-group:not(.e-outline).e-control-wrapper.e-bigger textarea.e-input,
  .e-float-input:not(.e-outline).e-rtl textarea.e-bigger,
  .e-float-input:not(.e-outline).e-control-wrapper.e-rtl textarea.e-bigger,
  .e-input-group:not(.e-outline).e-rtl textarea.e-input.e-bigger,
  .e-input-group:not(.e-outline).e-control-wrapper.e-rtl textarea.e-input.e-bigger,
  .e-rtl .e-float-input:not(.e-outline) textarea.e-bigger,
  .e-rtl .e-float-input:not(.e-outline).e-control-wrapper textarea.e-bigger,
  .e-rtl .e-input-group:not(.e-outline) textarea.e-input.e-bigger,
  .e-rtl .e-input-group:not(.e-outline).e-control-wrapper textarea.e-input.e-bigger,
  .e-bigger .e-float-input:not(.e-outline).e-rtl textarea,
  .e-bigger .e-float-input:not(.e-outline).e-control-wrapper.e-rtl textarea,
  .e-bigger .e-input-group:not(.e-outline).e-rtl textarea.e-input,
  .e-bigger .e-input-group:not(.e-outline).e-control-wrapper.e-rtl textarea.e-input,
  .e-bigger.e-rtl .e-float-input:not(.e-outline) textarea,
  .e-bigger.e-rtl .e-float-input:not(.e-outline).e-control-wrapper textarea,
  .e-bigger.e-rtl .e-input-group:not(.e-outline) textarea.e-input,
  .e-bigger.e-rtl .e-input-group:not(.e-outline).e-control-wrapper textarea.e-input,
  .e-bigger.e-rtl .e-input-group:not(.e-outline) textarea.e-input:focus,
  .e-bigger.e-rtl .e-input-group:not(.e-outline).e-control-wrapper textarea.e-input:focus,
  .e-bigger.e-rtl .e-float-input:not(.e-outline) textarea:focus,
  .e-bigger.e-rtl .e-float-input:not(.e-outline).e-control-wrapper textarea:focus,
  .e-bigger .e-input-group:not(.e-outline).e-rtl textarea.e-input:focus,
  .e-bigger .e-input-group:not(.e-outline).e-control-wrapper.e-rtl textarea.e-input:focus,
  .e-bigger .e-float-input:not(.e-outline).e-rtl textarea:focus,
  .e-bigger .e-float-input:not(.e-outline).e-control-wrapper.e-rtl textarea:focus {
    padding-right: $input-bigger-text-indent;
    text-indent: 0;
  }

  input.e-input.e-bigger.e-small#{$css},
  .e-bigger input.e-input.e-small#{$css},
  .e-input-group.e-small.e-bigger input.e-input,
  .e-input-group.e-control-wrapper.e-small.e-bigger input.e-input,
  .e-input-group.e-small input.e-input.e-bigger,
  .e-input-group.e-control-wrapper.e-small input.e-input.e-bigger,
  .e-bigger .e-input-group.e-small input.e-input,
  .e-bigger .e-input-group.e-control-wrapper.e-small input.e-input,
  .e-float-input.e-small.e-bigger input,
  .e-float-input.e-small input.e-bigger,
  .e-bigger .e-float-input.e-small input,
  .e-float-input.e-control-wrapper.e-small.e-bigger input,
  .e-float-input.e-control-wrapper.e-small input.e-bigger,
  .e-bigger .e-float-input.e-control-wrapper.e-small input,
  .e-bigger .e-input-group.e-small input.e-input:focus,
  .e-bigger .e-input-group.e-control-wrapper.e-small input.e-input:focus,
  .e-bigger .e-float-input.e-control-wrapper.e-small input:focus,
  .e-bigger .e-float-input.e-small input:focus,
  .e-small .e-input-group.e-bigger input.e-input:focus,
  .e-small .e-input-group.e-control-wrapper.e-bigger input.e-input:focus,
  .e-small .e-float-input.e-control-wrapper.e-bigger input:focus,
  .e-small .e-float-input.e-bigger input:focus,
  .e-input-group.e-bigger.e-small input.e-input:focus,
  .e-input-group.e-control-wrapper.e-bigger.e-small input.e-input:focus,
  .e-float-input.e-control-wrapper.e-bigger.e-small input:focus,
  .e-float-input.e-bigger.e-small input:focus,
  .e-bigger .e-input-group.e-small.e-input-focus input.e-input,
  .e-bigger .e-input-group.e-control-wrapper.e-small.e-input-focus input.e-input,
  .e-small .e-input-group.e-bigger.e-input-focus input.e-input,
  .e-small .e-input-group.e-control-wrapper.e-bigger.e-input-focus input.e-input,
  .e-input-group.e-bigger.e-small.e-input-focus input.e-input,
  .e-input-group.e-control-wrapper.e-bigger.e-small.e-input-focus input.e-input,
  .e-bigger .e-float-input.e-control-wrapper.e-small.e-input-focus input,
  .e-bigger .e-float-input.e-small.e-input-focus input,
  .e-small .e-float-input.e-control-wrapper.e-bigger.e-input-focus input,
  .e-small .e-float-input.e-bigger.e-input-focus input,
  .e-float-input.e-control-wrapper.e-bigger.e-small.e-input-focus input,
  .e-float-input.e-bigger.e-small.e-input-focus input,
  textarea.e-input.e-bigger.e-small#{$css},
  .e-bigger textarea.e-input.e-small#{$css},
  .e-input-group.e-small.e-bigger textarea.e-input,
  .e-input-group.e-control-wrapper.e-small.e-bigger textarea.e-input,
  .e-input-group.e-small textarea.e-input.e-bigger,
  .e-input-group.e-control-wrapper.e-small textarea.e-input.e-bigger,
  .e-bigger .e-input-group.e-small textarea.e-input,
  .e-bigger .e-input-group.e-control-wrapper.e-small textarea.e-input,
  .e-float-input.e-small.e-bigger textarea,
  .e-float-input.e-small textarea.e-bigger,
  .e-bigger .e-float-input.e-small textarea,
  .e-float-input.e-control-wrapper.e-small.e-bigger textarea,
  .e-float-input.e-control-wrapper.e-small textarea.e-bigger,
  .e-bigger .e-float-input.e-control-wrapper.e-small textarea,
  .e-bigger .e-input-group.e-small textarea.e-input:focus,
  .e-bigger .e-input-group.e-control-wrapper.e-small textarea.e-input:focus,
  .e-bigger .e-float-input.e-control-wrapper.e-small textarea:focus,
  .e-bigger .e-float-input.e-small textarea:focus,
  .e-small .e-input-group.e-bigger textarea.e-input:focus,
  .e-small .e-input-group.e-control-wrapper.e-bigger textarea.e-input:focus,
  .e-small .e-float-input.e-control-wrapper.e-bigger textarea:focus,
  .e-small .e-float-input.e-bigger textarea:focus,
  .e-input-group.e-bigger.e-small textarea.e-input:focus,
  .e-input-group.e-control-wrapper.e-bigger.e-small textarea.e-input:focus,
  .e-float-input.e-control-wrapper.e-bigger.e-small textarea:focus,
  .e-float-input.e-bigger.e-small textarea:focus {
    padding-left: $input-small-bigger-text-indent;
    text-indent: 0;
  }

  .e-bigger input.e-input.e-small.e-rtl#{$css},
  .e-input-group.e-small.e-bigger.e-rtl input.e-input,
  .e-input-group.e-control-wrapper.e-small.e-bigger.e-rtl input.e-input,
  .e-input-group.e-small.e-rtl input.e-input.e-bigger,
  .e-input-group.e-control-wrapper.e-small.e-rtl input.e-input.e-bigger,
  .e-bigger .e-input-group.e-small.e-rtl input.e-input,
  .e-bigger .e-input-group.e-control-wrapper.e-small.e-rtl input.e-input,
  .e-float-input.e-small.e-bigger.e-rtl input,
  .e-float-input.e-small.e-rtl input.e-bigger,
  .e-bigger .e-float-input.e-small.e-rtl input,
  .e-float-input.e-control-wrapper.e-small.e-bigger.e-rtl input,
  .e-float-input.e-control-wrapper.e-small.e-rtl input.e-bigger,
  .e-bigger .e-float-input.e-control-wrapper.e-small.e-rtl input,
  .e-bigger.e-rtl input.e-input.e-small,
  .e-rtl .e-input-group.e-small.e-bigger input.e-input,
  .e-rtl .e-input-group.e-control-wrapper.e-small.e-bigger input.e-input,
  .e-rtl .e-input-group.e-small input.e-input.e-bigger,
  .e-rtl .e-input-group.e-control-wrapper.e-small input.e-input.e-bigger,
  .e-bigger.e-rtl .e-input-group.e-small input.e-input,
  .e-bigger.e-rtl .e-input-group.e-control-wrapper.e-small input.e-input,
  .e-rtl .e-float-input.e-control-wrapper.e-small.e-bigger input,
  .e-rtl .e-float-input.e-control-wrapper.e-small input.e-bigger,
  .e-bigger.e-rtl .e-float-input.e-control-wrapper.e-small input,
  .e-rtl .e-float-input.e-small.e-bigger input,
  .e-rtl .e-float-input.e-small input.e-bigger,
  .e-bigger.e-rtl .e-float-input.e-small input,
  .e-bigger .e-input-group.e-small.e-rtl input.e-input:focus,
  .e-bigger .e-input-group.e-control-wrapper.e-small.e-rtl input.e-input:focus,
  .e-bigger .e-float-input.e-control-wrapper.e-small.e-rtl input:focus,
  .e-bigger .e-float-input.e-small.e-rtl input:focus,
  .e-small .e-input-group.e-bigger.e-rtl input.e-input:focus,
  .e-small .e-input-group.e-control-wrapper.e-bigger.e-rtl input.e-input:focus,
  .e-small .e-float-input.e-control-wrapper.e-bigger.e-rtl input:focus,
  .e-small .e-float-input.e-bigger.e-rtl input:focus,
  .e-input-group.e-bigger.e-small.e-rtl input.e-input:focus,
  .e-input-group.e-control-wrapper.e-bigger.e-small.e-rtl input.e-input:focus,
  .e-float-input.e-control-wrapper.e-bigger.e-small.e-rtl input:focus,
  .e-float-input.e-bigger.e-small.e-rtl input:focus,
  .e-bigger.e-rtl .e-input-group.e-small input.e-input:focus,
  .e-bigger.e-rtl .e-input-group.e-control-wrapper.e-small input.e-input:focus,
  .e-bigger.e-rtl .e-float-input.e-control-wrapper.e-small input:focus,
  .e-bigger.e-rtl .e-float-input.e-small input:focus,
  .e-small.e-rtl .e-input-group.e-bigger input.e-input:focus,
  .e-small.e-rtl .e-input-group.e-control-wrapper.e-bigger input.e-input:focus,
  .e-small.e-rtl .e-float-input.e-control-wrapper.e-bigger input:focus,
  .e-small.e-rtl .e-float-input.e-bigger input:focus,
  .e-rtl .e-input-group.e-bigger.e-small input.e-input:focus,
  .e-rtl .e-input-group.e-control-wrapper.e-bigger.e-small input.e-input:focus,
  .e-rtl .e-float-input.e-control-wrapper.e-bigger.e-small input:focus,
  .e-rtl .e-float-input.e-bigger.e-small input:focus,
  .e-bigger .e-float-input.e-control-wrapper.e-small.e-rtl.e-input-focus input,
  .e-bigger .e-float-input.e-small.e-rtl.e-input-focus input,
  .e-small .e-float-input.e-control-wrapper.e-bigger.e-rtl.e-input-focus input,
  .e-small .e-float-input.e-bigger.e-rtl.e-input-focus input,
  .e-float-input.e-control-wrapper.e-bigger.e-small.e-rtl.e-input-focus input,
  .e-float-input.e-bigger.e-small.e-rtl.e-input-focus input,
  .e-bigger.e-rtl .e-float-input.e-control-wrapper.e-small.e-input-focus input,
  .e-bigger.e-rtl .e-float-input.e-small.e-input-focus input,
  .e-small.e-rtl .e-float-input.e-control-wrapper.e-bigger.e-input-focus input,
  .e-small.e-rtl .e-float-input.e-bigger.e-input-focus input,
  .e-rtl .e-float-input.e-control-wrapper.e-bigger.e-small.e-input-focus input,
  .e-rtl .e-float-input.e-bigger.e-small.e-input-focus input,
  .e-bigger .e-input-group.e-small.e-rtl.e-input-focus input.e-input,
  .e-bigger .e-input-group.e-control-wrapper.e-small.e-rtl.e-input-focus input.e-input,
  .e-small .e-input-group.e-bigger.e-rtl.e-input-focus input.e-input,
  .e-small .e-input-group.e-control-wrapper.e-bigger.e-rtl.e-input-focus input.e-input,
  .e-input-group.e-bigger.e-small.e-rtl.e-input-focus input.e-input,
  .e-input-group.e-control-wrapper.e-bigger.e-small.e-rtl.e-input-focus input.e-input,
  .e-bigger.e-rtl .e-input-group.e-small.e-input-focus input.e-input,
  .e-bigger.e-rtl .e-input-group.e-control-wrapper.e-small.e-input-focus input.e-input,
  .e-small.e-rtl .e-input-group.e-bigger.e-input-focus input.e-input,
  .e-small.e-rtl .e-input-group.e-control-wrapper.e-bigger.e-input-focus input.e-input,
  .e-rtl .e-input-group.e-bigger.e-small.e-input-focus input.e-input,
  .e-rtl .e-input-group.e-control-wrapper.e-bigger.e-small.e-input-focus input.e-input {
    padding-left: 0;
    padding-right: $input-small-bigger-text-indent;
    text-indent: 0;
  }

  .e-bigger .e-input-group.e-small textarea.e-input:focus,
  .e-bigger .e-input-group.e-control-wrapper.e-small textarea.e-input:focus,
  .e-bigger .e-float-input.e-control-wrapper.e-small textarea:focus,
  .e-bigger .e-float-input.e-small textarea:focus,
  .e-small .e-input-group.e-bigger textarea.e-input:focus,
  .e-small .e-input-group.e-control-wrapper.e-bigger textarea.e-input:focus,
  .e-small .e-float-input.e-control-wrapper.e-bigger textarea:focus,
  .e-small .e-float-input.e-bigger textarea:focus,
  .e-input-group.e-bigger.e-small textarea.e-input:focus,
  .e-input-group.e-bigger textarea.e-input:focus,
  .e-input-group.e-small textarea.e-input:focus,
  .e-input-group textarea.e-input:focus,
  .e-input-group.e-control-wrapper.e-bigger.e-small textarea.e-input:focus,
  .e-float-input.e-control-wrapper.e-bigger.e-small textarea:focus,
  .e-float-input.e-bigger.e-small textarea:focus {
    @if ($input-skin-name == 'tailwind' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3') {
      box-shadow: $input-group-focus-box-shadow;
    }
  }

  .e-bigger textarea.e-input.e-small.e-rtl#{$css},
  .e-input-group:not(.e-outline).e-small.e-bigger.e-rtl textarea.e-input,
  .e-input-group:not(.e-outline).e-control-wrapper.e-small.e-bigger.e-rtl textarea.e-input,
  .e-input-group:not(.e-outline).e-small.e-rtl textarea.e-input.e-bigger,
  .e-input-group:not(.e-outline).e-control-wrapper.e-small.e-rtl textarea.e-input.e-bigger,
  .e-bigger .e-input-group:not(.e-outline).e-small.e-rtl textarea.e-input,
  .e-bigger .e-input-group:not(.e-outline).e-control-wrapper.e-small.e-rtl textarea.e-input,
  .e-float-input:not(.e-outline).e-small.e-bigger.e-rtl textarea,
  .e-float-input:not(.e-outline).e-small.e-rtl textarea.e-bigger,
  .e-bigger .e-float-input:not(.e-outline).e-small.e-rtl textarea,
  .e-float-input:not(.e-outline).e-control-wrapper.e-small.e-bigger.e-rtl textarea,
  .e-float-input:not(.e-outline).e-control-wrapper.e-small.e-rtl textarea.e-bigger,
  .e-bigger .e-float-input:not(.e-outline).e-control-wrapper.e-small.e-rtl textarea,
  .e-bigger.e-rtl textarea.e-input.e-small#{$css},
  .e-rtl .e-input-group:not(.e-outline).e-small.e-bigger textarea.e-input,
  .e-rtl .e-input-group:not(.e-outline).e-control-wrapper.e-small.e-bigger textarea.e-input,
  .e-rtl .e-input-group:not(.e-outline).e-small textarea.e-input.e-bigger,
  .e-rtl .e-input-group:not(.e-outline).e-control-wrapper.e-small textarea.e-input.e-bigger,
  .e-bigger.e-rtl .e-input-group:not(.e-outline).e-small textarea.e-input,
  .e-bigger.e-rtl .e-input-group:not(.e-outline).e-control-wrapper.e-small textarea.e-input,
  .e-rtl .e-float-input:not(.e-outline).e-control-wrapper.e-small.e-bigger textarea,
  .e-rtl .e-float-input:not(.e-outline).e-small textarea.e-bigger,
  .e-bigger.e-rtl .e-float-input:not(.e-outline).e-control-wrapper.e-small textarea,
  .e-rtl .e-float-input:not(.e-outline).e-control-wrapper.e-small.e-bigger textarea,
  .e-rtl .e-float-input:not(.e-outline).e-small textarea.e-bigger,
  .e-bigger.e-rtl .e-float-input:not(.e-outline).e-small textarea,
  .e-bigger.e-rtl .e-float-input:not(.e-outline).e-small.e-control-wrapper textarea,
  .e-bigger .e-input-group:not(.e-outline).e-small.e-rtl textarea.e-input:focus,
  .e-bigger .e-input-group:not(.e-outline).e-control-wrapper.e-small.e-rtl textarea.e-input:focus,
  .e-bigger .e-float-input:not(.e-outline).e-control-wrapper.e-small.e-rtl textarea:focus,
  .e-bigger .e-float-input:not(.e-outline).e-small.e-rtl textarea:focus,
  .e-small .e-input-group:not(.e-outline).e-bigger.e-rtl textarea.e-input:focus,
  .e-small .e-input-group:not(.e-outline).e-control-wrapper.e-bigger.e-rtl textarea.e-input:focus,
  .e-small .e-float-input:not(.e-outline).e-control-wrapper.e-bigger.e-rtl textarea:focus,
  .e-small .e-float-input:not(.e-outline).e-bigger.e-rtl textarea:focus,
  .e-input-group:not(.e-outline).e-bigger.e-small.e-rtl textarea.e-input:focus,
  .e-input-group:not(.e-outline).e-control-wrapper.e-bigger.e-small.e-rtl textarea.e-input:focus,
  .e-float-input:not(.e-outline).e-control-wrapper.e-bigger.e-small.e-rtl textarea:focus,
  .e-float-input:not(.e-outline).e-bigger.e-small.e-rtl textarea:focus,
  .e-bigger.e-rtl .e-input-group:not(.e-outline).e-small textarea.e-input:focus,
  .e-bigger.e-rtl .e-input-group:not(.e-outline).e-control-wrapper.e-small textarea.e-input:focus,
  .e-bigger.e-rtl .e-float-input:not(.e-outline).e-control-wrapper.e-small textarea:focus,
  .e-bigger.e-rtl .e-float-input:not(.e-outline).e-small textarea:focus,
  .e-small.e-rtl .e-input-group:not(.e-outline).e-bigger textarea.e-input:focus,
  .e-small.e-rtl .e-input-group:not(.e-outline).e-control-wrapper.e-bigger textarea.e-input:focus,
  .e-small.e-rtl .e-float-input:not(.e-outline).e-control-wrapper.e-bigger textarea:focus,
  .e-small.e-rtl .e-float-input:not(.e-outline).e-bigger textarea:focus,
  .e-rtl .e-input-group:not(.e-outline).e-bigger.e-small textarea.e-input:focus,
  .e-rtl .e-input-group:not(.e-outline).e-control-wrapper.e-bigger.e-small textarea.e-input:focus,
  .e-rtl .e-float-input:not(.e-outline).e-control-wrapper.e-bigger.e-small textarea:focus,
  .e-rtl .e-float-input:not(.e-outline).e-bigger.e-small textarea:focus {
    padding-right: $input-small-bigger-text-indent;
    text-indent: 0;
  }

  .e-float-input.e-small.e-bigger .e-clear-icon,
  .e-float-input.e-control-wrapper.e-small.e-bigger .e-clear-icon,
  .e-input-group.e-small.e-bigger .e-clear-icon,
  .e-input-group.e-control-wrapper.e-small.e-bigger .e-clear-icon,
  .e-small .e-input-group.e-bigger input:first-child ~ .e-clear-icon,
  .e-small .e-input-group.e-control-wrapper.e-bigger input:first-child ~ .e-clear-icon,
  .e-bigger .e-input-group.e-small input:first-child ~ .e-clear-icon,
  .e-bigger .e-input-group.e-control-wrapper.e-small input:first-child ~ .e-clear-icon,
  .e-small .e-float-input.e-control-wrapper.e-bigger input:first-child ~ .e-clear-icon,
  .e-small .e-float-input.e-bigger input:first-child ~ .e-clear-icon,
  .e-bigger .e-float-input.e-control-wrapper.e-small input:first-child ~ .e-clear-icon,
  .e-bigger .e-float-input.e-small input:first-child ~ .e-clear-icon {
    padding-bottom: $zero-value;
  }

  .e-float-input.e-bigger .e-clear-icon::before,
  .e-float-input.e-control-wrapper.e-bigger .e-clear-icon::before,
  .e-input-group.e-bigger .e-clear-icon::before,
  .e-input-group.e-control-wrapper.e-bigger .e-clear-icon::before,
  .e-float-input.e-control-wrapper input.e-bigger:first-child ~ .e-clear-icon::before,
  .e-bigger .e-float-input.e-control-wrapper .e-clear-icon::before,
  .e-float-input input.e-bigger:first-child ~ .e-clear-icon::before,
  .e-bigger .e-float-input .e-clear-icon::before,
  .e-bigger .e-input-group .e-clear-icon::before,
  .e-bigger .e-input-group.e-control-wrapper .e-clear-icon::before {
    font-size: $input-bigger-clear-icon;
  }

  .e-float-input.e-small.e-bigger .e-clear-icon::before,
  .e-float-input.e-control-wrapper.e-small.e-bigger .e-clear-icon::before,
  .e-input-group.e-small.e-bigger .e-clear-icon::before,
  .e-input-group.e-control-wrapper.e-small.e-bigger .e-clear-icon::before,
  .e-small.e-bigger .e-float-input .e-clear-icon::before,
  .e-small.e-bigger .e-float-input.e-control-wrapper .e-clear-icon::before,
  .e-small.e-bigger .e-input-group .e-clear-icon::before,
  .e-small.e-bigger .e-input-group.e-control-wrapper .e-clear-icon::before,
  .e-small .e-input-group.e-bigger input:first-child ~ .e-clear-icon::before,
  .e-small .e-input-group.e-control-wrapper.e-bigger input:first-child ~ .e-clear-icon::before,
  .e-bigger .e-input-group.e-small input:first-child ~ .e-clear-icon::before,
  .e-bigger .e-input-group.e-control-wrapper.e-small input:first-child ~ .e-clear-icon::before,
  .e-small .e-float-input.e-control-wrapper.e-bigger input:first-child ~ .e-clear-icon::before,
  .e-small .e-float-input.e-bigger input:first-child ~ .e-clear-icon::before,
  .e-bigger .e-float-input.e-control-wrapper.e-small input:first-child ~ .e-clear-icon::before,
  .e-bigger .e-float-input.e-small input:first-child ~ .e-clear-icon::before {
    font-size: $input-bigger-small-clear-icon;
  }

  .e-input-group.e-bigger .e-clear-icon,
  .e-input-group.e-control-wrapper.e-bigger .e-clear-icon,
  .e-input-group .e-clear-icon.e-bigger,
  .e-input-group.e-control-wrapper .e-clear-icon.e-bigger,
  .e-bigger .e-input-group .e-clear-icon,
  .e-bigger .e-input-group.e-control-wrapper .e-clear-icon {
    min-width: $input-bigger-clear-icon-min-width;
    @if $input-skin-name != 'Material3' and $input-skin-name != 'fluent2' {
      min-height: $input-bigger-clear-icon-min-height;
      padding-bottom: $input-clear-icon-bigger-padding-bottom;
      padding-right: $input-clear-icon-bigger-padding-right;
      padding-top: $input-clear-icon-bigger-padding-top;
    }
    @else if $input-skin-name == 'fluent2' {
      padding: $input-bigger-clear-icon-padding;
    }
    @else {
      min-height: $input-bigger-clear-icon-min-height;
      padding: $input-clear-icon-padding;
      margin: $input-clear-icon-margin;
      border-radius: $input-bigger-clear-icon-border-radius;
    }
  }

  .e-input-group.e-float-input.e-bigger .e-clear-icon,
  .e-input-group.e-float-input .e-clear-icon.e-bigger,
  .e-bigger .e-input-group.e-float-input .e-clear-icon,
  .e-input-group.e-control-wrapper.e-float-input.e-bigger .e-clear-icon,
  .e-input-group.e-control-wrapper.e-float-input .e-clear-icon.e-bigger,
  .e-bigger .e-input-group.e-control-wrapper.e-float-input .e-clear-icon {
    @if $input-skin-name != 'Material3' and $input-skin-name != 'fluent2' {
      padding-right: $input-clear-icon-bigger-padding-right;
      padding-top: $float-input-clear-icon-bigger-padding-top;
    }
  }

  .e-small.e-bigger .e-input-group .e-clear-icon,
  .e-small.e-bigger .e-input-group.e-control-wrapper .e-clear-icon {
    @if $input-skin-name != 'Material3' and $input-skin-name != 'fluent2' {
      min-height: $input-bigger-clear-icon-min-height;
      min-width: $input-bigger-clear-icon-min-width;
      padding-bottom: $input-clear-icon-bigger-padding-bottom;
      padding-right: $input-clear-icon-bigger-padding-right;
      padding-top: $input-clear-icon-bigger-padding-top;
    }
    @else {
      min-height: $input-bigger-small-clear-icon-min-height;
      min-width: $input-bigger-small-clear-icon-min-width;
      padding: $input-clear-icon-padding;
      margin: $input-clear-icon-margin;
      border-radius: $input-bigger-clear-icon-border-radius;
    }
  }

  .e-input-group.e-small.e-bigger .e-clear-icon,
  .e-input-group.e-small .e-clear-icon.e-bigger,
  .e-bigger .e-input-group.e-small .e-clear-icon,
  .e-input-group.e-control-wrapper.e-small.e-bigger .e-clear-icon,
  .e-input-group.e-control-wrapper.e-small .e-clear-icon.e-bigger,
  .e-bigger .e-input-group.e-control-wrapper.e-small .e-clear-icon {
    min-height: $input-bigger-small-clear-icon-min-height;
    min-width: $input-bigger-small-clear-icon-min-width;
    @if $input-skin-name != 'Material3' and $input-skin-name != 'fluent2' {
      padding-bottom: $input-clear-icon-small-bigger-padding-bottom;
      padding-right: $input-clear-icon-small-bigger-padding-right;
      padding-top: $input-clear-icon-small-bigger-padding-top;
    }
  }

  .e-input-group.e-float-input.e-small.e-bigger .e-clear-icon,
  .e-input-group.e-float-input.e-small .e-clear-icon.e-bigger,
  .e-bigger .e-input-group.e-float-input.e-small .e-clear-icon,
  .e-input-group.e-control-wrapper.e-float-input.e-small.e-bigger .e-clear-icon,
  .e-input-group.e-control-wrapper.e-float-input.e-small .e-clear-icon.e-bigger,
  .e-bigger .e-input-group.e-control-wrapper.e-float-input.e-small .e-clear-icon,
  .e-input-group.e-float-input.e-control-wrapper.e-small.e-bigger .e-clear-icon,
  .e-input-group.e-float-input.e-control-wrapper.e-small .e-clear-icon.e-bigger,
  .e-bigger .e-input-group.e-float-input.e-control-wrapper.e-small .e-clear-icon,
  .e-input-group.e-control-wrapper.e-float-input.e-small.e-bigger .e-clear-icon,
  .e-input-group.e-control-wrapper.e-float-input.e-small .e-clear-icon.e-bigger,
  .e-bigger .e-input-group.e-control-wrapper.e-float-input.e-small .e-clear-icon {
    @if $input-skin-name != 'Material3' and $input-skin-name != 'fluent2' {
      padding-right: $input-clear-icon-small-bigger-padding-right;
      padding-top: $float-input-clear-icon-small-bigger-padding-top;
    }
  }

  .e-float-input.e-bigger textarea:focus ~ label.e-float-text,
  .e-float-input.e-bigger textarea:valid ~ label.e-float-text,
  .e-float-input.e-bigger textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input textarea:focus ~ label.e-float-text,
  .e-bigger .e-float-input textarea:valid ~ label.e-float-text,
  .e-bigger .e-float-input textarea ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger textarea:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger textarea:valid ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper textarea:focus ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper textarea:valid ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper textarea ~ label.e-label-top.e-float-text {
    font-size: $float-label-bigger-font-size;
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      top: -20px;
    }
    @else if $input-skin-name == 'fabric' or $input-skin-name == 'FluentUI' or $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' or $input-skin-name == 'highcontrast' {
      top: 14px;
    }
    @else if $input-skin-name == 'tailwind' {
      top: 12px;
    }
    @else if $input-skin-name == 'fluent2' {
      top: -28px;
    }
  }

  .e-float-input.e-small.e-bigger textarea:focus ~ label.e-float-text,
  .e-float-input.e-small.e-bigger textarea:valid ~ label.e-float-text,
  .e-float-input.e-small.e-bigger textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-small textarea:focus ~ label.e-float-text,
  .e-bigger .e-float-input.e-small textarea:valid ~ label.e-float-text,
  .e-bigger .e-float-input.e-small textarea ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger textarea:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger textarea:valid ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small textarea:focus ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small textarea:valid ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small textarea ~ label.e-label-top.e-float-text {
    font-size: $float-label-bigger-small-font-size;
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      top: -20px;
    }
    @else if $input-skin-name == 'fabric' or $input-skin-name == 'FluentUI' or $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' or $input-skin-name == 'highcontrast' {
      top: 16px;
    }
    @else if $input-skin-name == 'tailwind' {
      top: 15px;
    }
  }

  .e-float-input.e-bigger textarea ~ label.e-float-text,
  .e-float-input textarea ~ label.e-float-text.e-bigger,
  .e-float-input textarea.e-bigger ~ label.e-float-text,
  .e-bigger .e-float-input textarea ~ label.e-float-text,
  .e-float-input.e-bigger textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-bigger textarea ~ label.e-float-text,
  .e-float-input.e-control-wrapper textarea ~ label.e-float-text.e-bigger,
  .e-float-input.e-control-wrapper textarea.e-bigger ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper textarea ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    font-size: $float-placeholder-bigger-font-size;
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      top: -8px;
    }
    @else if $input-skin-name == 'fabric' or $input-skin-name == 'FluentUI' or $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' or $input-skin-name == 'highcontrast' {
      top: 18px;
    }
    @else if $input-skin-name == 'tailwind' {
      top: -11px;
    }
  }

  .e-input-group.e-bigger:not(.e-float-input) .e-input,
  .e-bigger .e-input-group:not(.e-float-input) .e-input,
  .e-input-group.e-control-wrapper.e-bigger:not(.e-float-input) .e-input,
  .e-bigger .e-input-group.e-control-wrapper:not(.e-float-input) .e-input,
  .e-float-input.e-bigger input,
  .e-bigger .e-float-input input,
  .e-float-input.e-input-group.e-bigger input,
  .e-bigger .e-float-input.e-input-group input,
  .e-float-input.e-input-group.e-control-wrapper.e-bigger input,
  .e-bigger .e-float-input.e-input-group.e-control-wrapper input,
  .e-float-input.e-control-wrapper.e-bigger input,
  .e-bigger .e-float-input.e-control-wrapper input,
  .e-float-input.e-control-wrapper.e-input-group.e-bigger input,
  .e-bigger .e-float-input.e-control-wrapper.e-input-group input,
  .e-float-input.e-control-wrapper.e-input-group.e-bigger input,
  .e-bigger .e-float-input.e-control-wrapper.e-input-group input {
    @if $input-skin-name == 'material' {
      min-height: 27px;
    }
    @if $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      min-height: 28px;
    }
  }

  .e-input-group.e-bigger.e-small:not(.e-float-input) .e-input,
  .e-bigger .e-input-group.e-small:not(.e-float-input) .e-input .e-small .e-input-group.e-bigger:not(.e-float-input) .e-input,
  .e-input-group.e-control-wrapper.e-bigger.e-small:not(.e-float-input) .e-input,
  .e-bigger .e-input-group.e-control-wrapper.e-small:not(.e-float-input) .e-input .e-small .e-input-group.e-control-wrapper.e-bigger:not(.e-float-input) .e-input,
  .e-float-input.e-bigger.e-small input,
  .e-bigger .e-float-input.e-small input,
  .e-small .e-float-input.e-bigger input,
  .e-float-input.e-input-group.e-bigger.e-small input,
  .e-bigger .e-float-input.e-input-group.e-small input,
  .e-small .e-float-input.e-input-group.e-bigger input,
  .e-float-input.e-input-group.e-control-wrapper.e-bigger.e-small input,
  .e-bigger .e-float-input.e-input-group.e-control-wrapper.e-small input,
  .e-small .e-float-input.e-input-group.e-control-wrapper.e-bigger input,
  .e-float-input.e-control-wrapper.e-bigger.e-small input,
  .e-bigger .e-float-input.e-control-wrapper.e-small input,
  .e-small .e-float-input.e-control-wrapper.e-bigger input,
  .e-float-input.e-control-wrapper.e-input-group.e-bigger.e-small input,
  .e-bigger .e-float-input.e-control-wrapper.e-input-group.e-small input,
  .e-small .e-float-input.e-control-wrapper.e-input-group.e-bigger input  {
    @if $input-skin-name == 'material' {
      min-height: 28px;
    }
    @if $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      min-height: 26px;
    }
  }

  .e-bigger .e-float-input:not(.e-outline) input:-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-float-input:not(.e-outline) input:-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-autofill:not(.e-outline) input:-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-float-input.e-control-wrapper.e-autofill:not(.e-outline) input:-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-autofill:not(.e-input-focus):not(.e-outline) input:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-float-input.e-control-wrapper.e-autofill:not(.e-input-focus):not(.e-outline) input:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom {
    font-size: $float-label-bigger-font-size;
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      top: -8px;
      transform: translate3d(0, -6px, 0) scale(.86);
    }
    @else if $input-skin-name == 'fluent2' {
      top: -14px;
      transform: translate3d(0, -6px, 0) scale(.86);
    }
    @else if $input-skin-name == 'fabric' or $input-skin-name == 'highcontrast' {
      font-style: $input-font-normal;
      padding-right: 0;
      top: 14px;
      transform: translate3d(-10px, -43px, 0) scale(1);
    }
    @else if $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' {
      font-weight: bold;
      padding-right: 0;
      top: 14px;
      transform: translate3d(-10px, -39px, 0) scale(1);
    }
    @else if ($input-skin-name == 'tailwind') {
      font-weight: 500;
      padding-right: 0;
      transform: translate3d(-10px, -40px, 0) scale(1);
    }
    @else if ($input-skin-name == 'FluentUI') {
      font-style: $input-font-normal;
      font-weight: 600;
      padding-right: 0;
      transform: translate3d(-10px, -45px, 0) scale(1);
    }
    user-select: text;
  }

  .e-bigger .e-float-input.e-small:not(.e-outline) input:-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-small .e-float-input:not(.e-outline) input:-webkit-autofill ~ label.e-float-text,
  .e-float-input.e-bigger.e-small:not(.e-outline) input:-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-float-input.e-small.e-control-wrapper.e-autofill:not(.e-outline) input:-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-small .e-float-input.e-control-wrapper.e-autofill:not(.e-outline) input:-webkit-autofill ~ label.e-float-text,
  .e-float-input.e-bigger.e-small.e-control-wrapper.e-autofill:not(.e-outline) input:-webkit-autofill ~ label.e-float-text,
  .e-small.e-bigger .e-float-input.e-control-wrapper.e-autofill:not(.e-input-focus):not(.e-outline) input:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-small.e-bigger.e-float-input.e-control-wrapper.e-autofill:not(.e-input-focus):not(.e-outline) input:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-small.e-float-input.e-control-wrapper.e-autofill:not(.e-input-focus):not(.e-outline) input:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-small .e-bigger.e-float-input.e-control-wrapper.e-autofill:not(.e-input-focus):not(.e-outline) input:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom {
    font-size: $float-label-bigger-small-font-size;
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      top: -10px;
      transform: translate3d(0, -6px, 0) scale(.92);
    }
    @else if $input-skin-name == 'fabric' or $input-skin-name == 'highcontrast' {
      font-style: $input-font-normal;
      padding-right: 0;
      top: 16px;
      transform: translate3d(-10px, -43px, 0) scale(1);
    }
    @else if $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' {
      font-weight: bold;
      padding-right: 0;
      top: 16px;
      transform: translate3d(-10px, -39px, 0) scale(1);
    }
    @else if ($input-skin-name == 'FluentUI') {
      font-style: $input-font-normal;
      font-weight: 600;
      padding-right: 0;
      top: 16px;
      transform: translate3d(-10px, -41px, 0) scale(1);
    }
    user-select: text;
  }

  .e-float-input.e-bigger textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input.e-bigger textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-bigger.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-float-input textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-bigger.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-bigger textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input.e-control-wrapper.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input.e-control-wrapper.e-bigger textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-bigger.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-float-input.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-bigger.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom  {
    @if $input-skin-name == 'fabric' or $input-skin-name == 'FluentUI' or $input-skin-name == 'bootstrap' or $input-skin-name == 'highcontrast' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' {
      @include textarea-float-label-alignment;
      font-style: $input-font-style;
    }
  }

  .e-bigger .e-float-input.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input.e-bigger textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-bigger.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-float-input textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-bigger.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input.e-control-wrapper.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input.e-control-wrapper.e-bigger textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-bigger.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-float-input.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-bigger.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    font-size: $float-placeholder-bigger-small-font-size;
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      top: -10px;
    }
    font-style: $input-font-style;
  }

  .e-float-input.e-bigger.e-small textarea:focus ~ label.e-float-text,
  .e-float-input.e-bigger.e-small textarea:valid ~ label.e-float-text,
  .e-float-input.e-bigger.e-small textarea ~ label.e-label-top.e-float-text,
  .e-float-input.e-bigger.e-small textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-bigger.e-small textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-bigger.e-small textarea label.e-float-text.e-label-top,
  .e-bigger .e-float-input.e-small textarea:focus ~ label.e-float-text,
  .e-bigger .e-float-input.e-small textarea:valid ~ label.e-float-text,
  .e-bigger .e-float-input.e-small textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-small textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-small textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-small textarea label.e-float-text.e-label-top,
  .e-small .e-float-input.e-bigger textarea:focus ~ label.e-float-text,
  .e-small .e-float-input.e-bigger textarea:valid ~ label.e-float-text,
  .e-small .e-float-input.e-bigger textarea ~ label.e-label-top.e-float-text,
  .e-small .e-float-input.e-bigger textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-small .e-float-input.e-bigger textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-small .e-float-input.e-bigger textarea label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper.e-bigger.e-small textarea:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger.e-small textarea:valid ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger.e-small textarea ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger.e-small textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger.e-small textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger.e-small textarea label.e-float-text.e-label-top,
  .e-bigger .e-float-input.e-control-wrapper.e-small textarea:focus ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small textarea:valid ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small textarea label.e-float-text.e-label-top,
  .e-small .e-float-input.e-control-wrapper.e-bigger textarea:focus ~ label.e-float-text,
  .e-small .e-float-input.e-control-wrapper.e-bigger textarea:valid ~ label.e-float-text,
  .e-small .e-float-input.e-control-wrapper.e-bigger textarea ~ label.e-label-top.e-float-text,
  .e-small .e-float-input.e-control-wrapper.e-bigger textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-small .e-float-input.e-control-wrapper.e-bigger textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-small .e-float-input.e-control-wrapper.e-bigger textarea label.e-float-text.e-label-top {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      transform: translate3d(0, 6px, 0) scale(.92);
    }
    @else if $input-skin-name == 'fabric' or $input-skin-name == 'highcontrast' {
      font-style: $input-font-normal;
      padding-right: 0;
      transform: translate3d(-10px, -43px, 0) scale(1);
    }
    @else if $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' {
      padding-right: 0;
      transform: translate3d(-10px, -37px, 0) scale(1);
    }
    @else if ($input-skin-name == 'FluentUI') {
      font-style: $input-font-normal;
      padding-right: 0;
      transform: translate3d(-10px, -41px, 0) scale(1);
    }
  }

  .e-bigger .e-float-input.e-small textarea:-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-small .e-float-input textarea:-webkit-autofill ~ label.e-float-text,
  .e-float-input.e-bigger.e-small textarea:-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-float-input.e-small.e-control-wrapper.e-autofill textarea:-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-small .e-float-input.e-control-wrapper.e-autofill textarea:-webkit-autofill ~ label.e-float-text,
  .e-float-input.e-bigger.e-small.e-control-wrapper.e-autofill textarea:-webkit-autofill ~ label.e-float-text,
  .e-small.e-bigger .e-float-input.e-control-wrapper.e-autofill textarea:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-small.e-bigger.e-float-input.e-control-wrapper.e-autofill textarea:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-small.e-float-input.e-control-wrapper.e-autofill textarea:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-small .e-bigger.e-float-input.e-control-wrapper.e-autofill textarea:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom {
    font-size: $float-label-bigger-small-font-size;
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      top: -10px;
      transform: translate3d(0, -6px, 0) scale(.92);
    }
    @else if $input-skin-name == 'fabric' or $input-skin-name == 'highcontrast' {
      font-style: $input-font-normal;
      padding-right: 0;
      top: 16px;
      transform: translate3d(-10px, -43px, 0) scale(1);
    }

    @else if $input-skin-name == 'bootstrap' {
      font-weight: $input-float-font-weight;
      padding-right: 0;
      top: 16px;
      transform: translate3d(-10px, -39px, 0) scale(1);
    }
    @else if $input-skin-name == 'FluentUI' {
      font-style: $input-font-normal;
      font-weight: 600;
      padding-right: 0;
      top: 16px;
      transform: translate3d(-10px, -41px, 0) scale(1);
    }
    user-select: text;
  }

  .e-bigger .e-float-input textarea:-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-float-input textarea:-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-autofill textarea:-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-float-input.e-control-wrapper.e-autofill textarea:-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-autofill textarea:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-float-input.e-control-wrapper.e-autofill textarea:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom {
    font-size: $float-label-bigger-font-size;
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      top: -8px;
      transform: translate3d(0, -6px, 0) scale(.86);
    }
    @else if $input-skin-name == 'fabric' or $input-skin-name == 'highcontrast' {
      font-style: $input-font-normal;
      padding-right: 0;
      top: 14px;
      transform: translate3d(-10px, -43px, 0) scale(1);
    }
    @else if $input-skin-name == 'bootstrap' {
      font-weight: $input-float-font-weight;
      padding-right: 0;
      top: 14px;
      transform: translate3d(-10px, -39px, 0) scale(1);
    }
    @else if $input-skin-name == 'FluentUI' {
      font-style: $input-font-normal;
      font-weight: 600;
      padding-right: 0;
      top: 14px;
      transform: translate3d(-10px, -45px, 0) scale(1);
    }
    user-select: text;
  }

  .e-float-input.e-bigger textarea:focus ~ label.e-float-text,
  .e-float-input.e-bigger textarea:valid ~ label.e-float-text,
  .e-float-input.e-bigger textarea ~ label.e-label-top.e-float-text,
  .e-float-input.e-bigger textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-bigger textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-bigger textarea label.e-float-text.e-label-top,
  .e-bigger .e-float-input textarea:focus ~ label.e-float-text,
  .e-bigger .e-float-input textarea:valid ~ label.e-float-text,
  .e-bigger .e-float-input textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input textarea label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper.e-bigger textarea:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger textarea:valid ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger textarea ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger textarea label.e-float-text.e-label-top,
  .e-bigger .e-float-input.e-control-wrapper textarea:focus ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper textarea:valid ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper textarea label.e-float-text.e-label-top {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      transform: translate3d(0, 6px, 0) scale(.86);
    }
    @else if $input-skin-name == 'fabric' or $input-skin-name == 'highcontrast' {
      font-style: $input-font-normal;
      padding-right: 0;
      transform: translate3d(-10px, -43px, 0) scale(1);
    }
    @else if $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' {
      padding-right: 0;
      transform: translate3d(-10px, -39px, 0) scale(1);
    }
    @else if ($input-skin-name == 'tailwind') {
      font-weight: 500;
      padding-right: 0;
      transform: translate3d(-10px, -35px, 0) scale(1);
    }
    @else if ($input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3') {
      font-weight: 500;
      padding-right: 0;
      transform: translate3d(-10px, -39px, 0) scale(1);
    }
    @else if ($input-skin-name == 'FluentUI') {
      font-style: $input-font-normal;
      font-weight: 600;
      padding-right: 0;
      transform: translate3d(-10px, -45px, 0) scale(1);
    }
  }

  .e-float-input.e-small.e-bigger textarea:focus ~ label.e-float-text,
  .e-float-input.e-small.e-bigger textarea:valid ~ label.e-float-text,
  .e-float-input.e-small.e-bigger textarea ~ label.e-label-top.e-float-text,
  .e-float-input.e-small.e-bigger textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-small.e-bigger textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-small.e-bigger textarea label.e-float-text.e-label-top,
  .e-bigger.e-small .e-float-input textarea:focus ~ label.e-float-text,
  .e-bigger.e-small .e-float-input textarea:valid ~ label.e-float-text,
  .e-bigger.e-small .e-float-input textarea ~ label.e-label-top.e-float-text,
  .e-bigger.e-small .e-float-input textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger.e-small .e-float-input textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger.e-small .e-float-input textarea label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper.e-bigger.e-small textarea:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger.e-small textarea:valid ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger.e-small textarea ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger.e-small textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger.e-small textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger.e-small textarea label.e-float-text.e-label-top,
  .e-small.e-bigger .e-float-input.e-control-wrapper textarea:focus ~ label.e-float-text,
  .e-small.e-bigger .e-float-input.e-control-wrapper textarea:valid ~ label.e-float-text,
  .e-small.e-bigger .e-float-input.e-control-wrapper textarea ~ label.e-label-top.e-float-text,
  .e-small.e-bigger .e-float-input.e-control-wrapper textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-small.e-bigger .e-float-input.e-control-wrapper textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-small.e-bigger .e-float-input.e-control-wrapper textarea label.e-float-text.e-label-top {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      transform: translate3d(0, 6px, 0) scale(.86);
    }
    @else if $input-skin-name == 'fabric' or $input-skin-name == 'highcontrast' {
      font-style: $input-font-normal;
      padding-right: 0;
      transform: translate3d(-10px, -43px, 0) scale(1);
    }
    @else if $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' {
      padding-right: 0;
      transform: translate3d(-10px, -39px, 0) scale(1);
    }
    @else if ($input-skin-name == 'tailwind') {
      font-weight: 500;
      padding-right: 0;
      transform: translate3d(-10px, -33px, 0) scale(1);
    }
    @else if ($input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3') {
      font-weight: 500;
      padding-right: 0;
      transform: translate3d(-10px, -36px, 0) scale(1);
    }
    @else if ($input-skin-name == 'FluentUI') {
      font-style: $input-font-normal;
      font-weight: 600;
      padding-right: 0;
      transform: translate3d(-10px, -41px, 0) scale(1);
    }
  }

  .e-input-group.e-bigger textarea,
  .e-input-group.e-bigger textarea.e-input,
  .e-input-group textarea.e-bigger,
  .e-input-group textarea.e-input.e-bigger,
  .e-input-group.e-control-wrapper.e-bigger textarea,
  .e-input-group.e-control-wrapper.e-bigger textarea.e-input,
  .e-input-group.e-control-wrapper textarea.e-bigger,
  .e-input-group.e-control-wrapper textarea.e-input.e-bigger,
  .e-bigger .e-input-group textarea,
  .e-bigger .e-input-group textarea.e-input,
  .e-bigger .e-input-group.e-control-wrapper textarea,
  .e-bigger .e-input-group.e-control-wrapper textarea.e-input,
  .e-float-input.e-bigger textarea,
  .e-float-input textarea.e-bigger,
  .e-float-input.e-control-wrapper.e-bigger textarea,
  .e-float-input.e-control-wrapper textarea.e-bigger,
  .e-bigger .e-float-input textarea,
  .e-bigger .e-float-input.e-control-wrapper textarea {
    font: inherit;
    line-height: 1.2;
    min-height: $textarea-bigger-min-height;
    padding: $textarea-bigger-padding;
  }

  .e-input-group.e-input-focus.e-bigger textarea,
  .e-input-group.e-input-focus.e-bigger textarea.e-input,
  .e-input-group.e-input-focus textarea.e-bigger,
  .e-input-group.e-input-focus textarea.e-input.e-bigger,
  .e-input-group.e-control-wrapper.e-input-focus.e-bigger textarea,
  .e-input-group.e-control-wrapper.e-input-focus.e-bigger textarea.e-input,
  .e-input-group.e-control-wrapper.e-input-focus textarea.e-bigger,
  .e-input-group.e-control-wrapper.e-input-focus textarea.e-input.e-bigger,
  .e-bigger .e-input-group.e-input-focus textarea,
  .e-bigger .e-input-group.e-control-wrapper.e-input-focus textarea,
  .e-bigger .e-input-group.e-control-wrapper.e-input-focus textarea.e-input,
  .e-input-group.e-control-wrapper textarea.e-bigger:focus,
  .e-input-group.e-control-wrapper textarea.e-input.e-bigger:focus,
  .e-bigger .e-input-group.e-control-wrapper textarea:focus,
  .e-bigger .e-input-group.e-control-wrapper.e-input-focus textarea:focus,
  .e-bigger .e-input-group.e-control-wrapper textarea.e-input:focus {
    font: inherit;
    line-height: 1.2;
    min-height: $textarea-bigger-min-height;
    padding: $textarea-bigger-padding;
  }

  .e-input-group.e-small.e-bigger textarea.e-input,
  .e-input-group.e-small textarea.e-input.e-bigger,
  .e-input-group textarea.e-input.e-bigger.e-small,
  .e-input-group.e-bigger textarea.e-small,
  .e-input-group.e-control-wrapper.e-small.e-bigger textarea.e-input,
  .e-input-group.e-control-wrapper.e-small textarea.e-input.e-bigger,
  .e-bigger.e-small .e-input-group textarea.e-input,
  .e-bigger.e-small .e-input-group.e-control-wrapper textarea.e-input,
  .e-bigger .input-group textarea.e-small,
  .e-bigger .e-input-group.e-small textarea.e-input,
  .e-bigger .e-input-group.e-control-wrapper.e-small textarea.e-input,
  .e-bigger .e-small.e-input-group.e-control-wrapper textarea.e-input,
  .e-small .input-group textarea.e-bigger,
  .e-small .e-input-group.e-bigger textarea.e-input,
  .e-small .e-input-group.e-control-wrapper textarea.e-input.e-bigger,
  .e-small .e-input-group.e-control-wrapper.e-input-focus textarea.e-input.e-bigger,
  .e-float-input textarea.e-bigger.e-small,
  .e-float-input.e-small.e-bigger textarea,
  .e-float-input.e-small textarea.e-bigger,
  .e-float-input.e-bigger textarea.e-small,
  .e-bigger .e-float-input.e-small textarea,
  .e-bigger.e-small .e-float-input textarea,
  .e-bigger .float-input textarea.e-small,
  .e-small .e-float-input.e-bigger textarea,
  .e-small .float-input textarea.e-bigger,
  .e-small .e-float-input.e-control-wrapper textarea.e-bigger,
  .e-float-input.e-control-wrapper.e-small.e-bigger textarea,
  .e-float-input.e-control-wrapper.e-small textarea.e-bigger,
  .e-bigger .e-float-input.e-control-wrapper.e-small textarea,
  .e-bigger.e-small .e-float-input.e-control-wrapper textarea,
  .e-bigger .e-small.e-float-input.e-control-wrapper textarea {
    font: inherit;
    line-height: 1.2;
    min-height: $textarea-bigger-small-min-height;
    padding: $textarea-bigger-small-padding;
  }

  .e-input-group.e-input-focus textarea.e-input.e-bigger.e-small,
  .e-input-group.e-input-focus.e-small.e-bigger textarea.e-input,
  .e-input-group.e-input-focus.e-small textarea.e-input.e-bigger,
  .e-input-group.e-input-focus.e-bigger textarea.e-small,
  .e-input-group.e-control-wrapper.e-input-focus.e-small.e-bigger textarea.e-input,
  .e-input-group.e-control-wrapper.e-input-focus.e-small textarea.e-input.e-bigger,
  .e-bigger.e-small .e-input-group.e-input-focus textarea.e-input,
  .e-bigger .e-input-group.e-control-wrapper.e-input-focus.e-small textarea.e-input,
  .e-bigger.e-small .e-input-group.e-control-wrapper.e-input-focus textarea.e-input,
  .e-bigger .e-small.e-input-group.e-control-wrapper.e-input-focus textarea.e-input,
  .e-small .e-input-group.e-control-wrapper.e-input-focus.e-bigger textarea.e-input {
    font: inherit;
    line-height: 1.2;
    min-height: $textarea-bigger-small-min-height;
    padding: $textarea-bigger-small-padding;
  }

  .e-bigger input.e-input,
  .e-bigger textarea.e-input,
  input.e-input.e-bigger,
  textarea.e-input.e-bigger {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      min-height: $input-bigger-min-height;
    }
  }

  .e-bigger input.e-input.e-small,
  .e-bigger .e-small input.e-input,
  .e-bigger.e-small input.e-input,
  .e-small input.e-input.e-bigger,
  input.e-input.e-bigger.e-small,
  .e-small .e-bigger input.e-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      min-height: $input-bigger-smaller-min-height;
    }
  }

  input.e-input.e-bigger.e-outline,
  textarea.e-input.e-bigger.e-outline,
  .e-outline.e-bigger input.e-input,
  .e-outline.e-bigger textarea.e-input,
  .e-outline.e-input-group.e-bigger,
  .e-bigger .e-input-group.e-outline,
  .e-outline.e-input-group.e-control-wrapper.e-bigger,
  .e-bigger .e-outline.e-input-group.e-control-wrapper,
  .e-outline.e-input-group.e-bigger.e-disabled,
  .e-bigger .e-outline.e-input-group.e-disabled,
  .e-outline.e-input-group.e-control-wrapper.e-bigger.e-disabled,
  .e-bigger .e-outline.e-input-group.e-control-wrapper.e-disabled,
  .e-outline.e-input-group.e-bigger,
  .e-bigger .e-outline.e-input-group,
  .e-outline.e-input-group.e-control-wrapper.e-bigger,
  .e-bigger .e-outline.e-input-group.e-control-wrapper,
  .e-outline.e-float-input.e-bigger,
  .e-bigger .e-outline.e-float-input,
  .e-outline.e-float-input.e-control-wrapper.e-bigger,
  .e-bigger .e-outline.e-float-input.e-control-wrapper {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      font-size: $outline-bigger-input-font-size;
    }
  }

  .e-outline.e-input-group.e-control-wrapper.e-bigger .e-input-group-icon,
  .e-bigger .e-outline.e-input-group.e-control-wrapper .e-input-group-icon,
  .e-outline.e-input-group.e-bigger.e-disabled .e-input-group-icon,
  .e-bigger .e-outline.e-input-group.e-disabled .e-input-group-icon,
  .e-outline.e-input-group.e-control-wrapper.e-bigger.e-disabled .e-input-group-icon,
  .e-bigger .e-outline.e-input-group.e-control-wrapper.e-disabled .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      font-size: $outline-bigger-input-icon-font-size;
    }
  }

  .e-outline.e-input-group.e-bigger .e-input-group-icon + .e-input-group-icon:last-child,
  .e-outline.e-input-group .e-input-group-icon.e-bigger + .e-input-group-icon.e-bigger:last-child,
  .e-bigger .e-outline.e-input-group .e-input-group-icon + .e-input-group-icon:last-child,
  .e-outline.e-input-group.e-small.e-bigger .e-input-group-icon + .e-input-group-icon:last-child,
  .e-outline.e-input-group.e-small .e-input-group-icon.e-bigger + .e-input-group-icon.e-bigger:last-child,
  .e-outline.e-input-group.e-control-wrapper.e-bigger .e-input-group-icon + .e-input-group-icon:last-child,
  .e-outline.e-input-group.e-control-wrapper .e-input-group-icon.e-bigger + .e-input-group-icon.e-bigger:last-child,
  e-outline.e-input-group.e-control-wrapper.e-small.e-bigger .e-input-group-icon + .e-input-group-icon:last-child,
  .e-outline.e-input-group.e-control-wrapper.e-small .e-input-group-icon.e-bigger + .e-input-group-icon.e-bigger:last-child,
  .e-bigger .e-outline.e-input-group.e-control-wrapper.e-small .e-input-group-icon + .e-input-group-icon:last-child,
  .e-bigger .e-outline.e-input-group.e-small .e-input-group-icon + .e-input-group-icon:last-child {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      margin-left: $zero-value;
    }
  }

  .e-outline.e-float-input.e-bigger input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-bigger input:valid ~ label.e-float-text,
  .e-outline.e-float-input.e-bigger input ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input input ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-bigger input[readonly] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-bigger input[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger input:valid ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger input ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper input ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger input[readonly] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger input[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-bigger textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-bigger textarea:valid ~ label.e-float-text,
  .e-outline.e-float-input.e-bigger textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input textarea ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-bigger textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-bigger textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger textarea:valid ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper textarea ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-bigger.e-input-focus input ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-input-focus input ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      font-size: $outline-bigger-label-font-size;
    }
  }

  .e-outline.e-input-group.e-small.e-bigger,
  .e-small .e-outline.e-input-group.e-bigger,
  .e-bigger .e-outline.e-input-group.e-small,
  .e-bigger.e-small .e-outline.e-input-group,
  .e-outline.e-input-group.e-control-wrapper.e-small.e-bigger,
  .e-small .e-outline.e-input-group.e-control-wrapper.e-bigger,
  .e-bigger .e-outline.e-input-group.e-control-wrapper.e-small,
  .e-bigger.e-small .e-outline.e-input-group.e-control-wrapper,
  .e-outline.e-float-input.e-small.e-bigger,
  .e-small .e-outline.e-float-input.e-bigger,
  .e-bigger .e-outline.e-float-input.e-small,
  .e-bigger.e-small .e-outline.e-float-input,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small,
  .e-bigger.e-small .e-outline.e-float-input.e-control-wrapper {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      font-size: $outline-small-bigger-input-font-size;
    }
  }

  .e-small.e-outline.e-float-input.e-bigger input:focus ~ label.e-float-text,
  .e-small.e-outline.e-float-input.e-bigger input:valid ~ label.e-float-text,
  .e-small.e-outline.e-float-input.e-bigger input ~ label.e-label-top.e-float-text,
  .e-bigger .e-small.e-outline.e-float-input input ~ label.e-label-top.e-float-text,
  .e-small.e-outline.e-float-input.e-bigger input[readonly] ~ label.e-label-top.e-float-text,
  .e-small.e-outline.e-float-input.e-bigger input[disabled] ~ label.e-label-top.e-float-text,
  .e-small.e-outline.e-float-input.e-control-wrapper.e-bigger input:focus ~ label.e-float-text,
  .e-small.e-outline.e-float-input.e-control-wrapper.e-bigger input:valid ~ label.e-float-text,
  .e-small.e-outline.e-float-input.e-control-wrapper.e-bigger input ~ label.e-label-top.e-float-text,
  .e-bigger .e-small.e-outline.e-float-input.e-control-wrapper input ~ label.e-label-top.e-float-text,
  .e-small.e-outline.e-float-input.e-control-wrapper.e-bigger input[readonly] ~ label.e-label-top.e-float-text,
  .e-small.e-outline.e-float-input.e-control-wrapper.e-bigger input[disabled] ~ label.e-label-top.e-float-text,
  .e-small .e-outline.e-float-input.e-bigger input:focus ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-bigger input:valid ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-bigger input ~ label.e-label-top.e-float-text,
  .e-small .e-bigger.e-outline.e-float-input input ~ label.e-label-top.e-float-text,
  .e-small .e-outline.e-float-input.e-bigger input[readonly] ~ label.e-label-top.e-float-text,
  .e-small .e-outline.e-float-input.e-bigger input[disabled] ~ label.e-label-top.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger input:focus ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger input:valid ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger input ~ label.e-label-top.e-float-text,
  .e-small .e-bigger.e-outline.e-float-input.e-control-wrapper input ~ label.e-label-top.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger input[readonly] ~ label.e-label-top.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-bigger input:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-bigger input:valid ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-bigger input ~ label.e-label-top.e-float-text,
  .e-bigger .e-small.e-outline.e-float-input input ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-bigger input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-bigger input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-bigger input:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-bigger input:valid ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-bigger input ~ label.e-label-top.e-float-text,
  .e-bigger .e-small.e-outline.e-float-input.e-control-wrapper input ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-bigger input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-bigger input[disabled] ~ label.e-label-top.e-float-text,
  .e-small.e-outline.e-float-input.e-bigger textarea:focus ~ label.e-float-text,
  .e-small.e-outline.e-float-input.e-bigger textarea:valid ~ label.e-float-text,
  .e-small.e-outline.e-float-input.e-bigger textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-small.e-outline.e-float-input textarea ~ label.e-label-top.e-float-text,
  .e-small.e-outline.e-float-input.e-bigger textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-small.e-outline.e-float-input.e-bigger textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-small.e-outline.e-float-input.e-control-wrapper.e-bigger textarea:focus ~ label.e-float-text,
  .e-small.e-outline.e-float-input.e-control-wrapper.e-bigger textarea:valid ~ label.e-float-text,
  .e-small.e-outline.e-float-input.e-control-wrapper.e-bigger textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-small.e-outline.e-float-input.e-control-wrapper textarea ~ label.e-label-top.e-float-text,
  .e-small.e-outline.e-float-input.e-control-wrapper.e-bigger textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-small.e-outline.e-float-input.e-control-wrapper.e-bigger textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-small .e-outline.e-float-input.e-bigger textarea:focus ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-bigger textarea:valid ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-bigger textarea ~ label.e-label-top.e-float-text,
  .e-small .e-bigger.e-outline.e-float-input textarea ~ label.e-label-top.e-float-text,
  .e-small .e-outline.e-float-input.e-bigger textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-small .e-outline.e-float-input.e-bigger textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger textarea:focus ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger textarea:valid ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger textarea ~ label.e-label-top.e-float-text,
  .e-small .e-bigger.e-outline.e-float-input.e-control-wrapper textarea ~ label.e-label-top.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-bigger textarea:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-bigger textarea:valid ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-bigger textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-small.e-outline.e-float-input textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-bigger textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-bigger textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-bigger textarea:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-bigger textarea:valid ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-bigger textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-small.e-outline.e-float-input.e-control-wrapper textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-bigger textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-bigger textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-small.e-outline.e-float-input.e-bigger.e-input-focus input ~ label.e-float-text,
  .e-small.e-outline.e-float-input.e-control-wrapper.e-bigger.e-input-focus input ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-bigger.e-input-focus input ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-bigger.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-bigger.e-input-focus input ~ label.e-float-text,
  .e-outline.e-float-input.e-small.e-bigger input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-small.e-bigger input:valid ~ label.e-float-text,
  .e-outline.e-float-input.e-small.e-bigger input ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-small.e-bigger input[readonly] ~ label.e-float-text,
  .e-outline.e-float-input.e-small.e-bigger input[disabled] ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small input:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small input:valid ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small input ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small input[readonly] ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small input[disabled] ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger input:valid ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger input ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger input[readonly] ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger input[disabled] ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small input:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small input:valid ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small input ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small input[readonly] ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small input[disabled] ~ label.e-float-text,
  .e-outline.e-float-input.e-small.e-bigger textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-small.e-bigger textarea:valid ~ label.e-float-text,
  .e-outline.e-float-input.e-small.e-bigger textarea ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-small.e-bigger textarea[readonly] ~ label.e-float-text,
  .e-outline.e-float-input.e-small.e-bigger textarea[disabled] ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small textarea:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small textarea:valid ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small textarea[readonly] ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small textarea[disabled] ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger textarea:valid ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger textarea ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger textarea[readonly] ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger textarea[disabled] ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small textarea:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small textarea:valid ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small textarea[readonly] ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small textarea[disabled] ~ label.e-float-text,
  .e-outline.e-float-input.e-small.e-bigger.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small.e-input-focus input ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small.e-input-focus input ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      font-size: $outline-small-bigger-label-font-size;
    }
  }

  .e-outline.e-input-group.e-bigger:not(.e-float-input) .e-input,
  .e-bigger .e-outline.e-input-group:not(.e-float-input) .e-input,
  .e-outline.e-input-group.e-control-wrapper.e-bigger:not(.e-float-input) .e-input,
  .e-bigger .e-outline.e-input-group.e-control-wrapper:not(.e-float-input) .e-input,
  .e-outline.e-float-input.e-bigger input,
  .e-bigger .e-outline.e-float-input input,
  .e-outline.e-float-input.e-input-group.e-bigger input,
  .e-bigger .e-outline.e-float-input.e-input-group input,
  .e-outline.e-float-input.e-input-group.e-control-wrapper.e-bigger input,
  .e-bigger .e-outline.e-float-input.e-input-group.e-control-wrapper input,
  .e-outline.e-float-input.e-control-wrapper.e-bigger input,
  .e-bigger .e-outline.e-float-input.e-control-wrapper input,
  .e-outline.e-float-input.e-control-wrapper.e-input-group.e-bigger input,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-input-group input,
  .e-outline.e-float-input.e-control-wrapper.e-input-group.e-bigger input,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-input-group input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      box-sizing: border-box;
      min-height: $outline-bigger-input-min-height;
    }
  }

  .e-outline.e-input-group.e-bigger .e-clear-icon,
  .e-outline.e-input-group.e-control-wrapper.e-bigger .e-clear-icon,
  .e-outline.e-input-group .e-clear-icon.e-bigger,
  .e-outline.e-input-group.e-control-wrapper .e-clear-icon.e-bigger,
  .e-bigger .e-outline.e-input-group .e-clear-icon,
  .e-bigger .e-outline.e-input-group.e-control-wrapper .e-clear-icon {
    @if $input-skin-name == 'material' {
      font-size: $outline-bigger-clear-icon-font-size;
      padding: $outline-bigger-input-icon-padding $outline-bigger-input-icon-margin-left $outline-bigger-input-icon-padding $zero-value;
    }
    @if $input-skin-name == 'Material3' {
      font-size: $outline-bigger-clear-icon-font-size;
      margin: 8px;
      padding: $zero-value;
    }
  }

  .e-outline.e-input-group.e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-input-group.e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-outline.e-input-group.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-float-input.e-input-group.e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-float-input.e-input-group.e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-outline.e-float-input.e-input-group.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-input-group.e-control-wrapper.e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-input-group.e-control-wrapper.e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-outline.e-input-group.e-control-wrapper.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-float-input.e-input-group.e-control-wrapper.e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-float-input.e-input-group.e-control-wrapper.e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-outline.e-float-input.e-input-group.e-control-wrapper.e-float-icon-left > .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      margin-left: $outline-bigger-input-icon-margin-right;
      margin-right: $zero-value;
    }
  }

  .e-outline.e-input-group.e-rtl.e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-input-group.e-rtl.e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-outline.e-input-group.e-rtl.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-float-input.e-input-group.e-rtl.e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-float-input.e-input-group.e-rtl.e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-outline.e-float-input.e-input-group.e-rtl.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-input-group.e-rtl.e-control-wrapper.e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-input-group.e-rtl.e-control-wrapper.e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-outline.e-input-group.e-rtl.e-control-wrapper.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-float-input.e-input-group.e-rtl.e-control-wrapper.e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-float-input.e-input-group.e-rtl.e-control-wrapper.e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-outline.e-float-input.e-input-group.e-rtl.e-control-wrapper.e-float-icon-left > .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      margin-left: $zero-value;
      margin-right: $outline-bigger-input-icon-margin-right;
    }
  }

  .e-outline.e-input-group.e-bigger .e-input-group-icon,
  .e-outline.e-input-group .e-input-group-icon.e-bigger,
  .e-bigger .e-outline.e-input-group .e-input-group-icon,
  .e-outline.e-input-group.e-control-wrapper.e-bigger .e-input-group-icon,
  .e-outline.e-input-group.e-control-wrapper .e-input-group-icon.e-bigger,
  .e-bigger .e-outline.e-input-group.e-control-wrapper .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      margin: $outline-bigger-input-icon-padding $outline-bigger-input-icon-margin-left  $outline-bigger-input-icon-padding $zero-value;
    }
  }

  .e-outline.e-input-group.e-prepend.e-bigger .e-input-group-icon,
  .e-outline.e-input-group.e-prepend .e-input-group-icon.e-bigger,
  .e-bigger .e-outline.e-input-group.e-prepend .e-input-group-icon,
  .e-outline.e-input-group.e-prepend.e-control-wrapper.e-bigger .e-input-group-icon,
  .e-outline.e-input-group.e-prepend.e-control-wrapper .e-input-group-icon.e-bigger,
  .e-bigger .e-outline.e-input-group.e-prepend.e-control-wrapper .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      margin: $outline-bigger-input-icon-padding $zero-value $outline-bigger-input-icon-padding $outline-bigger-input-icon-margin-left;
    }
  }

  input.e-input.e-bigger.e-outline,
  .e-bigger input.e-input.e-outline,
  .e-outline.e-input-group.e-bigger:not(.e-float-icon-left) input.e-input,
  .e-outline.e-input-group.e-control-wrapper.e-bigger:not(.e-float-icon-left) input.e-input,
  .e-outline.e-float-input.e-control-wrapper.e-bigger:not(.e-float-icon-left) input,
  .e-outline.e-float-input.e-control-wrapper:not(.e-float-icon-left) input.e-bigger,
  .e-outline.e-float-input.e-bigger:not(.e-float-icon-left) input,
  .e-outline.e-float-input:not(.e-float-icon-left) input.e-bigger,
  .e-outline.e-input-group:not(.e-float-icon-left) input.e-input.e-bigger,
  .e-outline.e-input-group.e-control-wrapper:not(.e-float-icon-left) input.e-input.e-bigger,
  .e-bigger .e-outline.e-float-input.e-control-wrapper:not(.e-float-icon-left) input,
  .e-bigger .e-outline.e-float-input:not(.e-float-icon-left) input,
  .e-bigger .e-outline.e-input-group.e-control-wrapper:not(.e-float-icon-left) input.e-input,
  .e-bigger .e-outline.e-input-group:not(.e-float-icon-left) input.e-input,
  .e-outline.e-input-group.e-bigger:not(.e-float-icon-left) input.e-input:focus,
  .e-outline.e-input-group.e-control-wrapper.e-bigger:not(.e-float-icon-left) input.e-input:focus,
  .e-outline.e-float-input.e-control-wrapper.e-bigger:not(.e-float-icon-left) input:focus,
  .e-outline.e-float-input.e-bigger.e-float-input:not(.e-float-icon-left) input:focus,
  .e-bigger .e-outline.e-input-group.e-float-input:not(.e-float-icon-left) input.e-input:focus,
  .e-bigger .e-outline.e-input-group.e-control-wrapper.e-float-input:not(.e-float-icon-left) input.e-input:focus,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-float-input:not(.e-float-icon-left) input:focus,
  .e-bigger .e-outline.e-float-input:not(.e-float-icon-left) input:focus,
  .e-outline.e-float-input.e-control-wrapper.e-bigger:not(.e-float-icon-left).e-input-focus input,
  .e-outline.e-float-input.e-bigger.e-float-input:not(.e-float-icon-left).e-input-focus input,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-float-input:not(.e-float-icon-left).e-input-focus input,
  .e-bigger .e-outline.e-float-input:not(.e-float-icon-left).e-input-focus input,
  .e-outline.e-input-group.e-bigger:not(.e-float-icon-left).e-input-focus input.e-input,
  .e-outline.e-input-group.e-control-wrapper.e-bigger:not(.e-float-icon-left).e-input-focus input.e-input,
  .e-bigger .e-outline.e-input-group.e-float-input:not(.e-float-icon-left).e-input-focus input.e-input,
  .e-bigger .e-outline.e-input-group.e-control-wrapper.e-float-input:not(.e-float-icon-left).e-input-focus input.e-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      padding: $outline-bigger-input-padding-top $outline-bigger-input-padding-left;
    }
  }

  textarea.e-input.e-bigger.e-outline,
  .e-bigger textarea.e-input.e-outline,
  .e-outline.e-input-group.e-bigger:not(.e-float-icon-left) textarea.e-input,
  .e-outline.e-input-group.e-control-wrapper.e-bigger:not(.e-float-icon-left) textarea.e-input,
  .e-outline.e-float-input.e-control-wrapper.e-bigger:not(.e-float-icon-left) textarea,
  .e-outline.e-float-input.e-control-wrapper:not(.e-float-icon-left) textarea.e-bigger,
  .e-outline.e-float-input.e-bigger:not(.e-float-icon-left) textarea,
  .e-outline.e-float-input:not(.e-float-icon-left) textarea.e-bigger,
  .e-outline.e-input-group:not(.e-float-icon-left) textarea.e-input.e-bigger,
  .e-outline.e-input-group.e-control-wrapper:not(.e-float-icon-left) textarea.e-input.e-bigger,
  .e-bigger .e-outline.e-float-input.e-control-wrapper:not(.e-float-icon-left) textarea,
  .e-bigger .e-outline.e-float-input:not(.e-float-icon-left) textarea,
  .e-bigger .e-outline.e-input-group.e-control-wrapper:not(.e-float-icon-left) textarea.e-input,
  .e-bigger .e-outline.e-input-group:not(.e-float-icon-left) textarea.e-input,
  .e-outline.e-input-group.e-bigger:not(.e-float-icon-left) textarea.e-input:focus,
  .e-outline.e-input-group.e-control-wrapper.e-bigger:not(.e-float-icon-left) textarea.e-input:focus,
  .e-outline.e-float-input.e-control-wrapper.e-bigger:not(.e-float-icon-left) textarea:focus,
  .e-outline.e-float-input.e-bigger.e-float-input:not(.e-float-icon-left) textarea:focus,
  .e-bigger .e-outline.e-input-group.e-float-input:not(.e-float-icon-left) textarea.e-input:focus,
  .e-bigger .e-outline.e-input-group.e-control-wrapper.e-float-input:not(.e-float-icon-left) textarea.e-input:focus,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-float-input:not(.e-float-icon-left) textarea:focus,
  .e-bigger .e-outline.e-float-input:not(.e-float-icon-left) textarea:focus {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      margin: $outline-bigger-textarea-maring-top;
      padding: $zero-value $outline-bigger-input-padding-left $outline-bigger-input-padding-left;
    }
  }

  .e-outline.e-input-group.e-bigger.e-small:not(.e-float-input) .e-input,
  .e-bigger .e-outline.e-input-group.e-small:not(.e-float-input) .e-input .e-small .e-input-group.e-bigger:not(.e-float-input) .e-input,
  .e-outline.e-input-group.e-control-wrapper.e-bigger.e-small:not(.e-float-input) .e-input,
  .e-bigger .e-outline.e-input-group.e-control-wrapper.e-small:not(.e-float-input) .e-input,
  .e-small .e-outline.e-input-group.e-control-wrapper.e-bigger:not(.e-float-input) .e-input,
  .e-outline.e-float-input.e-bigger.e-small input,
  .e-bigger .e-outline.e-float-input.e-small input,
  .e-small .e-outline.e-float-input.e-bigger input,
  .e-outline.e-float-input.e-input-group.e-bigger.e-small input,
  .e-bigger .e-outline.e-float-input.e-input-group.e-small input,
  .e-small .e-outline.e-float-input.e-input-group.e-bigger input,
  .e-outline.e-float-input.e-input-group.e-control-wrapper.e-bigger.e-small input,
  .e-bigger .e-outline.e-float-input.e-input-group.e-control-wrapper.e-small input,
  .e-small .e-outline.e-float-input.e-input-group.e-control-wrapper.e-bigger input,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-small input,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small input,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger input,
  .e-outline.e-float-input.e-control-wrapper.e-input-group.e-bigger.e-small input,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-input-group.e-small input,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-input-group.e-bigger input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      box-sizing: border-box;
      min-height: $outline-small-bigger-input-min-height;
    }
  }

  input.e-input.e-bigger.e-small.e-outline,
  .e-bigger input.e-input.e-small.e-outline,
  .e-outline.e-input-group.e-small.e-bigger:not(.e-float-icon-left) input.e-input,
  .e-outline.e-input-group.e-control-wrapper.e-small.e-bigger:not(.e-float-icon-left) input.e-input,
  .e-outline.e-input-group.e-small:not(.e-float-icon-left) input.e-input.e-bigger,
  .e-outline.e-input-group.e-control-wrapper.e-small:not(.e-float-icon-left) input.e-input.e-bigger,
  .e-bigger .e-outline.e-input-group.e-small:not(.e-float-icon-left) input.e-input,
  .e-bigger .e-outline.e-input-group.e-control-wrapper.e-small:not(.e-float-icon-left) input.e-input,
  .e-outline.e-float-input.e-small.e-bigger:not(.e-float-icon-left) input,
  .e-outline.e-float-input.e-small:not(.e-float-icon-left) input.e-bigger,
  .e-bigger .e-outline.e-float-input.e-small:not(.e-float-icon-left) input,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-float-icon-left) input,
  .e-outline.e-float-input.e-control-wrapper.e-small:not(.e-float-icon-left) input.e-bigger,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small:not(.e-float-icon-left) input,
  .e-bigger .e-outline.e-input-group.e-small:not(.e-float-icon-left) input.e-input:focus,
  .e-bigger .e-outline.e-input-group.e-control-wrapper.e-small:not(.e-float-icon-left) input.e-input:focus,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small:not(.e-float-icon-left) input:focus,
  .e-bigger .e-outline.e-float-input.e-small:not(.e-float-icon-left) input:focus,
  .e-small .e-outline.e-input-group.e-bigger:not(.e-float-icon-left) input.e-input:focus,
  .e-small .e-outline.e-input-group.e-control-wrapper.e-bigger:not(.e-float-icon-left) input.e-input:focus,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger:not(.e-float-icon-left) input:focus,
  .e-small .e-outline.e-float-input.e-bigger:not(.e-float-icon-left) input:focus,
  .e-outline.e-input-group.e-bigger.e-small:not(.e-float-icon-left) input.e-input:focus,
  .e-outline.e-input-group.e-control-wrapper.e-bigger.e-small:not(.e-float-icon-left) input.e-input:focus,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-small:not(.e-float-icon-left) input:focus,
  .e-outline.e-float-input.e-bigger.e-small:not(.e-float-icon-left) input:focus,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small:not(.e-float-icon-left).e-input-focus input,
  .e-bigger .e-outline.e-float-input.e-small:not(.e-float-icon-left).e-input-focus input,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger:not(.e-float-icon-left).e-input-focus input,
  .e-small .e-outline.e-float-input.e-bigger:not(.e-float-icon-left).e-input-focus input,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-small:not(.e-float-icon-left).e-input-focus input,
  .e-outline.e-float-input.e-bigger.e-small:not(.e-float-icon-left).e-input-focus input,
  .e-bigger .e-outline.e-input-group.e-small:not(.e-float-icon-left).e-input-focus input.e-input,
  .e-bigger .e-outline.e-input-group.e-control-wrapper.e-small:not(.e-float-icon-left).e-input-focus input.e-input,
  .e-small .e-outline.e-input-group.e-bigger:not(.e-float-icon-left).e-input-focus input.e-input,
  .e-small .e-outline.e-input-group.e-control-wrapper.e-bigger:not(.e-float-icon-left).e-input-focus input.e-input,
  .e-outline.e-input-group.e-bigger.e-small:not(.e-float-icon-left).e-input-focus input.e-input,
  .e-outline.e-input-group.e-control-wrapper.e-bigger.e-small:not(.e-float-icon-left).e-input-focus input.e-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      padding: $outline-small-bigger-input-margin-top $outline-small-bigger-input-margin-left $outline-small-bigger-input-margin-bottom;
    }
  }

  textarea.e-input.e-bigger.e-small.e-outline,
  .e-bigger textarea.e-input.e-small.e-outline,
  .e-outline.e-input-group.e-small.e-bigger:not(.e-float-icon-left) textarea.e-input,
  .e-outline.e-input-group.e-control-wrapper.e-small.e-bigger:not(.e-float-icon-left) textarea.e-input,
  .e-outline.e-input-group.e-small:not(.e-float-icon-left) textarea.e-input.e-bigger,
  .e-outline.e-input-group.e-control-wrapper.e-small:not(.e-float-icon-left) textarea.e-input.e-bigger,
  .e-bigger .e-outline.e-input-group.e-small:not(.e-float-icon-left) textarea.e-input,
  .e-bigger .e-outline.e-input-group.e-control-wrapper.e-small:not(.e-float-icon-left) textarea.e-input,
  .e-outline.e-float-input.e-small.e-bigger:not(.e-float-icon-left) textarea,
  .e-outline.e-float-input.e-small:not(.e-float-icon-left) textarea.e-bigger,
  .e-bigger .e-outline.e-float-input.e-small:not(.e-float-icon-left) textarea,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-float-icon-left) textarea,
  .e-outline.e-float-input.e-control-wrapper.e-small:not(.e-float-icon-left) textarea.e-bigger,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small:not(.e-float-icon-left) textarea,
  .e-bigger .e-outline.e-input-group.e-small:not(.e-float-icon-left) textarea.e-input:focus,
  .e-bigger .e-outline.e-input-group.e-control-wrapper.e-small:not(.e-float-icon-left) textarea.e-input:focus,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small:not(.e-float-icon-left) textarea:focus,
  .e-bigger .e-outline.e-float-input.e-small:not(.e-float-icon-left) textarea:focus,
  .e-small .e-outline.e-input-group.e-bigger:not(.e-float-icon-left) textarea.e-input:focus,
  .e-small .e-outline.e-input-group.e-control-wrapper.e-bigger:not(.e-float-icon-left) textarea.e-input:focus,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger:not(.e-float-icon-left) textarea:focus,
  .e-small .e-outline.e-float-input.e-bigger:not(.e-float-icon-left) textarea:focus,
  .e-outline.e-input-group.e-bigger.e-small:not(.e-float-icon-left) textarea.e-input:focus,
  .e-outline.e-input-group.e-control-wrapper.e-bigger.e-small:not(.e-float-icon-left) textarea.e-input:focus,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-small:not(.e-float-icon-left) textarea:focus,
  .e-outline.e-float-input.e-bigger.e-small:not(.e-float-icon-left) textarea:focus {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      box-sizing: border-box;
      margin: $outline-bigger-textarea-maring-top;
      padding: $zero-value $outline-small-bigger-input-margin-left $outline-small-bigger-input-margin-left;
    }
  }

  .e-outline.e-input-group.e-small.e-bigger .e-clear-icon,
  .e-outline.e-input-group.e-small .e-clear-icon.e-bigger,
  .e-bigger .e-outline.e-input-group.e-small .e-clear-icon,
  .e-outline.e-input-group.e-control-wrapper.e-small.e-bigger .e-clear-icon,
  .e-outline.e-input-group.e-control-wrapper.e-small .e-clear-icon.e-bigger,
  .e-bigger .e-outline.e-input-group.e-control-wrapper.e-small .e-clear-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      font-size: $outline-small-bigger-clear-icon-font-size;
      padding: 9px $outline-small-bigger-icon-margin-left 9px $zero-value;
    }
  }

  .e-outline.e-input-group.e-small.e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-input-group.e-small.e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-outline.e-input-group.e-small.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-float-input.e-input-group.e-small.e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-float-input.e-input-group.e-small.e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-outline.e-input-group.e-float-input.e-small.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-input-group.e-control-wrapper.e-small.e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-input-group.e-control-wrapper.e-small.e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-outline.e-input-group.e-control-wrapper.e-small.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-float-input.e-control-wrapper.e-input-group.e-small.e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-float-input.e-control-wrapper.e-input-group.e-small.e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-outline.e-input-group.e-control-wrapper.e-float-input.e-small.e-float-icon-left > .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      margin-left: $zero-value;
      margin-right: $outline-small-bigger-icon-margin-right;
    }
  }

  .e-outline.e-input-group.e-small.e-bigger .e-input-group-icon,
  .e-outline.e-input-group.e-small .e-input-group-icon.e-bigger,
  .e-outline.e-input-group.e-control-wrapper.e-small.e-bigger .e-input-group-icon,
  .e-outline.e-input-group.e-control-wrapper.e-small .e-input-group-icon.e-bigger,
  .e-bigger .e-outline.e-input-group.e-small .e-input-group-icon,
  .e-bigger .e-outline.e-input-group.e-control-wrapper.e-small .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      margin: $outline-bigger-small-group-icon-top $outline-small-bigger-icon-margin-left $outline-bigger-small-group-icon-bottom $zero-value;
    }
  }

  .e-outline.e-input-group.e-prepend.e-small.e-bigger .e-input-group-icon,
  .e-outline.e-input-group.e-prepend.e-small .e-input-group-icon.e-bigger,
  .e-outline.e-input-group.e-prepend.e-control-wrapper.e-small.e-bigger .e-input-group-icon,
  .e-outline.e-input-group.e-prepend.e-control-wrapper.e-small .e-input-group-icon.e-bigger,
  .e-bigger .e-outline.e-input-group.e-prepend.e-small .e-input-group-icon,
  .e-bigger .e-outline.e-input-group.e-prepend.e-control-wrapper.e-small .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      margin: $outline-bigger-small-group-icon-top $zero-value $outline-bigger-small-group-icon-bottom $outline-small-bigger-icon-margin-left;
    }
  }

  .e-outline.e-float-input.e-bigger:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-bigger.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-small.e-bigger:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper.e-bigger:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-bigger textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-bigger.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-small.e-bigger textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper.e-bigger textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      box-sizing: border-box;
      display: flex;
      left: $zero-value;
      line-height: $outline-label-default-line-height;
      position: absolute;
      top: $outline-float-label-top;
      transform: translate3d($zero-value, $zero-value, $zero-value) scale(1);
      transition: color .2s, font-size .2s, line-height .2s;
    }
  }

  .e-outline.e-float-input.e-bigger:not(.e-error):not(.e-valid-input):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-bigger.e-control-wrapper:not(.e-error):not(.e-valid-input):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input:not(.e-error):not(.e-valid-input):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-outline.e-float-input:not(.e-error):not(.e-valid-input):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper.e-bigger:not(.e-error):not(.e-valid-input):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper.e-bigger:not(.e-error):not(.e-valid-input):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input.e-control-wrapper:not(.e-error):not(.e-valid-input):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-outline.e-float-input.e-control-wrapper:not(.e-error):not(.e-valid-input):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-outline:not(.e-valid-input):not(.e-valid-input):not(.e-input-focus) input:not(:focus):not(:valid) ~ label.e-float-text,
  .e-bigger .e-outline:not(.e-valid-input):not(.e-valid-input):not(.e-input-focus) input:not(:focus):not(:valid) ~ label.e-float-text,
  .e-bigger.e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus) input:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus) input:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-bigger.e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(.e-input-focus) input:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(.e-input-focus) input:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      line-height: $outline-bigger-label-line-height;
    }
  }

  .e-outline.e-float-input.e-bigger:not(.e-error):not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-bigger.e-control-wrapper:not(.e-error):not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input:not(.e-error):not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-outline.e-float-input:not(.e-error):not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper.e-bigger:not(.e-error):not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper.e-bigger:not(.e-error):not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input.e-control-wrapper:not(.e-error):not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-outline.e-float-input.e-control-wrapper:not(.e-error):not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus) textarea:valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(.e-input-focus) textarea:valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus) textarea:valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(.e-input-focus) textarea:valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-outline:not(.e-valid-input):not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):not(:valid) ~ label.e-float-text,
  .e-bigger .e-outline:not(.e-valid-input):not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):not(:valid) ~ label.e-float-text,
  .e-bigger.e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-bigger.e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-outline.e-float-input.e-bigger:not(.e-error):not(.e-valid-input) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-bigger.e-control-wrapper:not(.e-error):not(.e-valid-input) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input:not(.e-error):not(.e-valid-input) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-outline.e-float-input:not(.e-error):not(.e-valid-input) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper.e-bigger:not(.e-error):not(.e-valid-input) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper.e-bigger:not(.e-error):not(.e-valid-input) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input.e-control-wrapper:not(.e-error):not(.e-valid-input) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-outline.e-float-input.e-control-wrapper:not(.e-error):not(.e-valid-input) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-outline:not(.e-valid-input):not(.e-valid-input) textarea:not(:focus):not(:valid) ~ label.e-float-text,
  .e-bigger .e-outline:not(.e-valid-input):not(.e-valid-input) textarea:not(:focus):not(:valid) ~ label.e-float-text,
  .e-bigger.e-outline.e-float-input:not(.e-valid-input) textarea:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input:not(.e-valid-input) textarea:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-bigger.e-outline.e-float-input.e-control-wrapper:not(.e-valid-input) textarea:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper:not(.e-valid-input) textarea:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      line-height: $outline-textarea-bigger-label-line-height;
    }
  }

  .e-small.e-bigger.e-outline.e-float-input:not(.e-error):not(.e-valid-input):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small.e-bigger.e-outline.e-float-input.e-control-wrapper:not(.e-error):not(.e-valid-input):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small.e-bigger.e-outline:not(.e-valid-input):not(.e-valid-input):not(.e-input-focus) input:not(:focus):not(:valid) ~ label.e-float-text,
  .e-small.e-bigger.e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus) input:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-small.e-bigger.e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(.e-input-focus) input:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-small .e-bigger.e-outline.e-float-input:not(.e-error):not(.e-valid-input):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-bigger.e-outline.e-float-input.e-control-wrapper:not(.e-error):not(.e-valid-input):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-bigger.e-outline:not(.e-valid-input):not(.e-valid-input):not(.e-input-focus) input:not(:focus):not(:valid) ~ label.e-float-text,
  .e-small .e-bigger.e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus) input:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-small .e-bigger.e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(.e-input-focus) input:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-bigger .e-small.e-outline.e-float-input:not(.e-error):not(.e-valid-input):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-small.e-outline.e-float-input.e-control-wrapper:not(.e-error):not(.e-valid-input):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-small.e-outline:not(.e-valid-input):not(.e-valid-input):not(.e-input-focus) input:not(:focus):not(:valid) ~ label.e-float-text,
  .e-bigger .e-small.e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus) input:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-bigger .e-small.e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(.e-input-focus) input:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-outline.e-float-input.e-small.e-bigger:not(.e-error):not(.e-valid-input):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input.e-small:not(.e-error):not(.e-valid-input):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error):not(.e-valid-input):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small:not(.e-error):not(.e-valid-input):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      line-height: $outline-small-bigger-label-line-height;
    }
  }

  .e-small.e-bigger.e-outline.e-float-input:not(.e-error):not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small.e-bigger.e-outline.e-float-input.e-control-wrapper:not(.e-error):not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small.e-bigger.e-outline:not(.e-valid-input):not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):not(:valid) ~ label.e-float-text,
  .e-small.e-bigger.e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-small.e-bigger.e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-small .e-bigger.e-outline.e-float-input:not(.e-error):not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-bigger.e-outline.e-float-input.e-control-wrapper:not(.e-error):not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-bigger.e-outline:not(.e-valid-input):not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):not(:valid) ~ label.e-float-text,
  .e-small .e-bigger.e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-small .e-bigger.e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-bigger .e-small.e-outline.e-float-input:not(.e-error):not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-small.e-outline.e-float-input.e-control-wrapper:not(.e-error):not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-small.e-outline:not(.e-valid-input):not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):not(:valid) ~ label.e-float-text,
  .e-bigger .e-small.e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-bigger .e-small.e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-outline.e-float-input.e-small.e-bigger:not(.e-error):not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input.e-small:not(.e-error):not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error):not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small:not(.e-error):not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small.e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small.e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small.e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus) textarea:valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small.e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(.e-input-focus) textarea:valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus) textarea:valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(.e-input-focus) textarea:valid ~ label.e-float-text.e-label-bottom,
  .e-small.e-bigger.e-outline.e-float-input:not(.e-error):not(.e-valid-input) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small.e-bigger.e-outline.e-float-input.e-control-wrapper:not(.e-error):not(.e-valid-input) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small.e-bigger.e-outline:not(.e-valid-input):not(.e-valid-input) textarea:not(:focus):not(:valid) ~ label.e-float-text,
  .e-small.e-bigger.e-outline.e-float-input:not(.e-valid-input) textarea:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-small.e-bigger.e-outline.e-float-input.e-control-wrapper:not(.e-valid-input) textarea:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-small .e-bigger.e-outline.e-float-input:not(.e-error):not(.e-valid-input) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-bigger.e-outline.e-float-input.e-control-wrapper:not(.e-error):not(.e-valid-input) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-bigger.e-outline:not(.e-valid-input):not(.e-valid-input) textarea:not(:focus):not(:valid) ~ label.e-float-text,
  .e-small .e-bigger.e-outline.e-float-input:not(.e-valid-input) textarea:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-small .e-bigger.e-outline.e-float-input.e-control-wrapper:not(.e-valid-input) textarea:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-bigger .e-small.e-outline.e-float-input:not(.e-error):not(.e-valid-input) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-small.e-outline.e-float-input.e-control-wrapper:not(.e-error):not(.e-valid-input) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-small.e-outline:not(.e-valid-input):not(.e-valid-input) textarea:not(:focus):not(:valid) ~ label.e-float-text,
  .e-bigger .e-small.e-outline.e-float-input:not(.e-valid-input) textarea:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-bigger .e-small.e-outline.e-float-input.e-control-wrapper:not(.e-valid-input) textarea:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-outline.e-float-input.e-small.e-bigger:not(.e-error):not(.e-valid-input) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input.e-small:not(.e-error):not(.e-valid-input) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error):not(.e-valid-input) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small:not(.e-error):not(.e-valid-input) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      line-height: $outline-textarea-small-bigger-label-line-height;
    }
  }

  .e-bigger.e-outline label.e-float-text::before,
  .e-bigger.e-outline label.e-float-text::after,
  .e-bigger.e-outline.e-float-input label.e-float-text::before,
  .e-bigger.e-outline.e-float-input label.e-float-text::after,
  .e-bigger.e-outline.e-float-input.e-control-wrapper label.e-float-text::before,
  .e-bigger.e-outline.e-float-input.e-control-wrapper label.e-float-text::after,
  .e-bigger .e-outline label.e-float-text::before,
  .e-bigger .e-outline label.e-float-text::after,
  .e-bigger .e-outline.e-float-input label.e-float-text::before,
  .e-bigger .e-outline.e-float-input label.e-float-text::after,
  .e-bigger .e-outline.e-float-input.e-control-wrapper label.e-float-text::before,
  .e-bigger .e-outline.e-float-input.e-control-wrapper label.e-float-text::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      min-width: $outline-bigger-label-min-width;
    }
  }

  .e-bigger.e-small.e-outline label.e-float-text::before,
  .e-bigger.e-small.e-outline label.e-float-text::after,
  .e-bigger.e-small.e-outline.e-float-input label.e-float-text::before,
  .e-bigger.e-small.e-outline.e-float-input label.e-float-text::after,
  .e-bigger.e-small.e-outline.e-float-input.e-control-wrapper label.e-float-text::before,
  .e-bigger.e-small.e-outline.e-float-input.e-control-wrapper label.e-float-text::after,
  .e-bigger.e-small .e-outline label.e-float-text::before,
  .e-bigger.e-small .e-outline label.e-float-text::after,
  .e-bigger.e-small .e-outline.e-float-input label.e-float-text::before,
  .e-bigger.e-small .e-outline.e-float-input label.e-float-text::after,
  .e-bigger.e-small .e-outline.e-float-input.e-control-wrapper label.e-float-text::before,
  .e-bigger.e-small .e-outline.e-float-input.e-control-wrapper label.e-float-text::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      min-width: $outline-bigger-small-label-min-width;
    }
  }

  .e-rtl .e-outline.e-input-group.e-bigger .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl.e-outline.e-input-group.e-bigger .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl .e-outline.e-input-group .e-input-group-icon.e-bigger + .e-input-group-icon.e-bigger:last-child,
  .e-rtl.e-outline.e-input-group .e-input-group-icon.e-bigger + .e-input-group-icon.e-bigger:last-child,
  .e-bigger .e-rtl.e-outline.e-input-group .e-input-group-icon + .e-input-group-icon:last-child,
  .e-bigger.e-rtl .e-outline.e-input-group .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl.e-outline.e-input-group.e-small.e-bigger .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl .e-outline.e-input-group.e-small.e-bigger .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl.e-outline.e-input-group.e-small .e-input-group-icon.e-bigger + .e-input-group-icon.e-bigger:last-child,
  .e-rtl .e-outline.e-input-group.e-small .e-input-group-icon.e-bigger + .e-input-group-icon.e-bigger:last-child,
  .e-rtl.e-outline.e-input-group.e-control-wrapper.e-bigger .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl .e-outline.e-input-group.e-control-wrapper.e-bigger .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl.e-outline.e-input-group.e-control-wrapper .e-input-group-icon.e-bigger + .e-input-group-icon.e-bigger:last-child,
  .e-rtl .e-outline.e-input-group.e-control-wrapper .e-input-group-icon.e-bigger + .e-input-group-icon.e-bigger:last-child,
  .e-rtl.e-outline.e-input-group.e-control-wrapper.e-small.e-bigger .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl .e-outline.e-input-group.e-control-wrapper.e-small.e-bigger .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl.e-outline.e-input-group.e-control-wrapper.e-small .e-input-group-icon.e-bigger + .e-input-group-icon.e-bigger:last-child,
  .e-rtl .e-outline.e-input-group.e-control-wrapper.e-small .e-input-group-icon.e-bigger + .e-input-group-icon.e-bigger:last-child,
  .e-rtl.e-bigger .e-outline.e-input-group.e-control-wrapper.e-small .e-input-group-icon + .e-input-group-icon:last-child,
  .e-bigger .e-rtl.e-outline.e-input-group.e-control-wrapper.e-small .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl.e-bigger .e-outline.e-input-group.e-small .e-input-group-icon + .e-input-group-icon:last-child .e-bigger .e-rtl.e-outline.e-input-group.e-small .e-input-group-icon + .e-input-group-icon:last-child {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      margin-right: $zero-value;
    }
  }

  .e-rtl.e-outline.e-input-group.e-bigger .e-clear-icon,
  .e-rtl.e-outline.e-input-group.e-control-wrapper.e-bigger .e-clear-icon,
  .e-rtl.e-outline.e-input-group .e-clear-icon.e-bigger,
  .e-rtl.e-outline.e-input-group.e-control-wrapper .e-clear-icon.e-bigger,
  .e-bigger .e-rtl.e-outline.e-input-group .e-clear-icon,
  .e-bigger .e-rtl.e-outline.e-input-group.e-control-wrapper .e-clear-icon .e-rtl .e-outline.e-input-group.e-bigger .e-clear-icon,
  .e-rtl .e-outline.e-input-group.e-control-wrapper.e-bigger .e-clear-icon,
  .e-rtl .e-outline.e-input-group .e-clear-icon.e-bigger,
  .e-rtl .e-outline.e-input-group.e-control-wrapper .e-clear-icon.e-bigger,
  .e-bigger.e-rtl .e-outline.e-input-group .e-clear-icon,
  .e-bigger.e-rtl .e-outline.e-input-group.e-control-wrapper .e-clear-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      padding-left: $outline-bigger-input-icon-margin-left;
      padding-right: $zero-value;
    }
  }

  .e-rtl.e-outline.e-input-group.e-bigger .e-input-group-icon,
  .e-rtl.e-outline.e-input-group .e-input-group-icon.e-bigger,
  .e-bigger .e-rtl.e-outline.e-input-group .e-input-group-icon,
  .e-rtl.e-outline.e-input-group.e-control-wrapper.e-bigger .e-input-group-icon,
  .e-rtl.e-outline.e-input-group.e-control-wrapper .e-input-group-icon.e-bigger,
  .e-bigger .e-rtl.e-outline.e-input-group.e-control-wrapper .e-input-group-icon,
  .e-rtl .e-outline.e-input-group.e-bigger .e-input-group-icon,
  .e-rtl .e-outline.e-input-group .e-input-group-icon.e-bigger,
  .e-bigger.e-rtl .e-outline.e-input-group .e-input-group-icon,
  .e-rtl .e-outline.e-input-group.e-control-wrapper.e-bigger .e-input-group-icon,
  .e-rtl .e-outline.e-input-group.e-control-wrapper .e-input-group-icon.e-bigger,
  .e-bigger.e-rtl .e-outline.e-input-group.e-control-wrapper .e-input-group-icon,
  .e-rtl .e-outline.e-input-group.e-bigger .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl.e-outline.e-input-group.e-bigger .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl .e-outline.e-input-group .e-input-group-icon.e-bigger + .e-input-group-icon.e-bigger:last-child,
  .e-rtl.e-outline.e-input-group .e-input-group-icon.e-bigger + .e-input-group-icon.e-bigger:last-child,
  .e-bigger .e-rtl.e-outline.e-input-group .e-input-group-icon + .e-input-group-icon:last-child,
  .e-bigger.e-rtl .e-outline.e-input-group .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl.e-outline.e-input-group.e-control-wrapper.e-bigger .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl .e-outline.e-input-group.e-control-wrapper.e-bigger .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl.e-outline.e-input-group.e-control-wrapper .e-input-group-icon.e-bigger + .e-input-group-icon.e-bigger:last-child,
  .e-rtl .e-outline.e-input-group.e-control-wrapper .e-input-group-icon.e-bigger + .e-input-group-icon.e-bigger:last-child {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      margin-left: $outline-bigger-input-icon-margin-left;
      margin-right: $zero-value;
    }
  }

  .e-rtl.e-outline.e-input-group.e-small.e-bigger .e-clear-icon,
  .e-rtl .e-outline.e-input-group.e-small.e-bigger .e-clear-icon,
  .e-rtl.e-outline.e-input-group.e-small .e-clear-icon.e-bigger,
  .e-rtl .e-outline.e-input-group.e-small .e-clear-icon.e-bigger,
  .e-rtl.e-outline.e-input-group.e-control-wrapper.e-small.e-bigger .e-clear-icon,
  .e-rtl .e-outline.e-input-group.e-control-wrapper.e-small.e-bigger .e-clear-icon,
  .e-rtl.e-outline.e-input-group.e-control-wrapper.e-small .e-clear-icon.e-bigger,
  .e-rtl .e-outline.e-input-group.e-control-wrapper.e-small .e-clear-icon.e-bigger,
  .e-bigger.e-rtl .e-outline.e-input-group.e-small .e-clear-icon,
  .e-bigger .e-rtl.e-outline.e-input-group.e-small .e-clear-icon,
  .e-bigger .e-rtl.e-outline.e-input-group.e-control-wrapper.e-small .e-clear-icon,
  .e-bigger.e-rtl .e-outline.e-input-group.e-control-wrapper.e-small .e-clear-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      padding-left: $outline-small-bigger-icon-margin-left;
      padding-right: $zero-value;
    }
  }

  .e-rtl.e-outline.e-input-group.e-small.e-bigger .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl .e-outline.e-input-group.e-small.e-bigger .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl.e-outline.e-input-group.e-small .e-input-group-icon.e-bigger + .e-input-group-icon.e-bigger:last-child,
  .e-rtl .e-outline.e-input-group.e-small .e-input-group-icon.e-bigger + .e-input-group-icon.e-bigger:last-child,
  .e-rtl.e-outline.e-input-group.e-control-wrapper.e-small.e-bigger .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl .e-outline.e-input-group.e-control-wrapper.e-small.e-bigger .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl.e-outline.e-input-group.e-control-wrapper.e-small .e-input-group-icon.e-bigger + .e-input-group-icon.e-bigger:last-child,
  .e-rtl .e-outline.e-input-group.e-control-wrapper.e-small .e-input-group-icon.e-bigger + .e-input-group-icon.e-bigger:last-child,
  .e-rtl.e-bigger .e-outline.e-input-group.e-control-wrapper.e-small .e-input-group-icon + .e-input-group-icon:last-child,
  .e-bigger .e-rtl.e-outline.e-input-group.e-control-wrapper.e-small .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl.e-bigger .e-outline.e-input-group.e-small .e-input-group-icon + .e-input-group-icon:last-child .e-bigger,
  .e-rtl.e-outline.e-input-group.e-small.e-bigger .e-input-group-icon,
  .e-rtl .e-outline.e-input-group.e-small.e-bigger .e-input-group-icon,
  .e-rtl.e-outline.e-input-group.e-small .e-input-group-icon.e-bigger,
  .e-rtl .e-outline.e-input-group.e-small .e-input-group-icon.e-bigger,
  .e-rtl.e-outline.e-input-group.e-control-wrapper.e-small.e-bigger .e-input-group-icon,
  .e-rtl .e-outline.e-input-group.e-control-wrapper.e-small.e-bigger .e-input-group-icon,
  .e-rtl.e-outline.e-input-group.e-control-wrapper.e-small .e-input-group-icon.e-bigger,
  .e-rtl .e-outline.e-input-group.e-control-wrapper.e-small .e-input-group-icon.e-bigger,
  .e-bigger.e-rtl .e-outline.e-input-group.e-small .e-input-group-icon,
  .e-bigger .e-rtl.e-outline.e-input-group.e-small .e-input-group-icon,
  .e-bigger .e-rtl.e-outline.e-input-group.e-control-wrapper.e-small .e-input-group-icon,
  .e-bigger.e-rtl .e-outline.e-input-group.e-control-wrapper.e-small .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      margin-left: $outline-small-bigger-icon-margin-left;
      margin-right: $zero-value;
    }
  }

  .e-outline.e-float-input.e-small.e-bigger label.e-float-text,
  .e-bigger .e-small .e-outline.e-float-input label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small label.e-float-text,
  .e-outline.e-float-input.e-bigger.e-small input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-bigger.e-small input:valid ~ label.e-float-text,
  .e-outline.e-float-input.e-bigger.e-small input ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-bigger.e-small input[readonly] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-bigger.e-small input[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-bigger.e-small input label.e-float-text.e-label-top,
  .e-bigger .e-outline.e-float-input.e-small input:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small input:valid ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small input ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small input label.e-float-text.e-label-top,
  .e-small .e-outline.e-float-input.e-bigger input:focus ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-bigger input:valid ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-bigger input ~ label.e-label-top.e-float-text,
  .e-small .e-outline.e-float-input.e-bigger input[readonly] ~ label.e-label-top.e-float-text,
  .e-small .e-outline.e-float-input.e-bigger input[disabled] ~ label.e-label-top.e-float-text,
  .e-small .e-outline.e-float-input.e-bigger input label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-small input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-small input:valid ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-small input ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-small input[readonly] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-small input[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-small input label.e-float-text.e-label-top,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small input:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small input:valid ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small input ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small input label.e-float-text.e-label-top,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger input:focus ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger input:valid ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger input ~ label.e-label-top.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger input[readonly] ~ label.e-label-top.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger input[disabled] ~ label.e-label-top.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger input label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-bigger.e-small textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-bigger.e-small textarea:valid ~ label.e-float-text,
  .e-outline.e-float-input.e-bigger.e-small textarea ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-bigger.e-small textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-bigger.e-small textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-bigger.e-small textarea label.e-float-text.e-label-top,
  .e-bigger .e-outline.e-float-input.e-small textarea:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small textarea:valid ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small textarea label.e-float-text.e-label-top,
  .e-small .e-outline.e-float-input.e-bigger textarea:focus ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-bigger textarea:valid ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-bigger textarea ~ label.e-label-top.e-float-text,
  .e-small .e-outline.e-float-input.e-bigger textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-small .e-outline.e-float-input.e-bigger textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-small .e-outline.e-float-input.e-bigger textarea label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-small textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-small textarea:valid ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-small textarea ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-small textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-small textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-small textarea label.e-float-text.e-label-top,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small textarea:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small textarea:valid ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small textarea label.e-float-text.e-label-top,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger textarea:focus ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger textarea:valid ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger textarea ~ label.e-label-top.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger textarea label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-bigger.e-small.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small.e-input-focus input ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-bigger.e-input-focus input ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-small.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small.e-input-focus input ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger.e-input-focus input ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      top: $outline-float-label-top;
      transform: translate3d(0, 0, 0) scale(1);
    }
  }

  .e-outline.e-float-input.e-bigger .e-clear-icon::before,
  .e-outline.e-float-input.e-control-wrapper.e-bigger .e-clear-icon::before,
  .e-outline.e-input-group.e-bigger .e-clear-icon::before,
  .e-outline.e-input-group.e-control-wrapper.e-bigger .e-clear-icon::before,
  .e-outline.e-float-input.e-control-wrapper input.e-bigger:first-child ~ .e-clear-icon::before,
  .e-outline.e-bigger .e-float-input.e-control-wrapper .e-clear-icon::before,
  .e-outline.e-float-input input.e-bigger:first-child ~ .e-clear-icon::before,
  .e-outline.e-bigger .e-float-input .e-clear-icon::before,
  .e-outline.e-bigger .e-input-group .e-clear-icon::before,
  .e-outline.e-bigger .e-input-group.e-control-wrapper .e-clear-icon::before {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      font-size: $outline-input-bigger-clear-icon;
    }
  }

  .e-outline.e-float-input.e-small.e-bigger .e-clear-icon::before,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger .e-clear-icon::before,
  .e-outline.e-input-group.e-small.e-bigger .e-clear-icon::before,
  .e-outline.e-input-group.e-control-wrapper.e-small.e-bigger .e-clear-icon::before,
  .e-outline.e-small .e-input-group.e-bigger input:first-child ~ .e-clear-icon::before,
  .e-outline.e-small .e-input-group.e-control-wrapper.e-bigger input:first-child ~ .e-clear-icon::before,
  .e-outline.e-bigger .e-input-group.e-small input:first-child ~ .e-clear-icon::before,
  .e-outline.e-bigger .e-input-group.e-control-wrapper.e-small input:first-child ~ .e-clear-icon::before,
  .e-outline.e-small .e-float-input.e-control-wrapper.e-bigger input:first-child ~ .e-clear-icon::before,
  .e-outline.e-small .e-float-input.e-bigger input:first-child ~ .e-clear-icon::before,
  .e-outline.e-bigger .e-float-input.e-control-wrapper.e-small input:first-child ~ .e-clear-icon::before,
  .e-outline.e-bigger .e-float-input.e-small input:first-child ~ .e-clear-icon::before {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      font-size: $outline-input-bigger-small-clear-icon;
    }
  }

  .e-outline.e-float-input.e-bigger textarea ~ label.e-float-text,
  .e-outline.e-float-input textarea ~ label.e-float-text.e-bigger,
  .e-outline.e-float-input textarea.e-bigger ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input textarea ~ label.e-float-text,
  .e-outline.e-float-input.e-bigger textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper.e-bigger textarea ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper textarea ~ label.e-float-text.e-bigger,
  .e-outline.e-float-input.e-control-wrapper textarea.e-bigger ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper textarea ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input textarea:-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-outline.e-float-input textarea:-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-autofill textarea:-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-outline.e-float-input.e-control-wrapper.e-autofill textarea:-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-autofill textarea:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-outline.e-float-input.e-control-wrapper.e-autofill textarea:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-bigger label.e-float-text,
  .e-outline.e-float-input label.e-float-text.e-bigger,
  .e-outline.e-float-input input.e-bigger ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input label.e-float-text,
  .e-outline.e-float-input.e-bigger:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper.e-bigger label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper label.e-float-text.e-bigger,
  .e-outline.e-float-input.e-control-wrapper input.e-bigger ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-bigger textarea ~ label.e-float-text,
  .e-outline.e-float-input textarea ~ label.e-float-text.e-bigger,
  .e-outline.e-float-input textarea.e-bigger ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input textarea ~ label.e-float-text,
  .e-outline.e-float-input.e-bigger textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper.e-bigger textarea ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper textarea ~ label.e-float-text.e-bigger,
  .e-outline.e-float-input.e-control-wrapper textarea.e-bigger ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper textarea ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      font-size: $bigger-outline-float-label-font-size;
    }
  }

  .e-bigger .e-outline.e-float-input.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-outline.e-float-input.e-bigger textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-bigger.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-outline.e-float-input textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-bigger.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-outline.e-float-input.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input.e-small textarea:-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-small .e-outline.e-float-input textarea:-webkit-autofill ~ label.e-float-text,
  .e-outline.e-float-input.e-bigger.e-small textarea:-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small.e-control-wrapper.e-autofill textarea:-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-small .e-outline.e-float-input.e-control-wrapper.e-autofill textarea:-webkit-autofill ~ label.e-float-text,
  .e-outline.e-float-input.e-bigger.e-small.e-control-wrapper.e-autofill textarea:-webkit-autofill ~ label.e-float-text,
  .e-small.e-bigger .e-outline.e-float-input.e-control-wrapper.e-autofill textarea:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-small.e-bigger.e-outline.e-float-input.e-control-wrapper.e-autofill textarea:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-small.e-outline.e-float-input.e-control-wrapper.e-autofill textarea:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-small .e-bigger.e-outline.e-float-input.e-control-wrapper.e-autofill textarea:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-small.e-bigger label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-outline.e-float-input.e-bigger:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-bigger.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-outline.e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-bigger.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-outline.e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-outline.e-float-input.e-bigger textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-bigger.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-outline.e-float-input textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-bigger.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-outline.e-float-input.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      font-size: $bigger-small-outline-float-label-font-size;
    }
  }

  .e-bigger .e-outline.e-float-input input:-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-outline.e-float-input input:-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-autofill input:-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-outline.e-float-input.e-control-wrapper.e-autofill input:-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-autofill:not(.e-input-focus) input:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-outline.e-float-input.e-control-wrapper.e-autofill:not(.e-input-focus) input:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      animation-name: slideTopUp;/* stylelint-disable-line no-unknown-animations */
    }
  }

  .e-bigger .e-outline.e-float-input.e-small input:-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-small .e-outline.e-float-input input:-webkit-autofill ~ label.e-float-text,
  .e-outline.e-float-input.e-bigger.e-small input:-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small.e-control-wrapper.e-autofill input:-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-small .e-outline.e-float-input.e-control-wrapper.e-autofill input:-webkit-autofill ~ label.e-float-text,
  .e-outline.e-float-input.e-bigger.e-small.e-control-wrapper.e-autofill input:-webkit-autofill ~ label.e-float-text,
  .e-small.e-bigger .e-outline.e-float-input.e-control-wrapper.e-autofill:not(.e-input-focus) input:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-small.e-bigger.e-outline.e-float-input.e-control-wrapper.e-autofill:not(.e-input-focus) input:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-small.e-outline.e-float-input.e-control-wrapper.e-autofill:not(.e-input-focus) input:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-small .e-bigger.e-outline.e-float-input.e-control-wrapper.e-autofill:not(.e-input-focus) input:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      animation-name: slideTopUp;/* stylelint-disable-line no-unknown-animations */
    }
  }

  .e-filled input.e-input.e-bigger#{$css},
  .e-filled.e-input-group.e-bigger input,
  .e-filled.e-input-group.e-bigger input.e-input,
  .e-filled.e-input-group.e-control-wrapper.e-bigger input,
  .e-filled.e-input-group.e-control-wrapper.e-bigger input.e-input,
  .e-bigger .e-filled.e-input-group input,
  .e-bigger .e-filled.e-input-group input.e-input,
  .e-bigger .e-filled.e-input-group.e-control-wrapper input,
  .e-bigger .e-filled.e-input-group.e-control-wrapper input.e-input,
  .e-filled.e-float-input.e-bigger input,
  .e-filled.e-float-input.e-bigger input.e-input,
  .e-bigger .e-filled.e-float-input input,
  .e-bigger .e-filled.e-float-input input.e-input,
  .e-filled.e-float-input.e-control-wrapper.e-bigger input,
  .e-filled.e-float-input.e-control-wrapper.e-bigger input.e-input,
  .e-bigger .e-filled.e-float-input.e-control-wrapper input,
  .e-bigger .e-filled.e-float-input.e-control-wrapper input.e-input,
  .e-filled.e-float-input.e-bigger:not(.e-input-group) input,
  .e-filled.e-float-input.e-bigger:not(.e-input-group) input.e-input,
  .e-bigger .e-filled.e-float-input:not(.e-input-group) input,
  .e-bigger .e-filled.e-float-input:not(.e-input-group) input.e-input,
  .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-input-group) input,
  .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-input-group) input.e-input,
  .e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-input-group) input,
  .e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-input-group) input.e-input,
  .e-filled input.e-input.e-small.e-bigger#{$css},
  .e-filled.e-input-group.e-bigger.e-small input,
  .e-filled.e-input-group.e-bigger.e-small input.e-input,
  .e-bigger.e-small .e-filled.e-input-group input,
  .e-bigger.e-small .e-filled.e-input-group input.e-input,
  .e-filled.e-input-group.e-control-wrapper.e-bigger.e-small input,
  .e-filled.e-input-group.e-control-wrapper.e-bigger.e-small input.e-input,
  .e-bigger.e-small .e-filled.e-input-group.e-control-wrapper input,
  .e-bigger.e-small .e-filled.e-input-group.e-control-wrapper input.e-input,
  .e-filled.e-float-input.e-bigger.e-small input,
  .e-filled.e-float-input.e-bigger.e-small input.e-input,
  .e-bigger.e-small .e-filled.e-float-input input,
  .e-bigger.e-small .e-filled.e-float-input input.e-input,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-small input,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-small input.e-input,
  .e-bigger.e-small .e-filled.e-float-input.e-control-wrapper input,
  .e-bigger.e-small .e-filled.e-float-input.e-control-wrapper input.e-input,
  .e-filled.e-float-input.e-bigger.e-small:not(.e-input-group) input,
  .e-filled.e-float-input.e-bigger.e-small:not(.e-input-group) input.e-input,
  .e-bigger.e-small .e-filled.e-float-input:not(.e-input-group) input,
  .e-bigger.e-small .e-filled.e-float-input:not(.e-input-group) input.e-input,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-small:not(.e-input-group) input,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-small:not(.e-input-group) input.e-input,
  .e-bigger.e-small .e-filled.e-float-input.e-control-wrapper:not(.e-input-group) input,
  .e-bigger.e-small .e-filled.e-float-input.e-control-wrapper:not(.e-input-group) input.e-input,
  .e-filled.e-input-group textarea.e-input.e-bigger,
  .e-filled.e-input-group.e-control-wrapper textarea.e-input.e-bigger,
  .e-filled textarea.e-input.e-bigger#{$css},
  .e-filled.e-input-group.e-bigger textarea,
  .e-filled.e-input-group.e-bigger textarea.e-input,
  .e-bigger .e-filled.e-input-group textarea,
  .e-bigger .e-filled.e-input-group textarea.e-input,
  .e-filled.e-input-group.e-control-wrapper.e-bigger textarea,
  .e-filled.e-input-group.e-control-wrapper.e-bigger textarea.e-input,
  .e-bigger .e-filled.e-input-group.e-control-wrapper textarea,
  .e-bigger .e-filled.e-input-group.e-control-wrapper textarea.e-input,
  .e-filled.e-float-input.e-bigger textarea,
  .e-filled.e-float-input.e-bigger textarea.e-input,
  .e-bigger .e-filled.e-float-input textarea,
  .e-bigger .e-filled.e-float-input textarea.e-input,
  .e-filled.e-float-input.e-control-wrapper.e-bigger textarea,
  .e-filled.e-float-input.e-control-wrapper.e-bigger textarea.e-input,
  .e-bigger .e-filled.e-float-input.e-control-wrapper textarea,
  .e-bigger .e-filled.e-float-input.e-control-wrapper textarea.e-input,
  .e-filled textarea.e-input.e-small.e-bigger#{$css},
  .e-filled.e-input-group.e-bigger.e-small textarea,
  .e-filled.e-input-group.e-bigger.e-small textarea.e-input,
  .e-bigger.e-small .e-filled.e-input-group textarea,
  .e-bigger.e-small .e-filled.e-input-group textarea.e-input,
  .e-filled.e-input-group.e-control-wrapper.e-bigger.e-small textarea,
  .e-filled.e-input-group.e-control-wrapper.e-bigger.e-small textarea.e-input,
  .e-bigger.e-small .e-filled.e-input-group.e-control-wrapper textarea,
  .e-bigger.e-small .e-filled.e-input-group.e-control-wrapper textarea.e-input,
  .e-filled.e-float-input.e-bigger.e-small textarea,
  .e-filled.e-float-input.e-bigger.e-small textarea.e-input,
  .e-bigger.e-small .e-filled.e-float-input textarea,
  .e-bigger.e-small .e-filled.e-float-input textarea.e-input,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-small textarea,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-small textarea.e-input,
  .e-bigger.e-small .e-filled.e-float-input.e-control-wrapper textarea,
  .e-bigger.e-small .e-filled.e-float-input.e-control-wrapper textarea.e-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      box-sizing: border-box;
    }
  }

  .e-filled.e-input-group.e-bigger,
  .e-bigger .e-filled.e-input-group,
  .e-filled.e-input-group.e-control-wrapper.e-bigger,
  .e-bigger .e-filled.e-input-group.e-control-wrapper {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      margin-top: $filled-wrapper-margin;
      padding: $bigger-filled-wrapper-padding;
    }
  }

  .e-filled.e-input-group.e-small.e-bigger,
  .e-bigger.e-small .e-filled.e-input-group.e-small,
  .e-bigger .e-filled.e-input-group.e-small,
  .e-small .e-filled.e-input-group.e-bigger,
  .e-filled.e-input-group.e-control-wrapper.e-small.e-bigger,
  .e-bigger.e-small .e-filled.e-input-group.e-control-wrapper.e-small,
  .e-bigger .e-filled.e-input-group.e-control-wrapper.e-small,
  .e-small .e-filled.e-input-group.e-control-wrapper.e-bigger {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      margin-top: $filled-wrapper-margin;
      padding: $bigger-small-filled-wrapper-padding;
    }
  }

  .e-filled.e-float-input.e-bigger,
  .e-bigger .e-filled.e-float-input,
  .e-filled.e-float-input.e-control-wrapper.e-bigger,
  .e-bigger .e-filled.e-float-input.e-control-wrapper {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      margin-top: $filled-wrapper-margin;
      padding: $bigger-filled-float-input-wrapper-padding;
    }
  }

  .e-filled.e-float-input.e-small.e-bigger,
  .e-bigger.e-small .e-filled.e-float-input.e-small,
  .e-bigger .e-filled.e-float-input.e-small,
  .e-small .e-filled.e-float-input.e-bigger,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger,
  .e-bigger.e-small .e-filled.e-float-input.e-control-wrapper.e-small,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-bigger {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      margin-top: $filled-wrapper-margin;
      padding: $bigger-small-filled-float-input-wrapper-padding;
    }
  }

  .e-rtl.e-filled.e-input-group.e-bigger,
  .e-bigger .e-rtl.e-filled.e-input-group,
  .e-rtl.e-filled.e-input-group.e-control-wrapper.e-bigger,
  .e-bigger .e-rtl.e-filled.e-input-group.e-control-wrapper,
  .e-rtl .e-filled.e-input-group.e-bigger,
  .e-bigger.e-rtl .e-filled.e-input-group,
  .e-rtl .e-filled.e-input-group.e-control-wrapper.e-bigger,
  .e-bigger.e-rtl .e-filled.e-input-group.e-control-wrapper {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      padding: $bigger-filled-wrapper-rtl-padding;
    }
  }

  .e-rtl.e-filled.e-input-group.e-small.e-bigger,
  .e-bigger.e-small .e-rtl.e-filled.e-input-group.e-small,
  .e-bigger .e-rtl.e-filled.e-input-group.e-small,
  .e-small .e-rtl.e-filled.e-input-group.e-bigger,
  .e-rtl.e-filled.e-input-group.e-control-wrapper.e-small.e-bigger,
  .e-bigger.e-small .e-rtl.e-filled.e-input-group.e-control-wrapper.e-small,
  .e-bigger .e-rtl.e-filled.e-input-group.e-control-wrapper.e-small,
  .e-small .e-rtl.e-filled.e-input-group.e-control-wrapper.e-bigger,
  .e-rtl .e-filled.e-input-group.e-small.e-bigger,
  .e-bigger.e-small.e-rtl .e-filled.e-input-group.e-small,
  .e-bigger.e-rtl .e-filled.e-input-group.e-small,
  .e-small.e-rtl .e-filled.e-input-group.e-bigger,
  .e-rtl .e-filled.e-input-group.e-control-wrapper.e-small.e-bigger,
  .e-bigger.e-small.e-rtl .e-filled.e-input-group.e-control-wrapper.e-small,
  .e-bigger.e-rtl .e-filled.e-input-group.e-control-wrapper.e-small,
  .e-small.e-rtl .e-filled.e-input-group.e-control-wrapper.e-bigger {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      padding: $bigger-small-filled-wrapper-rtl-padding;
    }
  }

  .e-rtl.e-filled.e-float-input.e-bigger,
  .e-bigger .e-rtl.e-filled.e-float-input,
  .e-rtl.e-filled.e-float-input.e-control-wrapper.e-bigger,
  .e-bigger .e-rtl.e-filled.e-float-input.e-control-wrapper,
  .e-rtl .e-filled.e-float-input.e-bigger,
  .e-bigger.e-rtl .e-filled.e-float-input,
  .e-rtl .e-filled.e-float-input.e-control-wrapper.e-bigger,
  .e-bigger.e-rtl .e-filled.e-float-input.e-control-wrapper {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      padding: $bigger-filled-float-input-wrapper-rtl-padding;
    }
  }

  .e-rtl.e-filled.e-float-input.e-small.e-bigger,
  .e-bigger.e-small .e-rtl.e-filled.e-float-input.e-small,
  .e-bigger .e-rtl.e-filled.e-float-input.e-small,
  .e-small .e-rtl.e-filled.e-float-input.e-bigger,
  .e-rtl.e-filled.e-float-input.e-control-wrapper.e-small.e-bigger,
  .e-bigger.e-small .e-rtl.e-filled.e-float-input.e-control-wrapper.e-small,
  .e-bigger .e-rtl.e-filled.e-float-input.e-control-wrapper.e-small,
  .e-small .e-rtl.e-filled.e-float-input.e-control-wrapper.e-bigger,
  .e-rtl .e-filled.e-float-input.e-small.e-bigger,
  .e-bigger.e-small.e-rtl .e-filled.e-float-input.e-small,
  .e-bigger.e-rtl .e-filled.e-float-input.e-small,
  .e-small.e-rtl .e-filled.e-float-input.e-bigger,
  .e-rtl .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger,
  .e-bigger.e-small.e-rtl .e-filled.e-float-input.e-control-wrapper.e-small,
  .e-bigger.e-rtl .e-filled.e-float-input.e-control-wrapper.e-small,
  .e-small.e-rtl .e-filled.e-float-input.e-control-wrapper.e-bigger {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      padding: $bigger-small-filled-float-input-wrapper-rtl-padding;
    }
  }

  .e-filled input.e-input.e-bigger#{$css},
  .e-filled textarea.e-input.e-bigger#{$css},
  .e-filled.e-input-group.e-bigger .e-input,
  .e-filled.e-input-group.e-bigger.e-control-wrapper .e-input,
  .e-bigger .e-filled input.e-input#{$css},
  .e-bigger .e-filled textarea.e-input#{$css},
  .e-bigger .e-filled.e-input-group .e-input,
  .e-bigger .e-filled.e-input-group.e-control-wrapper .e-input,
  .e-filled.e-input-group.e-bigger .e-input:focus,
  .e-bigger .e-filled.e-input-group .e-input:focus,
  .e-filled.e-input-group.e-control-wrapper.e-bigger .e-input:focus,
  .e-bigger .e-filled.e-input-group.e-control-wrapper .e-input:focus,
  .e-filled.e-input-group.e-bigger.e-input-focus .e-input,
  .e-bigger .e-filled.e-input-group.e-input-focus .e-input,
  .e-filled.e-input-group.e-control-wrapper.e-bigger.e-input-focus .e-input,
  .e-bigger .e-filled.e-input-group.e-control-wrapper.e-input-focus .e-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      padding: $filled-bigger-input-padding;
    }
  }

  .e-filled .e-input.e-bigger#{$css}:focus,
  .e-bigger .e-filled .e-input#{$css}:focus {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      padding-bottom: $filled-bigger-input-padding-bottom;
    }
  }

  .e-filled .e-input.e-small.e-bigger#{$css}:focus,
  .e-bigger .e-filled .e-input.e-small#{$css}:focus {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      padding-bottom: $filled-bigger-small-input-padding-bottom;
    }
  }

  .e-filled .e-input.e-small.e-bigger#{$css},
  .e-bigger .e-filled .e-input#{$css}.e-small,
  .e-small .e-filled .e-input#{$css}.e-bigger,
  .e-filled.e-input-group.e-small.e-bigger .e-input,
  .e-filled.e-input-group.e-small .e-input.e-bigger,
  .e-filled.e-input-group.e-control-wrapper.e-small.e-bigger .e-input,
  .e-filled.e-input-group.e-control-wrapper.e-small .e-input.e-bigger,
  .e-bigger .e-filled.e-input-group.e-small .e-input,
  .e-bigger .e-filled.e-input-group.e-control-wrapper.e-small .e-input,
  .e-filled.e-input-group.e-small.e-bigger .e-input:focus,
  .e-filled.e-input-group.e-small .e-input.e-bigger:focus,
  .e-bigger .e-filled.e-input-group.e-small .e-input:focus,
  .e-filled.e-input-group.e-control-wrapper.e-small.e-bigger .e-input:focus,
  .e-filled.e-input-group.e-control-wrapper.e-small .e-input.e-bigger:focus,
  .e-bigger .e-filled.e-input-group.e-control-wrapper.e-small .e-input:focus,
  .e-filled.e-input-group.e-small.e-bigger.e-input-focus .e-input,
  .e-filled.e-input-group.e-small.e-input-focus .e-input.e-bigger,
  .e-bigger .e-filled.e-input-group.e-small.e-input-focus .e-input,
  .e-filled.e-input-group.e-control-wrapper.e-small.e-bigger.e-input-focus .e-input,
  .e-filled.e-input-group.e-control-wrapper.e-small.e-input-focus .e-input.e-bigger,
  .e-bigger .e-filled.e-input-group.e-control-wrapper.e-small.e-input-focus .e-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      padding: $filled-bigger-small-input-padding;
    }
  }

  .e-filled.e-float-input.e-bigger input,
  .e-filled.e-float-input input.e-bigger,
  .e-bigger .e-filled.e-float-input input,
  .e-filled.e-float-input.e-control-wrapper.e-bigger input,
  .e-filled.e-float-input.e-control-wrapper input.e-bigger,
  .e-bigger .e-filled.e-float-input.e-control-wrapper input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      padding: $filled-bigger-float-input-padding;
    }
  }

  .e-filled.e-float-input.e-small.e-bigger input,
  .e-filled.e-float-input.e-small input.e-bigger,
  .e-bigger .e-filled.e-float-input.e-small input,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger input,
  .e-filled.e-float-input.e-control-wrapper.e-small input.e-bigger,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      padding: $filled-bigger-small-float-input-padding;
    }
  }

  .e-rtl .e-filled input.e-input.e-bigger#{$css},
  .e-filled input.e-input.e-bigger.e-rtl#{$css},
  .e-bigger.e-rtl .e-filled input.e-input#{$css},
  .e-bigger .e-filled input.e-input.e-rtl#{$css},
  .e-filled.e-input-group.e-bigger.e-rtl input.e-input,
  .e-filled.e-input-group.e-control-wrapper.e-bigger.e-rtl input.e-input,
  .e-rtl .e-filled.e-input-group.e-bigger input.e-input,
  .e-rtl .e-filled.e-input-group.e-control-wrapper.e-bigger input.e-input,
  .e-filled.e-input-group.e-rtl input.e-input.e-bigger,
  .e-filled.e-input-group.e-control-wrapper.e-rtl input.e-input.e-bigger,
  .e-rtl .e-filled.e-input-group input.e-input.e-bigger,
  .e-rtl .e-filled.e-input-group.e-control-wrapper input.e-input.e-bigger,
  .e-bigger .e-filled.e-input-group.e-rtl input.e-input,
  .e-bigger .e-filled.e-input-group.e-control-wrapper.e-rtl input.e-input,
  .e-bigger.e-rtl .e-filled.e-input-group input.e-input,
  .e-bigger.e-rtl .e-filled.e-input-group.e-control-wrapper input.e-input,
  .e-bigger.e-rtl .e-filled.e-input-group input.e-input:focus,
  .e-bigger.e-rtl .e-filled.e-input-group.e-control-wrapper input.e-input:focus,
  .e-bigger .e-filled.e-input-group.e-rtl input.e-input:focus,
  .e-bigger .e-filled.e-input-group.e-control-wrapper.e-rtl input.e-input:focus,
  .e-bigger.e-rtl .e-filled.e-input-group.e-input-focus input.e-input,
  .e-bigger.e-rtl .e-filled.e-input-group.e-control-wrapper.e-input-focus input.e-input,
  .e-bigger .e-filled.e-input-group.e-rtl.e-input-focus input.e-input,
  .e-bigger .e-filled.e-input-group.e-control-wrapper.e-rtl.e-input-focus input.e-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      padding: $filled-bigger-input-rtl-padding;
      text-indent: 0;
    }
  }

  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-rtl input,
  .e-filled.e-float-input.e-bigger.e-rtl input,
  .e-rtl .e-filled.e-float-input.e-control-wrapper.e-bigger input,
  .e-rtl .e-filled.e-float-input.e-bigger input,
  .e-filled.e-float-input.e-control-wrapper.e-rtl input.e-bigger,
  .e-filled.e-float-input.e-rtl input.e-bigger,
  .e-rtl .e-filled.e-float-input.e-control-wrapper input.e-bigger,
  .e-rtl .e-filled.e-float-input input.e-bigger,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-rtl input,
  .e-bigger .e-filled.e-float-input.e-rtl input,
  .e-bigger.e-rtl .e-filled.e-float-input.e-control-wrapper input,
  .e-bigger.e-rtl .e-filled.e-float-input input,
  .e-bigger.e-rtl .e-filled.e-float-input.e-control-wrapper input:focus,
  .e-bigger.e-rtl .e-filled.e-float-input input:focus,
  .e-bigger .e-filled.e-float-input.e-rtl input:focus,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-rtl input:focus,
  .e-bigger.e-rtl .e-filled.e-float-input.e-control-wrapper.e-input-focus input,
  .e-bigger.e-rtl .e-filled.e-float-input.e-input-focus input,
  .e-bigger .e-filled.e-float-input.e-rtl.e-input-focus input,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-rtl input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      padding: $filled-bigger-float-input-rtl-padding;
      text-indent: 0;
    }
  }

  .e-bigger .e-filled input.e-input.e-small.e-rtl#{$css},
  .e-filled.e-input-group.e-small.e-bigger.e-rtl input.e-input,
  .e-filled.e-input-group.e-control-wrapper.e-small.e-bigger.e-rtl input.e-input,
  .e-filled.e-input-group.e-small.e-rtl input.e-input.e-bigger,
  .e-filled.e-input-group.e-control-wrapper.e-small.e-rtl input.e-input.e-bigger,
  .e-bigger .e-filled.e-input-group.e-small.e-rtl input.e-input,
  .e-bigger .e-filled.e-input-group.e-control-wrapper.e-small.e-rtl input.e-input,
  .e-bigger.e-rtl input.e-input.e-small,
  .e-rtl .e-filled.e-input-group.e-small.e-bigger input.e-input,
  .e-rtl .e-filled.e-input-group.e-control-wrapper.e-small.e-bigger input.e-input,
  .e-rtl .e-filled.e-input-group.e-small input.e-input.e-bigger,
  .e-rtl .e-filled.e-input-group.e-control-wrapper.e-small input.e-input.e-bigger,
  .e-bigger.e-rtl .e-filled.e-input-group.e-small input.e-input,
  .e-bigger.e-rtl .e-filled.e-input-group.e-control-wrapper.e-small input.e-input,
  .e-bigger .e-filled.e-input-group.e-small.e-rtl input.e-input:focus,
  .e-bigger .e-filled.e-input-group.e-control-wrapper.e-small.e-rtl input.e-input:focus,
  .e-small .e-filled.e-input-group.e-bigger.e-rtl input.e-input:focus,
  .e-small .e-filled.e-input-group.e-control-wrapper.e-bigger.e-rtl input.e-input:focus,
  .e-filled.e-input-group.e-bigger.e-small.e-rtl input.e-input:focus,
  .e-filled.e-input-group.e-control-wrapper.e-bigger.e-small.e-rtl input.e-input:focus,
  .e-bigger.e-rtl .e-filled.e-input-group.e-small input.e-input:focus,
  .e-bigger.e-rtl .e-filled.e-input-group.e-control-wrapper.e-small input.e-input:focus,
  .e-small.e-rtl .e-filled.e-input-group.e-bigger input.e-input:focus,
  .e-small.e-rtl .e-filled.e-input-group.e-control-wrapper.e-bigger input.e-input:focus,
  .e-rtl .e-filled.e-input-group.e-bigger.e-small input.e-input:focus,
  .e-rtl .e-filled.e-input-group.e-control-wrapper.e-bigger.e-small input.e-input:focus,
  .e-bigger .e-filled.e-input-group.e-small.e-rtl.e-input-focus input.e-input,
  .e-bigger .e-filled.e-input-group.e-control-wrapper.e-small.e-rtl.e-input-focus input.e-input,
  .e-small .e-filled.e-input-group.e-bigger.e-rtl.e-input-focus input.e-input,
  .e-small .e-filled.e-input-group.e-control-wrapper.e-bigger.e-rtl.e-input-focus input.e-input,
  .e-filled.e-input-group.e-bigger.e-small.e-rtl.e-input-focus input.e-input,
  .e-filled.e-input-group.e-control-wrapper.e-bigger.e-small.e-rtl.e-input-focus input.e-input,
  .e-bigger.e-rtl .e-filled.e-input-group.e-small.e-input-focus input.e-input,
  .e-bigger.e-rtl .e-filled.e-input-group.e-control-wrapper.e-small.e-input-focus input.e-input,
  .e-small.e-rtl .e-filled.e-input-group.e-bigger.e-input-focus input.e-input,
  .e-small.e-rtl .e-filled.e-input-group.e-control-wrapper.e-bigger.e-input-focus input.e-input,
  .e-rtl .e-filled.e-input-group.e-bigger.e-small.e-input-focus input.e-input,
  .e-rtl .e-filled.e-input-group.e-control-wrapper.e-bigger.e-small.e-input-focus input.e-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      padding: $filled-bigger-small-input-rtl-padding;
      text-indent: 0;
    }
  }

  .e-filled.e-float-input.e-small.e-bigger.e-rtl input,
  .e-filled.e-float-input.e-small.e-rtl input.e-bigger,
  .e-bigger .e-filled.e-float-input.e-small.e-rtl input,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-rtl input,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-rtl input.e-bigger,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-rtl input,
  .e-rtl .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger input,
  .e-rtl .e-filled.e-float-input.e-control-wrapper.e-small input.e-bigger,
  .e-bigger.e-rtl .e-filled.e-float-input.e-control-wrapper.e-small input,
  .e-rtl .e-filled.e-float-input.e-small.e-bigger input,
  .e-rtl .e-filled.e-float-input.e-small input.e-bigger,
  .e-bigger.e-rtl .e-filled.e-float-input.e-small input,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-rtl input:focus,
  .e-bigger .e-filled.e-float-input.e-small.e-rtl input:focus,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-bigger.e-rtl input:focus,
  .e-small .e-filled.e-float-input.e-bigger.e-rtl input:focus,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-small.e-rtl input:focus,
  .e-filled.e-float-input.e-bigger.e-small.e-rtl input:focus,
  .e-bigger.e-rtl .e-filled.e-float-input.e-control-wrapper.e-small input:focus,
  .e-bigger.e-rtl .e-filled.e-float-input.e-small input:focus,
  .e-small.e-rtl .e-filled.e-float-input.e-control-wrapper.e-bigger input:focus,
  .e-small.e-rtl .e-filled.e-float-input.e-bigger input:focus,
  .e-rtl .e-filled.e-float-input.e-control-wrapper.e-bigger.e-small input:focus,
  .e-rtl .e-filled.e-float-input.e-bigger.e-small input:focus,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-rtl.e-input-focus input,
  .e-bigger .e-filled.e-float-input.e-small.e-rtl.e-input-focus input,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-bigger.e-rtl.e-input-focus input,
  .e-small .e-filled.e-float-input.e-bigger.e-rtl.e-input-focus input,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-small.e-rtl.e-input-focus input,
  .e-filled.e-float-input.e-bigger.e-small.e-rtl.e-input-focus input,
  .e-bigger.e-rtl .e-filled.e-float-input.e-control-wrapper.e-small.e-input-focus input,
  .e-bigger.e-rtl .e-filled.e-float-input.e-small.e-input-focus input,
  .e-small.e-rtl .e-filled.e-float-input.e-control-wrapper.e-bigger.e-input-focus input,
  .e-small.e-rtl .e-filled.e-float-input.e-bigger.e-input-focus input,
  .e-rtl .e-filled.e-float-input.e-control-wrapper.e-bigger.e-small.e-input-focus input,
  .e-rtl .e-filled.e-float-input.e-bigger.e-small.e-input-focus input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      padding: $filled-bigger-small-float-input-rtl-padding;
      text-indent: 0;
    }
  }

  .e-filled.e-float-input.e-bigger,
  .e-bigger .e-filled.e-float-input,
  .e-filled.e-float-input.e-control-wrapper.e-bigger,
  .e-bigger .e-filled.e-float-input.e-control-wrapper,
  .e-filled.e-float-input.e-bigger.e-disabled,
  .e-bigger .e-filled.e-float-input.e-disabled,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-disabled,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-disabled,
  .e-filled.e-float-input.e-input-group.e-bigger.e-disabled,
  .e-bigger .e-filled.e-float-input.e-input-group.e-disabled,
  .e-filled.e-float-input.e-input-group.e-control-wrapper.e-bigger.e-disabled,
  .e-bigger .e-filled.e-float-input.e-input-group.e-control-wrapper.e-disabled {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      font-size: $bigger-filled-input-font-size;
    }
  }

  .e-filled.e-float-input.e-small.e-bigger,
  .e-small.e-bigger .e-filled.e-float-input,
  .e-bigger .e-filled.e-float-input.e-small,
  .e-small .e-filled.e-float-input.e-bigger,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger,
  .e-small.e-bigger .e-filled.e-float-input.e-control-wrapper,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-bigger,
  .e-filled.e-float-input.e-small.e-bigger.e-disabled,
  .e-small.e-bigger .e-filled.e-float-input.e-disabled,
  .e-bigger .e-filled.e-float-input.e-small.e-disabled,
  .e-small .e-filled.e-float-input.e-bigger.e-disabled,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-disabled,
  .e-small.e-bigger .e-filled.e-float-input.e-control-wrapper.e-disabled,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-disabled,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-bigger.e-disabled,
  .e-filled.e-float-input.e-input-group.e-small.e-bigger.e-disabled,
  .e-small.e-bigger .e-filled.e-float-input.e-input-group.e-disabled,
  .e-bigger .e-filled.e-float-input.e-input-group.e-small.e-disabled,
  .e-small .e-filled.e-float-input.e-input-group.e-bigger.e-disabled,
  .e-filled.e-float-input.e-input-group.e-control-wrapper.e-small.e-bigger.e-disabled,
  .e-small.e-bigger .e-filled.e-float-input.e-input-group.e-control-wrapper.e-disabled,
  .e-bigger .e-filled.e-float-input.e-input-group.e-control-wrapper.e-small.e-disabled,
  .e-small .e-filled.e-float-input.e-input-group.e-control-wrapper.e-bigger.e-disabled {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      font-size: $bigger-small-filled-input-font-size;
    }
  }

  .e-filled.e-input-group.e-bigger:not(.e-float-input) .e-input,
  .e-bigger .e-filled.e-input-group:not(.e-float-input) .e-input,
  .e-filled.e-input-group.e-control-wrapper.e-bigger:not(.e-float-input) .e-input,
  .e-bigger .e-filled.e-input-group.e-control-wrapper:not(.e-float-input) .e-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      min-height: $bigger-filled-default-input-min-height;
    }
  }

  .e-filled.e-float-input.e-bigger input,
  .e-bigger .e-filled.e-float-input input,
  .e-filled.e-float-input.e-input-group.e-bigger input,
  .e-bigger .e-filled.e-float-input.e-input-group input,
  .e-filled.e-float-input.e-input-group.e-control-wrapper.e-bigger input,
  .e-bigger .e-filled.e-float-input.e-input-group.e-control-wrapper input,
  .e-filled.e-float-input.e-control-wrapper.e-bigger input,
  .e-bigger .e-filled.e-float-input.e-control-wrapper input,
  .e-filled.e-float-input.e-control-wrapper.e-input-group.e-bigger input,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-input-group input,
  .e-filled.e-float-input.e-control-wrapper.e-input-group.e-bigger input,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-input-group input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      min-height: $bigger-filled-input-min-height;
    }
  }

  .e-filled.e-input-group.e-bigger.e-small:not(.e-float-input) .e-input,
  .e-bigger .e-filled.e-input-group.e-small:not(.e-float-input) .e-input .e-small .e-filled.e-input-group.e-bigger:not(.e-float-input) .e-input,
  .e-filled.e-input-group.e-control-wrapper.e-bigger.e-small:not(.e-float-input) .e-input,
  .e-bigger .e-filled.e-input-group.e-control-wrapper.e-small:not(.e-float-input) .e-input .e-small .e-filled.e-input-group.e-control-wrapper.e-bigger:not(.e-float-input) .e-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      min-height: $bigger-small-filled-default-input-min-height;
    }
  }

  .e-float-input.e-bigger.e-small.e-filled input,
  .e-bigger .e-float-input.e-filled.e-small input,
  .e-small .e-float-input.e-filled.e-bigger input,
  .e-float-input.e-filled.e-input-group.e-bigger.e-small input,
  .e-bigger .e-float-input.e-filled.e-input-group.e-small input,
  .e-small .e-float-input.e-filled.e-input-group.e-bigger input,
  .e-float-input.e-filled.e-input-group.e-control-wrapper.e-bigger.e-small input,
  .e-bigger .e-float-input.e-filled.e-input-group.e-control-wrapper.e-small input,
  .e-small .e-float-input.e-filled.e-input-group.e-control-wrapper.e-bigger input,
  .e-float-input.e-filled.e-control-wrapper.e-bigger.e-small input,
  .e-bigger .e-float-input.e-filled.e-control-wrapper.e-small input,
  .e-small .e-float-input.e-filled.e-control-wrapper.e-bigger input,
  .e-float-input.e-control-wrapper.e-filled.e-input-group.e-bigger.e-small input,
  .e-bigger .e-float-input.e-control-wrapper.e-filled.e-input-group.e-small input,
  .e-small .e-float-input.e-control-wrapper.e-filled.e-input-group.e-bigger input  {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      min-height: $bigger-small-filled-input-min-height;
    }
  }

  .e-filled.e-float-input.e-bigger input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input input:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input input ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper input:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper input ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input textarea:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input textarea:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input textarea ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper textarea:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper textarea:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper textarea ~ label.e-label-top.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      font-size: $bigger-filled-float-label-font-size;
      top: $bigger-filled-input-label-top-after-floating;
    }
    @if $input-skin-name == 'fluent2' {
      left: 2px;
    }
  }

  .e-bigger .e-filled.e-float-input input:-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-filled.e-float-input input:-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-autofill input:-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-filled.e-float-input.e-control-wrapper.e-autofill input:-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-autofill:not(.e-input-focus) input:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-filled.e-float-input.e-control-wrapper.e-autofill:not(.e-input-focus) input:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input textarea:-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-filled.e-float-input textarea:-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-autofill textarea:-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-filled.e-float-input.e-control-wrapper.e-autofill textarea:-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-autofill textarea:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-filled.e-float-input.e-control-wrapper.e-autofill textarea:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      font-size: $bigger-filled-float-label-font-size;
      top: $bigger-filled-input-label-top-after-floating;
      transform: translateY(-50%) scale(.75);
      user-select: text;
    }
  }

  .e-filled.e-float-input.e-small.e-bigger input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger input[readonly] ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger input[disabled] ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small input:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small input ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small input[readonly] ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small input[disabled] ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger input[readonly] ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger input[disabled] ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small input:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small input ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small input[readonly] ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small input[disabled] ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small textarea:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small textarea:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small textarea ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small textarea:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small textarea:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small textarea ~ label.e-label-top.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      font-size: $bigger-small-filled-float-label-font-size;
      top: $bigger-small-filled-input-label-top-after-floating;
    }
  }

  .e-bigger .e-filled.e-float-input.e-small input:-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-small .e-filled.e-float-input input:-webkit-autofill ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-small input:-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-control-wrapper.e-autofill input:-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-small .e-filled.e-float-input.e-control-wrapper.e-autofill input:-webkit-autofill ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-small.e-control-wrapper.e-autofill input:-webkit-autofill ~ label.e-float-text,
  .e-small.e-bigger .e-filled.e-float-input.e-control-wrapper.e-autofill:not(.e-input-focus) input:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-small.e-bigger.e-filled.e-float-input.e-control-wrapper.e-autofill:not(.e-input-focus) input:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-small.e-filled.e-float-input.e-control-wrapper.e-autofill:not(.e-input-focus) input:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-small .e-bigger.e-filled.e-float-input.e-control-wrapper.e-autofill:not(.e-input-focus) input:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input.e-small textarea:-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-small .e-filled.e-float-input textarea:-webkit-autofill ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-small textarea:-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-control-wrapper.e-autofill textarea:-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-small .e-filled.e-float-input.e-control-wrapper.e-autofill textarea:-webkit-autofill ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-small.e-control-wrapper.e-autofill textarea:-webkit-autofill ~ label.e-float-text,
  .e-small.e-bigger .e-filled.e-float-input.e-control-wrapper.e-autofill textarea:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-small.e-bigger.e-filled.e-float-input.e-control-wrapper.e-autofill textarea:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-small.e-filled.e-float-input.e-control-wrapper.e-autofill textarea:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-small .e-bigger.e-filled.e-float-input.e-control-wrapper.e-autofill textarea:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      font-size: $bigger-small-filled-float-label-font-size;
      top: $bigger-small-filled-input-label-top-after-floating;
      transform: translateY(-50%) scale(.75);
      user-select: text;
    }
  }

  .e-filled.e-float-input.e-bigger label.e-float-text,
  .e-filled.e-float-input label.e-float-text.e-bigger,
  .e-filled.e-float-input input.e-bigger ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input label.e-float-text,
  .e-filled.e-float-input.e-bigger:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-bigger label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper label.e-float-text.e-bigger,
  .e-filled.e-float-input.e-control-wrapper input.e-bigger ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-bigger textarea ~ label.e-float-text,
  .e-filled.e-float-input textarea ~ label.e-float-text.e-bigger,
  .e-filled.e-float-input textarea.e-bigger ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input textarea ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-bigger textarea ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper textarea ~ label.e-float-text.e-bigger,
  .e-filled.e-float-input.e-control-wrapper textarea.e-bigger ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper textarea ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      font-size: $bigger-filled-float-label-font-size;
      top: $bigger-filled-input-label-top;
    }
  }

  .e-filled.e-float-input.e-small.e-bigger label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input.e-bigger:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-bigger.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-filled.e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-bigger.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      font-size: $bigger-small-filled-float-label-font-size;
      top: $bigger-small-filled-input-label-top;
      transform: none;
    }
  }

  .e-filled.e-float-input.e-bigger.e-small input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-small input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-small input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger.e-small input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger.e-small input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger.e-small input label.e-float-text.e-label-top,
  .e-bigger .e-filled.e-float-input.e-small input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small input:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small input ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small input label.e-float-text.e-label-top,
  .e-small .e-filled.e-float-input.e-bigger input:focus ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-bigger input:valid ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-bigger input ~ label.e-label-top.e-float-text,
  .e-small .e-filled.e-float-input.e-bigger input[readonly] ~ label.e-label-top.e-float-text,
  .e-small .e-filled.e-float-input.e-bigger input[disabled] ~ label.e-label-top.e-float-text,
  .e-small .e-filled.e-float-input.e-bigger input label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-small input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-small input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-small input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-small input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-small input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-small input label.e-float-text.e-label-top,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small input:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small input ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small input label.e-float-text.e-label-top,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-bigger input:focus ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-bigger input:valid ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-bigger input ~ label.e-label-top.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-bigger input[readonly] ~ label.e-label-top.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-bigger input[disabled] ~ label.e-label-top.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-bigger input label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-bigger.e-small.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-input-focus input ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-bigger.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-small.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-input-focus input ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-bigger.e-input-focus input ~ label.e-float-text,
  .e-float-input.e-filled.e-bigger.e-small textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-small textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-small textarea ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger.e-small textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger.e-small textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger.e-small textarea label.e-float-text.e-label-top,
  .e-bigger .e-filled.e-float-input.e-small textarea:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small textarea:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small textarea label.e-float-text.e-label-top,
  .e-small .e-filled.e-float-input.e-bigger textarea:focus ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-bigger textarea:valid ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-bigger textarea ~ label.e-label-top.e-float-text,
  .e-small .e-filled.e-float-input.e-bigger textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-small .e-filled.e-float-input.e-bigger textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-small .e-filled.e-float-input.e-bigger textarea label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-small textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-small textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-small textarea ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-small textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-small textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-small textarea label.e-float-text.e-label-top,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small textarea:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small textarea:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small textarea label.e-float-text.e-label-top,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-bigger textarea:focus ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-bigger textarea:valid ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-bigger textarea ~ label.e-label-top.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-bigger textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-bigger textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-bigger textarea label.e-float-text.e-label-top {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      transform: translateY(-50%) scale(.75);
    }
  }

  .e-bigger .e-filled.e-float-input.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input.e-bigger textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-bigger.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-filled.e-float-input textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-bigger.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-bigger textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-filled.e-float-input.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      font-size: $bigger-small-filled-float-label-font-size;
      top: $bigger-small-filled-input-label-top;
    }
  }

  .e-filled.e-float-input.e-bigger label.e-float-text,
  .e-filled.e-float-input.e-bigger label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-bigger label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-bigger:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input label.e-float-text,
  .e-bigger .e-filled.e-float-input label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input.e-control-wrapper label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-bigger:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      left: $bigger-filled-input-label-left;
    }
  }

  .e-filled.e-float-input.e-bigger.e-rtl label.e-float-text,
  .e-filled.e-float-input.e-rtl.e-bigger label.e-float-text.e-label-bottom,
  .e-rtl .e-filled.e-float-input.e-bigger label.e-float-text .e-filled.e-float-input.e-control-wrapper.e-rtl.e-bigger label.e-float-text,
  .e-filled.e-float-input.e-rtl.e-control-wrapper.e-bigger label.e-float-text.e-label-bottom,
  .e-rtl .e-filled.e-float-input.e-control-wrapper.e-bigger label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-rtl label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-rtl label.e-float-text.e-label-bottom,
  .e-rtl.e-bigger .e-filled.e-float-input label.e-float-text .e-bigger .e-filled.e-float-input.e-control-wrapper.e-rtl label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-rtl.e-control-wrapper label.e-float-text.e-label-bottom,
  .e-rtl.e-bigger .e-filled.e-float-input.e-control-wrapper label.e-float-text,
  .e-bigger.e-filled.e-float-input.e-rtl:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-filled.e-float-input.e-control-wrapper.e-rtl:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl .e-bigger.e-filled.e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl .e-bigger.e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input.e-rtl:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-rtl:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl.e-bigger .e-filled.e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl.e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-filled.e-float-input.e-rtl:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-filled.e-float-input.e-control-wrapper.e-rtl:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl .e-bigger.e-filled.e-float-input:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl .e-bigger.e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input.e-rtl:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-rtl:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl.e-bigger .e-filled.e-float-input:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl.e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      right: $bigger-filled-input-label-left;
    }
  }

  .e-small .e-filled.e-float-input.e-bigger label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      left: $small-filled-input-label-left;
    }
  }

  .e-filled.e-float-input.e-small.e-bigger label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-small.e-bigger:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small.e-bigger .e-filled.e-float-input label.e-float-text,
  .e-small.e-bigger .e-filled.e-float-input label.e-float-text.e-label-bottom,
  .e-small.e-bigger .e-filled.e-float-input.e-control-wrapper label.e-float-text,
  .e-small.e-bigger .e-filled.e-float-input.e-control-wrapper label.e-float-text.e-label-bottom,
  .e-small.e-bigger .e-filled.e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small.e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-small.e-bigger:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small.e-bigger .e-filled.e-float-input:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small.e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      left: $bigger-small-filled-input-label-left;
    }
  }

  .e-filled.e-float-input.e-small.e-bigger.e-rtl label.e-float-text,
  .e-filled.e-float-input.e-rtl.e-small.e-bigger label.e-float-text.e-label-bottom,
  .e-rtl .e-filled.e-float-input.e-small.e-bigger label.e-float-text .e-filled.e-float-input.e-control-wrapper.e-rtl.e-small.e-bigger label.e-float-text,
  .e-filled.e-float-input.e-rtl.e-control-wrapper.e-small.e-bigger label.e-float-text.e-label-bottom,
  .e-rtl .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger label.e-float-text,
  .e-small.e-bigger .e-filled.e-float-input.e-rtl label.e-float-text,
  .e-small.e-bigger .e-filled.e-float-input.e-rtl label.e-float-text.e-label-bottom,
  .e-rtl.e-small.e-bigger .e-filled.e-float-input label.e-float-text .e-small.e-bigger .e-filled.e-float-input.e-control-wrapper.e-rtl label.e-float-text,
  .e-small.e-bigger .e-filled.e-float-input.e-rtl.e-control-wrapper label.e-float-text.e-label-bottom,
  .e-rtl.e-small.e-bigger .e-filled.e-float-input.e-control-wrapper label.e-float-text,
  .e-small.e-bigger.e-filled.e-float-input.e-rtl:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small.e-bigger.e-filled.e-float-input.e-control-wrapper.e-rtl:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl .e-small.e-bigger.e-filled.e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl .e-small.e-bigger.e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-bigger.e-filled.e-float-input.e-rtl:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-bigger.e-filled.e-float-input.e-control-wrapper.e-rtl:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-small.e-filled.e-float-input.e-rtl:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-small.e-filled.e-float-input.e-control-wrapper.e-rtl:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl.e-small.e-bigger .e-filled.e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl.e-small.e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small.e-bigger.e-filled.e-float-input.e-rtl:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small.e-bigger.e-filled.e-float-input.e-control-wrapper.e-rtl:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl .e-small.e-bigger.e-filled.e-float-input:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl .e-small.e-bigger.e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-bigger.e-filled.e-float-input.e-rtl:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-bigger.e-filled.e-float-input.e-control-wrapper.e-rtl:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-small.e-filled.e-float-input.e-rtl:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-small.e-filled.e-float-input.e-control-wrapper.e-rtl:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl.e-small.e-bigger .e-filled.e-float-input:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl.e-small.e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      right: $bigger-small-filled-input-label-left;
    }
  }

  .e-filled.e-float-input.e-bigger.e-rtl .e-input-in-wrap label.e-float-text,
  .e-filled.e-float-input.e-rtl.e-bigger .e-input-in-wrap label.e-float-text.e-label-bottom,
  .e-rtl .e-filled.e-float-input.e-bigger .e-input-in-wrap label.e-float-text .e-filled.e-float-input.e-control-wrapper.e-rtl.e-bigger .e-input-in-wrap label.e-float-text,
  .e-filled.e-float-input.e-rtl.e-control-wrapper.e-bigger .e-input-in-wrap label.e-float-text.e-label-bottom,
  .e-rtl .e-filled.e-float-input.e-control-wrapper.e-bigger .e-input-in-wrap label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-rtl .e-input-in-wrap label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-rtl .e-input-in-wrap label.e-float-text.e-label-bottom,
  .e-rtl.e-bigger .e-filled.e-float-input .e-input-in-wrap label.e-float-text .e-bigger .e-filled.e-float-input.e-control-wrapper.e-rtl .e-input-in-wrap label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-rtl.e-control-wrapper .e-input-in-wrap label.e-float-text.e-label-bottom,
  .e-rtl.e-bigger .e-filled.e-float-input.e-control-wrapper .e-input-in-wrap label.e-float-text,
  .e-bigger.e-filled.e-float-input.e-rtl:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-filled.e-float-input.e-control-wrapper.e-rtl:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl .e-bigger.e-filled.e-float-input:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl .e-bigger.e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input.e-rtl:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-rtl:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl.e-bigger .e-filled.e-float-input:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl.e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-small.e-bigger.e-rtl .e-input-in-wrap label.e-float-text,
  .e-filled.e-float-input.e-rtl.e-small.e-bigger .e-input-in-wrap label.e-float-text.e-label-bottom,
  .e-rtl .e-filled.e-float-input.e-small.e-bigger .e-input-in-wrap label.e-float-text .e-filled.e-float-input.e-control-wrapper.e-rtl.e-small.e-bigger .e-input-in-wrap label.e-float-text,
  .e-filled.e-float-input.e-rtl.e-control-wrapper.e-small.e-bigger .e-input-in-wrap label.e-float-text.e-label-bottom,
  .e-rtl .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger .e-input-in-wrap label.e-float-text,
  .e-small.e-bigger .e-filled.e-float-input.e-rtl .e-input-in-wrap label.e-float-text,
  .e-small.e-bigger .e-filled.e-float-input.e-rtl .e-input-in-wrap label.e-float-text.e-label-bottom,
  .e-rtl.e-small.e-bigger .e-filled.e-float-input .e-input-in-wrap label.e-float-text .e-small.e-bigger .e-filled.e-float-input.e-control-wrapper.e-rtl .e-input-in-wrap label.e-float-text,
  .e-small.e-bigger .e-filled.e-float-input.e-rtl.e-control-wrapper .e-input-in-wrap label.e-float-text.e-label-bottom,
  .e-rtl.e-small.e-bigger .e-filled.e-float-input.e-control-wrapper .e-input-in-wrap label.e-float-text,
  .e-small.e-bigger.e-filled.e-float-input.e-rtl:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small.e-bigger.e-filled.e-float-input.e-control-wrapper.e-rtl:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl .e-small.e-bigger.e-filled.e-float-input:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl .e-small.e-bigger.e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-bigger.e-filled.e-float-input.e-rtl:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-bigger.e-filled.e-float-input.e-control-wrapper.e-rtl:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-small.e-filled.e-float-input.e-rtl:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-small.e-filled.e-float-input.e-control-wrapper.e-rtl:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl.e-small.e-bigger .e-filled.e-float-input:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl.e-small.e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      right: $zero-value;
    }
  }

  .e-filled.e-input-group.e-bigger .e-input-group-icon,
  .e-filled.e-input-group .e-input-group-icon.e-bigger,
  .e-filled.e-input-group.e-control-wrapper.e-bigger .e-input-group-icon,
  .e-filled.e-input-group.e-control-wrapper .e-input-group-icon.e-bigger,
  .e-bigger .e-filled.e-input-group .e-input-group-icon,
  .e-bigger .e-filled.e-input-group.e-control-wrapper .e-input-group-icon,
  .e-filled.e-input-group.e-small.e-bigger .e-input-group-icon,
  .e-filled.e-input-group.e-small .e-input-group-icon.e-bigger,
  .e-filled.e-input-group.e-control-wrapper.e-small.e-bigger .e-input-group-icon,
  .e-filled.e-input-group.e-control-wrapper.e-small .e-input-group-icon.e-bigger,
  .e-bigger .e-filled.e-input-group.e-small .e-input-group-icon,
  .e-bigger .e-filled.e-input-group.e-control-wrapper.e-small .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      font-size: $filled-input-icon-size;
      margin-bottom: $zero-value;
      margin-top: $zero-value;
      min-height: $filled-input-icon-min-height;
      min-width: $filled-input-icon-min-height;
      padding: $zero-value $zero-value $zero-value 8px;
    }
  }

  .e-rtl.e-filled.e-input-group.e-bigger .e-input-group-icon,
  .e-rtl.e-filled.e-input-group .e-input-group-icon.e-bigger,
  .e-rtl.e-filled.e-input-group.e-control-wrapper.e-bigger .e-input-group-icon,
  .e-rtl.e-filled.e-input-group.e-control-wrapper .e-input-group-icon.e-bigger,
  .e-bigger .e-rtl.e-filled.e-input-group .e-input-group-icon,
  .e-bigger .e-rtl.e-filled.e-input-group.e-control-wrapper .e-input-group-icon,
  .e-rtl.e-filled.e-input-group.e-small.e-bigger .e-input-group-icon,
  .e-rtl.e-filled.e-input-group.e-small .e-input-group-icon.e-bigger,
  .e-rtl.e-filled.e-input-group.e-control-wrapper.e-small.e-bigger .e-input-group-icon,
  .e-rtl.e-filled.e-input-group.e-control-wrapper.e-small .e-input-group-icon.e-bigger,
  .e-bigger .e-rtl.e-filled.e-input-group.e-small .e-input-group-icon,
  .e-bigger .e-rtl.e-filled.e-input-group.e-control-wrapper.e-small .e-input-group-icon,
  .e-rtl .e-filled.e-input-group.e-bigger .e-input-group-icon,
  .e-rtl .e-filled.e-input-group .e-input-group-icon.e-bigger,
  .e-rtl .e-filled.e-input-group.e-control-wrapper.e-bigger .e-input-group-icon,
  .e-rtl .e-filled.e-input-group.e-control-wrapper .e-input-group-icon.e-bigger,
  .e-bigger.e-rtl .e-filled.e-input-group .e-input-group-icon,
  .e-bigger.e-rtl .e-filled.e-input-group.e-control-wrapper .e-input-group-icon,
  .e-rtl .e-filled.e-input-group.e-small.e-bigger .e-input-group-icon,
  .e-rtl .e-filled.e-input-group.e-small .e-input-group-icon.e-bigger,
  .e-rtl .e-filled.e-input-group.e-control-wrapper.e-small.e-bigger .e-input-group-icon,
  .e-rtl .e-filled.e-input-group.e-control-wrapper.e-small .e-input-group-icon.e-bigger,
  .e-bigger.e-rtl .e-filled.e-input-group.e-small .e-input-group-icon,
  .e-bigger.e-rtl .e-filled.e-input-group.e-control-wrapper.e-small .e-input-group-icon,
  .e-rtl.e-filled.e-float-input.e-input-group.e-bigger .e-input-group-icon,
  .e-rtl.e-filled.e-float-input.e-input-group .e-input-group-icon.e-bigger,
  .e-bigger .e-rtl.e-filled.e-float-input.e-input-group .e-input-group-icon,
  .e-rtl.e-filled.e-float-input.e-control-wrapper.e-input-group.e-bigger .e-input-group-icon,
  .e-rtl.e-filled.e-float-input.e-control-wrapper.e-input-group .e-input-group-icon.e-bigger,
  .e-bigger .e-rtl.e-filled.e-float-input.e-control-wrapper.e-input-group .e-input-group-icon,
  .e-rtl.e-filled.e-float-input.e-input-group.e-small.e-bigger .e-input-group-icon,
  .e-rtl.e-filled.e-float-input.e-input-group.e-small .e-input-group-icon.e-bigger,
  .e-bigger .e-rtl.e-filled.e-float-input.e-input-group.e-small .e-input-group-icon,
  .e-rtl.e-filled.e-float-input.e-control-wrapper.e-input-group.e-small.e-bigger .e-input-group-icon,
  .e-rtl.e-filled.e-float-input.e-control-wrapper.e-input-group.e-small .e-input-group-icon.e-bigger,
  .e-bigger .e-rtl.e-filled.e-float-input.e-control-wrapper.e-input-group.e-small .e-input-group-icon,
  .e-rtl .e-filled.e-float-input.e-input-group.e-bigger .e-input-group-icon,
  .e-rtl .e-filled.e-float-input.e-input-group .e-input-group-icon.e-bigger,
  .e-bigger.e-rtl .e-filled.e-float-input.e-input-group .e-input-group-icon,
  .e-rtl .e-filled.e-float-input.e-control-wrapper.e-input-group.e-bigger .e-input-group-icon,
  .e-rtl .e-filled.e-float-input.e-control-wrapper.e-input-group .e-input-group-icon.e-bigger,
  .e-bigger.e-rtl .e-filled.e-float-input.e-control-wrapper.e-input-group .e-input-group-icon,
  .e-rtl .e-filled.e-float-input.e-input-group.e-small.e-bigger .e-input-group-icon,
  .e-rtl .e-filled.e-float-input.e-input-group.e-small .e-input-group-icon.e-bigger,
  .e-bigger.e-rtl .e-filled.e-float-input.e-input-group.e-small .e-input-group-icon,
  .e-rtl .e-filled.e-float-input.e-control-wrapper.e-input-group.e-small.e-bigger .e-input-group-icon,
  .e-rtl .e-filled.e-float-input.e-control-wrapper.e-input-group.e-small .e-input-group-icon.e-bigger,
  .e-bigger.e-rtl .e-filled.e-float-input.e-control-wrapper.e-input-group.e-small .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      padding: $zero-value 8px $zero-value $zero-value;
    }
  }

  .e-filled.e-input-group.e-bigger .e-input-group-icon,
  .e-filled.e-input-group .e-input-group-icon.e-bigger,
  .e-filled.e-input-group.e-control-wrapper.e-bigger .e-input-group-icon,
  .e-filled.e-input-group.e-control-wrapper .e-input-group-icon.e-bigger,
  .e-bigger .e-filled.e-input-group .e-input-group-icon,
  .e-bigger .e-filled.e-input-group.e-control-wrapper .e-input-group-icon {
    @if $input-skin-name == 'material' {
      font-size: $bigger-filled-input-icon-size;
      min-height: $bigger-filled-input-icon-min-height;
      min-width: $bigger-filled-input-icon-min-height;
      padding: $zero-value $zero-value $zero-value 8px;
    }
    @if $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      font-size: $bigger-filled-input-icon-size;
      height: $bigger-filled-input-icon-min-height;
      min-width: $bigger-filled-input-icon-min-height;
    }
  }

  .e-filled.e-input-group.e-small.e-bigger .e-input-group-icon,
  .e-filled.e-input-group.e-small .e-input-group-icon.e-bigger,
  .e-filled.e-input-group.e-control-wrapper.e-small.e-bigger .e-input-group-icon,
  .e-filled.e-input-group.e-control-wrapper.e-small .e-input-group-icon.e-bigger,
  .e-bigger .e-filled.e-input-group.e-small .e-input-group-icon,
  .e-bigger .e-filled.e-input-group.e-control-wrapper.e-small .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      font-size: $bigger-small-filled-input-icon-size;
      min-height: $bigger-small-filled-input-icon-min-height;
      min-width: $bigger-small-filled-input-icon-min-height;
      padding: $zero-value $zero-value $zero-value 8px;
    }
  }

  .e-filled.e-float-input.e-input-group.e-bigger .e-input-group-icon,
  .e-filled.e-float-input.e-input-group .e-input-group-icon.e-bigger,
  .e-bigger .e-filled.e-float-input.e-input-group .e-input-group-icon,
  .e-filled.e-float-input.e-control-wrapper.e-input-group.e-bigger .e-input-group-icon,
  .e-filled.e-float-input.e-control-wrapper.e-input-group .e-input-group-icon.e-bigger,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-input-group .e-input-group-icon,
  .e-filled.e-float-input.e-input-group.e-small.e-bigger .e-input-group-icon,
  .e-filled.e-float-input.e-input-group.e-small .e-input-group-icon.e-bigger,
  .e-bigger .e-filled.e-float-input.e-input-group.e-small .e-input-group-icon,
  .e-filled.e-float-input.e-control-wrapper.e-input-group.e-small.e-bigger .e-input-group-icon,
  .e-filled.e-float-input.e-control-wrapper.e-input-group.e-small .e-input-group-icon.e-bigger,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-input-group.e-small .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      font-size: $filled-input-icon-size;
      margin-bottom: $zero-value;
      margin-top: $zero-value;
      padding: 8px;
      @if $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
        padding: 0;
        margin: 9px 12px 9px 0;
      }
    }
  }

  .e-filled.e-float-input.e-input-group.e-bigger .e-input-group-icon,
  .e-filled.e-float-input.e-input-group .e-input-group-icon.e-bigger,
  .e-bigger .e-filled.e-float-input.e-input-group .e-input-group-icon,
  .e-filled.e-float-input.e-control-wrapper.e-input-group.e-bigger .e-input-group-icon,
  .e-filled.e-float-input.e-control-wrapper.e-input-group .e-input-group-icon.e-bigger,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-input-group .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      font-size: $bigger-filled-input-icon-size;
      padding: $zero-value $zero-value $zero-value 8px;
    }
  }

  .e-filled.e-float-input.e-input-group.e-small.e-bigger .e-input-group-icon,
  .e-filled.e-float-input.e-input-group.e-small .e-input-group-icon.e-bigger,
  .e-bigger .e-filled.e-float-input.e-input-group.e-small .e-input-group-icon,
  .e-filled.e-float-input.e-control-wrapper.e-input-group.e-small.e-bigger .e-input-group-icon,
  .e-filled.e-float-input.e-control-wrapper.e-input-group.e-small .e-input-group-icon.e-bigger,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-input-group.e-small .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      font-size: $bigger-small-filled-input-icon-size;
      padding: $zero-value $zero-value $zero-value 8px;
    }
  }

  .e-filled.e-input-group.e-bigger .e-clear-icon,
  .e-filled.e-input-group.e-control-wrapper.e-bigger .e-clear-icon,
  .e-filled.e-input-group .e-clear-icon.e-bigger,
  .e-filled.e-input-group.e-control-wrapper .e-clear-icon.e-bigger,
  .e-bigger .e-filled.e-input-group .e-clear-icon,
  .e-bigger .e-filled.e-input-group.e-control-wrapper .e-clear-icon {
    @if $input-skin-name == 'material' {
      font-size: $bigger-filled-input-clear-icon-size;
      min-height: $bigger-filled-input-icon-min-height;
      min-width: $bigger-filled-input-icon-min-height;
      padding: $zero-value $zero-value $zero-value 8px;
    }
    @if $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      font-size: $bigger-filled-input-clear-icon-size;
      height: $bigger-filled-input-icon-min-height;
      min-width: $bigger-filled-input-icon-min-height;
    }
  }

  .e-filled.e-input-group.e-float-input.e-bigger .e-clear-icon,
  .e-filled.e-input-group.e-float-input .e-clear-icon.e-bigger,
  .e-bigger .e-filled.e-input-group.e-float-input .e-clear-icon,
  .e-filled.e-input-group.e-control-wrapper.e-float-input.e-bigger .e-clear-icon,
  .e-filled.e-input-group.e-control-wrapper.e-float-input .e-clear-icon.e-bigger,
  .e-bigger .e-filled.e-input-group.e-control-wrapper.e-float-input .e-clear-icon {
    @if $input-skin-name == 'material' {
      padding: $zero-value $zero-value $zero-value 8px;
    }
    @if $input-skin-name == 'Material3' {
      padding: $zero-value;
      margin: 8px;
    }
    @if $input-skin-name == 'fluent2' {
      padding: $input-clear-icon-padding;
      margin: $input-clear-icon-margin;
    }
  }

  .e-filled.e-input-group.e-small.e-bigger .e-clear-icon,
  .e-filled.e-input-group.e-small .e-clear-icon.e-bigger,
  .e-bigger .e-filled.e-input-group.e-small .e-clear-icon,
  .e-filled.e-input-group.e-control-wrapper.e-small.e-bigger .e-clear-icon,
  .e-filled.e-input-group.e-control-wrapper.e-small .e-clear-icon.e-bigger,
  .e-bigger .e-filled.e-input-group.e-control-wrapper.e-small .e-clear-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      font-size: $bigger-small-filled-input-clear-icon-size;
      min-height: $bigger-small-filled-input-icon-min-height;
      min-width: $bigger-small-filled-input-icon-min-height;
      padding: $zero-value $zero-value $zero-value 8px;
    }
    @if $input-skin-name == 'fluent2' {
      font-size: $bigger-small-filled-input-clear-icon-size;
      min-height: $bigger-small-filled-input-icon-min-height;
      min-width: $bigger-small-filled-input-icon-min-height;
      padding: $input-clear-icon-padding;
    }
  }

  .e-filled.e-input-group.e-float-input.e-small.e-bigger .e-clear-icon,
  .e-filled.e-input-group.e-float-input.e-small .e-clear-icon.e-bigger,
  .e-bigger .e-filled.e-input-group.e-float-input.e-small .e-clear-icon,
  .e-filled.e-input-group.e-control-wrapper.e-float-input.e-small.e-bigger .e-clear-icon,
  .e-filled.e-input-group.e-control-wrapper.e-float-input.e-small .e-clear-icon.e-bigger,
  .e-bigger .e-filled.e-input-group.e-control-wrapper.e-float-input.e-small .e-clear-icon,
  .e-filled.e-input-group.e-float-input.e-control-wrapper.e-small.e-bigger .e-clear-icon,
  .e-filled.e-input-group.e-float-input.e-control-wrapper.e-small .e-clear-icon.e-bigger,
  .e-bigger .e-filled.e-input-group.e-float-input.e-control-wrapper.e-small .e-clear-icon,
  .e-filled.e-input-group.e-control-wrapper.e-float-input.e-small.e-bigger .e-clear-icon,
  .e-filled.e-input-group.e-control-wrapper.e-float-input.e-small .e-clear-icon.e-bigger,
  .e-bigger .e-filled.e-input-group.e-control-wrapper.e-float-input.e-small .e-clear-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      padding: $zero-value $zero-value $zero-value 8px;
    }
  }

  .e-rtl.e-filled.e-input-group.e-bigger .e-clear-icon,
  .e-rtl.e-filled.e-input-group .e-clear-icon.e-bigger,
  .e-rtl.e-filled.e-input-group.e-control-wrapper.e-bigger .e-clear-icon,
  .e-rtl.e-filled.e-input-group.e-control-wrapper .e-clear-icon.e-bigger,
  .e-bigger .e-rtl.e-filled.e-input-group .e-clear-icon,
  .e-bigger .e-rtl.e-filled.e-input-group.e-control-wrapper .e-clear-icon,
  .e-rtl.e-filled.e-input-group.e-small.e-bigger .e-clear-icon,
  .e-rtl.e-filled.e-input-group.e-small .e-clear-icon.e-bigger,
  .e-rtl.e-filled.e-input-group.e-control-wrapper.e-small.e-bigger .e-clear-icon,
  .e-rtl.e-filled.e-input-group.e-control-wrapper.e-small .e-clear-icon.e-bigger,
  .e-bigger .e-rtl.e-filled.e-input-group.e-small .e-clear-icon,
  .e-bigger .e-rtl.e-filled.e-input-group.e-control-wrapper.e-small .e-clear-icon,
  .e-rtl.e-filled.e-input-group.e-small .e-clear-icon,
  .e-rtl .e-filled.e-input-group.e-bigger .e-clear-icon,
  .e-rtl .e-filled.e-input-group .e-clear-icon.e-bigger,
  .e-rtl .e-filled.e-input-group.e-control-wrapper.e-bigger .e-clear-icon,
  .e-rtl .e-filled.e-input-group.e-control-wrapper .e-clear-icon.e-bigger,
  .e-bigger.e-rtl .e-filled.e-input-group .e-clear-icon,
  .e-bigger.e-rtl .e-filled.e-input-group.e-control-wrapper .e-clear-icon,
  .e-rtl .e-filled.e-input-group.e-small.e-bigger .e-clear-icon,
  .e-rtl .e-filled.e-input-group.e-small .e-clear-icon.e-bigger,
  .e-rtl .e-filled.e-input-group.e-control-wrapper.e-small.e-bigger .e-clear-icon,
  .e-rtl .e-filled.e-input-group.e-control-wrapper.e-small .e-clear-icon.e-bigger,
  .e-bigger.e-rtl .e-filled.e-input-group.e-small .e-clear-icon,
  .e-bigger.e-rtl .e-filled.e-input-group.e-control-wrapper.e-small .e-clear-icon,
  .e-rtl.e-filled.e-float-input.e-input-group.e-bigger .e-clear-icon,
  .e-rtl.e-filled.e-float-input.e-input-group .e-clear-icon.e-bigger,
  .e-bigger .e-rtl.e-filled.e-float-input.e-input-group .e-clear-icon,
  .e-rtl.e-filled.e-float-input.e-control-wrapper.e-input-group.e-bigger .e-clear-icon,
  .e-rtl.e-filled.e-float-input.e-control-wrapper.e-input-group .e-clear-icon.e-bigger,
  .e-bigger .e-rtl.e-filled.e-float-input.e-control-wrapper.e-input-group .e-clear-icon,
  .e-rtl.e-filled.e-float-input.e-input-group.e-small.e-bigger .e-clear-icon,
  .e-rtl.e-filled.e-float-input.e-input-group.e-small .e-clear-icon.e-bigger,
  .e-bigger .e-rtl.e-filled.e-float-input.e-input-group.e-small .e-clear-icon,
  .e-rtl.e-filled.e-float-input.e-control-wrapper.e-input-group.e-small.e-bigger .e-clear-icon,
  .e-rtl.e-filled.e-float-input.e-control-wrapper.e-input-group.e-small .e-clear-icon.e-bigger,
  .e-bigger .e-rtl.e-filled.e-float-input.e-control-wrapper.e-input-group.e-small .e-clear-icon,
  .e-rtl .e-filled.e-float-input.e-input-group.e-bigger .e-clear-icon,
  .e-rtl .e-filled.e-float-input.e-input-group .e-clear-icon.e-bigger,
  .e-bigger.e-rtl .e-filled.e-float-input.e-input-group .e-clear-icon,
  .e-rtl .e-filled.e-float-input.e-control-wrapper.e-input-group.e-bigger .e-clear-icon,
  .e-rtl .e-filled.e-float-input.e-control-wrapper.e-input-group .e-clear-icon.e-bigger,
  .e-bigger.e-rtl .e-filled.e-float-input.e-control-wrapper.e-input-group .e-clear-icon,
  .e-rtl .e-filled.e-float-input.e-input-group.e-small.e-bigger .e-clear-icon,
  .e-rtl .e-filled.e-float-input.e-input-group.e-small .e-clear-icon.e-bigger,
  .e-bigger.e-rtl .e-filled.e-float-input.e-input-group.e-small .e-clear-icon,
  .e-rtl .e-filled.e-float-input.e-control-wrapper.e-input-group.e-small.e-bigger .e-clear-icon,
  .e-rtl .e-filled.e-float-input.e-control-wrapper.e-input-group.e-small .e-clear-icon.e-bigger,
  .e-bigger.e-rtl .e-filled.e-float-input.e-control-wrapper.e-input-group.e-small .e-clear-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      padding: $zero-value 8px $zero-value $zero-value;
    }
  }

  .e-filled.e-float-input.e-bigger .e-clear-icon::before,
  .e-filled.e-float-input.e-control-wrapper.e-bigger .e-clear-icon::before,
  .e-filled.e-input-group.e-bigger .e-clear-icon::before,
  .e-filled.e-input-group.e-control-wrapper.e-bigger .e-clear-icon::before,
  .e-filled.e-float-input.e-control-wrapper input.e-bigger:first-child ~ .e-clear-icon::before,
  .e-bigger .e-filled.e-float-input.e-control-wrapper .e-clear-icon::before,
  .e-filled.e-float-input input.e-bigger:first-child ~ .e-clear-icon::before,
  .e-bigger .e-filled.e-float-input .e-clear-icon::before,
  .e-bigger .e-filled.e-input-group .e-clear-icon::before,
  .e-bigger .e-filled.e-input-group.e-control-wrapper .e-clear-icon::before {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      font-size: $bigger-filled-input-clear-icon-size;
    }
  }

  .e-filled.e-float-input.e-small.e-bigger .e-clear-icon::before,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger .e-clear-icon::before,
  .e-filled.e-input-group.e-small.e-bigger .e-clear-icon::before,
  .e-filled.e-input-group.e-control-wrapper.e-small.e-bigger .e-clear-icon::before,
  .e-small .e-filled.e-input-group.e-bigger input:first-child ~ .e-clear-icon::before,
  .e-small .e-filled.e-input-group.e-control-wrapper.e-bigger input:first-child ~ .e-clear-icon::before,
  .e-bigger .e-filled.e-input-group.e-small input:first-child ~ .e-clear-icon::before,
  .e-bigger .e-filled.e-input-group.e-control-wrapper.e-small input:first-child ~ .e-clear-icon::before,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-bigger input:first-child ~ .e-clear-icon::before,
  .e-small .e-filled.e-float-input.e-bigger input:first-child ~ .e-clear-icon::before,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small input:first-child ~ .e-clear-icon::before,
  .e-bigger .e-filled.e-float-input.e-small input:first-child ~ .e-clear-icon::before {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      font-size: $bigger-small-filled-input-clear-icon-size;
    }
  }

  .e-filled.e-float-input.e-bigger .e-input-in-wrap input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger .e-input-in-wrap input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger .e-input-in-wrap input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger .e-input-in-wrap input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger .e-input-in-wrap input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input .e-input-in-wrap input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input .e-input-in-wrap input:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input .e-input-in-wrap input ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input .e-input-in-wrap input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input .e-input-in-wrap input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger .e-input-in-wrap input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger .e-input-in-wrap input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger .e-input-in-wrap input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger .e-input-in-wrap input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger .e-input-in-wrap input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper .e-input-in-wrap input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper .e-input-in-wrap input:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper .e-input-in-wrap input ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper .e-input-in-wrap input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper .e-input-in-wrap input[disabled] ~ label.e-label-top.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      top: $bigger-filled-input-label-top-after-floating;
    }
  }

  .e-filled.e-float-input.e-small.e-bigger .e-input-in-wrap input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger .e-input-in-wrap input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger .e-input-in-wrap input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger .e-input-in-wrap input[readonly] ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger .e-input-in-wrap input[disabled] ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small .e-input-in-wrap input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small .e-input-in-wrap input:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small .e-input-in-wrap input ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small .e-input-in-wrap input[readonly] ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small .e-input-in-wrap input[disabled] ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger .e-input-in-wrap input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger .e-input-in-wrap input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger .e-input-in-wrap input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger .e-input-in-wrap input[readonly] ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger .e-input-in-wrap input[disabled] ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small .e-input-in-wrap input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small .e-input-in-wrap input:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small .e-input-in-wrap input ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small .e-input-in-wrap input[readonly] ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small .e-input-in-wrap input[disabled] ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      top: $bigger-small-filled-input-label-top-after-floating;
    }
  }

  .e-filled.e-float-input.e-bigger .e-input-in-wrap label.e-float-text,
  .e-filled.e-float-input .e-input-in-wrap label.e-float-text.e-bigger,
  .e-filled.e-float-input .e-input-in-wrap input.e-bigger ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input .e-input-in-wrap label.e-float-text,
  .e-filled.e-float-input.e-bigger:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-bigger .e-input-in-wrap label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper .e-input-in-wrap label.e-float-text.e-bigger,
  .e-filled.e-float-input.e-control-wrapper .e-input-in-wrap input.e-bigger ~ label.e-float-text,
  .e-bigger.e-filled.e-float-input.e-control-wrapper .e-input-in-wrap label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom  {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      top: $bigger-filled-input-label-top;
    }
  }

  .e-filled.e-float-input.e-small.e-bigger .e-input-in-wrap label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small .e-input-in-wrap label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input.e-bigger:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-bigger.e-small:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-filled.e-float-input:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger .e-input-in-wrap label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small .e-input-in-wrap label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-small:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      top: $bigger-small-filled-input-label-top;
    }
  }

  .e-filled.e-input-group:not(.e-float-input).e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-filled.e-input-group:not(.e-float-input).e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-filled.e-input-group:not(.e-float-input).e-float-icon-left > .e-input-group-icon,
  .e-filled.e-input-group:not(.e-float-input).e-control-wrapper.e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-filled.e-input-group:not(.e-float-input).e-control-wrapper.e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-filled.e-input-group:not(.e-float-input).e-control-wrapper.e-float-icon-left > .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      font-size: $bigger-filled-input-icon-size;
      margin: $zero-value;
      min-height: $bigger-filled-input-left-icon-min-height;
      min-width: $bigger-filled-input-left-icon-min-width;
      padding: $zero-value;
    }
  }

  .e-filled.e-input-group:not(.e-float-input).e-small.e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-filled.e-input-group:not(.e-float-input).e-small.e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-filled.e-input-group:not(.e-float-input).e-small.e-float-icon-left > .e-input-group-icon,
  .e-filled.e-input-group.e-control-wrapper:not(.e-float-input).e-small.e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-filled.e-input-group.e-control-wrapper:not(.e-float-input).e-small.e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-filled.e-input-group.e-control-wrapper:not(.e-float-input).e-small.e-float-icon-left > .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      font-size: $bigger-small-filled-input-icon-size;
      margin: $zero-value;
      min-height: $bigger-small-filled-input-left-icon-min-height;
      min-width: $bigger-small-filled-input-left-icon-min-width;
      padding: $zero-value;
    }
  }

  .e-filled.e-input-group.e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-filled.e-input-group.e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-filled.e-input-group.e-float-icon-left > .e-input-group-icon,
  .e-filled.e-float-input.e-input-group.e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-filled.e-float-input.e-input-group.e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-filled.e-float-input.e-input-group.e-float-icon-left > .e-input-group-icon,
  .e-filled.e-input-group.e-control-wrapper.e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-filled.e-input-group.e-control-wrapper.e-float-icon-left > .e-input-group-icon,
  .e-filled.e-float-input.e-input-group.e-control-wrapper.e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-filled.e-float-input.e-input-group.e-control-wrapper.e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-filled.e-float-input.e-input-group.e-control-wrapper.e-float-icon-left > .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      margin: $zero-value;
      min-height: $bigger-filled-input-left-icon-min-height;
      min-width: $bigger-filled-input-left-icon-min-width;
      padding: $zero-value;
    }
  }

  .e-filled.e-input-group.e-small.e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-filled.e-input-group.e-small.e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-filled.e-input-group.e-small.e-float-icon-left > .e-input-group-icon,
  .e-filled.e-float-input.e-input-group.e-small.e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-filled.e-float-input.e-input-group.e-small.e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-filled.e-input-group.e-float-input.e-small.e-float-icon-left > .e-input-group-icon,
  .e-filled.e-input-group.e-control-wrapper.e-small.e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-filled.e-input-group.e-control-wrapper.e-small.e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-filled.e-input-group.e-control-wrapper.e-small.e-float-icon-left > .e-input-group-icon,
  .e-filled.e-float-input.e-control-wrapper.e-input-group.e-small.e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-filled.e-float-input.e-control-wrapper.e-input-group.e-small.e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-filled.e-input-group.e-control-wrapper.e-float-input.e-small.e-float-icon-left > .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      margin: $zero-value;
      min-height: $bigger-small-filled-input-left-icon-min-height;
      min-width: $bigger-small-filled-input-left-icon-min-width;
      padding: $zero-value;
    }
  }

  .e-filled.e-float-input.e-bigger .e-input-in-wrap label.e-float-text,
  .e-filled.e-float-input.e-bigger .e-input-in-wrap label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-bigger .e-input-in-wrap label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger .e-input-in-wrap label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-bigger:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom .e-bigger .e-filled.e-float-input .e-input-in-wrap label.e-float-text,
  .e-bigger .e-filled.e-float-input .e-input-in-wrap label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input.e-control-wrapper .e-input-in-wrap label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper .e-input-in-wrap label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-small.e-bigger .e-input-in-wrap label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger .e-input-in-wrap label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger .e-input-in-wrap label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger .e-input-in-wrap label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-small.e-bigger:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom .e-small.e-bigger .e-filled.e-float-input .e-input-in-wrap label.e-float-text,
  .e-small.e-bigger .e-filled.e-float-input .e-input-in-wrap label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom .e-small .e-filled.e-float-input.e-bigger .e-input-in-wrap label.e-float-text,
  .e-small.e-bigger .e-filled.e-float-input.e-control-wrapper .e-input-in-wrap label.e-float-text,
  .e-small.e-bigger .e-filled.e-float-input.e-control-wrapper .e-input-in-wrap label.e-float-text.e-label-bottom,
  .e-small.e-bigger .e-filled.e-float-input:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small.e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      left: $zero-value;
    }
  }

  .e-filled.e-input-group.e-bigger .e-input-group-icon:last-child,
  .e-bigger .e-filled.e-input-group .e-input-group-icon:last-child,
  .e-filled.e-input-group.e-small.e-bigger .e-input-group-icon:last-child,
  .e-small.e-bigger .e-filled.e-input-group .e-input-group-icon:last-child,
  .e-bigger .e-filled.e-input-group.e-small .e-input-group-icon:last-child,
  .e-small .e-filled.e-input-group.e-bigger .e-input-group-icon:last-child,
  .e-filled.e-input-group.e-bigger.e-control-wrapper .e-input-group-icon:last-child,
  .e-bigger .e-filled.e-input-group.e-control-wrapper .e-input-group-icon:last-child,
  .e-filled.e-input-group.e-small.e-bigger.e-control-wrapper .e-input-group-icon:last-child,
  .e-small.e-bigger .e-filled.e-input-group.e-control-wrapper .e-input-group-icon:last-child,
  .e-bigger .e-filled.e-input-group.e-small.e-control-wrapper .e-input-group-icon:last-child,
  .e-small .e-filled.e-input-group.e-bigger.e-control-wrapper .e-input-group-icon:last-child,
  .e-filled.e-input-group.e-small.e-bigger .e-input-group-icon,
  .e-filled.e-input-group.e-control-wrapper.e-small.e-bigger .e-input-group-icon,
  .e-small.e-bigger .e-filled.e-input-group .e-input-group-icon,
  .e-small.e-bigger .e-filled.e-input-group.e-control-wrapper .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      margin-right: $zero-value;
    }
  }

  .e-filled.e-input-group.e-bigger.e-rtl .e-input-group-icon:last-child,
  .e-bigger .e-filled.e-input-group.e-rtl .e-input-group-icon:last-child,
  .e-filled.e-input-group.e-small.e-bigger.e-rtl .e-input-group-icon:last-child,
  .e-small.e-bigger .e-filled.e-input-group.e-rtl .e-input-group-icon:last-child,
  .e-bigger .e-filled.e-input-group.e-small.e-rtl .e-input-group-icon:last-child,
  .e-small .e-filled.e-input-group.e-bigger.e-rtl .e-input-group-icon:last-child,
  .e-filled.e-input-group.e-bigger.e-control-wrapper.e-rtl .e-input-group-icon:last-child,
  .e-bigger .e-filled.e-input-group.e-control-wrapper.e-rtl .e-input-group-icon:last-child,
  .e-filled.e-input-group.e-small.e-bigger.e-control-wrapper.e-rtl .e-input-group-icon:last-child,
  .e-small.e-bigger .e-filled.e-input-group.e-control-wrapper.e-rtl .e-input-group-icon:last-child,
  .e-bigger .e-filled.e-input-group.e-small.e-control-wrapper.e-rtl .e-input-group-icon:last-child,
  .e-small .e-filled.e-input-group.e-bigger.e-control-wrapper.e-rtl .e-input-group-icon:last-child,
  .e-rtl .e-filled.e-input-group.e-bigger .e-input-group-icon:last-child,
  .e-rtl.e-bigger .e-filled.e-input-group .e-input-group-icon:last-child,
  .e-rtl .e-filled.e-input-group.e-small.e-bigger .e-input-group-icon:last-child,
  .e-rtl.e-small.e-bigger .e-filled.e-input-group .e-input-group-icon:last-child,
  .e-rtl.e-bigger .e-filled.e-input-group.e-small .e-input-group-icon:last-child,
  .e-rtl.e-small .e-filled.e-input-group.e-bigger .e-input-group-icon:last-child,
  .e-rtl .e-filled.e-input-group.e-bigger.e-control-wrapper .e-input-group-icon:last-child,
  .e-rtl.e-bigger .e-filled.e-input-group.e-control-wrapper .e-input-group-icon:last-child,
  .e-rtl .e-filled.e-input-group.e-small.e-bigger.e-control-wrapper .e-input-group-icon:last-child,
  .e-rtl.e-small.e-bigger .e-filled.e-input-group.e-control-wrapper .e-input-group-icon:last-child,
  .e-rtl.e-bigger .e-filled.e-input-group.e-small.e-control-wrapper .e-input-group-icon:last-child,
  .e-rtl.e-small .e-filled.e-input-group.e-bigger.e-control-wrapper .e-input-group-icon:last-child {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      margin-left: $zero-value;
    }
  }

  .e-filled.e-rtl.e-input-group.e-small.e-bigger .e-input-group-icon,
  .e-filled.e-rtl.e-input-group.e-control-wrapper.e-small.e-bigger .e-input-group-icon,
  .e-small.e-bigger .e-rtl.e-filled.e-input-group .e-input-group-icon,
  .e-small.e-bigger .e-rtl.e-filled.e-input-group.e-control-wrapper .e-input-group-icon,
  .e-rtl .e-filled.e-input-group.e-small.e-bigger .e-input-group-icon,
  .e-rtl .e-filled.e-input-group.e-control-wrapper.e-small.e-bigger .e-input-group-icon,
  .e-rtl.e-small.e-bigger .e-filled.e-input-group .e-input-group-icon,
  .e-rtl.e-small.e-bigger .e-filled.e-input-group.e-control-wrapper .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      margin-left: $zero-value;
      margin-right: $zero-value;
    }
  }

  .e-filled.e-input-group.e-bigger textarea,
  .e-filled.e-input-group.e-bigger textarea.e-input,
  .e-filled.e-input-group textarea.e-bigger,
  .e-filled.e-input-group textarea.e-input.e-bigger,
  .e-filled.e-input-group.e-control-wrapper.e-bigger textarea,
  .e-filled.e-input-group.e-control-wrapper.e-bigger textarea.e-input,
  .e-filled.e-input-group.e-control-wrapper textarea.e-bigger,
  .e-filled.e-input-group.e-control-wrapper textarea.e-input.e-bigger,
  .e-bigger .e-filled.e-input-group textarea,
  .e-bigger .e-filled.e-input-group textarea.e-input,
  .e-bigger .e-filled.e-input-group.e-control-wrapper textarea,
  .e-bigger .e-filled.e-input-group.e-control-wrapper textarea.e-input,
  .e-filled.e-input-group.e-input-focus.e-bigger textarea,
  .e-filled.e-input-group.e-input-focus.e-bigger textarea.e-input,
  .e-filled.e-input-group.e-input-focus textarea.e-bigger,
  .e-filled.e-input-group.e-input-focus textarea.e-input.e-bigger,
  .e-filled.e-input-group.e-control-wrapper.e-input-focus.e-bigger textarea,
  .e-filled.e-input-group.e-control-wrapper.e-input-focus.e-bigger textarea.e-input,
  .e-filled.e-input-group.e-control-wrapper.e-input-focus textarea.e-bigger,
  .e-filled.e-input-group.e-control-wrapper.e-input-focus textarea.e-input.e-bigger,
  .e-bigger .e-filled.e-input-group.e-input-focus textarea,
  .e-bigger .e-filled.e-input-group.e-control-wrapper.e-input-focus textarea,
  .e-bigger .e-filled.e-input-group.e-control-wrapper.e-input-focus textarea.e-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      padding: $filled-bigger-textarea-padding;
    }
  }

  .e-filled.e-float-input.e-bigger textarea,
  .e-filled.e-float-input textarea.e-bigger,
  .e-filled.e-float-input.e-control-wrapper.e-bigger textarea,
  .e-filled.e-float-input.e-control-wrapper textarea.e-bigger,
  .e-bigger .e-filled.e-float-input textarea,
  .e-bigger .e-filled.e-float-input.e-control-wrapper textarea {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      padding: $filled-bigger-float-textarea-padding;
    }
  }

  .e-filled.e-input-group.e-small.e-bigger textarea.e-input,
  .e-filled.e-input-group.e-small textarea.e-input.e-bigger,
  .e-filled.e-input-group textarea.e-input.e-bigger.e-small,
  .e-filled.e-input-group.e-bigger textarea.e-small,
  .e-filled.e-input-group.e-control-wrapper.e-small.e-bigger textarea.e-input,
  .e-filled.e-input-group.e-control-wrapper.e-small textarea.e-input.e-bigger,
  .e-bigger.e-small .e-filled.e-input-group textarea.e-input,
  .e-bigger.e-small .e-filled.e-input-group.e-control-wrapper textarea.e-input,
  .e-bigger .input-group textarea.e-small,
  .e-bigger .e-filled.e-input-group.e-small textarea.e-input,
  .e-bigger .e-filled.e-input-group.e-control-wrapper.e-small textarea.e-input,
  .e-bigger .e-small.e-filled.e-input-group.e-control-wrapper textarea.e-input,
  .e-small .input-group textarea.e-bigger,
  .e-small .e-filled.e-input-group.e-bigger textarea.e-input,
  .e-small .e-filled.e-input-group.e-control-wrapper textarea.e-input.e-bigger,
  .e-small .e-filled.e-input-group.e-control-wrapper.e-input-focus textarea.e-input.e-bigger,
  .e-filled.e-input-group.e-input-focus textarea.e-input.e-bigger.e-small,
  .e-filled.e-input-group.e-input-focus.e-small.e-bigger textarea.e-input,
  .e-filled.e-input-group.e-input-focus.e-small textarea.e-input.e-bigger,
  .e-filled.e-input-group.e-input-focus.e-bigger textarea.e-small,
  .e-filled.e-input-group.e-control-wrapper.e-input-focus.e-small.e-bigger textarea.e-input,
  .e-filled.e-input-group.e-control-wrapper.e-input-focus.e-small textarea.e-input.e-bigger,
  .e-bigger.e-small .e-filled.e-input-group.e-input-focus textarea.e-input,
  .e-bigger .e-filled.e-input-group.e-control-wrapper.e-input-focus.e-small textarea.e-input,
  .e-bigger.e-small .e-filled.e-input-group.e-control-wrapper.e-input-focus textarea.e-input,
  .e-bigger .e-small.e-filled.e-input-group.e-control-wrapper.e-input-focus textarea.e-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      padding: $filled-bigger-small-textarea-padding;
    }
  }

  .e-filled.e-float-input textarea.e-bigger.e-small,
  .e-filled.e-float-input.e-small.e-bigger textarea,
  .e-filled.e-float-input.e-small textarea.e-bigger,
  .e-filled.e-float-input.e-bigger textarea.e-small,
  .e-bigger .e-filled.e-float-input.e-small textarea,
  .e-bigger.e-small .e-filled.e-float-input textarea,
  .e-bigger .e-float-input textarea.e-small,
  .e-small .e-filled.e-float-input.e-bigger textarea,
  .e-small .float-input textarea.e-bigger,
  .e-small .e-filled.e-float-input.e-control-wrapper textarea.e-bigger,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger textarea,
  .e-filled.e-float-input.e-control-wrapper.e-small textarea.e-bigger,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small textarea,
  .e-bigger.e-small .e-filled.e-float-input.e-control-wrapper textarea,
  .e-bigger .e-small.e-filled.e-float-input.e-control-wrapper textarea {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      padding: $filled-bigger-small-float-textarea-padding;
    }
  }

  .e-filled.e-input-group.e-multi-line-input.e-bigger,
  .e-bigger .e-filled.e-input-group.e-multi-line-input,
  .e-filled.e-input-group.e-control-wrapper.e-bigger.e-multi-line-input,
  .e-bigger .e-filled.e-input-group.e-control-wrapper.e-multi-line-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      padding: $bigger-filled-textarea-wrapper-padding;
    }
  }

  .e-filled.e-input-group.e-small.e-bigger.e-multi-line-input,
  .e-bigger.e-small .e-filled.e-input-group.e-small.e-multi-line-input,
  .e-bigger .e-filled.e-input-group.e-small.e-multi-line-input,
  .e-small .e-filled.e-input-group.e-bigger.e-multi-line-input,
  .e-filled.e-input-group.e-control-wrapper.e-small.e-bigger.e-multi-line-input,
  .e-bigger.e-small .e-filled.e-input-group.e-control-wrapper.e-small.e-multi-line-input,
  .e-bigger .e-filled.e-input-group.e-control-wrapper.e-small.e-multi-line-input,
  .e-small .e-filled.e-input-group.e-control-wrapper.e-bigger.e-multi-line-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      padding: $bigger-small-filled-textarea-wrapper-padding;
    }
  }

  .e-filled.e-float-input.e-bigger.e-multi-line-input,
  .e-bigger .e-filled.e-float-input.e-multi-line-input,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-multi-line-input,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-multi-line-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      padding: $bigger-filled-float-textarea-wrapper-padding;
    }
  }

  .e-filled.e-float-input.e-small.e-bigger.e-multi-line-input,
  .e-bigger.e-small .e-filled.e-float-input.e-small.e-multi-line-input,
  .e-bigger .e-filled.e-float-input.e-small.e-multi-line-input,
  .e-small .e-filled.e-float-input.e-bigger.e-multi-line-input,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-multi-line-input,
  .e-bigger.e-small .e-filled.e-float-input.e-control-wrapper.e-small.e-multi-line-input,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-multi-line-input,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-bigger.e-multi-line-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      padding: $bigger-small-filled-float-textarea-wrapper-padding;
    }
  }

  .e-rtl .e-filled textarea.e-input.e-bigger#{$css},
  .e-filled textarea.e-input.e-bigger.e-rtl#{$css},
  .e-bigger.e-rtl .e-filled textarea.e-input#{$css},
  .e-bigger .e-filled textarea.e-input.e-rtl#{$css},
  .e-filled.e-input-group.e-multi-line-input.e-bigger.e-rtl textarea.e-input,
  .e-filled.e-input-group.e-multi-line-input.e-control-wrapper.e-bigger.e-rtl textarea.e-input,
  .e-rtl .e-filled.e-input-group.e-multi-line-input.e-bigger textarea.e-input,
  .e-rtl .e-filled.e-input-group.e-multi-line-input.e-control-wrapper.e-bigger textarea.e-input,
  .e-filled.e-input-group.e-multi-line-input.e-rtl textarea.e-input.e-bigger,
  .e-filled.e-input-group.e-multi-line-input.e-control-wrapper.e-rtl textarea.e-input.e-bigger,
  .e-rtl .e-filled.e-input-group.e-multi-line-input textarea.e-input.e-bigger,
  .e-rtl .e-filled.e-input-group.e-multi-line-input.e-control-wrapper textarea.e-input.e-bigger,
  .e-bigger .e-filled.e-input-group.e-multi-line-input.e-rtl textarea.e-input,
  .e-bigger .e-filled.e-input-group.e-multi-line-input.e-control-wrapper.e-rtl textarea.e-input,
  .e-bigger.e-rtl .e-filled.e-input-group.e-multi-line-input textarea.e-input,
  .e-bigger.e-rtl .e-filled.e-input-group.e-multi-line-input.e-control-wrapper textarea.e-input,
  .e-bigger.e-rtl .e-filled.e-input-group.e-multi-line-input textarea.e-input:focus,
  .e-bigger.e-rtl .e-filled.e-input-group.e-multi-line-input.e-control-wrapper textarea.e-input:focus,
  .e-bigger .e-filled.e-input-group.e-multi-line-input.e-rtl textarea.e-input:focus,
  .e-bigger .e-filled.e-input-group.e-multi-line-input.e-control-wrapper.e-rtl textarea.e-input:focus {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      padding: $filled-bigger-textarea-padding;
      text-indent: 0;
    }
  }

  .e-filled.e-float-input.e-multi-line-input.e-control-wrapper.e-bigger.e-rtl textarea,
  .e-filled.e-float-input.e-multi-line-input.e-bigger.e-rtl textarea,
  .e-rtl .e-filled.e-float-input.e-multi-line-input.e-control-wrapper.e-bigger textarea,
  .e-rtl .e-filled.e-float-input.e-multi-line-input.e-bigger textarea,
  .e-filled.e-float-input.e-multi-line-input.e-control-wrapper.e-rtl textarea.e-bigger,
  .e-filled.e-float-input.e-multi-line-input.e-rtl textarea.e-bigger,
  .e-rtl .e-filled.e-float-input.e-multi-line-input.e-control-wrapper textarea.e-bigger,
  .e-rtl .e-filled.e-float-input.e-multi-line-input textarea.e-bigger,
  .e-bigger .e-filled.e-float-input.e-multi-line-input.e-control-wrapper.e-rtl textarea,
  .e-bigger .e-filled.e-float-input.e-multi-line-input.e-rtl textarea,
  .e-bigger.e-rtl .e-filled.e-float-input.e-multi-line-input.e-control-wrapper textarea,
  .e-bigger.e-rtl .e-filled.e-float-input.e-multi-line-input textarea,
  .e-bigger.e-rtl .e-filled.e-float-input.e-multi-line-input.e-control-wrapper textarea:focus,
  .e-bigger.e-rtl .e-filled.e-float-input.e-multi-line-input textarea:focus,
  .e-bigger .e-filled.e-float-input.e-multi-line-input.e-rtl textarea:focus,
  .e-bigger .e-filled.e-float-input.e-multi-line-input.e-control-wrapper.e-rtl textarea:focus {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      padding: $filled-bigger-float-textarea-padding;
      text-indent: 0;
    }
  }

  .e-bigger .e-filled textarea.e-input.e-small.e-rtl#{$css},
  .e-filled.e-input-group.e-multi-line-input.e-small.e-bigger.e-rtl textarea.e-input,
  .e-filled.e-input-group.e-multi-line-input.e-control-wrapper.e-small.e-bigger.e-rtl textarea.e-input,
  .e-filled.e-input-group.e-multi-line-input.e-small.e-rtl textarea.e-input.e-bigger,
  .e-filled.e-input-group.e-multi-line-input.e-control-wrapper.e-small.e-rtl textarea.e-input.e-bigger,
  .e-bigger .e-filled.e-input-group.e-multi-line-input.e-small.e-rtl textarea.e-input,
  .e-bigger .e-filled.e-input-group.e-multi-line-input.e-control-wrapper.e-small.e-rtl textarea.e-input,
  .e-bigger.e-rtl textarea.e-input.e-small,
  .e-rtl .e-filled.e-input-group.e-multi-line-input.e-small.e-bigger textarea.e-input,
  .e-rtl .e-filled.e-input-group.e-multi-line-input.e-control-wrapper.e-small.e-bigger textarea.e-input,
  .e-rtl .e-filled.e-input-group.e-multi-line-input.e-small textarea.e-input.e-bigger,
  .e-rtl .e-filled.e-input-group.e-multi-line-input.e-control-wrapper.e-small textarea.e-input.e-bigger,
  .e-bigger.e-rtl .e-filled.e-input-group.e-multi-line-input.e-small textarea.e-input,
  .e-bigger.e-rtl .e-filled.e-input-group.e-multi-line-input.e-control-wrapper.e-small textarea.e-input,
  .e-bigger .e-filled.e-input-group.e-multi-line-input.e-small.e-rtl textarea.e-input:focus,
  .e-bigger .e-filled.e-input-group.e-multi-line-input.e-control-wrapper.e-small.e-rtl textarea.e-input:focus,
  .e-small .e-filled.e-input-group.e-multi-line-input.e-bigger.e-rtl textarea.e-input:focus,
  .e-small .e-filled.e-input-group.e-multi-line-input.e-control-wrapper.e-bigger.e-rtl textarea.e-input:focus,
  .e-filled.e-input-group.e-multi-line-input.e-bigger.e-small.e-rtl textarea.e-input:focus,
  .e-filled.e-input-group.e-multi-line-input.e-control-wrapper.e-bigger.e-small.e-rtl textarea.e-input:focus,
  .e-bigger.e-rtl .e-filled.e-input-group.e-multi-line-input.e-small textarea.e-input:focus,
  .e-bigger.e-rtl .e-filled.e-input-group.e-multi-line-input.e-control-wrapper.e-small textarea.e-input:focus,
  .e-small.e-rtl .e-filled.e-input-group.e-multi-line-input.e-bigger textarea.e-input:focus,
  .e-small.e-rtl .e-filled.e-input-group.e-multi-line-input.e-control-wrapper.e-bigger textarea.e-input:focus,
  .e-rtl .e-filled.e-input-group.e-multi-line-input.e-bigger.e-small textarea.e-input:focus,
  .e-rtl .e-filled.e-input-group.e-multi-line-input.e-control-wrapper.e-bigger.e-small textarea.e-input:focus {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      padding: $filled-bigger-small-textarea-padding;
      text-indent: 0;
    }
  }

  .e-filled.e-float-input.e-multi-line-input.e-small.e-bigger.e-rtl textarea,
  .e-filled.e-float-input.e-multi-line-input.e-small.e-rtl textarea.e-bigger,
  .e-bigger .e-filled.e-float-input.e-multi-line-input.e-small.e-rtl textarea,
  .e-filled.e-float-input.e-multi-line-input.e-control-wrapper.e-small.e-bigger.e-rtl textarea,
  .e-filled.e-float-input.e-multi-line-input.e-control-wrapper.e-small.e-rtl textarea.e-bigger,
  .e-bigger .e-filled.e-float-input.e-multi-line-input.e-control-wrapper.e-small.e-rtl textarea,
  .e-rtl .e-filled.e-float-input.e-multi-line-input.e-control-wrapper.e-small.e-bigger textarea,
  .e-rtl .e-filled.e-float-input.e-multi-line-input.e-control-wrapper.e-small textarea.e-bigger,
  .e-bigger.e-rtl .e-filled.e-float-input.e-multi-line-input.e-control-wrapper.e-small textarea,
  .e-rtl .e-filled.e-float-input.e-multi-line-input.e-small.e-bigger textarea,
  .e-rtl .e-filled.e-float-input.e-multi-line-input.e-small textarea.e-bigger,
  .e-bigger.e-rtl .e-filled.e-float-input.e-multi-line-input.e-small textarea,
  .e-bigger .e-filled.e-float-input.e-multi-line-input.e-control-wrapper.e-small.e-rtl textarea:focus,
  .e-bigger .e-filled.e-float-input.e-multi-line-input.e-small.e-rtl textarea:focus,
  .e-small .e-filled.e-float-input.e-multi-line-input.e-control-wrapper.e-bigger.e-rtl textarea:focus,
  .e-small .e-filled.e-float-input.e-multi-line-input.e-bigger.e-rtl textarea:focus,
  .e-filled.e-float-input.e-multi-line-input.e-control-wrapper.e-bigger.e-small.e-rtl textarea:focus,
  .e-filled.e-float-input.e-multi-line-input.e-bigger.e-small.e-rtl textarea:focus,
  .e-bigger.e-rtl .e-filled.e-float-input.e-multi-line-input.e-control-wrapper.e-small textarea:focus,
  .e-bigger.e-rtl .e-filled.e-float-input.e-multi-line-input.e-small textarea:focus,
  .e-small.e-rtl .e-filled.e-float-input.e-multi-line-input.e-control-wrapper.e-bigger textarea:focus,
  .e-small.e-rtl .e-filled.e-float-input.e-multi-line-input.e-bigger textarea:focus,
  .e-rtl .e-filled.e-float-input.e-multi-line-input.e-control-wrapper.e-bigger.e-small textarea:focus,
  .e-rtl .e-filled.e-float-input.e-multi-line-input.e-bigger.e-small textarea:focus {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      padding: $filled-bigger-small-float-textarea-padding;
      text-indent: 0;
    }
  }

  .e-filled.e-float-input.e-float-icon-left.e-bigger.e-small .e-input-in-wrap,
  .e-filled.e-float-input.e-control-wrapper.e-float-icon-left.e-bigger.e-small .e-input-in-wrap,
  .e-filled.e-input-group.e-float-icon-left.e-bigger.e-small .e-input-in-wrap,
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left.e-bigger.e-small .e-input-in-wrap,
  .e-bigger.e-small .e-filled.e-float-input.e-float-icon-left .e-input-in-wrap,
  .e-bigger.e-small .e-filled.e-float-input.e-control-wrapper.e-float-icon-left .e-input-in-wrap,
  .e-bigger.e-small .e-filled.e-input-group.e-float-icon-left .e-input-in-wrap,
  .e-bigger.e-small .e-filled.e-input-group.e-control-wrapper.e-float-icon-left .e-input-in-wrap {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      border: $input-group-full-border;
      border-width: $zero-value;
      margin-left: 8px;
    }
  }

  .e-rtl.e-bigger.e-small .e-filled.e-float-input.e-float-icon-left .e-input-in-wrap,
  .e-rtl.e-bigger.e-small .e-filled.e-float-input.e-control-wrapper.e-float-icon-left .e-input-in-wrap,
  .e-rtl.e-bigger.e-small .e-filled.e-input-group.e-float-icon-left .e-input-in-wrap,
  .e-rtl.e-bigger.e-small .e-filled.e-input-group.e-control-wrapper.e-float-icon-left .e-input-in-wrap,
  .e-rtl .e-bigger.e-small.e-filled.e-float-input.e-float-icon-left .e-input-in-wrap,
  .e-rtl .e-bigger.e-small.e-filled.e-float-input.e-control-wrapper.e-float-icon-left .e-input-in-wrap,
  .e-rtl .e-bigger.e-small.e-filled.e-input-group.e-float-icon-left .e-input-in-wrap,
  .e-rtl .e-bigger.e-small.e-filled.e-input-group.e-control-wrapper.e-float-icon-left .e-input-in-wrap,
  .e-bigger.e-small.e-filled.e-float-input.e-control-wrapper.e-float-icon-left.e-rtl .e-input-in-wrap,
  .e-bigger.e-small.e-filled.e-float-input.e-float-icon-left.e-rtl .e-input-in-wrap,
  .e-bigger.e-small.e-filled.e-input-group.e-float-icon-left.e-rtl .e-input-in-wrap,
  .e-bigger.e-small.e-filled.e-input-group.e-control-wrapper.e-float-icon-left.e-rtl .e-input-in-wrap,
  .e-bigger.e-small .e-filled.e-float-input.e-control-wrapper.e-float-icon-left.e-rtl .e-input-in-wrap,
  .e-bigger.e-small .e-filled.e-float-input.e-float-icon-left.e-rtl .e-input-in-wrap,
  .e-bigger.e-small .e-filled.e-input-group.e-float-icon-left.e-rtl .e-input-in-wrap,
  .e-bigger.e-small .e-filled.e-input-group.e-control-wrapper.e-float-icon-left.e-rtl .e-input-in-wrap {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      margin-left: $zero-value;
      margin-right: 8px;
    }
  }

  .e-float-input.e-bigger:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input.e-control-wrapper:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-small.e-bigger:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input.e-small:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    color: $input-header-font-color;
    @if $input-skin-name == 'highcontrast' {
      color: $input-placeholder;
    }
  }

  .e-float-input.e-bigger:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input.e-bigger:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-bigger.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-bigger.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input.e-control-wrapper.e-bigger:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-bigger.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-bigger.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-bigger textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input.e-bigger textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-bigger.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-float-input textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-bigger.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-bigger textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input.e-control-wrapper.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input.e-control-wrapper.e-bigger textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-bigger.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-float-input.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-bigger.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'highcontrast' {
      color: $content-font-alt;
    }
  }

  .e-bigger .e-input#{$css}:focus:not(.e-success):not(.e-warning):not(.e-error):not(.e-textarea) {
    @if $input-skin-name == 'fluent2' {
      padding-bottom: 0;
    }
  }

  .e-bigger textarea.e-input#{$css}:focus:not(.e-success):not(.e-warning):not(.e-error) {
    @if $input-skin-name == 'fluent2' {
      padding-bottom: 6px;
    }
  }

  .e-float-input.e-bigger:not(.e-error) input:focus ~ label.e-float-text,
  .e-float-input.e-bigger:not(.e-error) input:valid ~ label.e-float-text,
  .e-float-input.e-bigger:not(.e-error) input ~ label.e-label-top.e-float-text,
  .e-float-input.e-bigger:not(.e-error) input[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-bigger:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input:not(.e-error) input:focus ~ label.e-float-text,
  .e-bigger .e-float-input:not(.e-error) input:valid ~ label.e-float-text,
  .e-bigger .e-float-input:not(.e-error) input ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input:not(.e-error) input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-bigger:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-bigger .e-float-input:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-float-input.e-small.e-bigger:not(.e-error) input:focus ~ label.e-float-text,
  .e-float-input.e-small.e-bigger:not(.e-error) input:valid ~ label.e-float-text,
  .e-float-input.e-small.e-bigger:not(.e-error) input ~ label.e-label-top.e-float-text,
  .e-float-input.e-small.e-bigger:not(.e-error) input[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-small.e-bigger:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-small:not(.e-error) input:focus ~ label.e-float-text,
  .e-bigger .e-float-input.e-small:not(.e-error) input:valid ~ label.e-float-text,
  .e-bigger .e-float-input.e-small:not(.e-error) input ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-small:not(.e-error) input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-small:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-small.e-bigger:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-bigger .e-float-input.e-small:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) input:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) input:valid ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) input ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) input[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-error) input:focus ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-error) input:valid ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-error) input ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-error) input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-float-input.e-bigger:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-float-input.e-bigger:not(.e-error) textarea:valid ~ label.e-float-text,
  .e-float-input.e-bigger:not(.e-error) textarea ~ label.e-label-top.e-float-text,
  .e-float-input.e-bigger:not(.e-error) textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-bigger:not(.e-error) textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-bigger .e-float-input:not(.e-error) textarea:valid ~ label.e-float-text,
  .e-bigger .e-float-input:not(.e-error) textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input:not(.e-error) textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input:not(.e-error) textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-small.e-bigger:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-float-input.e-small.e-bigger:not(.e-error) textarea:valid ~ label.e-float-text,
  .e-float-input.e-small.e-bigger:not(.e-error) textarea ~ label.e-label-top.e-float-text,
  .e-float-input.e-small.e-bigger:not(.e-error) textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-small.e-bigger:not(.e-error) textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-small:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-bigger .e-float-input.e-small:not(.e-error) textarea:valid ~ label.e-float-text,
  .e-bigger .e-float-input.e-small:not(.e-error) textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-small:not(.e-error) textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-small:not(.e-error) textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-float-input:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-float-input.e-small:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-small .e-float-input:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-float-input.e-bigger.e-small:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-autofill:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-float-input.e-control-wrapper.e-autofill:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-float-input.e-small.e-control-wrapper.e-autofill:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-small .e-float-input.e-control-wrapper.e-autofill:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-float-input.e-bigger.e-small.e-control-wrapper.e-autofill:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-float-input:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-float-input:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-float-input.e-small:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-small .e-float-input:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-float-input.e-bigger.e-small:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-autofill:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-float-input.e-control-wrapper.e-autofill:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-float-input.e-small.e-control-wrapper.e-autofill:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-small .e-float-input.e-control-wrapper.e-autofill:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-float-input.e-bigger.e-small.e-control-wrapper.e-autofill:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text {
    color: $float-label-font-color;
  }

  .e-float-input.e-control-wrapper.e-bigger:not(.e-error) input:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-error) input:valid ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-error) input ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-error) input[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper:not(.e-error) input:focus ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper:not(.e-error) input:valid ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper:not(.e-error) input ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper:not(.e-error) input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-error) textarea:valid ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-error) textarea ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-error) textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-error) textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper:not(.e-error) textarea:valid ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper:not(.e-error) textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper:not(.e-error) textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper:not(.e-error) textarea[disabled] ~ label.e-label-top.e-float-text {
    @if $input-skin-name != 'fluent2' {
      color: $float-label-font-color;
    }
    @else {
      color: $float-label-top-font-color;
    }
  }

  .e-float-input.e-bigger:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input.e-control-wrapper:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-small.e-bigger:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input.e-small:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    color: $input-header-font-color;
    @if $input-skin-name == 'highcontrast' {
      color: $input-placeholder;
    }
  }

  .e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) textarea:valid ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) textarea ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-error) textarea:valid ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-error) textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-error) textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-error) textarea[disabled] ~ label.e-label-top.e-float-text {
    color: $float-label-font-color;
  }

  .e-float-input.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-float-input.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text.e-label-top,
  .e-float-input.e-small.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-float-input.e-small.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text.e-label-top,
  .e-float-input.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-float-input.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text.e-label-top,
  .e-float-input.e-small.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-float-input.e-small.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper.e-disabled.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-disabled.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper.e-disabled.e-small.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-disabled.e-small.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper.e-disabled.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-disabled.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper.e-disabled.e-small.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-disabled.e-small.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text.e-label-top {
    color: $float-label-disbale-font-color;
  }

  .e-float-input.e-bigger:not(.e-error) input:focus ~ label.e-float-text,
  .e-bigger .e-float-input:not(.e-error) input:focus ~ label.e-float-text,
  .e-float-input.e-bigger:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-float-input.e-small.e-bigger:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-small:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-float-input.e-bigger:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-small.e-bigger:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-small:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-bigger:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-float-input.e-small.e-bigger:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-small:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text .e-float-input.e-control-wrapper.e-small:not(.e-error) textarea[readonly]:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-float-input.e-bigger:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-small.e-bigger:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-small:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text .e-float-input.e-control-wrapper.e-small:not(.e-error).e-input-focus textarea[readonly] ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-small.e-bigger:not(.e-error) input:focus ~ label.e-float-text,
  .e-bigger .e-float-input.e-small:not(.e-error) input:focus ~ label.e-float-text,
  .e-float-input.e-bigger:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-bigger .e-float-input:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-float-input.e-small.e-bigger:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-bigger .e-float-input.e-small:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-error) input:focus ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper:not(.e-error) input:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) input:focus ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-error) input:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-float-input.e-bigger:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-bigger .e-float-input:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-float-input.e-small.e-bigger:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-bigger .e-float-input.e-small:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-error).e-input-focus input ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      color: $input-active-accent-color;
    }
  }

  .e-outline.e-float-input.e-bigger.e-success.e-input-focus input:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-success.e-input-focus input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-small.e-bigger.e-success input:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small:not(.e-success) input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-bigger.e-success textarea:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-success textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-small.e-bigger.e-success textarea:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small.e-success textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-success input:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-success input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger.e-success input:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small.e-success input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-success textarea:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-success textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger.e-success textarea:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small.e-success textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-bigger.e-success.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-success.e-input-focus input ~ label.e-float-text,
  .e-outline.e-float-input.e-small.e-bigger.e-success.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small:not(.e-success).e-input-focus input ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-success.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-success.e-input-focus input ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger.e-success.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small.e-success.e-input-focus input ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      color: $input-success-color;
    }
  }

  .e-outline.e-float-input.e-bigger.e-error.e-input-focus input:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-error.e-input-focus input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-small.e-bigger.e-error input:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small:not(.e-error) input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-bigger.e-error textarea:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-error textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-small.e-bigger.e-error textarea:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small.e-error textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-error input:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-error input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger.e-error input:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small.e-error input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-error textarea:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-error textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger.e-error textarea:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small.e-error textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-bigger.e-error.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-error.e-input-focus input ~ label.e-float-text,
  .e-outline.e-float-input.e-small.e-bigger.e-error.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-error.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-error.e-input-focus input ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger.e-error.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small.e-error.e-input-focus input ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      color: $input-error-color;
    }
  }

  .e-outline.e-float-input.e-bigger.e-warning.e-input-focus input:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-warning.e-input-focus input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-small.e-bigger.e-warning input:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small:not(.e-warning) input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-bigger.e-warning textarea:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-warning textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-small.e-bigger.e-warning textarea:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small.e-warning textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-warning input:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-warning input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger.e-warning input:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small.e-warning input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-warning textarea:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-warning textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger.e-warning textarea:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small.e-warning textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-bigger.e-warning.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-warning.e-input-focus input ~ label.e-float-text,
  .e-outline.e-float-input.e-small.e-bigger.e-warning.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small:not(.e-warning).e-input-focus input ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-warning.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-warning.e-input-focus input ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger.e-warning.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small.e-warning.e-input-focus input ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      color: $input-warning-color;
    }
  }

  .e-outline.e-float-input.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-outline.e-float-input.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-small.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-outline.e-float-input.e-small.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-outline.e-float-input.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-small.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-outline.e-float-input.e-small.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-control-wrapper.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-control-wrapper.e-disabled.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-disabled.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-control-wrapper.e-disabled.e-small.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-disabled.e-small.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-control-wrapper.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-control-wrapper.e-disabled.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-disabled.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-control-wrapper.e-disabled.e-small.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-disabled.e-small.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text.e-label-top {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      color: $outline-float-label-disbale-font-color;
    }
  }

  .e-filled.e-float-input.e-bigger:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-small.e-bigger:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input.e-small:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-bigger:not(.e-error) input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger:not(.e-error) input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger:not(.e-error) input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger:not(.e-error) input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input:not(.e-error) input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input:not(.e-error) input:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input:not(.e-error) input ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input:not(.e-error) input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-filled.e-float-input:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-small .e-filled.e-float-input:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-small:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-autofill:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-filled.e-float-input.e-control-wrapper.e-autofill:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-control-wrapper.e-autofill:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-small .e-filled.e-float-input.e-control-wrapper.e-autofill:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-small.e-control-wrapper.e-autofill:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-filled.e-float-input:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-small .e-filled.e-float-input:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-small:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-autofill:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-filled.e-float-input.e-control-wrapper.e-autofill:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-control-wrapper.e-autofill:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-small .e-filled.e-float-input.e-control-wrapper.e-autofill:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-filled.e-bigger.e-small.e-control-wrapper.e-autofill:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      color: $filled-input-float-label-color;
    }
  }

  .e-filled.e-float-input.e-bigger.e-success input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-success input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-success input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger.e-success input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger.e-success input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-success input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-success input:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-success input ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-success input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-success input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger.e-success.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-success.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-success input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-success input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-success input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger.e-success input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger.e-success input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-success input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-success input:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-success input ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-success input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-success input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger.e-success.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-success.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-success input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-success input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-success input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-success input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-success input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-success input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-success input:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-success input ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-success input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-success input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-success.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-success.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger.e-success input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger.e-success input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger.e-success input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger.e-success input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger.e-success input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-success input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-success input:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-success input ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-success input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-success input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger.e-success.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-success.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-success input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-success input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-success input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-success input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-success input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-success input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-success input:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-success input ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-success input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-success input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-success.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-success.e-input-focus input ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      color: $input-success-color;
    }
  }

  .e-filled.e-float-input.e-bigger.e-warning input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-warning input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-warning input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger.e-warning input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger.e-warning input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-warning input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-warning input:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-warning input ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-warning input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-warning input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger.e-warning.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-warning.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-warning input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-warning input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-warning input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger.e-warning input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger.e-warning input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-warning input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-warning input:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-warning input ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-warning input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-warning input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger.e-warning.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-warning.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-warning input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-warning input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-warning input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-warning input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-warning input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-warning input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-warning input:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-warning input ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-warning input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-warning input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-warning.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-warning.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger.e-warning input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger.e-warning input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger.e-warning input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger.e-warning input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger.e-warning input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-warning input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-warning input:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-warning input ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-warning input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-warning input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger.e-warning.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-warning.e-input-focus input ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      color: $input-warning-color;
    }
  }

  .e-filled.e-float-input.e-bigger:not(.e-error) input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger:not(.e-error) input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger:not(.e-error) input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger:not(.e-error) input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input:not(.e-error) input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input:not(.e-error) input:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input:not(.e-error) input ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input:not(.e-error) input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-error) input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-error) input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-error) input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-error) input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-error) input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-error) input:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-error) input ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-error) input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger:not(.e-error) input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger:not(.e-error) input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger:not(.e-error) input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger:not(.e-error) input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small:not(.e-error) input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small:not(.e-error) input:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small:not(.e-error) input ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small:not(.e-error) input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) input:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) input ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-small.e-bigger:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input.e-small:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-bigger:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger:not(.e-error) textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger:not(.e-error) textarea ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger:not(.e-error) textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger:not(.e-error) textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input:not(.e-error) textarea:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input:not(.e-error) textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input:not(.e-error) textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input:not(.e-error) textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-error) textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-error) textarea ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-error) textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-error) textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-error) textarea:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-error) textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-error) textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-error) textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger:not(.e-error) textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger:not(.e-error) textarea ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger:not(.e-error) textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger:not(.e-error) textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small:not(.e-error) textarea:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small:not(.e-error) textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small:not(.e-error) textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small:not(.e-error) textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) textarea ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) textarea:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) textarea[disabled] ~ label.e-label-top.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      color: $filled-input-float-label-color;
    }
  }

  .e-filled.e-float-input.e-bigger:not(.e-error) input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input:not(.e-error) input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger:not(.e-error) input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small:not(.e-error) input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) textarea[readonly]:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error).e-input-focus textarea[readonly] ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-error) input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-error) input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error).e-input-focus input ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      color: $input-active-accent-color;
    }
  }

  .e-filled.e-float-input.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-small.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-small.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper.e-disabled.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-disabled.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper.e-disabled.e-small.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-disabled.e-small.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper.e-disabled.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-disabled.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper.e-disabled.e-small.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-disabled.e-small.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text.e-label-top {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      color: $filled-input-disabled-font-color;
    }
  }
  
  .e-filled.e-float-input.e-bigger.e-success textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-success textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input.e-success textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-success textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-small.e-bigger.e-success textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-success textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input.e-small.e-success textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-success textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-bigger.e-success textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-success textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-success textarea ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger.e-success textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger.e-success textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-success textarea:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-success textarea:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-success textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-success textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-success textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-success textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-success textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-success textarea ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-success textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-success textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-success textarea:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-success textarea:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-success textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-success textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-success textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger.e-success textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger.e-success textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger.e-success textarea ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger.e-success textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger.e-success textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-success textarea:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-success textarea:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-success textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-success textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-success textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-success textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-success textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-success textarea ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-success textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-success textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-success textarea:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-success textarea:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-success textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-success textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-success textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger.e-success input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-success input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger.e-success input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-success input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-success textarea:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-success textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger.e-success textarea:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-success textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-success input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-success input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-success input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-success input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-success textarea:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-success textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-success textarea:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-success textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-success.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-success.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger.e-success.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-success.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-success.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-success.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-success.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-success.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-success:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-filled.e-float-input.e-success:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-success:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-small .e-filled.e-float-input.e-success:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-small.e-success:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-autofill.e-success:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-filled.e-float-input.e-control-wrapper.e-autofill.e-success:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-control-wrapper.e-autofill.e-success:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-small .e-filled.e-float-input.e-control-wrapper.e-autofill.e-success:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-small.e-control-wrapper.e-autofill.e-success:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-success textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-filled.e-float-input.e-success textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-success textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-small .e-filled.e-float-input.e-success textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-small.e-success textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-autofill.e-success textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-filled.e-float-input.e-control-wrapper.e-autofill.e-success textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-control-wrapper.e-autofill.e-success textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-small .e-filled.e-float-input.e-control-wrapper.e-autofill.e-success textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-filled.e-bigger.e-small.e-control-wrapper.e-autofill.e-success textarea:not(:focus):-webkit-autofill ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      color: $input-success-color;
    }
  }

  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-warning input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-warning input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-warning input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-warning input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-warning input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-warning input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-warning input:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-warning input ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-warning input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-warning input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-warning.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-warning.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-warning textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-warning textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input.e-warning textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-warning textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-small.e-bigger.e-warning textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-warning textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input.e-small.e-warning textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-warning textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-bigger.e-warning textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-warning textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-warning textarea ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger.e-warning textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger.e-warning textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-warning textarea:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-warning textarea:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-warning textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-warning textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-warning textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-warning textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-warning textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-warning textarea ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-warning textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-warning textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-warning textarea:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-warning textarea:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-warning textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-warning textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-warning textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger.e-warning textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger.e-warning textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger.e-warning textarea ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger.e-warning textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger.e-warning textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-warning textarea:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-warning textarea:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-warning textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-warning textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-warning textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-warning textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-warning textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-warning textarea ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-warning textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-warning textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-warning textarea:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-warning textarea:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-warning textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-warning textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-warning textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger.e-warning input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-warning input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger.e-warning input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-warning input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-warning textarea:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-warning textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger.e-warning textarea:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-warning textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-warning input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-warning input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-warning input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-warning input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-warning textarea:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-warning textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-warning textarea:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-warning textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-warning.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-warning.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger.e-warning.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-warning.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-warning.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-warning.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-warning.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-warning.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-warning:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-filled.e-float-input.e-warning:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-warning:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-small .e-filled.e-float-input.e-warning:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-small.e-warning:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-autofill.e-warning:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-filled.e-float-input.e-control-wrapper.e-autofill.e-warning:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-control-wrapper.e-autofill.e-warning:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-small .e-filled.e-float-input.e-control-wrapper.e-autofill.e-warning:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-small.e-control-wrapper.e-autofill.e-warning:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-warning textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-filled.e-float-input.e-warning textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-warning textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-small .e-filled.e-float-input.e-warning textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-small.e-warning textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-autofill.e-warning textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-filled.e-float-input.e-control-wrapper.e-autofill.e-warning textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-control-wrapper.e-autofill.e-warning textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-small .e-filled.e-float-input.e-control-wrapper.e-autofill.e-warning textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-filled.e-bigger.e-small.e-control-wrapper.e-autofill.e-warning textarea:not(:focus):-webkit-autofill ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      color: $input-warning-color;
    }
  }
}
