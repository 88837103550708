@include export-module('input-layout') {

  /*! input layout */
  .e-input-group,
  .e-input-group.e-control-wrapper {
    display: table;
    line-height: 1.4;
    margin-bottom: $input-margin-bottom;
  }

  input.e-input#{$css},
  .e-input-group input.e-input,
  .e-input-group.e-control-wrapper input.e-input,
  textarea.e-input#{$css},
  .e-input-group textarea.e-input,
  .e-input-group.e-control-wrapper textarea.e-input {
    border: $input-border;
    border-width: $input-box-border-width;
    height: auto;
    line-height: inherit;
    margin: $zero-value;
    margin-bottom: $input-margin-bottom;
    outline: none;
    padding: $input-padding;
    text-indent: $input-text-indent;
    width: 100%;
  }

  input.e-input#{$css},
  textarea.e-input#{$css},
  .e-input-group,
  .e-input-group.e-control-wrapper,
  .e-input-group.e-disabled,
  .e-input-group.e-control-wrapper.e-disabled {
    font-family: $input-font-family;
    font-size: $input-font-size;
    font-weight: normal;
  }

  .e-input-group input.e-input,
  .e-input-group.e-control-wrapper input.e-input,
  .e-input-group textarea.e-input,
  .e-input-group.e-control-wrapper textarea.e-input,
  .e-input-group.e-small .e-input,
  .e-input-group.e-small.e-control-wrapper .e-input,
  .e-small .e-input-group .e-input,
  .e-small .e-input-group.e-control-wrapper .e-input {
    font: inherit;
  }

  input.e-input#{$css},
  .e-input-group input.e-input,
  .e-input-group input,
  .e-input-group.e-control-wrapper input.e-input,
  .e-input-group.e-control-wrapper input,
  .e-float-input input,
  .e-float-input.e-input-group input,
  .e-float-input.e-control-wrapper input,
  .e-float-input.e-control-wrapper.e-input-group input,
  input.e-input#{$css}:focus,
  .e-input-group input.e-input:focus,
  .e-input-group input:focus,
  .e-input-group.e-control-wrapper input.e-input:focus,
  .e-input-group.e-control-wrapper input:focus,
  .e-float-input input:focus,
  .e-float-input.e-input-group input:focus,
  .e-float-input.e-control-wrapper input:focus,
  .e-float-input.e-control-wrapper.e-input-group input:focus,
  .e-input-group.e-input-focus input.e-input,
  .e-input-group.e-input-focus input,
  .e-input-group.e-control-wrapper.e-input-focus input.e-input,
  .e-input-group.e-control-wrapper.e-input-focus input,
  .e-float-input.e-input-focus input,
  .e-float-input.e-input-group.e-input-focus input,
  .e-float-input.e-control-wrapper.e-input-focus input,
  .e-float-input.e-control-wrapper.e-input-group.e-input-focus input {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  input.e-input#{$css},
  .e-input-group input.e-input,
  .e-input-group input,
  .e-input-group.e-control-wrapper input.e-input,
  .e-input-group.e-control-wrapper input,
  .e-float-input input,
  .e-float-input.e-input-group input,
  .e-float-input.e-control-wrapper input,
  .e-float-input.e-control-wrapper.e-input-group input,
  .e-input-group,
  .e-input-group.e-control-wrapper,
  .e-float-input,
  .e-float-input.e-control-wrapper,
  .e-input#{$css}.e-corner,
  .e-input-group.e-corner.e-rtl input.e-input:only-child,
  .e-input-group.e-control-wrapper.e-corner.e-rtl input.e-input:only-child,
  .e-input-group.e-input-focus.e-corner,
  .e-input-group.e-control-wrapper.e-input-focus.e-corner,
  textarea.e-input#{$css},
  .e-input-group textarea.e-input,
  .e-input-group textarea,
  .e-input-group.e-control-wrapper textarea.e-input,
  .e-input-group.e-control-wrapper textarea,
  .e-float-input textarea,
  .e-float-input.e-input-group textarea,
  .e-float-input.e-control-wrapper textarea,
  .e-float-input.e-control-wrapper.e-input-group textarea {
    @if $skin-name == 'fluent2' {
      border-radius: 4px;
    }
    @else {
      border-radius: $input-box-border-radius;
    }
  }

  .e-input.e-small,
  .e-input-group.e-small,
  .e-input-group.e-control-wrapper.e-small,
  .e-input-group.e-small .e-input,
  .e-input-group.e-small input,
  .e-input-group.e-control-wrapper.e-small .e-input,
  .e-input-group.e-control-wrapper.e-small input,
  .e-float-input.e-small input,
  .e-float-input.e-input-group.e-small input,
  .e-float-input.e-control-wrapper.e-small input,
  .e-float-input.e-control-wrapper.e-input-group.e-small input,
  .e-float-input.e-small,
  .e-float-input.e-control-wrapper.e-small,
  .e-small .e-input-group,
  .e-small .e-input-group.e-control-wrapper,
  .e-small .e-input-group .e-input,
  .e-small .e-input-group input,
  .e-small .e-input-group.e-control-wrapper .e-input,
  .e-small .e-input-group.e-control-wrapper input,
  .e-small .e-float-input input,
  .e-small .e-float-input.e-input-group input,
  .e-small .e-float-input.e-control-wrapper input,
  .e-small .e-float-input.e-control-wrapper.e-input-group input,
  .e-small .e-float-input,
  .e-small .e-float-input.e-control-wrapper {
    @if ($input-skin-name == 'tailwind' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3') {
      border-radius: $input-small-border-radius;
    }
  }

  .e-input#{$css}:focus {
    border-width: $input-focus-border-width;
    padding-bottom: $input-padding-bottom;
  }

  .e-input.e-small#{$css}:focus {
    border-width: $input-focus-border-width;
    padding-bottom: $input-small-padding-bottom;
  }

  .e-input#{$css}:focus {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      padding-bottom: $input-focus-padding-bottom;
    }
  }

  .e-input.e-small#{$css}:focus {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      padding-bottom: $input-focus-small-padding-bottom;
    }
  }

  .e-input-group input.e-input:focus,
  .e-input-group.e-control-wrapper input.e-input:focus,
  .e-input-group textarea.e-input:focus,
  .e-input-group.e-control-wrapper textarea.e-input:focus,
  .e-input-group.e-input-focus input.e-input,
  .e-input-group.e-control-wrapper.e-input-focus input.e-input {
    padding: $input-padding;
  }

  .e-input-group textarea.e-input:focus,
  .e-input-group.e-control-wrapper textarea.e-input:focus {
    padding: $textarea-padding;
  }

  .e-input-group .e-input-group-icon,
  .e-input-group.e-control-wrapper .e-input-group-icon {
    align-items: center;
    border: $input-border;
    border-width: $input-group-child-border-width;
    box-sizing: content-box;
    cursor: pointer;
    flex-direction: column;
    font-size: $input-icon-font-size;
    justify-content: center;
    line-height: 1;
    min-height: $input-child-min-height;
    min-width: $input-child-min-width;
    padding: $input-child-padding;
    text-align: center;
    @if ($input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3') {
      border: 1px solid;
      border-bottom: $zero-value;
      border-collapse: collapse;
      border-top: $zero-value;
    }
    @if $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      border-radius: $input-child-icon-border-radius;
    }
  }

  /* stylelint-disable property-no-vendor-prefix */
  .e-input-group:not(.e-disabled) .e-input-group-icon:hover,
  .e-input-group.e-control-wrapper:not(.e-disabled) .e-input-group-icon:hover {
    @if $input-skin-name == 'highcontrast' {
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      border-collapse: collapse;
      border-width: $zero-value + px 1px $zero-value + px 1px;
      box-sizing: border-box;
    }
  }

  .e-input-group .e-input-group-icon:last-child,
  .e-input-group.e-control-wrapper .e-input-group-icon:last-child {
    @if ($input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3') {
      border-right-width: $zero-value;
    }
  }

  .e-input-group:not(.e-rtl):not(.e-disabled) .e-input-group-icon:last-child:hover,
  .e-input-group.e-control-wrapper:not(.e-rtl):not(.e-disabled) .e-input-group-icon:last-child:hover,
  .e-input-group:not(.e-disabled) .e-input-group-icon:not(:last-child):hover + .e-input-group-icon:not(:last-child):hover .e-input-group.e-control-wrapper:not(.e-disabled) .e-input-group-icon:not(:last-child):hover + .e-input-group-icon:not(:last-child):hover,
  .e-input-group:not(.e-disabled) .e-input-group-icon:first-child:hover,
  .e-input-group.e-control-wrapper:not(.e-disabled) .e-input-group-icon:first-child:hover,
  .e-input-group:not(.e-rtl):not(.e-disabled) .e-input + .e-input-group-icon:last-child:hover,
  .e-input-group.e-control-wrapper:not(.e-rtl):not(.e-disabled) .e-input + .e-input-group-icon:last-child:hover,
  .e-input-group:not(.e-disabled) .e-input-group-icon:not(:last-child):hover,
  .e-input-group.e-control-wrapper:not(.e-disabled) .e-input-group-icon:not(:last-child):hover,
  .e-float-input.e-input-group.e-small:not(:disabled) .e-input-group-icon:first-child:hover,
  .e-float-input.e-control-wrapper.e-input-group.e-small:not(:disabled) .e-input-group-icon:first-child:hover,
  .e-input-group.e-rtl:not(.e-disabled) .e-input-group-icon:last-child:hover,
  .e-input-group.e-control-wrapper.e-rtl:not(.e-disabled) .e-input-group-icon:last-child:hover,
  .e-input-group.e-small:not(.e-disabled):not(.e-float-input):not(.e-rtl) .e-input-group-icon:last-child:hover,
  .e-input-group.e-float-icon-left .e-input-group-icon:last-child:hover,
  .e-input-group.e-control-wrapper.e-small:not(.e-disabled):not(.e-float-input):not(.e-rtl) .e-input-group-icon:last-child:hover,
  .e-input-group.e-control-wrapper.e-float-icon-left .e-input-group-icon:last-child:hover,
  .e-input-group.e-small:not(.e-disabled):not(.e-float-input):not(.e-bigger):not(.e-float-icon-left) .e-input-group-icon:not(:last-child):hover,
  .e-input-group.e-float-icon-left .e-input-group-icon:not(:last-child):hover,
  .e-input-group.e-control-wrapper.e-small:not(.e-disabled):not(.e-float-input):not(.e-bigger):not(.e-float-icon-left) .e-input-group-icon:not(:last-child):hover,
  .e-input-group.e-control-wrapper.e-float-icon-left .e-input-group-icon:not(:last-child):hover,
  .e-input-group.e-small.e-float-icon-left:not(.e-disabled) .e-input-group-icon:first-child:hover,
  .e-input-group.e-control-wrapper.e-small.e-float-icon-left:not(.e-disabled) .e-input-group-icon:first-child:hover,
  .e-float-input.e-input-group.e-small:not(.e-float-icon-left):not(.e-input-group):not(.e-disabled) .e-input-group-icon:last-child:hover,
  .e-float-input.e-control-wrapper.e-input-group.e-small:not(.e-float-icon-left):not(.e-input-group):not(.e-disabled) .e-input-group-icon:last-child:hover,
  .e-float-input.e-input-group.e-small.e-float-icon-left .e-input-group-icon:not(:last-child):hover,
  .e-float-input.e-input-group.e-float-icon-left .e-input-group-icon:not(:last-child):hover,
  .e-float-input.e-control-wrapper.e-input-group.e-small.e-float-icon-left .e-input-group-icon:not(:last-child):hover,
  .e-float-input.e-control-wrapper.e-input-group.e-float-icon-left .e-input-group-icon:not(:last-child):hover,
  .e-float-input.e-input-group.e-small.e-float-icon-left .e-input-group-icon:last-child:hover,
  .e-float-input.e-input-group.e-float-icon-left .e-input-group-icon:last-child:hover,
  .e-float-input.e-control-wrapper.e-input-group.e-small.e-float-icon-left .e-input-group-icon:last-child:hover,
  .e-float-input.e-control-wrapper.e-input-group.e-float-icon-left .e-input-group-icon:last-child:hover {
    @if $input-skin-name == 'highcontrast' {
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      border-width: $zero-value + px 1px $zero-value + px 1px;
      box-sizing: border-box;
    }
  }

  .e-input-group .e-input-group-icon + .e-input-group-icon .e-input-group.e-control-wrapper .e-input-group-icon + .e-input-group-icon {
    @if ($input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3') {
      border-left-width: $zero-value;
    }
  }

  .e-input-group .e-input-group-icon:first-child,
  .e-input-group.e-control-wrapper .e-input-group-icon:first-child {
    @if ($input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' or $input-skin-name == 'tailwind') {
      border-left-width: $zero-value;
    }
  }

  .e-input-group.e-rtl .e-input-group-icon,
  .e-input-group.e-control-wrapper.e-rtl .e-input-group-icon,
  .e-rtl .e-input-group .e-input-group-icon,
  .e-rtl .e-input-group.e-control-wrapper .e-input-group-icon,
  .e-input-group.e-rtl .e-input-group-icon + .e-input-group-icon,
  .e-input-group.e-control-wrapper.e-rtl .e-input-group-icon + .e-input-group-icon,
  .e-rtl .e-input-group .e-input-group-icon + .e-input-group-icon,
  .e-rtl .e-input-group.e-control-wrapper .e-input-group-icon + .e-input-group-icon {
    @if ($input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3') {
      border-left-width: 1px;
      border-right-width: $zero-value;
    }
  }

  .e-input-group.e-rtl input ~ .e-input-group-icon,
  .e-input-group.e-control-wrapper.e-rtl input ~ .e-input-group-icon,
  .e-rtl .e-input-group input ~ .e-input-group-icon,
  .e-rtl .e-input-group.e-control-wrapper input ~ .e-input-group-icon {
    @if ($input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3') {
      border-left-width: $zero-value;
      border-right: 1px solid $input-box-border-color;
    }
  }

  .e-input-group.e-rtl input ~ .e-input-group-icon + .e-input-group-icon,
  .e-input-group.e-control-wrapper.e-rtl input ~ .e-input-group-icon + .e-input-group-icon,
  .e-rtl .e-input-group input ~ .e-input-group-icon + .e-input-group-icon,
  .e-rtl .e-input-group.e-control-wrapper input ~ .e-input-group-icon + .e-input-group-icon {
    @if ($input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3') {
      border-left-width: $zero-value;
      border-right-width: 1px;
    }
  }

  .e-input-group .e-input-group-icon + .e-input-group-icon,
  .e-input-group.e-control-wrapper .e-input-group-icon + .e-input-group-icon {
    @if ($input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3') {
      border-left-width: $zero-value;
    }
  }

  .e-input-group .e-input-group-icon:last-child,
  .e-input-group.e-control-wrapper .e-input-group-icon:last-child {
    @if ($input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' or $input-skin-name == 'tailwind') {
      border-bottom-right-radius: $input-group-icon-border-radius;
      border-top-right-radius: $input-group-icon-border-radius;
    }
  }

  .e-input-group .e-input-group-icon:first-child,
  .e-input-group.e-control-wrapper .e-input-group-icon:first-child {
    @if ($input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' or $input-skin-name == 'tailwind') {
      border-bottom-left-radius: $input-group-icon-border-radius;
      border-top-left-radius: $input-group-icon-border-radius;
    }
  }

  .e-input-group.e-rtl .e-input-group-icon:last-child,
  .e-input-group.e-control-wrapper.e-rtl .e-input-group-icon:last-child,
  .e-rtl .e-input-group .e-input-group-icon:last-child,
  .e-rtl .e-input-group.e-control-wrapper .e-input-group-icon:last-child {
    @if ($input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' or $input-skin-name == 'tailwind') {
      border-bottom-left-radius: $input-group-icon-border-radius;
      border-bottom-right-radius: $zero-value;
      border-top-left-radius: $input-group-icon-border-radius;
      border-top-right-radius: $zero-value;
    }
  }

  .e-input-group.e-rtl .e-input-group-icon:first-child,
  .e-input-group.e-control-wrapper.e-rtl .e-input-group-icon:first-child,
  .e-rtl .e-input-group .e-input-group-icon:first-child,
  .e-rtl .e-input-group.e-control-wrapper .e-input-group-icon:first-child {
    @if ($input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3') {
      border-bottom-left-radius: $zero-value;
      border-bottom-right-radius: $input-group-icon-border-radius;
      border-top-left-radius: $zero-value;
      border-top-right-radius: $input-group-icon-border-radius;
    }
  }

  .e-input-group.e-float-icon-left > .e-input-group-icon,
  .e-float-input.e-input-group.e-float-icon-left > .e-input-group-icon,
  .e-input-group.e-control-wrapper.e-float-icon-left > .e-input-group-icon,
  .e-float-input.e-input-group.e-control-wrapper.e-float-icon-left > .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      font-size: $input-left-icon-font-size;
      margin: $zero-value;
      min-height: $input-left-child-min-height;
      min-width: $input-left-child-min-width;
      padding: $zero-value;
    }
  }

  .e-input-group.e-small.e-float-icon-left > .e-input-group-icon,
  .e-input-group.e-float-icon-left > .e-input-group-icon.e-small,
  .e-input-group.e-control-wrapper.e-small.e-float-icon-left > .e-input-group-icon,
  .e-input-group.e-control-wrapper.e-float-icon-left > .e-input-group-icon.e-small,
  .e-small .e-input-group.e-float-icon-left > .e-input-group-icon,
  .e-small .e-input-group.e-control-wrapper.e-float-icon-left > .e-input-group-icon,
  .e-float-input.e-input-group.e-small.e-float-icon-left > .e-input-group-icon,
  .e-float-input.e-input-group.e-float-icon-left > .e-input-group-icon.e-small,
  .e-small .e-float-input.e-input-group.e-float-icon-left > .e-input-group-icon,
  .e-float-input.e-control-wrapper.e-input-group.e-small.e-float-icon-left > .e-input-group-icon,
  .e-float-input.e-control-wrapper.e-input-group.e-float-icon-left > .e-input-group-icon.e-small,
  .e-small .e-float-input.e-control-wrapper.e-input-group.e-float-icon-left > .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      font-size: $input-small-left-icon-font-size;
      margin: $zero-value;
      min-height: $input-small-left-child-min-height;
      min-width: $input-small-left-child-min-width;
      padding: $zero-value;
    }
  }

  .e-input-group.e-float-icon-left:not(.e-disabled) > .e-input-group-icon:active,
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-disabled) > .e-input-group-icon:active {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      background: transparent;
    }
  }

  .e-input-group.e-float-icon-left > .e-input-group-icon,
  .e-input-group.e-control-wrapper.e-float-icon-left > .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      cursor: auto;
    }
  }

  .e-input#{$css}[disabled],
  .e-input-group .e-input[disabled],
  .e-input-group.e-control-wrapper .e-input#{$css}[disabled],
  .e-input-group.e-disabled,
  .e-input-group.e-disabled input,
  .e-input-group.e-disabled input.e-input,
  .e-input-group.e-disabled textarea,
  .e-input-group.e-disabled textarea.e-input,
  .e-input-group.e-control-wrapper.e-disabled,
  .e-input-group.e-control-wrapper.e-disabled input,
  .e-input-group.e-control-wrapper.e-disabled input.e-input,
  .e-input-group.e-control-wrapper.e-disabled textarea,
  .e-input-group.e-control-wrapper.e-disabled textarea.e-input,
  .e-float-input.e-disabled input,
  .e-float-input.e-disabled textarea,
  .e-float-input input[disabled],
  .e-float-input input.e-disabled,
  .e-float-input textarea[disabled],
  .e-float-input textarea.e-disabled,
  .e-float-input.e-control-wrapper.e-disabled input,
  .e-float-input.e-control-wrapper.e-disabled textarea,
  .e-float-input.e-control-wrapper input[disabled],
  .e-float-input.e-control-wrapper input.e-disabled,
  .e-float-input.e-control-wrapper textarea[disabled],
  .e-float-input.e-control-wrapper textarea.e-disabled,
  .e-input-group.e-disabled span,
  .e-input-group.e-control-wrapper.e-disabled span,
  .e-input-group.e-disabled input.e-input:not(:valid):first-child ~ .e-clear-icon,
  .e-input-group.e-control-wrapper.e-disabled input.e-input:not(:valid):first-child ~ .e-clear-icon,
  .e-float-input.e-disabled input:not(:valid):first-child ~ .e-clear-icon,
  .e-float-input.e-input-group.e-disabled input:not(:valid):first-child ~ .e-clear-icon,
  .e-float-input.e-input-group.e-control-wrapper.e-disabled input:not(:valid):first-child ~ .e-clear-icon,
  .e-float-input.e-control-wrapper.e-disabled input:not(:valid):first-child ~ .e-clear-icon,
  .e-input-group.e-disabled .e-clear-icon.e-clear-icon-hide,
  .e-input-group.e-control-wrapper.e-disabled .e-clear-icon.e-clear-icon-hide {
    cursor: not-allowed;
  }

  .e-input#{$css}[disabled],
  .e-input-group.e-disabled,
  .e-input-group.e-control-wrapper.e-disabled,
  .e-float-input input[disabled],
  .e-float-input input.e-disabled,
  .e-float-input.e-control-wrapper input[disabled],
  .e-float-input.e-control-wrapper input.e-disabled  {
    @if $input-skin-name != 'material' and $input-skin-name != 'Material3' {
      border-color: $input-disable-border-color;
      border-style: $input-disable-border-type;
    }
  }

  .e-input-group.e-disabled,
  .e-input-group.e-control-wrapper.e-disabled {
    @if $input-skin-name != 'material' and $input-skin-name != 'Material3' {
      border-bottom-style: $input-disable-border-type;
      border-width: $input-disable-group-border-width;
    }
  }

  .e-input#{$css}[disabled],
  .e-input-group.e-disabled,
  .e-input-group.e-control-wrapper.e-disabled,
  .e-float-input.e-disabled,
  .e-float-input input[disabled],
  .e-float-input input.e-disabled,
  .e-float-input.e-disabled input,
  .e-float-input.e-control-wrapper.e-disabled,
  .e-float-input.e-control-wrapper input[disabled],
  .e-float-input.e-control-wrapper input.e-disabled,
  .e-float-input.e-control-wrapper.e-disabled input,
  .e-float-input textarea[disabled],
  .e-float-input textarea.e-disabled,
  .e-float-input.e-disabled textarea,
  .e-float-input.e-control-wrapper textarea[disabled],
  .e-float-input.e-control-wrapper textarea.e-disabled,
  .e-float-input.e-control-wrapper.e-disabled textarea {
    filter: alpha(opacity=$input-opacity-filter);
    opacity: $input-opacity;
  }

  .e-input#{$css}.e-rtl,
  .e-input-group.e-rtl,
  .e-input-group.e-control-wrapper.e-rtl,
  .e-float-input.e-rtl,
  .e-float-input.e-control-wrapper.e-rtl {
    direction: rtl;
  }

  .e-input-group,
  .e-input-group.e-control-wrapper,
  .e-float-custom-tag.e-input-group,
  .e-float-custom-tag.e-input-group.e-control-wrapper,
  .e-input-custom-tag,
  .e-input-custom-tag.e-input-group,
  .e-input-custom-tag.e-input-group.e-control-wrapper {
    display: inline-flex;
    vertical-align: middle;
  }

  .e-float-input:not(.e-input-group),
  .e-float-input.e-control-wrapper:not(.e-input-group),
  .e-float-custom-tag,
  .e-float-custom-tag.e-control-wrapper {
    display: inline-block;
  }

  .e-input-group .e-input-group-icon,
  .e-input-group.e-control-wrapper .e-input-group-icon {
    display: flex;
  }

  .e-input-group .e-input-group-icon:first-child,
  .e-input-group.e-control-wrapper .e-input-group-icon:first-child {
    @if ($input-skin-name != 'bootstrap' and $input-skin-name != 'bootstrap4' and $input-skin-name != 'bootstrap5' and $input-skin-name != 'bootstrap5.3' and $input-skin-name != 'tailwind' and $input-skin-name != 'FluentUI') {
      border-left-width: $input-border-left-width;
    }
  }

  .e-input-group .e-input-group-icon,
  .e-input-group.e-control-wrapper .e-input-group-icon {
    white-space: nowrap;
  }

  .e-input-group .e-input-group-icon:not(:last-child),
  .e-input-group.e-control-wrapper .e-input-group-icon:not(:last-child) {
    @if ($input-skin-name != 'bootstrap' and $input-skin-name != 'bootstrap4' and $input-skin-name != 'bootstrap5' and $input-skin-name != 'bootstrap5.3' and $input-skin-name != 'FluentUI') {
      border-right-width: $zero-value;
    }
  }

  .e-input + .e-input-group-icon,
  .e-input-group .e-input + .e-input-group-icon,
  .e-input-group.e-control-wrapper .e-input + .e-input-group-icon {
    @if ($input-skin-name != 'bootstrap' and $input-skin-name != 'bootstrap4' and $input-skin-name != 'bootstrap5' and $input-skin-name != 'bootstrap5.3' and $input-skin-name != 'FluentUI') {
      border-left-width: $zero-value;
    }
  }
  
  .e-input-group.e-corner .e-input:first-child,
  .e-input-group.e-corner .e-input-group-icon:first-child,
  .e-input-group.e-control-wrapper.e-corner .e-input:first-child,
  .e-input-group.e-control-wrapper.e-corner .e-input-group-icon:first-child {
    border-bottom-left-radius: $input-box-border-radius;
    border-top-left-radius: $input-box-border-radius;
  }

  .e-input-group.e-corner .e-input:last-child,
  .e-input-group.e-corner .e-input-group-icon:last-child,
  .e-input-group.e-control-wrapper.e-corner .e-input:last-child,
  .e-input-group.e-control-wrapper.e-corner .e-input-group-icon:last-child {
    border-bottom-right-radius: $input-box-border-radius;
    border-top-right-radius: $input-box-border-radius;
  }

  .e-input-group.e-rtl .e-input-group-icon:first-child,
  .e-input-group.e-control-wrapper.e-rtl .e-input-group-icon:first-child  {
    @if ($input-skin-name != 'bootstrap' and $input-skin-name != 'bootstrap4' and $input-skin-name != 'bootstrap5' and $input-skin-name != 'bootstrap5.3' and $input-skin-name != 'FluentUI') {
      border-left-width: $zero-value;
      border-right-width: $input-border-left-width;
    }
  }

  .e-input-group.e-rtl .e-input-group-icon:last-child,
  .e-input-group.e-control-wrapper.e-rtl .e-input-group-icon:last-child {
    @if ($input-skin-name != 'bootstrap' and $input-skin-name != 'bootstrap4' and $input-skin-name != 'FluentUI' and $input-skin-name != 'bootstrap5' and $input-skin-name != 'bootstrap5.3' and $input-skin-name != 'tailwind') or $input-skin-name == 'highcontrast' {
      border-left-width: $input-border-left-width;
      border-right-width: $zero-value;
    }
  }

  .e-input-group.e-rtl .e-input-group-icon:not(:last-child),
  .e-input-group.e-control-wrapper.e-rtl .e-input-group-icon:not(:last-child) {
    @if ($input-skin-name != 'bootstrap' and $input-skin-name != 'bootstrap4' and $input-skin-name != 'bootstrap5' and $input-skin-name != 'bootstrap5.3' and $input-skin-name != 'tailwind' and $input-skin-name != 'FluentUI') {
      border-left-width: $input-border-left-width;
    }
  }

  .e-input-group.e-rtl:not(.e-disabled) .e-input-group-icon:not(:last-child):hover,
  .e-input-group.e-control-wrapper.e-rtl:not(.e-disabled) .e-input-group-icon:not(:last-child):hover {
    @if $input-skin-name == 'highcontrast' {
      border-width: $zero-value + px 1px $zero-value + px 1px;
    }
  }

  .e-input-group.e-rtl .e-input-group-icon + .e-input,
  .e-input-group.e-control-wrapper.e-rtl .e-input-group-icon + .e-input {
    @if ($input-skin-name != 'bootstrap' and $input-skin-name != 'bootstrap4' and $input-skin-name != 'bootstrap5' and $input-skin-name != 'bootstrap5.3' and $input-skin-name != 'FluentUI') {
      border-right-width: $zero-value;
    }
  }

  input.e-input.e-small#{$css},
  textarea.e-input.e-small#{$css},
  .e-small input.e-input#{$css},
  .e-small textarea.e-input#{$css},
  .e-input-group.e-small,
  .e-small .e-input-group,
  .e-input-group.e-control-wrapper.e-small,
  .e-small .e-input-group.e-control-wrapper,
  .e-input-group.e-small.e-disabled,
  .e-small .e-input-group.e-disabled,
  .e-input-group.e-control-wrapper.e-small.e-disabled,
  .e-small .e-input-group.e-control-wrapper.e-disabled {
    font-size: $input-small-font-size;
  }

  .e-input#{$css}.e-small,
  .e-input-group.e-small .e-input,
  .e-input-group.e-control-wrapper.e-small .e-input {
    line-height: inherit;
    padding: $input-small-padding;
  }

  .e-input-group.e-small .e-input:focus,
  .e-input-group.e-control-wrapper.e-small .e-input:focus,
  .e-input-group.e-small.e-input-focus .e-input,
  .e-input-group.e-control-wrapper.e-small.e-input-focus .e-input {
    padding: $input-small-padding;
  }

  .e-input-group.e-small .e-input-group-icon,
  .e-input-group.e-control-wrapper.e-small .e-input-group-icon,
  .e-small .e-input-group .e-input-group-icon,
  .e-small .e-input-group.e-control-wrapper .e-input-group-icon {
    font-size: $input-small-icon-font-size;
    min-height: $input-small-child-min-height;
    min-width: $input-small-child-min-width;
    padding: $input-small-child-padding;
    @if $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      border-radius: $input-small-child-border-radius;
    }
  }

  .e-input-group.e-small:not(.e-disabled) .e-input-group-icon:first-child:hover,
  .e-input-group.e-float-icon-left .e-input-group-icon:first-child:hover,
  .e-input-group.e-control-wrapper.e-small:not(.e-disabled) .e-input-group-icon:first-child:hover,
  .e-input-group.e-control-wrapper.e-float-icon-left .e-input-group-icon:first-child:hover {
    @if $input-skin-name == 'highcontrast' {
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      border-width: $zero-value + px  1px $zero-value + px 1px;
      box-sizing: border-box;
    }
  }

  label.e-float-text,
  .e-float-input label.e-float-text,
  .e-float-input.e-control-wrapper label.e-float-text,
  .e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    font-size: $float-placeholder-font-size;
    font-style: $input-font-style;
    font-weight: normal;
    left: 0;
    overflow: hidden;
    padding-left: $float-label-padding;
    pointer-events: none;
    position: absolute;
    text-overflow: ellipsis;
    top: -11px;
    transform: translate3d(0, 16px, 0) scale(1);
    transform-origin: left top;
    transition: .25s cubic-bezier(.25, .8, .25, 1);
    user-select: none;
    white-space: nowrap;
    width: 100%;
  }

  label.e-float-text,
  .e-float-input label.e-float-text,
  .e-float-input.e-control-wrapper label.e-float-text,
  .e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    top: -11px;
  }

  label.e-float-text,
  .e-float-input label.e-float-text,
  .e-float-input.e-control-wrapper label.e-float-text,
  .e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @include float-label-alignment;
    font-style: $input-font-style;
  }

  .e-float-input.e-small label.e-float-text,
  .e-float-input.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-small label.e-float-text,
  .e-float-input.e-control-wrapper.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    font-size: $float-placeholder-small-font-size;
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      top: -11px;
    }
  }

  .e-float-input .e-input-in-wrap label.e-float-text,
  .e-float-input:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper .e-input-in-wrap label.e-float-text,
  .e-float-input.e-control-wrapper:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      top: -11px;
    }
  }

  .e-float-input input:focus ~ label.e-float-text,
  .e-float-input input:valid ~ label.e-float-text,
  .e-float-input input ~ label.e-label-top.e-float-text,
  .e-float-input input[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input input[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input input label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper input:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper input:valid ~ label.e-float-text,
  .e-float-input.e-control-wrapper input ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper input[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper input[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper input label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper.e-input-focus input ~ label.e-float-text,
  .e-float-input.e-input-focus input ~ label.e-float-text {
    font-size: $float-label-font-size;
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      top: -9px;
      transform: translate3d(0, -6px, 0) scale(.92);
    }
    @else if $input-skin-name == 'fluent2' {
      padding: 0;
      top: -15px;
      transform: translate3d(0, -6px, 0) scale(.92);
    }
    @else if $input-skin-name == 'fabric' or $input-skin-name == 'highcontrast' {
      font-style: $input-font-normal;
      padding-right: 0;
      transform: translate3d(-10px, -43px, 0) scale(1);
    }
    @else if ($input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4') {
      padding-right: 0;
      transform: translate3d(-10px, -39px, 0) scale(1);
    }
    @else if ($input-skin-name == 'tailwind') {
      font-weight: 500;
      padding-right: 0;
      transform: translate3d(-10px, -35px, 0) scale(1);
    }
    @else if ($input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3') {
      font-weight: 500;
      padding-right: 0;
      transform: translate3d(-10px, -36px, 0) scale(1);
    }
    @else if ($input-skin-name == 'FluentUI') {
      font-weight: 600;
      padding-right: 0;
      transform: translate3d(-10px, -41px, 0) scale(1);
    }
  }

  .e-float-input.e-small input:focus ~ label.e-float-text,
  .e-float-input.e-small input:valid ~ label.e-float-text,
  .e-float-input.e-small input ~ label.e-label-top.e-float-text,
  .e-float-input.e-small input[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-small input[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-small input label.e-float-text.e-label-top,
  .e-small .e-float-input input:focus ~ label.e-float-text,
  .e-small .e-float-input input:valid ~ label.e-float-text,
  .e-small .e-float-input input ~ label.e-label-top.e-float-text,
  .e-small .e-float-input input[readonly] ~ label.e-label-top.e-float-text,
  .e-small .e-float-input input[disabled] ~ label.e-label-top.e-float-text,
  .e-small .e-float-input input label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper.e-small input:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small input:valid ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small input ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-small input[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-small input[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-small input label.e-float-text.e-label-top,
  .e-small .e-float-input.e-control-wrapper input:focus ~ label.e-float-text,
  .e-small .e-float-input.e-control-wrapper input:valid ~ label.e-float-text,
  .e-small .e-float-input.e-control-wrapper input ~ label.e-label-top.e-float-text,
  .e-small .e-float-input.e-control-wrapper input[readonly] ~ label.e-label-top.e-float-text,
  .e-small .e-float-input.e-control-wrapper input[disabled] ~ label.e-label-top.e-float-text,
  .e-small .e-float-input.e-control-wrapper input label.e-float-text.e-label-top {
    font-size: $float-label-font-size;
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      top: -9px;
      transform: translate3d(0, -6px, 0) scale(.92);
    }
    @else if $input-skin-name == 'fabric' or $input-skin-name == 'highcontrast' {
      font-style: $input-font-normal;
      padding-right: 0;
      transform: translate3d(-10px, -43px, 0) scale(1);
    }
    @else if ($input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4') {
      padding-right: 0;
      transform: translate3d(-10px, -39px, 0) scale(1);
    }
    @else if ($input-skin-name == 'tailwind') {
      font-weight: 500;
      padding-right: 0;
      transform: translate3d(-10px, -34px, 0) scale(1);
    }
    @else if ($input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3') {
      font-weight: 500;
      padding-right: 0;
      transform: translate3d(-10px, -35px, 0) scale(1);
    }
    @else if ($input-skin-name == 'FluentUI') {
      font-style: $input-font-normal;
      font-weight: 600;
      padding-right: 0;
      transform: translate3d(-10px, -38px, 0) scale(1);
    }
  }

  .e-float-input .e-input-in-wrap input:focus ~ label.e-float-text,
  .e-float-input .e-input-in-wrap input:valid ~ label.e-float-text,
  .e-float-input .e-input-in-wrap input ~ label.e-label-top.e-float-text,
  .e-float-input .e-input-in-wrap input[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input .e-input-in-wrap input[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input .e-input-in-wrap input label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper .e-input-in-wrap input:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper .e-input-in-wrap input:valid ~ label.e-float-text,
  .e-float-input.e-control-wrapper .e-input-in-wrap input ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper .e-input-in-wrap input[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper .e-input-in-wrap input[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper .e-input-in-wrap input label.e-float-text.e-label-top {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      top: -9px;
    }
  }

  .e-float-input.e-small input:focus ~ label.e-float-text,
  .e-float-input.e-small input:valid ~ label.e-float-text,
  .e-float-input.e-small input ~ label.e-label-top.e-float-text,
  .e-small .e-float-input input ~ label.e-label-top.e-float-text,
  .e-float-input.e-small input[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-small input[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-small input:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small input:valid ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small input ~ label.e-label-top.e-float-text,
  .e-small .e-float-input.e-control-wrapper input ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-small input[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-small input[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-small.e-input-focus input-group-animation ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-input-focus input ~ label.e-float-text {
    font-size: $float-label-small-font-size;
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      top: -8px;
    }
    @else if $input-skin-name == 'fabric' or $input-skin-name == 'FluentUI' or $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' or $input-skin-name == 'highcontrast' or $input-skin-name == 'tailwind' {
      padding-right: 0;
      top: 17px;
    }
  }

  .e-float-input.e-small .e-input-in-wrap input:focus ~ label.e-float-text,
  .e-float-input.e-small .e-input-in-wrap input:valid ~ label.e-float-text,
  .e-float-input.e-small .e-input-in-wrap input ~ label.e-label-top.e-float-text,
  .e-small .e-float-input .e-input-in-wrap input ~ label.e-label-top.e-float-text,
  .e-float-input.e-small .e-input-in-wrap input[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-small .e-input-in-wrap input[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-small .e-input-in-wrap input:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small .e-input-in-wrap input:valid ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small .e-input-in-wrap input ~ label.e-label-top.e-float-text,
  .e-small .e-float-input.e-control-wrapper .e-input-in-wrap input ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-small .e-input-in-wrap input[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-small .e-input-in-wrap input[disabled] ~ label.e-label-top.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      top: -7px;
    }
  }

  .e-float-input,
  .e-float-input.e-control-wrapper {
    line-height: 1.4;
    margin-bottom: $input-margin-bottom;
    margin-top: $input-margin-top;
    padding-top: $float-input-wrap-padding-top;
    position: relative;
    width: 100%;
  }

  .e-float-input.e-small,
  .e-float-input.e-control-wrapper.e-small,
  .e-small .e-float-input.e-control-wrapper {
    line-height: 1.35;
    margin-bottom: $input-small-margin-bottom;
    margin-top: $input-small-margin-top;
    padding-top: $float-input-small-wrap-padding-top;
  }

  .e-input-group.e-small,
  .e-input-group.e-control-wrapper.e-small,
  .e-small .e-input-group,
  .e-small .e-input-group.e-control-wrapper {
    line-height: normal;
  }

  .e-float-input.e-no-float-label,
  .e-float-input.e-small.e-no-float-label,
  .e-small .e-float-input.e-no-float-label,
  .e-float-input.e-control-wrapper.e-no-float-label,
  .e-float-input.e-control-wrapper.e-small.e-no-float-label,
  .e-small .e-float-input.e-control-wrapper.e-no-float-label {
    margin-top: $zero-value;
  }

  .e-float-input,
  .e-float-input.e-control-wrapper,
  .e-float-input.e-disabled,
  .e-float-input.e-control-wrapper.e-disabled,
  .e-float-input.e-input-group.e-disabled,
  .e-float-input.e-input-group.e-control-wrapper.e-disabled {
    font-family: $input-font-family;
    font-size: $input-font-size;
    font-weight: normal;
  }

  .e-float-input input,
  .e-float-input textarea,
  .e-float-input.e-control-wrapper input,
  .e-float-input.e-control-wrapper textarea {
    border: $float-input-border;
    border-width: $float-input-border-width;
    display: block;
    font: inherit;
    width: 100%;
  }

  .e-float-input input,
  .e-float-input.e-control-wrapper input {
    min-width: 0;
    padding: $float-input-padding;
  }

  .e-float-input input,
  .e-input-group input,
  .e-float-input.e-control-wrapper input,
  .e-input-group.e-control-wrapper input,
  .e-float-input textarea,
  .e-input-group textarea,
  .e-float-input.e-control-wrapper textarea,
  .e-input-group.e-control-wrapper textarea {
    text-indent: $input-text-indent;
  }

  .e-float-input.e-small.e-disabled,
  .e-small .e-float-input.e-disabled,
  .e-float-input.e-control-wrapper.e-small.e-disabled,
  .e-small .e-float-input.e-control-wrapper.e-disabled,
  .e-float-input.e-input-group.e-small.e-disabled,
  .e-small .e-float-input.e-input-group.e-disabled,
  .e-float-input.e-input-group.e-control-wrapper.e-small.e-disabled,
  .e-small .e-float-input.e-input-group.e-control-wrapper.e-disabled,
  .e-float-input.e-small,
  .e-small .e-float-input,
  .e-float-input.e-control-wrapper.e-small,
  .e-small .e-float-input.e-control-wrapper {
    font-size: $input-small-font-size;
  }

  .e-float-input.e-small input,
  .e-float-input.e-control-wrapper.e-small input {
    font: inherit;
    line-height: inherit;
    padding: $float-input-small-padding;
  }

  .e-float-input input:focus,
  .e-float-input.e-control-wrapper input:focus,
  .e-float-input textarea:focus,
  .e-float-input.e-control-wrapper textarea:focus,
  .e-float-input.e-input-focus input,
  .e-float-input.e-control-wrapper.e-input-focus input,
  .e-input-group.e-control-container.valid.modified,
  .e-input-group.e-control-container.invalid,
  .e-float-input.e-control-container.valid.modified,
  .e-float-input.e-control-container.invalid {
    outline: none;
  }

  label.e-float-text,
  .e-float-input label.e-float-text,
  .e-float-input.e-control-wrapper label.e-float-text {
    font-family: inherit;
  }

  .e-float-input input:valid ~ label.e-float-text,
  .e-float-input input:focus ~ label.e-float-text,
  .e-float-input input:valid ~ label.e-float-text.e-label-top,
  .e-float-input input ~ label.e-float-text.e-label-top,
  .e-float-input .e-input-in-wrap input:valid ~ label.e-float-text,
  .e-float-input .e-input-in-wrap input:valid ~ label.e-float-text.e-label-top,
  .e-float-input .e-input-in-wrap input ~ label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper input:valid ~ label.e-float-text,
  .e-float-input.e-control-wrapper input:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper input:valid ~ label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper input ~ label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper .e-input-in-wrap input:valid ~ label.e-float-text,
  .e-float-input.e-control-wrapper .e-input-in-wrap input:valid ~ label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper .e-input-in-wrap input ~ label.e-float-text.e-label-top,
  .e-float-input.e-input-focus input ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-input-focus input ~ label.e-float-text,
  .e-float-input textarea:valid ~ label.e-float-text,
  .e-float-input textarea:focus ~ label.e-float-text,
  .e-float-input textarea:valid ~ label.e-float-text.e-label-top,
  .e-float-input textarea ~ label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper textarea:valid ~ label.e-float-text,
  .e-float-input.e-control-wrapper textarea:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper textarea:valid ~ label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper textarea ~ label.e-float-text.e-label-top {
    @if ($input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3') {
      font-weight: $input-float-font-weight;
    }
    user-select: text;
  }

  .e-float-input.e-outline.e-float-icon-left:not(.e-rtl) .e-input-in-wrap input ~ label.e-float-text.e-label-top {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      left: -34px;
      width: auto;
    }
  }

  .e-float-input.e-outline.e-float-icon-left.e-rtl .e-input-in-wrap input ~ label.e-float-text.e-label-top {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      right: -34px;
      width: auto;
    }
  }
  
  label.e-float-text,
  .e-float-input label.e-float-text,
  .e-float-input.e-control-wrapper label.e-float-text,
  .e-float-input:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-small:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-small:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input.e-control-wrapper:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-small:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-small:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input.e-control-wrapper:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    font-weight: normal;
  }

  .e-float-input:not(.e-input-group) .e-float-line::before,
  .e-float-input:not(.e-input-group) .e-float-line::after,
  .e-float-input.e-control-wrapper:not(.e-input-group) .e-float-line::before,
  .e-float-input.e-control-wrapper:not(.e-input-group) .e-float-line::after {
    bottom: 0;
    content: '';
    height: 2px;
    position: absolute;
    transition: .2s ease;
    width: 0;
  }

  .e-float-input:not(.e-input-group) .e-float-line::before,
  .e-float-input.e-control-wrapper:not(.e-input-group) .e-float-line::before {
    left: 50%;
  }

  .e-float-input:not(.e-input-group) .e-float-line::after,
  .e-float-input.e-control-wrapper:not(.e-input-group) .e-float-line::after {
    right: 50%;
  }

  .e-float-input:not(.e-input-group) input:focus ~ .e-float-line::before,
  .e-float-input:not(.e-input-group) textarea:focus ~ .e-float-line::before,
  .e-float-input:not(.e-input-group) input:focus ~ .e-float-line::after,
  .e-float-input:not(.e-input-group) textarea:focus ~ .e-float-line::after,
  .e-float-input.e-control-wrapper:not(.e-input-group) input:focus ~ .e-float-line::before,
  .e-float-input.e-control-wrapper:not(.e-input-group) textarea:focus ~ .e-float-line::before,
  .e-float-input.e-control-wrapper:not(.e-input-group) input:focus ~ .e-float-line::after,
  .e-float-input.e-control-wrapper:not(.e-input-group) textarea:focus ~ .e-float-line::after,
  .e-float-input:not(.e-input-group).e-input-focus input ~ .e-float-line::before,
  .e-float-input:not(.e-input-group).e-input-focus input ~ .e-float-line::after,
  .e-float-input.e-control-wrapper:not(.e-input-group).e-input-focus input ~ .e-float-line::before,
  .e-float-input.e-control-wrapper:not(.e-input-group).e-input-focus input ~ .e-float-line::after {
    width: 50%;
  }

  .e-float-input .e-float-line,
  .e-float-input.e-control-wrapper .e-float-line {
    display: block;
    position: relative;
    width: 100%;
  }

  .e-float-input.e-rtl label.e-float-text,
  .e-float-input.e-control-wrapper.e-rtl label.e-float-text,
  .e-rtl .e-float-input label.e-float-text,
  .e-rtl .e-float-input.e-control-wrapper label.e-float-text,
  .e-rtl label.e-float-text,
  .e-rtl .e-float-input.e-control-wrapper label.e-float-text,
  .e-rtl.e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl .e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl.e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl .e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl.e-float-input textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl.e-float-input.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl .e-float-input textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl .e-float-input.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    right: 0;
    transform-origin: right top;
    @if $input-skin-name == 'fluent2' {
      padding-right: 8px;
    }
  }

  .e-float-input.e-rtl:not(.e-input-focus) label.e-float-text.e-label-bottom,
  .e-rtl .e-float-input:not(.e-input-focus) label.e-float-text.e-label-bottom,
  .e-float-input.e-rtl.e-control-wrapper:not(.e-input-focus) label.e-float-text.e-label-bottom,
  .e-rtl .e-float-input.e-control-wrapper:not(.e-input-focus) label.e-float-text.e-label-bottom,
  .e-float-input.e-rtl input:not(:focus):not(:valid) label.e-float-text,
  .e-float-input.e-rtl input:not(:focus):not(:valid) label.e-float-text.e-label-bottom,
  .e-rtl .e-float-input input:not(:focus):not(:valid) label.e-float-text,
  .e-rtl .e-float-input input:not(:focus):not(:valid) label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-rtl input:not(:focus):not(:valid) label.e-float-text,
  .e-rtl .e-float-input.e-control-wrapper input:not(:focus):not(:valid) label.e-float-text,
  .e-float-input.e-rtl.e-control-wrapper input:not(:focus):not(:valid) label.e-float-text.e-label-bottom,
  .e-rtl .e-float-input.e-control-wrapper input:not(:focus):not(:valid) label.e-float-text.e-label-bottom,
  .e-float-input.e-rtl.e-small:not(.e-input-focus) label.e-float-text.e-label-bottom,
  .e-float-input.e-rtl.e-control-wrapper.e-small:not(.e-input-focus) label.e-float-text.e-label-bottom,
  .e-rtl .e-float-input.e-small:not(.e-input-focus) label.e-float-text.e-label-bottom,
  .e-rtl .e-float-input.e-control-wrapper.e-small:not(.e-input-focus) label.e-float-text.e-label-bottom,
  .e-small .e-float-input.e-rtl:not(.e-input-focus) label.e-float-text.e-label-bottom,
  .e-small .e-float-input.e-rtl.e-control-wrapper:not(.e-input-focus) label.e-float-text.e-label-bottom,
  .e-small.e-rtl .e-float-input:not(.e-input-focus) label.e-float-text.e-label-bottom,
  .e-small.e-rtl .e-float-input.e-control-wrapper:not(.e-input-focus) label.e-float-text.e-label-bottom,
  .e-float-input.e-small.e-rtl input:not(:focus):not(:valid) label.e-float-text,
  .e-float-input.e-rtl.e-small input:not(:focus):not(:valid) label.e-float-text.e-label-bottom,
  .e-rtl .e-float-input.e-small input:not(:focus):not(:valid) label.e-float-text,
  .e-rtl .e-float-input.e-small input:not(:focus):not(:valid) label.e-float-text.e-label-bottom,
  .e-small .e-float-input.e-rtl input:not(:focus):not(:valid) label.e-float-text,
  .e-small .e-float-input.e-rtl input:not(:focus):not(:valid) label.e-float-text.e-label-bottom,
  .e-small.e-rtl .e-float-input input:not(:focus):not(:valid) label.e-float-text,
  .e-small.e-rtl .e-float-input input:not(:focus):not(:valid) label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-small.e-rtl input:not(:focus):not(:valid) label.e-float-text,
  .e-float-input.e-control-wrapper.e-rtl.e-small input:not(:focus):not(:valid) label.e-float-text.e-label-bottom,
  .e-rtl .e-float-input.e-control-wrapper.e-small input:not(:focus):not(:valid) label.e-float-text,
  .e-rtl .e-float-input.e-control-wrapper.e-small input:not(:focus):not(:valid) label.e-float-text.e-label-bottom,
  .e-small .e-float-input.e-control-wrapper.e-rtl input:not(:focus):not(:valid) label.e-float-text,
  .e-small .e-float-input.e-control-wrapper.e-rtl input:not(:focus):not(:valid) label.e-float-text.e-label-bottom,
  .e-small.e-rtl .e-float-input.e-control-wrapper input:not(:focus):not(:valid) label.e-float-text,
  .e-small.e-rtl .e-float-input.e-control-wrapper input:not(:focus):not(:valid) label.e-float-text.e-label-bottom {
    padding-right: $float-label-padding;
  }
  
  .e-input-group.e-corner.e-rtl .e-input:first-child,
  .e-input-group.e-corner.e-rtl .e-input-group-icon:first-child,
  .e-input-group.e-control-wrapper.e-corner.e-rtl .e-input:first-child,
  .e-input-group.e-control-wrapper.e-corner.e-rtl .e-input-group-icon:first-child {
    border-bottom-left-radius: $zero-value;
    border-bottom-right-radius: $input-box-border-radius;
    border-top-left-radius: $zero-value;
    border-top-right-radius: $input-box-border-radius;
  }

  .e-input-group.e-corner.e-rtl .e-input:last-child,
  .e-input-group.e-corner.e-rtl .e-input-group-icon:last-child,
  .e-input-group.e-control-wrapper.e-corner.e-rtl .e-input:last-child,
  .e-input-group.e-control-wrapper.e-corner.e-rtl .e-input-group-icon:last-child  {
    border-bottom-left-radius: $input-box-border-radius;
    border-bottom-right-radius: $zero-value;
    border-top-left-radius: $input-box-border-radius;
    border-top-right-radius: $zero-value;
  }

  .e-input-group.e-warning::before,
  .e-input-group.e-control-wrapper.e-warning::before {
    content: '';
  }

  .e-float-input input[disabled],
  .e-float-input input.e-disabled,
  .e-float-input.e-control-wrapper input[disabled],
  .e-float-input.e-control-wrapper input.e-disabled {
    background: transparent;
    background-image: none;
    cursor: not-allowed;
    @if $input-skin-name == 'highcontrast' {
      color: $disable;
    }
  }

  .e-input-group.e-rtl .e-input:not(:first-child):focus,
  .e-input-group.e-control-wrapper.e-rtl .e-input:not(:first-child):focus {
    border-right-width: $input-right-border-width;
  }

  .e-input-group input.e-input,
  .e-input-group.e-control-wrapper input.e-input {
    min-width: 0;
    width: 100%;
  }

  .e-input-group input.e-input,
  .e-input-group textarea.e-input,
  .e-input-group input.e-input:hover:not(.e-success):not(.e-warning):not(.e-error):not([disabled]):not(:focus),
  .e-input-group textarea.e-input:hover:not(.e-success):not(.e-warning):not(.e-error):not([disabled]),
  .e-input-group.e-control-wrapper input.e-input,
  .e-input-group.e-control-wrapper textarea.e-input,
  .e-input-group.e-control-wrapper input.e-input:hover:not(.e-success):not(.e-warning):not(.e-error):not([disabled]):not(:focus),
  .e-input-group.e-control-wrapper textarea.e-input:hover:not(.e-success):not(.e-warning):not(.e-error):not([disabled]) {
    border: $input-border;
    border-width: $input-group-border-width;
  }

  .e-input-group input.e-input,
  .e-input-group textarea.e-input,
  .e-input-group.e-control-wrapper input.e-input,
  .e-input-group.e-control-wrapper textarea.e-input,
  .e-float-input input.e-input,
  .e-float-input.e-control-wrapper input.e-input {
    margin-bottom: $zero-value;
  }

  .e-input-group.e-input-focus::before,
  .e-input-group.e-control-wrapper.e-input-focus::before,
  .e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
  .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
  .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
  .e-float-input.e-input-group:not(.e-float-icon-left) .e-float-line::before,
  .e-float-input.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::before,
  .e-float-input.e-control-wrapper.e-input-group:not(.e-float-icon-left) .e-float-line::before,
  .e-float-input.e-control-wrapper.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::before {
    @if $input-skin-name == 'fluent2' {
      border-bottom-right-radius: 10px;
    }
  }

  .e-input-group.e-input-focus::after,
  .e-input-group.e-control-wrapper.e-input-focus::after,
  .e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
  .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
  .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after,
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
  .e-float-input.e-input-group:not(.e-float-icon-left) .e-float-line::after,
  .e-float-input.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::after,
  .e-float-input.e-control-wrapper.e-input-group:not(.e-float-icon-left) .e-float-line::after,
  .e-float-input.e-control-wrapper.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::after {
    @if $input-skin-name == 'fluent2' {
      border-bottom-left-radius: 10px;
    }
  }

  .e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
  .e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
  .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
  .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
  .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
  .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after,
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
  .e-float-input.e-input-group:not(.e-float-icon-left) .e-float-line::before,
  .e-float-input.e-input-group:not(.e-float-icon-left) .e-float-line::after,
  .e-float-input.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::before,
  .e-float-input.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::after,
  .e-float-input.e-control-wrapper.e-input-group:not(.e-float-icon-left) .e-float-line::before,
  .e-float-input.e-control-wrapper.e-input-group:not(.e-float-icon-left) .e-float-line::after,
  .e-float-input.e-control-wrapper.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::before,
  .e-float-input.e-control-wrapper.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::after,
  .e-filled.e-float-input.e-input-group.e-float-icon-left .e-float-line::before,
  .e-filled.e-float-input.e-input-group.e-float-icon-left .e-float-line::after,
  .e-filled.e-float-input.e-control-wrapper.e-input-group.e-float-icon-left .e-float-line::before,
  .e-filled.e-float-input.e-control-wrapper.e-input-group.e-float-icon-left .e-float-line::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      @include input-group-animation;
    }
  }

  .e-input-group::before,
  .e-input-group::after,
  .e-input-group.e-control-wrapper::before,
  .e-input-group.e-control-wrapper::after  {
    @if $input-skin-name != 'material' and $input-skin-name != 'Material3' {
      @include input-group-animation;
    }
  }

  .e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
  .e-input-group.e-float-icon-left .e-input-in-wrap:not(.e-float-input)::before,
  .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
  .e-input-group.e-control-wrapper.e-float-icon-left .e-input-in-wrap:not(.e-float-input)::before,
  .e-float-input.e-input-group:not(.e-float-icon-left) .e-float-line::before,
  .e-float-input.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::before,
  .e-float-input.e-control-wrapper.e-input-group:not(.e-float-icon-left) .e-float-line::before,
  .e-float-input.e-control-wrapper.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::before,
  .e-filled.e-input-group.e-float-icon-left::before,
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left::before,
  .e-filled.e-float-input.e-input-group.e-float-icon-left .e-float-line::before,
  .e-filled.e-float-input.e-control-wrapper.e-input-group.e-float-icon-left .e-float-line::before {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      @include input-group-animation-left;
    }
  }

  .e-input-group::before,
  .e-input-group.e-control-wrapper::before {
    @if $input-skin-name != 'material' and $input-skin-name != 'Material3' {
      @include input-group-animation-left;
    }
  }

  .e-input-group:not(.e-float-icon-left):not(.e-float-input).e-input-focus::before,
  .e-input-group:not(.e-float-icon-left):not(.e-float-input).e-input-focus::after,
  .e-input-group.e-float-icon-left:not(.e-float-input).e-input-focus .e-input-in-wrap::before,
  .e-input-group.e-float-icon-left:not(.e-float-input).e-input-focus .e-input-in-wrap::after,
  .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input).e-input-focus::before,
  .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input).e-input-focus::after,
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input).e-input-focus .e-input-in-wrap::before,
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input).e-input-focus .e-input-in-wrap::after,
  .e-float-input.e-input-group:not(.e-float-icon-left).e-input-focus .e-float-line::before,
  .e-float-input.e-input-group:not(.e-float-icon-left).e-input-focus .e-float-line::after,
  .e-float-input.e-input-group.e-float-icon-left.e-input-focus .e-input-in-wrap .e-float-line::before,
  .e-float-input.e-input-group.e-float-icon-left.e-input-focus .e-input-in-wrap .e-float-line::after,
  .e-float-input.e-control-wrapper.e-input-group:not(.e-float-icon-left).e-input-focus .e-float-line::before,
  .e-float-input.e-control-wrapper.e-input-group:not(.e-float-icon-left).e-input-focus .e-float-line::after,
  .e-float-input.e-control-wrapper.e-input-group.e-float-icon-left.e-input-focus .e-input-in-wrap .e-float-line::before,
  .e-float-input.e-control-wrapper.e-input-group.e-float-icon-left.e-input-focus .e-input-in-wrap .e-float-line::after,
  .e-filled.e-float-input.e-input-group.e-float-icon-left.e-input-focus .e-float-line::before,
  .e-filled.e-float-input.e-input-group.e-float-icon-left.e-input-focus .e-float-line::after,
  .e-filled.e-float-input.e-control-wrapper.e-input-group.e-float-icon-left.e-input-focus .e-float-line::before,
  .e-filled.e-float-input.e-control-wrapper.e-input-group.e-float-icon-left.e-input-focus .e-float-line::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      @include input-group-animation-width;
    }
  }

  .e-input-group.e-input-focus::before,
  .e-input-group.e-input-focus::after,
  .e-input-group.e-control-wrapper.e-input-focus::before,
  .e-input-group.e-control-wrapper.e-input-focus::after {
    @if $input-skin-name != 'material' and $input-skin-name != 'Material3' {
      @include input-group-animation-width;
    }
  }

  .e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
  .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
  .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after,
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
  .e-float-input.e-input-group:not(.e-float-icon-left) .e-float-line::after,
  .e-float-input.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::after,
  .e-float-input.e-control-wrapper.e-input-group:not(.e-float-icon-left) .e-float-line::after,
  .e-float-input.e-control-wrapper.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::after,
  .e-filled.e-input-group.e-float-icon-left:not(.e-float-input)::after,
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)::after,
  .e-filled.e-float-input.e-input-group.e-float-icon-left .e-float-line::after,
  .e-filled.e-float-input.e-control-wrapper.e-input-group.e-float-icon-left .e-float-line::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      @include input-group-animation-right;
    }
  }

  .e-input-group::after,
  .e-input-group.e-control-wrapper::after {
    @if $input-skin-name != 'material' and $input-skin-name != 'Material3' {
      @include input-group-animation-right;
    }
  }

  .e-input-group,
  .e-input-group.e-control-wrapper {
    position: relative;
    width: 100%;
  }

  .e-input-group .e-input-group-icon:hover,
  .e-input-group.e-rtl.e-corner .e-input-group-icon:hover,
  .e-input-group.e-control-wrapper .e-input-group-icon:hover,
  .e-input-group.e-control-wrapper.e-rtl.e-corner .e-input-group-icon:hover {
    @if ($input-skin-name != 'bootstrap' and $input-skin-name != 'bootstrap4' and $input-skin-name != 'bootstrap5' and $input-skin-name != 'bootstrap5.3' and $input-skin-name != 'Material3') {
      border-radius: $input-group-border-radius;
    }
  }

  .e-input#{$css}.e-small,
  .e-input-group.e-small,
  .e-input-group.e-control-wrapper.e-small {
    margin-bottom: $input-small-margin-bottom;
  }

  .e-input-group .e-input-group-icon,
  .e-input-group.e-control-wrapper .e-input-group-icon {
    margin-bottom: $input-child-margin-bottom;
    margin-right: $input-child-margin-right;
    margin-top: $input-child-margin-top;
  }

  .e-float-input.e-input-group .e-input-group-icon,
  .e-float-input.e-control-wrapper.e-input-group .e-input-group-icon {
    @if $input-skin-name != 'Material3' {
      margin-top: $float-input-child-margin-top;
    }
  }

  .e-input-group.e-small .e-input-group-icon,
  .e-input-group.e-control-wrapper.e-small .e-input-group-icon,
  .e-small .e-input-group .e-input-group-icon,
  .e-small .e-input-group.e-control-wrapper .e-input-group-icon {
    @if ($input-skin-name != 'Material3') {
      margin-bottom: $input-child-small-margin-bottom;
      margin-right: $input-child-small-margin-right;
      margin-top: $input-child-small-margin-top;
    }
    @else {
      margin: $input-child-small-margin;
    }
  }

  .e-float-input.e-input-group.e-small .e-input-group-icon,
  .e-small .e-float-input.e-input-group .e-input-group-icon,
  .e-float-input.e-control-wrapper.e-input-group.e-small .e-input-group-icon,
  .e-small .e-float-input.e-control-wrapper.e-input-group .e-input-group-icon {
    @if $input-skin-name != 'Material3' and $input-skin-name != 'fluent2' {
      margin-top: $float-input-child-small-margin-top;
    }
  }

  .e-input-group .e-input-group-icon:last-child,
  .e-input-group.e-control-wrapper .e-input-group-icon:last-child {
    @if $skin-name == 'fluent2' {
      margin-right: 4px;
    }
  }

  .e-input-group .e-input-group-icon:last-child,
  .e-input-group.e-control-wrapper .e-input-group-icon:last-child,
  .e-input-group.e-small .e-input-group-icon:last-child,
  .e-input-group.e-control-wrapper.e-small .e-input-group-icon:last-child {
    @if $input-skin-name != 'fluent' {
      margin-right: $zero-value;
    }
    @if $input-skin-name == 'fluent2' {
      margin: $zero-value;
    }
  }

  .e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
  .e-input-group.e-control-wrapper.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error) {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-style: $input-group-border-type-focus;
      border-width: $input-group-border-width-focus;
    }
  }

  .e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
  .e-input-group.e-input-focus:not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error),
  .e-input-group.e-input-focus:not(.e-float-icon-left).e-warning:not(.e-success):not(.e-error),
  .e-input-group.e-input-focus:not(.e-float-icon-left).e-error:not(.e-success):not(.e-warning),
  .e-float-input.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
  .e-float-input.e-input-group.e-input-focus:not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error),
  .e-float-input.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success).e-warning:not(.e-error),
  .e-float-input.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning).e-error,
  .e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error) input,
  .e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error) input,
  .e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-success).e-warning:not(.e-error) input,
  .e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-success):not(.e-warning).e-error input,
  .e-input-group.e-float-icon-left.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap,
  .e-input-group.e-control-wrapper.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
  .e-input-group.e-control-wrapper.e-input-focus:not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error),
  .e-input-group.e-control-wrapper.e-input-focus:not(.e-float-icon-left).e-warning:not(.e-success):not(.e-error),
  .e-input-group.e-control-wrapper.e-input-focus:not(.e-float-icon-left).e-error:not(.e-success):not(.e-warning),
  .e-float-input.e-control-wrapper.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
  .e-float-input.e-control-wrapper.e-input-group.e-input-focus:not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error),
  .e-float-input.e-control-wrapper.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success).e-warning:not(.e-error),
  .e-float-input.e-control-wrapper.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning).e-error,
  .e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error) input,
  .e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error) input,
  .e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-success).e-warning:not(.e-error) input,
  .e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-success):not(.e-warning).e-error input,
  .e-input-group.e-control-wrapper.e-float-icon-left.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-style: $input-group-border-type-focus;
      border-width: $input-group-border-width-focus;
    }
  }

  .e-input-group,
  .e-input-group.e-control-wrapper {
    @if $input-skin-name != 'material' and $input-skin-name != 'Material3' {
      border-bottom: $input-group-border;
    }
  }

  .e-input-group:not(.e-float-icon-left),
  .e-input-group.e-control-wrapper:not(.e-float-icon-left),
  .e-filled.e-input-group.e-float-icon-left,
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      border-bottom: $input-group-border;
    }
  }

  .e-input-group:not(.e-float-icon-left),
  .e-input-group.e-success:not(.e-float-icon-left),
  .e-input-group.e-warning:not(.e-float-icon-left),
  .e-input-group.e-error:not(.e-float-icon-left),
  .e-input-group.e-control-wrapper:not(.e-float-icon-left),
  .e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left),
  .e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left),
  .e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left) {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border: $input-group-full-border;
      border-width: $input-group-full-border-width;
    }
    @if $input-skin-name == 'Material3' {
      padding-top: 1px;
    }
  }

  .e-underline.e-input-group:not(.e-float-icon-left),
  .e-underline.e-input-group.e-success:not(.e-float-icon-left),
  .e-underline.e-input-group.e-warning:not(.e-float-icon-left),
  .e-underline.e-input-group.e-error:not(.e-float-icon-left),
  .e-underline.e-input-group.e-control-wrapper:not(.e-float-icon-left),
  .e-underline.e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left),
  .e-underline.e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left),
  .e-underline.e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left) {
    @if $input-skin-name == 'fluent2' {
      border: $input-group-full-border;
      border-width: $input-group-full-border-width;
      padding-top: 1px;
      border-radius: 0;
    }
  }

  .e-input-group,
  .e-input-group.e-success,
  .e-input-group.e-warning,
  .e-input-group.e-error,
  .e-input-group.e-control-wrapper,
  .e-input-group.e-control-wrapper.e-success,
  .e-input-group.e-control-wrapper.e-warning,
  .e-input-group.e-control-wrapper.e-error {
    @if $input-skin-name != 'material' and $input-skin-name != 'Material3' {
      border: $input-group-full-border;
      border-width: $input-group-full-border-width;
    }
  }

  .e-input-group.e-rtl.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input:not(:first-child):focus,
  .e-input-group.e-control-wrapper.e-rtl.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input:not(:first-child):focus {
    border-right-width: $zero-value;
  }

  .e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left).e-disabled,
  .e-input-group.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error).e-disabled .e-input-in-wrap,
  .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left).e-disabled,
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error).e-disabled .e-input-in-wrap {
    background: $input-disable-bg-color;
    color: $input-disable-font-color;
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      background-image: linear-gradient(90deg, $input-disable-border-color 0, $input-disable-border-color 33%, transparent 0);
      background-position: bottom -1px left 0;
      background-repeat: repeat-x;
      background-size: 4px 1px;
      border-bottom-color: transparent;
      color: $input-disable-font-color;
    }
    @if $input-skin-name == 'fluent2' {
      border-color: $input-disable-border-color;
    }
  }

  .e-input-group:not(.e-success):not(.e-warning):not(.e-error).e-disabled,
  .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error).e-disabled {
    @if $input-skin-name != 'material' and $input-skin-name != 'Material3' {
      border-style: $input-disable-border-type;
    }
  }

  .e-input-group .e-input-group-icon,
  .e-input-group.e-control-wrapper .e-input-group-icon {
    @if $input-skin-name != 'fluent2' {
      @include input-ripple-parent;
    }
  }

  .e-input-group:not(.e-filled) .e-input-group-icon::after,
  .e-input-group.e-control-wrapper:not(.e-filled) .e-input-group-icon::after {
    @if $input-skin-name != 'fluent2' {
      @include input-ripple-style;
    }
  }

  .e-input-group .e-input-group-icon.e-input-btn-ripple::after,
  .e-input-group.e-control-wrapper .e-input-group-icon.e-input-btn-ripple::after {
    @if $input-skin-name != 'fluent2' {
      @include input-ripple-animation;
    }
  }

  input.e-input#{$css}::-ms-clear,
  .e-float-input input::-ms-clear,
  .e-float-input.e-control-wrapper input::-ms-clear,
  .e-input:not(:valid):not(.e-control):first-child ~ .e-clear-icon,
  .e-input-group input:not(.e-control).e-input:not(:valid):first-child ~ .e-clear-icon,
  .e-input-group.e-control-wrapper input.e-input:not(:valid):not(.e-control):first-child ~ .e-clear-icon,
  .e-float-input input:not(:valid):not(.e-control):first-child ~ .e-clear-icon,
  .e-float-input.e-control-wrapper input:not(:valid):not(.e-control):first-child ~ .e-clear-icon,
  .e-float-input.e-input-group input:not(:valid):not(.e-control):first-child ~ .e-clear-icon,
  .e-float-input.e-input-group.e-control-wrapper input:not(:valid):not(.e-control):first-child ~ .e-clear-icon {
    display: none;
  }

  .e-input-group .e-clear-icon.e-clear-icon-hide,
  .e-input-group.e-control-wrapper .e-clear-icon.e-clear-icon-hide,
  .e-float-input.e-control-wrapper.e-hidden,
  .e-input-group.e-control-wrapper.e-hidden {
    display: none;
  }

  input.e-input[type = 'search']::-webkit-search-decoration,
  input.e-input[type = 'search']::-webkit-search-cancel-button,
  input.e-input[type = 'search']::-webkit-search-results-button,
  input.e-input[type = 'search']::-webkit-search-results-decoration,
  .e-float-input input[type = 'search']::-webkit-search-decoration,
  .e-float-input input[type = 'search']::-webkit-search-cancel-button,
  .e-float-input input[type = 'search']::-webkit-search-results-button,
  .e-float-input input[type = 'search']::-webkit-search-results-decoration,
  .e-float-input.e-control-wrapper input[type = 'search']::-webkit-search-decoration,
  .e-float-input.e-control-wrapper input[type = 'search']::-webkit-search-cancel-button,
  .e-float-input.e-control-wrapper input[type = 'search']::-webkit-search-results-button,
  .e-float-input.e-control-wrapper input[type = 'search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  .e-float-input.e-input-group .e-float-line,
  .e-float-input.e-input-group.e-control-wrapper .e-float-line,
  .e-float-input.e-control-wrapper.e-input-group .e-float-line,
  .e-float-input.e-control-wrapper.e-input-group.e-control-wrapper .e-float-line {
    @if $input-skin-name == 'fluent2' {
      bottom: -.1px;
      position: absolute;
    }
    @else {
      bottom: -1px;
      position: absolute;
    }
  }

  .e-float-input.e-input-group input,
  .e-float-input.e-input-group textarea,
  .e-float-input.e-input-group.e-control-wrapper input,
  .e-float-input.e-input-group.e-control-wrapper textarea {
    border: $zero-value;
  }

  .e-float-input.e-input-group input:focus,
  .e-input-group input:focus,
  .e-float-input.e-input-group textarea:focus,
  .e-float-input.e-input-group.e-control-wrapper input:focus,
  .e-float-input.e-input-group.e-control-wrapper textarea {
    @if $input-skin-name == 'tailwind' {
      box-shadow: none;
    }
  }

  .e-float-input.e-input-group .e-float-line,
  .e-float-input.e-input-group .e-float-text,
  .e-float-input.e-input-group.e-control-wrapper .e-float-line,
  .e-float-input.e-input-group.e-control-wrapper .e-float-text  {
    right: $zero-value;
  }

  
  input.e-input#{$css}::-webkit-input-placeholder,
  input.e-input#{$css}:-moz-placeholder,
  input.e-input#{$css}:-ms-input-placeholder,
  input.e-input#{$css}::-moz-placeholder,
  textarea.e-input#{$css}::-webkit-input-placeholder,
  textarea.e-input#{$css}:-moz-placeholder,
  textarea.e-input#{$css}:-ms-input-placeholder,
  textarea.e-input#{$css}::-moz-placeholder,
  textarea.e-input#{$css}::-webkit-textarea-placeholder,
  textarea.e-input#{$css}:-moz-placeholder,
  textarea.e-input#{$css}:-ms-textarea-placeholder,
  textarea.e-input#{$css}::-moz-placeholder {
    font-size: $input-font-size;
    font-style: $input-font-style;
  }
  
  .e-small input.e-input#{$css}::-webkit-input-placeholder,
  input.e-small.e-input#{$css}::-webkit-input-placeholder,
  .e-small input.e-input#{$css}:-moz-placeholder,
  input.e-small.e-input#{$css}:-moz-placeholder,
  .e-small input.e-input#{$css}:-ms-input-placeholder,
  input.e-small.e-input#{$css}:-ms-input-placeholder,
  .e-small input.e-input#{$css}::-moz-placeholder,
  input.e-small.e-input#{$css}::-moz-placeholder,
  .e-small textarea.e-input#{$css}::-webkit-input-placeholder,
  textarea.e-small.e-input#{$css}::-webkit-input-placeholder,
  .e-small textarea.e-input#{$css}:-moz-placeholder,
  textarea.e-small.e-input#{$css}:-moz-placeholder,
  .e-small textarea.e-input#{$css}:-ms-input-placeholder,
  textarea.e-small.e-input#{$css}:-ms-input-placeholder,
  .e-small textarea.e-input#{$css}::-moz-placeholder,
  textarea.e-small.e-input#{$css}::-moz-placeholder,
  .e-small textarea.e-input#{$css}::-webkit-textarea-placeholder,
  textarea.e-small.e-input#{$css}::-webkit-textarea-placeholder,
  .e-small textarea.e-input#{$css}:-moz-placeholder,
  textarea.e-small.e-input#{$css}:-moz-placeholder,
  .e-small textarea.e-input#{$css}:-ms-input-placeholder,
  textarea.e-small.e-input#{$css}:-ms-input-placeholder,
  .e-small textarea.e-input#{$css}::-moz-placeholder,
  textarea.e-small.e-input#{$css}::-moz-placeholder {
    font-size: $input-small-font-size;
    font-style: $input-font-style;
  }
  
  input.e-input#{$css}:-moz-placeholder,
  textarea.e-input#{$css}:-moz-placeholder,
  .e-input-group input.e-input:-moz-placeholder,
  .e-input-group textarea.e-input:-moz-placeholder,
  .e-input-group.e-control-wrapper input.e-input:-moz-placeholder,
  .e-input-group.e-control-wrapper textarea.e-input:-moz-placeholder,
  input.e-input#{$css}:-moz-placeholder,
  .e-input-group input.e-input:-moz-placeholder,
  .e-input-group.e-control-wrapper input.e-input:-moz-placeholder,
  textarea.e-input#{$css}:-moz-placeholder,
  input.e-input#{$css}::-moz-placeholder,
  textarea.e-input#{$css}::-moz-placeholder,
  input.e-input#{$css}::-webkit-input-placeholder,
  textarea.e-input#{$css}::-webkit-input-placeholder {
    font-style: $input-font-style;
    user-select: none;
  }
  
  input.e-input#{$css}:-ms-input-placeholder,
  textarea.e-input#{$css}:-ms-input-placeholder {
    font-style: $input-font-style;
  }

  input.e-input#{$css},
  .e-input-group input,
  .e-input-group.e-control-wrapper input,
  .e-input-group input.e-input,
  .e-input-group.e-control-wrapper input.e-input {
    @include input-sizing;
    @include input-height ($input-normal-height);
  }

  .e-float-input:not(.e-input-group) input,
  .e-float-input.e-control-wrapper:not(.e-input-group) input {
    @include input-sizing;
    @include input-height ($float-input-normal-height);
  }

  .e-control input.e-input,
  .e-control .e-input-group input,
  .e-control .e-input-group input.e-input,
  .e-control .e-input-group.e-control-wrapper input,
  .e-control .e-input-group.e-control-wrapper input.e-input,
  .e-control .e-float-input input,
  .e-control .e-float-input.e-control-wrapper input,
  .e-control.e-input-group input,
  .e-control.e-input-group input.e-input,
  .e-control.e-input-group.e-control-wrapper input,
  .e-control.e-input-group.e-control-wrapper input.e-input,
  .e-control.e-float-input input,
  .e-control.e-float-input.e-control-wrapper input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      @include input-sizing;
    }
  }

  input.e-input.e-small#{$css},
  .e-input-group.e-small input,
  .e-input-group.e-small input.e-input,
  .e-small .e-input-group input,
  .e-small .e-input-group input.e-input,
  .e-input-group.e-control-wrapper.e-small input,
  .e-input-group.e-control-wrapper.e-small input.e-input,
  .e-small .e-input-group.e-control-wrapper input,
  .e-small .e-input-group.e-control-wrapper input.e-input,
  .e-float-input.e-small input,
  .e-float-input.e-small input.e-input,
  .e-small .e-float-input input,
  .e-small .e-float-input input.e-input,
  .e-float-input.e-control-wrapper.e-small input,
  .e-float-input.e-control-wrapper.e-small input.e-input,
  .e-small .e-float-input.e-control-wrapper input,
  .e-small .e-float-input.e-control-wrapper input.e-input {
    @include input-sizing;
    @include input-height ($input-small-height);
  }

  .e-float-input.e-small:not(.e-input-group) input,
  .e-float-input.e-small:not(.e-input-group) input.e-input,
  .e-small .e-float-input:not(.e-input-group) input,
  .e-small .e-float-input:not(.e-input-group) input.e-input .e-float-input.e-control-wrapper.e-small:not(.e-input-group) input,
  .e-float-input.e-control-wrapper.e-small:not(.e-input-group) input.e-input,
  .e-small .e-float-input.e-control-wrapper:not(.e-input-group) input,
  .e-small .e-float-input.e-control-wrapper:not(.e-input-group) input.e-input  {
    @include input-sizing;
    @include input-height ($float-input-small-height);
  }

  textarea.e-input#{$css},
  .e-input-group textarea,
  .e-input-group.e-control-wrapper textarea,
  .e-float-input textarea,
  .e-float-input.e-control-wrapper textarea {
    @include input-sizing;
    @include input-height ($textarea-normal-height);
  }

  textarea.e-input.e-small#{$css},
  .e-input-group.e-small textarea,
  .e-input-group.e-small textarea.e-input,
  .e-small .e-input-group textarea,
  .e-small .e-input-group textarea.e-input,
  .e-input-group.e-control-wrapper.e-small textarea,
  .e-input-group.e-control-wrapper.e-small textarea.e-input,
  .e-small .e-input-group.e-control-wrapper textarea,
  .e-small .e-input-group.e-control-wrapper textarea.e-input,
  .e-float-input.e-small textarea,
  .e-float-input.e-small textarea.e-input,
  .e-small .e-float-input textarea,
  .e-small .e-float-input textarea.e-input,
  .e-float-input.e-control-wrapper.e-small textarea,
  .e-float-input.e-control-wrapper.e-small textarea.e-input,
  .e-small .e-float-input.e-control-wrapper textarea,
  .e-small .e-float-input.e-control-wrapper textarea.e-input {
    @include input-sizing;
    @include input-height ($textarea-small-height);
  }

  input.e-input#{$css}.e-small,
  .e-input-group input.e-input.e-small,
  .e-input-group.e-control-wrapper input.e-input.e-small,
  .e-input-group.e-small .e-input,
  .e-input-group.e-control-wrapper.e-small .e-input,
  .e-small input.e-input#{$css},
  .e-small .e-input-group .e-input,
  .e-small .e-input-group.e-control-wrapper .e-input,
  .e-float-input.e-small input,
  .e-float-input input.e-small,
  .e-small .e-float-input input,
  .e-float-input.e-control-wrapper.e-small input,
  .e-float-input.e-control-wrapper input.e-small,
  .e-small .e-float-input.e-control-wrapper input,
  textarea.e-input#{$css}.e-small,
  .e-input-group textarea.e-input.e-small,
  .e-input-group.e-control-wrapper input.e-input-group textarea.e-input.e-small,
  .e-small input.e-input#{$css},
  .e-float-input.e-small textarea,
  .e-float-input textarea.e-small,
  .e-small .e-float-input textarea,
  .e-float-input.e-control-wrapper.e-small textarea,
  .e-float-input.e-control-wrapper textarea.e-small,
  .e-small .e-float-input.e-control-wrapper textarea {
    text-indent: $input-small-text-indent;
  }

  input.e-input#{$css},
  .e-input-group input.e-input,
  .e-input-group input,
  .e-input-group.e-control-wrapper input.e-input,
  .e-input-group.e-control-wrapper input,
  .e-float-input input.e-input,
  .e-float-input input,
  .e-float-input.e-control-wrapper input.e-input,
  .e-float-input.e-control-wrapper input,
  .e-input-group input.e-input:focus,
  .e-input-group.e-control-wrapper input.e-input:focus,
  .e-float-input.e-control-wrapper input:focus,
  .e-float-input input:focus,
  .e-input-group.e-input-focus input.e-input,
  .e-input-group.e-control-wrapper.e-input-focus input.e-input,
  .e-float-input.e-control-wrapper.e-input-focus input,
  .e-float-input.e-input-focus input {
    padding-left: $input-text-indent;
    text-indent: 0;
  }

  textarea.e-input#{$css},
  .e-input-group textarea.e-input,
  .e-input-group textarea,
  .e-input-group.e-control-wrapper textarea.e-input,
  .e-input-group.e-control-wrapper textarea,
  .e-float-input textarea.e-input,
  .e-float-input textarea,
  .e-float-input.e-control-wrapper textarea.e-input,
  .e-float-input.e-control-wrapper textarea,
  .e-input-group textarea.e-input:focus,
  .e-input-group.e-control-wrapper textarea.e-input:focus,
  .e-float-input.e-control-wrapper textarea:focus,
  .e-float-input textarea:focus {
    @if $input-skin-name == 'fluent2' {
      padding-left: 12px;
    }
    @else {
      padding-left: $input-text-indent;
    }
    text-indent: 0;
  }

  input.e-input.e-rtl#{$css},
  .e-input-group.e-rtl input.e-input,
  .e-input-group.e-control-container.e-rtl input.e-control,
  .e-input-group.e-control-wrapper.e-rtl input.e-input,
  .e-float-input.e-rtl input,
  .e-float-input.e-control-wrapper.e-rtl input,
  .e-rtl .e-input-group input.e-input,
  .e-rtl .e-input-group.e-control-wrapper input.e-input,
  .e-rtl .e-float-input input,
  .e-rtl .e-float-input.e-control-wrapper input,
  .e-input-group.e-rtl input.e-input,
  .e-input-group.e-control-wrapper.e-rtl input.e-input,
  .e-float-input.e-rtl input,
  .e-float-input.e-control-wrapper.e-rtl input,
  .e-rtl .e-input-group input.e-input,
  .e-rtl .e-input-group.e-control-wrapper input.e-input,
  .e-rtl .e-float-input input,
  .e-rtl .e-float-input.e-control-wrapper input,
  .e-input-group.e-rtl input.e-input:focus,
  .e-input-group.e-control-wrapper.e-rtl input.e-input:focus,
  .e-float-input.e-rtl input:focus,
  .e-float-input.e-control-wrapper.e-rtl input:focus,
  .e-rtl .e-input-group input.e-input:focus,
  .e-rtl .e-input-group.e-control-wrapper input.e-input:focus,
  .e-rtl .e-float-input input:focus,
  .e-rtl .e-float-input.e-control-wrapper input:focus,
  .e-input-group.e-rtl.e-input-focus input.e-input,
  .e-input-group.e-control-wrapper.e-rtl.e-input-focus input.e-input,
  .e-rtl .e-input-group.e-input-focus input.e-input,
  .e-rtl .e-input-group.e-control-wrapper.e-input-focus input.e-input,
  .e-float-input.e-rtl.e-input-focus input,
  .e-float-input.e-control-wrapper.e-rtl.e-input-focus input,
  .e-rtl .e-float-input.e-input-focus input,
  .e-rtl .e-float-input.e-control-wrapper.e-input-focus input {
    padding-left: 0;
    padding-right: $input-text-indent;
    text-indent: 0;
  }

  textarea.e-input.e-rtl#{$css},
  .e-input-group:not(.e-outline).e-rtl textarea.e-input,
  .e-input-group:not(.e-outline).e-control-wrapper.e-rtl textarea.e-input,
  .e-float-input:not(.e-outline).e-rtl textarea,
  .e-float-input:not(.e-outline).e-control-wrapper.e-rtl textarea,
  .e-rtl .e-input-group:not(.e-outline) textarea.e-input,
  .e-rtl .e-input-group:not(.e-outline).e-control-wrapper textarea.e-input,
  .e-rtl .e-float-input:not(.e-outline) textarea,
  .e-rtl .e-float-input:not(.e-outline).e-control-wrapper textarea,
  .e-input-group:not(.e-outline).e-rtl textarea.e-input,
  .e-input-group:not(.e-outline).e-control-wrapper.e-rtl textarea.e-input,
  .e-float-input:not(.e-outline).e-rtl textarea,
  .e-float-input:not(.e-outline).e-control-wrapper.e-rtl textarea,
  .e-rtl .e-input-group:not(.e-outline) textarea.e-input,
  .e-rtl .e-input-group:not(.e-outline).e-control-wrapper textarea.e-input,
  .e-rtl .e-float-input:not(.e-outline) textarea,
  .e-rtl .e-float-input:not(.e-outline).e-control-wrapper textarea,
  .e-input-group:not(.e-outline).e-rtl textarea.e-input:focus,
  .e-input-group:not(.e-outline).e-control-wrapper.e-rtl textarea.e-input:focus,
  .e-float-input:not(.e-outline).e-rtl textarea:focus,
  .e-float-input:not(.e-outline).e-control-wrapper.e-rtl textarea:focus,
  .e-rtl .e-input-group:not(.e-outline) textarea.e-input:focus,
  .e-rtl .e-input-group:not(.e-outline).e-control-wrapper textarea.e-input:focus,
  .e-rtl .e-float-input:not(.e-outline) textarea:focus,
  .e-rtl .e-float-input:not(.e-outline).e-control-wrapper textarea:focus {
    padding-right: $input-text-indent;
    text-indent: 0;
  }

  input.e-input.e-small#{$css},
  .e-small input.e-input#{$css},
  .e-input-group.e-small input.e-input,
  .e-input-group.e-control-wrapper.e-small input.e-input,
  .e-float-input.e-small input,
  .e-float-input.e-control-wrapper input.e-small,
  .e-float-input.e-small input,
  .e-float-input.e-control-wrapper input.e-small,
  .e-input-group input.e-input.e-small,
  .e-input-group.e-control-wrapper input.e-input.e-small,
  .e-small .e-float-input input,
  .e-small .e-float-input.e-control-wrapper input,
  .e-small .e-input-group input.e-input,
  .e-small .e-input-group.e-control-wrapper input.e-input,
  .e-input-group.e-small input.e-input:focus,
  .e-input-group.e-control-wrapper.e-small input.e-input:focus,
  .e-float-input.e-small input:focus,
  .e-float-input.e-control-wrapper.e-small input:focus,
  .e-small .e-input-group.e-control-wrapper input.e-input:focus,
  .e-small .e-input-group input.e-input:focus,
  .e-small .e-float-input input:focus,
  .e-small .e-float-input.e-control-wrapper input:focus,
  .e-input-group.e-small.e-input-focus input.e-input,
  .e-input-group.e-control-wrapper.e-small.e-input-focus input.e-input,
  .e-small .e-input-group.e-control-wrapper.e-input-focus input.e-input,
  .e-small .e-input-group.e-input-focus input.e-input,
  .e-float-input.e-small.e-input-focus input,
  .e-float-input.e-control-wrapper.e-input-focus.e-small input,
  .e-small .e-float-input.e-input-focus input,
  .e-small .e-float-input.e-control-wrapper.e-input-focus input,
  textarea.e-input.e-small#{$css},
  .e-small textarea.e-input#{$css},
  .e-input-group.e-small textarea.e-input,
  .e-input-group.e-control-wrapper.e-small textarea.e-input,
  .e-float-input.e-control-wrapper.e-small textarea,
  .e-float-input.e-control-wrapper textarea.e-small,
  .e-float-input.e-small textarea,
  .e-float-input textarea.e-small,
  .e-input-group textarea.e-input.e-small,
  .e-input-group.e-control-wrapper textarea.e-input.e-small,
  .e-small .e-float-input.e-control-wrapper textarea,
  .e-small .e-float-input textarea,
  .e-small .e-input-group textarea.e-input,
  .e-small .e-input-group.e-control-wrapper textarea.e-input,
  .e-input-group.e-small textarea.e-input:focus,
  .e-input-group.e-control-wrapper.e-small textarea.e-input:focus,
  .e-float-input.e-small textarea:focus,
  .e-float-input.e-control-wrapper.e-small textarea:focus,
  .e-small .e-input-group textarea.e-input:focus,
  .e-small .e-input-group.e-control-wrapper textarea.e-input:focus,
  .e-small .e-float-input.e-control-wrapper textarea:focus,
  .e-small .e-float-input textarea:focus {
    padding-left: $input-small-text-indent;
    text-indent: 0;
  }

  .e-rtl input.e-input.e-small#{$css},
  input.e-input#{$css}.e-small.e-rtl,
  .e-small.e-rtl input.e-input#{$css},
  .e-small input.e-input.e-rtl#{$css},
  .e-float-input.e-control-wrapper.e-small.e-rtl input,
  .e-float-input.e-small.e-rtl input,
  .e-input-group.e-small.e-rtl input.e-input,
  .e-input-group.e-control-wrapper.e-small.e-rtl input.e-input,
  .e-rtl .e-float-input.e-small input,
  .e-rtl .e-float-input.e-control-wrapper.e-small input,
  .e-rtl .e-input-group.e-small input.e-input,
  .e-rtl .e-input-group.e-control-wrapper.e-small input.e-input,
  .e-float-input.e-rtl input.e-small,
  .e-float-input.e-control-wrapper.e-rtl input.e-small,
  .e-input-group.e-rtl input.e-input.e-small,
  .e-input-group.e-control-wrapper.e-rtl input.e-input.e-small,
  .e-rtl .e-float-input input.e-small,
  .e-rtl .e-float-input.e-control-wrapper input.e-small,
  .e-rtl .e-input-group input.e-input.e-small,
  .e-rtl .e-input-group.e-control-wrapper input.e-input.e-small,
  .e-small .e-float-input.e-rtl input,
  .e-small .e-float-input.e-control-wrapper.e-rtl input,
  .e-small .e-input-group.e-rtl input.e-input,
  .e-small .e-input-group.e-control-wrapper.e-rtl input.e-input,
  .e-small.e-rtl .e-float-input.e-control-wrapper input,
  .e-small.e-rtl .e-float-input input,
  .e-small.e-rtl .e-input-group.e-control-wrapper input.e-input,
  .e-small.e-rtl .e-input-group input.e-input,
  .e-small.e-rtl .e-input-group.e-control-wrapper input.e-input:focus,
  .e-small.e-rtl .e-input-group input.e-input:focus,
  .e-small.e-rtl .e-float-input.e-control-wrapper input:focus,
  .e-small.e-rtl .e-float-input input:focus,
  .e-small .e-input-group.e-control-wrapper.e-rtl input.e-input:focus,
  .e-small .e-input-group.e-rtl input.e-input:focus,
  .e-small .e-float-input.e-control-wrapper.e-rtl input:focus,
  .e-small .e-float-input.e-rtl input:focus,
  .e-small.e-rtl .e-input-group.e-control-wrapper.e-input-focus input.e-input,
  .e-small.e-rtl .e-input-group.e-input-focus input.e-input,
  .e-small .e-input-group.e-control-wrapper.e-rtl.e-input-focus input.e-input,
  .e-small .e-input-group.e-rtl.e-input-focus input.e-input,
  .e-small.e-rtl .e-float-input.e-control-wrapper.e-input-focus input,
  .e-small.e-rtl .e-float-input.e-input-focus input,
  .e-small .e-float-input.e-control-wrapper.e-rtl.e-input-focus input,
  .e-small .e-float-input.e-rtl.e-input-focus input {
    padding-left: 0;
    padding-right: $input-small-text-indent;
    text-indent: 0;
  }

  .e-rtl textarea.e-input.e-small#{$css},
  textarea.e-input.e-small.e-rtl#{$css},
  .e-small.e-rtl textarea.e-input#{$css},
  .e-small textarea.e-input.e-rtl#{$css},
  .e-float-input:not(.e-outline).e-small.e-rtl textarea,
  .e-float-input:not(.e-outline).e-control-wrapper.e-small.e-rtl textarea,
  .e-input-group:not(.e-outline).e-small.e-rtl textarea.e-input,
  .e-input-group:not(.e-outline).e-control-wrapper.e-small.e-rtl textarea.e-input,
  .e-rtl .e-float-input:not(.e-outline).e-control-wrapper.e-small textarea,
  .e-rtl .e-float-input:not(.e-outline).e-small textarea,
  .e-rtl .e-input-group:not(.e-outline).e-small textarea.e-input,
  .e-rtl .e-input-group:not(.e-outline).e-control-wrapper.e-small textarea.e-input,
  .e-float-input:not(.e-outline).e-control-wrapper.e-rtl textarea.e-small,
  .e-float-input:not(.e-outline).e-rtl textarea.e-small,
  .e-input-group:not(.e-outline).e-rtl textarea.e-input.e-small,
  .e-input-group:not(.e-outline).e-control-wrapper.e-rtl textarea.e-input.e-small,
  .e-rtl .e-float-input:not(.e-outline).e-control-wrapper textarea.e-small,
  .e-rtl .e-float-input:not(.e-outline) textarea.e-small,
  .e-rtl .e-input-group:not(.e-outline) textarea.e-input.e-small,
  .e-rtl .e-input-group:not(.e-outline).e-control-wrapper textarea.e-input.e-small,
  .e-small .e-float-input:not(.e-outline).e-control-wrapper.e-rtl textarea,
  .e-small .e-float-input:not(.e-outline).e-rtl textarea,
  .e-small .e-input-group:not(.e-outline).e-rtl textarea.e-input,
  .e-small .e-input-group:not(.e-outline).e-control-wrapper.e-rtl textarea.e-input,
  .e-small.e-rtl .e-float-input:not(.e-outline).e-control-wrapper textarea,
  .e-small.e-rtl .e-float-input:not(.e-outline) textarea,
  .e-small.e-rtl .e-input-group:not(.e-outline) textarea.e-input,
  .e-small.e-rtl .e-input-group:not(.e-outline).e-control-wrapper textarea.e-input,
  .e-small.e-rtl .e-input-group:not(.e-outline) textarea.e-input:focus,
  .e-small.e-rtl .e-input-group:not(.e-outline).e-control-wrapper textarea.e-input:focus,
  .e-small.e-rtl .e-float-input:not(.e-outline).e-control-wrapper textarea:focus,
  .e-small.e-rtl .e-float-input:not(.e-outline) textarea:focus,
  .e-small .e-input-group:not(.e-outline).e-rtl textarea.e-input:focus,
  .e-small .e-input-group:not(.e-outline).e-control-wrapper.e-rtl textarea.e-input:focus,
  .e-small .e-float-input:not(.e-outline).e-control-wrapper.e-rtl textarea:focus,
  .e-small .e-float-input:not(.e-outline).e-rtl textarea:focus {
    padding-right: $input-small-text-indent;
    text-indent: 0;
  }
  // Clear-icon support
  .e-float-input .e-clear-icon,
  .e-float-input.e-control-wrapper .e-clear-icon,
  .e-input-group .e-clear-icon,
  .e-input-group.e-control-wrapper .e-clear-icon {
    background: transparent;
    border: $zero-value;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: $input-clear-icon-position;
    line-height: 1;
    min-width: 24px;
    outline: none;
    padding: $input-clear-icon-padding;
    text-align: center;
  }

  .e-float-input .e-clear-icon::before,
  .e-float-input.e-control-wrapper .e-clear-icon::before,
  .e-input-group .e-clear-icon::before,
  .e-input-group.e-control-wrapper .e-clear-icon::before {
    font-size: $input-clear-icon;
    padding: $zero-value;
    text-align: center;
    vertical-align: middle;
  }

  .e-float-input.e-static-clear .e-clear-icon.e-clear-icon-hide,
  .e-float-input.e-control-wrapper.e-static-clear .e-clear-icon.e-clear-icon-hide,
  .e-input-group.e-static-clear .e-clear-icon.e-clear-icon-hide,
  .e-input-group.e-control-wrapper.e-static-clear .e-clear-icon.e-clear-icon-hide {
    cursor: pointer;
    display: flex;
  }

  .e-float-input.e-small .e-clear-icon::before,
  .e-float-input.e-control-wrapper.e-small .e-clear-icon::before,
  .e-input-group.e-small .e-clear-icon::before,
  .e-input-group.e-control-wrapper.e-small .e-clear-icon::before,
  .e-float-input.e-control-wrapper input.e-small:first-child ~ .e-clear-icon::before,
  .e-small .e-float-input.e-control-wrapper .e-clear-icon::before,
  .e-float-input input.e-small:first-child ~ .e-clear-icon::before,
  .e-small .e-float-input .e-clear-icon::before,
  .e-small .e-input-group .e-clear-icon::before,
  .e-small .e-input-group.e-control-wrapper .e-clear-icon::before {
    font-size: $input-small-clear-icon;
  }

  .e-input-group.e-static-clear .e-clear-icon.e-clear-icon-hide,
  .e-input-group.e-control-wrapper.e-static-clear .e-clear-icon.e-clear-icon-hide {
    cursor: pointer;
  }

  .e-float-input.e-control-wrapper input[readonly]:first-child ~ .e-clear-icon,
  .e-float-input.e-control-wrapper.e-input-group input[readonly]:first-child ~ .e-clear-icon,
  .e-float-input input[readonly]:first-child ~ .e-clear-icon,
  .e-float-input.e-input-group input[readonly]:first-child ~ .e-clear-icon,
  .e-input-group input[readonly]:first-child ~ .e-clear-icon.e-clear-icon-hide,
  .e-float-input.e-control-wrapper.e-input-group input[readonly]:first-child ~ .e-clear-icon,
  .e-float-input.e-input-group.e-control-wrapper input[readonly]:first-child ~ .e-clear-icon,
  .e-input-group.e-control-wrapper input[readonly]:first-child .e-clear-icon.e-clear-icon-hide  {
    cursor: auto;
  }

  .e-input-group .e-clear-icon,
  .e-input-group.e-control-wrapper .e-clear-icon {
    min-height: $input-clear-icon-min-height;
    min-width: $input-clear-icon-min-width;
    @if $input-skin-name != 'Material3' and $input-skin-name != 'fluent2' {
      padding-bottom: $input-clear-icon-padding-bottom;
      padding-left: $input-clear-icon-padding-left;
      padding-right: $input-clear-icon-padding-right;
      padding-top: $input-clear-icon-padding-top;
    }
    @else {
      padding: $input-clear-icon-padding;
      margin: $input-clear-icon-margin;
      border-radius: $input-clear-icon-hover-border-radius;
    }
  }

  .e-float-input.e-input-group .e-clear-icon,
  .e-float-input.e-input-group.e-control-wrapper .e-clear-icon {
    @if $input-skin-name != 'Material3' and $input-skin-name != 'fluent2' {
      padding-right: $input-clear-icon-padding-right;
      padding-top: $float-input-clear-icon-padding-top;
    }
  }

  .e-input-group.e-small .e-clear-icon,
  .e-input-group .e-clear-icon.e-small,
  .e-input-group.e-control-wrapper.e-small .e-clear-icon,
  .e-input-group.e-control-wrapper .e-clear-icon.e-small {
    @if $input-skin-name != 'Material3' and $input-skin-name != 'fluent2' {
      min-height: $input-bigger-clear-icon-min-height;
      min-width: $input-bigger-clear-icon-min-width;
      padding-bottom: $input-clear-icon-bigger-padding-bottom;
      padding-right: $input-clear-icon-bigger-padding-right;
      padding-top: $input-clear-icon-bigger-padding-top;
    }
    @else {
      min-height: $input-bigger-small-clear-icon-min-height;
      min-width: $input-bigger-small-clear-icon-min-width;
      padding: $input-clear-icon-padding;
      margin: $input-clear-icon-margin;
      border-radius: $input-bigger-clear-icon-border-radius;
    }
  }

  .e-input-group.e-small .e-clear-icon,
  .e-input-group .e-clear-icon.e-small,
  .e-small .e-input-group .e-clear-icon,
  .e-input-group.e-control-wrapper.e-small .e-clear-icon,
  .e-input-group.e-control-wrapper .e-clear-icon.e-small,
  .e-small .e-input-group.e-control-wrapper .e-clear-icon {
    min-height: $input-small-clear-icon-min-height;
    min-width: $input-small-clear-icon-min-width;
    @if $input-skin-name != 'Material3' and $input-skin-name != 'fluent2' {
      padding-bottom: $input-clear-icon-small-padding-bottom;
      padding-right: $input-clear-icon-small-padding-right;
      padding-top: $input-clear-icon-small-padding-top;
    }
    @else {
      padding: $input-clear-icon-padding;
      margin: $input-clear-icon-margin;
      border-radius: $input-small-clear-icon-border-radius;
    }
  }

  .e-input-group.e-float-input.e-small .e-clear-icon,
  .e-input-group.e-float-input .e-clear-icon.e-small,
  .e-small .e-input-group.e-float-input .e-clear-icon,
  .e-input-group.e-control-wrapper.e-float-input.e-small .e-clear-icon,
  .e-input-group.e-control-wrapper.e-float-input .e-clear-icon.e-small,
  .e-small .e-input-group.e-control-wrapper.e-float-input .e-clear-icon,
  .e-input-group.e-float-input.e-control-wrapper.e-small .e-clear-icon,
  .e-input-group.e-float-input.e-control-wrapper .e-clear-icon.e-small,
  .e-small .e-input-group.e-float-input.e-control-wrapper .e-clear-icon,
  .e-input-group.e-control-wrapper.e-float-input.e-small .e-clear-icon,
  .e-input-group.e-control-wrapper.e-float-input .e-clear-icon.e-small,
  .e-small .e-input-group.e-control-wrapper.e-float-input .e-clear-icon {
    @if $input-skin-name != 'Material3' and $input-skin-name != 'fluent2' {
      padding-right: $input-clear-icon-small-padding-right;
      padding-top: $float-input-clear-icon-small-padding-top;
    }
  }

  .e-input#{$css}:not(:valid),
  .e-input#{$css}:valid,
  .e-float-input.e-control-wrapper input:not(:valid),
  .e-float-input.e-control-wrapper input:valid,
  .e-float-input input:not(:valid),
  .e-float-input input:valid,
  .e-input-group input:not(:valid),
  .e-input-group input:valid,
  .e-input-group.e-control-wrapper input:not(:valid),
  .e-input-group.e-control-wrapper input:valid,
  .e-float-input.e-control-wrapper textarea:not(:valid),
  .e-float-input.e-control-wrapper textarea:valid,
  .e-float-input textarea:not(:valid),
  .e-float-input textarea:valid,
  .e-input-group.e-control-wrapper textarea:not(:valid),
  .e-input-group.e-control-wrapper textarea:valid,
  .e-input-group textarea:not(:valid),
  .e-input-group textarea:valid {
    box-shadow: none;
  }

  .e-input#{$css}:not(:valid),
  .e-input#{$css}:valid,
  .e-float-input input:valid,
  .e-input-group input:valid {
    @if $input-skin-name == 'fluent2' {
      border-bottom-color: $input-box-border-color;
      border-top-color: $border;
      border-left-color: $border;
      border-right-color: $border;
      border-radius: 4px;
      height: 32px;
    }
  }

  .e-input-group .e-input-in-wrap,
  .e-input-group.e-control-wrapper .e-input-in-wrap,
  .e-float-input .e-input-in-wrap,
  .e-float-input.e-control-wrapper .e-input-in-wrap {
    width: 100%;
    @if $input-skin-name == 'fabric' or $input-skin-name == 'FluentUI' or $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' or $input-skin-name == 'highcontrast' or $input-skin-name == 'tailwind' {
      display: flex;
      position: relative;
    }
  }

  .e-float-input .e-input-in-wrap label.e-float-text,
  .e-float-input.e-control-wrapper .e-input-in-wrap label.e-float-text {
    right: 0;
    @if $input-skin-name == 'fabric' or $input-skin-name == 'FluentUI' or $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' or $input-skin-name == 'highcontrast' or $input-skin-name == 'tailwind' {
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: middle;
      white-space: nowrap;
    }
  }

  .e-input-group .e-input-in-wrap,
  .e-input-group.e-control-wrapper .e-input-in-wrap,
  .e-float-input .e-input-in-wrap,
  .e-float-input.e-control-wrapper .e-input-in-wrap {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      display: flex;
      position: relative;
      width: 100%;
    }
  }

  .e-float-input.e-float-icon-left .e-input-in-wrap,
  .e-float-input.e-control-wrapper.e-float-icon-left .e-input-in-wrap,
  .e-input-group.e-float-icon-left .e-input-in-wrap,
  .e-input-group.e-control-wrapper.e-float-icon-left .e-input-in-wrap {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border: $input-group-full-border;
      border-width: $input-group-full-border-width;
      margin-left: $input-inner-wrap-margin-left;
    }
    @if $input-skin-name == 'fluent2' {
      border-width: $input-group-full-border-width;
      margin-left: $input-inner-wrap-margin-left;
    }
  }

  .e-rtl .e-float-input.e-float-icon-left .e-input-in-wrap,
  .e-rtl .e-float-input.e-control-wrapper.e-float-icon-left .e-input-in-wrap,
  .e-rtl .e-input-group.e-float-icon-left .e-input-in-wrap,
  .e-rtl .e-input-group.e-control-wrapper.e-float-icon-left .e-input-in-wrap .e-float-input.e-control-wrapper.e-float-icon-left.e-rtl .e-input-in-wrap,
  .e-float-input.e-float-icon-left.e-rtl .e-input-in-wrap,
  .e-input-group.e-float-icon-left.e-rtl .e-input-in-wrap,
  .e-input-group.e-control-wrapper.e-float-icon-left.e-rtl .e-input-in-wrap {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      margin-left: $zero-value;
      margin-right: $input-inner-wrap-margin-left;
    }
  }

  .e-float-input label.e-float-text.e-label-bottom,
  .e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      transform: translate3d(0, 16px, 0) scale(1);
    }
    @if $input-skin-name == 'fluent2' {
      transform: translate3d(0, 16px, 0) scale(1);
      padding-left: 7px;
    }
  }

  .e-float-input textarea:focus ~ label.e-float-text,
  .e-float-input textarea:valid ~ label.e-float-text,
  .e-float-input textarea ~ label.e-label-top.e-float-text,
  .e-float-input textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input textarea label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper textarea:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper textarea:valid ~ label.e-float-text,
  .e-float-input.e-control-wrapper textarea ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper textarea label.e-float-text.e-label-top {
    font-size: $float-label-font-size;
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      top: $textarea-float-top;
      transform: translate3d(0, 6px, 0) scale(.92);
    }
    @else if $input-skin-name == 'fabric' or  $input-skin-name == 'highcontrast' {
      font-style: $input-font-normal;
      top: 15px;
      transform: translate3d(-10px, -40px, 0) scale(1);
    }
    @else if $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' {
      padding-right: 0;
      transform: translate3d(-10px, -37px, 0) scale(1);
    }
    @else if $input-skin-name == 'tailwind' {
      font-weight: 500;
      padding-right: 0;
      transform: translate3d(-10px, -33px, 0) scale(1);
    }
    @else if ($input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3') {
      font-weight: 500;
      padding-right: 0;
      transform: translate3d(-10px, -36px, 0) scale(1);
    }
    @else if ($input-skin-name == 'FluentUI') {
      font-style: $input-font-normal;
      font-weight: 600;
      padding-right: 0;
      transform: translate3d(-10px, -41px, 0) scale(1);
    }
  }

  .e-float-input.e-small textarea:focus ~ label.e-float-text,
  .e-float-input.e-small textarea:valid ~ label.e-float-text,
  .e-float-input.e-small textarea ~ label.e-label-top.e-float-text,
  .e-small .e-float-input textarea ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-small textarea:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small textarea:valid ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small textarea ~ label.e-label-top.e-float-text,
  .e-small .e-float-input.e-control-wrapper textarea ~ label.e-label-top.e-float-text {
    font-size: $float-label-small-font-size;
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      top: -21px;
    }
    @else if $input-skin-name == 'fabric' or $input-skin-name == 'FluentUI' or $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' or $input-skin-name == 'highcontrast' or $input-skin-name == 'tailwind' {
      top: 16px;
    }
  }

  .e-float-input textarea ~ .e-float-text,
  .e-float-input.e-control-wrapper textarea ~ .e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      top: -11px;
    }
    @else if $input-skin-name == 'fabric' or $input-skin-name == 'FluentUI' or $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' or $input-skin-name == 'highcontrast' {
      top: 15px;
    }
    @else if $input-skin-name == 'tailwind' {
      top: 13px;
    }
  }

  .e-float-input.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    font-size: $float-placeholder-small-font-size;
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      top: -11px;
    }
  }

  .e-float-input.e-small textarea ~ label.e-float-text,
  .e-float-input textarea ~ label.e-float-text.e-small,
  .e-float-input textarea.e-small ~ label.e-float-text,
  .e-small .e-float-input textarea ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small textarea ~ label.e-float-text,
  .e-float-input.e-control-wrapper textarea ~ label.e-float-text.e-small,
  .e-float-input.e-control-wrapper textarea.e-small ~ label.e-float-text,
  .e-small .e-float-input.e-control-wrapper textarea ~ label.e-float-text {
    font-size: $float-placeholder-small-font-size;
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      top: -11px;
    }
    @else if $input-skin-name == 'fabric' or $input-skin-name == 'FluentUI' or $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' or $input-skin-name == 'highcontrast' or $input-skin-name == 'tailwind' {
      top: 15px;
    }
  }

  .e-input-group.e-small:not(.e-float-input) .e-input,
  .e-small .e-input-group:not(.e-float-input) .e-input,
  .e-input-group.e-control-wrapper.e-small:not(.e-float-input) .e-input,
  .e-small .e-input-group.e-control-wrapper:not(.e-float-input) .e-input,
  .e-float-input.e-small input,
  .e-small .e-float-input input,
  .e-float-input.e-input-group.e-small input,
  .e-small .e-float-input.e-input-group input,
  .e-float-input.e-input-group.e-control-wrapper.e-small input,
  .e-small .e-float-input.e-input-group.e-control-wrapper input,
  .e-float-input.e-control-wrapper.e-small input,
  .e-small .e-float-input.e-control-wrapper input,
  .e-float-input.e-control-wrapper.e-input-group.e-small input,
  .e-small .e-float-input.e-control-wrapper.e-input-group input,
  .e-float-input.e-control-wrapper.e-input-group.e-small input,
  .e-small .e-float-input.e-control-wrapper.e-input-group input {
    @if $input-skin-name == 'material' {
      min-height: 16px;
    }
    @if $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      min-height: 14px;
    }
  }

  .e-input-group input.e-input,
  .e-float-input.e-input-group input,
  .e-input-group.e-control-wrapper input.e-input,
  .e-float-input.e-input-group.e-control-wrapper input,
  .e-float-input input,
  .e-float-input.e-control-wrapper input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      min-height: 22px;
    }
  }

  .e-input-group:hover:not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
  .e-input-group.e-control-wrapper:hover:not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
  .e-float-input:hover:not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
  .e-float-input:hover:not(.e-input-group):not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error) input:not([disabled]),
  .e-float-input:hover:not(.e-input-group):not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error) textarea:not([disabled]),
  .e-float-input:hover:not(.e-input-group):not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error) input:not([disabled]),
  .e-float-input:hover:not(.e-input-group):not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error) textarea:not([disabled]),
  .e-float-input.e-control-wrapper:hover:not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
  .e-float-input.e-control-wrapper:hover:not(.e-input-group):not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error) input:not([disabled]),
  .e-float-input.e-control-wrapper:hover:not(.e-input-group):not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error) textarea:not([disabled]),
  .e-float-input.e-control-wrapper:hover:not(.e-input-group):not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error) input:not([disabled]),
  .e-float-input.e-control-wrapper:hover:not(.e-input-group):not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error) textarea:not([disabled]) {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-bottom-width: $input-group-border-width-hover;
    }
    @else if $input-skin-name == 'fluent2' {
      border-bottom-width: $input-group-border-width-hover;
      border-color: $input-group-border-color-hover;
    }
  }

  .e-input-group:hover:not(.e-disabled),
  .e-input-group.e-control-wrapper:hover:not(.e-disabled),
  .e-float-input:hover:not(.e-disabled),
  .e-float-input:hover:not(.e-input-group):not(.e-disabled) input:not([disabled]),
  .e-float-input:hover:not(.e-input-group):not(.e-disabled) textarea:not([disabled]),
  .e-float-input:hover:not(.e-input-group):not(.e-disabled) input:not([disabled]),
  .e-float-input:hover:not(.e-input-group):not(.e-disabled) textarea:not([disabled]),
  .e-float-input.e-control-wrapper:hover:not(.e-disabled),
  .e-float-input.e-control-wrapper:hover:not(.e-input-group):not(.e-disabled) input:not([disabled]),
  .e-float-input.e-control-wrapper:hover:not(.e-input-group):not(.e-disabled) textarea:not([disabled]),
  .e-float-input.e-control-wrapper:hover:not(.e-input-group):not(.e-disabled) input:not([disabled]),
  .e-float-input.e-control-wrapper:hover:not(.e-input-group):not(.e-disabled) textarea:not([disabled]) {
    @if $input-skin-name != 'material' and $input-skin-name != 'Material3' {
      border-bottom-width: $input-group-border-width-hover;
    }
  }

  .e-input-group.e-float-icon-left:not(.e-disabled):not(.e-input-focus) .e-input-in-wrap:hover,
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-disabled):not(.e-input-focus) .e-input-in-wrap:hover,
  .e-float-input.e-float-icon-left:not(.e-disabled):not(.e-input-focus) .e-input-in-wrap:hover,
  .e-float-input.e-control-wrapper.e-float-icon-left:not(.e-disabled):not(.e-input-focus) .e-input-in-wrap:hover {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      border-bottom-width: $input-group-border-width-hover;
    }
  }

  .e-input-group:not(.e-disabled):not(.e-float-icon-left)::before,
  .e-input-group:not(.e-disabled):not(.e-float-icon-left)::after,
  .e-input-group.e-float-icon-left:not(.e-disabled) .e-input-in-wrap::before,
  .e-input-group.e-float-icon-left:not(.e-disabled) .e-input-in-wrap::after,
  .e-input-group.e-control-wrapper:not(.e-disabled):not(.e-float-icon-left)::before,
  .e-input-group.e-control-wrapper:not(.e-disabled):not(.e-float-icon-left)::after,
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-disabled) .e-input-in-wrap::before,
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-disabled) .e-input-in-wrap::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      bottom: -2px;
    }
    @else if $input-skin-name == 'fluent2' {
      bottom: -.3px;
    }
  }

  .e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
  .e-input-group.e-control-wrapper.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
  .e-float-input.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
  .e-float-input.e-input-group.e-input-focus:not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error),
  .e-float-input.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success).e-warning:not(.e-error),
  .e-float-input.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning).e-error,
  .e-float-input.e-control-wrapper.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
  .e-float-input.e-control-wrapper.e-input-group.e-input-focus:not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error),
  .e-float-input.e-control-wrapper.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success).e-warning:not(.e-error),
  .e-float-input.e-control-wrapper.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning).e-error,
  .e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error) input,
  .e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled).e-success:not(.e-warning):not(.e-error) input,
  .e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success).e-warning:not(.e-error) input,
  .e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning).e-error input,
  .e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error) textarea,
  .e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled).e-success:not(.e-warning):not(.e-error) textarea,
  .e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success).e-warning:not(.e-error) textarea,
  .e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning).e-error textarea,
  .e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error) input,
  .e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled).e-success:not(.e-warning):not(.e-error) input,
  .e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success).e-warning:not(.e-error) input,
  .e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning).e-error input,
  .e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error) textarea,
  .e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled).e-success:not(.e-warning):not(.e-error) textarea,
  .e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success).e-warning:not(.e-error) textarea,
  .e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning).e-error textarea,
  .e-input-group.e-input-focus:not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error),
  .e-input-group.e-input-focus:not(.e-float-icon-left).e-warning:not(.e-success):not(.e-error),
  .e-input-group.e-control-wrapper.e-input-focus:not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error),
  .e-input-group.e-control-wrapper.e-input-focus:not(.e-float-icon-left).e-warning:not(.e-success):not(.e-error),
  .e-input-group.e-input-focus:not(.e-float-icon-left).e-error:not(.e-success):not(.e-warning),
  .e-input-group.e-float-icon-left.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap,
  .e-input-group.e-float-icon-left.e-input-focus.e-success:not(.e-warning):not(.e-error) .e-input-in-wrap,
  .e-input-group.e-float-icon-left.e-input-focus.e-warning:not(.e-success):not(.e-error) .e-input-in-wrap,
  .e-input-group.e-float-icon-left.e-input-focus.e-error:not(.e-success):not(.e-warning) .e-input-in-wrap {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-style: $input-group-border-type-focus;
      border-width: $zero-value $zero-value 1px;
    }
  }

  .e-float-input:not(.e-outline) input:-webkit-autofill ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-autofill:not(.e-outline) input:-webkit-autofill ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-autofill:not(.e-input-focus):not(.e-outline) input:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom {
    font-size: $float-label-font-size;
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3'  {
      top: -9px;
      transform: translate3d(0, -6px, 0) scale(.92);
    }
    @else if $input-skin-name == 'fabric' or $input-skin-name == 'highcontrast' {
      font-style: $input-font-normal;
      padding-right: 0;
      transform: translate3d(-10px, -43px, 0) scale(1);
    }
    @else if $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' {
      font-weight: bold;
      padding-right: 0;
      transform: translate3d(-10px, -39px, 0) scale(1);
    }
    @else if ($input-skin-name == 'FluentUI') {
      font-style: $input-font-normal;
      font-weight: 600;
      padding-right: 0;
      transform: translate3d(-10px, -41px, 0) scale(1);
    }
    user-select: text;
  }

  .e-small .e-float-input:not(.e-outline) input:-webkit-autofill ~ label.e-float-text,
  .e-small.e-float-input:not(.e-outline) input:-webkit-autofill ~ label.e-float-text,
  .e-small .e-float-input.e-control-wrapper.e-autofill:not(.e-outline) input:-webkit-autofill ~ label.e-float-text,
  .e-small.e-float-input.e-control-wrapper.e-autofill:not(.e-outline) input:-webkit-autofill ~ label.e-float-text,
  .e-small .e-float-input.e-control-wrapper.e-autofill:not(.e-input-focus):not(.e-outline) input:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-small.e-float-input.e-control-wrapper.e-autofill:not(.e-input-focus):not(.e-outline) input:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom {
    font-size: $float-label-small-font-size;
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      top: -8px;
      transform: translate3d(0, -6px, 0) scale(.92);
    }
    @else if $input-skin-name == 'fabric' or $input-skin-name == 'highcontrast' {
      font-style: $input-font-normal;
      padding-right: 0;
      top: 17px;
      transform: translate3d(-10px, -43px, 0) scale(1);
    }
    @else if $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' {
      font-weight: bold;
      padding-right: 0;
      top: 17px;
      transform: translate3d(-10px, -39px, 0) scale(1);
    }
    @else if ($input-skin-name == 'FluentUI') {
      font-style: $input-font-normal;
      font-weight: 600;
      padding-right: 0;
      top: 17px;
      transform: translate3d(-10px, -38px, 0) scale(1);
    }
    user-select: text;
  }

  .e-float-input textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'fabric' or $input-skin-name == 'FluentUI' or $input-skin-name == 'bootstrap' or $input-skin-name == 'highcontrast' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' {
      @include textarea-float-label-alignment;
      font-style: $input-font-style;
    }
  }

  .e-float-input textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      transform: translate3d(0, 16px, 0) scale(1);
    }
    @else if $input-skin-name == 'fabric' or $input-skin-name == 'FluentUI' or  $input-skin-name == 'highcontrast' {
      font-style: $input-font-normal;
      transform: translate3d(0, 16px, 0) scale(1);
    }
    @else if $input-skin-name == 'bootstrap' {
      padding-right: 0;
      transform: translate3d(0, 16px, 0) scale(1);
    }
  }

  .e-float-input textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    font-size: $float-placeholder-font-size;
    font-style: $input-font-style;
    font-weight: normal;
    left: 0;
    overflow: hidden;
    padding-left: $float-label-padding;
    pointer-events: none;
    position: absolute;
    text-overflow: ellipsis;
    top: -11px;
    transform: translate3d(0, 16px, 0) scale(1);
    transform-origin: left top;
    transition: .25s cubic-bezier(.25, .8, .25, 1);
    user-select: none;
    white-space: nowrap;
    width: 100%;
  }

  .e-float-input textarea:-webkit-autofill ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-autofill textarea:-webkit-autofill ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-autofill textarea:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom {
    font-size: $float-label-font-size;
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      top: -9px;
      transform: translate3d(0, -6px, 0) scale(.92);
    }
    @else if $input-skin-name == 'fabric' or $input-skin-name == 'highcontrast' {
      font-style: $input-font-normal;
      padding-right: 0;
      transform: translate3d(-10px, -43px, 0) scale(1);
    }
    @else if $input-skin-name == 'bootstrap' {
      font-weight: $input-float-font-weight;
      padding-right: 0;
      transform: translate3d(-10px, -39px, 0) scale(1);
    }
    @else if ($input-skin-name == 'tailwind'  or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3') {
      font-weight: 500;
      padding-right: 0;
      transform: translate3d(-10px, -40px, 0) scale(1);
    }
    @else if $input-skin-name == 'FluentUI' {
      font-style: $input-font-normal;
      font-weight: 600;
      padding-right: 0;
      transform: translate3d(-10px, -41px, 0) scale(1);
    }
    user-select: text;
  }

  .e-small .e-float-input textarea:-webkit-autofill ~ label.e-float-text,
  .e-small.e-float-input textarea:-webkit-autofill ~ label.e-float-text,
  .e-small .e-float-input.e-control-wrapper.e-autofill textarea:-webkit-autofill ~ label.e-float-text,
  .e-small.e-float-input.e-control-wrapper.e-autofill textarea:-webkit-autofill ~ label.e-float-text,
  .e-small .e-float-input.e-control-wrapper.e-autofill textarea:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-small.e-float-input.e-control-wrapper.e-autofill textarea:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom {
    font-size: $float-label-small-font-size;
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      top: -8px;
      transform: translate3d(0, -6px, 0) scale(.92);
    }
    @else if $input-skin-name == 'fabric' or $input-skin-name == 'highcontrast' {
      font-style: $input-font-normal;
      padding-right: 0;
      top: 17px;
      transform: translate3d(-10px, -43px, 0) scale(1);
    }
    @else if $input-skin-name == 'bootstrap' {
      font-weight: $input-float-font-weight;
      padding-right: 0;
      top: 17px;
      transform: translate3d(-10px, -39px, 0) scale(1);
    }
    @else if $input-skin-name == 'FluentUI' {
      font-style: $input-font-normal;
      font-weight: 600;
      padding-right: 0;
      top: 17px;
      transform: translate3d(-10px, -37px, 0) scale(1);
    }
    user-select: text;
  }

  .e-float-input.e-small textarea:focus ~ label.e-float-text,
  .e-float-input.e-small textarea:valid ~ label.e-float-text,
  .e-float-input.e-small textarea ~ label.e-label-top.e-float-text,
  .e-float-input.e-small textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-small textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-small textarea label.e-float-text.e-label-top,
  .e-small .e-float-input textarea:focus ~ label.e-float-text,
  .e-small .e-float-input textarea:valid ~ label.e-float-text,
  .e-small .e-float-input textarea ~ label.e-label-top.e-float-text,
  .e-small .e-float-input textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-small .e-float-input textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-small .e-float-input textarea label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper.e-small textarea:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small textarea:valid ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small textarea ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-small textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-small textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-small textarea label.e-float-text.e-label-top,
  .e-small .e-float-input.e-control-wrapper textarea:focus ~ label.e-float-text,
  .e-small .e-float-input.e-control-wrapper textarea:valid ~ label.e-float-text,
  .e-small .e-float-input.e-control-wrapper textarea ~ label.e-label-top.e-float-text,
  .e-small .e-float-input.e-control-wrapper textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-small .e-float-input.e-control-wrapper textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-small .e-float-input.e-control-wrapper textarea label.e-float-text.e-label-top {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      transform: translate3d(0, 6px, 0) scale(.86);
    }
    @else if $input-skin-name == 'fabric' or $input-skin-name == 'highcontrast' {
      font-style: $input-font-normal;
      padding-right: 0;
      transform: translate3d(-10px, -43px, 0) scale(1);
    }
    @else if $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' {
      padding-right: 0;
      transform: translate3d(-10px, -39px, 0) scale(1);
    }
    @else if ($input-skin-name == 'tailwind') {
      font-weight: 500;
      padding-right: 0;
      transform: translate3d(-10px, -31px, 0) scale(1);
    }
    @else if ($input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3') {
      font-weight: 500;
      padding-right: 0;
      transform: translate3d(-10px, -35px, 0) scale(1);
    }
    @else if ($input-skin-name == 'FluentUI') {
      font-style: $input-font-normal;
      font-weight: 600;
      padding-right: 0;
      transform: translate3d(-10px, -37px, 0) scale(1);
    }
  }

  .e-float-input textarea[disabled],
  .e-float-input textarea.e-disabled,
  .e-float-input.e-control-wrapper textarea[disabled],
  .e-float-input.e-control-wrapper textarea.e-disabled  {
    @if $input-skin-name != 'material' and $input-skin-name != 'Material3' {
      border-color: $input-disable-border-color;
      border-style: $input-disable-border-type;
    }
  }

  .e-float-input textarea[disabled],
  .e-float-input textarea.e-disabled,
  .e-float-input.e-control-wrapper textarea[disabled],
  .e-float-input.e-control-wrapper textarea.e-disabled {
    background: transparent;
    background-image: none;
    cursor: not-allowed;
    @if $input-skin-name == 'highcontrast' {
      color: $disable;
    }
  }

  textarea.e-input#{$css},
  .e-input-group textarea,
  .e-input-group textarea.e-input,
  .e-input-group.e-input-focus textarea,
  .e-input-group.e-input-focus textarea.e-input,
  .e-input-group.e-control-wrapper textarea,
  .e-input-group.e-control-wrapper.e-input-focus textarea,
  .e-input-group.e-control-wrapper textarea.e-input,
  .e-input-group.e-control-wrapper.e-input-focus textarea.e-input,
  .e-float-input textarea,
  .e-float-input.e-control-wrapper textarea {
    line-height: 1.5;
    min-height: $textarea-min-height;
    min-width: 0;
    padding: $textarea-padding;
    resize: vertical;
  }

  .e-input-group.e-small textarea,
  .e-input-group.e-small textarea.e-input,
  .e-input-group textarea.e-small,
  .e-input-group textarea.e-input.e-small,
  .e-input-group.e-control-wrapper.e-small textarea,
  .e-input-group.e-control-wrapper.e-small textarea.e-input,
  .e-small .e-input-group textarea,
  .e-small .e-input-group textarea.e-input,
  .e-float-input.e-small textarea,
  .e-float-input textarea.e-small,
  .e-float-input.e-control-wrapper.e-small textarea,
  .e-float-input.e-control-wrapper textarea.e-small,
  .e-small .e-float-input textarea,
  .e-small .e-float-input.e-control-wrapper textarea {
    font: inherit;
    min-height: $textarea-small-min-height;
    padding: $textarea-small-padding;
  }

  .e-input-group.e-input-focus.e-small textarea,
  .e-input-group.e-input-focus.e-small textarea.e-input,
  .e-input-group.e-input-focus textarea.e-small,
  .e-input-group.e-input-focus textarea.e-input.e-small,
  .e-input-group.e-input-focus textarea.e-input.e-small,
  .e-input-group.e-control-wrapper.e-input-focus.e-small textarea,
  .e-input-group.e-control-wrapper.e-input-focus.e-small textarea.e-input,
  .e-small .e-input-group.e-input-focus textarea,
  .e-small .e-input-group.e-input-focus textarea.e-input {
    font: inherit;
    min-height: $textarea-small-min-height;
    padding: $textarea-small-padding;
  }

  .e-input-group.e-small textarea:focus,
  .e-input-group.e-small textarea.e-input:focus,
  .e-input-group textarea.e-small:focus,
  .e-input-group textarea.e-input.e-small:focus,
  .e-input-group.e-control-wrapper.e-small textarea:focus,
  .e-input-group.e-control-wrapper.e-small textarea.e-input:focus,
  .e-small .e-input-group textarea:focus,
  .e-small .e-input-group textarea.e-input:focus,
  .e-float-input.e-small textarea:focus,
  .e-float-input textarea.e-small:focus,
  .e-float-input.e-control-wrapper.e-small textarea:focus,
  .e-float-input.e-control-wrapper textarea.e-small:focus,
  .e-small .e-float-input textarea:focus,
  .e-small .e-float-input.e-control-wrapper textarea:focus {
    padding: $textarea-small-padding;
  }

  input.e-input.e-small#{$css},
  textarea.e-input.e-small#{$css},
  .e-small input.e-input#{$css},
  .e-small textarea.e-input #{$css} {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      min-height: $input-smaller-min-height;
    }
  }

  input.e-input#{$css},
  textarea.e-input#{$css} {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      min-height: $input-min-height;
    }
  }

  // Outline textbox

  textarea.e-outline.e-input,
  .e-outline.e-input-group textarea.e-input,
  .e-outline.e-input-group textarea,
  .e-outline.e-input-group.e-control-wrapper textarea.e-input,
  .e-outline.e-input-group.e-control-wrapper textarea,
  .e-outline.e-float-input textarea.e-input,
  .e-outline.e-float-input textarea,
  .e-outline.e-float-input.e-control-wrapper textarea.e-input,
  .e-outline.e-float-input.e-control-wrapper textarea {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      width: calc(100% - 1px);
    }
  }

  .e-outline.e-float-input.e-control-wrapper.e-numeric label.e-float-text.e-label-bottom span.e-float-text-content.e-float-text-overflow {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      width: calc(100% - 80px);
    }
  }

  .e-outline.e-float-input.e-control-wrapper.e-numeric label.e-float-text.e-label-top span.e-float-text-content.e-float-text-overflow,
  .e-outline.e-float-input.e-control-wrapper label.e-float-text.e-label-top span.e-float-text-content.e-float-text-overflow,
  .e-outline.e-float-input.e-control-wrapper label.e-float-text.e-label-bottom span.e-float-text-content.e-float-text-overflow,
  .e-outline.e-float-input.e-control-wrapper.e-static-clear label.e-float-text.e-label-top span.e-float-text-content.e-float-text-overflow,
  .e-outline.e-float-input.e-control-wrapper.e-numeric.e-static-clear label.e-float-text.e-label-top span.e-float-text-content.e-float-text-overflow {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }
  }

  .e-outline.e-float-input.e-control-wrapper.e-static-clear label.e-float-text.e-label-bottom span.e-float-text-content.e-float-text-overflow,
  .e-outline.e-float-input.e-control-wrapper label.e-float-text.e-label-bottom span.e-float-text-content.e-float-text-overflow.e-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      width: calc(100% - 45px);
    }
  }

  .e-outline.e-float-input.e-control-wrapper.e-static-clear label.e-float-text.e-label-bottom span.e-float-text-content.e-float-text-overflow.e-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      width: calc(100% - 75px);
    }
  }
  
  .e-outline.e-float-input.e-control-wrapper.e-numeric.e-static-clear label.e-float-text.e-label-bottom span.e-float-text-content.e-float-text-overflow {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      width: calc(100% - 110px);
    }
  }

  .e-outline.e-float-input.e-input-group:not(.e-float-icon-left).e-input-focus .e-float-line::before,
  .e-outline.e-float-input.e-input-group:not(.e-float-icon-left).e-input-focus .e-float-line::after,
  .e-outline.e-float-input.e-input-group.e-float-icon-left.e-input-focus .e-input-in-wrap .e-float-line::before,
  .e-outline.e-float-input.e-input-group.e-float-icon-left.e-input-focus .e-input-in-wrap .e-float-line::after,
  .e-outline.e-float-input.e-control-wrapper.e-input-group:not(.e-float-icon-left).e-input-focus .e-float-line::before,
  .e-outline.e-float-input.e-control-wrapper.e-input-group:not(.e-float-icon-left).e-input-focus .e-float-line::after,
  .e-outline.e-float-input.e-control-wrapper.e-input-group.e-float-icon-left.e-input-focus .e-input-in-wrap .e-float-line::before,
  .e-outline.e-float-input.e-control-wrapper.e-input-group.e-float-icon-left.e-input-focus .e-input-in-wrap .e-float-line::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      width: 0%;
    }
  }

  .e-outline.e-input-group.e-control-wrapper,
  .e-outline.e-input-group,
  .e-outline.e-input-group:not(.e-float-icon-left),
  .e-outline.e-float-input,
  .e-outline.e-float-input.e-control-wrapper,
  .e-outline.e-input-group:not(.e-float-icon-left),
  .e-outline.e-input-group.e-success:not(.e-float-icon-left),
  .e-outline.e-input-group.e-warning:not(.e-float-icon-left),
  .e-outline.e-input-group.e-error:not(.e-float-icon-left),
  .e-outline.e-input-group.e-control-wrapper:not(.e-float-icon-left),
  .e-outline.e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left),
  .e-outline.e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left),
  .e-outline.e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left) {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border: 1px solid $outline-border-color;
      border-radius: 4px;
      border-width: 1px;
      fill: transparent;
      transition: border .2s, box-shadow .2s;
    }
  }

  .e-input-group.e-control-wrapper,
  .e-input-group,
  .e-input-group:not(.e-float-icon-left),
  .e-float-input,
  .e-float-input.e-control-wrapper,
  .e-input-group:not(.e-float-icon-left),
  .e-input-group.e-success:not(.e-float-icon-left),
  .e-input-group.e-warning:not(.e-float-icon-left),
  .e-input-group.e-error:not(.e-float-icon-left),
  .e-input-group.e-control-wrapper:not(.e-float-icon-left),
  .e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left),
  .e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left),
  .e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left) {
    @if $input-skin-name == 'fluent2' {
      border: 1px solid $outline-border-color;
      border-radius: 4px;
      border-width: 1px;
      fill: transparent;
      transition: border .2s, box-shadow .2s;
    }
  }

  .e-underline.e-input-group.e-control-wrapper,
  .e-underline.e-input-group,
  .e-underline.e-input-group:not(.e-float-icon-left),
  .e-underline.e-float-input,
  .e-underline.e-float-input.e-control-wrapper,
  .e-underline.e-input-group:not(.e-float-icon-left),
  .e-underline.e-input-group.e-success:not(.e-float-icon-left),
  .e-underline.e-input-group.e-warning:not(.e-float-icon-left),
  .e-underline.e-input-group.e-error:not(.e-float-icon-left),
  .e-underline.e-input-group.e-control-wrapper:not(.e-float-icon-left),
  .e-underline.e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left),
  .e-underline.e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left),
  .e-underline.e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left) {
    @if $input-skin-name == 'fluent2' {
      border: 1px solid $underline-border-color;
      border-width: $input-group-full-border-width;
      padding-top: 1px;
      border-radius: 0;
    }
  }

  .e-outline.e-float-input,
  .e-outline.e-float-input.e-control-wrapper {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      margin-top: $zero-value;
    }
  }

  .e-outline.e-float-input.e-valid-input:not(.e-success):not(.e-error):not(.e-warning):not(.e-float-icon-left),
  .e-outline.e-float-input.e-valid-input:hover:not(.e-success):not(.e-error):not(.e-warning):not(.e-float-icon-left),
  .e-outline.e-float-input.e-valid-input.e-control-wrapper:hover:not(.e-success):not(.e-error):not(.e-warning):not(.e-float-icon-left),
  .e-outline.e-float-input.e-valid-input.e-control-wrapper:not(.e-success):not(.e-error):not(.e-warning):not(.e-float-icon-left),
  .e-outline.e-float-input.e-valid-input.e-control-wrapper:hover:not(.e-success):not(.e-error):not(.e-warning):not(.e-float-icon-left):not(.e-disabled),
  .e-outline.e-float-input.e-valid-input.e-control-wrapper:not(.e-success):not(.e-error):not(.e-warning):not(.e-float-icon-left):not(.e-disabled),
  .e-outline.e-float-input.e-input-group.e-valid-input:not(.e-success):not(.e-error):not(.e-warning):not(.e-float-icon-left),
  .e-outline.e-float-input.e-input-group.e-valid-input:hover:not(.e-success):not(.e-error):not(.e-warning):not(.e-float-icon-left),
  .e-outline.e-float-input.e-input-group.e-control-wrapper.e-valid-input:not(.e-success):not(.e-error):not(.e-warning):not(.e-float-icon-left),
  .e-outline.e-float-input.e-input-group.e-control-wrapper.e-valid-input:hover:not(.e-success):not(.e-error):not(.e-warning):not(.e-float-icon-left),
  .e-outline.e-float-input.e-valid-input.e-success:not(.e-error):not(.e-warning):not(.e-float-icon-left),
  .e-outline.e-float-input.e-valid-input.e-success:hover:not(.e-error):not(.e-warning):not(.e-float-icon-left),
  .e-outline.e-float-input.e-input-group.e-valid-input.e-success:not(.e-error):not(.e-warning):not(.e-float-icon-left),
  .e-outline.e-float-input.e-input-group.e-valid-input.e-success:hover:not(.e-error):not(.e-warning):not(.e-float-icon-left),
  .e-outline.e-float-input.e-control-wrapper.e-valid-input.e-success:not(.e-error):not(.e-warning):not(.e-float-icon-left),
  .e-outline.e-float-input.e-control-wrapper.e-valid-input.e-success:hover:not(.e-error):not(.e-warning):not(.e-float-icon-left),
  .e-outline.e-float-input.e-valid-input.e-error:not(.e-success):not(.e-warning):not(.e-float-icon-left),
  .e-outline.e-float-input.e-valid-input.e-error:hover:not(.e-success):not(.e-warning):not(.e-float-icon-left),
  .e-outline.e-float-input.e-input-group.e-valid-input.e-error:not(.e-success):not(.e-warning):not(.e-float-icon-left),
  .e-outline.e-float-input.e-input-group.e-valid-input.e-error:hover:not(.e-success):not(.e-warning):not(.e-float-icon-left),
  .e-outline.e-float-input.e-control-wrapper.e-valid-input.e-error:not(.e-success):not(.e-warning):not(.e-float-icon-left),
  .e-outline.e-float-input.e-control-wrapper.e-valid-input.e-error:hover:not(.e-success):not(.e-warning):not(.e-float-icon-left),
  .e-outline.e-float-input.e-valid-input.e-warning:not(.e-success):not(.e-error):not(.e-float-icon-left),
  .e-outline.e-float-input.e-valid-input.e-warning:hover:not(.e-success):not(.e-error):not(.e-float-icon-left),
  .e-outline.e-float-input.e-input-group.e-valid-input.e-warning:not(.e-success):not(.e-error):not(.e-float-icon-left),
  .e-outline.e-float-input.e-input-group.e-valid-input.e-warning:hover:not(.e-success):not(.e-error):not(.e-float-icon-left),
  .e-outline.e-float-input.e-control-wrapper.e-valid-input.e-warning:not(.e-success):not(.e-error):not(.e-float-icon-left),
  .e-outline.e-float-input.e-control-wrapper.e-valid-input.e-warning:hover:not(.e-success):not(.e-error):not(.e-float-icon-left),
  .e-outline.e-float-input.e-input-group.e-valid-input.e-float-icon-left:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-outline.e-float-input.e-input-group.e-valid-input.e-control-wrapper.e-float-icon-left:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-outline.e-float-input.e-valid-input.e-float-icon-left:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-outline.e-float-input.e-valid-input.e-control-wrapper.e-float-icon-left:not(.e-input-focus):hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-outline.e-float-input.e-control-wrapper.e-valid-input.e-float-icon-left:not(.e-warning):not(.e-success):not(.e-error) {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-top-color: transparent;
    }
  }

  .e-outline.e-input-group,
  .e-outline .e-input-group,
  .e-outline .e-input-group.e-control-wrapper,
  .e-outline.e-float-input,
  .e-outline.e-float-input.e-control-wrapper {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      font-size: $outline-input-font-size;
    }
  }

  .e-outline.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
  .e-outline.e-input-group.e-control-wrapper.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
  .e-outline.e-float-input.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
  .e-outline.e-float-input.e-input-group.e-input-focus:not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error),
  .e-outline.e-float-input.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success).e-warning:not(.e-error),
  .e-outline.e-float-input.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning).e-error,
  .e-outline.e-float-input.e-control-wrapper.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
  .e-outline.e-float-input.e-control-wrapper.e-input-group.e-input-focus:not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error),
  .e-outline.e-float-input.e-control-wrapper.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success).e-warning:not(.e-error),
  .e-outline.e-float-input.e-control-wrapper.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning).e-error,
  .e-outline.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error),
  .e-outline.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled).e-success:not(.e-warning):not(.e-error),
  .e-outline.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success).e-warning:not(.e-error),
  .e-outline.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning).e-error,
  .e-outline.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error),
  .e-outline.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled).e-success:not(.e-warning):not(.e-error),
  .e-outline.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success).e-warning:not(.e-error),
  .e-outline.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning).e-error,
  .e-outline.e-input-group.e-input-focus:not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error),
  .e-outline.e-input-group.e-input-focus:not(.e-float-icon-left).e-warning:not(.e-success):not(.e-error),
  .e-outline.e-input-group.e-control-wrapper.e-input-focus:not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error),
  .e-outline.e-input-group.e-control-wrapper.e-input-focus:not(.e-float-icon-left).e-warning:not(.e-success):not(.e-error),
  .e-outline.e-input-group.e-input-focus:not(.e-float-icon-left).e-error:not(.e-success):not(.e-warning),
  .e-outline.e-input-group.e-float-icon-left.e-input-focus:not(.e-success):not(.e-warning):not(.e-error),
  .e-outline.e-input-group.e-float-icon-left.e-input-focus.e-success:not(.e-warning):not(.e-error),
  .e-outline.e-input-group.e-float-icon-left.e-input-focus.e-warning:not(.e-success):not(.e-error),
  .e-outline.e-input-group.e-float-icon-left.e-input-focus.e-error:not(.e-success):not(.e-warning) {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-width: $outline-active-input-border;
    }
  }

  input.e-outline.e-input,
  .e-outline.e-input-group input.e-input,
  .e-outline.e-input-group input,
  .e-outline.e-input-group.e-control-wrapper input.e-input,
  .e-outline.e-input-group.e-control-wrapper input,
  .e-outline.e-float-input input.e-input,
  .e-outline.e-float-input input,
  .e-outline.e-float-input.e-control-wrapper input.e-input,
  .e-outline.e-float-input.e-control-wrapper input,
  .e-outline.e-input-group:not(.e-float-icon-left) input.e-input:focus,
  .e-outline.e-input-group.e-control-wrapper:not(.e-float-icon-left) input.e-input:focus,
  .e-outline.e-float-input.e-control-wrapper:not(.e-float-icon-left) input:focus,
  .e-outline.e-float-input:not(.e-float-icon-left) input:focus,
  .e-outline.e-float-input.e-control-wrapper:not(.e-float-icon-left).e-input-focus input,
  .e-outline.e-float-input:not(.e-float-icon-left).e-input-focus input,
  .e-outline.e-input-group:not(.e-float-icon-left).e-input-focus input.e-input,
  .e-outline.e-input-group.e-control-wrapper:not(.e-float-icon-left).e-input-focus input.e-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      box-sizing: border-box;
      padding: $outline-input-padding-top $outline-input-padding-left $outline-input-padding-bottom;
    }
  }

  textarea.e-outline.e-input,
  .e-outline.e-input-group textarea.e-input,
  .e-outline.e-input-group textarea,
  .e-outline.e-input-group.e-control-wrapper textarea.e-input,
  .e-outline.e-input-group.e-control-wrapper textarea,
  .e-outline.e-float-input textarea.e-input,
  .e-outline.e-float-input textarea,
  .e-outline.e-float-input.e-control-wrapper textarea.e-input,
  .e-outline.e-float-input.e-control-wrapper textarea,
  .e-outline.e-input-group:not(.e-float-icon-left) textarea.e-input:focus,
  .e-outline.e-input-group.e-control-wrapper:not(.e-float-icon-left) textarea.e-input:focus {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      box-sizing: border-box;
      margin: $outline-textarea-margin-top;
      padding: $zero-value $outline-input-padding-left $outline-input-padding-left;
    }
  }

  .e-outline.e-input-group input.e-input,
  .e-outline.e-float-input.e-input-group input,
  .e-outline.e-input-group.e-control-wrapper input.e-input,
  .e-outline.e-float-input.e-input-group.e-control-wrapper input,
  .e-outline.e-float-input input,
  .e-outline.e-float-input.e-control-wrapper input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      box-sizing: border-box;
      min-height: $outline-input-min-height;
    }
  }

  .e-outline.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
  .e-outline.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
  .e-outline.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
  .e-outline.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
  .e-outline.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
  .e-outline.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after,
  .e-outline.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
  .e-outline.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
  .e-outline.e-float-input:not(.e-input-group) .e-float-line::before,
  .e-outline.e-float-input:not(.e-input-group) .e-float-line::after,
  .e-outline.e-float-input.e-control-wrapper:not(.e-input-group) .e-float-line::before,
  .e-outline.e-float-input.e-control-wrapper:not(.e-input-group) .e-float-line::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      content: none;
    }
  }

  .e-outline.e-input-group.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-outline.e-input-group.e-input-focus.e-control-wrapper:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-outline.e-float-input.e-input-focus.e-control-wrapper:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-outline.e-float-input.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-outline.e-float-input.e-input-focus:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-outline.e-float-input.e-input-focus.e-control-wrapper:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-outline.e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-outline.e-input-group.e-control-wrapper.e-input-focus:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-outline.e-float-input.e-input-focus:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-outline.e-float-input.e-control-wrapper.e-input-focus:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-color: $input-active-border-color;
      box-shadow: inset 1px 1px $input-active-border-color, inset -1px 0 $input-active-border-color, inset 0 -1px $input-active-border-color;
    }
  }

  .e-outline.e-float-input.e-float-icon-left .e-input-in-wrap,
  .e-outline.e-float-input.e-control-wrapper.e-float-icon-left .e-input-in-wrap,
  .e-outline.e-input-group.e-float-icon-left .e-input-in-wrap,
  .e-outline.e-input-group.e-control-wrapper.e-float-icon-left .e-input-in-wrap {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border: $input-group-border-width;
      border-width: $input-group-border-width;
      margin-left: $zero-value;
    }
  }

  .e-outline.e-input-group.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-float-input.e-input-group.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-input-group.e-control-wrapper.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-float-input.e-input-group.e-control-wrapper.e-float-icon-left > .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      margin-left: 4px;
      margin-right: $zero-value;
    }
  }

  .e-outline.e-input-group.e-rtl.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-float-input.e-rtl.e-input-group.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-input-group.e-rtl.e-control-wrapper.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-float-input.e-input-group.e-rtl.e-control-wrapper.e-float-icon-left > .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      margin-right: 4px;
      margin-left: $zero-value;
    }
  }

  .e-outline.e-float-input .e-clear-icon,
  .e-outline.e-float-input.e-control-wrapper .e-clear-icon,
  .e-outline.e-input-group .e-clear-icon,
  .e-outline.e-input-group.e-control-wrapper .e-clear-icon {
    @if $input-skin-name == 'material' {
      font-size: $outline-input-clear-icon-font-size;
      padding: 9px $outline-input-icon-margin-right 9px $zero-value;
    }
    @if $input-skin-name == 'Material3' {
      font-size: $outline-input-clear-icon-font-size;
      padding: $zero-value;
      height: 32px;
      margin: 4px;
    }
  }

  .e-outline.e-input-group.e-float-icon-left .e-input,
  .e-outline.e-float-input.e-input-group.e-float-icon-left .e-input,
  .e-outline.e-input-group.e-control-wrapper.e-float-icon-left .e-input,
  .e-outline.e-float-input.e-input-group.e-control-wrapper.e-float-icon-left .e-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      padding-left: $zero-value;
    }
  }

  .e-outline.e-input-group .e-input-group-icon,
  .e-outline.e-input-group.e-control-wrapper .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      font-size: $outline-input-icon-font-size;
      margin: 9px $outline-input-icon-margin-right 9px $zero-value;
    }
  }

  .e-outline.e-input-group.e-prepend .e-input-group-icon,
  .e-outline.e-input-group.e-prepend.e-control-wrapper .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      font-size: $outline-input-icon-font-size;
      margin: 9px $zero-value 9px $outline-input-icon-margin-right;
    }
  }

  .e-outline.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error) input,
  .e-outline.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error) input,
  .e-outline.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-success).e-warning:not(.e-error) input,
  .e-outline.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-success):not(.e-warning).e-error input,
  .e-outline.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error) textarea,
  .e-outline.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled).e-success:not(.e-warning):not(.e-error) textarea,
  .e-outline.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success).e-warning:not(.e-error) textarea,
  .e-outline.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning).e-error textarea,
  .e-outline.e-input-group.e-float-icon-left.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap,
  .e-outline.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error) input,
  .e-outline.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error) input,
  .e-outline.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-success).e-warning:not(.e-error) input,
  .e-outline.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-success):not(.e-warning).e-error input,
  .e-outline.e-input-group.e-control-wrapper.e-float-icon-left.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border: $zero-value;
    }
  }

  .e-outline.e-input-group .e-input-group-icon + .e-input-group-icon:last-child,
  .e-outline.e-input-group.e-small .e-input-group-icon + .e-input-group-icon:last-child,
  .e-outline.e-input-group.e-control-wrapper .e-input-group-icon + .e-input-group-icon:last-child,
  .e-outline.e-input-group.e-control-wrapper.e-small .e-input-group-icon + .e-input-group-icon:last-child {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      margin-left: $zero-value;
    }
  }

  .e-outline.e-input-group.e-small:not(.e-float-input) .e-input,
  .e-small .e-outline.e-input-group:not(.e-float-input) .e-input,
  .e-outline.e-input-group.e-control-wrapper.e-small:not(.e-float-input) .e-input,
  .e-small .e-outline.e-input-group.e-control-wrapper:not(.e-float-input) .e-input,
  .e-outline.e-float-input.e-small input,
  .e-small .e-outline.e-float-input input,
  .e-outline.e-float-input.e-input-group.e-small input,
  .e-small .e-outline.e-float-input.e-input-group input,
  .e-outline.e-float-input.e-input-group.e-control-wrapper.e-small input,
  .e-small .e-outline.e-float-input.e-input-group.e-control-wrapper input,
  .e-outline.e-float-input.e-control-wrapper.e-small input,
  .e-small .e-outline.e-float-input.e-control-wrapper input,
  .e-outline.e-float-input.e-control-wrapper.e-input-group.e-small input,
  .e-outline.e-float-input.e-control-wrapper.e-input-group.e-small input,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-input-group input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      box-sizing: border-box;
      min-height: $outline-small-input-min-height;
    }
  }

  .e-outline.e-float-input.e-small input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-small input:valid ~ label.e-float-text,
  .e-outline.e-float-input.e-small input ~ label.e-label-top.e-float-text,
  .e-small .e-outline.e-float-input input ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-small input[readonly] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-small input[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small input:valid ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small input ~ label.e-label-top.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper input ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small input[readonly] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small input[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-small textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-small textarea:valid ~ label.e-float-text,
  .e-outline.e-float-input.e-small textarea ~ label.e-label-top.e-float-text,
  .e-small .e-outline.e-float-input textarea ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-small textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-small textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small textarea:valid ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small textarea ~ label.e-label-top.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper textarea ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-small.e-input-focus input ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-input-focus input ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      font-size: $outline-small-label-font-size;
    }
  }

  .e-outline.e-input-group,
  .e-outline.e-input-group.e-control-wrapper,
  .e-outline.e-float-input,
  .e-outline.e-float-input.e-control-wrapper {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      font-size: $outline-valid-input-font-size;
    }
  }

  .e-outline.e-input-group.e-small,
  .e-small .e-outline.e-input-group,
  .e-outline.e-input-group.e-control-wrapper.e-small,
  .e-small .e-outline.e-input-group.e-control-wrapper,
  .e-outline.e-float-input.e-small,
  .e-small .e-outline.e-float-input,
  .e-outline.e-float-input.e-control-wrapper.e-small,
  .e-small .e-outline.e-float-input.e-control-wrapper {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      font-size: $outline-small-input-font-size;
    }
  }

  input.e-input.e-small.e-outline,
  .e-small input.e-input.e-outline,
  .e-input-group.e-small.e-outline input.e-input,
  .e-outline.e-input-group.e-control-wrapper.e-small input.e-input,
  .e-outline.e-float-input.e-small input,
  .e-outline.e-float-input.e-control-wrapper input.e-small,
  .e-outline.e-float-input.e-small input,
  .e-outline.e-float-input.e-control-wrapper input.e-small,
  .e-outline.e-input-group input.e-input.e-small,
  .e-outline.e-input-group.e-control-wrapper input.e-input.e-small,
  .e-small .e-outline.e-float-input input,
  .e-small .e-outline.e-float-input.e-control-wrapper input,
  .e-small .e-outline.e-input-group input.e-input,
  .e-small .e-outline.e-input-group.e-control-wrapper input.e-input,
  .e-outline.e-input-group.e-small input.e-input:focus,
  .e-outline.e-input-group.e-control-wrapper.e-small input.e-input:focus,
  .e-outline.e-float-input.e-small input:focus,
  .e-outline.e-float-input.e-control-wrapper.e-small input:focus,
  .e-small .e-outline.e-input-group.e-control-wrapper input.e-input:focus,
  .e-small .e-outline.e-input-group input.e-input:focus,
  .e-small .e-outline.e-float-input input:focus,
  .e-small .e-outline.e-float-input.e-control-wrapper input:focus,
  .e-outline.e-float-input.e-small.e-input-focus input,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-input-focus input,
  .e-small .e-outline.e-float-input.e-input-focus input,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-input-focus input,
  .e-outline.e-input-group.e-small.e-input-focus input.e-input,
  .e-outline.e-input-group.e-control-wrapper.e-small.e-input-focus input.e-input,
  .e-small .e-outline.e-input-group.e-control-wrapper.e-input-focus input.e-input,
  .e-small .e-outline.e-input-group.e-input-focus input.e-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      padding: $outline-small-input-padding-top $outline-small-input-padding-left;
    }
  }

  textarea.e-input.e-small.e-outline,
  .e-small textarea.e-input.e-outline,
  .e-input-group.e-small.e-outline textarea.e-input,
  .e-outline.e-input-group.e-control-wrapper.e-small textarea.e-input,
  .e-outline.e-float-input.e-control-wrapper textarea.e-small,
  .e-outline.e-float-input.e-small textarea,
  .e-outline.e-input-group textarea.e-input.e-small,
  .e-outline.e-input-group.e-control-wrapper textarea.e-input.e-small,
  .e-small .e-outline.e-float-input textarea,
  .e-small .e-outline.e-float-input.e-control-wrapper textarea,
  .e-small .e-outline.e-input-group textarea.e-input,
  .e-small .e-outline.e-input-group.e-control-wrapper textarea.e-input,
  .e-outline.e-input-group.e-small textarea.e-input:focus,
  .e-outline.e-input-group.e-control-wrapper.e-small textarea.e-input:focus,
  .e-outline.e-float-input.e-small textarea:focus,
  .e-outline.e-float-input.e-control-wrapper.e-small textarea:focus,
  .e-small .e-outline.e-input-group.e-control-wrapper textarea.e-input:focus,
  .e-small .e-outline.e-input-group textarea.e-input:focus,
  .e-small .e-outline.e-float-input textarea:focus,
  .e-small .e-outline.e-float-input.e-control-wrapper textarea:focus {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      box-sizing: border-box;
      margin: $outline-small-textarea-margin-top;
      padding: $zero-value $outline-small-input-padding-left $outline-small-input-padding-left;
    }
  }

  .e-outline.e-input-group.e-small.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-input-group.e-float-icon-left > .e-input-group-icon.e-small,
  .e-outline.e-input-group.e-control-wrapper.e-small.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-input-group.e-control-wrapper.e-float-icon-left > .e-input-group-icon.e-small,
  .e-small .e-outline.e-input-group.e-float-icon-left > .e-input-group-icon,
  .e-small .e-outline.e-input-group.e-control-wrapper.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-float-input.e-input-group.e-small.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-float-input.e-input-group.e-float-icon-left > .e-input-group-icon.e-small,
  .e-small .e-outline.e-float-input.e-input-group.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-float-input.e-control-wrapper.e-input-group.e-small.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-float-input.e-control-wrapper.e-input-group.e-float-icon-left > .e-input-group-icon.e-small,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-input-group.e-float-icon-left > .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      margin-left: $zero-value;
      margin-right: $outline-small-input-icon-margin-right;
    }
  }

  .e-outline.e-input-group.e-small .e-input-group-icon,
  .e-outline.e-input-group.e-control-wrapper.e-small .e-input-group-icon,
  .e-small .e-outline.e-input-group .e-input-group-icon,
  .e-small .e-outline.e-input-group.e-control-wrapper .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      margin-left: $zero-value;
      margin-right: $outline-small-input-icon-margin-left;
    }
  }

  .e-outline.e-input-group.e-prepend.e-small .e-input-group-icon,
  .e-outline.e-input-group.e-prepend.e-control-wrapper.e-small .e-input-group-icon,
  .e-small .e-outline.e-input-group.e-prepend .e-input-group-icon,
  .e-small .e-outline.e-input-group.e-prepend.e-control-wrapper .e-input-group-icon,
  .e-rtl.e-outline.e-input-group.e-small .e-input-group-icon,
  .e-rtl .e-outline.e-input-group.e-small .e-input-group-icon,
  .e-rtl.e-outline.e-input-group.e-control-wrapper.e-small .e-input-group-icon,
  .e-rtl .e-outline.e-input-group.e-control-wrapper.e-small .e-input-group-icon,
  .e-small .e-rtl.e-outline.e-input-group .e-input-group-icon,
  .e-rtl.e-small .e-outline.e-input-group .e-input-group-icon,
  .e-small .e-rtl.e-outline.e-input-group.e-control-wrapper .e-input-group-icon .e-rtl.e-small .e-outline.e-input-group.e-control-wrapper .e-input-group-icon,
  .e-rtl.e-outline.e-input-group.e-small .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl .e-outline.e-input-group.e-small .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl .e-outline.e-input-group.e-control-wrapper.e-small .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl.e-outline.e-input-group.e-control-wrapper.e-small .e-input-group-icon + .e-input-group-icon:last-child {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      margin-left: $outline-small-input-icon-margin-left;
      margin-right: $zero-value;
    }
  }

  .e-outline.e-input-group.e-small .e-clear-icon,
  .e-outline.e-input-group .e-clear-icon.e-small,
  .e-small .e-outline.e-input-group .e-clear-icon,
  .e-outline.e-input-group.e-control-wrapper.e-small .e-clear-icon,
  .e-outline.e-input-group.e-control-wrapper .e-clear-icon.e-small,
  .e-small .e-outline.e-input-group.e-control-wrapper .e-clear-icon {
    @if $input-skin-name == 'material' {
      font-size: $outline-small-clear-icon-font-size;
      padding: 7px $outline-small-input-icon-margin-left 7px $zero-value;
    }
    @if $input-skin-name == 'Material3' {
      font-size: $outline-small-clear-icon-font-size;
    }
  }

  .e-outline.e-float-input.e-input-group.e-small .e-input-group-icon,
  .e-small .e-outline.e-float-input.e-input-group .e-input-group-icon,
  .e-outline.e-float-input.e-control-wrapper.e-input-group.e-small .e-input-group-icon,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-input-group .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      margin-top: $outline-bigger-small-group-icon-top;
    }
  }

  .e-outline.e-float-input input,
  .e-outline.e-float-input textarea,
  .e-outline.e-float-input.e-control-wrapper input,
  .e-outline.e-float-input.e-control-wrapper textarea {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border: $outline-input-border;
      border-width: $outline-input-group-border-width;
    }
  }

  .e-outline label.e-float-text,
  .e-outline.e-float-input label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper label.e-float-text,
  .e-outline.e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-outline.e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-outline.e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-outline.e-float-input textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-outline.e-float-input.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      box-sizing: border-box;
      display: flex;
      left: $zero-value;
      line-height: $outline-label-default-line-height;
      position: absolute;
      top: $outline-float-label-top;
      transform: translate3d($zero-value, $zero-value, $zero-value) scale(1);
      transition: color .2s, font-size .2s, line-height .2s;
    }
  }

  .e-outline.e-float-input.e-small:not(.e-error):not(.e-valid-input):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-outline.e-float-input:not(.e-error):not(.e-valid-input):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper.e-small:not(.e-error):not(.e-valid-input):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-outline.e-float-input.e-control-wrapper:not(.e-error):not(.e-valid-input):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small.e-outline:not(.e-valid-input):not(.e-valid-input):not(.e-input-focus) input:not(:focus):not(:valid) ~ label.e-float-text,
  .e-small.e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus) input:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-small.e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(.e-input-focus) input:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      line-height: $outline-small-label-line-height;
    }
  }

  .e-outline.e-float-input.e-small:not(.e-error):not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-outline.e-float-input:not(.e-error):not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper.e-small:not(.e-error):not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-outline.e-float-input.e-control-wrapper:not(.e-error):not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small.e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small.e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small.e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus) textarea:valid ~ label.e-float-text.e-label-bottom,
  .e-small.e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(.e-input-focus) textarea:valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus) textarea:valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(.e-input-focus) textarea:valid ~ label.e-float-text.e-label-bottom,
  .e-small.e-outline:not(.e-valid-input):not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):not(:valid) ~ label.e-float-text,
  .e-small.e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-small.e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-outline.e-float-input.e-small:not(.e-error):not(.e-valid-input) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-outline.e-float-input:not(.e-error):not(.e-valid-input) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper.e-small:not(.e-error):not(.e-valid-input) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-outline.e-float-input.e-control-wrapper:not(.e-error):not(.e-valid-input) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small.e-outline:not(.e-valid-input):not(.e-valid-input) textarea:not(:focus):not(:valid) ~ label.e-float-text,
  .e-small.e-outline.e-float-input:not(.e-valid-input) textarea:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-small.e-outline.e-float-input.e-control-wrapper:not(.e-valid-input) textarea:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      line-height: $outline-textarea-small-label-line-height;
    }
  }

  .e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus) input:valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(.e-input-focus) input:valid ~ label.e-float-text.e-label-bottom,
  .e-outline:not(.e-valid-input):not(.e-valid-input):not(.e-input-focus) input:not(:focus):not(:valid) ~ label.e-float-text,
  .e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus) input:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(.e-input-focus) input:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      line-height: $outline-label-line-height;
    }
  }

  .e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus) textarea:valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(.e-input-focus) textarea:valid ~ label.e-float-text.e-label-bottom,
  .e-outline:not(.e-valid-input):not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):not(:valid) ~ label.e-float-text,
  .e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-outline.e-float-input:not(.e-error):not(.e-valid-input) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper:not(.e-error):not(.e-valid-input) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline:not(.e-valid-input):not(.e-valid-input) textarea:not(:focus):not(:valid) ~ label.e-float-text,
  .e-outline.e-float-input:not(.e-valid-input) textarea:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper:not(.e-valid-input) textarea:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      line-height: $outline-textarea-label-line-height;
    }
  }

  .e-outline label.e-float-text::before,
  .e-outline label.e-float-text::after,
  .e-outline.e-float-input label.e-float-text::before,
  .e-outline.e-float-input label.e-float-text::after,
  .e-outline.e-float-input.e-control-wrapper label.e-float-text::before,
  .e-outline.e-float-input.e-control-wrapper label.e-float-text::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-top: solid $outline-wrapper-border-infocused;
      border-top-color: transparent;
      box-shadow: inset $zero-value 1px transparent;
      box-sizing: border-box;
      content: ' ';
      display: block;
      height: $outline-label-height;
      margin-top: $outline-label-before-element-margin-top;
      min-width: $outline-label-min-width;
      pointer-events: none;
      position: relative;
      transition: border-color .1s, box-shadow .2s;
    }
  }

  .e-outline.e-float-icon-left label.e-float-text::before,
  .e-outline.e-float-icon-left label.e-float-text::after,
  .e-outline.e-float-input.e-float-icon-left label.e-float-text::before,
  .e-outline.e-float-input.e-float-icon-left label.e-float-text::after,
  .e-outline.e-float-input.e-control-wrapper.e-float-icon-left label.e-float-text::before,
  .e-outline.e-float-input.e-control-wrapper.e-float-icon-left label.e-float-text::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      transition: none;
    }
  }

  .e-small.e-outline label.e-float-text::before,
  .e-small.e-outline label.e-float-text::after,
  .e-small.e-outline.e-float-input label.e-float-text::before,
  .e-small.e-outline.e-float-input label.e-float-text::after,
  .e-small.e-outline.e-float-input.e-control-wrapper label.e-float-text::before,
  .e-small.e-outline.e-float-input.e-control-wrapper label.e-float-text::after,
  .e-small .e-outline label.e-float-text::before,
  .e-small .e-outline label.e-float-text::after,
  .e-small .e-outline.e-float-input label.e-float-text::before,
  .e-small .e-outline.e-float-input label.e-float-text::after,
  .e-small .e-outline.e-float-input.e-control-wrapper label.e-float-text::before,
  .e-small .e-outline.e-float-input.e-control-wrapper label.e-float-text::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      min-width: $outline-small-label-min-width;
    }
  }

  .e-outline.e-valid-input:not(.e-input-focus) label.e-float-text::before,
  .e-outline.e-valid-input:not(.e-input-focus) label.e-float-text::after,
  .e-outline.e-float-input.e-valid-input:not(.e-input-focus) label.e-float-text::before,
  .e-outline.e-float-input.e-valid-input:not(.e-input-focus) label.e-float-text::after,
  .e-outline.e-float-input.e-control-wrapper.e-valid-input:not(.e-input-focus) label.e-float-text::before,
  .e-outline.e-float-input.e-control-wrapper.e-valid-input:not(.e-input-focus) label.e-float-text::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-top-color: $outline-border-color;
      border-width: 1px;
    }
  }

  .e-outline.e-valid-input.e-success:not(.e-error):not(.e-warning):not(.e-input-focus) label.e-float-text::before,
  .e-outline.e-valid-input.e-success:not(.e-error):not(.e-warning):not(.e-input-focus) label.e-float-text::after,
  .e-outline.e-float-input.e-valid-input.e-success:not(.e-error):not(.e-warning):not(.e-input-focus) label.e-float-text::before,
  .e-outline.e-float-input.e-valid-input.e-success:not(.e-error):not(.e-warning):not(.e-input-focus) label.e-float-text::after,
  .e-outline.e-float-input.e-control-wrapper.e-valid-input.e-success:not(.e-error):not(.e-warning):not(.e-input-focus) label.e-float-text::before,
  .e-outline.e-float-input.e-control-wrapper.e-valid-input.e-success:not(.e-error):not(.e-warning):not(.e-input-focus) label.e-float-text::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-top-color: $input-success-color;
    }
  }

  .e-outline.e-success.e-input-focus:not(.e-error):not(.e-warning) label.e-float-text::before,
  .e-outline.e-success.e-input-focus:not(.e-error):not(.e-warning) label.e-float-text::after,
  .e-outline.e-float-input.e-success.e-input-focus:not(.e-error):not(.e-warning) label.e-float-text::before,
  .e-outline.e-float-input.e-success.e-input-focus:not(.e-error):not(.e-warning) label.e-float-text::after,
  .e-outline.e-float-input.e-control-wrapper.e-success.e-input-focus:not(.e-error):not(.e-warning) label.e-float-text::before,
  .e-outline.e-float-input.e-control-wrapper.e-success.e-input-focus:not(.e-error):not(.e-warning) label.e-float-text::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-color: $input-success-color;
    }
  }

  .e-outline.e-valid-input.e-error:not(.e-success):not(.e-warning):not(.e-input-focus) label.e-float-text::before,
  .e-outline.e-valid-input.e-error:not(.e-success):not(.e-warning):not(.e-input-focus) label.e-float-text::after,
  .e-outline.e-float-input.e-valid-input.e-error:not(.e-success):not(.e-warning):not(.e-input-focus) label.e-float-text::before,
  .e-outline.e-float-input.e-valid-input.e-error:not(.e-success):not(.e-warning):not(.e-input-focus) label.e-float-text::after,
  .e-outline.e-float-input.e-control-wrapper.e-valid-input.e-error:not(.e-success):not(.e-warning):not(.e-input-focus) label.e-float-text::before,
  .e-outline.e-float-input.e-control-wrapper.e-valid-input.e-error:not(.e-success):not(.e-warning):not(.e-input-focus) label.e-float-text::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-top-color: $input-error-color;
    }
  }

  .e-outline.e-error.e-input-focus:not(.e-success):not(.e-warning) label.e-float-text::before,
  .e-outline.e-error.e-input-focus:not(.e-success):not(.e-warning) label.e-float-text::after,
  .e-outline.e-float-input.e-error.e-input-focus:not(.e-success):not(.e-warning) label.e-float-text::before,
  .e-outline.e-float-input.e-error.e-input-focus:not(.e-success):not(.e-warning) label.e-float-text::after,
  .e-outline.e-float-input.e-control-wrapper.e-error.e-input-focus:not(.e-success):not(.e-warning) label.e-float-text::before,
  .e-outline.e-float-input.e-control-wrapper.e-error.e-input-focus:not(.e-success):not(.e-warning) label.e-float-text::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-color: $input-error-color;
    }
  }

  .e-outline.e-valid-input.e-warning:not(.e-success):not(.e-error):not(.e-input-focus) label.e-float-text::before,
  .e-outline.e-valid-input.e-warning:not(.e-success):not(.e-error):not(.e-input-focus) label.e-float-text::after,
  .e-outline.e-float-input.e-valid-input.e-warning:not(.e-success):not(.e-error):not(.e-input-focus) label.e-float-text::before,
  .e-outline.e-float-input.e-valid-input.e-warning:not(.e-success):not(.e-error):not(.e-input-focus) label.e-float-text::after,
  .e-outline.e-float-input.e-control-wrapper.e-valid-input.e-warning:not(.e-success):not(.e-error):not(.e-input-focus) label.e-float-text::before,
  .e-outline.e-float-input.e-control-wrapper.e-valid-input.e-warning:not(.e-success):not(.e-error):not(.e-input-focus) label.e-float-text::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-top-color: $input-warning-color;
    }
  }

  .e-outline.e-warning.e-input-focus:not(.e-success):not(.e-error) label.e-float-text::before,
  .e-outline.e-warning.e-input-focus:not(.e-success):not(.e-error) label.e-float-text::after,
  .e-outline.e-float-input.e-warning.e-input-focus:not(.e-success):not(.e-error) label.e-float-text::before,
  .e-outline.e-float-input.e-warning.e-input-focus:not(.e-success):not(.e-error) label.e-float-text::after,
  .e-outline.e-float-input.e-control-wrapper.e-warning.e-input-focus:not(.e-success):not(.e-error) label.e-float-text::before,
  .e-outline.e-float-input.e-control-wrapper.e-warning.e-input-focus:not(.e-success):not(.e-error) label.e-float-text::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-color: $input-warning-color;
    }
  }

  .e-outline label.e-float-text::before,
  .e-outline.e-float-input label.e-float-text::before,
  .e-outline.e-float-input.e-control-wrapper label.e-float-text::before {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-left: solid 1px transparent;
      border-radius: $outline-label-margin $zero-value;
      left: $outline-label-before-left;
      margin-right: $outline-label-margin;
    }
  }

  .e-rtl.e-outline label.e-float-text::before,
  .e-rtl.e-outline.e-float-input label.e-float-text::before,
  .e-rtl.e-outline.e-float-input.e-control-wrapper label.e-float-text::before {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-left: $zero-value;
      border-radius: $zero-value $outline-label-margin;
      left: $outline-label-before-left-rtl;
    }
  }

  .e-rtl.e-outline label.e-float-text::after,
  .e-rtl.e-outline.e-float-input label.e-float-text::after,
  .e-rtl.e-outline.e-float-input.e-control-wrapper label.e-float-text::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-radius: $outline-label-border-radius $zero-value;
      border-right: $zero-value;
      left: $outline-label-after-left-rtl;
    }
  }

  .e-outline label.e-float-text::after,
  .e-outline.e-float-input label.e-float-text::after,
  .e-outline.e-float-input.e-control-wrapper label.e-float-text::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-radius: $zero-value $outline-label-border-radius;
      border-right: solid 1px transparent;
      flex-grow: 1;
      left: $outline-label-after-left;
      margin-left: $outline-label-margin;
    }
  }

  .e-outline.e-input-focus label.e-float-text::before,
  .e-outline.e-input-focus label.e-float-text::after,
  .e-outline.e-float-input.e-input-focus label.e-float-text::before,
  .e-outline.e-float-input.e-input-focus label.e-float-text::after,
  .e-outline.e-float-input.e-control-wrapper.e-input-focus label.e-float-text::before,
  .e-outline.e-float-input.e-control-wrapper.e-input-focus label.e-float-text::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-top-color: $input-active-border-color;
    }
  }

  .e-outline.e-float-input.e-input-focus:not(.e-error):not(.e-success):not(.e-warning):not(.e-disabled),
  .e-outline.e-float-input.e-input-focus:not(.e-error):not(.e-success):not(.e-warning):not(.e-disabled):not(.e-float-icon-left),
  .e-outline.e-float-input.e-input-group.e-input-focus:not(.e-error):not(.e-success):not(.e-warning):not(.e-disabled):not(.e-float-icon-left),
  .e-outline.e-float-input.e-input-group.e-input-focus:not(.e-error):not(.e-success):not(.e-warning):not(.e-disabled),
  .e-outline.e-float-input.e-control-wrapper.e-input-focus:not(.e-error):not(.e-success):not(.e-warning):not(.e-disabled):not(.e-float-icon-left),
  .e-outline.e-float-input.e-control-wrapper.e-input-focus:not(.e-error):not(.e-success):not(.e-warning):not(.e-disabled),
  .e-outline.e-float-input.e-input-focus:hover:not(.e-error):not(.e-success):not(.e-warning):not(.e-disabled),
  .e-outline.e-float-input.e-input-group.e-input-focus:hover:not(.e-error):not(.e-success):not(.e-warning):not(.e-disabled),
  .e-outline.e-float-input.e-control-wrapper.e-input-focus:hover:not(.e-error):not(.e-success):not(.e-warning):not(.e-disabled),
  .e-outline.e-float-input.e-input-focus:hover:not(.e-error):not(.e-success):not(.e-warning):not(.e-disabled):not(.e-float-icon-left),
  .e-outline.e-float-input.e-input-group.e-input-focus:hover:not(.e-error):not(.e-success):not(.e-warning):not(.e-disabled):not(.e-float-icon-left),
  .e-outline.e-float-input.e-control-wrapper.e-input-focus:hover:not(.e-error):not(.e-success):not(.e-warning):not(.e-disabled):not(.e-float-icon-left) {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-top-color: transparent;
      box-shadow: inset 1px $zero-value $input-active-border-color, inset -1px $zero-value $input-active-border-color, inset $zero-value -1px $input-active-border-color;
    }
  }

  .e-outline.e-float-input input:focus ~ label.e-float-text,
  .e-outline.e-float-input input:valid ~ label.e-float-text,
  .e-outline.e-float-input input ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input input[readonly] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input input[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input input label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-control-wrapper input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper input:valid ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper input ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper input[readonly] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper input[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper input label.e-float-text.e-label-top,
  .e-outline.e-float-input textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input textarea:valid ~ label.e-float-text,
  .e-outline.e-float-input textarea ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input textarea label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-control-wrapper textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper textarea:valid ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper textarea ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper textarea label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-input-focus input ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-input-focus input ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      font-size: $outline-floating-label-font-size;
      top: $outline-float-label-top;
      transform: translate3d($zero-value, $zero-value, $zero-value) scale(1);
    }
  }

  .e-outline.e-float-input.e-rtl .e-clear-icon,
  .e-rtl .e-outline.e-float-input .e-clear-icon,
  .e-outline.e-float-input.e-control-wrapper.e-rtl .e-clear-icon,
  e-rtl .e-outline.e-float-input.e-control-wrapper .e-clear-icon,
  .e-outline.e-input-group.e-rtl .e-clear-icon,
  .e-rtl .e-outline.e-input-group .e-clear-icon,
  .e-outline.e-input-group.e-control-wrapper.e-rtl .e-clear-icon,
  .e-rtl .e-outline.e-input-group.e-control-wrapper.e-rtl .e-clear-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      padding-left: $outline-input-icon-margin-right;
      padding-right: $outline-input-icon-margin-left;
    }
  }

  .e-rtl .e-outline.e-input-group .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl.e-outline.e-input-group .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl.e-outline.e-input-group.e-small .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl .e-outline.e-input-group.e-small .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl .e-outline.e-input-group.e-control-wrapper .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl.e-outline.e-input-group.e-control-wrapper .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl .e-outline.e-input-group.e-control-wrapper.e-small .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl.e-outline.e-input-group.e-control-wrapper.e-small .e-input-group-icon + .e-input-group-icon:last-child,
  .e-outline.e-float-input.e-rtl.e-float-icon-left .e-input-in-wrap,
  .e-outline.e-float-input.e-rtl.e-control-wrapper.e-float-icon-left .e-input-in-wrap,
  .e-outline.e-input-group.e-rtl.e-float-icon-left .e-input-in-wrap,
  .e-outline.e-input-group.e-rtl.e-control-wrapper.e-float-icon-left .e-input-in-wrap {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      margin-right: $zero-value;
    }
  }

  .e-rtl .e-outline.e-input-group .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl.e-outline.e-input-group .e-input-group-icon + .e-input-group-icon:last-child {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      margin-left: $outline-input-icon-margin-right;
    }
  }

  .e-rtl.e-outline.e-input-group .e-input-group-icon,
  .e-rtl .e-outline.e-input-group .e-input-group-icon,
  .e-rtl.e-outline.e-input-group.e-control-wrapper .e-input-group-icon .e-rtl .e-outline.e-input-group.e-control-wrapper .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      margin-left: $outline-input-icon-margin-right;
      margin-right: $outline-input-icon-margin-left;
    }
  }

  .e-rtl.e-outline.e-input-group.e-small .e-clear-icon,
  .e-rtl .e-outline.e-input-group.e-small .e-clear-icon,
  .e-rtl.e-outline.e-input-group .e-clear-icon.e-small,
  .e-rtl .e-outline.e-input-group .e-clear-icon.e-small,
  .e-rtl.e-small .e-outline.e-input-group .e-clear-icon,
  .e-small .e-rtl.e-outline.e-input-group .e-clear-icon,
  .e-rtl.e-outline.e-input-group.e-control-wrapper.e-small .e-clear-icon,
  .e-rtl .e-outline.e-input-group.e-control-wrapper.e-small .e-clear-icon,
  .e-rtl .e-outline.e-input-group.e-control-wrapper .e-clear-icon.e-small,
  .e-small .e-rtl.e-outline.e-input-group.e-control-wrapper .e-clear-icon .e-rtl.e-small .e-outline.e-input-group.e-control-wrapper .e-clear-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      padding-left: $outline-small-input-icon-margin-left;
      padding-right: $zero-value;
    }
  }

  .e-rtl .e-outline.e-input-group .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl.e-outline.e-input-group .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl .e-outline.e-input-group.e-control-wrapper .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl.e-outline.e-input-group.e-control-wrapper .e-input-group-icon + .e-input-group-icon:last-child {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      margin-left: $outline-input-icon-margin-right;
      margin-right: $zero-value;
    }
  }

  .e-outline.e-float-input textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input textarea:valid ~ label.e-float-text,
  .e-outline.e-float-input textarea ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input textarea label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-control-wrapper textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper textarea:valid ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper textarea ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper textarea label.e-float-text.e-label-top {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      top: $outline-float-label-top;
      transform: translate3d($zero-value, $zero-value, $zero-value) scale(1);
    }
  }

  .e-outline textarea,
  textarea.e-outline,
  .e-outline.e-float-input textarea,
  .e-outline.e-float-input.e-control-wrapper textarea,
  .e-outline.e-float-input.e-input-group textarea {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      box-sizing: border-box;
    }
  }

  .e-outline.e-float-input.e-valid-input:not(.e-input-focus) input:valid ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-valid-input:not(.e-input-focus) input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-valid-input:not(.e-input-focus) textarea:valid ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-valid-input:not(.e-input-focus) textarea:focus ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      color: $outline-label-font-color-with-value;
    }
  }

  .e-rtl.e-outline.e-float-input.e-small textarea ~ label.e-float-text,
  .e-rtl.e-outline.e-float-input textarea ~ label.e-float-text.e-small,
  .e-rtl.e-outline.e-float-input textarea.e-small ~ label.e-float-text,
  .e-small .e-rtl.e-outline.e-float-input textarea ~ label.e-float-text,
  .e-rtl.e-outline.e-float-input.e-control-wrapper.e-small textarea ~ label.e-float-text,
  .e-rtl.e-outline.e-float-input.e-control-wrapper textarea ~ label.e-float-text.e-small,
  .e-rtl.e-outline.e-float-input.e-control-wrapper textarea.e-small ~ label.e-float-text,
  .e-small .e-rtl.e-outline.e-float-input.e-control-wrapper textarea ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      top: $outline-float-label-top;
    }
  }

  .e-outline.e-float-input.e-small .e-clear-icon::before,
  .e-outline.e-float-input.e-control-wrapper.e-small .e-clear-icon::before,
  .e-outline.e-input-group.e-small .e-clear-icon::before,
  .e-outline.e-input-group.e-control-wrapper.e-small .e-clear-icon::before,
  .e-outline.e-float-input.e-control-wrapper input.e-small:first-child ~ .e-clear-icon::before,
  .e-outline.e-small .e-float-input.e-control-wrapper .e-clear-icon::before,
  .e-outline.e-float-input input.e-small:first-child ~ .e-clear-icon::before,
  .e-outline.e-small .e-float-input .e-clear-icon::before,
  .e-outline.e-small .e-input-group .e-clear-icon::before,
  .e-outline.e-small .e-input-group.e-control-wrapper .e-clear-icon::before {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      font-size: $outline-input-small-clear-icon;
    }
  }

  .e-outline.e-float-input .e-clear-icon::before,
  .e-outline.e-float-input.e-control-wrapper .e-clear-icon::before,
  .e-outline.e-input-group .e-clear-icon::before,
  .e-outline.e-input-group.e-control-wrapper .e-clear-icon::before {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      font-size: $outline-input-clear-icon;
    }
  }

  .e-outline.e-float-input textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline label.e-float-text,
  .e-outline.e-float-input label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper label.e-float-text,
  .e-outline.e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input textarea:-webkit-autofill ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-autofill textarea:-webkit-autofill ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-autofill textarea:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      font-size: $outline-float-label-font-size;
    }
  }

  .e-outline.e-float-input.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-outline.e-float-input textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-outline.e-float-input.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-outline.e-float-input textarea:-webkit-autofill ~ label.e-float-text,
  .e-small.e-outline.e-float-input textarea:-webkit-autofill ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-autofill textarea:-webkit-autofill ~ label.e-float-text,
  .e-small.e-outline.e-float-input.e-control-wrapper.e-autofill textarea:-webkit-autofill ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-autofill textarea:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-small.e-outline.e-float-input.e-control-wrapper.e-autofill textarea:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-small label.e-float-text,
  .e-outline.e-float-input.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-outline.e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper.e-small label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-outline.e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-outline.e-float-input textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-outline.e-float-input.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-small textarea ~ label.e-float-text,
  .e-outline.e-float-input textarea ~ label.e-float-text.e-small,
  .e-outline.e-float-input textarea.e-small ~ label.e-float-text,
  .e-small .e-outline.e-float-input textarea ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small textarea ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper textarea ~ label.e-float-text.e-small,
  .e-outline.e-float-input.e-control-wrapper textarea.e-small ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper textarea ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      font-size: $small-outline-float-label-font-size;
    }
  }

  .e-outline.e-float-input input:-webkit-autofill ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-autofill input:-webkit-autofill ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-autofill:not(.e-input-focus) input:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      animation-name: slideTopUp;/* stylelint-disable-line no-unknown-animations */
    }
  }

  .e-small .e-outline.e-float-input input:-webkit-autofill ~ label.e-float-text,
  .e-small.e-outline.e-float-input input:-webkit-autofill ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-autofill input:-webkit-autofill ~ label.e-float-text,
  .e-small.e-outline.e-float-input.e-control-wrapper.e-autofill input:-webkit-autofill ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-autofill:not(.e-input-focus) input:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-small.e-outline.e-float-input.e-control-wrapper.e-autofill:not(.e-input-focus) input:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      animation-name: slideTopUp;/* stylelint-disable-line no-unknown-animations */
    }
  }

  .e-filled.e-input-group,
  .e-filled.e-input-group.e-control-wrapper,
  .e-filled.e-float-input,
  .e-filled.e-float-input.e-control-wrapper {
    line-height: 1;
  }

  .e-filled input.e-input#{$css},
  .e-filled.e-input-group input,
  .e-filled.e-input-group.e-control-wrapper input,
  .e-filled.e-input-group input.e-input,
  .e-filled.e-input-group.e-control-wrapper input.e-input,
  .e-filled.e-float-input:not(.e-input-group) input,
  .e-filled.e-float-input.e-control-wrapper:not(.e-input-group) input,
  .e-control .e-filled input.e-input,
  .e-control .e-filled.e-input-group input,
  .e-control .e-filled.e-input-group input.e-input,
  .e-control .e-filled.e-input-group.e-control-wrapper input,
  .e-control .e-filled.e-input-group.e-control-wrapper input.e-input,
  .e-control .e-filled.e-float-input input,
  .e-control .e-filled.e-float-input.e-control-wrapper input,
  .e-control.e-filled.e-input-group input,
  .e-control.e-filled.e-input-group input.e-input,
  .e-control.e-filled.e-input-group.e-control-wrapper input,
  .e-control.e-filled.e-input-group.e-control-wrapper input.e-input,
  .e-control.e-filled.e-float-input input,
  .e-control.e-filled.e-float-input.e-control-wrapper input,
  .e-filled input.e-input.e-small#{$css},
  .e-filled.e-input-group.e-small input,
  .e-filled.e-input-group.e-small input.e-input,
  .e-small .e-filled.e-input-group input,
  .e-small .e-filled.e-input-group input.e-input,
  .e-filled.e-input-group.e-control-wrapper.e-small input,
  .e-filled.e-input-group.e-control-wrapper.e-small input.e-input,
  .e-small .e-filled.e-input-group.e-control-wrapper input,
  .e-small .e-filled.e-input-group.e-control-wrapper input.e-input,
  .e-filled.e-float-input.e-small input,
  .e-filled.e-float-input.e-small input.e-input,
  .e-small .e-filled.e-float-input input,
  .e-small .e-filled.e-float-input input.e-input,
  .e-filled.e-float-input.e-control-wrapper.e-small input,
  .e-filled.e-float-input.e-control-wrapper.e-small input.e-input,
  .e-small .e-filled.e-float-input.e-control-wrapper input,
  .e-small .e-filled.e-float-input.e-control-wrapper input.e-input,
  .e-filled.e-float-input.e-small:not(.e-input-group) input,
  .e-filled.e-float-input.e-small:not(.e-input-group) input.e-input,
  .e-small .e-filled.e-float-input:not(.e-input-group) input,
  .e-small .e-filled.e-float-input:not(.e-input-group) input.e-input .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-input-group) input,
  .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-input-group) input.e-input,
  .e-small .e-filled.e-float-input.e-control-wrapper:not(.e-input-group) input,
  .e-small .e-filled.e-float-input.e-control-wrapper:not(.e-input-group) input.e-input,
  .e-filled textarea.e-input#{$css},
  .e-filled.e-input-group textarea,
  .e-filled.e-input-group.e-control-wrapper textarea,
  .e-filled.e-float-input textarea,
  .e-filled.e-float-input.e-control-wrapper textarea,
  .e-filled textarea.e-input.e-small#{$css},
  .e-filled.e-input-group.e-small textarea,
  .e-filled.e-input-group.e-small textarea.e-input,
  .e-small .e-filled.e-input-group textarea,
  .e-small .e-filled.e-input-group textarea.e-input,
  .e-filled.e-input-group.e-control-wrapper.e-small textarea,
  .e-filled.e-input-group.e-control-wrapper.e-small textarea.e-input,
  .e-small .e-filled.e-input-group.e-control-wrapper textarea,
  .e-small .e-filled.e-input-group.e-control-wrapper textarea.e-input,
  .e-filled.e-float-input.e-small textarea,
  .e-filled.e-float-input.e-small textarea.e-input,
  .e-small .e-filled.e-float-input textarea,
  .e-small .e-filled.e-float-input textarea.e-input,
  .e-filled.e-float-input.e-control-wrapper.e-small textarea,
  .e-filled.e-float-input.e-control-wrapper.e-small textarea.e-input,
  .e-small .e-filled.e-float-input.e-control-wrapper textarea,
  .e-small .e-filled.e-float-input.e-control-wrapper textarea.e-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      box-sizing: border-box;
    }
  }

  .e-filled.e-float-input input,
  .e-filled.e-float-input textarea,
  .e-filled.e-float-input.e-control-wrapper input,
  .e-filled.e-float-input.e-control-wrapper textarea {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      border: $float-input-border;
      border-width: $zero-value;
    }
  }

  .e-filled.e-float-input:hover:not(.e-input-group):not(.e-disabled) input:not([disabled]),
  .e-filled.e-float-input:hover:not(.e-input-group):not(.e-disabled) textarea:not([disabled]),
  .e-filled.e-float-input:hover:not(.e-input-group):not(.e-disabled) input:not([disabled]),
  .e-filled.e-float-input:hover:not(.e-input-group):not(.e-disabled) textarea:not([disabled]),
  .e-filled.e-float-input.e-control-wrapper:hover:not(.e-input-group):not(.e-disabled) input:not([disabled]),
  .e-filled.e-float-input.e-control-wrapper:hover:not(.e-input-group):not(.e-disabled) textarea:not([disabled]),
  .e-filled.e-float-input.e-control-wrapper:hover:not(.e-input-group):not(.e-disabled) input:not([disabled]),
  .e-filled.e-float-input.e-control-wrapper:hover:not(.e-input-group):not(.e-disabled) textarea:not([disabled]),
  .e-filled.e-input-group.e-float-icon-left:not(.e-disabled):not(.e-input-focus) .e-input-in-wrap:hover,
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-disabled):not(.e-input-focus) .e-input-in-wrap:hover,
  .e-filled.e-float-input.e-float-icon-left:not(.e-disabled):not(.e-input-focus) .e-input-in-wrap:hover,
  .e-filled.e-float-input.e-control-wrapper.e-float-icon-left:not(.e-disabled):not(.e-input-focus) .e-input-in-wrap:hover {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      border-bottom-width: $zero-value;
    }
  }

  .e-filled.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error) input,
  .e-filled.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled).e-success:not(.e-warning):not(.e-error) input,
  .e-filled.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success).e-warning:not(.e-error) input,
  .e-filled.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning).e-error input,
  .e-filled.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error) textarea,
  .e-filled.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled).e-success:not(.e-warning):not(.e-error) textarea,
  .e-filled.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success).e-warning:not(.e-error) textarea,
  .e-filled.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning).e-error textarea,
  .e-filled.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error) input,
  .e-filled.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled).e-success:not(.e-warning):not(.e-error) input,
  .e-filled.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success).e-warning:not(.e-error) input,
  .e-filled.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning).e-error input,
  .e-filled.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error) textarea,
  .e-filled.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled).e-success:not(.e-warning):not(.e-error) textarea,
  .e-filled.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success).e-warning:not(.e-error) textarea,
  .e-filled.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning).e-error textarea,
  .e-filled.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error) input,
  .e-filled.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error) input,
  .e-filled.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-success).e-warning:not(.e-error) input,
  .e-filled.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-success):not(.e-warning).e-error input,
  .e-filled.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error) input,
  .e-filled.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error) input,
  .e-filled.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-success).e-warning:not(.e-error) input,
  .e-filled.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-success):not(.e-warning).e-error input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      border-width: $zero-value;
    }
  }

  .e-filled.e-input-group,
  .e-filled.e-input-group.e-control-wrapper {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      border-radius: 4px 4px $zero-value $zero-value;
      padding: $filled-wrapper-padding;
    }
  }

  .e-filled.e-input-group.e-small,
  .e-filled.e-input-group.e-control-wrapper.e-small,
  .e-small .e-filled.e-input-group.e-control-wrapper {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      margin-top: $filled-wrapper-margin;
      padding: $small-filled-wrapper-padding;
    }
  }

  .e-filled.e-float-input,
  .e-filled.e-float-input.e-control-wrapper {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      border: $input-group-full-border;
      border-radius: 4px 4px $zero-value $zero-value;
      border-width: $input-group-full-border-width;
      margin-top: $filled-wrapper-margin;
      padding: $filled-float-input-wrapper-padding;
    }
  }

  .e-filled.e-float-input.e-small,
  .e-filled.e-float-input.e-control-wrapper.e-small,
  .e-small .e-filled.e-float-input.e-control-wrapper {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      margin-top: $filled-wrapper-margin;
      padding: $small-filled-float-input-wrapper-padding;
    }
  }

  .e-rtl.e-filled.e-input-group,
  .e-rtl.e-filled.e-input-group.e-control-wrapper,
  .e-rtl .e-filled.e-input-group,
  .e-rtl .e-filled.e-input-group.e-control-wrapper {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      padding: $filled-wrapper-rtl-padding;
    }
  }

  .e-rtl.e-filled.e-input-group.e-small,
  .e-rtl.e-filled.e-input-group.e-control-wrapper.e-small,
  .e-small .e-rtl.e-filled.e-input-group.e-control-wrapper,
  .e-rtl .e-filled.e-input-group.e-small,
  .e-rtl .e-filled.e-input-group.e-control-wrapper.e-small,
  .e-small.e-rtl .e-filled.e-input-group.e-control-wrapper {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      padding: $small-filled-wrapper-rtl-padding;
    }
  }

  .e-rtl.e-filled.e-float-input,
  .e-rtl.e-filled.e-float-input.e-control-wrapper,
  .e-rtl .e-filled.e-float-input,
  .e-rtl .e-filled.e-float-input.e-control-wrapper {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      padding: $filled-float-input-wrapper-rtl-padding;
    }
  }

  .e-rtl.e-filled.e-float-input.e-small,
  .e-rtl.e-filled.e-float-input.e-control-wrapper.e-small,
  .e-small .e-rtl.e-filled.e-float-input.e-control-wrapper,
  .e-rtl .e-filled.e-float-input.e-small,
  .e-rtl .e-filled.e-float-input.e-control-wrapper.e-small,
  .e-small.e-rtl .e-filled.e-float-input.e-control-wrapper {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      padding: $small-filled-float-input-wrapper-rtl-padding;
    }
  }

  .e-filled input.e-input#{$css},
  .e-filled.e-input-group input.e-input,
  .e-filled.e-input-group.e-control-wrapper input.e-input,
  .e-filled textarea.e-input#{$css},
  .e-filled.e-input-group textarea.e-input,
  .e-filled.e-input-group.e-control-wrapper textarea.e-input,
  .e-filled.e-input-group input.e-input:focus,
  .e-filled.e-input-group.e-control-wrapper input.e-input:focus,
  .e-filled.e-input-group textarea.e-input:focus,
  .e-filled.e-input-group.e-control-wrapper textarea.e-input:focus,
  .e-filled.e-input-group.e-input-focus input.e-input,
  .e-filled.e-input-group.e-control-wrapper.e-input-focus input.e-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      padding: $filled-input-padding;
    }
  }

  .e-filled .e-input#{$css}:focus {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      padding-bottom: $filled-input-padding-bottom;
    }
  }

  .e-filled .e-input.e-small#{$css}:focus {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      padding-bottom: $filled-small-input-padding-bottom;
    }
  }

  .e-filled .e-input#{$css}.e-small,
  .e-filled.e-input-group.e-small .e-input,
  .e-filled.e-input-group.e-control-wrapper.e-small .e-input,
  .e-filled.e-input-group.e-small .e-input:focus,
  .e-filled.e-input-group.e-control-wrapper.e-small .e-input:focus,
  .e-filled.e-input-group.e-small.e-input-focus .e-input,
  .e-filled.e-input-group.e-control-wrapper.e-small.e-input-focus .e-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      padding: $filled-small-input-padding;
    }
  }

  .e-filled.e-float-input input,
  .e-filled.e-float-input.e-control-wrapper input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      padding: $filled-float-input-padding;
    }
  }

  .e-filled.e-float-input.e-small input,
  .e-filled.e-float-input.e-control-wrapper.e-small input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      padding: $filled-small-float-input-padding;
    }
  }

  .e-filled input.e-input.e-rtl#{$css},
  .e-filled.e-input-group.e-rtl input.e-input,
  .e-filled.e-input-group.e-control-wrapper.e-rtl input.e-input,
  .e-rtl .e-filled.e-input-group input.e-input,
  .e-rtl .e-filled.e-input-group.e-control-wrapper input.e-input,
  .e-filled.e-input-group.e-rtl input.e-input,
  .e-filled.e-input-group.e-control-wrapper.e-rtl input.e-input,
  .e-rtl .e-filled.e-input-group input.e-input,
  .e-rtl .e-filled.e-input-group.e-control-wrapper input.e-input,
  .e-filled.e-input-group.e-rtl input.e-input:focus,
  .e-filled.e-input-group.e-control-wrapper.e-rtl input.e-input:focus,
  .e-rtl .e-filled.e-input-group input.e-input:focus,
  .e-rtl .e-filled.e-input-group.e-control-wrapper input.e-input:focus,
  .e-filled.e-input-group.e-rtl.e-input-focus input.e-input,
  .e-filled.e-input-group.e-control-wrapper.e-rtl.e-input-focus input.e-input,
  .e-rtl .e-filled.e-input-group.e-input-focus input.e-input,
  .e-rtl .e-filled.e-input-group.e-control-wrapper.e-input-focus input.e-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      padding: $filled-input-rtl-padding;
      text-indent: 0;
    }
  }

  .e-filled.e-float-input.e-rtl input,
  .e-filled.e-float-input.e-control-wrapper.e-rtl input,
  .e-rtl .e-filled.e-float-input input,
  .e-rtl .e-filled.e-float-input.e-control-wrapper input,
  .e-filled.e-float-input.e-rtl input,
  .e-filled.e-float-input.e-control-wrapper.e-rtl input,
  .e-rtl .e-filled.e-float-input input,
  .e-rtl .e-filled.e-float-input.e-control-wrapper input,
  .e-filled.e-float-input.e-rtl input:focus,
  .e-filled.e-float-input.e-control-wrapper.e-rtl input:focus,
  .e-rtl .e-filled.e-float-input input:focus,
  .e-rtl .e-filled.e-float-input.e-control-wrapper input:focus,
  .e-filled.e-float-input.e-rtl.e-input-focus input,
  .e-filled.e-float-input.e-control-wrapper.e-rtl.e-input-focus input,
  .e-rtl .e-filled.e-float-input.e-input-focus input,
  .e-rtl .e-filled.e-float-input.e-control-wrapper.e-input-focus input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      padding: $filled-float-input-rtl-padding;
      text-indent: 0;
    }
  }

  .e-rtl .e-filled input.e-input.e-small#{$css},
  .e-filled input.e-input#{$css}.e-small.e-rtl,
  .e-small.e-rtl .e-filled input.e-input#{$css},
  .e-small .e-filled input.e-input.e-rtl#{$css},
  .e-filled.e-input-group.e-small.e-rtl input.e-input,
  .e-filled.e-input-group.e-control-wrapper.e-small.e-rtl input.e-input,
  .e-rtl .e-filled.e-input-group.e-small input.e-input,
  .e-rtl .e-filled.e-input-group.e-control-wrapper.e-small input.e-input,
  .e-filled.e-input-group.e-rtl input.e-input.e-small,
  .e-filled.e-input-group.e-control-wrapper.e-rtl input.e-input.e-small,
  .e-rtl .e-filled.e-input-group input.e-input.e-small,
  .e-rtl .e-filled.e-input-group.e-control-wrapper input.e-input.e-small,
  .e-small .e-filled.e-input-group.e-rtl input.e-input,
  .e-small .e-filled.e-input-group.e-control-wrapper.e-rtl input.e-input,
  .e-small.e-rtl .e-filled.e-input-group.e-control-wrapper input.e-input,
  .e-small.e-rtl .e-filled.e-input-group input.e-input,
  .e-small.e-rtl .e-filled.e-input-group.e-control-wrapper input.e-input:focus,
  .e-small.e-rtl .e-filled.e-input-group input.e-input:focus,
  .e-small .e-filled.e-input-group.e-control-wrapper.e-rtl input.e-input:focus,
  .e-small .e-filled.e-input-group.e-rtl input.e-input:focus,
  .e-small.e-rtl .e-filled.e-input-group.e-control-wrapper.e-input-focus input.e-input,
  .e-small.e-rtl .e-filled.e-input-group.e-input-focus input.e-input,
  .e-small .e-filled.e-input-group.e-control-wrapper.e-rtl.e-input-focus input.e-input,
  .e-small .e-filled.e-input-group.e-rtl.e-input-focus input.e-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      padding: $filled-small-input-rtl-padding;
      text-indent: 0;
    }
  }

  .e-filled.e-float-input.e-control-wrapper.e-small.e-rtl input,
  .e-filled.e-float-input.e-small.e-rtl input,
  .e-rtl .e-filled.e-float-input.e-small input,
  .e-rtl .e-filled.e-float-input.e-control-wrapper.e-small input,
  .e-filled.e-float-input.e-rtl input.e-small,
  .e-filled.e-float-input.e-control-wrapper.e-rtl input.e-small,
  .e-rtl .e-filled.e-float-input input.e-small,
  .e-rtl .e-filled.e-float-input.e-control-wrapper input.e-small,
  .e-small .e-filled.e-float-input.e-rtl input,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-rtl input,
  .e-small.e-rtl .e-filled.e-float-input.e-control-wrapper input,
  .e-small.e-rtl .e-filled.e-float-input input,
  .e-small.e-rtl .e-filled.e-float-input.e-control-wrapper input:focus,
  .e-small.e-rtl .e-filled.e-float-input input:focus,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-rtl input:focus,
  .e-small .e-filled.e-float-input.e-rtl input:focus,
  .e-small.e-rtl .e-filled.e-float-input.e-control-wrapper.e-input-focus input,
  .e-small.e-rtl .e-filled.e-float-input.e-input-focus input,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-rtl.e-input-focus input,
  .e-small .e-filled.e-float-input.e-rtl.e-input-focus input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      padding: $filled-small-float-input-rtl-padding;
      text-indent: 0;
    }
  }

  .e-filled.e-float-input,
  .e-filled.e-float-input.e-control-wrapper,
  .e-filled.e-float-input.e-disabled,
  .e-filled.e-float-input.e-control-wrapper.e-disabled,
  .e-filled.e-float-input.e-input-group.e-disabled,
  .e-filled.e-float-input.e-input-group.e-control-wrapper.e-disabled {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      font-size: $filled-input-font-size;
    }
  }

  .e-filled.e-float-input.e-small,
  .e-small .e-filled.e-float-input,
  .e-filled.e-float-input.e-control-wrapper.e-small,
  .e-small .e-filled.e-float-input.e-control-wrapper,
  .e-filled.e-float-input.e-small.e-disabled,
  .e-small .e-filled.e-float-input.e-disabled,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-disabled,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-disabled,
  .e-filled.e-float-input.e-input-group.e-small.e-disabled,
  .e-small .e-filled.e-float-input.e-input-group.e-disabled,
  .e-filled.e-float-input.e-input-group.e-control-wrapper.e-small.e-disabled,
  .e-small .e-filled.e-float-input.e-input-group.e-control-wrapper.e-disabled {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      font-size: $small-filled-input-font-size;
    }
  }

  .e-filled.e-input-group.e-small:not(.e-float-input) .e-input,
  .e-small .e-filled.e-input-group:not(.e-float-input) .e-input,
  .e-filled.e-input-group.e-control-wrapper.e-small:not(.e-float-input) .e-input,
  .e-small .e-filled.e-input-group.e-control-wrapper:not(.e-float-input) .e-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      min-height: $small-filled-default-input-min-height;
    }
  }

  .e-filled.e-float-input.e-small input,
  .e-small .e-filled.e-float-input input,
  .e-filled.e-float-input.e-input-group.e-small input,
  .e-small .e-filled.e-float-input.e-input-group input,
  .e-filled.e-float-input.e-input-group.e-control-wrapper.e-small input,
  .e-small .e-filled.e-float-input.e-input-group.e-control-wrapper input,
  .e-filled.e-float-input.e-control-wrapper.e-small input,
  .e-small .e-filled.e-float-input.e-control-wrapper input,
  .e-filled.e-float-input.e-control-wrapper.e-input-group.e-small input,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-input-group input,
  .e-filled.e-float-input.e-control-wrapper.e-input-group.e-small input,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-input-group input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      min-height: $small-filled-input-min-height;
    }
  }

  .e-filled.e-input-group input.e-input,
  .e-filled.e-input-group.e-control-wrapper input.e-input,
  .e-filled.e-input-group:not(.e-float-input) input.e-input,
  .e-filled.e-input-group:not(.e-float-input).e-control-wrapper input.e-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      min-height: $filled-default-input-min-height;
    }
  }

  .e-float-input.e-filled.e-input-group.e-control-wrapper input,
  .e-float-input.e-filled input,
  .e-float-input.e-filled.e-control-wrapper input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      min-height: $filled-input-min-height;
    }
  }

  .e-filled label.e-float-text,
  .e-filled.e-float-input label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper label.e-float-text,
  .e-filled.e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      font-size: $filled-float-label-font-size;
      left: $filled-input-label-left;
      letter-spacing: .009375em;
      line-height: 1.15;
      padding-left: $float-label-padding;
      pointer-events: none;
      right: auto;
      top: $filled-input-label-top;
      transform: none;
      transition: transform 150ms cubic-bezier(.4, $zero-value, .2, 1), color 150ms cubic-bezier(.4, $zero-value, .2, 1);
      width: 100%;
    }
  }

  .e-filled.e-float-input textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      font-size: $filled-float-label-font-size;
      padding-left: $float-label-padding;
      top: $filled-input-label-top;
      transform: none;
      width: 100%;
    }
  }

  .e-filled.e-float-input input:focus ~ label.e-float-text,
  .e-filled.e-float-input input:valid ~ label.e-float-text,
  .e-filled.e-float-input input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input input label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper input label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-input-focus input ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      font-size: $filled-float-label-font-size;
      top: $filled-input-label-top-after-floating;
      transform: translateY(-50%) scale(.75);
    }
    @if $input-skin-name == 'fluent2' {
      left: 1px;
    }
  }

  .e-filled.e-float-input textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input textarea ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input textarea label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper textarea ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper textarea label.e-float-text.e-label-top {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      font-size: $filled-float-label-font-size;
      top: $filled-input-label-top-after-floating;
      transform: translateY(-50%) scale(.75);
    }
  }

  .e-filled.e-float-input input:-webkit-autofill ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-autofill input:-webkit-autofill ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-autofill:not(.e-input-focus) input:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input textarea:-webkit-autofill ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-autofill textarea:-webkit-autofill ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-autofill textarea:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      font-size: $filled-float-label-font-size;
      top: $filled-input-label-top-after-floating;
      transform: translateY(-50%) scale(.75);
      user-select: text;
    }
  }

  .e-filled.e-float-input.e-small input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-small input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-small input ~ label.e-label-top.e-float-text,
  .e-small .e-filled.e-float-input input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small input ~ label.e-label-top.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-small textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-small textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-small textarea ~ label.e-label-top.e-float-text,
  .e-small .e-filled.e-float-input textarea ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small textarea ~ label.e-label-top.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper textarea ~ label.e-label-top.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      font-size: $small-filled-float-label-font-size;
      top: $small-filled-input-label-top-after-floating;
    }
  }

  .e-small .e-filled.e-float-input input:-webkit-autofill ~ label.e-float-text,
  .e-small.e-filled.e-float-input input:-webkit-autofill ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-autofill input:-webkit-autofill ~ label.e-float-text,
  .e-small.e-filled.e-float-input.e-control-wrapper.e-autofill input:-webkit-autofill ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-autofill:not(.e-input-focus) input:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-small.e-filled.e-float-input.e-control-wrapper.e-autofill:not(.e-input-focus) input:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input textarea:-webkit-autofill ~ label.e-float-text,
  .e-small.e-filled.e-float-input textarea:-webkit-autofill ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-autofill textarea:-webkit-autofill ~ label.e-float-text,
  .e-small.e-filled.e-float-input.e-control-wrapper.e-autofill textarea:-webkit-autofill ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-autofill textarea:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-small.e-filled.e-float-input.e-control-wrapper.e-autofill textarea:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      font-size: $small-filled-float-label-font-size;
      top: $small-filled-input-label-top-after-floating;
      transform: translateY(-50%) scale(.75);
      user-select: text;
    }
  }

  .e-filled.e-float-input.e-small label.e-float-text,
  .e-filled.e-float-input.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-small label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-small textarea ~ label.e-float-text,
  .e-filled.e-float-input textarea ~ label.e-float-text.e-small,
  .e-filled.e-float-input textarea.e-small ~ label.e-float-text,
  .e-small .e-filled.e-float-input textarea ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small textarea ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper textarea ~ label.e-float-text.e-small,
  .e-filled.e-float-input.e-control-wrapper textarea.e-small ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper textarea ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      font-size: $small-filled-float-label-font-size;
      top: $small-filled-input-label-top;
    }
  }

  .e-filled.e-float-input label.e-float-text,
  .e-filled.e-float-input label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper label.e-float-text.e-label-bottom,
  .e-filled.e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      left: $filled-input-label-left;
    }
  }

  .e-filled.e-float-input.e-rtl label.e-float-text,
  .e-filled.e-float-input.e-rtl label.e-float-text.e-label-bottom,
  .e-rtl .e-filled.e-float-input label.e-float-text .e-filled.e-float-input.e-control-wrapper.e-rtl label.e-float-text,
  .e-filled.e-float-input.e-rtl.e-control-wrapper label.e-float-text.e-label-bottom,
  .e-rtl .e-filled.e-float-input.e-control-wrapper label.e-float-text,
  .e-filled.e-float-input.e-rtl:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-rtl:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl .e-filled.e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-rtl:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-rtl:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl .e-filled.e-float-input:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      right: $filled-input-label-left;
    }
  }

  .e-filled.e-float-input.e-small label.e-float-text,
  .e-filled.e-float-input.e-small label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-small label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input.e-control-wrapper label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-small:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      left: $small-filled-input-label-left;
    }
  }

  .e-filled.e-float-input.e-small.e-rtl label.e-float-text,
  .e-filled.e-float-input.e-rtl.e-small label.e-float-text.e-label-bottom,
  .e-rtl .e-filled.e-float-input.e-small label.e-float-text .e-filled.e-float-input.e-control-wrapper.e-rtl.e-small label.e-float-text,
  .e-filled.e-float-input.e-rtl.e-control-wrapper.e-small label.e-float-text.e-label-bottom,
  .e-rtl .e-filled.e-float-input.e-control-wrapper.e-small label.e-float-text,
  .e-small .e-filled.e-float-input.e-rtl label.e-float-text,
  .e-small .e-filled.e-float-input.e-rtl label.e-float-text.e-label-bottom,
  .e-rtl.e-small .e-filled.e-float-input label.e-float-text .e-small .e-filled.e-float-input.e-control-wrapper.e-rtl label.e-float-text,
  .e-small .e-filled.e-float-input.e-rtl.e-control-wrapper label.e-float-text.e-label-bottom,
  .e-rtl.e-small .e-filled.e-float-input.e-control-wrapper label.e-float-text,
  .e-small.e-filled.e-float-input.e-rtl:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small.e-filled.e-float-input.e-control-wrapper.e-rtl:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl .e-small.e-filled.e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl .e-small.e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input.e-rtl:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-rtl:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl.e-small .e-filled.e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl.e-small .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small.e-filled.e-float-input.e-rtl:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small.e-filled.e-float-input.e-control-wrapper.e-rtl:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl .e-small.e-filled.e-float-input:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl .e-small.e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input.e-rtl:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-rtl:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl.e-small .e-filled.e-float-input:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl.e-small .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      right: $small-filled-input-label-left;
    }
  }

  .e-filled.e-float-input .e-float-line,
  .e-float-input.e-filled.e-control-wrapper .e-float-line {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      bottom: -1px;
      position: absolute;
    }
  }

  .e-float-input.e-filled .e-float-line,
  .e-float-input.e-filled .e-float-text,
  .e-float-input.e-filled.e-control-wrapper .e-float-line,
  .e-float-input.e-filled.e-control-wrapper .e-float-text,
  .e-filled.e-float-input.e-rtl .e-input-in-wrap label.e-float-text,
  .e-filled.e-float-input.e-rtl .e-input-in-wrap label.e-float-text.e-label-bottom,
  .e-rtl .e-filled.e-float-input .e-input-in-wrap label.e-float-text .e-filled.e-float-input.e-control-wrapper.e-rtl .e-input-in-wrap label.e-float-text,
  .e-filled.e-float-input.e-rtl.e-control-wrapper .e-input-in-wrap label.e-float-text.e-label-bottom,
  .e-rtl .e-filled.e-float-input.e-control-wrapper .e-input-in-wrap label.e-float-text,
  .e-filled.e-float-input.e-rtl:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-rtl:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl .e-filled.e-float-input:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-small.e-rtl .e-input-in-wrap label.e-float-text,
  .e-filled.e-float-input.e-rtl.e-small .e-input-in-wrap label.e-float-text.e-label-bottom,
  .e-rtl .e-filled.e-float-input.e-small .e-input-in-wrap label.e-float-text .e-filled.e-float-input.e-control-wrapper.e-rtl.e-small .e-input-in-wrap label.e-float-text,
  .e-filled.e-float-input.e-rtl.e-control-wrapper.e-small .e-input-in-wrap label.e-float-text.e-label-bottom,
  .e-rtl .e-filled.e-float-input.e-control-wrapper.e-small .e-input-in-wrap label.e-float-text,
  .e-small .e-filled.e-float-input.e-rtl .e-input-in-wrap label.e-float-text,
  .e-small .e-filled.e-float-input.e-rtl .e-input-in-wrap label.e-float-text.e-label-bottom,
  .e-rtl.e-small .e-filled.e-float-input .e-input-in-wrap label.e-float-text .e-small .e-filled.e-float-input.e-control-wrapper.e-rtl .e-input-in-wrap label.e-float-text,
  .e-small .e-filled.e-float-input.e-rtl.e-control-wrapper .e-input-in-wrap label.e-float-text.e-label-bottom,
  .e-rtl.e-small .e-filled.e-float-input.e-control-wrapper .e-input-in-wrap label.e-float-text,
  .e-small.e-filled.e-float-input.e-rtl:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small.e-filled.e-float-input.e-control-wrapper.e-rtl:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl .e-small.e-filled.e-float-input:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl .e-small.e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input.e-rtl:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-rtl:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl.e-small .e-filled.e-float-input:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl.e-small .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      right: $zero-value;
    }
  }

  .e-filled.e-input-group:not(.e-disabled):not(.e-float-icon-left)::before,
  .e-filled.e-filled.e-input-group:not(.e-disabled):not(.e-float-icon-left)::after,
  .e-filled.e-input-group.e-control-wrapper:not(.e-disabled):not(.e-float-icon-left)::before,
  .e-filled.e-input-group.e-control-wrapper:not(.e-disabled):not(.e-float-icon-left)::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      bottom: -1px;
    }
    @if $input-skin-name == 'fluent2' {
      bottom: -.1px;
    }
  }

  .e-filled.e-input-group .e-input-group-icon,
  .e-filled.e-input-group.e-control-wrapper .e-input-group-icon,
  .e-filled.e-input-group.e-small .e-input-group-icon,
  .e-filled.e-input-group.e-control-wrapper.e-small .e-input-group-icon,
  .e-small .e-filled.e-input-group .e-input-group-icon,
  .e-small .e-filled.e-input-group.e-control-wrapper .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      font-size: $filled-input-icon-size;
      margin-bottom: $zero-value;
      margin-top: $zero-value;
      min-height: $filled-input-icon-min-height;
      min-width: $filled-input-icon-min-height;
      padding: $zero-value $zero-value $zero-value 8px;
    }
  }

  .e-rtl.e-filled.e-input-group .e-input-group-icon,
  .e-rtl.e-filled.e-input-group.e-control-wrapper .e-input-group-icon,
  .e-rtl.e-filled.e-input-group.e-small .e-input-group-icon,
  .e-rtl.e-filled.e-input-group.e-control-wrapper.e-small .e-input-group-icon,
  .e-small .e-rtl.e-filled.e-input-group .e-input-group-icon,
  .e-small .e-rtl.e-filled.e-input-group.e-control-wrapper .e-input-group-icon,
  .e-rtl .e-filled.e-input-group .e-input-group-icon,
  .e-rtl .e-filled.e-input-group.e-control-wrapper .e-input-group-icon,
  .e-rtl .e-filled.e-input-group.e-small .e-input-group-icon,
  .e-rtl .e-filled.e-input-group.e-control-wrapper.e-small .e-input-group-icon,
  .e-small.e-rtl .e-filled.e-input-group .e-input-group-icon,
  .e-small.e-rtl .e-filled.e-input-group.e-control-wrapper .e-input-group-icon,
  .e-rtl.e-filled.e-float-input.e-input-group .e-input-group-icon,
  .e-rtl.e-filled.e-float-input.e-control-wrapper.e-input-group .e-input-group-icon,
  .e-rtl.e-filled.e-float-input.e-input-group.e-small .e-input-group-icon,
  .e-small .e-rtl.e-filled.e-float-input.e-input-group .e-input-group-icon,
  .e-rtl.e-filled.e-float-input.e-control-wrapper.e-input-group.e-small .e-input-group-icon,
  .e-small .e-rtl.e-filled.e-float-input.e-control-wrapper.e-input-group .e-input-group-icon,
  .e-rtl .e-filled.e-float-input.e-input-group .e-input-group-icon,
  .e-rtl .e-filled.e-float-input.e-control-wrapper.e-input-group .e-input-group-icon,
  .e-rtl .e-filled.e-float-input.e-input-group.e-small .e-input-group-icon,
  .e-small.e-rtl .e-filled.e-float-input.e-input-group .e-input-group-icon,
  .e-rtl .e-filled.e-float-input.e-control-wrapper.e-input-group.e-small .e-input-group-icon,
  .e-small.e-rtl .e-filled.e-float-input.e-control-wrapper.e-input-group .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      padding: $zero-value 8px $zero-value $zero-value;
    }
  }

  .e-filled.e-input-group.e-small .e-input-group-icon,
  .e-filled.e-input-group.e-control-wrapper.e-small .e-input-group-icon,
  .e-small .e-filled.e-input-group .e-input-group-icon,
  .e-small .e-filled.e-input-group.e-control-wrapper .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      font-size: $small-filled-input-icon-size;
      min-height: $small-filled-input-icon-min-height;
      min-width: $small-filled-input-icon-min-height;
      padding: $zero-value $zero-value $zero-value 4px;
    }
  }

  .e-rtl.e-filled.e-input-group.e-small .e-input-group-icon,
  .e-rtl.e-filled.e-input-group.e-control-wrapper.e-small .e-input-group-icon,
  .e-small .e-rtl.e-filled.e-input-group .e-input-group-icon,
  .e-small .e-rtl.e-filled.e-input-group.e-control-wrapper .e-input-group-icon,
  .e-rtl .e-filled.e-input-group.e-small .e-input-group-icon,
  .e-rtl .e-filled.e-input-group.e-control-wrapper.e-small .e-input-group-icon,
  .e-small.e-rtl .e-filled.e-input-group .e-input-group-icon,
  .e-small.e-rtl .e-filled.e-input-group.e-control-wrapper .e-input-group-icon,
  .e-rtl.e-filled.e-float-input.e-input-group.e-small .e-input-group-icon,
  .e-small .e-rtl.e-filled.e-float-input.e-input-group .e-input-group-icon,
  .e-rtl.e-filled.e-float-input.e-control-wrapper.e-input-group.e-small .e-input-group-icon,
  .e-small .e-rtl.e-filled.e-float-input.e-control-wrapper.e-input-group .e-input-group-icon,
  .e-rtl .e-filled.e-float-input.e-input-group.e-small .e-input-group-icon,
  .e-small.e-rtl .e-filled.e-float-input.e-input-group .e-input-group-icon,
  .e-rtl .e-filled.e-float-input.e-control-wrapper.e-input-group.e-small .e-input-group-icon,
  .e-small.e-rtl .e-filled.e-float-input.e-control-wrapper.e-input-group .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      padding: $zero-value 4px $zero-value $zero-value;
    }
  }

  .e-filled.e-float-input.e-input-group .e-input-group-icon,
  .e-filled.e-float-input.e-control-wrapper.e-input-group .e-input-group-icon,
  .e-filled.e-float-input.e-input-group.e-small .e-input-group-icon,
  .e-small .e-filled.e-float-input.e-input-group .e-input-group-icon,
  .e-filled.e-float-input.e-control-wrapper.e-input-group.e-small .e-input-group-icon,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-input-group .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      font-size: $filled-input-icon-size;
      margin-bottom: $zero-value;
      margin-top: $zero-value;
      padding: 8px;
      @if $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
        padding: 0;
        margin: 9px 12px 9px 0;
      }
    }
  }

  .e-filled.e-float-input.e-input-group.e-small .e-input-group-icon,
  .e-small .e-filled.e-float-input.e-input-group .e-input-group-icon,
  .e-filled.e-float-input.e-control-wrapper.e-input-group.e-small .e-input-group-icon,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-input-group .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      font-size: $small-filled-input-icon-size;
      padding: $zero-value $zero-value $zero-value 4px;
    }
  }

  .e-filled.e-float-input .e-clear-icon,
  .e-filled.e-float-input.e-control-wrapper .e-clear-icon,
  .e-filled.e-input-group .e-clear-icon,
  .e-filled.e-input-group.e-control-wrapper .e-clear-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      font-size: $filled-input-clear-icon-size;
      padding: $zero-value $zero-value $zero-value 8px;
    }
    @if $input-skin-name == 'fluent2' {
      font-size: $filled-input-clear-icon-size;
      padding: $input-clear-icon-padding;
    }
  }

  .e-filled.e-input-group .e-clear-icon,
  .e-filled.e-input-group.e-control-wrapper .e-clear-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      min-height: $filled-input-icon-min-height;
      min-width: $filled-input-icon-min-height;
      padding: $zero-value $zero-value $zero-value 8px;
    }
    @if $input-skin-name == 'fluent2' {
      min-height: $filled-input-icon-min-height;
      min-width: $filled-input-icon-min-height;
      padding: $input-clear-icon-padding;
    }
  }

  .e-filled.e-float-input.e-input-group .e-clear-icon,
  .e-filled.e-float-input.e-input-group.e-control-wrapper .e-clear-icon {
    @if $input-skin-name == 'material' {
      padding: $zero-value $zero-value $zero-value 8px;
    }
    @if $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      padding: $zero-value;
      margin: 2px;
      height: 32px;
    }
  }

  .e-filled.e-input-group.e-small .e-clear-icon,
  .e-filled.e-input-group .e-clear-icon.e-small,
  .e-small .e-filled.e-input-group .e-clear-icon,
  .e-filled.e-input-group.e-control-wrapper.e-small .e-clear-icon,
  .e-filled.e-input-group.e-control-wrapper .e-clear-icon.e-small,
  .e-small .e-filled.e-input-group.e-control-wrapper .e-clear-icon {
    @if $input-skin-name == 'material' {
      font-size: $small-filled-input-clear-icon-size;
      min-height: $small-filled-input-icon-min-height;
      min-width: $small-filled-input-icon-min-height;
      padding: $zero-value $zero-value $zero-value 4px;
    }
    @if $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      font-size: $small-filled-input-clear-icon-size;
      min-height: $small-filled-input-icon-min-height;
      min-width: $small-filled-input-icon-min-height;
      padding: $zero-value;
    }
  }

  .e-filled.e-input-group.e-float-input.e-small .e-clear-icon,
  .e-filled.e-input-group.e-float-input .e-clear-icon.e-small,
  .e-small .e-filled.e-input-group.e-float-input .e-clear-icon,
  .e-filled.e-input-group.e-control-wrapper.e-float-input.e-small .e-clear-icon,
  .e-filled.e-input-group.e-control-wrapper.e-float-input .e-clear-icon.e-small,
  .e-small .e-filled.e-input-group.e-control-wrapper.e-float-input .e-clear-icon,
  .e-filled.e-input-group.e-float-input.e-control-wrapper.e-small .e-clear-icon,
  .e-filled.e-input-group.e-float-input.e-control-wrapper .e-clear-icon.e-small,
  .e-small .e-filled.e-input-group.e-float-input.e-control-wrapper .e-clear-icon,
  .e-filled.e-input-group.e-control-wrapper.e-float-input.e-small .e-clear-icon,
  .e-filled.e-input-group.e-control-wrapper.e-float-input .e-clear-icon.e-small,
  .e-small .e-filled.e-input-group.e-control-wrapper.e-float-input .e-clear-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      padding: $zero-value $zero-value $zero-value 4px;
    }
  }

  .e-rtl.e-filled.e-input-group .e-clear-icon,
  .e-rtl.e-filled.e-input-group.e-control-wrapper .e-clear-icon,
  .e-rtl.e-filled.e-input-group.e-control-wrapper.e-small .e-clear-icon,
  .e-small .e-rtl.e-filled.e-input-group .e-clear-icon,
  .e-small .e-rtl.e-filled.e-input-group.e-control-wrapper .e-clear-icon,
  .e-rtl .e-filled.e-input-group .e-clear-icon,
  .e-rtl .e-filled.e-input-group.e-control-wrapper .e-clear-icon,
  .e-rtl .e-filled.e-input-group.e-small .e-clear-icon,
  .e-rtl .e-filled.e-input-group.e-control-wrapper.e-small .e-clear-icon,
  .e-small.e-rtl .e-filled.e-input-group .e-clear-icon,
  .e-small.e-rtl .e-filled.e-input-group.e-control-wrapper .e-clear-icon,
  .e-rtl.e-filled.e-float-input.e-input-group .e-clear-icon,
  .e-rtl.e-filled.e-float-input.e-control-wrapper.e-input-group .e-clear-icon,
  .e-rtl.e-filled.e-float-input.e-input-group.e-small .e-clear-icon,
  .e-small .e-rtl.e-filled.e-float-input.e-input-group .e-clear-icon,
  .e-rtl.e-filled.e-float-input.e-control-wrapper.e-input-group.e-small .e-clear-icon,
  .e-small .e-rtl.e-filled.e-float-input.e-control-wrapper.e-input-group .e-clear-icon,
  .e-rtl .e-filled.e-float-input.e-input-group .e-clear-icon,
  .e-rtl .e-filled.e-float-input.e-control-wrapper.e-input-group .e-clear-icon,
  .e-rtl .e-filled.e-float-input.e-input-group.e-small .e-clear-icon,
  .e-small.e-rtl .e-filled.e-float-input.e-input-group .e-clear-icon,
  .e-rtl .e-filled.e-float-input.e-control-wrapper.e-input-group.e-small .e-clear-icon,
  .e-small.e-rtl .e-filled.e-float-input.e-control-wrapper.e-input-group .e-clear-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      padding: $zero-value 8px $zero-value $zero-value;
    }
  }

  .e-rtl.e-filled.e-input-group.e-small .e-clear-icon,
  .e-rtl.e-filled.e-input-group.e-control-wrapper.e-small .e-clear-icon,
  .e-small .e-rtl.e-filled.e-input-group .e-clear-icon,
  .e-small .e-rtl.e-filled.e-input-group.e-control-wrapper .e-clear-icon,
  .e-rtl .e-filled.e-input-group.e-small .e-clear-icon,
  .e-rtl .e-filled.e-input-group.e-control-wrapper.e-small .e-clear-icon,
  .e-small.e-rtl .e-filled.e-input-group .e-clear-icon,
  .e-small.e-rtl .e-filled.e-input-group.e-control-wrapper .e-clear-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      padding: $zero-value 4px $zero-value $zero-value;
    }
  }

  .e-rtl.e-filled.e-float-input.e-input-group.e-small .e-clear-icon,
  .e-small .e-rtl.e-filled.e-float-input.e-input-group .e-clear-icon,
  .e-rtl.e-filled.e-float-input.e-control-wrapper.e-input-group.e-small .e-clear-icon,
  .e-small .e-rtl.e-filled.e-float-input.e-control-wrapper.e-input-group .e-clear-icon,
  .e-rtl .e-filled.e-float-input.e-input-group.e-small .e-clear-icon,
  .e-small.e-rtl .e-filled.e-float-input.e-input-group .e-clear-icon,
  .e-rtl .e-filled.e-float-input.e-control-wrapper.e-input-group.e-small .e-clear-icon,
  .e-small.e-rtl .e-filled.e-float-input.e-control-wrapper.e-input-group .e-clear-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      padding: $zero-value 4px $zero-value $zero-value;
    }
    @if $input-skin-name == 'fluent2' {
      padding: $zero-value;
    }
  }

  .e-filled.e-float-input .e-clear-icon::before,
  .e-filled.e-float-input.e-control-wrapper .e-clear-icon::before,
  .e-filled.e-input-group .e-clear-icon::before,
  .e-filled.e-input-group.e-control-wrapper .e-clear-icon::before {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      font-size: $filled-input-clear-icon-size;
    }
  }

  .e-filled.e-float-input.e-small .e-clear-icon::before,
  .e-filled.e-float-input.e-control-wrapper.e-small .e-clear-icon::before,
  .e-filled.e-input-group.e-small .e-clear-icon::before,
  .e-filled.e-input-group.e-control-wrapper.e-small .e-clear-icon::before,
  .e-filled.e-float-input.e-control-wrapper input.e-small:first-child ~ .e-clear-icon::before,
  .e-small .e-filled.e-float-input.e-control-wrapper .e-clear-icon::before,
  .e-filled.e-float-input input.e-small:first-child ~ .e-clear-icon::before,
  .e-small .e-filled.e-float-input .e-clear-icon::before,
  .e-small .e-filled.e-input-group .e-clear-icon::before,
  .e-small .e-filled.e-input-group.e-control-wrapper .e-clear-icon::before {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      font-size: $small-filled-input-clear-icon-size;
    }
  }

  .e-filled.e-float-input .e-input-in-wrap input:focus ~ label.e-float-text,
  .e-filled.e-float-input .e-input-in-wrap input:valid ~ label.e-float-text,
  .e-filled.e-float-input .e-input-in-wrap input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input .e-input-in-wrap input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input .e-input-in-wrap input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input .e-input-in-wrap input label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper .e-input-in-wrap input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper .e-input-in-wrap input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper .e-input-in-wrap input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper .e-input-in-wrap input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper .e-input-in-wrap input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper .e-input-in-wrap input label.e-float-text.e-label-top {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      top: $filled-input-label-top-after-floating;
    }
  }

  .e-filled.e-float-input.e-small .e-input-in-wrap input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-small .e-input-in-wrap input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-small .e-input-in-wrap input ~ label.e-label-top.e-float-text,
  .e-small .e-filled.e-float-input .e-input-in-wrap input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small .e-input-in-wrap input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small .e-input-in-wrap input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small .e-input-in-wrap input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small .e-input-in-wrap input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small .e-input-in-wrap input ~ label.e-label-top.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper .e-input-in-wrap input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small .e-input-in-wrap input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small .e-input-in-wrap input[disabled] ~ label.e-label-top.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      top: $small-filled-input-label-top-after-floating;
    }
  }

  .e-filled.e-input-group.e-float-icon-left.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap,
  .e-filled.e-input-group.e-float-icon-left.e-input-focus.e-success:not(.e-warning):not(.e-error) .e-input-in-wrap,
  .e-filled.e-input-group.e-float-icon-left.e-input-focus.e-warning:not(.e-success):not(.e-error) .e-input-in-wrap,
  .e-filled.e-input-group.e-float-icon-left.e-input-focus.e-error:not(.e-success):not(.e-warning) .e-input-in-wrap,
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap,
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left.e-input-focus.e-success:not(.e-warning):not(.e-error) .e-input-in-wrap,
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left.e-input-focus.e-warning:not(.e-success):not(.e-error) .e-input-in-wrap,
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left.e-input-focus.e-error:not(.e-success):not(.e-warning) .e-input-in-wrap {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      border-style: none;
      border-width: $zero-value;
    }
  }

  .e-filled.e-float-input .e-input-in-wrap label.e-float-text,
  .e-filled.e-float-input:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper .e-input-in-wrap label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      top: $filled-input-label-top;
    }
  }

  .e-filled.e-float-input.e-small .e-input-in-wrap label.e-float-text,
  .e-small .e-filled.e-float-input .e-input-in-wrap label.e-float-text,
  .e-filled.e-float-input.e-small:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-small .e-input-in-wrap label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper .e-input-in-wrap label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      top: $small-filled-input-label-top;
    }
  }

  .e-filled.e-input-group:not(.e-float-input).e-float-icon-left > .e-input-group-icon,
  .e-filled.e-input-group:not(.e-float-input).e-control-wrapper.e-float-icon-left > .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      font-size: $filled-input-icon-size;
      margin: $zero-value;
      min-height: $filled-input-left-icon-min-height;
      min-width: $filled-input-left-icon-min-width;
      padding: $zero-value;
    }
  }

  .e-filled.e-input-group:not(.e-float-input).e-small.e-float-icon-left > .e-input-group-icon,
  .e-filled.e-input-group:not(.e-float-input).e-float-icon-left > .e-input-group-icon.e-small,
  .e-filled.e-input-group:not(.e-float-input).e-control-wrapper.e-small.e-float-icon-left > .e-input-group-icon,
  .e-filled.e-input-group:not(.e-float-input).e-control-wrapper.e-float-icon-left > .e-input-group-icon.e-small,
  .e-small .e-filled.e-input-group:not(.e-float-input).e-float-icon-left > .e-input-group-icon,
  .e-small .e-filled.e-input-group:not(.e-float-input).e-control-wrapper.e-float-icon-left > .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      font-size: $small-filled-input-icon-size;
      margin: $zero-value;
      min-height: $small-filled-input-left-icon-min-height;
      min-width: $small-filled-input-left-icon-min-width;
      padding: $zero-value;
    }
  }

  .e-filled.e-input-group.e-float-icon-left > .e-input-group-icon,
  .e-filled.e-float-input.e-input-group.e-float-icon-left > .e-input-group-icon,
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left > .e-input-group-icon,
  .e-filled.e-float-input.e-input-group.e-control-wrapper.e-float-icon-left > .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      margin: $zero-value;
      min-height: $filled-input-left-icon-min-height;
      min-width: $filled-input-left-icon-min-width;
      padding: $zero-value;
    }
  }

  .e-filled.e-input-group.e-small.e-float-icon-left > .e-input-group-icon,
  .e-filled.e-input-group.e-float-icon-left > .e-input-group-icon.e-small,
  .e-filled.e-input-group.e-control-wrapper.e-small.e-float-icon-left > .e-input-group-icon,
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left > .e-input-group-icon.e-small,
  .e-small .e-filled.e-input-group.e-float-icon-left > .e-input-group-icon,
  .e-small .e-filled.e-input-group.e-control-wrapper.e-float-icon-left > .e-input-group-icon,
  .e-filled.e-float-input.e-input-group.e-small.e-float-icon-left > .e-input-group-icon,
  .e-filled.e-float-input.e-input-group.e-float-icon-left > .e-input-group-icon.e-small,
  .e-small .e-filled.e-float-input.e-input-group.e-float-icon-left > .e-input-group-icon,
  .e-filled.e-float-input.e-control-wrapper.e-input-group.e-small.e-float-icon-left > .e-input-group-icon,
  .e-filled.e-float-input.e-control-wrapper.e-input-group.e-float-icon-left > .e-input-group-icon.e-small,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-input-group.e-float-icon-left > .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      margin: $zero-value;
      min-height: $small-filled-input-left-icon-min-height;
      min-width: $small-filled-input-left-icon-min-width;
      padding: $zero-value;
    }
  }

  .e-filled.e-float-input .e-input-in-wrap label.e-float-text,
  .e-filled.e-float-input .e-input-in-wrap label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper .e-input-in-wrap label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper .e-input-in-wrap label.e-float-text.e-label-bottom,
  .e-filled.e-float-input:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-small .e-input-in-wrap label.e-float-text,
  .e-filled.e-float-input.e-small .e-input-in-wrap label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-small .e-input-in-wrap label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small .e-input-in-wrap label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-small:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input .e-input-in-wrap label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input.e-control-wrapper .e-input-in-wrap label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper .e-input-in-wrap label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      left: $zero-value;
    }
  }

  .e-filled.e-input-group .e-input-group-icon:last-child,
  .e-filled.e-input-group.e-small .e-input-group-icon:last-child,
  .e-small .e-filled.e-input-group .e-input-group-icon:last-child,
  .e-filled.e-input-group.e-control-wrapper .e-input-group-icon:last-child,
  .e-filled.e-input-group.e-small.e-control-wrapper .e-input-group-icon:last-child,
  .e-small .e-filled.e-input-group.e-control-wrapper .e-input-group-icon:last-child,
  .e-filled.e-input-group .e-input-group-icon,
  .e-filled.e-input-group.e-control-wrapper .e-input-group-icon,
  .e-filled.e-input-group.e-small .e-input-group-icon,
  .e-filled.e-input-group.e-control-wrapper.e-small .e-input-group-icon,
  .e-small .e-filled.e-input-group .e-input-group-icon,
  .e-small .e-filled.e-input-group.e-control-wrapper .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      margin-right: $zero-value;
    }
  }

  .e-filled.e-input-group.e-rtl .e-input-group-icon:last-child,
  .e-filled.e-input-group.e-small.e-rtl .e-input-group-icon:last-child,
  .e-small .e-filled.e-input-group.e-rtl .e-input-group-icon:last-child,
  .e-filled.e-input-group.e-control-wrapper.e-rtl .e-input-group-icon:last-child,
  .e-filled.e-input-group.e-small.e-control-wrapper.e-rtl .e-input-group-icon:last-child,
  .e-small .e-filled.e-input-group.e-control-wrapper.e-rtl .e-input-group-icon:last-child,
  .e-rtl .e-filled.e-input-group .e-input-group-icon:last-child,
  .e-rtl .e-filled.e-input-group.e-small .e-input-group-icon:last-child,
  .e-rtl.e-small .e-filled.e-input-group .e-input-group-icon:last-child,
  .e-rtl .e-filled.e-input-group.e-control-wrapper .e-input-group-icon:last-child,
  .e-rtl .e-filled.e-input-group.e-small.e-control-wrapper .e-input-group-icon:last-child,
  .e-rtl.e-small .e-filled.e-input-group.e-control-wrapper .e-input-group-icon:last-child {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      margin-left: $zero-value;
    }
  }

  .e-filled.e-rtl.e-input-group .e-input-group-icon,
  .e-filled.e-rtl.e-input-group.e-control-wrapper .e-input-group-icon,
  .e-rtl .e-filled.e-input-group .e-input-group-icon,
  .e-rtl .e-filled.e-input-group.e-control-wrapper .e-input-group-icon,
  .e-filled.e-input-group.e-small.e-rtl .e-input-group-icon,
  .e-filled.e-input-group.e-control-wrapper.e-small.e-rtl .e-input-group-icon,
  .e-small .e-filled.e-input-group.e-rtl .e-input-group-icon,
  .e-small .e-filled.e-input-group.e-control-wrapper.e-rtl .e-input-group-icon,
  .e-rtl .e-filled.e-input-group.e-small .e-input-group-icon,
  .e-rtl .e-filled.e-input-group.e-control-wrapper.e-small .e-input-group-icon,
  .e-rtl.e-small .e-filled.e-input-group .e-input-group-icon,
  .e-rtl.e-small .e-filled.e-input-group.e-control-wrapper .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      margin-left: $zero-value;
      margin-right: $zero-value;
    }
  }

  .e-filled textarea.e-input#{$css},
  .e-filled.e-input-group.e-multi-line-input textarea,
  .e-filled.e-input-group.e-control-wrapper.e-multi-line-input textarea,
  .e-filled.e-float-input.e-multi-line-input textarea,
  .e-filled.e-float-input.e-control-wrapper.e-multi-line-input textarea {
    box-sizing: border-box;
  }

  .e-filled textarea.e-input#{$css},
  .e-filled.e-input-group textarea,
  .e-filled.e-input-group textarea.e-input,
  .e-filled.e-input-group.e-input-focus textarea,
  .e-filled.e-input-group.e-input-focus textarea.e-input,
  .e-filled.e-input-group.e-control-wrapper textarea,
  .e-filled.e-input-group.e-control-wrapper.e-input-focus textarea,
  .e-filled.e-input-group.e-control-wrapper textarea.e-input,
  .e-filled.e-input-group.e-control-wrapper.e-input-focus textarea.e-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      padding: $filled-textarea-padding;
    }
  }

  .e-filled.e-float-input textarea,
  .e-filled.e-float-input.e-control-wrapper textarea {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      padding: $filled-float-textarea-padding;
    }
  }

  .e-filled.e-input-group.e-small textarea,
  .e-filled.e-input-group.e-small textarea.e-input,
  .e-filled.e-input-group textarea.e-small,
  .e-filled.e-input-group textarea.e-input.e-small,
  .e-filled.e-input-group.e-control-wrapper.e-small textarea,
  .e-filled.e-input-group.e-control-wrapper.e-small textarea.e-input,
  .e-small .e-filled.e-input-group textarea,
  .e-small .e-filled.e-input-group textarea.e-input,
  .e-filled.e-input-group.e-input-focus.e-small textarea,
  .e-filled.e-input-group.e-input-focus.e-small textarea.e-input,
  .e-filled.e-input-group.e-input-focus textarea.e-small,
  .e-filled.e-input-group.e-input-focus textarea.e-input.e-small,
  .e-filled.e-input-group.e-input-focus textarea.e-input.e-small,
  .e-filled.e-input-group.e-control-wrapper.e-input-focus.e-small textarea,
  .e-filled.e-input-group.e-control-wrapper.e-input-focus.e-small textarea.e-input,
  .e-small .e-filled.e-input-group.e-input-focus textarea,
  .e-small .e-filled.e-input-group.e-input-focus textarea.e-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      padding: $filled-small-textarea-padding;
    }
  }

  .e-filled.e-float-input.e-small textarea,
  .e-filled.e-float-input textarea.e-small,
  .e-filled.e-float-input.e-control-wrapper.e-small textarea,
  .e-filled.e-float-input.e-control-wrapper textarea.e-small,
  .e-small .e-filled.e-float-input textarea,
  .e-small .e-filled.e-float-input.e-control-wrapper textarea {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      padding: $filled-small-float-textarea-padding;
    }
  }

  .e-filled.e-input-group.e-multi-line-input,
  .e-filled.e-input-group.e-control-wrapper.e-multi-line-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      padding: $filled-textarea-wrapper-padding;
    }
  }

  .e-filled.e-input-group.e-small.e-multi-line-input,
  .e-filled.e-input-group.e-control-wrapper.e-small.e-multi-line-input,
  .e-small .e-filled.e-input-group.e-control-wrapper.e-multi-line-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      padding: $small-filled-textarea-wrapper-padding;
    }
  }

  .e-filled.e-float-input.e-multi-line-input,
  .e-filled.e-float-input.e-control-wrapper.e-multi-line-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      padding: $filled-float-textarea-wrapper-padding;
    }
  }

  .e-filled.e-float-input.e-small.e-multi-line-input,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-multi-line-input,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-multi-line-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      padding: $small-filled-float-textarea-wrapper-padding;
    }
  }

  .e-filled textarea.e-input.e-rtl#{$css},
  .e-filled.e-input-group.e-multi-line-input.e-rtl textarea.e-input,
  .e-filled.e-input-group.e-multi-line-input.e-control-wrapper.e-rtl textarea.e-input,
  .e-rtl .e-filled.e-input-group.e-multi-line-input textarea.e-input,
  .e-rtl .e-filled.e-input-group.e-multi-line-input.e-control-wrapper textarea.e-input,
  .e-filled.e-input-group.e-multi-line-input.e-rtl textarea.e-input,
  .e-filled.e-input-group.e-multi-line-input.e-control-wrapper.e-rtl textarea.e-input,
  .e-rtl .e-filled.e-input-group.e-multi-line-input textarea.e-input,
  .e-rtl .e-filled.e-input-group.e-multi-line-input.e-control-wrapper textarea.e-input,
  .e-filled.e-input-group.e-multi-line-input.e-rtl textarea.e-input:focus,
  .e-filled.e-input-group.e-multi-line-input.e-control-wrapper.e-rtl textarea.e-input:focus,
  .e-rtl .e-filled.e-input-group.e-multi-line-input textarea.e-input:focus,
  .e-rtl .e-filled.e-input-group.e-multi-line-input.e-control-wrapper textarea.e-input:focus {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      padding: $filled-textarea-padding;
      text-indent: 0;
    }
  }

  .e-filled.e-float-input.e-multi-line-input.e-rtl textarea,
  .e-filled.e-float-input.e-multi-line-input.e-control-wrapper.e-rtl textarea,
  .e-rtl .e-filled.e-float-input.e-multi-line-input textarea,
  .e-rtl .e-filled.e-float-input.e-multi-line-input.e-control-wrapper textarea,
  .e-filled.e-float-input.e-multi-line-input.e-rtl textarea,
  .e-filled.e-float-input.e-multi-line-input.e-control-wrapper.e-rtl textarea,
  .e-rtl .e-filled.e-float-input.e-multi-line-input textarea,
  .e-rtl .e-filled.e-float-input.e-multi-line-input.e-control-wrapper textarea,
  .e-filled.e-float-input.e-multi-line-input.e-rtl textarea:focus,
  .e-filled.e-float-input.e-multi-line-input.e-control-wrapper.e-rtl textarea:focus,
  .e-rtl .e-filled.e-float-input.e-multi-line-input textarea:focus,
  .e-rtl .e-filled.e-float-input.e-multi-line-input.e-control-wrapper textarea:focus {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      padding: $filled-float-textarea-padding;
      text-indent: 0;
    }
  }

  .e-rtl .e-filled textarea.e-input.e-small#{$css},
  .e-filled textarea.e-input#{$css}.e-small.e-rtl,
  .e-small.e-rtl .e-filled textarea.e-input#{$css},
  .e-small .e-filled textarea.e-input.e-rtl#{$css},
  .e-filled.e-input-group.e-multi-line-input.e-small.e-rtl textarea.e-input,
  .e-filled.e-input-group.e-multi-line-input.e-control-wrapper.e-small.e-rtl textarea.e-input,
  .e-rtl .e-filled.e-input-group.e-multi-line-input.e-small textarea.e-input,
  .e-rtl .e-filled.e-input-group.e-multi-line-input.e-control-wrapper.e-small textarea.e-input,
  .e-filled.e-input-group.e-multi-line-input.e-rtl textarea.e-input.e-small,
  .e-filled.e-input-group.e-multi-line-input.e-control-wrapper.e-rtl textarea.e-input.e-small,
  .e-rtl .e-filled.e-input-group.e-multi-line-input textarea.e-input.e-small,
  .e-rtl .e-filled.e-input-group.e-multi-line-input.e-control-wrapper textarea.e-input.e-small,
  .e-small .e-filled.e-input-group.e-multi-line-input.e-rtl textarea.e-input,
  .e-small .e-filled.e-input-group.e-multi-line-input.e-control-wrapper.e-rtl textarea.e-input,
  .e-small.e-rtl .e-filled.e-input-group.e-multi-line-input.e-control-wrapper textarea.e-input,
  .e-small.e-rtl .e-filled.e-input-group.e-multi-line-input textarea.e-input,
  .e-small.e-rtl .e-filled.e-input-group.e-multi-line-input.e-control-wrapper textarea.e-input:focus,
  .e-small.e-rtl .e-filled.e-input-group.e-multi-line-input textarea.e-input:focus,
  .e-small .e-filled.e-input-group.e-multi-line-input.e-control-wrapper.e-rtl textarea.e-input:focus,
  .e-small .e-filled.e-input-group.e-multi-line-input.e-rtl textarea.e-input:focus {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      padding: $filled-small-textarea-padding;
      text-indent: 0;
    }
  }

  .e-filled.e-float-input.e-multi-line-input.e-control-wrapper.e-small.e-rtl textarea,
  .e-filled.e-float-input.e-multi-line-input.e-small.e-rtl textarea,
  .e-rtl .e-filled.e-float-input.e-multi-line-input.e-small textarea,
  .e-rtl .e-filled.e-float-input.e-multi-line-input.e-control-wrapper.e-small textarea,
  .e-filled.e-float-input.e-multi-line-input.e-rtl textarea.e-small,
  .e-filled.e-float-input.e-multi-line-input.e-control-wrapper.e-rtl textarea.e-small,
  .e-rtl .e-filled.e-float-input.e-multi-line-input textarea.e-small,
  .e-rtl .e-filled.e-float-input.e-multi-line-input.e-control-wrapper textarea.e-small,
  .e-small .e-filled.e-float-input.e-multi-line-input.e-rtl textarea,
  .e-small .e-filled.e-float-input.e-multi-line-input.e-control-wrapper.e-rtl textarea,
  .e-small.e-rtl .e-filled.e-float-input.e-multi-line-input.e-control-wrapper textarea,
  .e-small.e-rtl .e-filled.e-float-input.e-multi-line-input textarea,
  .e-small.e-rtl .e-filled.e-float-input.e-multi-line-input.e-control-wrapper textarea:focus,
  .e-small.e-rtl .e-filled.e-float-input.e-multi-line-input textarea:focus,
  .e-small .e-filled.e-float-input.e-multi-line-input.e-control-wrapper.e-rtl textarea:focus,
  .e-small .e-filled.e-float-input.e-multi-line-input.e-rtl textarea:focus {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      padding: $filled-small-float-textarea-padding;
      text-indent: 0;
    }
  }

  .e-filled.e-float-input.e-float-icon-left .e-input-in-wrap,
  .e-filled.e-float-input.e-control-wrapper.e-float-icon-left .e-input-in-wrap,
  .e-filled.e-input-group.e-float-icon-left .e-input-in-wrap,
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left .e-input-in-wrap {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      border: $input-group-full-border;
      border-width: $zero-value;
      margin-left: 8px;
    }
  }

  .e-rtl .e-filled.e-float-input.e-float-icon-left .e-input-in-wrap,
  .e-rtl .e-filled.e-float-input.e-control-wrapper.e-float-icon-left .e-input-in-wrap,
  .e-rtl .e-filled.e-input-group.e-float-icon-left .e-input-in-wrap,
  .e-rtl .e-filled.e-input-group.e-control-wrapper.e-float-icon-left .e-input-in-wrap .e-filled.e-float-input.e-control-wrapper.e-float-icon-left.e-rtl .e-input-in-wrap,
  .e-filled.e-float-input.e-float-icon-left.e-rtl .e-input-in-wrap,
  .e-filled.e-input-group.e-float-icon-left.e-rtl .e-input-in-wrap,
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left.e-rtl .e-input-in-wrap {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      margin-left: $zero-value;
      margin-right: 8px;
    }
  }

  .e-filled.e-float-input.e-float-icon-left.e-small .e-input-in-wrap,
  .e-filled.e-float-input.e-control-wrapper.e-float-icon-left.e-small .e-input-in-wrap,
  .e-filled.e-input-group.e-float-icon-left.e-small .e-input-in-wrap,
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left.e-small .e-input-in-wrap,
  .e-small .e-filled.e-float-input.e-float-icon-left .e-input-in-wrap,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-float-icon-left .e-input-in-wrap,
  .e-small .e-filled.e-input-group.e-float-icon-left .e-input-in-wrap,
  .e-small .e-filled.e-input-group.e-control-wrapper.e-float-icon-left .e-input-in-wrap {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      margin-left: 4px;
    }
  }

  .e-rtl.e-small .e-filled.e-float-input.e-float-icon-left .e-input-in-wrap,
  .e-rtl.e-small .e-filled.e-float-input.e-control-wrapper.e-float-icon-left .e-input-in-wrap,
  .e-rtl.e-small .e-filled.e-input-group.e-float-icon-left .e-input-in-wrap,
  .e-rtl.e-small .e-filled.e-input-group.e-control-wrapper.e-float-icon-left .e-input-in-wrap,
  .e-rtl .e-small.e-filled.e-float-input.e-float-icon-left .e-input-in-wrap,
  .e-rtl .e-small.e-filled.e-float-input.e-control-wrapper.e-float-icon-left .e-input-in-wrap,
  .e-rtl .e-small.e-filled.e-input-group.e-float-icon-left .e-input-in-wrap,
  .e-rtl .e-small.e-filled.e-input-group.e-control-wrapper.e-float-icon-left .e-input-in-wrap,
  .e-small.e-filled.e-float-input.e-control-wrapper.e-float-icon-left.e-rtl .e-input-in-wrap,
  .e-small.e-filled.e-float-input.e-float-icon-left.e-rtl .e-input-in-wrap,
  .e-small.e-filled.e-input-group.e-float-icon-left.e-rtl .e-input-in-wrap,
  .e-small.e-filled.e-input-group.e-control-wrapper.e-float-icon-left.e-rtl .e-input-in-wrap,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-float-icon-left.e-rtl .e-input-in-wrap,
  .e-small .e-filled.e-float-input.e-float-icon-left.e-rtl .e-input-in-wrap,
  .e-small .e-filled.e-input-group.e-float-icon-left.e-rtl .e-input-in-wrap,
  .e-small .e-filled.e-input-group.e-control-wrapper.e-float-icon-left.e-rtl .e-input-in-wrap {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      margin-left: $zero-value;
      margin-right: 4px;
    }
  }

  .e-filled.e-input-group.e-float-icon-left:not(.e-float-input)::before,
  .e-filled.e-input-group.e-float-icon-left:not(.e-float-input)::after,
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)::before,
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      @include input-group-animation;
      bottom: -1px;
    }
  }

  .e-filled.e-input-group.e-float-icon-left:not(.e-float-input).e-input-focus::before,
  .e-filled.e-input-group.e-float-icon-left:not(.e-float-input).e-input-focus::after,
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input).e-input-focus::before,
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input).e-input-focus::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      @include input-group-animation-width;
      bottom: -1px;
    }
  }

  .e-filled.e-input-group.e-float-icon-left:not(.e-float-input).e-input-focus .e-input-in-wrap::before,
  .e-filled.e-input-group.e-float-icon-left:not(.e-float-input).e-input-focus .e-input-in-wrap::after,
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input).e-input-focus .e-input-in-wrap::before,
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input).e-input-focus .e-input-in-wrap::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      width: $zero-value;
    }
  }

  .e-filled.e-input-group.e-float-icon-left,
  .e-filled.e-input-group.e-success.e-float-icon-left,
  .e-filled.e-input-group.e-warning.e-float-icon-left,
  .e-filled.e-input-group.e-error.e-float-icon-left,
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left,
  .e-filled.e-input-group.e-control-wrapper.e-success.e-float-icon-left,
  .e-filled.e-input-group.e-control-wrapper.e-warning.e-float-icon-left,
  .e-filled.e-input-group.e-control-wrapper.e-error.e-float-icon-left {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      border: $input-group-full-border;
      border-width: $input-group-full-border-width;
    }
  }

  .e-input-group.e-control-container.e-control-wrapper.e-float-input.e-prepend.e-append:not(.e-outline) .e-input-in-wrap,
  .e-input-group.e-control-container.e-control-wrapper.e-float-input.e-prepend:not(.e-outline) .e-input-in-wrap {
    @if $input-skin-name == 'material' {
      border-bottom: 1px solid;
      border-bottom-color: $input-box-border-color;
    }
  }

  .e-error.e-input-group.e-control-container.e-control-wrapper.e-float-input.e-prepend.e-append .e-input-in-wrap,
  .e-error.e-input-group.e-control-container.e-control-wrapper.e-float-input.e-prepend .e-input-in-wrap {
    @if $input-skin-name == 'material' {
      border-bottom: 1px solid;
      border-bottom-color: $error-font-color;
    }
  }

  .e-success.e-input-group.e-control-container.e-control-wrapper.e-float-input.e-prepend.e-append .e-input-in-wrap,
  .e-success.e-input-group.e-control-container.e-control-wrapper.e-float-input.e-prepend .e-input-in-wrap {
    @if $input-skin-name == 'material' {
      border-bottom: 1px solid;
      border-bottom-color: $success-font-color;
    }
  }

  .e-input-group:not(.e-outline).e-prepend.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
  .e-input-group:not(.e-outline).e-control-wrapper.e-prepend.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
  .e-float-input:not(.e-outline).e-input-group.e-prepend.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
  .e-float-input:not(.e-outline).e-input-group.e-prepend.e-input-focus:not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error),
  .e-float-input:not(.e-outline).e-input-group.e-prepend.e-input-focus:not(.e-float-icon-left):not(.e-success).e-warning:not(.e-error),
  .e-float-input:not(.e-outline).e-input-group.e-prepend.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning).e-error,
  .e-float-input:not(.e-outline).e-control-wrapper.e-input-group.e-prepend.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
  .e-float-input:not(.e-outline).e-control-wrapper.e-input-group.e-prepend.e-input-focus:not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error),
  .e-float-input:not(.e-outline).e-control-wrapper.e-input-group.e-prepend.e-input-focus:not(.e-float-icon-left):not(.e-success).e-warning:not(.e-error),
  .e-float-input:not(.e-outline).e-control-wrapper.e-input-group.e-prepend.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning).e-error,
  .e-float-input:not(.e-outline).e-control-wrapper.e-prepend.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error) input,
  .e-float-input:not(.e-outline).e-control-wrapper.e-prepend.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled).e-success:not(.e-warning):not(.e-error) input,
  .e-float-input:not(.e-outline).e-control-wrapper.e-prepend.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success).e-warning:not(.e-error) input,
  .e-float-input:not(.e-outline).e-control-wrapper.e-prepend.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning).e-error input,
  .e-float-input:not(.e-outline).e-control-wrapper.e-prepend.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error) textarea,
  .e-float-input:not(.e-outline).e-control-wrapper.e-prepend.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled).e-success:not(.e-warning):not(.e-error) textarea,
  .e-float-input:not(.e-outline).e-control-wrapper.e-prepend.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success).e-warning:not(.e-error) textarea,
  .e-float-input:not(.e-outline).e-control-wrapper.e-prepend.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning).e-error textarea,
  .e-float-input:not(.e-outline).e-prepend.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error) input,
  .e-float-input:not(.e-outline).e-prepend.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled).e-success:not(.e-warning):not(.e-error) input,
  .e-float-input:not(.e-outline).e-prepend.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success).e-warning:not(.e-error) input,
  .e-float-input:not(.e-outline).e-prepend.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning).e-error input,
  .e-float-input:not(.e-outline).e-prepend.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error) textarea,
  .e-float-input:not(.e-outline).e-prepend.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled).e-success:not(.e-warning):not(.e-error) textarea,
  .e-float-input:not(.e-outline).e-prepend.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success).e-warning:not(.e-error) textarea,
  .e-float-input:not(.e-outline).e-prepend.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning).e-error textarea,
  .e-input-group:not(.e-outline).e-prepend.e-input-focus:not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error),
  .e-input-group:not(.e-outline).e-prepend.e-input-focus:not(.e-float-icon-left).e-warning:not(.e-success):not(.e-error),
  .e-input-group:not(.e-outline).e-control-wrapper.e-prepend.e-input-focus:not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error),
  .e-input-group:not(.e-outline).e-control-wrapper.e-prepend.e-input-focus:not(.e-float-icon-left).e-warning:not(.e-success):not(.e-error),
  .e-input-group:not(.e-outline).e-prepend.e-input-focus:not(.e-float-icon-left).e-error:not(.e-success):not(.e-warning),
  .e-input-group:not(.e-outline).e-float-icon-left.e-prepend.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap,
  .e-input-group:not(.e-outline).e-float-icon-left.e-prepend.e-input-focus.e-success:not(.e-warning):not(.e-error) .e-input-in-wrap,
  .e-input-group:not(.e-outline).e-float-icon-left.e-prepend.e-input-focus.e-warning:not(.e-success):not(.e-error) .e-input-in-wrap,
  .e-input-group:not(.e-outline).e-float-icon-left.e-prepend.e-input-focus.e-error:not(.e-success):not(.e-warning) .e-input-in-wrap,
  .e-input-group:not(.e-outline).e-prepend:not(.e-float-icon-left),
  .e-input-group:not(.e-outline).e-prepend.e-success:not(.e-float-icon-left),
  .e-input-group:not(.e-outline).e-prepend.e-warning:not(.e-float-icon-left),
  .e-input-group:not(.e-outline).e-prepend.e-error:not(.e-float-icon-left),
  .e-input-group:not(.e-outline).e-control-wrapper.e-prepend:not(.e-float-icon-left),
  .e-input-group:not(.e-outline).e-control-wrapper.e-prepend.e-success:not(.e-float-icon-left),
  .e-input-group:not(.e-outline).e-control-wrapper.e-prepend.e-warning:not(.e-float-icon-left),
  .e-input-group:not(.e-outline).e-control-wrapper.e-prepend.e-error:not(.e-float-icon-left),
  .e-input-group:not(.e-outline).e-prepend:hover:not(.e-disabled):not(.e-float-icon-left),
  .e-input-group:not(.e-outline).e-control-wrapper.e-prepend:hover:not(.e-disabled):not(.e-float-icon-left),
  .e-float-input:not(.e-outline).e-prepend:hover:not(.e-disabled):not(.e-float-icon-left),
  .e-float-input:not(.e-outline).e-prepend:hover:not(.e-input-group):not(.e-disabled) input:not([disabled]),
  .e-float-input:not(.e-outline).e-prepend:hover:not(.e-input-group):not(.e-disabled) textarea:not([disabled]),
  .e-float-input:not(.e-outline).e-prepend:hover:not(.e-input-group):not(.e-disabled) input:not([disabled]),
  .e-float-input:not(.e-outline).e-prepend:hover:not(.e-input-group):not(.e-disabled) textarea:not([disabled]),
  .e-float-input:not(.e-outline).e-control-wrapper.e-prepend:hover:not(.e-disabled):not(.e-float-icon-left),
  .e-float-input:not(.e-outline).e-control-wrapper.e-prepend:hover:not(.e-input-group):not(.e-disabled) input:not([disabled]),
  .e-float-input:not(.e-outline).e-control-wrapper.e-prepend:hover:not(.e-input-group):not(.e-disabled) textarea:not([disabled]),
  .e-float-input:not(.e-outline).e-control-wrapper.e-prepend:hover:not(.e-input-group):not(.e-disabled) input:not([disabled]),
  .e-float-input:not(.e-outline).e-control-wrapper.e-prepend:hover:not(.e-input-group):not(.e-disabled) textarea:not([disabled]) {
    @if $input-skin-name == 'material' {
      border-bottom: transparent;
    }
  }
}
