@mixin ripple-container {
  bottom: $cbox-ripple-small-size;
  height: $cbox-ripple-small-height;
  left: $cbox-ripple-small-size;
  right: $cbox-ripple-small-size;
  top: $cbox-ripple-small-size;
  width: $cbox-ripple-small-width;
}

@include export-module('checkbox-layout') {
  .e-checkbox-wrapper,
  .e-css.e-checkbox-wrapper {
    cursor: pointer;
    display: inline-block;
    line-height: 1;
    outline: none;
    user-select: none;
    @if $skin-name == 'FluentUI' {
      border: 1px solid transparent;
      padding: 3px;
    }
    @if $skin-name == 'fluent2' {
      border-radius: 4px;
      border: 1px solid transparent;
      line-height: 0;
    }
    @at-root {
      & label {
        cursor: pointer;
        display: inline-block;
        line-height: 0;
        margin: 0;
        position: relative;
        white-space: nowrap;
      }

      &:focus,
      &.e-focus {
        & .e-frame {
          box-shadow: $cbox-focussed-box-shadow;
          @if $skin-name == 'bootstrap5.3' {
            border-color: $border-focus;
          }
        }
        @if $skin-name == 'FluentUI' {
          border: 1px solid $cbox-border-color;
          padding: 3px;
        }
        @if $skin-name == 'fluent2' {
          border-radius: 4px;
          box-shadow: $shadow-focus-ring1;
        }
      }

      &:active {
        & .e-frame {
          @if $skin-name == 'bootstrap5.3' {
            box-shadow: $cbox-focussed-box-shadow;
            border-color: $border-focus !important; /* stylelint-disable-line declaration-no-important */
            background-color: $content-bg-color-pressed;
          }
        }
      }

      & .e-ripple-container {
        border-radius: 50%;
        bottom: $cbox-ripple-size;
        height: $cbox-ripple-height;
        left: $cbox-ripple-size;
        pointer-events: none;
        position: absolute;
        right: $cbox-ripple-size;
        top: $cbox-ripple-size;
        width: $cbox-ripple-width;
        z-index: 1;

        & .e-ripple-element {
          @if $skin-name == 'Material3' {
            border-radius: 50%;
          }
        }
      }

      & .e-label {
        cursor: pointer;
        display: inline-block;
        font-family: $font-family;
        font-size: $cbox-font-size;
        font-weight: normal;
        line-height: $cbox-height;
        user-select: none;
        vertical-align: middle;
        white-space: normal;
      }

      & .e-checkbox {
        height: 1px;
        opacity: 0;
        position: absolute;
        width: 1px;

        +.e-label {
          @if $skin-name == 'fluent2' {
            margin: 6px 4px 6px 8px;
          }
          @else {
            margin-right: $cbox-margin;
          }
        }
      }

      & .e-frame {
        border: $cbox-border;
        border-radius: $cbox-border-radius;
        box-sizing: border-box;
        cursor: pointer;
        display: inline-block;
        font-family: 'e-icons';
        height: $cbox-height;
        line-height: $cbox-lineheight;
        padding: $cbox-padding;
        text-align: center;
        vertical-align: middle;
        width: $cbox-width;
        @if $skin-name == 'fluent2' {
          margin: 7.2px 8px;
        }

        +.e-label {
          @if $skin-name == 'fluent2' {
            margin: 6px 8px 6px 4px;
          }
          @else {
            margin-left: $cbox-margin;
          }
        }

        +.e-ripple-container {
          left: auto;
        }
      }

      & .e-check {
        font-size: $cbox-check-fontsize;
      }

      & .e-stop {
        font-size: $cbox-indeterminate-fontsize;
        line-height: $cbox-indeterminate-lineheight;
      }

      &.e-checkbox-disabled {
        cursor: default;
        pointer-events: none;
        @if $skin-name == 'bootstrap5.3' {
          opacity: .5;
        }

        & .e-frame {
          cursor: default;
        }

        & .e-label {
          cursor: default;
        }
      }

      &.e-rtl {
        .e-ripple-container {
          right: $cbox-ripple-size;
        }

        & .e-frame {
          @if $skin-name != 'fluent2' {
            margin: 0;
          }

          &:hover {
            background-color: $cbox-hover-bgcolor;
            border-color: $cbox-hover-border-color;
            @if $skin-name == 'FluentUI' {
              &:not(.e-check),
              &:not(.e-stop)::before {
                content: $cbox-check-content;
                font-size: $cbox-check-fontsize;
              }
            }
          }

          + .e-ripple-container {
            left: $cbox-ripple-size;
            right: auto;
          }
        }

        & .e-label {
          @if $skin-name == 'fluent2' {
            margin: 6px 4px 6px 8px;
          }
          @else {
            margin-left: 0;
            margin-right: $cbox-margin;
          }

          +.e-frame {
            @if $skin-name != 'fluent2' {
              margin: 0;
            }
          }
        }

        & .e-checkbox {
          +.e-label {
            @if $skin-name == 'fluent2' {
              margin: 6px 4px 6px 8px;
            }
            @else {
              margin-left: $cbox-margin;
              margin-right: 0;
            }
          }
        }
      }

      &.e-small {
        & .e-frame {
          height: $cbox-small-height;
          line-height: $cbox-small-lineheight;
          width: $cbox-small-width;
        }

        & .e-check {
          font-size: $cbox-small-check-fontsize;
        }

        & .e-stop {
          font-size: $cbox-small-indeterminate-fontsize;
          line-height: $cbox-small-indeterminate-lineheight;
        }

        & .e-label {
          font-size: $cbox-small-font-size;
          line-height: $cbox-small-height;
        }

        & .e-ripple-container {
          @include ripple-container;
        }
      }
    }
  }

  .e-checkbox-wrapper[readonly] {
    pointer-events: none;
  }

  .e-small .e-checkbox-wrapper,
  .e-small.e-checkbox-wrapper,
  .e-small .e-css.e-checkbox-wrapper,
  .e-small.e-css.e-checkbox-wrapper {
    & .e-frame {
      height: $cbox-small-height;
      line-height: $cbox-small-lineheight;
      width: $cbox-small-width;
      @if $skin-name == 'fluent2' {
        margin: 6.2px 8px;
      }

      &:hover {
        background-color: $cbox-hover-bgcolor;
        border-color: $cbox-hover-border-color;
        @if $skin-name == 'FluentUI' {
          &:not(.e-check),
          &:not(.e-stop)::before {
            content: $cbox-check-content;
            font-size: $cbox-small-check-fontsize;
          }
        }
      }
    }

    & .e-check {
      font-size: $cbox-small-check-fontsize;
    }

    & .e-stop {
      font-size: $cbox-small-indeterminate-fontsize;
      line-height: $cbox-small-indeterminate-lineheight;
    }

    & .e-label {
      font-size: $cbox-small-font-size;
      line-height: $cbox-small-height;
    }

    & .e-ripple-container {
      @include ripple-container;
    }
  }
}
