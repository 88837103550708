@include export-module('multiselect-material-icons') {
  .e-multiselect.e-input-group .e-ddl-icon::before {
    content: '\e969';
    font-family: 'e-icons';
  }

  .e-multi-select-wrapper .e-chips .e-chips-close::before {
    content: '\e7e9';
    cursor: pointer;
    left: $ddl-chip-close-left;
    position: relative;
    top: $ddl-chip-close-top;
  }

  .e-multi-select-wrapper .e-close-hooker::before {
    content: '\e7a7';
    cursor: pointer;
    left: $ddl-overall-close-left;
    position: relative;
    top: $ddl-overall-close-top;
  }

  .e-multiselect.e-input-group .e-ddl-disable-icon::before {
    content: '';
  }

  .e-multiselect.e-outline .e-multi-select-wrapper .e-chips-close.e-close-hooker {
    @if $ddl-multiselect-skin-name == 'material' {
      height: 38px;
      margin-top: -38px;
      right: 18px;
      top: 100%;
      width: 32px;
    }
  }

  .e-multiselect.e-filled .e-multi-select-wrapper .e-chips-close.e-close-hooker {
    @if $ddl-multiselect-skin-name == 'material' {
      height: 38px;
      margin-top: -35px;
      right: 18px;
      top: 100%;
      width: 32px;
    }
  }

  .e-multiselect.e-outline .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker,
  .e-multiselect.e-filled .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker {
    @if $ddl-multiselect-skin-name == 'material' {
      right: 30px;
    }
  }

  .e-multiselect.e-filled .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker {
    @if $ddl-multiselect-skin-name == 'material' {
      right: 30px;
    }
  }

  .e-small.e-multiselect.e-outline .e-multi-select-wrapper .e-chips-close.e-close-hooker,
  .e-small .e-multiselect.e-outline .e-multi-select-wrapper .e-chips-close.e-close-hooker {
    @if $ddl-multiselect-skin-name == 'material' {
      height: 34px;
      margin-top: -35px;
      right: 16px;
      top: 100%;
      width: 14px;
    }
  }

  .e-small.e-multiselect.e-outline .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker,
  .e-small .e-multiselect.e-outline .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker,
  .e-small.e-multiselect.e-filled .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker,
  .e-small .e-multiselect.e-filled .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker {
    @if $ddl-multiselect-skin-name == 'material' {
      right: 48px;
    }
  }

  .e-rtl.e-multiselect.e-outline .e-multi-select-wrapper .e-chips-close.e-close-hooker,
  .e-rtl .e-multiselect.e-outline .e-multi-select-wrapper .e-chips-close.e-close-hooker,
  .e-rtl.e-multiselect.e-filled .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker,
  .e-rtl .e-multiselect.e-filled .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker {
    @if $ddl-multiselect-skin-name == 'material' {
      left: 52px;
      right: auto;
    }
  }

  .e-rtl.e-small.e-multiselect.e-outline .e-multi-select-wrapper .e-chips-close.e-close-hooker,
  .e-rtl.e-small .e-multiselect.e-outline .e-multi-select-wrapper .e-chips-close.e-close-hooker,
  .e-rtl .e-small.e-multiselect.e-outline .e-multi-select-wrapper .e-chips-close.e-close-hooker,
  .e-rtl .e-small .e-multiselect.e-outline .e-multi-select-wrapper .e-chips-close.e-close-hooker,
  .e-small .e-rtl.e-multiselect.e-outline .e-multi-select-wrapper .e-chips-close.e-close-hooker,
  .e-small .e-rtl .e-multiselect.e-outline .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker,
  .e-rtl.e-small.e-multiselect.e-filled .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker,
  .e-rtl.e-small .e-multiselect.e-filled .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker,
  .e-rtl .e-small.e-multiselect.e-filled .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker,
  .e-rtl .e-small .e-multiselect.e-filled .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker,
  .e-small .e-rtl.e-multiselect.e-filled .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker,
  .e-small .e-rtl .e-multiselect.e-filled .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker {
    @if $ddl-multiselect-skin-name == 'material' {
      left: 48px;
      right: auto;
    }
  }

  .e-multiselect.e-outline .e-multi-select-wrapper .e-close-hooker::before {
    @if $ddl-multiselect-skin-name == 'material' {
      content: '\e208';
      font-size: 16px;
      height: 16px;
      margin-top: -8px;
      top: 50%;
    }
  }

  .e-small.e-multiselect.e-outline .e-multi-select-wrapper .e-close-hooker::before,
  .e-small .e-multiselect.e-outline .e-multi-select-wrapper .e-close-hooker::before {
    @if $ddl-multiselect-skin-name == 'material' {
      content: '\e208';
      font-size: 14px;
      height: 14px;
      margin-top: -7px;
      top: 50%;
    }
  }

  .e-multiselect.e-outline .e-multi-select-wrapper .e-chips .e-chips-close::before {
    @if $ddl-multiselect-skin-name == 'material' {
      font-size: 14px;
    }
  }

  .e-small .e-multiselect.e-outline .e-multi-select-wrapper .e-chips .e-chips-close::before,
  .e-small.e-multiselect.e-outline .e-multi-select-wrapper .e-chips .e-chips-close::before {
    @if $ddl-multiselect-skin-name == 'material' {
      font-size: 13px;
    }
  }

  // Multiselect dropdown icon margin

  .e-multiselect.e-input-group.e-outline .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
  .e-multiselect.e-outline.e-input-group.e-control-wrapper .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon {
    @if $ddl-multiselect-skin-name == 'material' {
      margin: 7px 12px 9px 0;
    }
  }

  .e-multiselect.e-input-group.e-outline.e-small .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
  .e-multiselect.e-input-group.e-outline.e-control-wrapper.e-small .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
  .e-small .e-multiselect.e-input-group.e-outline .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
  .e-small .e-multiselect.e-input-group.e-outline.e-control-wrapper .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon {
    @if $ddl-multiselect-skin-name == 'material' {
      margin: 6px 10px 8px 0;
    }
  }

  // RTL Multiselect dropdown icon margin

  .e-rtl.e-multiselect.e-input-group.e-outline .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
  .e-rtl.e-multiselect.e-outline.e-input-group.e-control-wrapper .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
  .e-rtl .e-multiselect.e-input-group.e-outline .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
  .e-rtl .e-multiselect.e-outline.e-input-group.e-control-wrapper .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon {
    @if $ddl-multiselect-skin-name == 'material' {
      margin: 7px 0 9px 12px;
    }
  }

  .e-rtl.e-multiselect.e-input-group.e-outline.e-small .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
  .e-rtl.e-multiselect.e-input-group.e-outline.e-control-wrapper.e-small .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
  .e-small .e-rtl.e-multiselect.e-input-group.e-outline .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
  .e-small .e-rtl.e-multiselect.e-input-group.e-outline.e-control-wrapper .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
  .e-rtl .e-multiselect.e-input-group.e-outline.e-small .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
  .e-rtl .e-multiselect.e-input-group.e-outline.e-control-wrapper.e-small .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
  .e-rtl.e-small .e-multiselect.e-input-group.e-outline .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
  .e-rtl.e-small .e-multiselect.e-input-group.e-outline.e-control-wrapper .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon {
    @if $ddl-multiselect-skin-name == 'material' {
      margin: 6px 0 8px 10px;
    }
  }

  // Filled theme clear icon styles

  .e-filled .e-multi-select-wrapper .e-chips-close.e-close-hooker,
  .e-filled.e-multiselect .e-down-icon .e-chips-close.e-close-hooker {
    height: 20px;
    margin-top: -33px;
    right: 18px;
    width: 20px;
  }

  .e-filled.e-float-input .e-multi-select-wrapper .e-chips-close.e-close-hooker,
  .e-filled.e-float-input.e-multiselect .e-down-icon .e-chips-close.e-close-hooker {
    margin-top: -32px;
  }

  .e-small.e-filled .e-multi-select-wrapper .e-chips-close.e-close-hooker,
  .e-small .e-filled .e-multi-select-wrapper .e-chips-close.e-close-hooker {
    height: 18px;
    margin: -29px 0 8px;
    width: 18px;
  }

  .e-small.e-filled.e-float-input .e-multi-select-wrapper .e-chips-close.e-close-hooker,
  .e-small .e-filled.e-float-input.e-multiselect .e-down-icon .e-chips-close.e-close-hooker {
    margin-top: -26px;
  }

  .e-filled.e-multiselect:not(.e-float-input) .e-multi-select-wrapper .e-chips:not(.e-mob-chip) .e-chips-close {
    @if $ddl-multiselect-skin-name == 'material' {
      height: $ddl-multiselect-filled-chip-clear-icon-size;
      width: $ddl-multiselect-filled-chip-clear-icon-size;
    }
  }

  .e-small.e-filled.e-multiselect.e-float-input .e-multi-select-wrapper .e-chips:not(.e-mob-chip) .e-chips-close,
  .e-small .e-filled.e-multiselect.e-float-input .e-multi-select-wrapper .e-chips:not(.e-mob-chip) .e-chips-close {
    @if $ddl-multiselect-skin-name == 'material' {
      height: $ddl-multiselect-filled-chip-clear-icon-size-small;
      width: $ddl-multiselect-filled-chip-clear-icon-size-small;
    }
  }

  .e-multiselect.e-filled.e-float-input .e-multi-select-wrapper .e-chips .e-chips-close::before,
  .e-small.e-filled.e-multiselect:not(.e-float-input) .e-multi-select-wrapper .e-chips .e-chips-close::before,
  .e-small .e-filled.e-multiselect:not(.e-float-input) .e-multi-select-wrapper .e-chips .e-chips-close::before {
    @if $ddl-multiselect-skin-name == 'material' {
      font-size: $ddl-multiselect-filled-float-input-chip-clear-icon-size;
      height: $ddl-multiselect-filled-float-input-chip-clear-icon-size;
      width: $ddl-multiselect-filled-float-input-chip-clear-icon-size;
    }
  }

  .e-small.e-multiselect.e-filled.e-float-input .e-multi-select-wrapper .e-chips .e-chips-close::before,
  .e-small .e-multiselect.e-filled.e-float-input .e-multi-select-wrapper .e-chips .e-chips-close::before {
    @if $ddl-multiselect-skin-name == 'material' {
      font-size: $ddl-multiselect-filled-chip-clear-icon-size-small;
      height: $ddl-multiselect-filled-chip-clear-icon-size-small;
      width: $ddl-multiselect-filled-chip-clear-icon-size-small;
    }
  }

  .e-multiselect.e-filled:not(.e-float-input) .e-multi-select-wrapper .e-chips .e-chips-close::before {
    @if $ddl-multiselect-skin-name == 'material' {
      font-size: $ddl-multiselect-filled-chip-clear-icon-size;
      height: $ddl-multiselect-filled-chip-clear-icon-size;
      width: $ddl-multiselect-filled-chip-clear-icon-size;
    }
  }

  .e-filled .e-multi-select-wrapper .e-chips-close.e-close-hooker::before {
    content: '\e208';
    font-size: 16px;
    height: 16px;
    padding: 0;
    text-align: center;
    vertical-align: middle;
    width: 16px;
  }

  .e-small.e-filled .e-multi-select-wrapper .e-chips-close.e-close-hooker::before,
  .e-small .e-filled .e-multi-select-wrapper .e-chips-close.e-close-hooker::before {
    font-size: 14px;
    height: 14px;
    width: 14px;
  }

  .e-rtl.e-multiselect.e-filled .e-multi-select-wrapper .e-chips-close.e-close-hooker,
  .e-rtl .e-multiselect.e-filled .e-multi-select-wrapper .e-chips-close.e-close-hooker {
    @if $ddl-multiselect-skin-name == 'material' {
      left: 10px;
      right: auto;
    }
  }

  .e-small.e-rtl.e-multiselect.e-filled .e-multi-select-wrapper .e-chips-close.e-close-hooker,
  .e-rtl .e-small.e-multiselect.e-filled .e-multi-select-wrapper .e-chips-close.e-close-hooker,
  .e-small .e-rtl.e-multiselect.e-filled .e-multi-select-wrapper .e-chips-close.e-close-hooker,
  .e-rtl.e-small .e-multiselect.e-filled .e-multi-select-wrapper .e-chips-close.e-close-hooker {
    @if $ddl-multiselect-skin-name == 'material' {
      left: 6px;
      right: auto;
    }
  }

  // Filled theme dropdown icon styles
  .e-multiselect.e-input-group.e-filled .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
  .e-multiselect.e-filled.e-input-group.e-control-wrapper .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon {
    @if $ddl-multiselect-skin-name == 'material' {
      margin: 10px 0 9px;
    }
  }

  .e-multiselect.e-filled.e-input-group.e-float-input.e-control-wrapper .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon {
    @if $ddl-multiselect-skin-name == 'material' {
      margin: 0 8px 8px 0;
      padding: 0;
    }
  }

  .e-small.e-multiselect.e-input-group.e-filled .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
  .e-small .e-multiselect.e-filled.e-input-group.e-control-wrapper .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon {
    @if $ddl-multiselect-skin-name == 'material' {
      height: 18px;
      margin: 7px 0 2px;
      width: 18px;
    }
  }

  .e-small.e-multiselect.e-input-group.e-float-input.e-filled .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
  .e-small .e-multiselect.e-filled.e-input-group.e-float-input.e-control-wrapper .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon {
    @if $ddl-multiselect-skin-name == 'material' {
      margin: 0 12px 4px 0;
    }
  }

  .e-multiselect.e-input-group.e-filled .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon::before,
  .e-multiselect.e-filled.e-input-group.e-control-wrapper .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon::before {
    @if $ddl-multiselect-skin-name == 'material' {
      font-size: 16px;
      height: 16px;
      width: 16px;
    }
  }

  .e-small.e-multiselect.e-input-group.e-filled .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon::before,
  .e-small .e-multiselect.e-filled.e-input-group.e-control-wrapper .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon::before {
    @if $ddl-multiselect-skin-name == 'material' {
      font-size: 14px;
      height: 14px;
      width: 14px;
    }
  }

  .e-multiselect.e-outline .e-multi-select-wrapper .e-close-hooker.e-ddl-disable-icon::before,
  .e-small.e-multiselect.e-outline .e-multi-select-wrapper .e-close-hooker.e-ddl-disable-icon::before,
  .e-small .e-multiselect.e-outline .e-multi-select-wrapper .e-close-hooker.e-ddl-disable-icon::before {
    @if $ddl-multiselect-skin-name == 'material' {
      content: '';
    }
  }
}
