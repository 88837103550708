@mixin focused-icon-color {
  @if $skin-name != 'bootstrap5' {
    & .e-caret {
      color: $cmenu-caret-color;
      @if $skin-name == 'fluent2' {
        color: $cmenu-hover-icon-color;
      }
    }
  
    & .e-menu-icon {
      @if $skin-name == 'fluent2' {
        color: $cmenu-hover-icon-color;
      }
      @else if $skin-name == 'bootstrap5.3' {
        color: $cmenu-hover-icon-color;
      }
      @else {
        color: $cmenu-icon-color;
      }
    }
  }
}

@mixin selected-icon-color {
  @if $skin-name != 'bootstrap5' {
    & .e-caret {
      color: $cmenu-caret-hover-color;
    }
  
    & .e-menu-icon {
      @if $skin-name == 'bootstrap5.3' {
        color: $flyout-text-color-pressed;
      }
      @else {
        color: $cmenu-hover-icon-color;
      }
    }
  }
}

@mixin icon-color {
  &.e-menu-header {
    border-bottom-color: $cmenu-li-border-color;
  }
  @include focused-icon-color;
}

@mixin focused-color {
  background-color: $cmenu-li-bgcolor;
  color: $cmenu-li-selection-font-color;
  outline: $cmenu-li-hover-outline;
  outline-offset: $cmenu-li-hover-outline-offset;
  @if $skin-name == 'fluent2' {
    border-radius: 4px;
  }
}

@mixin selected-color {
  background-color: $cmenu-li-selection-bgcolor;
  color: $cmenu-selected-color;
  outline: $cmenu-li-selected-outline;
  outline-offset: $cmenu-li-selected-outline-offset;
  @if $skin-name == 'fluent2' {
    border-radius: 4px;
    box-shadow: none;
  }
}

@mixin disabled-color {
  & .e-disabled {
    color: $cmenu-disable-text;
    @if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' {
      opacity: $cmenu-disabled-item-opacity;
    }
    @else{
      opacity: 1;
    }

    & .e-menu-icon {
      color: $cmenu-icon-disabled-color;
    }

    & .e-caret {
      color: $cmenu-icon-disabled-color;
    }

    & .e-menu-url {
      color: $cmenu-disable-text;
    }
  }
}
