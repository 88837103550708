// Grid-spezifische Mixins
@mixin base-grid-colors {
  border-color: transparent;
  background-color: var(--table-background);
  color: var(--text-color);
}

@mixin row-cell-colors {
  color: var(--text-color);

  &.e-selectionbackground {
    background-color: var(--primary-color);
    color: white;
  }
}

// Hauptgrid-Komponente
.e-grid {
  @include base-grid-colors;

  .e-headertext {
    color: var(--text-color) !important;
  }

  .e-gridheader
    .e-icons:not(.e-icon-hide):not(.e-check):not(.e-stop):not(.e-icon-reorderuparrow):not(
      .e-icon-reorderdownarrow
    ) {
    color: var(--text-color);
  }

  .e-toolbar {
    background-image: none !important;
    background: var(--toolbar-background) !important;
    border-top: 1px solid var(--table-border-color);
    box-shadow: none !important;

    .e-tbar-btn span {
      color: var(--text-color) !important;
    }
  }

  .e-headercontent {
    border-color: var(--table-border-color) !important;
  }

  .e-gridheader {
    border-top-color: transparent !important;
    border-bottom-color: var(--table-border-color) !important;
    background: var(--table-bg-color) !important;
  }

  .e-focused:not(.e-menu-item):not(.e-editedbatchcell) {
    box-shadow: 0 0 0 1px transparent inset;
  }

  .e-rowcell {
    border-color: var(--table-border-color);
  }

  // Tabellen-Grundstruktur
  .e-table {
    color: var(--text-color);
    background-color: var(--background-color);

    &.e-inline-edit {
      position: relative;
      margin-top: -22px;
    }
  }

  // Header
  .e-headercell {
    background-color: var(--table-header-color);
    color: var(--text-color);

    .e-headertext {
      font-weight: bold;
      font-size: 1.2em;
    }
  }

  .e-headercelldiv {
    background-color: var(--table-header-color);
    color: var(--text-color);

    .e-headertext {
      font-weight: bold;
      font-size: 1.2em;
    }
  }

  .e-detailheadercell {
    background-color: var(--table-header-color);

    .e-headertext {
      font-weight: bold;
      font-size: 1.2em;
    }
  }

  // Normale Zeilen
  .e-row {
    background-color: var(--table-row-color);
    min-height: 45px;
    border-color: var(--table-border-color);

    &:hover {
      background-color: var(--table-row-highlight-color) !important;

      .e-cell {
        background: var(--table-row-highlight-color) !important;
      }

      .e-detailrowcollapse {
        background-color: var(--table-row-highlight-color) !important;
      }
    }
  }

  .e-detailrowcollapse {
    border-color: var(--table-border-color);

    &:hover {
      background-color: var(--table-row-highlight-color) !important;
    }
  }

  .e-detailrow {
    border-color: var(--table-border-color);
  }

  // Spezielle Styling für neu hinzugefügte Zeile
  .e-addedrow {
    height: 45px !important;
    line-height: 45px !important;
    background-color: var(--table-row-color) !important;

    .e-rowcell {
      height: 45px !important;
      line-height: 45px !important;
      vertical-align: middle !important;

      .e-focused:not(.e-menu-item):not(.e-editedbatchcell) {
        border: none !important;
      }
    }

    // Styling für Eingabefelder in der neuen Zeile
    .e-input-group {
      height: 35px !important;

      input,
      .e-input {
        height: 100% !important;
        line-height: 35px !important;
        padding: 0 !important;
      }
    }
  }

  .e-rowcell {
    &:not(.e-editedbatchcell):not(.e-updatedtd) {
      @include row-cell-colors;
    }

    .e-selectionbackground .e-active {
      color: white !important;
    }
  }

  // Alternierende Zeilen
  .e-altrow {
    background-color: var(--table-row-alternate-color);
  }

  .e-emptyrow {
    color: var(--text-color) !important;
  }

  // Verhindern von horizontalem Scrolling
  .e-gridcontent {
    overflow-x: hidden;
  }
}

// Hover-Effekte
.e-grid.e-gridhover:not(.e-rowdrag) {
  .e-row:not(.e-disable-gridhover, .e-editedrow, .e-detailrow):hover {
    .e-rowcell:not(.e-cellselectionbackground, .e-active, .e-updatedtd, .e-indentcell) {
      background-color: var(--primary-color);
    }
  }
}

.e-grid.e-gridhover:not(.e-rowdrag)
  .e-row:not(.e-disable-gridhover):not(.e-editedrow):not(.e-detailrow):hover
  .e-rowcell:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell) {
  background-color: var(--table-row-highlight-color);
}

// Zusätzliche Styles für Edit-Modus
.e-edit-dialog {
  .e-edit-form {
    padding: 12px;
  }
}
