$stepper-ol-padding: 0 !default;
$stepper-step-radius: 9999px !default;
$stepper-step-width: 32px !default;
$stepper-icon-size: 16px !default;
$stepper-progress-bar-height: 1.6px !default;
$stepper-progress-value-height: 100% !default;
$stepper-progress-position: 50% !default;
$stepper-temp-outline-width: 2px solid !default;
$stepper-label-top: 1em !default;
$stepper-label-bottom: 4.5em !default;
$stepper-label-position: 2.5em !default;
$stepper-validation-position: .25em !default;
$stepper-text-padding: 0 6px !default;
$stepper-icon-text-padding: 0 8px !default;
$stepper-text-vert-padding: 6px 0 !default;
$stepper-label-optional: 3em !default;
$stepper-text-postion: 10px !default;
$stepper-optional-size: 12px !default;
$step-border: 2px solid !default;
$stepper-label-order: -1 !default;
$stepper-font-weight: 600 !default;
$stepper-text-size: 14px !default;
$stepper-tooltip-tip-bottom-height: 8px !default;

$stepper-small-step-width: 28px !default;
$stepper-small-icon-size: 14px !default;
$stepper-small-optional-size: 10px !default;
$stepper-small-text-size: 12px !default;

$stepper-bigger-step-width: 40px !default;
$stepper-bigger-icon-size: 18px !default;
$stepper-bigger-optional-size: 14px !default;
$stepper-bigger-text-size: 16px !default;

$stepper-bigger-small-step-width: 36px !default;
$stepper-bigger-small-icon-size: 16px !default;
$stepper-bigger-small-optional-size: 12px !default;
$stepper-bigger-small-text-size: 14px !default;

$step-selected-li-shadow: 0 0 0 2px $grey-white, 0 0 0 4px $primary, 0 0 0 8px $grey-white !default;
$step-keyboard-selected-li-shadow: 0 0 0 2px $grey-white, 0 0 0 4px $base-font, 0 0 0 8px $grey-white !default;
$step-valid-li-shadow: 0 0 0 2px $grey-white, 0 0 0 4px $msg-success-color-alt1, 0 0 0 8px $grey-white !default;
$step-error-li-shadow: 0 0 0 2px $grey-white, 0 0 0 4px darken($error-font, 11%), 0 0 0 8px $grey-white !default;
$step-li-shadow: 0 0 0 8px $grey-white !default;
$step-border-color: $grey-400 !default;
$step-hover-text-color: $primary-300-font !default;
$step-hover-not-text-color: $base-font !default;
$step-hover-border-color: $grey-400 !default;
$step-active-border-color: $grey-400 !default;
$stepper-outline-color: $grey-white !default;
$step-color: $base-font !default;
$step-optional-color: $grey-800 !default;
$step-bg-color: $primary-300-font !default;
$step-completed-color: $primary-300-font !default;
$step-completed-bg-color: $primary !default;
$step-progress-bg-color: $grey-400 !default;
$step-progress-value-bg-color: $primary !default;
$step-disabled-bg-color: $grey-300 !default;
$step-disabled-font-color: $grey-500 !default;
$step-disabled-color: $grey-600 !default;
$stepper-text-color: $base-font !default;
$step-valid-icon-only-color:$msg-success-color-alt1 !default;
$step-error-icon-only-color: $error-font !default;
$step-valid-completed-color: $primary-300-font !default;
$step-valid-completed-bg-color: $msg-success-color-alt1 !default;
$step-error-icon-color: $primary-300-font !default;
$step-error-completed-color: darken($error-font, 11%) !default;
$step-error-completed-bg-color: darken($error-font, 11%) !default;
$step-hover-color: $grey-500 !default;
$step-active-color: $grey-600 !default;
$step-selected-hover-color: darken($primary, 10%) !default;
$step-selected-active-color: darken($primary, 15%) !default;
