.e-checkbox-wrapper {
  .e-label {
    color: var(--text-color) !important;
  }

  .e-frame {
    background: var(--input-background) !important;
    border-width: 1px;

    &.e-check {
      background: var(--primary-color) !important;
    }
  }
}
