@include export-module('datepicker-material-icons') {

  /*! component icons */

  .e-input-group-icon.e-date-icon,
  #{if(&, '&', '*')}.e-control-wrapper .e-input-group-icon.e-date-icon {
    font-size: $datepicker-icon-font-size;
    margin: $datepicker-icon-normal-margin;
    min-height: $datepicker-icon-container-min-height;
    min-width: $datepicker-icon-container-min-width;
    outline: none;

    #{if(&, '&', '*')}::before {
      content: '\e901';
      font-family: 'e-icons';
    }

    #{if(&, '&', '*')}:focus {
      background: $datepicker-icon-hover-color;
      border-radius: $datepicker-icon-border-radius;
    }
  }

  // mouse small icon
  .e-small .e-input-group-icon.e-date-icon,
  #{if(&, '&', '*')}.e-control-wrapper.e-small .e-input-group-icon.e-date-icon,
  #{if(&, '&', '*')}.e-small .e-control-wrapper .e-input-group-icon.e-date-icon {
    font-size: $datepicker-small-icon-font-size;
  }

  // Outline textbox icon configuration

  .e-date-wrapper.e-outline .e-input-group-icon.e-date-icon,
  .e-date-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-date-icon {
    min-height: $zero-value;
    min-width: 20px;
  }

  .e-small .e-date-wrapper.e-outline .e-input-group-icon.e-date-icon,
  .e-date-wrapper.e-control-wrapper.e-small.e-outline .e-input-group-icon.e-date-icon,
  .e-small .e-date-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-date-icon {
    min-height: $zero-value;
    min-width: 18px;
  }

  .e-datepick-mob-popup-wrap {
    
    #{if(&, '&', '*')} .e-datepicker.e-popup-expand {
  
      #{if(&, '&', '*')} .e-model-header {
  
        #{if(&, '&', '*')} .e-popup-close::before {
          content: '\e932';
          font-family: 'e-icons';
        }
      }
    }
  }
}
