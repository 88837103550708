//define base colors

$sr-clean-white: #ffffff;
$sr-grey-petrol: #bac7cc;
$sr-soft-grey: #f5f5f5;

$sr-light-grey: #d3dbdb;
$sr-lighter-grey: #9fabab;
$sr-grey: #788282;
$sr-darker-grey: #596060;
$sr-dark-grey: #36383b;
$sr-lighter-black: #282828;
$sr-black: #1a1a1a;
$sr-deeper-black: #101010;
$sr-deep-black: #000;

$sr-light-grey-blue: #6d9ac8;
$sr-lighter-grey-blue: #527598;
$sr-grey-blue: #34495e;
$sr-darker-grey-blue: #213340;
$sr-dark-grey-blue: #152129;

$sr-light-prof-blue: #565e72;
$sr-lighter-prof-blue: #3f4554;
$sr-prof-blue: #2a2e38;
$sr-darker-prof-blue: #1c1f25;
$sr-dark-prof-blue: #0f1114;

$sr-dark-blue: #3c4351;

$sr-light-power-orange: #ffb299;
$sr-lighter-power-orange: #ff6533;
$sr-power-orange: #ff4500;
$sr-darker-orange: #882107;
$sr-dark-orange: #441b04;

$header-height: 50px;
$footer-height: 50px;
$sidebar-width: 240px;

$button-height: 40px;

$border-radius: 15px;
$border-style: 1px solid var(--border-color);

:root {
  // Schriftgrößen
  --font-size-small: 5px;
  --font-size-medium: 15px;
  --font-size-large: 20px;
  --font-size-larger: 25px;

  // Abstände
  --spacing-small: 5px;
  --spacing-medium: 15px;
  --spacing-large: 20px;
  --spacing-extra-large: 25px;

  // Breakpoints
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
}

* {
  /* Ermöglicht Scrollen */
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Scrollbar-Track (Führungsschiene) */
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
    margin: 4px;
  }

  /* Scrollbar-Thumb (der bewegliche Teil) */
  &::-webkit-scrollbar-thumb {
    background: var(--primary-color);
    border-radius: 10px;
    border: 2px solid transparent;
    background-clip: padding-box;
    color: var(--primary-color);

    &:hover {
      background: rgba(155, 155, 155, 0.7);
      border: 2px solid transparent;
      background-clip: padding-box;
    }
  }

  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: rgba(155, 155, 155, 0.5) transparent;

  /* IE */
  -ms-overflow-style: none;
}
