$slider-bg-color: rgba($grey-black, .26) !default;
$slider-disabled-bg-color: rgba($grey-black, .26) !default;
$slider-disabled-opacity: 1 !default;
$slider-active-bg-color: rgba($grey-black, .38) !default;
$slider-start-handle-border-size: 2px solid rgba($grey-black, .26) !default;
$slider-border-radius: 2px !default;
$slider-height: 2px !default;
$slider-handle-border-size: 2px solid !default;
$slider-handle-border-color: rgba($grey-black, .26) !default;
$slider-handle-height: 12px !default;
$slider-handle-width: 12px !default;
$slider-handle-border-radius: 50% !default;
$slider-handle-top: -6px !default;
$slider-handle-mobile-top: calc(50% - 7px) !default;
$slider-h-handle-margin: 0 0 0 -6px !default;
$slider-h-handle-mobile-margin: 0 0 0 -7px !default;
$rtl-slider-h-handle-margin: 0 -6px 0 0 !default;
$rtl-slider-h-handle-mobile-margin: 0 -7px 0 0 !default;
$slider-v-handle-margin: -6px !default;
$slider-v-handle-mobile-margin: 0 0 -7px 0 !default;
$rtl-slider-v-handle-margin: 0 !default;
$rtl-slider-v-handle-mobile-margin: -7px 0 0 0 !default;
$slider-range-bar-color: $accent !default;
$slider-range-bar-hover-color: $accent !default;
$slider-color: $accent !default;
$slider-material-tooltip-content-color: $grey-white !default;
$slider-handle-color: $grey-white !default;
$slider-button-icon-color: $grey-white !default;
$slider-handle-start-border-color: rgba($grey-black, .26) !default;
$slider-handle-focus-border-color: rgba($grey-black, .38) !default;
$slider-handle-focus-bg-color: rgba($grey-black, .26) !default;
$slider-tick-color: rgba($grey-black, .87) !default;
$slider-button-bg-color: rgba($grey-black, .54) !default;
$slider-btn-focus-color:  rgba($grey-black, .54) !default;
$slider-btn-focus-border-color: transparent !default;
$slider-btn-focus-after-color: transparent !default;
$slider-container-height: 2px !default;
$slider-button-color-border: transparent !default;
$slider-normal-handle-border-size: 7px solid !default;
$slider-normal-handle-border-color: $accent !default;
$slider-handle-bg-color: $accent !default;
$slider-handle-hover-color: $accent !default;
$slider-disabled-handle-bg-color: rgba($grey-black, .26) !default;
$slider-disabled-handle-hover-color: rgba($grey-black, .26) !default;
$slider-disabled-handle-normal-border-color: $grey-white !default;
$slider-disabled-normal-handle-border-color: $grey-white !default;
$slider-disabled-range-bar-color: transparent !default;
$slider-disabled-button-bg-color: rgba($grey-black, .26) !default;
$slider-hover-color: rgba($grey-black, .26) !default;
$slider-tooltip-padding: 3px 6px !default;
$slider-start-transform: scale(.714) !default;
$slider-mid-transform: scale(1.05) !default;
$slider-final-transform: scale(1) !default;
$slider-handle-after-position: absolute !default;
$slider-handle-normal-border-color: $accent !default;
$slider-handle-hover-border-color: $accent !default;
$tick-after-height: 7px !default;
$tick-after-top: 5px;
$largetick-before-top: 5px !default;
$largetick-before-height: 10px !default;
$largetick-after-height: 10px !default;
$largetick-after-top: 13px !default;
$tick-before-height: 7px !default;
$tick-before-top: 10px !default;
$slider-btn-container-height: 2px !default;
$slider-horizontal-btn-icon-prev: '\e981' !default;
$slider-vertical-btn-icon-prev: '\e969' !default;
$slider-horizontal-btn-icon-next: '\e943' !default;
$slider-vertical-btn-icon-next: '\e995' !default;
$slider-vertical-width: 2px !default;
$slider-border-radius-handle: 50% !default;
$slider-border: 0 !default;
$slider-btn-top: 0 !default;
$slider-btn-after-height: 100% !default;
$slider-btn-after-width: 100% !default;
$slider-btn-after-padding: 10px !default;
$slider-btn-margin-right: 2px !default;
$slider-btn-margin-top: 0 !default;
$slider-vertical-left: -5px !default;
$slider-button-font-size: 9px !default;
$slider-scale-font-family: $font-family !default;
$slider-scale-font-size: 10px !default;
$slider-mobile-scale-font-size: 12px !default;
$slider-scale-tick-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAYAAABytg0kAAAACXBIWXMAAAsSAAALEgHS3X78AAAAFElEQVQI12NkYGAIZ2BgYGBigAIABPgAW+yfXroAAAAASUVORK5CYII=');
$slider-disabled-handle-color: $grey-white !default;
$slider-disabled-handle-show: block !default;
$slider-button-border-color: transparent !default;
$slider-first-btn-margin: 1px !default;
$slider-second-btn-margin: -4px !default;
$slider-button-size: 18px !default;
$slider-button-horizon-padding: 0 30px !default;
$slider-button-vertical-padding: 30px 0 !default;
$slider-scale-value-position-top: -30px !default;
$slider-scale-value-position-bottom: -8px !default;
$tick-after-left: 8px !default;
$largetick-before-left: 8px !default;
$tick-before-left: 8px !default;
$largetick-after-left: 8px !default;
$slider-scale-value-position-left: -28px !default;
$slider-scale-value-position-right: -28px !default;
$slider-scale-value-position-both-left: -28px !default;
$slider-scale-value-position-both-right: -72px !default;
$slider-vertical-btn-right: 0 !default;
$slider-horizon-prve-btn-left: 0 !default;
$slider-horizon-next-btn-left: 0 !default;
$slider-vertical-prve-btn-left: 0 !default;
$slider-vertical-next-btn-left: 0 !default;
$slider-handle-start-top: calc(50% - 4px) !default;
$slider-handle-start-left: calc(50% - 4px) !default;
$slider-tooltip-margin: 0 !default;
$slider-wrap-height: 18px !default;
$slider-wrap-width: 18px !default;
$slider-center-align: -1px !default;
$tick-before-mobile-top: 2px !default;
$tick-after-mobile-top: 2px !default;
$largetick-before-mobile-top: 2px !default;
$largetick-after-mobile-top: 2px !default;
$slider-btn-center-align: -9px !default;
$tick-both-top: $tick-before-top !default;
$tick-both-left: $tick-before-left !default;
$largetick-both-top: $largetick-after-top !default;
$largetick-both-left: $largetick-after-left !default;
$slider-range-height: $slider-container-height !default;
$slider-range-width: $slider-vertical-width !default;
$slider-range-border-radius: $slider-border-radius !default;
$slider-first-btn-font-size: 8px !default;
$slider-first-btn-height: 24px !default;
$slider-first-btn-line-height: 18px !default;
$slider-second-btn-margin-right: -1px !default;
$slider-second-btn-font-size: 8px !default;
$slider-second-btn-height: 24px !default;
$slider-second-btn-line-height: 18px !default;
$slider-button-hover-transform: 1.667 !default;
$slider-cursor: default !default;
$slider-handle-hover-cursor: grab !default;
$slider-handle-active-cursor: grabbing !default;
$slider-scale-top: calc(50% - 14px) !default;
$slider-scale-right: 5px !default;
$slider-tab-background: $grey-300 !default;
$slider-handle-start-background: $grey-white !default;
$slider-limit-bar-horizontal-height: 2px !default;
$slider-limit-bar-top: calc(50% - 1px) !default;
$slider-limit-bar-vertical-width: 2px !default;
$slider-limit-bar-left: calc(50% - 1px) !default;
$slider-limit-bar-color: rgba($grey-black, .26) !default;
$slider-tick-font-color: $grey-light-font !default;

// dummy variable declaration to avoid compilation issue in SASS
$slider-handle-mobile-height: none !default;
$slider-handle-mobile-width: none !default;
$slider-button-hleft-bg-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAAkFBMVEUAAAD////////////////////////+/v7////+/v7////////+/v7+/v7////////+/v7+/v7+/v7////+/v7+/v7+/v7+/v7////+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7///8G+5iqAAAAL3RSTlMABAgMEBQYHCAkKDA0ODxETFRYYGRsdHyAh4uTm6Onr7O/w8fL09ff4+fr7/P3+/6nVicAAADJSURBVHgBldJHdoNgAANhQUjvPTHuxbgAuv/t/J5O8I82Wn27UfGaVmjvBwvsdmEDUH33JuBxYwNQ/48m4LWzAbie2QR8nkzA/coGoPodTMDzzgagae1ykHAASDgAJBwAEk4xSDgAJBwAEg4ACacYJBwAEg4ACYeAjyMEaqYQSC97CFT/DQxID2sIpK8zBLqZQyC9dRDoajIyID1tIVD10zMg3S0ZSF4IJC8GkhcDyQuB5MVA8kIgeTGQvBhIXggkLwaSFwLJK3cBRvanB71ijlkAAAAASUVORK5CYII=');
$slider-button-hright-bg-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAAkFBMVEUAAAD////////////////////////+/v7////////+/v7+/v7+/v7////////+/v7////+/v7+/v7+/v7+/v7+/v7////////+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7///9VYKa9AAAAL3RSTlMABAgMEBQYHCAoLDQ4PEBMUFhcZGx0eICDi5Obn6ers7vDx8vP19vf4+fr7/P3++LF0fAAAADNSURBVHgBldNHbsNAGEPhkZSi9F4S915k6d3/dga8Jgjw7bkYzPeXS5ObkkX3EQ5gcRcO6H/qbACbp3AAo6twwOEtHMCsDQecvqpsAKuHcMDw12QD2L2EA/C8EFleiCwvRJYXIssLmeGFzPBCZnghM7yQGV7IDC9Eglc26D6zwfS2JIP9a/To4b8pyWD9GH1c/11FNOZthO/4nvEeX5dksH2OTnT4rUsyWN4Xk4cj8nBEHo7IwxF5OCIPR+ThiDwckYcj8nBEHo5Iw5GdAVm8p9sPUXNKAAAAAElFTkSuQmCC');
$slider-button-vdown-bg-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAjVBMVEUAAAD////////////////////////+/v7////+/v7////////+/v7////////+/v7+/v7+/v7+/v7////+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7////1kjJ6AAAALnRSTlMABAgMEBQYHCAkKDA4QERITFRcYGRodHyDj5OXn6Ons7e7v8vP09ff5+vv8/f7zZvJMAAAAItJREFUeAHdyVcagjAQBsAfUVTAXsTeSTRm7388i/kUQjYHYF4H9bAUXjuEV/KQEdC98f9I8dLXxJngY879CsbG/ccARuPsetHCT1tWXyUoSDXZRiiZ2p/Bsi7/HrbgVPw8REUk/n+P4ZAoMvQATmMyFmBk39+CExzefwnBauZEsgOPWOkevIYz1NEToo45F58KJy0AAAAASUVORK5CYII=');
$slider-button-vup-bg-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAjVBMVEUAAAD////////////////////////////////+/v7////+/v7+/v7////////+/v7+/v7////+/v7////+/v7+/v7////+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7////wrNfvAAAALnRSTlMABAgMEBQYICgsMDQ4QERITFBcYGhseHyDi5Obn6Ort7u/x8/X29/j5+vv8/f77y3LJgAAAI1JREFUeAHdyVeagjAUBtCbYQTsXewFENGYf//LsyB8kORmAZzXQ600mLrfz2XX9X8xcO0Qb4+3s2B/ia8N9yOFwtz+wR0/z77tvQSVm0+mI2piYXyEhp3+E2gWzQ8lNGpY//8UhjygijjBIvGotIbVofwZGFHxPQmGGn/ez8B6hETiAofUo1XmtKVWeAEhzjgqqZr/lwAAAABJRU5ErkJggg==');
