@include export-module('pdfviewer-material-icons') {
  .e-pdfviewer {
    .e-pv-icon::before {
      font-family: 'e-icons';
    }

    .e-pv-icon-search::before {
      font-family: 'e-icons';
      font-size: 12px;
    }

    .e-pv-open-document-icon::before {
      content: '\ec04';
    }

    .e-pv-download-document-icon::before {
      content: '\ec0b';
    }

    .e-pv-print-document-icon::before {
      content: '\ec20';
    }

    .e-pv-first-page-navigation-icon::before {
      content: '\ec05';
    }

    .e-pv-rotate-right-icon::before{
      content: '\e960';
    }

    .e-pv-organize-view-icon::before{
      content: '\e99f';
    }

    .e-pv-organize-view-disable-icon::before{
      color: $pv-thumbnail-icon-disable-color;
      content: '\e99f';
    }

    .e-pv-organize-view-selection-icon::before{
      color: $pv-thumbnail-icon-selection-color;
      content: '\e99f';
    }

    .e-pv-rotate-left-icon::before{
      content: '\e95f';
    }

    .e-pv-previous-page-navigation-icon::before,
    .e-pv-prev-search-icon::before {
      content: '\ec06';
    }

    .e-pv-next-page-navigation-icon::before,
    .e-pv-next-search-icon::before {
      content: '\ec07';
    }

    .e-pv-last-page-navigation-icon::before {
      content: '\ec08';
    }

    .e-pv-zoom-out-icon::before {
      content: '\ec09';
      line-height: $pv-toolbaritem-btn-line-height;
    }

    .e-pv-zoom-in-icon::before {
      content: '\ec0a';
      line-height: $pv-toolbaritem-btn-line-height;
    }

    .e-pv-thumbnail-view-icon::before {
      content: '\ec27';
    }

    .e-pv-stamp-icon::before {
      content: '\ec28';
    }

    .e-pv-thumbnail-view-disable-icon::before {
      color: $pv-thumbnail-icon-disable-color;
      content: '\ec27';
    }

    .e-pv-thumbnail-view-selection-icon::before {
      color: $pv-thumbnail-icon-selection-color;
      content: '\ec27';
    }

    .e-pv-bookmark-icon::before {
      content: '\ec0c';
    }

    .e-pv-bookmark-disable-icon::before {
      color: $pv-thumbnail-icon-disable-color;
      content: '\ec0c';
    }

    .e-pv-bookmark-selection-icon::before {
      color: $pv-thumbnail-icon-selection-color;
      content: '\ec0c';
    }

    .e-pv-title-close-icon::before,
    .e-pv-annotation-tools-close-icon::before,
    .e-pv-annotation-popup-close::before {
      content: '\ec0f';
    }

    .e-pv-resize-icon::before {
      content: '\e84b';
      font-size: 10px;
    }

    .e-pv-text-select-tool-icon::before {
      content: '\ec1d';
    }

    .e-pv-pan-tool-icon::before {
      content: '\ec1a';
    }

    .e-pv-text-search-icon::before {
      content: '\ec0d';
    }

    .e-pv-search-icon::before {
      content: '\ec0d';
      font-family: 'e-icons';
    }

    .e-pv-search-close::before {
      content: '\ec0f';
      font-family: 'e-icons';
    }

    .e-pv-formdesigner-icon::before {
      content: '\e90c';
    }

    .e-pv-annotation-icon::before {
      content: '\ec26';
    }

    .e-pv-annotation-color-icon::before {
      content: '\ec19';
    }

    .e-pv-annotation-stroke-icon::before {
      content: '\e668';
    }

    .e-pv-annotation-opacity-icon::before {
      content: '\ec1b';
    }

    .e-pv-annotation-thickness-icon::before {
      content: '\ec25';
    }

    .e-pv-annotation-delete-icon::before {
      content: '\ec1c';
    }

    .e-pv-undo-icon::before {
      content: '\ec14';
    }

    .e-pv-redo-icon::before {
      content: '\ec21';
    }

    .e-pv-more-icon::before {
      content: '\ec16';
    }

    .e-pv-backward-icon::before {
      content: '\e977';
    }

    .e-pv-notification-icon {
      background-image: $pv-corrupted-notification-background-image;
      background-repeat: $pv-corrupted-notification-background-repeat;
      background-size: $pv-corrupted-notification-background-size;
      height: $pv-corrupted-notification-height;
    }

    .e-pv-notification-icon-rtl {
      background-image: $pv-corrupted-notification-background-image;
      background-position: $pv-corrupted-notification-background-position;
      background-repeat: $pv-corrupted-notification-background-repeat;
      background-size: $pv-corrupted-notification-background-size;
      height: $pv-corrupted-notification-height;
    }

    .e-pv-textbox-icon::before {
      content: '\e89f';
    }

    .e-pv-password-icon::before {
      content: '\e91a';
    }

    .e-pv-checkbox-icon::before {
      content: '\e90b';
    }

    .e-pv-radiobutton-icon::before {
      content: '\e90f';
    }

    .e-pv-dropdown-icon::before {
      content: '\e90e';
    }

    .e-pv-listbox-icon::before {
      content: '\e89e';
    }

    .e-pv-annotation-shape-icon::before {
      content: '\ec23';
    }

    .e-pv-annotation-calibrate-icon::before {
      content: '\e678';
    }
  }

  .e-pv-download-document-icon.e-menu-icon::before {
    content: '\ec0b';
  }

  .e-pv-bookmark-icon.e-menu-icon::before {
    content: '\ec0c';
  }
  
  .e-pv-save-icon.e-menu-icon::before {
    content: '\e703';
  }

  .e-pv-save-as-icon.e-menu-icon::before {
    content: '\e704';
  }

  .e-pv-highlight-icon::before {
    content: '\ec15';
    font-family: 'e-icons';
  }

  .e-pv-underline-icon::before {
    content: '\ec17';
    font-family: 'e-icons';
  }

  .e-pv-strikethrough-icon::before {
    content: '\ec18';
    font-family: 'e-icons';
  }

  .e-pv-copy-icon::before {
    content: '\e33d';
    font-family: 'e-icons';
  }

  .e-pv-import-icon::before{
    content: '\e9d6';
    font-family: 'e-icons';
  }

  .e-pv-cut-icon::before {
    content: '\e33b';
    font-family: 'e-icons';
  }

  .e-pv-paste-icon::before {
    content: '\e355';
    font-family: 'e-icons';
  }

  .e-pv-delete-icon::before {
    content: '\ec1c';
    font-family: 'e-icons';
  }

  .e-pv-properties-fill-color-icon::before {
    content: '\ec19';
    font-family: 'e-icons';
  }

  .e-pv-properties-stroke-color-icon::before {
    content: '\e668';
    font-family: 'e-icons';
  }

  .e-pv-shape-line-icon::before {
    content: '\e668';
    font-family: 'e-icons';
  }

  .e-pv-shape-arrow-icon::before {
    content: '\e669';
    font-family: 'e-icons';
  }

  .e-pv-shape-rectangle-icon::before {
    content: '\e670';
    font-family: 'e-icons';
  }

  .e-pv-shape-circle-icon::before {
    content: '\e671';
    font-family: 'e-icons';
  }

  .e-pv-shape-pentagon-icon::before {
    content: '\e672';
    font-family: 'e-icons';
  }

  .e-pv-comment-icon::before {
    content: '\e680';
    font-family: 'e-icons';
  }

  .e-pv-property-icon::before {
    content: '\e199';
    font-family: 'e-icons';
  }

  .e-pv-comment-selection-icon::before {
    color: $pv-thumbnail-icon-selection-color;
    content: '\e680';
    font-family: 'e-icons';
  }

  .e-pv-comment-panel-icon::before {
    content: '\eb5d';
    font-family: 'e-icons';
  }

  .e-pv-accepted-icon::before {
    color: $pv-status-icon-color;
    content: '\e682';
    font-family: 'e-icons';
    font-size: $pv-status-icon-font-size;
    padding: $pv-accepted-icon-padding;
    position: $pv-status-icon-position;
  }

  .e-pv-rejected-icon::before {
    color: $pv-status-icon-color;
    content: '\e683';
    font-family: 'e-icons';
    font-size: $pv-status-icon-font-size;
    padding: $pv-rejected-icon-padding;
    position: $pv-status-icon-position;
  }

  .e-pv-completed-icon::before {
    color: $pv-status-icon-color;
    content: '\e614';
    font-family: 'e-icons';
    font-size: $pv-status-icon-font-size;
    padding: $pv-status-icon-padding;
    position: $pv-status-icon-position;
  }

  .e-pv-cancelled-icon::before {
    color: $pv-status-icon-color;
    content: '\e60a';
    font-family: 'e-icons';
    font-size: $pv-status-icon-font-size;
    padding: $pv-status-icon-padding;
    position: $pv-status-icon-position;
  }

  .e-pv-scale-ratio-icon::before {
    content: '\e678';
    font-family: 'e-icons';
  }

  .e-pv-calibrate-distance-icon::before {
    content: '\e673';
    font-family: 'e-icons';
  }

  .e-pv-calibrate-perimeter-icon::before {
    content: '\e674';
    font-family: 'e-icons';
  }

  .e-pv-calibrate-area-icon::before {
    content: '\e675';
    font-family: 'e-icons';
  }

  .e-pv-calibrate-radius-icon::before {
    content: '\e676';
    font-family: 'e-icons';
  }

  .e-pv-calibrate-volume-icon::before {
    content: '\e677';
    font-family: 'e-icons';
  }

  .e-pv-annotation-stamp-container .e-menu-wrapper ul .e-menu-item .e-caret::before {
    content: '\e969';
    font-size: 8px;
  }

  .e-pv-freetext-icon::before {
    content: '\ec0e';
    font-family: 'e-icons';
  }

  .e-pv-annotation-textcolor-icon::before {
    content: '\e34c';
  }

  .e-pv-annotation-textalign-icon::before {
    content: '\e33a';
    font-family: 'e-icons';
  }

  .e-pv-annotation-textprop-icon::before {
    content: '\eb04';
    font-family: 'e-icons';
  }

  .e-pv-left-align-icon::before {
    content: '\e33a';
    font-family: 'e-icons';
  }

  .e-pv-right-align-icon::before {
    content: '\eb5f';
    font-family: 'e-icons';
  }

  .e-pv-center-align-icon::before {
    content: '\e35e';
    font-family: 'e-icons';
  }

  .e-pv-justfiy-align-icon::before {
    content: '\e334';
    font-family: 'e-icons';
  }

  .e-pv-bold-icon::before {
    content: '\eb47';
    font-family: 'e-icons';
  }

  .e-pv-italic-icon::before {
    content: '\e35a';
    font-family: 'e-icons';
  }

  .e-pv-strikeout-icon::before {
    content: '\e332';
    font-family: 'e-icons';
  }

  .e-pv-underlinetext-icon::before {
    content: '\e343';
    font-family: 'e-icons';
  }

  .e-pv-superscript-icon::before {
    content: '\e352';
    font-family: 'e-icons';
  }

  .e-pv-subscript-icon::before {
    content: '\e357';
    font-family: 'e-icons';
  }

  .e-pv-handwritten-icon::before {
    content: '\e737';
    font-family: 'e-icons';
  }

  .e-pv-inkannotation-icon::before {
    content: '\ec13';
    font-family: 'e-icons';
  }

  .e-pv-delete::before {
    content: '\e94a';
    font-family: 'e-icons';
  }

  .e-pv-eye-icon::before {
    content: '\e345';
    cursor: $pv-eye-icon-hover-cursor;
    font-family: 'e-icons';
    font-size: $pv-eye-icon-font-size;
    opacity: $pv-eye-icon-opacity;
    padding: $pv-eye-icon-padding;
  }

  .e-pv-eye-slash-icon::before {
    content: '\e721';
    cursor: $pv-eye-icon-hover-cursor;
    font-family: 'e-icons';
    font-size: $pv-eye-icon-font-size;
    opacity: $pv-eye-icon-opacity;
    padding: $pv-eye-icon-padding;
  }
}
