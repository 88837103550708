@mixin tab-mob-last-item-margin {
  @media screen and (max-width: 480px) {
    margin: $tab-mob-last-item-margin;
  }
}
@mixin tab-mob-close-icon-margin {
  @media screen and (max-width: 480px) {
    margin: $tab-mob-close-icon-margin;
  }
}
@include export-module('tab-layout') {
  .e-tab {
    display: block;
    position: relative;

    &.e-hidden {
      display: none;
    }

    &.e-fill-mode .e-content,
    &.e-fill-mode .e-content .e-item,
    &.e-fill-mode .e-content .e-item > div,
    &.e-fill-mode .e-content .e-item > div > .e-blazor-template {
      height: 100%;
    }

    .e-tab-header {
      height: $tab-nrml-height;
      min-height: $tab-nrml-height;

      @media screen and (max-width: 480px) {
        height: $tab-big-height;
        min-height: $tab-big-height;
      }

      &::before {
        content: '';
        position: absolute;
        display: $tab-nrml-hdr-before-display;
      }

      &:not(.e-vertical) {

        &::before {
          bottom: 0;
          top: 0;
          width: 100%;
        }
      }

      .e-toolbar-items {
        height: auto;
        margin: 0;
        min-height: $tab-nrml-height;
        position: relative;

        @media screen and (max-width: 480px) {
          min-height: $tab-big-height;
        }

        &:not(.e-tbar-pos) .e-toolbar-item:first-child,
        &:not(.e-tbar-pos) .e-toolbar-item:last-child {
          margin: $tab-nrml-first-item-margin;
        }

        &:not(.e-tbar-pos) .e-toolbar-item:last-child {
          margin: $tab-nrml-last-item-margin;
          padding-bottom: $tab-big-boot-active-item-padding-bottom;
          @include tab-mob-last-item-margin;
        }

        .e-toolbar-item.e-active {
          position: $tab-nrml-item-active-position;

          &:hover::before {
            left: $tab-nrml-item-active-hover-before-left;
            right: $tab-nrml-item-active-hover-before-right;
          }

          &::before {
            background-color: $tab-nrml-item-active-before-bg-font;
            bottom: $tab-nrml-item-active-before-bottom;
            content: $tab-nrml-item-active-before-content;
            height: $tab-nrml-item-active-before-height;
            left: $tab-nrml-item-active-before-left;
            position: $tab-nrml-item-active-before-position;
            right: $tab-nrml-item-active-before-left;
            transition: $tab-nrml-item-active-before-transition;
          }

          &::after {
            color: $tab-nrml-item-active-after-font;
            content: $tab-nrml-item-active-after-content;
            display: $tab-nrml-item-active-after-display;
            font-weight: $tab-nrml-item-active-after-font-weight;
            height: $tab-nrml-item-active-after-height;
            overflow: $tab-nrml-item-active-after-overflow;
            visibility: $tab-nrml-item-active-after-overflow;
          }
        }
      }

      .e-toolbar-items.e-hscroll {

        &.e-scroll-device {
          padding: 0;

          .e-scroll-right-nav {
            display: none;
          }
        }

        .e-hscroll-bar {
          padding: $tab-nrml-hscroll-bar-padding;
        }

        .e-scroll-nav {
          width: $tab-nrml-hscroll-items-nav-width;
          border-radius: $tab-nrml-hscroll-items-bdr-radius;
        }

        &:not(.e-tbar-pos) .e-toolbar-item:last-child {
          margin: $tab-nrml-scroll-last-item-margin;
          @include tab-mob-last-item-margin;

          &.e-active {
            margin: $tab-nrml-active-scroll-last-item-margin;
          }
        }
      }

      .e-hscroll-bar {
        overflow: hidden;
      }

      .e-indicator {
        display: $tab-anim-border-display;
        position: absolute;

        &.e-hidden {
          display: none;
        }
      }

      &:not(.e-vertical) .e-indicator {
        bottom: 0;
        height: $tab-border-width;
        left: 0;
        right: 0;
        border-radius: $tab-nrml-vertical-indicator-bdr-radius;
        transition: $tab-nrml-indicator-transition;
      }

      .e-toolbar-item {

        &.e-hidden {
          display: none;
        }

        &:not(.e-separator) {
          height: $tab-nrml-height;
          margin: $tab-nrml-item-margin;
          min-height: $tab-nrml-height;
          min-width: auto;
          padding: $tab-nrml-item-padding;

          @media screen and (max-width: 480px) {
            height: $tab-big-height;
            min-height: $tab-big-height;
          }
        }

        .e-tab-wrap {
          height: $tab-nrml-height;
          padding: $tab-nrml-wrap-padding;
          width: 100%;

          @media screen and (max-width: 480px) {
            height: $tab-big-height;
            padding: $tab-mob-wrap-padding;
          }
        }

        .e-text-wrap {
          align-content: center;
          align-items: center;
          display: inline-flex;
          height: $tab-nrml-item-text-wrap-height;

          @media screen and (max-width: 480px) {
            height: $tab-big-height;
          }
        }

        .e-tab-text {
          display: inherit;
          font-family: $tab-font-family;
          font-size: $tab-nrml-font-size;
          font-weight: $tab-font-weight;
          text-transform: $tab-text-transform;
          align-self: auto;
          margin-bottom: $tab-nrml-item-text-margin-bottom;

          @media screen and (max-width: 480px) {
            font-size: $tab-big-font-size;
          }
        }

        &.e-active {
          margin: $tab-nrml-boot-item-margin;
          padding-bottom: $tab-nrml-even-boot-active-item-padding-bottom;

          @media screen and (max-width: 480px) {
            margin: $tab-mob-boot-item-margin;
          }

          .e-text-wrap {
            height: $tab-nrml-item-text-wrap-height;
            margin-top: $tab-active-text-container-margin;

            @media screen and (max-width: 480px) {
              height: $tab-big-active-text-container-height;
            }
          }

          .e-tab-wrap {
            margin-bottom: $tab-nrml-active-item-wrap-margin-bottom;
          }

          .e-tab-text {
            font-weight: $tab-semi-font-weight;
          }

          .e-tab-icon::before {
            top: $tab-item-icon-before-top;
            left: $tab-item-icon-before-left;
          }

          .e-icon-top.e-tab-icon::before,
          .e-icon-bottom.e-tab-icon::before {
            left: 0;
          }

          &.e-ileft,
          &.e-iright {

            .e-text-wrap {
              height: $tab-nrml-item-text-wrap-height;
              padding: 0;

              @media screen and (max-width: 480px) {
                height: $tab-big-active-it-text-container-height;
              }
            }
          }
        }

        .e-icons.e-close-icon {
          display: none;
          margin: $tab-nrml-close-icon-margin;
          min-width: $tab-close-icon-minwidth;
          @include tab-mob-close-icon-margin;
        }

        .e-close-icon,
        .e-close-icon::before {
          font-size: $tab-nrml-close-icon-size;

          @media screen and (max-width: 480px) {
            font-size: $tab-mob-close-icon-size;
          }
        }

        .e-close-icon::before {
          top: 1px;
        }

        .e-icons.e-tab-icon {
          display: inline-flex;
          height: $tab-nrml-icon-container-size;
          min-width: $tab-nrml-icon-container-size;
          width: $tab-nrml-icon-container-size;
          line-height: $tab-nrml-item-tabicon-line-height;

          @media screen and (max-width: 480px) {
            height: $tab-mob-icon-container-size;
            width: $tab-mob-icon-container-size;
          }
        }

        .e-tab-icon,
        .e-tab-icon::before {
          font-size: $tab-nrml-icon-size;

          @media screen and (max-width: 480px) {
            font-size: $tab-mob-icon-size;
          }
        }

        .e-tab-icon::before {
          position: relative;
          top: $tab-item-icon-before-top;
          left: $tab-item-icon-before-left;
        }

        .e-icon-top.e-tab-icon::before,
        .e-icon-bottom.e-tab-icon::before {
          left: 0;
        }

        &.e-icon {

          .e-tab-wrap {
            justify-content: center;
            padding: $tab-nrml-io-wrap-padding;

            @media screen and (max-width: 480px) {
              padding: $tab-big-wrap-padding;
            }
          }
        }

        .e-icon-right {
          margin: 0 0 0 8px;

          @media screen and (max-width: 480px) {
            margin: $tab-bgr-mob-item-iconright-margin;
          }
        }

        .e-icon-left + .e-tab-text {
          margin: $tab-nrml-it-text-margin;

          @media screen and (max-width: 480px) {
            margin: $tab-mob-it-text-margin;
          }
        }

        &.e-itop,
        &.e-ibottom {
          height: $tab-nrml-icon-top-bottom-item-height;

          @media screen and (max-width: 480px) {
            height: $tab-nrml-icon-top-bottom-item-height;
          }

          .e-tab-wrap {
            align-items: center;
            display: flex;
            height: $tab-nrml-icon-top-bottom-item-height;
            position: relative;

            @media screen and (max-width: 480px) {
              height: $tab-nrml-icon-top-bottom-item-height;
            }

            &:focus {

              .e-text-wrap {
                height: auto;
              }
            }
          }

          .e-text-wrap {
            display: block;
            height: auto;
          }

          &.e-active {

            .e-tab-wrap {

              &:focus {

                .e-text-wrap {
                  height: auto;
                }
              }

              .e-text-wrap {
                height: auto;
              }
            }
          }

          .e-close-icon {
            position: absolute;
            right: $tab-nrml-close-icon-top-bottom-right;
            top: calc(50% - 6px);

            @media screen and (max-width: 480px) {
              right: $tab-mob-close-icon-top-bottom-right;
            }
          }
        }

        &.e-itop .e-tab-text {
          margin: $tab-nrml-icon-top-margin;

          @media screen and (max-width: 480px) {
            margin: $tab-big-icon-top-margin;
          }
        }

        &.e-ibottom .e-tab-text {
          margin: $tab-nrml-icon-bottom-margin;

          @media screen and (max-width: 480px) {
            margin: $tab-big-icon-bottom-margin;
          }
        }

        .e-tab-icon.e-icon-top,
        .e-tab-icon.e-icon-bottom {
          align-items: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin: auto;
        }
      }

      .e-toolbar-pop {
        overflow-y: auto;
        padding: $tab-pop-padding;

        .e-toolbar-item {
          height: $tab-nrml-pop-item-height;
          min-height: $tab-nrml-pop-item-height;
          min-width: auto;

          @media screen and (max-width: 480px) {
            height: $tab-mob-pop-item-height;
            min-height: $tab-mob-pop-item-height;
          }

          &:not(.e-separator) {
            margin: $tab-pop-item-margin;
            padding: 0;
          }

          .e-tab-wrap {
            height: $tab-nrml-pop-item-height;
            padding: $tab-nrml-pop-item-wrap-padding;
            text-align: initial;

            @media screen and (max-width: 480px) {
              height: $tab-mob-pop-item-height;
              padding: $tab-mob-pop-item-wrap-padding;
            }
          }

          .e-text-wrap {
            height: $tab-nrml-pop-item-textwrap-height;
            width: 100%;
          }

          .e-tab-text {
            display: inline-flex;
            width: 100%;
          }

          .e-close-icon {
            margin: 0 0 0 8px;
          }

          .e-close-icon::before {
            top: $tab-nrml-pop-close-top;
          }

          .e-tab-text + .e-close-icon[style = 'display:block'] {
            padding-right: $tab-nrml-pop-text-padding-right;

            @media screen and (max-width: 480px) {
              padding-right: 0;
            }
          }

          &.e-itop,
          &.e-ibottom {
            height: $tab-pop-nrml-icon-top-bottom-item-height;

            @media screen and (max-width: 480px) {
              height: $tab-pop-big-icon-top-bottom-item-height;
            }

            &:not(.e-separator) {
              min-height: $tab-pop-nrml-icon-top-bottom-item-height;

              @media screen and (max-width: 480px) {
                min-height: $tab-pop-big-icon-top-bottom-item-height;
              }
            }

            .e-tab-wrap {
              height: $tab-pop-nrml-icon-top-bottom-item-height;

              @media screen and (max-width: 480px) {
                height: $tab-pop-big-icon-top-bottom-item-height;
              }
            }

            .e-text-wrap {
              display: inline-flex;
              height: auto;
            }

            .e-tab-text {
              display: block;
              justify-content: center;
              margin: 0 0 0 10px;
              padding-left: 0;
              padding-right: 10px;

              @media screen and (max-width: 480px) {
                justify-content: center;
                margin: 0 0 0 12px;
                padding-left: 0;
                padding-right: 0;
              }
            }

            .e-close-icon {
              right: $tab-nrml-pop-close-icon-top-bottom-right;
              top: initial;
            }
          }

          &.e-ibottom {

            .e-tab-text {
              margin: 0;
            }
          }

          .e-tab-icon.e-icon-top,
          .e-tab-icon.e-icon-bottom {
            display: inline-flex;
          }
        }
      }

      &.e-close-show {

        .e-icons.e-close-icon {
          align-items: center;
          align-self: center;
          display: inline-flex;

          @media screen and (max-width: 480px) {
            display: none;
          }
        }

        .e-toolbar-item.e-active .e-close-icon {

          @media screen and (max-width: 480px) {
            display: inline-flex;
          }
        }

        .e-toolbar-item.e-itop .e-text-wrap,
        .e-toolbar-item.e-ibottom .e-text-wrap {
          margin-right: $tab-nrml-text-container-margin-right;
        }
      }

      .e-scroll-nav {
        height: $tab-nrml-height;
        min-height: $tab-nrml-height;
        min-width: auto;
        width: auto;

        @media screen and (max-width: 480px) {
          height: $tab-big-height;
          min-height: $tab-big-height;
        }

        &.e-scroll-left-nav {
          padding: $tab-nrml-nav-left-icon-padding;
        }

        &.e-scroll-right-nav {
          padding: $tab-nrml-nav-right-icon-padding;

          @media screen and (max-width: 480px) {
            padding: $tab-mob-nav-right-icon-padding;
          }
        }

        .e-nav-left-arrow::before,
        .e-nav-right-arrow::before {
          font-size: $tab-nrml-nav-icon-size;
          line-height: $tab-nrml-nav-icon-container-size;
          position: relative;
          top: $tab-nrml-scroll-nav-arrow-before-top;
          vertical-align: initial;
        }

        .e-nav-arrow {
          font-size: $tab-nrml-nav-icon-size;
          height: $tab-nrml-nav-icon-container-size;
          line-height: $tab-nrml-nav-icon-container-size;
          width: $tab-nrml-nav-icon-container-size;

          @media screen and (max-width: 480px) {
            font-size: $tab-big-nav-icon-size;
            height: $tab-big-nav-icon-container-size;
            line-height: $tab-big-nav-icon-container-size;
            width: $tab-big-nav-icon-container-size;
          }
        }
      }

      .e-hor-nav {
        height: $tab-nrml-height;
        min-height: $tab-nrml-height;
        min-width: auto;
        padding: $tab-nrml-nav-right-icon-padding;
        width: auto;

        @media screen and (max-width: 480px) {
          height: $tab-big-height;
          min-height: $tab-big-height;
          padding: $tab-mob-nav-right-icon-padding;
        }

        .e-popup-up-icon,
        .e-popup-down-icon {
          font-size: $tab-nrml-nav-icon-size;
          height: $tab-nrml-nav-icon-container-size;
          line-height: $tab-nrml-nav-icon-container-size;
          width: $tab-nrml-nav-icon-container-size;

          @media screen and (max-width: 480px) {
            font-size: $tab-big-nav-icon-size;
            height: $tab-big-nav-icon-container-size;
            line-height: $tab-big-nav-icon-container-size;
            width: $tab-big-nav-icon-container-size;
          }

          &::before {
            font-size: $tab-nrml-nav-icon-size;
            line-height: $tab-nrml-nav-icon-container-size;
            position: relative;
            top: 0;
            vertical-align: initial;

            @media screen and (max-width: 480px) {
              font-size: $tab-mob-nav-icon-size;
            }
          }

          &:hover {
            line-height: $tab-nrml-hor-pop-lineheight;

            &::before {
              line-height: $tab-nrml-hor-pop-lineheight;
              top: 0;

              @media screen and (max-width: 480px) {
                line-height: $tab-nrml-mob-hor-pop-lineheight;
              }
            }
          }
        }

        .e-popup-up-icon {
          transform: $tab-pop-up-icon-transform;
          transition: $tab-pop-icon-transition;

          @media screen and (max-width: 480px) {
            transform: none;
            transition: none;
          }
        }

        .e-popup-down-icon {
          transform: $tab-pop-down-icon-transform;
          transition: $tab-pop-icon-transition;

          @media screen and (max-width: 480px) {
            transform: none;
            transition: none;
          }
        }

        &:focus {

          .e-popup-up-icon,
          .e-popup-down-icon {
            line-height: $tab-nrml-hor-focus-pop-lineheight;

            &::before {
              line-height: $tab-nrml-hor-focus-pop-lineheight;
              top: 0;

              @media screen and (max-width: 480px) {
                line-height: $tab-nrml-mob-hor-pop-lineheight;
              }
            }

            &:hover {
              line-height: $tab-nrml-hor-focus-pop-lineheight;
              top: $tab-nrml-hor-focus-pop-hover-top;
            }
          }
        }
      }

      &.e-horizontal-bottom {

        .e-toolbar-items .e-toolbar-item.e-active {
          position: $tab-nrml-item-active-position;

          &:hover::before {
            left: $tab-nrml-item-active-hover-before-left;
            right: $tab-nrml-item-active-hover-before-right;
          }

          &::before {
            background-color: $tab-nrml-item-active-before-bg-font;
            content: $tab-nrml-item-active-before-content;
            height: $tab-nrml-item-active-before-height;
            left: $tab-nrml-item-active-before-left;
            position: $tab-nrml-item-active-before-position;
            right: $tab-nrml-item-active-before-left;
            top: $tab-nrml-item-active-before-bottom;
            transition: $tab-nrml-item-active-before-transition;
          }

          &::after {
            color: $tab-nrml-item-active-after-font;
            content: $tab-nrml-item-active-after-content;
            display: $tab-nrml-item-active-after-display;
            font-weight: $tab-nrml-item-active-after-font-weight;
            height: $tab-nrml-item-active-after-height;
            overflow: $tab-nrml-item-active-after-overflow;
            visibility: $tab-nrml-item-active-after-overflow;
          }
        }

        &::before {
          bottom: auto;
        }

        .e-hscroll-bar {
          margin-top: $tab-nrml-active-item-tabicon-before-top;
        }

        .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
          margin: $tab-nrml-even-last-item-margin;
          padding-bottom: 0;
          padding-top: $tab-nrml-even-boot-active-item-padding-bottom;

          @media screen and (max-width: 480px) {
            margin: $tab-mob-even-last-item-margin;
          }
        }

        .e-indicator {
          bottom: auto;
          top: 0;
        }

        .e-toolbar-item {

          &.e-active {
            margin: $tab-nrml-even-boot-item-margin;
            padding-bottom: 0;
            padding-top: $tab-nrml-boot-active-item-padding-bottom;

            @media screen and (max-width: 480px) {
              margin: $tab-mob-even-boot-item-margin;
            }

            .e-text-wrap {
              height: $tab-nrml-item-text-wrap-height;
              padding: 0;

              @media screen and (max-width: 480px) {
                height: $tab-big-active-text-container-height;
              }
            }

            .e-text-wrap::before,
            &:not(.e-separator):last-child .e-text-wrap::before {
              top: $tab-nrml-bottom-active-before-top;
            }

            &.e-itop {
              .e-tab-wrap:focus .e-close-icon::before {
                top: 0;
              }
            }
          }

          &.e-itop,
          &.e-ibottom {

            .e-text-wrap {
              height: initial;
            }

            &.e-active {

              .e-text-wrap {
                height: initial;
                padding: 0;
              }

              .e-text-wrap::before {
                bottom: auto;
                top: 0;
              }
            }
          }
        }
      }

      &.e-reorder-active-item .e-toolbar-pop .e-toolbar-item.e-active .e-text-wrap::before {
        display: none;
      }

      &.e-vertical {
        max-width: 150px;
        z-index: 1;

        &::before {
          bottom: 0;
          height: 100%;
          left: 0;
          top: 0;
        }

        &[style *= 'overflow: hidden']::before {
          bottom: $tab-nrml-vertical-scroll-nav-top-bottom;
          height: auto;
          top: $tab-nrml-vertical-scroll-nav-top-bottom;

          @media screen and (max-width: 480px) {
            bottom: 0;
            top: 0;
          }
        }

        .e-indicator {
          display: $tab-indicator-display;
          transition: $tab-nrml-vertical-indicator-transition;
          width: $tab-border-width;
        }

        .e-toolbar-items {
          height: inherit;
          flex-direction: column;
          align-items: normal;

          &.e-vscroll:not(.e-scroll-device) {
            padding: $tab-vscroll-nrml-padding;
          }
        }

        .e-toolbar-item {

          &:last-child {
            margin: 0;
          }

          &:not(.e-separator) {
            margin: 0;
          }

          .e-tab-wrap {
            padding: $tab-nrml-v-wrap-padding;
          }

          .e-text-wrap {
            position: relative;
            width: 100%;
          }

          .e-tab-text,
          .e-tab-icon::before {
            text-align: center;
            width: 100%;
          }

          .e-tab-text {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          &.e-active {

            .e-text-wrap::before {
              display: none;
            }
          }

          &.e-ileft.e-icon {
            min-height: $tab-nrml-vertical-icon-min-height;
            min-width: $tab-nrml-vertical-icon-min-width;

            .e-tab-icon {
              margin: $tab-nrml-vertical-tabicon-margin;
            }
          }

          &.e-itop,
          &.e-ibottom {

            .e-close-icon {
              right: $tab-v-nrml-close-icon-top-bottom-right;
            }
          }
        }

        .e-toolbar-pop {
          top: initial !important; /* stylelint-disable-line declaration-no-important */
        }

        &.e-vertical-left {
          float: left;

          &::before {
            right: 0;
          }

          .e-indicator {
            left: auto;
            right: 0;
            @if ($skin-name == 'Material3') {
              border-radius: 3px 0 0 3px;
            }
            @else if ($skin-name == 'fluent2') {
              border-radius: $tab-nrml-vertical-indicator-bdr-radius;
            }
          }

          .e-toolbar-items .e-toolbar-item.e-active {

            &:hover::before {
              bottom: $tab-nrml-item-active-before-bottom;
              height: $tab-nrml-vertical-hover-before-height;
            }

            &::before {
              background-color: $tab-nrml-item-active-before-bg-font;
              bottom: $tab-nrml-vertical-before-bottom;
              content: $tab-nrml-item-active-before-content;
              height: $tab-nrml-vertical-before-height;
              left: $tab-nrml-vertical-before-left;
              position: $tab-nrml-item-active-before-position;
              right: $tab-nrml-item-active-before-bottom;
              transition: $tab-nrml-vertical-before-transition;
              width: $tab-nrml-item-active-before-height;
            }

            &::after {
              color: $tab-nrml-item-active-after-font;
              content: $tab-nrml-item-active-after-content;
              display: $tab-nrml-item-active-after-display;
              font-weight: $tab-nrml-item-active-after-font-weight;
              height: $tab-nrml-item-active-after-height;
              overflow: $tab-nrml-item-active-after-overflow;
              visibility: $tab-nrml-item-active-after-overflow;
            }
          }
        }

        &.e-vertical-right {
          float: right;

          &::before {
            right: auto;
          }

          .e-indicator {
            left: 0;
            right: auto;
            @if ($skin-name == 'Material3') {
              border-radius: 0 3px 3px 0;
            }
            @else if ($skin-name == 'fluent2') {
              border-radius: $tab-nrml-vertical-indicator-bdr-radius;
            }
          }

          .e-tab-wrap {
            text-align: right;
          }

          .e-toolbar-pop {

            .e-tab-text {
              width: auto;
            }
          }

          .e-toolbar-items .e-toolbar-item.e-active {

            &:hover::before {
              bottom: $tab-nrml-item-active-before-bottom;
              height: $tab-nrml-vertical-hover-before-height;
            }

            &::before {
              background-color: $tab-nrml-item-active-before-bg-font;
              bottom: $tab-nrml-vertical-before-bottom;
              content: $tab-nrml-item-active-before-content;
              height: $tab-nrml-vertical-before-height;
              left: $tab-nrml-item-active-before-bottom;
              position: $tab-nrml-item-active-before-position;
              right: $tab-nrml-vertical-before-left;
              transition: $tab-nrml-vertical-before-transition;
              width: $tab-nrml-item-active-before-height;
            }

            &::after {
              color: $tab-nrml-item-active-after-font;
              content: $tab-nrml-item-active-after-content;
              display: $tab-nrml-item-active-after-display;
              font-weight: $tab-nrml-item-active-after-font-weight;
              height: $tab-nrml-item-active-after-height;
              overflow: $tab-nrml-item-active-after-overflow;
              visibility: $tab-nrml-item-active-after-overflow;
            }
          }
        }

        .e-scroll-nav {
          height: $tab-nrml-vertical-nav-arrow-size;
          justify-content: center;
          min-height: $tab-nrml-vertical-nav-arrow-size;
          width: 100%;

          .e-nav-arrow {
            font-size: $tab-nrml-vertical-nav-arrow-font-size;
            height: $tab-nrml-vertical-nav-arrow-size;
            line-height: $tab-nrml-vertical-nav-arrow-size;
            margin: 0 auto;
            width: $tab-nrml-vertical-nav-arrow-size;
          }
        }

        .e-hor-nav {
          padding: $tab-nrml-wrap-padding;
          width: 100%;

          .e-popup-up-icon,
          .e-popup-down-icon {
            height: 100%;
            transform: none;
            transition: none;
            width: 100%;

            &::before {
              float: left;
              font-family: $tab-font-family;
              font-size: $tab-nrml-font-size;
              font-weight: $tab-font-weight;
              line-height: $tab-nrml-more-btn-line-height;
              text-align: left;
              text-transform: $tab-text-transform;
              transform: none;
              transition: none;
            }

            &::after {
              float: left;
              font-size: 12px;
              line-height: $tab-nrml-more-btn-line-height;
              margin: $tab-pop-more-icon-margin;
              vertical-align: initial;

              @media screen and (max-width: 480px) {
                font-size: $tab-big-font-size;
              }
            }
          }
        }

        .e-scroll-device {

          .e-scroll-nav,
          .e-scroll-overlay {
            display: none;
          }
        }

        &.e-toolpop {

          .e-toolbar-items {
            height: auto;
          }
        }
      }
    }

    &.e-rtl {

      .e-tab-header.e-vertical {

        .e-toolbar-items:not(.e-tbar-pos) {

          .e-toolbar-item:last-child {
            margin: 0;
          }
        }

        .e-toolbar-item {

          &:not(.e-separator) {
            margin: 0;

            &.e-itop .e-close-icon,
            &.e-ibottom .e-close-icon {
              left: $tab-v-rtl-nrml-close-icon-top-bottom-left;
            }
          }
        }

        .e-hor-nav {
          padding: $tab-nrml-wrap-padding;

          .e-popup-up-icon,
          .e-popup-down-icon {

            &::before {
              float: right;
            }

            &::after {
              float: right;
              margin: $tab-rtl-pop-more-icon-margin;
            }
          }
        }
      }
    }

    .e-content {
      position: relative;

      .e-item {

        &.e-view {
          bottom: 0;
          left: 0;
          overflow: hidden;
          position: absolute;
          right: 0;
          top: 0;
        }
      }

      > .e-item {
        display: none;

        &.e-active {
          background: inherit;
          display: block;
        }
      }

      &.e-progress {
        overflow: hidden;
      }
    }

    &.e-vertical-tab {

      .e-content {
        display: flex;

        .e-item.e-active {
          display: flex;
          flex: none;
        }

        .e-item,
        .e-item > :first-child {
          width: 100%;
        }
      }
    }

    &.e-vertical-icon {

      > .e-tab-header {
        height: $tab-nrml-tb-icon-height;
        min-height: $tab-nrml-tb-icon-height;

        &.e-reorder-active-item .e-toolbar-pop {

          .e-toolbar-item.e-active .e-tab-wrap::before,
          .e-toolbar-item:not(.e-separator):last-child.e-active .e-tab-wrap::before {
            display: none;
          }
        }

        > .e-toolbar-items {
          height: $tab-nrml-tb-icon-height;
        }

        .e-toolbar-item.e-active,
        .e-toolbar-item:not(.e-separator):last-child.e-active {
          .e-text-wrap {
            position: $tab-nrml-vertical-text-position;
          }

          .e-text-wrap::before {
            border: $tab-active-wrap-before-border;
            content: $tab-active-wrap-before-content;
            display: none;
            position: $tab-active-wrap-before-position;
            top: $tab-active-wrap-before-top;
            width: $tab-active-wrap-before-width;
          }

          .e-tab-wrap::before {
            content: $tab-nrml-vertical-wrap-before-content;
            display: $tab-nrml-vertical-wrap-before-display;
            position: $tab-nrml-vertical-wrap-before-position;
            top: $tab-nrml-vertical-wrap-before-top;
            width: $tab-nrml-vertical-wrap-before-width;
            border: $tab-nrml-vertical-wrap-before-border;
          }
        }

        .e-scroll-nav {
          height: $tab-nrml-icon-top-bottom-item-height;
        }

        .e-hor-nav {
          height: $tab-nrml-icon-top-bottom-item-height;

          .e-popup-up-icon::before,
          .e-popup-down-icon::before {

            @media screen and (max-width: 480px) {
              font-size: $tab-mob-nav-vicon-size;
            }
          }
        }

        &.e-vertical {

          .e-indicator {
            width: $tab-nrml-vertical-indicator-width;
          }

          .e-toolbar-item.e-active,
          .e-toolbar-item:not(.e-separator):last-child.e-active {

            .e-tab-wrap::before {
              display: none;
            }

            .e-text-wrap {
              position: relative;
            }
          }

          .e-scroll-nav {
            height: $tab-nrml-vertical-nav-arrow-size;
          }
        }

        &.e-horizontal-bottom {

          .e-toolbar-item.e-active,
          .e-toolbar-item:not(.e-separator):last-child.e-active {
            .e-tab-wrap::before {
              top: $tab-nrml-bottom-wrap-before-top;
            }
          }
        }
      }

      &.e-rtl .e-tab-header .e-toolbar-item.e-active {
        margin: $tab-nrml-vertical-rtl-active-margin;
      }

      &.e-vertical-tab {

        .e-tab-header.e-vertical .e-toolbar-items {
          height: inherit;
        }
      }
    }

    &.e-focused {

      .e-tab-header .e-toolbar-item {

        .e-tab-wrap:focus {
          height: $tab-nrml-height;

          .e-text-wrap {
            height: $tab-nrml-item-text-wrap-height;
          }
        }

        &.e-itop,
        &.e-ibottom {

          .e-tab-wrap:focus {
            height: $tab-nrml-icon-top-bottom-item-height;
          }

          .e-tab-wrap:focus .e-text-wrap {
            height: auto;
          }

          &.e-active .e-tab-wrap:focus .e-text-wrap {
            height: auto;
          }
        }
      }

      .e-tab-header .e-toolbar-pop {

        .e-toolbar-item {

          .e-tab-wrap:focus {
            height: $tab-nrml-pop-item-height;

            .e-text-wrap {
              height: $tab-nrml-pop-item-textwrap-height;
            }
          }

          &.e-itop .e-tab-wrap:focus,
          &.e-ibottom .e-tab-wrap:focus {
            height: $tab-nrml-height;
          }
        }
      }

      .e-tab-header .e-horizontal-bottom {

        .e-toolbar-item {

          &.e-active {

            .e-tab-wrap:focus .e-text-wrap {
              height: $tab-nrml-item-text-wrap-height;
              padding: 0;
            }
          }

          &.e-itop,
          &.e-ibottom {

            .e-tab-wrap:focus .e-text-wrap {
              height: auto;
            }

            &.e-active .e-tab-wrap:focus .e-text-wrap {
              height: auto;
            }
          }
        }
      }
    }

    &.e-template {

      .e-tab-header {

        .e-toolbar-items,
        .e-toolbar-pop {

          .e-toolbar-item .e-tab-wrap .e-text-wrap .e-tab-text > div {
            display: inherit;
          }
        }
      }

      .e-content {

        > .e-item {
          display: none;
  
          &.e-active {
            background: inherit;
            display: block;
          }
        }
      }
    }

    &.e-ie .e-tab-header {

      .e-hor-nav.e-ie-align,
      .e-scroll-nav.e-ie-align {
        display: flex;

        .e-popup-up-icon,
        .e-popup-down-icon {
          display: block;
          line-height: $tab-nrml-ie-pop-icon-line-height;
        }

        .e-nav-left-arrow,
        .e-nav-right-arrow {
          display: block;
          line-height: $tab-nrml-ie-nav-icon-line-height;
        }
      }

      .e-popup-up-icon,
      .e-popup-down-icon {
        transform: none;
        transition: none;
      }

      .e-popup-up-icon::before {
        transform: $tab-pop-up-icon-transform;
        transition: $tab-pop-icon-transition;

        @media screen and (max-width: 480px) {
          transform: none;
          transition: none;
        }
      }

      .e-popup-down-icon::before {
        transform: $tab-pop-down-icon-transform;
        transition: $tab-pop-icon-transition;

        @media screen and (max-width: 480px) {
          transform: none;
          transition: none;
        }
      }
    }

    &.e-edge .e-tab-header {

      .e-hor-nav.e-ie-align,
      .e-scroll-nav.e-ie-align {
        display: flex;

        .e-popup-up-icon,
        .e-popup-down-icon {
          display: block;
          line-height: $tab-nrml-edge-pop-icon-line-height;
          position: relative;
        }

        .e-nav-left-arrow,
        .e-nav-right-arrow {
          display: block;
          line-height: $tab-nrml-edge-nav-icon-line-height;
          position: relative;
        }
      }

      .e-popup-up-icon {
        transform: $tab-pop-up-icon-transform;
        transition: $tab-pop-icon-transition;

        @media screen and (max-width: 480px) {
          transform: none;
          transition: none;
        }
      }

      .e-popup-down-icon {
        transform: $tab-pop-down-icon-transform;
        transition: $tab-pop-icon-transition;

        @media screen and (max-width: 480px) {
          transform: none;
          transition: none;

          @media screen and (max-width: 480px) {
            transform: none;
            transition: none;
          }
        }
      }

      .e-popup-up-icon::before {
        transform: $tab-pop-up-icon-transform;
        transition: $tab-pop-icon-transition;

        @media screen and (max-width: 480px) {
          transform: none;
          transition: none;
        }
      }

      .e-popup-down-icon::before {
        transform: $tab-pop-down-icon-transform;
        transition: $tab-pop-icon-transition;

        @media screen and (max-width: 480px) {
          transform: none;
          transition: none;
        }
      }
    }

    &.e-safari .e-tab-header {

      .e-close-icon::before {
        top: $tab-nrml-safari-close-icon-top;
      }

      .e-hor-nav,
      .e-scroll-nav {

        .e-popup-up-icon::before,
        .e-popup-down-icon::before {
          top: 0;
        }

        .e-nav-left-arrow::before,
        .e-nav-right-arrow::before {
          top: 0;
        }
      }
    }

    &.e-disable {
      pointer-events: none;
    }

    &.e-fill {

      .e-tab-header {
        height: $tab-nrml-fill-height;
        min-height: $tab-nrml-fill-height;

        @media screen and (max-width: 480px) {
          height: $tab-mob-fill-height;
          min-height: $tab-mob-fill-height;
        }

        .e-indicator {
          display: none;
        }

        .e-toolbar-items {
          height: auto;
          min-height: $tab-nrml-fill-height;

          @media screen and (max-width: 480px) {
            min-height: $tab-mob-fill-height;
          }

          &:not(.e-tbar-pos) .e-toolbar-item:first-child,
          &:not(.e-tbar-pos) .e-toolbar-item:last-child {
            margin: $tab-nrml-alt-first-item-margin;
            padding: $tab-nrml-background-active-padding;
          }
        }

        .e-toolbar-item {

          &:not(.e-separator) {
            height: $tab-nrml-fill-height;
            margin: $tab-alt-item-margin;
            min-height: $tab-nrml-fill-height;
            padding: $tab-nrml-background-active-padding;

            @media screen and (max-width: 480px) {
              height: $tab-mob-fill-height;
              min-height: $tab-mob-fill-height;
            }

            &.e-itop,
            &.e-ibottom {
              height: $tab-nrml-fill-icon-top-bottom-item-height;
              min-height: $tab-nrml-fill-icon-top-bottom-item-height;
            }
          }

          .e-tab-wrap {
            height: $tab-nrml-fill-height;
            padding: $tab-fill-nrml-wrap-padding;

            @media screen and (max-width: 480px) {
              height: $tab-mob-fill-height;
              padding: $tab-mob-fill-wrap-padding;
            }
          }

          .e-text-wrap {
            height: $tab-nrml-fill-item-textwrap-height;

            @media screen and (max-width: 480px) {
              height: $tab-mob-fill-height;
            }
          }

          &.e-active {
            padding: $tab-nrml-background-active-padding;

            .e-tab-wrap {
              margin-bottom: $tab-fill-nrml-active-item-wrap-margin-bottom;
            }

            .e-text-wrap {
              height: $tab-nrml-fill-item-textwrap-height;

              @media screen and (max-width: 480px) {
                height: $tab-mob-fill-height;
              }
            }

            .e-tab-text {
              font-weight: $tab-light-font-weight;
            }

            &.e-ileft .e-text-wrap,
            &.e-iright .e-text-wrap {
              height: $tab-fill-nrml-active-it-text-container-height;

              @media screen and (max-width: 480px) {
                height: $tab-fill-big-active-it-text-container-height;
              }
            }
          }

          &.e-itop .e-tab-wrap,
          &.e-ibottom .e-tab-wrap {
            height: $tab-nrml-fill-icon-top-bottom-item-height;
          }

          &.e-itop .e-text-wrap,
          &.e-ibottom .e-text-wrap {
            height: auto;
          }
        }

        .e-toolbar-pop {

          .e-toolbar-item.e-itop:not(.e-separator),
          .e-toolbar-item.e-ibottom:not(.e-separator) {
            height: auto;
          }
        }

        &.e-horizontal-bottom {

          .e-toolbar-items:not(.e-tbar-pos) {

            .e-toolbar-item {

              &:first-child.e-active,
              &:last-child.e-active {
                padding: 0;
              }

              &.e-itop,
              &:first-child.e-itop,
              &:last-child.e-itop {
                padding-top: 0;
              }
            }
          }

          .e-toolbar-item {

            &.e-active {
              margin-right: 0;
              padding: 0;

              .e-text-wrap {
                height: $tab-nrml-fill-item-textwrap-height;

                @media screen and (max-width: 480px) {
                  height: $tab-mob-fill-height;
                }
              }
            }

            &.e-itop,
            &.e-ibottom {

              .e-tab-wrap:focus .e-text-wrap {
                height: $tab-nrml-fill-tb-wrap-height;
              }

              .e-text-wrap {
                height: auto;
              }
            }
          }
        }

        &.e-vertical {

          .e-toolbar-items {
            height: inherit;

            .e-toolbar-item {

              .e-tab-wrap {
                padding: $tab-nrml-v-wrap-padding;
              }
            }
          }
        }
      }

      &.e-focused {

        .e-tab-header .e-toolbar-item {

          .e-tab-wrap:focus {
            height: $tab-focus-nrml-fill-height;

            .e-text-wrap {
              height: $tab-nrml-fill-focused-wrap-height;
            }
          }

          &.e-active .e-tab-wrap:focus {

            .e-text-wrap {
              height: $tab-nrml-fill-focused-wrap-height;
            }
          }

          &.e-itop .e-tab-wrap:focus,
          &.e-ibottom .e-tab-wrap:focus {
            height: $tab-nrml-focus-fill-icon-top-bottom-item-height;

            .e-text-wrap {
              height: auto;

              @media screen and (max-width: 480px) {
                height: auto;
              }
            }
          }
        }
      }

      &.e-rtl {

        .e-tab-header .e-toolbar-item:not(.e-separator) {
          margin: $tab-nrml-rtl-alt-item-margin;
        }
      }

      &.e-vertical-icon {

        > .e-tab-header {
          height: $tab-nrml-tb-icon-height - 2;
          min-height: $tab-nrml-tb-icon-height - 2;
        }
      }
    }

    &.e-background {

      .e-tab-header {

        .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child,
        .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
          margin: $tab-nrml-alt-first-item-margin;
          padding: $tab-nrml-background-active-padding;
        }

        .e-toolbar-item {

          &:not(.e-separator) {
            margin: 0;
            padding: $tab-nrml-background-active-padding;
          }

          .e-tab-wrap {
            padding: $tab-bg-nrml-wrap-padding;

            @media screen and (max-width: 480px) {
              padding: $tab-mob-fill-wrap-padding;
            }
          }

          &.e-active {
            padding: $tab-nrml-background-active-padding;
            @if ($skin-name == 'fluent2') {
              &::before {
                background-color: $tab-background-text-color;
              }
            }

            .e-tab-wrap {
              margin-bottom: $tab-bg-nrml-active-item-wrap-margin-bottom;
            }

            .e-tab-text {
              font-weight: $tab-background-active-text-font-weight;
            }
          }
        }

        &.e-vertical {

          .e-indicator {
            display: $tab-nrml-background-vertical-indicator-display;
          }

          .e-toolbar-items {

            .e-toolbar-item {

              .e-tab-wrap {
                padding: $tab-nrml-v-wrap-padding;
              }
            }
          }
        }

        &.e-horizontal-bottom {

          .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child.e-active,
          .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child.e-active {
            padding: 0;
          }

          .e-toolbar-item.e-active {
            margin: 0;
            padding: 0;
          }
        }

        .e-toolbar-pop {

          .e-toolbar-item {

            &.e-active {

              .e-text-wrap {
                height: $tab-nrml-pop-item-textwrap-height;
              }
            }
          }
        }
      }

      &.e-rtl {

        .e-tab-header .e-toolbar-item {

          &:not(.e-separator) {
            margin: $tab-nrml-rtl-alt-item-margin;
          }
        }
      }
    }

    &.e-rtl {

      .e-tab-header {

        .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child,
        .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
          margin: $tab-nrml-rtl-item-margin;
        }

        .e-toolbar-item:not(.e-separator) {
          margin: $tab-nrml-rtl-item-margin;

          .e-icon-right {
            margin: $tab-nrml-rtl-icon-right-margin;
          }

          .e-icon-left + .e-tab-text {
            margin: $tab-rtl-nrml-it-text-margin;
          }

          .e-icons.e-close-icon {
            margin: $tab-rtl-nrml-close-icon-margin;
          }

          &.e-itop .e-close-icon,
          &.e-ibottom .e-close-icon {
            left: $tab-rtl-nrml-close-icon-top-bottom-left;
            right: auto;
          }
        }

        &.e-close-show {

          .e-toolbar-item.e-itop .e-text-wrap,
          .e-toolbar-item.e-ibottom .e-text-wrap {
            margin-left: $tab-rtl-nrml-text-container-margin-left;
            margin-right: $tab-rtl-nrml-text-container-margin-right;
          }
        }

        &.e-horizontal-bottom {

          .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:not(.e-separator) {
            margin: $tab-nrml-vertical-rtl-active-margin;
          }
        }

        .e-toolbar-pop {

          .e-toolbar-item {
            margin: 0;

            .e-tab-text {
              padding-left: $tab-rtl-nrml-pop-text-padding-left;
              padding-right: 0;

              @media screen and (max-width: 480px) {
                padding-left: 0;
              }
            }

            .e-icons.e-close-icon {
              margin: $tab-rtl-nrml-pop-close-icon-margin;
            }

            .e-close-icon {
              left: $tab-rtl-pop-close-icon-left;
              right: auto;
            }

            &.e-itop .e-close-icon,
            &.e-ibottom .e-close-icon {
              left: $tab-rtl-big-pop-close-icon-top-bottom-left;
              right: auto;
            }

            &.e-itop .e-tab-text,
            &.e-ibottom .e-tab-text {
              margin: 0;
              padding-left: 10px;
              padding-right: 0;

              @media screen and (max-width: 480px) {
                margin: 0;
                padding-left: 10px;
                padding-right: 0;
              }
            }
          }
        }

        .e-hor-nav,
        .e-scroll-right-nav {
          padding: $tab-rtl-nrml-nav-right-icon-padding;
        }

        .e-scroll-left-nav {
          padding: $tab-rtl-nrml-nav-left-icon-padding;
        }
      }
    }

    &.e-vertical-tab.e-icon-tab {

      .e-tab-header.e-vertical {

        .e-hor-nav .e-popup-up-icon,
        .e-hor-nav .e-popup-down-icon {

          &::before {
            display: none;
          }

          &::after {
            margin: 0;
            width: 100%;
          }
        }
      }
    }
  }

  .e-tab-clone-element {
    overflow: visible;
    z-index: 10000;

    &:not(.e-separator) {
      height: $tab-nrml-height;
      margin: $tab-nrml-item-margin;
      min-height: $tab-nrml-height;
      min-width: auto;
      padding: $tab-nrml-item-padding;

      @media screen and (max-width: 480px) {
        height: $tab-big-height;
        min-height: $tab-big-height;
      }
    }

    .e-tab-wrap {
      height: $tab-nrml-height;
      padding: $tab-nrml-wrap-padding;
      width: 100%;

      @media screen and (max-width: 480px) {
        height: $tab-big-height;
        padding: $tab-mob-wrap-padding;
      }
    }

    .e-text-wrap {
      align-content: center;
      align-items: center;
      display: inline-flex;
      height: $tab-nrml-item-text-wrap-height;

      @media screen and (max-width: 480px) {
        height: $tab-big-height;
      }
    }

    .e-tab-text {
      display: inherit;
      font-family: $tab-font-family;
      font-size: $tab-nrml-font-size;
      font-weight: $tab-font-weight;
      text-transform: $tab-text-transform;
      align-self: auto;

      @media screen and (max-width: 480px) {
        font-size: $tab-big-font-size;
      }
    }

    .e-icons.e-close-icon {
      display: none;
    }

    .e-close-icon,
    .e-close-icon::before {
      font-size: $tab-nrml-close-icon-size;

      @media screen and (max-width: 480px) {
        font-size: $tab-mob-close-icon-size;
      }
    }

    .e-close-icon::before {
      top: 1px;
    }

    .e-icons.e-tab-icon {
      display: inline-flex;
      height: $tab-nrml-icon-container-size;
      min-width: $tab-nrml-icon-container-size;
      width: $tab-nrml-icon-container-size;
      line-height: $tab-nrml-item-tabicon-line-height;

      @media screen and (max-width: 480px) {
        height: $tab-mob-icon-container-size;
        width: $tab-mob-icon-container-size;
      }
    }

    .e-tab-icon,
    .e-tab-icon::before {
      font-size: $tab-nrml-icon-size;

      @media screen and (max-width: 480px) {
        font-size: $tab-mob-icon-size;
      }
    }

    &.e-icon {

      .e-tab-wrap {
        justify-content: center;
        padding: $tab-nrml-io-wrap-padding;

        @media screen and (max-width: 480px) {
          padding: $tab-big-wrap-padding;
        }
      }
    }

    .e-icon-right {
      margin: 0 0 0 8px;

      @media screen and (max-width: 480px) {
        margin: $tab-bgr-mob-item-iconright-margin;
      }
    }

    .e-icon-left + .e-tab-text {
      margin: $tab-nrml-it-text-margin;

      @media screen and (max-width: 480px) {
        margin: $tab-mob-it-text-margin;
      }
    }

    &.e-itop,
    &.e-ibottom {
      height: $tab-nrml-icon-top-bottom-item-height;

      @media screen and (max-width: 480px) {
        height: $tab-nrml-icon-top-bottom-item-height;
      }

      .e-tab-wrap {
        align-items: center;
        display: flex;
        height: $tab-nrml-icon-top-bottom-item-height;
        position: relative;

        @media screen and (max-width: 480px) {
          height: $tab-nrml-icon-top-bottom-item-height;
        }

        &:focus {

          .e-text-wrap {
            height: auto;
          }
        }
      }

      .e-text-wrap {
        display: block;
        height: auto;
      }

      .e-close-icon {
        position: absolute;
        right: $tab-nrml-close-icon-top-bottom-right;
        top: calc(50% - 6px);

        @media screen and (max-width: 480px) {
          right: $tab-mob-close-icon-top-bottom-right;
        }
      }
    }

    &.e-itop .e-tab-text {
      margin: $tab-nrml-icon-top-margin;

      @media screen and (max-width: 480px) {
        margin: $tab-big-icon-top-margin;
      }
    }

    &.e-ibottom .e-tab-text {
      margin: $tab-nrml-icon-bottom-margin;

      @media screen and (max-width: 480px) {
        margin: $tab-big-icon-bottom-margin;
      }
    }

    .e-tab-icon.e-icon-top,
    .e-tab-icon.e-icon-bottom {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: auto;
    }

    &.e-close-show {

      .e-icons.e-close-icon {
        align-items: center;
        align-self: center;
        display: inline-flex;

        @media screen and (max-width: 480px) {
          display: none;
        }
      }

      .e-toolbar-item.e-itop .e-text-wrap,
      .e-toolbar-item.e-ibottom .e-text-wrap {
        margin-right: $tab-nrml-text-container-margin-right;
      }
    }
  }
}
