@mixin bigger-style($height, $width, $margin) {
  height: $height;
  width: $width;
  margin: $margin;
}

@include export-module('chip-bigger') {
  .e-bigger .e-chip-list,
  .e-bigger.e-chip-list {
    &.e-chip,
    & .e-chip {
      border-radius: $chip-touch-border-radius;
      font-size: $chip-touch-font-size;
      height: $chip-touch-height;
      padding: $chip-touch-padding;
    }
  
    .e-chip-text {
      @if ($skin-name == 'bootstrap5' or $skin-name == 'bootstrap5dark' ) {
        height: $chip-touch-text-height;
      }
      @if $skin-name == 'Material3' or $skin-name == 'fluent2' {
        line-height: $chip-touch-text-line-height;
      }
    }
  
    .e-chip-avatar {
      @include bigger-style($chip-touch-avatar-size, $chip-touch-avatar-size, $chip-touch-avatar-margin);
      font-size: $chip-touch-avatar-content-font-size;
      @if $skin-name == 'Material3' {
        border-radius: $chip-touch-avatar-wrapper-border-radius;
      }
    }
  
    .e-chip-avatar-wrap,
    &.e-chip-avatar-wrap {
      border-radius: $chip-touch-avatar-wrapper-border-radius;
    }
  
    .e-chip-icon {
      font-size: $chip-touch-leading-icon-font-size;
      @include bigger-style($chip-touch-leading-icon-size, $chip-touch-leading-icon-size, $chip-touch-leading-icon-margin);
    }
  
    .e-chip-delete {
      @include bigger-style($chip-touch-delete-icon-size, $chip-touch-delete-icon-size, $chip-touch-delete-icon-margin);
      font-size: $chip-touch-delete-icon-font-size;
      @if $skin-name == 'Material3' {
        line-height: $chip-touch-delete-icon-height;
        height: $chip-touch-delete-icon-height;
      }
  
      @if $skin-name == 'bootstrap4' {
        &.e-dlt-btn::before {
          font-size: small;
        }
      }
  
      @if ($skin-name == 'bootstrap5' or $skin-name == 'bootstrap5dark') {
        &.e-dlt-btn::before {
          font-size: 13px;
        }
      }
      @else if $skin-name == 'fluent2' {
        &.e-dlt-btn::before {
          font-size: 16px;
        }
      }
    }
  
    .trailing-icon-url {
      @include bigger-style($chip-touch-delete-icon-size, $chip-touch-delete-icon-size, $chip-touch-delete-icon-margin);
      font-size: $chip-touch-delete-icon-font-size;
    }
  
    &.e-multi-selection .e-chip {
      &::before {
        @include bigger-style($chip-touch-leading-icon-size, $chip-touch-leading-icon-size, $chip-touch-multi-selection-icon-margin);
        margin-top: $chip-multi-selection-icon-margin-top;
      }

      &.e-chip-avatar-wrap {
        &::before {
          @include bigger-style($chip-touch-avatar-size, $chip-touch-avatar-size, $chip-touch-avatar-margin);
          margin-top: $chip-multi-selection-icon-margin-top;
        }
      }

      &.e-active {
        &:not(.e-chip-icon-wrap):not(.e-chip-avatar-wrap) {
          &::before {
            width: $chip-touch-leading-icon-size;
          }
        }
      }
    }
  
    &.e-rtl {
      &.e-chip,
      & .e-chip {
        .e-chip-avatar {
          margin: $chip-touch-rtl-avatar-margin;
        }

        .e-chip-icon {
          margin: $chip-touch-rtl-leading-icon-margin;
        }

        .e-chip-delete {
          margin: $chip-touch-rtl-delete-icon-margin;
        }

        .e-chip-avatar-wrap,
        &.e-chip-avatar-wrap {
          border-radius: $chip-touch-rtl-avatar-wrapper-border-radius;
        }
      }

      &.e-multi-selection .e-chip {
        &::before {
          margin: $chip-touch-rtl-leading-icon-margin;
          margin-top: $chip-multi-selection-icon-margin-top;
        }

        &.e-chip-avatar-wrap {
          &::before {
            margin: $chip-touch-rtl-avatar-margin;
            margin-top: $chip-multi-selection-icon-margin-top;
          }
        }
      }

      .trailing-icon-url {
        margin: $chip-touch-rtl-delete-icon-margin;
      }
    }
  }
}
