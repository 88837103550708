﻿@include export-module('spinner-layout') {
  .e-spinner-pane {
    align-items: center;
    display: inline-flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    text-align: center;
    top: 0;
    user-select: none;
    vertical-align: middle;
    width: 100%;
    z-index: 1000;

    &::after {
      content: $theme;
      display: none;
    }

    &.e-spin-left {
      /* stylelint-disable property-no-vendor-prefix */
      .e-spinner-inner {
        -webkit-transform: translateX(0%) translateY(-50%);
        left: 0;
        padding-left: $spin-padding;
        transform: translateX(0%) translateY(-50%);
      }
    }

    &.e-spin-right {
      .e-spinner-inner {
        -webkit-transform: translateX(-100%) translateY(-50%);
        left: 100%;
        padding-right: $spin-padding;
        transform: translateX(-100%) translateY(-50%);
      }
    }

    &.e-spin-center {
      .e-spinner-inner {
        -webkit-transform: translateX(-50%) translateY(-50%);
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
      }
    }

    &.e-spin-hide {
      display: none;
    }

    &.e-spin-show {
      display: inline-flex;
    }

    .e-spinner-inner {
      -webkit-transform: translateX(-50%) translateY(-50%);
      left: 50%;
      margin: 0;
      position: absolute;
      text-align: center;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      z-index: 1000;

      .e-spin-label {
        font-family: $spin-label-font-family;
        font-size: $spin-label-font-size;
        margin-top: $spin-label-margin-top;
        text-align: center;
      }

      .e-spin-material,
      .e-spin-material3 {
        @include mat-spinner-rotate;
        display: block;
        margin: 0 auto;

        .e-path-circle {
          fill: none;
          stroke-linecap: square;
        }
      }

      .e-spin-bootstrap4 {
        @include boot4-spinner-rotate;
        border-radius: 50%;
        display: block;
        margin: 0 auto;

        .e-path-circle {
          fill: none;
          stroke-linecap: square;
          stroke-width: $spin-boot4-stroke-width;
        }
      }

      .e-spin-fluent,
      .e-spin-fluent2,
      .e-spin-fabric {
        @include fb-spinner-rotate;
        display: block;
        margin: 0 auto;
        overflow: visible;

        .e-path-arc,
        .e-path-circle {
          fill: none;
          stroke-width: $spin-fabric-stroke-width;
        }
      }

      .e-spin-tailwind {
        @include tw-spinner-rotate;
        display: block;
        margin: 0 auto;
        overflow: visible;

        .e-path-arc,
        .e-path-circle {
          fill: none;
          stroke-width: $spin-tailwind-stroke-width;
        }
      }

      .e-spin-bootstrap5,
      .e-spin-bootstrap5v3 {
        @include boot5-spinner-rotate;
        border-radius: 50%;
        display: block;
        margin: 0 auto;

        .e-path-circle {
          fill: none;
          stroke-linecap: square;
          stroke-width: $spin-boot5-stroke-width;
        }
      }

      .e-spin-bootstrap {
        display: block;
        margin: 0 auto;
      }

      .e-spin-high-contrast {
        @include fb-spinner-rotate;
        display: block;
        margin: 0 auto;
        overflow: visible;

        .e-path-arc,
        .e-path-circle {
          fill: none;
          stroke-width: $spin-fabric-stroke-width;
        }
      }
    }
  }
}
