$grid-xlfl-fieldset-padding: 15px !default;
$grid-xlfltd-padding: 12px !default;
$grid-xlfltable-border-spacing: 0 !default;
$grid-xlfl-dropdown-padding-right: 12px !default;
$grid-xlfl-radio-margin-left: 13px !default;
$grid-xlfl-min-height: 295px !default;
$grid-exlfltr-ctxtmnu-max-height: 298px !default;
$grid-bigger-exlfltr-ctxtmnu-max-height: 355px !default;
$grid-xlfl-li-left-padding: 28px !default;
$grid-xlfl-li-icon-margin-right: 12px !default;
$grid-xlfl-dlg-padding-bottom: 6px !default;
$grid-xlfltd-radio-mtcase-padding: 12px !default;
$grid-xlfl-fieldset-font-size: 14px !default;
$grid-xlfl-dlgfields-font-size: 17px !default;
$grid-xlfl-li-height: 36px !default;
$grid-xlfl-radiodiv-width: 117px !default;
$grid-xl-radio-padding-left: 23px !default;
$grid-xl-radio-padding-right: 13px !default;
$grid-xl-match-margin-left: 6px !default;
$grid-bigger-xlfl-li-height: 48px !default;
$grid-bigger-xlfl-fieldset-font-size: 15px !default;
$grid-bigger-xlfl-dlgfields-font-size: 18px !default;
$grid-bigger-xlfltd-radio-mtcase-padding: 16px !default;
$grid-bigger-xlfl-dlg-padding-bottom: 8px !default;
$grid-bigger-xlfl-li-icon-margin-right: 15px !default;
$grid-bigger-xlfl-li-left-padding: 35px !default;
$grid-bigger-xlfl-min-height: 396px !default;
$grid-bigger-xlfl-fieldset-padding: 20px !default;
$grid-bigger-xlfltd-padding: 16px !default;
$grid-bigger-xlfl-radio-margin-left: 18px !default;
$grid-bigger-xlfltable-border-spacing: 0 !default;
$grid-bigger-xlfl-dropdown-padding-right: 16px !default;
$grid-bigger-xlfl-radiodiv-width: 137px !default;
$grid-bigger-xl-radio-padding-left: 28px !default;
$grid-bigger-xl-radio-padding-right: 5px !default;
$grid-bigger-xl-match-margin-left: 8px !default;
$grid-resize-border-width: 1px !default;
$grid-xl-rtl-radio-padding-left: 0 !default;
$grid-xl-rtl-radio-padding-right: 25px !default;
$grid-checkbox-cell-padding-top-bottom: 6px !default;
$grid-checkboxfiltertext-width: 150px !default;
$grid-column-chooser-footdiv: 1px 0 0 !default;
$grid-columnchooser-footer-border-color: rgba($grey-light-font, .12) !default;
$grid-columnchooser-footer-border-opacity: 1 !default;
$grid-autofill-color: $accent !default;
$grid-bigger-checkbox-padding-topbottom: 13px !default;
$grid-checkbox-padding-topbottom: 9px !default;
$grid-checkboxfiltertext-width: 150px !default;
$grid-checkbox-content-padding-left: 9px !default;
$grid-rtl-checkbox-content-padding-left: 18px !default;
$grid-bigger-checkbox-content-padding-left: 11px !default;
$grid-rtl-bigger-checkbox-content-padding-left: 24px !default;
$grid-columnchooser-footer-border-color: rgba($grey-dark-font, .12) !default;
$grid-columnchooser-footer-border-opacity: 1 !default;
$grid-filtered-color: $accent !default;
$sfgrid-xlfl-dlg-padding: 18px !default;
$grid-excel-filter-separator: 4px !default;
$grid-dialog-footer-color: $grey-white !default;
$grid-xlfl-dlg-content-top-padding: 0 !default;
$grid-xlfl-context-menu-border-radious: 0 !default;
$grid-excel-filter-bottom-padding: 0 !default;
$grid-xlfl-li-icon-margin-arrow-icon: 17px !default;
$grid-bigger-xlfl-li-icon-margin-arrow-icon: 0 !default;
$grid-xl-radio-margin-top: 0 !default;
$grid-xlfl-skin: 'material' !default;
