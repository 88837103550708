$rating-li-font-size: 20px !default;
$rating-li-padding: 4px !default;
$rating-li-line-height: 13px !default;
$rating-li-min-width-height: 28px !default;
$rating-focus-border-radius: 0 !default;
$rating-label-bottom-margin: 4px !default;
$rating-label-right-margin: 8px !default;
$rating-label-top-margin: 4px !default;
$rating-label-left-margin: 4px !default;
$rating-label-font-size: 12px !default;
$rating-label-line-height: 18px !default;
$rating-reset-font-size : 16px !default;
$rating-tooltip-tip-bottom-height: 8px !default;
$rating-border-width: 1px !default;

$rating-small-li-font-size: 16px !default;
$rating-small-li-line-height: 9px !default;
$rating-small-li-min-width-height: 24px !default;
$rating-small-label-font-size: 10px !default;
$rating-small-label-line-height: 16px !default;
$rating-small-reset-font-size : 12px !default;

$rating-bigger-li-font-size: 32px !default;
$rating-bigger-li-line-height: 21px !default;
$rating-bigger-li-min-width-height: 40px !default;
$rating-bigger-label-font-size: 14px !default;
$rating-bigger-label-line-height: 22px !default;
$rating-bigger-reset-font-size : 28px !default;

$rating-bigger-small-li-font-size: 24px !default;
$rating-bigger-small-li-line-height: 17px !default;
$rating-bigger-small-li-min-width-height: 32px !default;
$rating-bigger-small-label-font-size: 12px !default;
$rating-bigger-small-label-line-height: 18px !default;
$rating-bigger-small-reset-font-size : 20px !default;

$rating-selected-bg-color: $grey-900 !default;
$rating-unrated-bg-color: $grey-500 !default;
$rating-unrated-icon-bg-color: none !default;
$rating-hover-bg-color: $primary !default;
$rating-pressed-bg-color: $primary-300 !default;
$rating-selected-disabled-bg-color: $grey-500 !default;
$rating-unrated-disabled-bg-color: $grey-500 !default;
$rating-li-focus: $primary !default;
$rating-reset-icon-color: $grey-800 !default;
$rating-reset-hover-icon-color: $grey-900 !default;
$rating-reset-disabled-icon-color: $grey-500 !default;
