.e-multiselect {
  .e-multi-select-wrapper {
    color: var(--text-color);

    .e-delim-values {
      color: var(--text-color) !important;
      -webkit-text-fill-color: var(--text-color) !important;
    }

    span {
      padding-left: 5px;

      &::before {
        color: var(--text-color) !important;
      }
    }
  }
}
