// Tab
.e-tab {
  max-height: 100% !important;

  .e-toolbar {
    background: var(--header-background-color);
    background-image: none !important;
  }

  .e-tab-header {
    background: var(--header-background-color);
    color: var(--text-color);

    .e-tab-text {
      color: var(--text-color) !important;
    }

    .e-scroll-nav .e-nav-right-arrow::before,
    .e-nav-left-arrow::before {
      color: var(--text-color) !important;
    }

    .e-item {
      color: var(--text-color);

      &.e-active {
        background-color: var(--active-item-background);
        color: var(--button-text);
      }
    }
  }

  .e-content {
    max-height: 100% !important;
    height: calc(100% - 12px) !important;

    .e-item > div {
      height: 100%;
      display: block;

      .e-active {
        height: 100%;
      }
    }
  }
}
