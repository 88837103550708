@include export-module('switch-theme') {
  .e-switch-wrapper,
  .e-css.e-switch-wrapper {
    /* stylelint-disable property-no-vendor-prefix */
    -webkit-tap-highlight-color: transparent;

    & .e-switch-off {
      background-color: $switch-off-bg-color;
      color: $switch-inner-off-font-color;
    }

    & .e-switch-handle {
      background-color: $switch-handle-bg-color;
      box-shadow: $switch-handle-shadow;
    }

    & .e-switch-on {
      background-color: $switch-on-bg-color;
      color: $switch-inner-on-font-color;
    }

    & .e-switch-handle.e-switch-active {
      background-color: $switch-active-handle-bg;
      @if $skin-name == 'bootstrap5.3' {
        opacity: 1;
      }
    }

    & .e-switch-inner.e-switch-active {
      background-color: $switch-inner-active-bg;
      border-color: $switch-inner-active-border-color;
    }

    & .e-switch-inner {
      background-color: $switch-inner-bg;
    }

    & .e-ripple-element {
      background-color: $switch-checked-ripple-bg-color;
    }

    & .e-ripple-check .e-ripple-element {
      background-color: $switch-ripple-bg-color;
    }

    &.e-switch-disabled {
      & .e-switch-handle.e-switch-active {
        box-shadow: $switch-handle-shadow-disabled;
        @if $skin-name == 'bootstrap4' {
          background-color: $switch-inner-on-font-color;
        }
        @if $skin-name == 'FluentUI' {
          background-color: $switch-handle-on-disabled-bg-color;
        }
        @if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' {
          background-color: $content-bg-color;
          @if $skin-name == 'bootstrap5.3' {
            opacity: 1;
          }
          @else {
            opacity: $switch-on-off-disabled-bg-color-opacity;
          }
        }
      }

      & .e-switch-handle:not(.e-switch-active) {
        @if $skin-name == 'Material3' {
          background-color: $switch-on-disabled-bg-color;
        }
      }

      & .e-switch-handle {
        background-color: $switch-handle-disabled-bg-color;
        box-shadow: $switch-handle-shadow-disabled;
      }

      & .e-switch-inner.e-switch-active {
        @if $skin-name == 'bootstrap4' {
          background-color: $switch-inner-active-bg;
          border-color: $switch-inner-active-bg;
        }
        @if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' {
          background-color: $primary;
          @if $skin-name == 'bootstrap5' {
            border-color: $primary;
          }
        }
      }

      & .e-switch-inner .e-switch-off {
        @if $skin-name == 'bootstrap4' {
          opacity: $switch-disabled-bg-color-opacity;
        }
        @else {
          opacity: $switch-on-off-disabled-bg-color-opacity;
        }
        background-color: $switch-on-off-disabled-bg-color;
        @if $skin-name == 'Material3' {
          border-color: $switch-handle-disabled-border-color;
        }
        @else {
          border-color: $switch-handle-disabled-bg-color;
        }
        color: $switch-disabled-font-color;
      }

      & .e-switch-inner .e-switch-on {
        @if $skin-name == 'bootstrap4' {
          color: $switch-inner-on-font-color;
          opacity: $switch-disabled-bg-color-opacity;
        }
        @else {
          color: $switch-disabled-font-color;
          opacity: $switch-on-off-disabled-bg-color-opacity;
        }
        background-color: $switch-on-disabled-bg-color;
        @if $skin-name == 'fluent2' {
          border-color: $toggle-switch-border-disabled !important; /* stylelint-disable-line declaration-no-important */
        }
      }

      & .e-switch-inner {
        @if $skin-name == 'fluent2' {
          background-color: transparent;
        }
        @else {
          background-color: $switch-on-off-disabled-bg-color;
        }
        @if $skin-name != 'bootstrap5.3' {
          opacity: $switch-on-off-disabled-bg-color-opacity;
        }
        border-color: $switch-border-disabled-bg-color;
      }

      &:hover {
        & .e-switch-inner.e-switch-active {
          @if $skin-name == 'bootstrap4' {
            background-color: $switch-inner-active-bg;
            border-color: $switch-inner-active-bg;
          }
          @else {
            @if $skin-name != 'bootstrap5' and $skin-name != 'bootstrap5.3' {
              background-color: $switch-on-off-disabled-bg-color;
            }
            border-color: $switch-border-disabled-bg-color;
          }
        }
        & .e-switch-handle:not(.e-switch-active) {
          @if $skin-name == 'Material3' {
            background-color: $switch-on-disabled-bg-color;
          }
        }

        & .e-switch-inner {
          @if $skin-name == 'bootstrap4' {
            background-color: $switch-on-off-disabled-bg-color;
          }
          border-color: $switch-border-disabled-bg-color;
          color: $switch-disabled-font-color;
        }

        & .e-switch-inner.e-switch-active .e-switch-on {
          @if $skin-name == 'bootstrap4' {
            color: $switch-inner-on-font-color;
          }
          @else {
            color: $switch-disabled-font-color;
          }
          background-color: $switch-on-disabled-bg-color;
        }

        & .e-switch-handle {
          background-color: $switch-handle-disabled-bg-color;
        }

        & .e-switch-handle.e-switch-active {
          @if $skin-name == 'bootstrap4' {
            background-color: $switch-inner-on-font-color;
          }
          @else if $skin-name == 'FluentUI' {
            background-color: $switch-active-handle-bg;
          }
          @else {
            background-color: $switch-handle-disabled-bg-color;
          }
        }
      }
    }

    &:hover {
      & .e-switch-inner.e-switch-active {
        background-color: $switch-inner-hover-border-color;
        border-color: $switch-inner-hover-border-color;
      }

      & .e-switch-inner {
        background-color: $switch-hover-bg-color;
        border-color: $switch-hover-border;
      }

      & .e-switch-inner.e-switch-active .e-switch-on {
        background-color: $switch-on-hover-bg-color;
        color: $switch-inner-hover-on-font-color;
      }

      & .e-switch-handle.e-switch-active {
        @if $skin-name == 'FluentUI' {
          background-color: $switch-active-handle-bg;
        }

        @else {
          background-color: $switch-handle-hover-bg-color;
        }

        & .e-ripple-container {
          @if $skin-name == 'Material3' {
            background-color: $switch-checked-ripple-bg-color;
          }
        }
  
        & .e-ripple-check.e-ripple-container {
          @if $skin-name == 'Material3' {
            background-color: $switch-ripple-bg-color;
          }
        }
      }
    }

    &:not(.e-switch-disabled):hover {
      & .e-switch-handle:not(.e-switch-active) {
        @if $skin-name != 'bootstrap5.3' {
          background-color: $switch-handle-off-hover-bg-color;
        }
      }
    }

    &.e-focus,
    &:focus {
      & .e-switch-inner {
        background-color: $switch-inner-focus-off-bg;
        border-color: $switch-inner-focus-border;
        box-shadow: $switch-focus-inner-box-shadow;
        outline: $switch-focused-outline;
        outline-offset: $switch-focused-outline-offset;
      }

      & .e-switch-inner.e-switch-active {
        background-color: $switch-inner-focus-bg-color;
        border-color: $switch-focus-border-color-active;
        outline: $switch-focused-outline-active;
      }

      & .e-ripple-container {
        background-color: $switch-checked-ripple-bg-color;
      }

      & .e-ripple-check.e-ripple-container {
        background-color: $switch-ripple-bg-color;
      }
    }

    &.e-active,
    &:active {
      & .e-switch-inner {
        @if $skin-name == 'fluent2' {
          background-color: $content-bg-color;
          border-color: $border-pressed;
        }
        @else {
          background-color: $switch-inner-focus-off-bg;
          border-color: $switch-inner-focus-border;
        }
        box-shadow: $switch-focus-inner-box-shadow;
        outline: $switch-focused-outline;
        outline-offset: $switch-focused-outline-offset;

        &.e-switch-active {
          @if $skin-name == 'fluent2' {
            background-color: $primary-border-color-selected;
            border-color: $primary-border-color-selected;
          }
        }
      }

      & .e-switch-inner.e-switch-active {
        background-color: $switch-inner-focus-bg-color;
        border-color: $switch-focus-border-color-active;
        outline: $switch-focused-outline-active;
      }

      & .e-ripple-container {
        background-color: $switch-checked-ripple-bg-color;
      }

      & .e-ripple-check.e-ripple-container {
        background-color: $switch-ripple-bg-color;
      }
    }

    &.e-rtl {
      &.e-focus {

        & .e-switch-on {
          @if $skin-name == 'bootstrap4' or $skin-name == 'tailwind' or $skin-name == 'FluentUI' {
            background-color: $switch-on-hover-bg-color;
          }
          @else if $skin-name != 'bootstrap5.3' {
            background-color: $switch-on-off-hover-bg-color;
          }
        }

        & .e-switch-off {
          @if $skin-name == 'bootstrap4' or $skin-name == 'tailwind' or $skin-name == 'FluentUI' {
            background-color: $switch-off-bg-color;
          }
          @else {
            background-color: $switch-on-off-hover-bg-color;
          }
        }

        & .e-switch-inner.e-switch-active {
          background-color: $switch-inner-focus-bg-color;
          border-color: $switch-focus-border-color-active;
          color: $switch-inner-on-font-color;
          outline: $switch-focused-outline-active;
        }
      }

      & .e-switch-on {
        background-color: $switch-inner-active;
      }

      & .e-switch-handle {
        background-color: $switch-handle-bg-color;
        box-shadow: $switch-handle-shadow;
      }

      & .e-switch-inner.e-switch-active .e-switch-off {
        background-color: $switch-off-bg-color;
      }

      & .e-switch-handle.e-switch-active {
        background-color: $switch-active-handle-bg;
      }

      & .e-switch-inner.e-switch-active {
        background-color: $switch-inner-active-bg;
        border-color: $switch-inner-active-border-color;
      }

      &:hover {
        & .e-switch-inner.e-switch-active {
          @if $skin-name == 'bootstrap4' {
            background-color: $switch-on-hover-bg-color;
          }
          @else {
            background-color: $switch-inner-hover-bg-color;
          }
          border-color: $switch-inner-hover-border-color;
        }

        & .e-switch-inner {
          border-color: $switch-hover-border;
        }

        & .e-switch-inner.e-switch-active .e-switch-on {
          background-color: $switch-on-hover-bg-color;
        }

        & .e-switch-handle.e-switch-active {
          @if $skin-name == 'bootstrap4' {
            background-color: $switch-handle-hover-bg-color;
          }
          @else if $skin-name == 'FluentUI' {
            background-color: $switch-active-handle-bg;
          }
          @else {
            background-color: $switch-handle-hover-bg-color;
          }
        }
      }

      &.e-switch-disabled {
        & .e-switch-inner .e-switch-on {
          @if $skin-name == 'bootstrap4' {
            color: $switch-inner-on-font-color;
            opacity: $switch-disabled-bg-color-opacity;
          }
          @else {
            color: $switch-disabled-font-color;
            opacity: $switch-on-off-disabled-bg-color-opacity;
          }
          background-color: $switch-on-disabled-bg-color;
          @if $skin-name == 'fluent2' {
            border-color: $toggle-switch-border-disabled;
          }
        }

        & .e-switch-inner.e-switch-active {
          @if $skin-name == 'bootstrap4' {
            background-color: $switch-inner-active-bg;
            border-color: $switch-inner-active-bg;
          }
        }

        & .e-switch-inner .e-switch-off {
          @if $skin-name == 'bootstrap4' {
            opacity: $switch-disabled-bg-color-opacity;
          }
          @else {
            opacity: $switch-on-off-disabled-bg-color-opacity;
          }
          background-color: $switch-on-off-disabled-bg-color;
          color: $switch-disabled-font-color;
        }

        & .e-switch-handle {
          background-color: $switch-handle-disabled-bg-color;
          box-shadow: $switch-handle-shadow-disabled;
        }

        & .e-switch-handle.e-switch-active {
          @if $skin-name == 'bootstrap4' {
            background-color: $switch-inner-on-font-color;
          }
          @else {
            background-color: $switch-handle-on-disabled-bg-color;
          }
          box-shadow: $switch-handle-shadow-disabled;
        }

        & .e-switch-inner {
          background-color: $switch-on-off-disabled-bg-color;
          border-color: $switch-border-disabled-bg-color;
          opacity: $switch-on-off-disabled-bg-color-opacity;
        }

        &:hover {
          & .e-switch-inner.e-switch-active .e-switch-on {
            @if $skin-name == 'bootstrap4' {
              color: $switch-inner-on-font-color;
            }
            @else {
              color: $switch-disabled-font-color;
            }
            background-color: $switch-on-disabled-bg-color;
          }

          & .e-switch-inner.e-switch-active {
            @if $skin-name == 'bootstrap4' {
              background-color: $switch-inner-active-bg;
              border-color: $switch-inner-active-bg;
            }
            @else {
              background-color: $switch-on-off-disabled-bg-color;
              border-color: $switch-border-disabled-bg-color;
            }
          }

          & .e-switch-inner {
            border-color: $switch-border-disabled-bg-color;
            color: $switch-disabled-font-color;
          }

          & .e-switch-handle.e-switch-active {
            @if $skin-name == 'bootstrap4' {
              background-color: $switch-inner-on-font-color;
            }
            @else {
              background-color: $switch-handle-on-disabled-bg-color;
            }
          }

          & .e-switch-handle {
            background-color: $switch-handle-disabled-bg-color;
          }
        }
      }
    }

    & .e-switch {
      &:focus {
        box-shadow: $switch-focus-inner-box-shadow;
      }
    }

    &.e-small {
      &.e-rtl.e-switch-disabled {
        &:hover {
          & .e-switch-inner.e-switch-active {
            background-color: $switch-small-disabled-rtl-active-bg-color;
          }
        }
      }
    }
  }
}

.e-switch-wrapper:focus .e-switch-handle:not(.e-switch-active),
.e-switch-wrapper:active .e-switch-handle:not(.e-switch-active),
.e-switch-wrapper.e-focus .e-switch-handle:not(.e-switch-active) {
  @if $skin-name == 'bootstrap5.3' {
    background-color: $primary-light;
  }
}
