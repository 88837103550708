@include export-module('tab-theme') {
  .e-tab {
    background: $tab-bg-color;
    border: $tab-border;

    .e-tab-header {
      background: inherit;
      border: 0;
      border-radius: 0;
      box-shadow: none;
      font-weight: $tab-header-font-weight;
      border-bottom: $tab-header-border-bottom;

      &::before {
        border-color: $tab-header-before-border-color;
        border-style: solid;
      }

      &:not(.e-vertical) {
        &::before {
          border-width: $tab-header-before-border-width;
        }
      }

      &:not(.e-vertical) {

        .e-toolbar-item.e-active {
          border-bottom: $tab-item-active-border-bottom;
          margin-bottom: 0;
        }
      }

      .e-toolbar-items {
        background: inherit;
      }

      .e-indicator {
        background: $tab-border-color;
      }

      .e-toolbar-item {
        background: inherit;
        border: $tab-item-border;

        &:hover {
          background: $tab-item-hover-bg;
        }

        &.e-active {
          @if ($skin-name == 'Bootstrap5') {
            background: $tab-item-active-bg;
          }
        }

        &.e-active:hover {
          background: $tab-item-active-hover-bg;
        }

        .e-ripple-element {
          background: $tab-ripple-bg-color;
        }

        .e-tab-wrap {
          border-radius: $tab-active-item-border-radius;
          color: $tab-text-color;
          border: $tab-item-wrap-border;

          .e-tab-icon {
            color: $tab-wrap-comb-icon-color;
          }

          @media screen and (max-width: 480px) {
            color: $tab-mob-text-color;

            .e-tab-icon {
              color: $tab-mob-text-color;
            }

            .e-close-icon {
              color: $tab-mob-close-icon-color;
            }
          }

          &:hover {
            background: $tab-item-hover-bg-color;
            border: $tab-item-wrap-hover-border;
            border-radius: $tab-item-wrap-hover-border-radius;

            @media screen and (max-width: 480px) {
              background: $tab-mob-item-hover-bg-color;
            }

            .e-tab-text,
            .e-tab-icon {
              color: $tab-hover-text-color;
            }
          }
        }

        &.e-active {
          border: $tab-active-item-border;
          border-radius: $tab-top-active-item-border-radius;

          .e-tab-wrap {
            border: $tab-item-active-tabwrap-border;
            &:hover {

              .e-tab-text,
              .e-tab-icon {
                color: $tab-active-text-color;
              }
            }
          }

          .e-text-wrap {
            position: $tab-active-wrap-position;

            &::before {
              border: $tab-active-wrap-before-border;
              content: $tab-active-wrap-before-content;
              display: $tab-active-wrap-before-display;
              position: $tab-active-wrap-before-position;
              width: $tab-active-wrap-before-width;
              @if ($skin-name == 'Material3') {
                border-radius: 3px 3px 0 0;
                bottom: $tab-active-wrap-before-top;
              }
              @else {
                top: $tab-active-wrap-before-top;
              }
            }
          }

          .e-tab-text,
          .e-tab-icon {
            color: $tab-active-text-color;
          }

          &.e-ileft,
          &.e-iright {

            .e-tab-icon {
              color: $tab-comb-icon-color;
            }

            .e-tab-text {
              color: $tab-comb-text-color;
            }
          }

          .e-close-icon {
            color: $tab-active-close-icon-color;
          }
        }

        .e-close-icon {
          color: $tab-close-icon-color;

          &:hover {
            color: $tab-close-icon-hover-color;
          }

          &:active {
            color: $tab-close-icon-active-color;
          }

          @if $skin-name == 'bootstrap5.3' {
            &:focus {
              color: $tab-close-icon-focus-color;
            }
          }
        }

        &.e-disable.e-overlay {
          background: inherit;
          opacity: 1;
          @if $skin-name =='bootstrap5.3' {
            opacity: .65;
          }
          pointer-events: none;

          .e-tab-text,
          .e-tab-icon {
            color: $tab-disable-text-color;
          }
        }
      }

      &.e-reorder-active-item:not(.e-vertical) .e-toolbar-pop .e-toolbar-item.e-active {
        border: $tab-active-item-hide-border;
      }

      .e-toolbar-pop {
        background: $tab-pop-bg-color;
        border: $tab-pop-border;
        border-radius: $tab-pop-border-radius;
        box-shadow: $tab-pop-box-shadow;
        overflow-y: auto;

        &[e-animate = 'true'] {
          overflow-y: hidden;
        }

        @media screen and (max-width: 480px) {
          box-shadow: $tab-pop-box-shadow;
        }

        .e-toolbar-item {

          .e-tab-wrap {

            .e-tab-text,
            .e-tab-icon {
              color: $tab-pop-text-color;
            }

            &:hover {
              background: $tab-hover-bg-color;
              border: $tab-pop-wrap-hover-border;
              border-radius: $tab-pop-wrap-hover-border-radius;

              .e-tab-text,
              .e-tab-icon,
              .e-close-icon {
                color: $tab-pop-wrap-hover-close-color;
                @if $skin-name == 'bootstrap5.3' {
                  color: $tab-alt-active-style-hover-text-color;
                }
              }
            }

            &:active {
              background: $tab-active-bg-color;
              border-color: $tab-pop-wrap-active-border-color;

              .e-tab-text,
              .e-tab-icon {
                color: $tab-pop-wrap-active-icon-font;
              }
            }
          }

          &.e-active .e-tab-wrap {

            .e-tab-text,
            .e-tab-icon {
              color: $tab-active-text-color;
            }
          }

          &.e-disable.e-overlay {
            .e-tab-text,
            .e-tab-icon {
              color: $tab-disable-text-color;
            }
          }
        }
      }

      .e-scroll-nav,
      .e-hscroll:not(.e-scroll-device) .e-scroll-nav {
        background: $tab-scroll-nav-bg;
        border: 0;

        .e-nav-arrow {
          color: $tab-scroll-nav-arrow-font;
          border: $tab-item-active-wrap-border;

          &:hover {
            background: $tab-hover-nav-bg-color;
            color: $tab-hover-nav-icon-color;
            border: $tab-scroll-arrow-hover-border;
          }

          &:active {
            box-shadow: none;
            border: $tab-scroll-arrow-active-border;
          }
        }

        &:focus {
          background: $tab-scroll-nav-bg;

          .e-nav-arrow {
            background: $tab-nav-focus-bg-color;
            color: $tab-nav-focus-icon-color;
            border: $tab-scroll-focus-arrow-border;

            &:hover {
              background: $tab-hover-nav-bg-color;
              color: $tab-hover-nav-icon-color;
            }

            &:active {
              background: $tab-scroll-focus-arrow-active-bg;
              border-color: $tab-scroll-focus-arrow-active-border-color;
              color: $tab-scroll-focus-arrow-active-color;
            }
          }
        }

        &:active {
          box-shadow: none;
          background: $tab-scroll-nav-bg;
          border: $tab-scroll-active-border;
          @if $skin-name == 'bootstrap5.3' or $skin-name == 'fluent2' {
            background: $tab-vertical-scroll-hover-active-bg;
            .e-nav-arrow {
              color: $tab-pop-focus-icon-active-color;
            }
          }
        }

        &.e-overlay {

          .e-nav-arrow {
            color: $tab-scroll-overlay-arrow-color;

            &:hover {
              color: $tab-scroll-overlay-arrow-hover-color;
              background: $tab-scroll-overlay-arrow-hover-bg;
              border-color: $tab-scroll-overlay-arrow-hover-border-color;
            }
          }
        }
      }

      .e-hor-nav,
      .e-hor-nav:not(.e-expended-nav) {
        background: transparent;
        border: 0;

        .e-popup-up-icon,
        .e-popup-down-icon {
          color: $tab-pop-icon-color;
          border: $tab-pop-icon-border;

          &:hover {
            background: $tab-pop-icon-hover-bg-color;
            border: $tab-pop-up-icon-hover-border;
            border-radius: $tab-active-item-border-radius;
          }
        }

        .e-popup-down-icon {

          &:hover {
            color: $tab-hover-nav-icon-color;
          }
        }

        &:focus {
          background: $tab-pop-focus-bg;
          .e-popup-up-icon,
          .e-popup-down-icon {
            background: $tab-nav-focus-bg-color;
            border: $tab-pop-up-icon-focus-border;
            border-radius: $tab-active-item-border-radius;
            color: $tab-nav-focus-icon-color;

            &:hover {
              background: $tab-pop-icon-hover-bg-color;
              border: $tab-pop-up-down-icon-hover-border;
              border-radius: $tab-active-item-border-radius;
            }

            &:active {
              background: $tab-pop-focus-icon-active-bg;
              color: $tab-pop-focus-icon-active-color;
              border: $tab-pop-up-icon-active-border;
              border-radius: $tab-active-item-border-radius;
              box-shadow: $tab-pop-border-box-shadow;
            }
          }
        }

        &:hover {
          border: 0;
          background: $tab-pop-hover-bg;

          &:active {
            background: transparent;
            @if $skin-name == 'bootstrap5.3' {
              background: $tab-pop-focus-bg;
            }
          }
        }

        &:active {
          box-shadow: none;
          background: $tab-pop-focus-bg;
          @if $skin-name == 'bootstrap5.3' or $skin-name == 'fluent2' {
            .e-popup-down-icon,
            .e-popup-up-icon {
              color: $tab-pop-focus-icon-active-color;
            }
          }
        }

        &.e-nav-active {
          background: $tab-pop-nav-active-bg;
          border: $tab-pop-nav-active-border;
          box-shadow: none;
          border-radius: $tab-pop-nav-active-border-radius;

          .e-icons {
            color: $tab-pop-active-icons-color;
          }

          &:focus {
            border: $tab-pop-nav-active-border;
          }
        }
      }

      &.e-horizontal-bottom {
        border-color: $tab-bottom-border-color;
        border-style: solid;
        border-width: $tab-bottom-border-width;

        &[style *= 'overflow: hidden'] {
          border-color: $tab-bottom-border-color;
          border-style: solid;
          border-width: $tab-bottom-border-width;
        }

        &[style *= 'overflow: hidden'] .e-toolbar-items {
          border-color: $tab-bottom-hidden-items-border-color;
          border-style: solid;
          border-width: $tab-bottom-hidden-items-border-width;
        }

        .e-toolbar-item {

          .e-tab-wrap:hover {
            border-radius: $tab-bottom-active-item-border-radius;
          }

          &.e-active {
            border-color: $tab-bottom-active-border-color;
            border-style: solid;
            border-width: $tab-bottom-active-border-width;
            margin-top: $tab-bottom-active-margin-top;
            border-radius: $tab-bottom-active-item-border-radius;
          }
        }
      }

      &.e-vertical {
        border-bottom: 0;

        &::before {
          border-width: $tab-vertical-before-border-width;
        }

        .e-toolbar-item {

          &.e-active {
            border-bottom: $tab-hdr-border;
            border-top: $tab-hdr-border;
          }
        }

        &.e-vertical-left {

          .e-toolbar-item {

            .e-tab-wrap:hover {
              border-radius: $tab-left-active-item-border-radius;
            }

            &.e-active {
              border-radius: $tab-left-active-item-border-radius;
              border-right: $tab-item-active-border-bottom;
            }
          }
        }

        &.e-vertical-right {

          .e-toolbar-item {

            .e-tab-wrap:hover {
              border-radius: $tab-right-active-item-border-radius;
            }

            &.e-active {
              border-left: $tab-item-active-border-bottom;
              border-radius: $tab-right-active-item-border-radius;
            }
          }
        }

        .e-toolbar-pop {
          box-shadow: $tab-v-popup-box-shadow;
        }

        .e-scroll-nav {
          background: inherit;
          border: $tab-vscroll-nav-border;

          &.e-scroll-up-nav {
            border-bottom-color: $tab-vscroll-nav-border-color;
          }

          &.e-scroll-down-nav {
            border-top-color: $tab-vscroll-nav-border-color;
          }

          .e-nav-arrow {
            border: 0;

            &:hover {
              background: none;
            }
          }

          &:hover {
            background: $tab-v-nav-hover-bg-color;
            border-color: $tab-vertical-scroll-hover-border-color;

            &:active:focus {
              background: $tab-vertical-scroll-hover-active-bg;
            }

            .e-nav-arrow {
              color: $tab-hover-nav-icon-color;

              &:hover {
                background: none;
              }
            }
          }

          &:focus {
            background: $tab-v-nav-focus-bg-color;

            &:hover:active {

              .e-nav-arrow {
                color: $tab-vertical-focus-active-arrow-color;
              }
            }

            .e-nav-arrow {
              background: none;

              &:hover {
                background: none;
                border: 0;
              }
            }
          }

          &:active {
            @if $skin-name == 'bootstrap5.3' {
              background: $tab-vertical-scroll-hover-active-bg;
            }
          }
        }

        .e-hor-nav {

          .e-popup-up-icon,
          .e-popup-down-icon {
            border: 0;

            &:hover {
              background: inherit;
              border: 0;
              color: $tab-vertical-pop-icon-hover-color;
            }
          }

          &:focus {

            .e-popup-up-icon,
            .e-popup-down-icon {
              background: inherit;
              border: 0;

              &:hover,
              &:active,
              &:active:hover {
                background: inherit;
                border: 0;
                box-shadow: none;
              }

              &:active {
                color: $tab-vertical-pop-icon-active-color;
              }
            }
          }

          &:hover {

            .e-popup-up-icon,
            .e-popup-down-icon {
              border: 0;
            }
          }
        }
      }
    }

    &.e-focused > .e-tab-header {

      .e-toolbar-item {
        @if ($skin-name == 'Material3') {
          &.e-active .e-tab-wrap:focus {
            background: $tab-active-focus-bg-color;

            .e-tab-icon,
            .e-tab-text {
              color: $tab-active-text-color;
            }
          }
        }

        .e-tab-wrap:focus {
          @if ($skin-name == 'fluent2') {
            box-shadow: $tab-focus-box-shadow;
          }
          background: $tab-focus-bg-color;
          border: $tab-focused-wrap-focus-border;

          .e-tab-icon,
          .e-tab-text {
            color: $tab-focus-text-color;
          }

          .e-tab-icon {
            color: $tab-focused-wrap-focus-icon-color;
          }
        }
      }

      .e-scroll-nav {

        &:focus {

          .e-nav-arrow {
            color: $tab-nav-focus-icon-color;
          }
        }
      }

      .e-hor-nav {

        &:focus {

          .e-popup-up-icon,
          .e-popup-down-icon {
            color: $tab-nav-focus-icon-color;
            background: $tab-scroll-focused-nav-focus-icon-bg;
            border: $tab-scroll-focused-nav-focus-icon-border;
          }
        }
      }

      &.e-vertical {

        .e-scroll-nav {

          &:focus {
            border-color: $tab-vertical-focused-nav-focus-border-color;

            .e-nav-arrow {
              border: 0;
            }
          }
        }

        .e-hor-nav {

          .e-popup-up-icon,
          .e-popup-down-icon {
            border: 0;
          }

          &:focus {
            outline: $tab-v-pop-focus-outline;
          }
        }
      }
    }

    &.e-disable {
      pointer-events: none;

      .e-tab-header {

        .e-indicator {
          background: $tab-disable-border-color;
        }

        .e-toolbar-item {

          .e-tab-wrap {
            color: $tab-disable-text-color;
          }

          &.e-active {

            .e-tab-text,
            .e-tab-icon {
              color: $tab-disable-active-text-color;
            }
          }
        }
      }

      .e-content {
        opacity: $tab-disable-cnt-opacity;
      }
    }

    &.e-fill {

      .e-tab-header {
        border-bottom: $tab-fill-hdr-border;

        .e-toolbar-items.e-hscroll {
          border: 0;
        }

        .e-toolbar-item {
          border: 0;

          .e-ripple-element {
            background: $tab-fill-ripple-bg-color;
          }

          .e-tab-wrap {
            color: $tab-text-color;

            &:hover {
              @if ($skin-name == 'fluent2') {
                background: $tab-fill-wrap-hover-bg;
              }
            }

            &:hover .e-tab-text,
            &:hover .e-tab-icon {
              color: $tab-hover-text-color;
              @if ($skin-name == 'fluent2') {
                color: $tab-fill-wrap-hover-icon-color;
              }
            }

            &:hover .e-tab-icon {
              color: $tab-fill-wrap-hover-icon-color;
            }

            @if ($skin-name == 'fluent2') {
              &:hover .e-close-icon {
                color: $tab-fill-wrap-hover-icon-color;
              }
            }
          }

          .e-text-wrap {
            margin-top: 0;
          }

          &.e-active {
            border: 0;

            &.e-disable.e-overlay {

              .e-tab-text,
              .e-tab-icon {
                color: $tab-fill-background-disable-text-color;
              }
            }

            .e-tab-wrap {
              background: $tab-fill-active-bg-color;
              border-radius: $tab-active-item-border-radius;
              border-color: $tab-fill-active-tabwrap-border-color;

              &:hover .e-tab-text,
              &:hover .e-tab-icon {
                color: $tab-alt-active-text-color;
              }
            }

            .e-text-wrap {
              margin-top: 0;
            }

            .e-text-wrap::before {
              border: $tab-fill-active-text-before-border;
            }

            .e-tab-text,
            .e-tab-icon {
              color: $tab-alt-active-text-color;
            }

            .e-close-icon {
              color: $tab-alt-active-close-icon-color;
            }

            .e-close-icon:hover {
              color: $tab-fill-active-close-hover-color;
            }
          }
        }

        .e-toolbar-pop {

          .e-toolbar-item:active:hover {
            .e-tab-text,
            .e-tab-icon {
              color: $tab-fill-pop-active-icon-color;
            }
          }

          .e-tab-wrap {
            &:hover {
              background: $tab-fill-pop-wrap-hover-bg;

              .e-tab-text {
                color: $tab-fill-pop-wrap-hover-text-color;
              }

              .e-tab-icon {
                color: $tab-fill-pop-wrap-hover-icon-color;
              }

              .e-close-icon {
                color: $tab-fill-pop-wrap-hover-close-color;
              }
            }
          }
        }

        &.e-horizontal-bottom {
          border-bottom: 0;
          border-top: $tab-fill-hdr-border;

          .e-toolbar-item {

            &.e-active .e-text-wrap {
              margin-top: 0;
            }
          }
        }

        &.e-vertical {
          border-bottom: 0;

          &.e-vertical-left {
            border-right: $tab-fill-hdr-border;
          }

          &.e-vertical-right {
            border-left: $tab-fill-hdr-border;
          }
        }
      }

      &.e-corner {

        .e-tab-header {

          .e-toolbar-item {

            &.e-active .e-tab-wrap {
              border-radius: $tab-fill-active-wrap-corner;
            }
          }

          &.e-horizontal-bottom {

            .e-toolbar-item.e-active .e-tab-wrap {
              border-radius: $tab-fill-even-active-wrap-corner;
            }
          }
        }
      }

      &.e-disable {

        .e-tab-header {
          border-bottom: $tab-fill-disable-hdr-border;

          .e-toolbar-item {

            .e-tab-wrap {
              color: $tab-disable-text-color;
            }

            &.e-active {
              opacity: $tab-alt-disable-active-item-opacity;

              .e-tab-wrap,
              .e-tab-wrap:focus {
                background: $tab-fill-disable-active-bg-color;
              }

              .e-tab-wrap .e-tab-text,
              .e-tab-wrap .e-tab-icon,
              .e-tab-wrap:focus .e-tab-text,
              .e-tab-wrap:focus .e-tab-icon {
                color: $tab-alt-disable-active-text-color;
              }
            }
          }

          &.e-horizontal-bottom {
            border-bottom: 0;
            border-top: $tab-fill-disable-hdr-border;
          }
        }
      }

      &.e-focused .e-tab-header {

        .e-toolbar-item {

          .e-tab-wrap:focus .e-tab-icon,
          .e-tab-wrap:focus .e-tab-text,
          .e-tab-wrap:focus .e-close-icon {
            color: $tab-hover-text-color;
          }

          &.e-active .e-tab-wrap:focus {
            background: $tab-fill-focus-bg-color;
            border-color: $tab-fill-focused-active-tabwrap-focus-border-color;

            .e-tab-text {
              color: $tab-fill-focused-active-wrap-focus-text-color;
            }

            .e-close-icon {
              color: $tab-fill-focused-active-wrap-focus-close-color;
            }

            .e-tab-text,
            .e-tab-icon {
              color: $tab-alt-active-style-hover-icon-color;
            }
          }
        }
      }
    }

    &.e-background {

      .e-tab-header {
        background: $tab-alt-hdr-bg-color;
        border: 0;

        .e-toolbar-items {
          background: $tab-alt-item-bg-color;
        }

        .e-toolbar-items.e-hscroll {
          border: 0;
        }

        .e-indicator {
          background: $tab-alt-border-color;
          bottom: $tab-background-indicator-bottom;
        }

        .e-toolbar-item {
          border: 0;

          .e-ripple-element {
            background: $tab-alt-ripple-bg-color;
          }

          .e-tab-wrap {
            background: $tab-alt-item-bg-color;
            border-color: $tab-background-wrap-border-color;

            .e-tab-icon,
            .e-close-icon {
              color: $tab-alt-text-color;
            }

            .e-tab-text {
              color: $tab-background-text-color;
            }

            .e-close-icon:hover {
              color: $tab-background-close-hover-color;
            }

            &:hover {
              background: $tab-bg-item-wrap-hover-bg-color;
              background-color: $tab-background-wrap-hover-bg-color;
              border-color: $tab-background-wrap-hover-border-color;

              .e-tab-text {
                color: $tab-background-wrap-hover-text-color;
              }

              .e-tab-icon {
                color: $tab-background-wrap-hover-icon-color;
              }

              .e-close-icon {
                color: $tab-background-wrap-hover-close-color;

                &:hover,
                &:active {
                  color: $tab-background-close-hover-active-color;
                }
              }
            }
          }

          &.e-active {
            border: 0;
            margin: 0;
            background: $tab-background-active-bg;

            &.e-disable.e-overlay {

              .e-tab-text,
              .e-tab-icon {
                color: $tab-fill-background-disable-text-color;
              }
            }

            .e-tab-wrap {
              background: $tab-background-active-wrap-bg;
              border-color: $tab-background-active-wrap-border-color;
              border-radius: $tab-background-active-wrap-border-radius;
              border-style: solid;
              border-width: $tab-background-active-wrap-border-width;

              .e-tab-text {
                color: $tab-background-active-text-color;
              }

              .e-tab-icon {
                color: $tab-background-active-icon-color;
              }

              .e-close-icon {
                color: $tab-background-active-close-color;
              }

              &:hover {
                background: $tab-background-active-hover-bg;
                border-color: $tab-background-active-hover-border-color;
                .e-tab-text {
                  color: $tab-background-active-hover-text-color;
                }

                .e-tab-icon {
                  color: $tab-background-active-hover-icon-color;
                }

                .e-close-icon {
                  color: $tab-background-active-hover-close-color;

                  &:hover {
                    color: $tab-background-active-close-hover-color;
                  }

                  &:active {
                    color: $tab-background-activeitem-close-active-color;
                  }
                }

                &:focus {
                  background: $tab-background-active-wrap-focus-bg;

                  .e-tab-icon,
                  .e-tab-text {
                    color: $tab-background-active-wrap-focus-text-color;
                  }
                }
              }

              &:focus {
                background: $tab-alt-active-focus-bg-color;

                .e-tab-text,
                .e-tab-icon {
                  color: $tab-background-active-wrap-focus-icon;
                }
              }

              &:focus:hover {

                .e-tab-text {
                  color: $tab-background-active-wrap-focushover-text-color;
                }

                .e-close-icon {
                  color: $tab-background-active-wrap-focushover-close-color;
                }
              }
            }

            .e-text-wrap::before {
              border: $tab-nrml-bottom-wrap-before-top;
              @if ($skin-name == 'Material3') {
                border-radius: 3px 3px 0 0;
              }
            }

            .e-tab-text,
            .e-tab-icon {
              color: $tab-background-active-text-icon-color;
            }

            .e-close-icon {
              color: $tab-background-active-close-icon-color;
            }

            .e-close-icon:hover,
            .e-tab-text:hover {
              color: $tab-background-active-close-text-hover-color;
            }

            &.e-itop .e-tab-wrap,
            &.e-ibottom .e-tab-wrap {
              background: $tab-alt-tb-active-bg-color;
            }
          }

          &.e-disable {
            .e-tab-text,
            .e-tab-icon,
            .e-close-icon {
              color: $tab-alt-disable-text-color;
            }
          }
        }

        .e-toolbar-pop {

          .e-toolbar-item {
            background: $tab-alt-item-bg-color;

            .e-tab-wrap {

              .e-tab-text,
              .e-tab-icon,
              .e-close-icon {
                color: $tab-background-pop-text-color;
              }

              &:hover {
                background: $tab-background-pop-wrap-hover-bg;

                .e-tab-text,
                .e-tab-icon,
                .e-close-icon {
                  color: $tab-background-pop-wrap-hover-text-color;
                }
              }

              &:active {
                .e-tab-text {
                  color: $tab-background-pop-wrap-active-text-color;
                }

                .e-tab-icon {
                  color: $tab-background-pop-wrap-active-icon-color;
                }

                .e-close-icon {
                  color: $tab-background-pop-wrap-active-close-color;
                }
              }
            }
          }
        }

        .e-hscroll:not(.e-scroll-device) .e-scroll-nav,
        .e-scroll-nav {
          background: $tab-background-scroll-bg;

          .e-nav-arrow {
            color: $tab-alt-nav-icon-color;
            border: $tab-background-scroll-arrow-border;

            &:hover {
              background: $tab-background-scroll-arrow-hover-bg;
              color: $tab-bg-hover-nav-icon-color;
              border-color: $tab-background-scroll-arrow-hover-border-color;
            }

            &:active {
              background: $tab-background-scroll-arrow-active-bg;
              color: $tab-background-scroll-arrow-active-color;
            }
          }

          &:focus {

            .e-nav-arrow {
              color: $tab-bg-hover-nav-icon-color;
              border: $tab-background-scroll-focus-arrow-border;
              background: $tab-background-scroll-focus-arrow-bg;

              &:hover {
                background: $tab-bg-hover-nav-bg-color;
                color: $tab-bg-hover-nav-icon-color;
              }

              &:active {
                background: $tab-background-nav-focus-active-bg;
                border-color: $tab-background-nav-focus-active-border-color;
                color: $tab-background-nav-focus-active-color;
              }
            }
          }

          &:active::after {
            @include hscroll-alt-btn-animation-after;
          }
        }

        .e-hor-nav:not(.e-expended-nav),
        .e-hor-nav {
          background: $tab-background-hor-nav-bg;

          .e-popup-up-icon,
          .e-popup-down-icon {
            color: $tab-background-pop-icon-color;
            border: $tab-background-pop-icon-border;

            &:hover {
              background: $tab-background-pop-icon-hover-bg;
              border-color: $tab-background-pop-icon-hover-border-color;
              color: $tab-bg-hover-nav-icon-color;
            }

            &:active {
              &:hover {
                background: $tab-background-pop-active-hover-bg;
                color: $tab-background-pop-active-hover-color;
              }
            }
          }

          &:focus {

            .e-popup-up-icon,
            .e-popup-down-icon {
              color: $tab-bg-hover-nav-icon-color;
              background: $tab-background-focus-pop-bg;
              border: $tab-background-focus-pop-border;

              &:hover {
                background: $tab-background-focus-pop-hover-bg;
              }

              &:active {
                background: $tab-background-popicon-focus-active-bg;
                border-color: $tab-background-popicon-focus-active-border-color;
                color: $tab-background-popicon-focus-active-color;
              }
            }
          }

          &:active::after {
            @include tbar-alt-btn-animation-after;
          }

          &.e-nav-active {
            .e-popup-up-icon {
              color: $tab-bg-hover-nav-icon-color;
            }
          }
        }

        &.e-horizontal-bottom {
          .e-toolbar-item.e-active {
            border: 0;
            margin: 0;

            .e-tab-wrap {
              border-color: $tab-background-bottom-active-wrap-border-color;
              border-radius: $tab-background-bottom-active-wrap-border-radius;
              border-style: solid;
              border-width: $tab-background-bottom-active-wrap-border-width;
            }
          }
        }

        &.e-vertical {

          &.e-vertical-left {
            .e-toolbar-item.e-active {
              border-color: $tab-background-vertical-left-active-border-color;
              border-style: solid;
              border-width: $tab-background-vertical-left-active-border-width;
              margin: 0;
              @if ($skin-name == 'fluent2') {
                &::before {
                  background-color: $tab-background-text-color;
                }
              }

              .e-tab-wrap {
                border-color: $tab-background-bottom-active-wrap-border-color;
                border-radius: $tab-background-vertical-left-active-wrap-border-radius;
                border-style: solid;
                border-width: $tab-background-vertical-left-active-wrap-border-width;
              }
            }
          }

          &.e-vertical-right {
            .e-toolbar-item.e-active {
              border-color: $tab-background-vertical-left-active-border-color;
              border-style: solid;
              border-width: $tab-background-vertical-right-active-border-width;
              margin: 0;

              @if ($skin-name == 'fluent2') {
                &::before {
                  background-color: $tab-background-text-color;
                }
              }

              .e-tab-wrap {
                border-color: $tab-background-bottom-active-wrap-border-color;
                border-radius: $tab-background-vertical-right-active-wrap-border-radius;
                border-style: solid;
                border-width: $tab-background-vertical-right-active-wrap-border-width;
              }
            }
          }

          .e-scroll-nav {

            .e-nav-arrow {
              border: 0;
            }

            &:focus {

              .e-nav-arrow {
                border: 0;

                &:hover {
                  background: none;
                }
              }
            }

            &:hover {

              .e-nav-arrow {
                color: $tab-bg-hover-nav-icon-color;
              }
            }
          }

          .e-hor-nav {

            &:focus {

              .e-popup-up-icon,
              .e-popup-down-icon {
                background: inherit;
                border-color: transparent;

                &:hover {
                  background: inherit;
                  border-color: transparent;
                }
              }
            }

            .e-popup-up-icon,
            .e-popup-down-icon {

              &:hover {
                background: inherit;
                border-color: transparent;
              }
            }
          }
        }
      }

      &.e-disable .e-tab-header {

        .e-indicator {
          background: $tab-alt-disable-border-color;
        }

        .e-toolbar-item {

          .e-tab-wrap {
            color: $tab-alt-disable-text-color;

            .e-tab-text,
            .e-tab-icon {
              color: $tab-alt-disable-text-color;
            }

            &:focus {

              .e-tab-text,
              .e-tab-icon {
                color: $tab-alt-disable-text-color;
              }
            }
          }

          &.e-active {
            opacity: $tab-alt-disable-active-item-opacity;

            .e-tab-wrap,
            .e-tab-wrap:focus {
              background: $tab-alt-disable-active-bg-color;
              color: $tab-alt-disable-active-text-color;

              .e-tab-text,
              .e-tab-icon {
                color: $tab-alt-disable-active-text-color;
              }
            }
          }
        }
      }

      &.e-accent .e-tab-header {

        .e-indicator {
          background: $tab-background-accent-indicator-bg;
        }

        &.e-disable {

          .e-indicator {
            background: $tab-disable-border-color;
          }
        }
      }

      &.e-focused .e-tab-header {

        .e-toolbar-item {

          .e-tab-wrap:focus {
            @if ($skin-name == 'fluent2') {
              box-shadow: $tab-focus-box-shadow;
            }
            background: $tab-background-focused-wrap-focus-bg;
            border-color: $tab-background-focused-wrap-focus-border-color;

            .e-tab-icon,
            .e-tab-text,
            .e-close-icon {
              color: $tab-background-focused-wrap-focus-close-color;
            }
          }

          &.e-active {

            .e-tab-wrap:focus {
              background: $tab-background-focused-active-wrap-focus-bg;
              border-color: $tab-background-focused-active-wrap-focus-border-color;
            }

            .e-tab-wrap:focus .e-tab-text,
            .e-tab-wrap:focus .e-tab-icon {
              color: $tab-background-focused-active-wrap-icon-color;
            }

            .e-tab-wrap:hover {
              background: $tab-background-focused-active-wrap-hover-bg;
              border: $tab-background-focused-active-wrap-hover-border;
              border-radius: $tab-background-focused-active-wrap-hover-border-radius;
            }

            .e-tab-wrap:focus {
              .e-tab-text {
                color: $tab-background-focused-active-wrap-focus-text-color;
              }

              .e-tab-icon {
                color: $tab-background-focused-active-wrap-focus-icon-color;
              }

              .e-close-icon {
                color: $tab-background-focused-active-wrap-focus-close-color;
              }
            }

            .e-tab-wrap:hover {
              .e-tab-text {
                color: $tab-background-focused-active-wrap-hover-text-color;
              }

              .e-tab-icon {
                color: $tab-background-focused-active-wrap-hover-icon-color;
              }

              .e-close-icon {
                color: $tab-background-focused-active-wrap-hover-close-color;
              }
            }

            .e-tab-wrap:focus:hover {
              .e-tab-text {
                color: $tab-background-focused-active-focus-hover-text-color;
              }
              .e-close-icon {
                color: $tab-background-focused-active-focus-hover-close-color;
              }
            }

            &.e-itop .e-tab-wrap,
            &.e-ibottom .e-tab-wrap {
              background: $tab-alt-tb-active-bg-color;

              &:focus {
                background: $tab-key-alt-active-focus-bg-color;
              }
            }
          }
        }

        .e-scroll-nav {

          &:focus {

            .e-nav-arrow {
              color: $tab-bg-nav-focus-icon-color;
            }
          }
        }

        .e-hor-nav {

          &:focus {

            .e-popup-up-icon,
            .e-popup-down-icon {
              color: $tab-bg-nav-focus-icon-color;
            }
          }
        }
      }
    }

    &.e-rtl {

      .e-tab-header {

        .e-hscroll.e-rtl {

          .e-scroll-nav {
            border: 0;
          }
        }
      }
    }

    .e-content {
      background: inherit;

      .e-item {
        background: inherit;
        color: $tab-content-font-color;
        font-size: $tab-content-font-size;
      }
    }
  }

  .e-tab-clone-element {
    background: $tab-clone-item-bg-color;
    color: $tab-clone-text-color;

    .e-tab-wrap {
      border-radius: $tab-active-item-border-radius;
      color: $tab-clone-text-color;
      border: $tab-clone-item-wrap-border;

      .e-tab-icon {
        color: $tab-clone-text-color;
      }
    }
  }
}
