@include export-module('maskedtextbox-bigger') {

  .e-bigger .e-control-wrapper.e-mask #{&}.e-maskedtextbox {
    @if $skin-name != 'bootstrap' {
      font-size: $mask-bigger-font-size;
    }
  }
    
  .e-control-wrapper.e-mask.e-bigger #{&}.e-maskedtextbox {
    @if $skin-name != 'bootstrap' {
      font-size: $mask-bigger-font-size;
    }
  }

  .e-bigger .e-content-placeholder.e-mask.e-placeholder-mask,
  .e-bigger.e-content-placeholder.e-mask.e-placeholder-mask {
    background-size: 300px 40px;
    min-height: 40px;
  }
}
