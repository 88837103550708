// Variables
$placeholder-opacity: 0.5;

// Mixins
@mixin placeholder-styles {
  color: var(--text-color);
  opacity: $placeholder-opacity;
}

@mixin float-line-styles {
  background: var(--primary-color) !important;
}

@mixin input-base-styles {
  color: var(--text-color) !important;
  background-color: var(--input-background);
}

// Placeholder Selektoren für Browser-Kompatibilität
.e-pdfviewer {
  .e-pv-viewer-main-container {
    background-color: var(--background-color);
  }

  .e-pv-viewer-container {
    border: none;
    background-color: var(--pdf-page-background);
  }

  &::placeholder {
    @include placeholder-styles;
  }

  &::-webkit-input-placeholder {
    @include placeholder-styles;
  }

  &::-moz-placeholder {
    @include placeholder-styles;
  }

  &:-ms-input-placeholder {
    @include placeholder-styles;
  }

  &:-moz-placeholder {
    @include placeholder-styles;
  }

  .e-pv-toolbar:not(.e-rtl)
    .e-input-group.e-control-wrapper.e-pv-zoom-drop-down.e-ddl
    .e-input.e-control.e-keyboard {
    padding-left: 0;
  }

  .e-pv-page-container {
    background-color: var(--pdf-page-background) !important;
  }

  .e-toolbar,
  .e-pv-sidebar-toolbar,
  .e-pv-toolbar {
    border: none;
    background-image: none !important;
    background-color: var(--pdf-toolbar-color) !important;
    box-shadow: none !important;

    .e-btn {
      background-color: transparent !important;
      color: var(--text-color) !important;

      &:hover {
        color: var(--primary-color) !important;
      }
    }

    e-pv-zoom-drop-down-container {
      width: 100px;
    }

    .e-toolbar-item {
      padding-bottom: 6px !important;
      padding-top: 6px !important;

      .e-btn {
        background-color: transparent !important;
      }

      span {
        color: var(--text-color) !important;
      }

      .e-pv-icon {
        color: var(--text-color);
      }
    }
  }

  // Haupt Input-Styles
  .e-input-group {
    &,
    &.e-control-wrapper {
      @include input-base-styles;
      border-color: var(--input-border);

      // Float Line Styles
      .e-float-line {
        @include float-line-styles;

        &::before,
        &::after {
          @include float-line-styles;
        }
      }

      // Input und Textarea Styles
      input,
      textarea {
        @include input-base-styles;
      }

      // Hover und Focus States
      &:hover:not(.e-disabled),
      &.e-input-focus:not(.e-disabled) {
        border-color: var(--input-focus);
      }

      // Icon Styles
      .e-input-group-icon {
        color: var(--text-color) !important;
      }
    }
  }

  .e-pv-sidebar-toolbar-splitter.e-left {
    visibility: hidden;
  }

  // Animated Icon Styles
  .e-input-group.e-icon-anim {
    .e-input-group-icon {
      color: var(--primary-color) !important;
    }
  }

  // Float Input Spezifische Styles
  .e-float-input {
    &.e-control-wrapper.e-input-group:not(.e-float-icon-left) {
      .e-float-line {
        &::before,
        &::after {
          @include float-line-styles;
        }
      }
    }

    &:not(.e-error).e-input-focus {
      input ~ label.e-float-text {
        color: var(--primary-color) !important;
      }
    }
  }

  // Label Styles
  input ~ label {
    color: var(--input-label-color) !important;
  }

  .e-hor-nav {
    background-color: var(--pdf-toolbar-color);
    color: var(--text-color) !important;
  }

  .e-pv-current-page-box {
    height: auto !important;
    padding-top: 3px !important;
    width: 60px !important;
    border: none !important;
  }

  .e-pv-zoom-drop-down {
    padding-left: 0 !important;

    .e-icons {
      color: var(--text-color) !important;
    }
  }

  .e-toolbar-pop {
    background-color: var(--pdf-toolbar-color) !important;
  }
}
